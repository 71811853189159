import { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { NoConnectionAzure } from "@dyce/ui-assets";
// Dyce-Lib
import { ComponentTheme } from "@dyce/interfaces";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";
import { DyceTheme } from "@dyce/theme";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      gap: "1rem",
    },
    textContainer: {
      display: "flex",
      flexDirection: "row",
    },
    link: {
      color: theme.palette.mode === "dark" ? "blue" : "darkblue",
      cursor: "pointer",
      textDecoration: "underline",
    },
  })
);

interface IInvalidGrantProps {
  /**
   * Prefer which design should be loaded (loading spinner)
   */
  componentTheme: ComponentTheme;
  /**
   * If true, component can be shown in small iFrame (picture won't be rendered)
   */
  smallIframe: boolean;
}

export const InvalidGrant: FunctionComponent<IInvalidGrantProps> = ({
  componentTheme,
  smallIframe,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const url =
    componentTheme === "azureComponent"
      ? "https://docs.dyce.cloud/docs/time-tracking/setup/AzureDevOpsExtSetup"
      : undefined;
  const graphic =
    componentTheme === "azureComponent" ? NoConnectionAzure : undefined;

  return (
    <div
      className={classes.container}
      style={{ padding: smallIframe ? "0px" : "2rem" }}
    >
      <Typography>
        {t("authentication.invalidGrant.header")}{" "}
        <a target="_blank" className={classes.link} href={url} rel="noreferrer">
          {t("authentication.invalidGrant.documentation")}
        </a>
      </Typography>
      {!smallIframe && (
        <img
          src={graphic}
          alt="Invalid Grant"
          width={"500px"}
          height={"300px"}
        />
      )}
    </div>
  );
};
