import { BatchResponses, ODataBatchRequest } from "../types";
import { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

export class BatchService {
  public baseUrl: string;
  public batchUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = baseUrl;
    this.batchUrl = combineURLs(baseUrl, "/$batch");

    this.axiosInstance = instance;
  }

  /**
   * Generic Function to batch requests
   * @param requests Requests to be batched @type {ODataBatchRequest[]}
   * @returns ODataBatchResponse<T>
   */
  public async batch<T>(requests: ODataBatchRequest[]) {
    const url = this.batchUrl;

    // Adjust the requests to include the base url
    const urlAdjustedRequests: ODataBatchRequest[] = requests.map(
      (request, index) => {
        return {
          id: request.id ? request.id : (index + 1).toString(),
          method: request.method ? request.method : "GET",
          url: combineURLs(this.baseUrl, request.url),
          body:
            request.method &&
            (request.method === "POST" || request.method === "PATCH")
              ? request.body
              : undefined,
        };
      }
    );

    const response = await this.axiosInstance.post<BatchResponses<T>>(url, {
      requests: urlAdjustedRequests,
    });

    return response.data;
  }
}
