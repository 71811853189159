import { FunctionComponent, useEffect, useState } from "react";
// Helper
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
// DYCE-Lib
import { DyceTheme } from "@dyce/theme";
import {
  AllDone,
  DateFieldMoment,
  LoadingSpinner,
  ScrollTop,
  SettingsElement,
  SubHeader,
  useStaticContent,
} from "@dyce/ui";
import {
  RootState,
  TaskSettings,
  getAllTasks,
  selectCurrentWorkspace,
  selectLanguageCode,
  selectTaskCount,
  selectTaskInitialResult,
  selectTaskPagination,
  selectTasksAsTwoDimArray,
  selectTasksFilter,
  selectTasksGroupedBy,
  selectTasksWODate,
  setAllowNetworkWatcher,
  setClearTasks,
  setResetState,
  setTaskPagination,
  setTasksFilter,
  setTasksWODate,
  useAppDispatch,
  useAppSelector,
} from "@dyce/slices";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { useDebounce, useUpdateEffect } from "@dyce/hooks";
import { Container, IconButton, TextField, useMediaQuery } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
// Components
import { TaskList } from "../../../components/timerecording";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    recordsContainer: { ...theme.palette.propsTimeTracking.recordsContainer },
    listContainer: {
      flexDirection: "column",
      display: "flex",
      justifyContent: "stretch",
    },
    subHeaderContainer: {
      display: "flex",
      flexDirection: "column",
      marginTop: "-.625rem",
      width: "100%",
      marginLeft: "2rem",
      alignItems: "flex-end",
    },
    subHeaderFields: {
      display: "flex",
      width: "100%",
      gap: "2rem",
      flexDirection: "row",
      marginTop: ".75rem",
      [theme.breakpoints.down(1200)]: {
        gap: "1rem",
      },
    },
    searchField: {
      width: 250,
      [theme.breakpoints.down(1200)]: {
        width: 150,
        maxWidth: 150,
      },
    },
    listDateSwitch: {
      width: "270px",
      [theme.breakpoints.down(1190)]: {
        width: 100,
        maxHeight: 56,
        marginTop: "-12px",
      },
      "& .MuiListItem-root": {
        padding: "0px",
      },
    },
    dateField: {
      width: "176px",
    },
  })
);

export const Tasks: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation<{
    fromTasks: {
      scrollPosition: number;
    };
  }>();
  const {
    docuLinks,
    isMobile: { mobile },
  } = useStaticContent();
  const isIpadAndSmaller = useMediaQuery((theme: DyceTheme) =>
    theme.breakpoints.down(1190)
  );
  const scrollContainer = document.getElementById("rootContainer") ?? undefined;

  // Selectors
  const isLoading = useAppSelector(
    (state: RootState): unknown[] => state.task.requests
  );
  const tasksGroupedBy = useAppSelector(selectTasksGroupedBy);
  const tasks = useAppSelector(selectTasksAsTwoDimArray(tasksGroupedBy));
  const tasksWODate = useAppSelector(selectTasksWODate);
  const taskFilter = useAppSelector(selectTasksFilter);
  const taskCount = useAppSelector(selectTaskCount);
  const taskPagination = useAppSelector(selectTaskPagination);
  const tasksInitial = useAppSelector(selectTaskInitialResult);
  const languageCode = useAppSelector(selectLanguageCode);
  const workspace = useAppSelector(selectCurrentWorkspace);

  // Debounce
  const { setValue, debouncedValue } = useDebounce<string>(
    taskFilter.searchKeyWord,
    300
  );

  // States
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>(taskFilter.startDate);
  const [searchValue, setSearchValue] = useState<string>(
    taskFilter.searchKeyWord
  );

  // UseEffects
  useEffect(() => {
    // Define pagination 'skip' correct when return from timerecs,
    // else reset storage correct
    if (location.state !== undefined) {
      dispatch(setTaskPagination({ skip: taskFilter.paginationSkip }));
    } else {
      handleSaveSettings(0);
    }

    // Init call API with filter saved in settings
    handleFilterTasks(searchValue);
    return () => {
      dispatch(setClearTasks());
      dispatch(setTaskPagination({ skip: 0 }));
    };
  }, []);

  useEffect(() => {
    if (location.state && !initLoading && scrollContainer) {
      scrollContainer.scrollTo({
        top: location.state.fromTasks.scrollPosition,
        left: 0,
        behavior: "auto",
      });
      history.push(location.pathname, undefined);
    }
  }, [location.state, initLoading]);

  useUpdateEffect(() => {
    if (isLoading.length === 0) {
      setInitLoading(false);
    }
  }, [isLoading]);

  useUpdateEffect(() => {
    if (startDate !== "Error") {
      handleFilterTasks();
      handleSaveSettings();
    }
  }, [debouncedValue, startDate, tasksWODate]);

  useUpdateEffect(() => {
    // Reload tasks when workspace changed
    setInitLoading(true);
    handleResetPage();
    dispatch(setResetState());
    handleFilterTasks();
  }, [workspace]);

  // Handler
  const handleSubtitleExtension = () => {
    switch (tasksGroupedBy) {
      case TaskSettings.CUSTOMER:
        return t("settings.timerecs.tasks.grouping.values.customer");

      case TaskSettings.JOB:
        return t("settings.timerecs.tasks.grouping.values.job");

      case TaskSettings.STARTDATE:
        return t("settings.timerecs.tasks.grouping.values.startDate");

      case TaskSettings.ENDDATE:
        return t("settings.timerecs.tasks.grouping.values.endDate");

      default:
        return " - expand ENUM";
    }
  };

  const handleFilterTasks = (keyWord: string | null = null) => {
    const searchKeyWord = keyWord ? keyWord : debouncedValue;
    if (searchKeyWord.length > 0 && startDate.length === 0) {
      // Search in all fields with searchText
      dispatch(getAllTasks({ search: searchKeyWord }));
    } else if (startDate.length > 0 && searchKeyWord.length === 0) {
      // Search only with starting date
      dispatch(getAllTasks({ date: startDate }));
    } else if (startDate.length > 0 && searchKeyWord.length > 0) {
      // Search with starting date and searchText
      dispatch(getAllTasks({ search: searchKeyWord, date: startDate }));
    } else {
      dispatch(getAllTasks({}));
    }
  };

  /**
   * Function to handle pagination (load more tasks)
   */
  const loadMore = () => {
    dispatch(
      setTaskPagination({ skip: taskPagination.skip + taskPagination.top })
    );
    handleFilterTasks();
    handleSaveSettings(taskPagination.skip + taskPagination.top);
  };

  /**
   * Function to save filter in settings (localStorage)
   * @param newSkipValue Optional value to set skip for pagination
   */
  const handleSaveSettings = (newSkipValue?: number) => {
    const skipValue =
      newSkipValue || newSkipValue === 0 ? newSkipValue : taskPagination.skip;
    dispatch(
      setTasksFilter({
        searchKeyWord: debouncedValue,
        startDate: startDate,
        paginationSkip: skipValue,
      })
    );
  };

  /**
   * Function to handle filter input and visual feedback from ListView
   */
  const handleResetPage = () => {
    dispatch(setTaskPagination({ skip: 0 }));
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
        left: 0,
        behavior: "auto",
      });
    }
  };

  return (
    <>
      <SubHeader
        tooltipLabel={t("timerec.subHeader.tasks.info")}
        tooltipUrlPath={docuLinks.timetracking.tasks.subheader.info}
        title={t("timerec.subHeader.tasks.title")}
        subtitle={t("timerec.subHeader.tasks.subtitle.info", {
          groupedBy: handleSubtitleExtension(),
        })}
        isLoading={isLoading.length > 0}
        childrenMobile={mobile}
        textMobile={mobile}
      >
        <div className={classes.subHeaderContainer}>
          <div>
            <div className={classes.subHeaderFields}>
              <div className={classes.listDateSwitch}>
                <SettingsElement
                  initialValue={tasksWODate}
                  header={t("settings.timerecs.tasks.withoutDate.title")}
                  subtext={
                    isIpadAndSmaller
                      ? ""
                      : t("settings.timerecs.tasks.withoutDate.caption")
                  }
                  onChange={(e: boolean) => {
                    dispatch(setTasksWODate(e));
                    handleResetPage();
                  }}
                  isDisabled={
                    (tasksInitial.loaded && !tasksInitial.hasValues) ||
                    (startDate.length > 0 && startDate !== "Error")
                  }
                  fullWidth={!isIpadAndSmaller}
                />
              </div>
              <div className={classes.searchField}>
                <TextField
                  placeholder={t("timerec.tasks.filter.search.placeholder")}
                  fullWidth
                  value={searchValue}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    setValue(e.target.value);
                    if (e.target.value.length > 0) {
                      handleResetPage();
                    }
                  }}
                  label={t("timerec.tasks.filter.search.label")}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={tasksInitial.loaded && !tasksInitial.hasValues}
                  InputProps={{
                    endAdornment: (
                      <>
                        {debouncedValue.length > 0 ? (
                          <IconButton
                            sx={{ marginRight: "-8px" }}
                            onClick={() => {
                              setSearchValue("");
                              setValue("");
                              dispatch(
                                setTasksFilter({
                                  searchKeyWord: "",
                                  startDate:
                                    startDate === "Error" ? "" : startDate,
                                  paginationSkip: 0,
                                })
                              );
                              handleResetPage();
                              if (taskCount === 0) {
                                setInitLoading(true);
                              }
                            }}
                            tabIndex={-1}
                          >
                            <ClearOutlinedIcon />
                          </IconButton>
                        ) : null}
                      </>
                    ),
                  }}
                />
              </div>
              <DateFieldMoment
                data-testid="date-field"
                onChange={(val) => {
                  if (DateTime.fromISO(val).isValid) {
                    setStartDate(val);
                    dispatch(
                      setTasksFilter({
                        searchKeyWord: searchValue,
                        startDate: val,
                        paginationSkip: taskPagination.skip,
                      })
                    );
                    handleResetPage();
                  } else {
                    if (val.length > 0) {
                      setStartDate("Error");
                    } else {
                      setStartDate("");
                      dispatch(
                        setTasksFilter({
                          searchKeyWord: searchValue,
                          startDate: "",
                          paginationSkip: 0,
                        })
                      );
                    }
                    if (startDate.length > 0) {
                      handleResetPage();
                    }
                  }
                }}
                onBlur={() => {}}
                isEnglish={languageCode === "en"}
                style={classes.dateField}
                // Temporarily needed, till MUI fixes 'LocalizationProvider' for DateTime lib.
                localeHyphen={moment.locale()}
                isDisabled={tasksInitial.loaded && !tasksInitial.hasValues}
                value={startDate}
                label={
                  startDate === "Error"
                    ? "Error"
                    : tasksGroupedBy === TaskSettings.STARTDATE
                    ? t("timerec.tasks.filter.date.label.startDate")
                    : tasksGroupedBy === TaskSettings.ENDDATE
                    ? t("timerec.tasks.filter.date.label.endDate")
                    : t("timerec.tasks.filter.date.label.dateRange")
                }
                variant={"outlined"}
                allowEmptyField={true}
                clearDateButton={true}
              />
            </div>
          </div>
        </div>
      </SubHeader>
      {initLoading ? (
        <LoadingSpinner
          allowNetworkWatcher={(value) =>
            dispatch(setAllowNetworkWatcher(value))
          }
        />
      ) : (
        <Container maxWidth={false}>
          {(tasksInitial.loaded && !tasksInitial.hasValues) ||
          // Special case, when tasks removed from backend and state is not updated (refreshed)
          (tasksInitial.loaded &&
            tasksInitial.hasValues &&
            taskCount === 0 &&
            taskFilter.searchKeyWord.length === 0 &&
            taskFilter.startDate.length === 0) ? (
            <AllDone
              label={t("timerec.tasks.noTasks")}
              IndividualIcon={(color) => (
                <TaskAltOutlinedIcon style={{ fontSize: 64, color: color }} />
              )}
            />
          ) : taskCount === 0 ? (
            <AllDone
              label={t("timerec.tasks.noTasksFound")}
              IndividualIcon={() => (
                <HelpOutlineOutlinedIcon
                  style={{ fontSize: 64, color: "orange" }}
                />
              )}
            />
          ) : (
            <>
              <TaskList
                styles={{
                  ...classes,
                  recordsContainer: classes.recordsContainer,
                }}
                groupedBy={tasksGroupedBy}
                tasks={tasks}
                pagination={{
                  count: taskCount,
                  loadCount: taskPagination.top + taskPagination.skip,
                  loadMore: loadMore,
                }}
              />
              <ScrollTop />
            </>
          )}
        </Container>
      )}
    </>
  );
};
