import { RecordTemplate } from "@dyce/tnt-api";

export const templatesAsTwoDim = (templates: RecordTemplate[]) => {
  // Sorting array is needed when add new Template
  const sortedTemplates = templates.sort((a, b) => {
    if (
      a.category.name.toLocaleLowerCase() < b.category.name.toLocaleLowerCase()
    ) {
      return -1;
    }
    if (
      a.category.name.toLocaleLowerCase() > b.category.name.toLocaleLowerCase()
    ) {
      return 1;
    }
    return 0;
  });

  // Group entries in 2-Dimensional Array [[Date],[Entries fits to Date]]
  const groupTemplates = Object.entries(
    sortedTemplates.reduce(
      (templates, template) => {
        const name = template.category.name;
        templates[name] = templates[name]
          ? [...templates[name], template]
          : [template];
        return templates;
      },
      {} as { [key: string]: RecordTemplate[] }
    )
  );

  return groupTemplates as [string, RecordTemplate[]][];
};
