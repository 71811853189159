import { FunctionComponent, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { DrawerLeft, useStaticContent } from "@dyce/ui";
import {
  getIncompleteRecs,
  selectAdminSettingsTasks,
  selectCurrentWorkspace,
  selectHasResource,
  selectSideDrawerLeft,
  setSideDrawerLeft,
  useAppDispatch,
  useAppSelector,
} from "@dyce/slices";
import { TasksFilterStatus } from "@dyce/tnt-api";
// MUI
import { Box, useTheme } from "@mui/material";
// Components
import {
  Calendar,
  Dashboard,
  IncompleteRecord,
  Tasks,
  Templates,
  TimeRecMenuItems,
  Timerecordings,
} from "./";
import { TimeTrackingWrapper } from "../../components/timerecording/mobile";

export const TimeRecMenuManagement: FunctionComponent = () => {
  const theme = useTheme<DyceTheme>();
  const {
    routes,
    isMobile: { mobile },
  } = useStaticContent();
  const dispatch = useAppDispatch();

  // Selectors
  const workspace = useAppSelector(selectCurrentWorkspace);
  const tasksRule = useAppSelector(selectAdminSettingsTasks);
  const resource = useAppSelector(selectHasResource);
  const open = useAppSelector(selectSideDrawerLeft);

  // To have count ready on page load
  useEffect(() => {
    dispatch(getIncompleteRecs({ top: 1, skip: 0 }));
  }, [workspace, resource]);

  return (
    <>
      {!mobile && (
        <DrawerLeft
          openClose={(value) => dispatch(setSideDrawerLeft(value))}
          open={open}
        >
          <TimeRecMenuItems />
        </DrawerLeft>
      )}
      <Box sx={theme.palette.propsDyce.commonPageProps}>
        <Switch>
          <Route exact={true} path={routes.TNT} component={Dashboard} />
          <Route
            exact={true}
            path={routes.TNT_RECS}
            component={Timerecordings}
          />
          <Route
            exact={true}
            path={`${routes.TNT_RECS}/:id`}
            component={TimeTrackingWrapper}
          />
          <Route
            exact={true}
            path={`${routes.TNT_RECS}/newRecord`}
            component={TimeTrackingWrapper}
          />
          <Route
            exact={true}
            path={`${routes.TNT_RECS}/newTemplate`}
            component={TimeTrackingWrapper}
          />
          <Route
            exact={true}
            path={routes.TNT_TEMPLATES}
            component={Templates}
          />
          <Route
            exact={true}
            path={routes.TNT_INCOMPLETE}
            component={IncompleteRecord}
          />
          <Route exact={true} path={routes.TNT_CALENDAR} component={Calendar} />
          {tasksRule.assignment !== TasksFilterStatus.NONE && (
            <Route exact={true} path={routes.TNT_TASKS} component={Tasks} />
          )}
        </Switch>
      </Box>
    </>
  );
};
