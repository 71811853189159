import {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
// Dyce-Lib
import { SetupLanguages } from "@dyce/slices";
// Helper
import { default as docs } from "./documentation.json";
import { ROUTES } from "./routes";
// Types
import { Documentation, MobileInfo, Modifiers } from "./types";

type StaticContextType = {
  docuLinks: Documentation;
  routes: any;
  platform: Modifiers;
  isMobile: MobileInfo;
};

export const StaticContext = createContext<StaticContextType | null>(null);

interface StaticProps {
  /**
   * Selected language from user to provide correct language modifier description
   */
  language?: SetupLanguages;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

/**
 * Wrapper for the context provider.
 * provides documentation links, routes and platform specific hotkey modifiers
 */
export const StaticProvider: FunctionComponent<StaticProps> = ({
  language = "en",
  children,
}) => {
  const [platform, setPlatform] = useState<"mac" | "other">("other");
  const [isMobile, setIsMobile] = useState<MobileInfo>({
    mobile: false,
    tablet: false,
    touch: false,
  });

  useEffect(() => {
    setPlatform(navigator.platform === "MacIntel" ? "mac" : "other");
    setIsMobile(mobileCheck());
  }, []);

  const mobileCheck = () => {
    const ua = navigator.userAgent,
      mobile = /IEMobile|Windows Phone|Lumia/i.test(ua)
        ? "w"
        : /iPhone|iP[oa]d/.test(ua)
        ? "i"
        : /Android/.test(ua)
        ? "a"
        : /BlackBerry|PlayBook|BB10/.test(ua)
        ? "b"
        : /Mobile Safari/.test(ua)
        ? "s"
        : /webOS|Mobile|Tablet|Opera Mini|\bCrMo\/|Opera Mobi/i.test(ua)
        ? 1
        : 0,
      tablet = /Tablet|iPad/i.test(ua),
      touch = "touchstart" in document.documentElement;

    return { mobile: mobile !== 0 && !tablet, tablet: tablet, touch: touch };
  };

  const modifiers: Modifiers =
    platform === "mac"
      ? {
          currentOs: "MacOS",
          modifiers: {
            modifierCtrl: "command",
            modifierAlt: "option",
            refreshPage: "command+r",
          },
          description: {
            modifierCtrl: "⌘",
            modifierAlt: "⌥",
          },
        }
      : {
          currentOs: "Windows",
          modifiers: {
            modifierCtrl: "ctrl",
            modifierAlt: "alt",
            refreshPage: "f5",
          },
          description: {
            modifierCtrl: language === "en" ? "Ctrl" : "Strg",
            modifierAlt: "Alt",
          },
        };

  return (
    <StaticContext.Provider
      value={{
        docuLinks: docs,
        routes: ROUTES,
        platform: modifiers,
        isMobile: isMobile,
      }}
    >
      {children}
    </StaticContext.Provider>
  );
};

/**
 * Custom hook for the ability context
 * @returns Ability context
 */
export const useStaticContent = (): StaticContextType => {
  const context = useContext(StaticContext);

  if (context === null) {
    throw new Error("Static Context is undefined");
  }

  return context;
};
