import { useState, useEffect, FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Helper
import { getAppAreaLogo, setAppAreaAndDocTitle } from "./utils";
import { useHotkeys } from "react-hotkeys-hook";
import { DateTime, Duration } from "luxon";
import { normalizeTime, getDuration } from "../stopwatch/utils";
// Dyce-Lib
import {
  Stopwatch as TStopwatch,
  RecordTimeRec,
  Instance,
  CurrentUser,
} from "@dyce/tnt-api";
import { useStaticContent } from "../static-provider/static-provider";
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  useTheme,
} from "@mui/material";
// Components
import { UserMenu } from "../user-menu/user-menu";
import { ApplicationMenu } from "../application-menu/application-menu";
import { WatchModal } from "../stopwatch/watch-modal";
import { Stopwatch } from "../stopwatch/stopwatch";
import { TooltipProvider } from "../tooltip/tooltip-context";

const useStyles = makeStyles(() =>
  createStyles({
    logoWrapper: {
      marginLeft: "1rem",
      marginRight: ".5rem",
      "& .MuiButtonBase-root": {
        "&:hover": {
          background: "none",
        },
      },
    },
    logoButton: {
      height: "2.5rem",
      width: "2.5rem",
    },
    title: {
      flexGrow: 1,
      "& .MuiTypography-root": {
        letterSpacing: "0.2em",
        fontWeight: 400,
      },
    },
    logo: {
      height: "2rem",
      width: "2rem",
    },
    watchFont: {
      cursor: "pointer",
    },
    watchInfoContainer: {
      marginRight: 10,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
    },
  })
);

export interface INavbarProps {
  /**
   * Wether to show features that are meant for logged in users
   */
  showInstrumentation?: boolean;
  /**
   * wether the current user is logged in
   */
  isLoggedIn?: boolean;
  /**
   * the stopwatch of the current user. if this property is undefined no stopwatch will be displayed.
   * providing this prop implies that the user fulfills all requirements (has resource and role) to use the stopwatch
   */
  stopwatch?: {
    data?: TStopwatch;
    start: (start: string, description: string) => void;
    stop: () => void;
    create: (record: RecordTimeRec) => void;
    fetch: () => void;
  };
  /**
   * user environment data
   */
  env: {
    /**
     * the users current instance
     */
    instance: string;
    /**
     * the users currently company
     */
    company: string;
    /**
     * all instances available to the user
     */
    instances: Instance[];
  };
  /**
   * location pathname
   */
  location: string;
  /**
   * Open Usermenu switch
   */
  toggleUsermenu?: boolean;
  /**
   * Callback for closing Usermenu
   */
  onToggle?: () => void;
  /**
   * The positioning type.
   * The behavior of the different options is described in the MDN web docs.
   * Note: sticky is not universally supported and will fall back to static when
   * unavailable.
   * @default 'fixed'
   */
  position?:
    | "fixed"
    | "absolute"
    | "relative"
    | "static"
    | "sticky"
    | undefined;
  /**
   * link component
   * @default <Link>
   */
  linkAs?: React.ReactElement | string;
  /**
   * set the title name
   * @default the application part of the current pathname
   */
  titleOverride?: string;
  /**
   * Callback to render individual JSX elements at the end of Navbar
   */
  individualComponent?: JSX.Element;
  /**
   * Current user information
   */
  currentUser?: CurrentUser;
  /**
   * callback when a new instance/company combination is selected
   */
  onSelection?: (instance: Instance, company: string) => void;
  /**
   * Base target URL for documentation link of tooltip
   */
  documentationUrl?: string;
  /**
   * Callback provides favicon url to "assets/[faviconFileName].ico"
   * Important, save ico files in root of your assets folder
   */
  handleChangeFavicon?: (arg: string) => void;
  /**
   * Define if the device is mobile or not
   * @default false
   */
  isMobile?: boolean;
}

export const Navbar: FunctionComponent<INavbarProps> = ({
  showInstrumentation = true,
  isLoggedIn = false,
  stopwatch,
  env,
  location,
  toggleUsermenu = false,
  onToggle,
  position = "fixed",
  titleOverride,
  linkAs = Link,
  individualComponent,
  currentUser = null,
  onSelection,
  documentationUrl = "",
  handleChangeFavicon,
  isMobile = false,
}) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const { platform } = useStaticContent();
  const theme = useTheme<DyceTheme>();

  // States
  const [appArea, setAppArea] = useState<string>("");
  const [anchor, setAnchor] = useState<any>(null);
  const [open, setOpen] = useState(false);
  const [time, setTime] = useState("");

  // UseEffects
  useEffect(() => {
    if (titleOverride) {
      setAppArea(titleOverride);
      return;
    }
    const area = setAppAreaAndDocTitle(location.split("/")[1], isLoggedIn);
    area.length === 0 ? setAppArea("DYCE") : setAppArea(`DYCE - ${area}`);
  }, [location, titleOverride, i18n.language]);

  useEffect(() => {
    if (stopwatch && stopwatch.data?.running) {
      timer = setInterval(updateTime, 1000);

      handleFavicon(true);
    } else {
      handleFavicon(false);
    }
    return () => clearInterval(timer);
  }, [stopwatch, location]);

  useEffect(() => {
    if (open) {
      stopwatch && stopwatch.fetch();
    }
  }, [open]);

  useHotkeys(
    `${platform.modifiers.modifierAlt}+s`,
    (e) => {
      e.preventDefault();
      if (stopwatch && stopwatch.data?.running) {
        setOpen(true);
      } else {
        handleStart("", new Date().toISOString());
        setOpen(true);
      }
    },
    {
      filter: () => showInstrumentation && Boolean(stopwatch),
    },
    [stopwatch, showInstrumentation]
  );

  const handleStart = (desc: string, start: string) => {
    setTime("00:00:00");
    stopwatch && stopwatch.start(start, desc);

    handleFavicon(true);
  };

  const handleStop = (forceEnd?: boolean, desc?: string) => {
    stopwatch && stopwatch.stop();
    clearInterval(timer);
    setTime("");

    let end;
    if (forceEnd) {
      end = DateTime.fromISO(stopwatch ? stopwatch.data!.start : "")
        .plus({ day: 1 })
        .startOf("day")
        .toISO();
    } else {
      end = DateTime.now().toISO();
    }

    const { start, end: normEnd } = normalizeTime(stopwatch!.data!.start, end);

    stopwatch?.create({
      start: start,
      end: normEnd,
      duration: getDuration(start, normEnd),
      durationBillable: getDuration(start, normEnd),
      nonBillableReason: "None",
      description:
        desc && desc != stopwatch!.data!.description
          ? desc
          : stopwatch!.data!.description,
    } as RecordTimeRec);

    handleFavicon(false);

    document.title = "DYCE Time Tracking";
  };

  const handleTrash = () => {
    stopwatch && stopwatch.stop();
    clearInterval(timer);
    setTime("");

    handleFavicon(false);
    document.title = "DYCE Time Tracking";
  };

  const updateTime = () => {
    if (
      DateTime.now().startOf("day") >
      DateTime.fromISO(stopwatch!.data!.start).startOf("day")
    ) {
      handleStop(true);
      return;
    }
    const stopTime: Duration = DateTime.now().diff(
      DateTime.fromISO(stopwatch!.data!.start)
    );

    setTime(stopTime.toFormat("hh:mm:ss"));

    const formatInfo =
      stopTime.milliseconds < 60000
        ? stopTime.toFormat("mm:ss")
        : stopTime.toFormat("h:mm");

    document.title = `${formatInfo} - DYCE ${document.title.split("DYCE")[1]}`;
  };

  const handleFavicon = (stopwatchIsRunning: boolean) => {
    if (location.includes("timetracking")) {
      // Time Tracking Favicon
      if (!stopwatchIsRunning) {
        handleChangeFavicon && handleChangeFavicon("assets/favicon-tt.ico");
      } else {
        handleChangeFavicon && handleChangeFavicon("assets/favicon-tt_rec.ico");
      }
    } else {
      // DYCE Favicon
      if (!stopwatchIsRunning) {
        handleChangeFavicon && handleChangeFavicon("favicon.ico");
      } else {
        handleChangeFavicon && handleChangeFavicon("assets/favicon_rec.ico");
      }
    }
  };

  let timer: NodeJS.Timeout;
  return (
    <AppBar
      position={position}
      color="secondary"
      elevation={0}
      sx={{
        backgroundImage:
          theme.palette.mode === "dark"
            ? "linear-gradient(to right, rgb(68 170 218), rgb(68 170 218));"
            : "linear-gradient(to right, rgb(42 143 189), rgb(42 143 189));",
      }}
    >
      <Toolbar>
        {showInstrumentation && !isMobile && <ApplicationMenu />}
        <div
          className={classes.logoWrapper}
          style={
            isMobile
              ? {
                  marginLeft: "0",
                }
              : {}
          }
        >
          <IconButton
            className={classes.logoButton}
            component={linkAs as any}
            to={"/"}
          >
            {getAppAreaLogo(location.split("/")[1])}
          </IconButton>
        </div>
        <div className={classes.title}>
          <Typography variant="h6" noWrap>
            {isMobile ? "DYCE" : appArea}
          </Typography>
        </div>
        {showInstrumentation && (
          <>
            {stopwatch && (
              <TooltipProvider baseUrl={documentationUrl}>
                {stopwatch?.data?.running && (
                  <div>
                    {stopwatch &&
                      stopwatch.data &&
                      stopwatch.data.description &&
                      stopwatch.data.description.length > 0 && (
                        <Typography
                          data-testid="stopwatch-description"
                          onClick={(e) => {
                            setOpen(true);
                          }}
                          className={classes.watchFont}
                        >
                          {stopwatch?.data?.description.length > 20
                            ? `${stopwatch?.data?.description.substring(
                                0,
                                20
                              )}...`
                            : stopwatch?.data?.description}
                        </Typography>
                      )}
                    <Typography
                      data-testid="stopwatch-time"
                      onClick={(e) => {
                        setOpen(true);
                      }}
                      className={classes.watchFont}
                      style={{ textAlign: "right" }}
                    >
                      {time}
                    </Typography>
                  </div>
                )}
                <Stopwatch
                  isLoading={!Boolean(stopwatch)}
                  isRunning={(stopwatch && stopwatch.data?.running) || false}
                  isOpen={open}
                  tooltipLabel={t("nav.stopwatch.tooltip.bar", {
                    modifier: "description.modifierAlt",
                  })}
                  openModal={() => {
                    setOpen(true);
                  }}
                  registerRef={(r: any) => setAnchor(r.current)}
                />
                <WatchModal
                  anchor={anchor}
                  stopwatch={stopwatch.data || null}
                  open={open}
                  close={() => {
                    setOpen(false);
                  }}
                  startRecording={handleStart}
                  stopRecording={handleStop}
                  trashRecording={handleTrash}
                />
              </TooltipProvider>
            )}
            <UserMenu
              isVisible={toggleUsermenu}
              onToggle={() => (onToggle ? onToggle() : {})}
              currentUser={currentUser}
              onSelection={onSelection}
              {...env}
            />
          </>
        )}
        {individualComponent && individualComponent}
      </Toolbar>
    </AppBar>
  );
};
