import { useState, useEffect, useRef, MutableRefObject } from "react";

/**
 * Hook to check if provided Reference is intersecting with Viewport
 * @param ref Referenced Element
 * @param options Optional parameter for IntersectionObserver
 * @param forward Optional boolean to stay at current Element or set new one
 * @returns Boolean if Reference has intersected
 */
export const useIntersection = (
  ref: MutableRefObject<Element | null>,
  options: IntersectionObserverInit = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  },
  forward = false
): boolean => {
  const [element, setElement] = useState<Element | null>(null);
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false);
  const observer = useRef<null | IntersectionObserver>(null);

  const cleanOb = () => {
    if (observer.current) {
      observer.current.disconnect();
    }
  };

  useEffect(() => {
    setElement(ref.current);
  }, [ref]);

  useEffect(() => {
    if (!element) {
      return;
    }

    cleanOb();

    const ob = (observer.current = new IntersectionObserver(
      ([entry]) => {
        const isElementIntersecting = entry.isIntersecting;
        if (!forward) {
          setIsIntersecting(isElementIntersecting);
        } else if (forward && !isIntersecting && isElementIntersecting) {
          setIsIntersecting(isElementIntersecting);
          cleanOb();
        }
      },
      { ...options }
    ));

    ob.observe(element);

    return () => {
      cleanOb();
    };
  }, [element, options]);

  return isIntersecting;
};
