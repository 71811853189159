import { createSlice } from "@reduxjs/toolkit";
import shortid from "shortid";
import { remove } from "lodash";
import { NotificationStateSlice } from "../../types/types";

const initialState: NotificationStateSlice = {
  current: [],
  past: [],
};

const notificationSlice = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    removeSnackbar(state, action) {
      const notification = state.current.find((e) => e.key === action.payload);
      remove(state.current, notification);
      state.past.push(notification);
    },
  },
  extraReducers: (builder) =>
    builder.addMatcher(
      (action) => action.type.endsWith("rejected"),
      (state, action) => {
        if (!action["payload"]) return;
        const err = {
          key: shortid.generate(),
          data: action,
          options: {
            variant: "error",
          },
        };
        state.current.push(err);
      }
    ),
});

export const { removeSnackbar } = notificationSlice.actions;

export default notificationSlice.reducer;
