import { FunctionComponent, ReactNode } from "react";
// Helper
// Dyce-Lib
import { Popper } from "../../popper/popper";
// MUI
import { IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
// Components

interface IEventEditorProps {
  /**
   * The anchor element to use for the popper
   */
  anchorEl: HTMLElement | null;
  /**
   * Info about current used theme
   */
  darkMode: boolean;
  /**
   * Header caption of the event editor
   */
  headerCaption: string;
  /**
   * Max width of the event editor forwarded to the popper
   * @default 448
   */
  maxWidth?: number;
  /**
   * Max width of the event editor forwarded to the popper
   */
  minHeight?: number;
  /**
   * If true, popper will not overflow viewport
   * @default true
   */
  altBoundaryPreventOverflow?: boolean;
  /**
   * Force min-height as height
   * @default false
   */
  forceMinHeightAsHeight?: boolean | number;
  /**
   * Callback fired on onClose events
   * @returns void
   */
  onClose: () => void;
  /**
   * If true, close button will be rendered
   * @default true
   */
  withCloseButton?: boolean;
  /**
   * Overwrite z-index of popper
   */
  overwriteZIndex?: number;
  /**
   * Children to render inside the event editor
   */
  children: ReactNode;
}

export const EventEditor: FunctionComponent<IEventEditorProps> = ({
  onClose,
  anchorEl,
  darkMode,
  headerCaption,
  maxWidth = 448,
  minHeight,
  altBoundaryPreventOverflow = true,
  forceMinHeightAsHeight = false,
  withCloseButton = true,
  overwriteZIndex,
  children,
}) => {
  return (
    <Popper
      anchorEl={anchorEl}
      onClose={onClose}
      placement="right"
      open={Boolean(anchorEl)}
      maxWidth={maxWidth}
      minHeight={minHeight}
      overwriteZIndex={overwriteZIndex}
      altBoundaryPreventOverflow={altBoundaryPreventOverflow}
      forceMinHeightAsHeight={forceMinHeightAsHeight}
      headerButtons={{
        expand: {},
        filter: {},
        individual: withCloseButton
          ? {
              renderIndividual() {
                return (
                  <div
                    style={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton size="small" onClick={() => onClose()}>
                      <CloseOutlined style={{ color: "#fff" }} />
                    </IconButton>
                  </div>
                );
              },
            }
          : {},
      }}
      header={{
        caption: headerCaption,
        sx: {
          backgroundColor: darkMode ? "rgb(0, 101, 188)" : "rgb(84, 181, 243)",
          color: "#fff",
          padding: "8px 8px",
        },
      }}
      arrow
    >
      {children}
    </Popper>
  );
};
