import { FunctionComponent } from "react";
// Helper
import { Controller, useFormContext } from "react-hook-form";

export const HiddenFocusInput: FunctionComponent = () => {
  const { control } = useFormContext();

  return (
    <Controller
      name="hidden"
      control={control}
      render={({ field: { ref } }) => (
        <input
          style={{
            position: "absolute",
            left: "-300px",
            opacity: 0,
          }}
          ref={ref}
          tabIndex={1}
        />
      )}
    />
  );
};
