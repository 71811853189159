import { DateTime } from "luxon";
import { minBy, maxBy } from "lodash";
import { getDefaultFromDate } from "../resources/util";
import _ from "lodash";
import {
  RecordTimeRec,
  Resource,
  PeriodTime,
  Statistics,
  StatisticsPiePeriod,
  PopulatedBillableTotal,
  JobTotal,
  ActivityTotal,
  Days,
  WeekLoad,
} from "@dyce/tnt-api";
import { Periods, ResourceList } from "@dyce/interfaces";
import { ResourcePlanning } from "../../types/types";

const dateFormat = "yyyy-MM-dd";

/**
 * Sorts an array of recordings by date
 * @param timeRecs Array of timerecordings
 */
export const recordsByDate = (timeRecs: RecordTimeRec[], dates: Date[]) => {
  // Group entries in 2-Dimensional Array [[Date],[Entries fits to Date]]
  const groupByDate = Object.entries(
    timeRecs.reduce(
      (timeRecs, timeRec) => {
        const date = DateTime.fromISO(timeRec.date).toFormat(dateFormat);
        timeRecs[date] = timeRecs[date]
          ? [...timeRecs[date], timeRec]
          : [timeRec];
        return timeRecs;
      },
      {} as { [key: string]: RecordTimeRec[] }
    )
  );

  //Sort Timerecordings within the day
  groupByDate.forEach((a, b, arr) => {
    let timeArray = [];
    let dateArray = [];

    timeArray = a[1].filter((e) => e.start && e.end);
    dateArray = a[1].filter((e) => !(e.start && e.end));

    timeArray.sort(
      (a, b) =>
        new Date(a.start !== null ? a.start : 0).getTime() -
        new Date(b.end !== null ? b.end : 0).getTime()
    );

    dateArray.sort(
      (a, b) => new Date(a.created).getTime() - new Date(b.created).getTime()
    );

    arr[b] = [a[0], dateArray.concat(timeArray)];
  });

  const emptyDays = Object.entries(
    dates.reduce(
      (dates, date) => {
        const d = DateTime.fromJSDate(date).toFormat(dateFormat);
        dates[d] = [];

        return dates;
      },
      {} as { [key: string]: any[] }
    )
  );

  let sortByDate: any[] = [];
  let tmpArray: any[] = [];

  emptyDays.forEach((emday) => {
    if (!groupByDate.some((el) => el[0] === emday[0])) {
      tmpArray.push(emday);
    }
  });

  tmpArray = [...tmpArray, ...groupByDate];
  // Sort entries first by Time (Date in milliseconds) forwards (oldest top)
  if (tmpArray.length > 0) {
    sortByDate = tmpArray.sort(
      (a, b) => new Date(a[0]).getTime() - new Date(b[0]).getTime()
    );
  }

  // TODO: Sort entries for each day

  return sortByDate as [string, RecordTimeRec[]][];
};

/**
 * Sorts an array of recordings by date, does not merge with days
 * @param timeRecs Array of timerecordings
 */
export const recordsByDateClean = (timeRecs: RecordTimeRec[]) => {
  // Group entries in 2-Dimensional Array [[Date],[Entries fits to Date]]
  const groupByDate = Object.entries(
    timeRecs.reduce(
      (timeRecs, timeRec) => {
        const date = timeRec.date.toString().split("T")[0];
        timeRecs[date] = timeRecs[date]
          ? [...timeRecs[date], timeRec]
          : [timeRec];
        return timeRecs;
      },
      {} as { [key: string]: RecordTimeRec[] }
    )
  );

  let sortByDate: [string, RecordTimeRec[]][] = [];
  // Sort entries first by Time (Date in milliseconds) backwards (newest top)
  if (groupByDate.length > 0) {
    sortByDate = groupByDate.sort(
      (a, b) => new Date(b[0]).getTime() - new Date(a[0]).getTime()
    );
  }

  // TODO: Sort entries for each day

  return sortByDate as [string, RecordTimeRec[]][];
};

/**
 * Determine wether to render that day or not
 * @param day The day in question
 * @param workTimes The assigned work hours per day a week
 * @param workDays Days off work
 * @returns boolean or null if date is out of bounds
 */
export const doRenderDay = (
  day: Date,
  resources: Record<string, ResourcePlanning>
) => {
  const localday = DateTime.fromJSDate(day).setLocale("en");
  const res = getDefaultFromDate(day, resources);

  if (!res?.capacity) {
    return false;
  }

  if (res) {
    if (
      (res.calendar !== undefined &&
        res.calendar.some((d) =>
          localday.hasSame(DateTime.fromFormat(d.toString(), dateFormat), "day")
        )) ||
      (res.recurringHolidays !== undefined &&
        res.recurringHolidays.some((d) => d === localday.toFormat("MMdd")))
    ) {
      return false;
    }

    return (
      (res.capacity as Record<string, number>)[
        localday.toFormat("EEEE").toLowerCase()
      ] > 0
    );
  } else {
    return null;
  }
};

/**
 * Creates an array of existing dates between a start and end date
 * see https://stackoverflow.com/questions/4413590/javascript-get-array-of-dates-between-2-dates
 * @param start
 * @param end
 */
export const getDaysBetween = (start: Date, end: Date) => {
  const dateArray: Date[] = [];
  let currentDate = new Date(start.toString());

  while (currentDate <= end) {
    dateArray.push(new Date(currentDate));
    currentDate = addDay(currentDate);
  }

  return dateArray;
};

/**
 * Returns range of dates to fetch from intersection
 * see https://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
 * @param timespan state timespan with max and min value
 * @param start requested start
 * @param end requested end
 */
export const getDateDelta = (
  timespan: [Date, Date] | null,
  start: Date,
  end: Date
): [start: Date, end: Date] | null => {
  if (!timespan || !timespan[0] || !timespan[1]) {
    return null;
  }

  // AFTER
  if (isBefore(start, timespan[0]) && isBefore(end, timespan[0]))
    return [start, end];

  // START TOUCHING
  if (isBefore(start, timespan[0]) && isSame(end, timespan[0])) {
    return [start, substractDay(timespan[0])];
  }

  // START INSIDE
  if (
    isBefore(start, timespan[0]) &&
    isBetween(end, timespan[0], timespan[1])
  ) {
    return [start, substractDay(timespan[0])];
  }

  // INSIDE START TOUCHING
  if (isSame(start, timespan[0]) && isAfter(end, timespan[1]))
    return [addDay(timespan[1]), end];

  // ENCLOSING START TOUCHING && ENCLOSING && ENCLOSING END TOUCHING && EXACT MACTH
  if (
    isBetween(start, timespan[0], timespan[1]) &&
    isBetween(end, timespan[0], timespan[1])
  )
    return null;

  // INSIDE
  if (isBefore(start, timespan[0]) && isAfter(end, timespan[1]))
    return [start, end];

  // INSIDE END TOUCHING
  if (isBefore(start, timespan[0]) && isSame(end, timespan[1])) {
    return [start, substractDay(timespan[0])];
  }

  // END INSIDE
  if (isBetween(start, timespan[0], timespan[1]) && isAfter(end, timespan[1]))
    return [addDay(timespan[1]), end];

  // END TOUCHING
  if (isSame(start, timespan[1]) && isAfter(end, timespan[1]))
    return [addDay(timespan[1]), end];

  // BEFORE
  if (isAfter(start, timespan[1]) && isAfter(end, timespan[1]))
    return [start, end];

  return null;
};

/**
 * Compares if date is between two another on day level
 * @param date original date
 * @param start range start
 * @param end range end
 */
export const isBetween = (date: Date, start: Date, end: Date) => {
  return (
    DateTime.fromJSDate(date).day >= DateTime.fromJSDate(start).day &&
    DateTime.fromJSDate(date).day <= DateTime.fromJSDate(end).day
  );
};

/**
 * Compares if date is after another
 * @param date original date
 * @param comparison comparison date
 */
export const isAfter = (date: Date, comparison: Date) => {
  return DateTime.fromJSDate(date) > DateTime.fromJSDate(comparison);
};

/**
 * Compares if date is before another on day level
 * @param date original date
 * @param comparison comparison date
 */
export const isBefore = (date: Date, comparison: Date) => {
  return DateTime.fromJSDate(date).day < DateTime.fromJSDate(comparison).day;
};

/**
 * Compares if two dates are the same on day level
 * @param date original date
 * @param comparison comparison date
 */
export const isSame = (date: Date, comparison: Date) => {
  return DateTime.fromJSDate(date).hasSame(
    DateTime.fromJSDate(comparison),
    "day"
  );
};

/**
 * Substracts one day
 * @param date Date to transform
 */
export const substractDay = (date: Date): Date => {
  try {
    const workdate = new Date(date.getTime());
    workdate.setDate(workdate.getDate() - 1);
    return workdate;
  } catch (error) {
    console.error(error);
    return date;
  }
};

/**
 * Adds one day to date
 * @param date Date to transform
 */
export const addDay = (date: Date): Date => {
  try {
    const workdate = new Date(date.getTime());
    workdate.setDate(workdate.getDate() + 1);
    return workdate;
  } catch (error) {
    console.error(error);
    return date;
  }
};

/**
 * Filters min and max date from array of recordings
 * @param recs Time Recordings
 * @returns min and max date
 */
export const getMinMax = (
  recs: RecordTimeRec[] | null
): { min: Date; max: Date } | { min: null; max: null } => {
  const min = minBy(recs, (e) => e.date)?.date;
  const max = maxBy(recs, (e) => e.date)?.date;

  if (!min || !max) {
    //throw new Error("Error processing result sizing in min/max");
    return { min: null, max: null };
  }

  return { min: new Date(min), max: new Date(max) };
};

export const sortRecs = (
  recs: RecordTimeRec[],
  start: string,
  direction: "past" | "future"
) => {
  // filter entries
  const filteredRecs = _.filter(
    recs,
    (val) =>
      direction == "past"
        ? DateTime.fromISO(val.date) >= DateTime.fromFormat(start, dateFormat)
        : DateTime.fromISO(val.date) <= DateTime.fromFormat(start, dateFormat) // direction == "future"
  );

  // group data by common day
  const sortedRecs: _.Dictionary<RecordTimeRec[]> =
    filteredRecs.length > 0
      ? _.groupBy(filteredRecs, (val) => val.date.split("T")[0]) // group by date
      : ([] as unknown as _.Dictionary<
          // or return empty array if recs empty
          (RecordTimeRec | RecordTimeRec)[]
        >);

  return sortedRecs;
};

export const getCountWithDirection = (
  recs: RecordTimeRec[],
  start: string,
  direction: "past" | "future",
  count: number,
  defaults: Record<string, ResourcePlanning>,
  skelCountDown: number,
  skelCountUp: number,
  topOffset: number | undefined
) => {
  const sortedRecs = sortRecs(recs, start, direction);

  let skelCounter = 0;
  let counter = 0;
  let offsetDay = DateTime.fromJSDate(
    addDay(DateTime.fromFormat(start, dateFormat).toJSDate())
  );
  let day = DateTime.fromFormat(start, dateFormat);
  let daySkel = DateTime.fromFormat(start, dateFormat);
  const recArray: RecordTimeRec[] = [];
  const dayArray: Date[] = [];

  // if offset is provided, add number of entries to the top
  if (topOffset && direction === "past") {
    // offset needs own array bc main array is "past" -> offsets got into the future
    const offsetRecs = sortRecs(recs, start, "future");
    while (counter <= topOffset) {
      // if day is present with entries, get those entries
      if (
        offsetRecs[offsetDay.toFormat(dateFormat)] &&
        offsetRecs[offsetDay.toFormat(dateFormat)].length > 0
      ) {
        counter = counter + 1;
        //.some() is like an foreach that can be broken with returning true; exits when counter is full
        offsetRecs[offsetDay.toFormat(dateFormat)].some((e) => {
          if (counter <= topOffset) {
            recArray.push(e);
            counter = counter + 1;
            return false;
          } else {
            return true;
          }
        });
      } else {
        // check if day should be rendered; if null break loop
        const render = doRenderDay(offsetDay.toJSDate(), defaults);
        if (render === null) {
          break;
        } else if (render) {
          dayArray.push(offsetDay.toJSDate());
          counter = counter + 1;
        }
      }

      // increase or decrease one day
      offsetDay = DateTime.fromJSDate(addDay(offsetDay.toJSDate()));
    }
  }

  // -> count + 1 depends on length reading (starting 0 vs starting 1)
  while (counter < count) {
    // if day is present with entries, get those entries
    if (
      sortedRecs[day.toFormat(dateFormat)] &&
      sortedRecs[day.toFormat(dateFormat)].length > 0
    ) {
      counter = counter + 1;
      //.some() is like an foreach that can be broken with returning true; exits when counter is full
      sortedRecs[day.toFormat(dateFormat)].some((e) => {
        if (counter < count) {
          recArray.push(e);
          counter = counter + 1;
          return false;
        } else {
          return true;
        }
      });
    } else {
      // check if day should be rendered; if null break loop
      const render = doRenderDay(day.toJSDate(), defaults);
      if (render === null) {
        break;
      } else if (render) {
        dayArray.push(day.toJSDate());
        counter = counter + 1;
      }
    }

    // increase or decrease one day
    day =
      direction === "past"
        ? DateTime.fromJSDate(substractDay(day.toJSDate()))
        : DateTime.fromJSDate(addDay(day.toJSDate()));
  }

  // zip recordings and empty days
  const result = recordsByDate(recArray, dayArray);

  // init skeleton days
  if (result.length > 0) {
    daySkel = DateTime.fromFormat(result[0][0], dateFormat).plus({ days: 1 });
    day = DateTime.fromFormat(result[result.length - 1][0], dateFormat).minus({
      days: 1,
    });
  }

  // Add placeholder days on top for inf. scrolling
  while (skelCounter < skelCountUp) {
    const render = doRenderDay(daySkel.toJSDate(), defaults);
    if (render === null) {
      break;
    } else if (render) {
      result.unshift([daySkel.toFormat(dateFormat), []]);
      skelCounter += 1;
    }
    daySkel = DateTime.fromJSDate(addDay(daySkel.toJSDate()));
  }

  //Add placeholder days at bottom for inf. scrolling
  skelCounter = 0;
  while (skelCounter < skelCountDown) {
    const render = doRenderDay(day.toJSDate(), defaults);
    if (render === null) {
      break;
    } else if (render) {
      result.push([day.toFormat(dateFormat), []]);
      skelCounter += 1;
    }
    day = DateTime.fromJSDate(substractDay(day.toJSDate()));
  }

  return result;
};

/**
 *
 * @param entries Time Recordings Array to filter and process
 * @param startDate The start date of
 * @returns time recordings filtered and sorted
 */
export const processRecs = (entries: RecordTimeRec[], startDate: string) => {
  const filteredRecs = _.filter(
    entries,
    (val) =>
      DateTime.fromISO(val.date) >= DateTime.fromFormat(startDate, dateFormat)
  );

  const sortedRecs = _.groupBy(filteredRecs, (val) => val.date.split("T")[0]);

  return sortedRecs;
};

export const isComplete = (record: RecordTimeRec): boolean => {
  if (
    record.duration > 0 &&
    record.durationBillable >= 0 &&
    record.description &&
    record.job &&
    record.job.id &&
    record.jobTask &&
    record.jobTask.id &&
    record.customer &&
    record.customer.id
  ) {
    return true;
  } else {
    return false;
  }
};

/**
 * Function to get resources ordered with current resource at top
 * @param recs Timerecordings as array
 * @param currentResource Resource from current logged in user
 * @returns All resources with entries ordered from A to Z with current resource at top
 */
export const foreignEntriesAsTwoDimArray = (
  recs: Record<string, RecordTimeRec>,
  currentResource: Resource | null
): [ResourceList, RecordTimeRec[]][] => {
  const recArray: RecordTimeRec[] = Object.values(recs);

  for (let i = 0; i < recArray.length; i++) {
    // Safely have records always with resource
    if (recArray[i].resource === undefined && currentResource) {
      recArray[i] = { ...recArray[i], resource: currentResource };
    }
  }

  let resourcesWithEntriesArray: Resource[] = [];
  if (currentResource) {
    resourcesWithEntriesArray = orderedResources(recArray, currentResource);
  }

  const results: [ResourceList, RecordTimeRec[]][] = [];

  resourcesWithEntriesArray.forEach((res) => {
    const entries = recArray.filter((rec) => rec.resource?.id === res.id);

    results.push([
      {
        resourceName: res.name,
        resourceId: res.id,
      },
      entries,
    ]);
  });

  return results;
};

/**
 * Helper function for foreignEntriesAsTwoDimArray
 * @param recArray Timerecordings as array
 * @param currentResource Current logged in user-resource
 * @returns All resources with from A to Z with current resource at top
 */
export const orderedResources = (
  recArray: RecordTimeRec[],
  currentResource: Resource
): Resource[] => {
  let currentResourceHasEntries = false;
  const resources = recArray.map((x) =>
    x.resource === undefined ? currentResource : x.resource
  );

  // Make unique entries from all resources
  const uniqueResources = _.unionBy(resources, "id");

  // Check if current resource has timerecs in current task
  uniqueResources.map((x) => {
    if (x.id === currentResource.id) {
      currentResourceHasEntries = true;
    }
  });

  // Filter currentResource from array
  const reorderResources = uniqueResources.filter(
    (res) => res.id !== currentResource.id
  );

  // Sort Resources by name (A to Z)
  reorderResources.sort((a, b) => {
    if (a.name.toLowerCase() > b.name.toLowerCase()) {
      return 1;
    }
    if (a.name.toLowerCase() < b.name.toLowerCase()) {
      return -1;
    }
    return 0;
  });

  // Add current Resource at start of array
  if (currentResourceHasEntries) {
    reorderResources.unshift(currentResource);
  }

  return reorderResources;
};

/**
 * Function to sum up getSummary entries for week | month | year
 * @param payload Array of PeriodTime for selected period (getSummary)
 * @returns PeriodTime Array with summed up entries for week | month | year
 */
export const handleSummaryByPeriods = ({
  payload,
  userCapacity,
}: {
  payload: PeriodTime[];
  userCapacity: Record<string, ResourcePlanning>;
}): PeriodTime[] => {
  let newPayload: PeriodTime[] = [...payload];
  let periodSwitch: "weekNumber" | "month" | "day" = "day";
  let sum: PeriodTime = {
    date: newPayload.length > 0 ? newPayload[0].date : "2000-01-01",
    duration: 0,
    durationBillable: 0,
    break: 0,
    capacity: 0,
  };
  const dstPeriodStart = DateTime.fromISO(sum.date).isInDST;

  if (payload.length < 7) {
    periodSwitch = "weekNumber";
  } else if (payload.length === 12) {
    periodSwitch = "month";
  }

  if (payload.length > 0) {
    newPayload = [];
    payload.forEach((entry, i) => {
      const totalCapa = handleCapaWithHolidays({
        dateToCheck: entry.date,
        capacity:
          Object.entries(userCapacity).length > 0 ? userCapacity : undefined,
        period:
          periodSwitch === "day"
            ? "day"
            : periodSwitch === "weekNumber"
            ? "week"
            : "month",
      });

      if (
        DateTime.fromISO(sum.date.split("T")[0])[periodSwitch] ===
        DateTime.fromISO(entry.date.split("T")[0])[periodSwitch]
      ) {
        sum.date = entry.date;
        sum.duration += entry.duration;
        sum.durationBillable += entry.durationBillable;
        sum.break += entry.break;
        sum.capacity = totalCapa;
      } else {
        newPayload.push(sum);
        sum = { ...entry, capacity: totalCapa };
      }
      if (payload.length - 1 === i) {
        let newDate = sum.date;
        const dstPeriodEnd = DateTime.fromISO(newDate).isInDST;
        // Add 1 hour from weekEnd-date if DST changes at end of week
        if (dstPeriodStart && !dstPeriodEnd) {
          newDate = DateTime.fromISO(sum.date).plus({ hours: 1 }).toISO();
        }
        // Subtract 1 hour from weekEnd-date if DST changes at end of week
        if (!dstPeriodStart && dstPeriodEnd) {
          newDate = DateTime.fromISO(sum.date).minus({ hours: 1 }).toISO();
        }
        sum = { ...sum, date: newDate };
        newPayload.push(sum);
      }
    });
  }

  return newPayload;
};

/**
 * Creates a final object for Timetracking Dashboard to show statistics
 * and getSummary data in graphic forms (pie- and barChart)
 * @param param0 {
    billableTotals: BillableTotal[];
    from: string;
    activityTotals: JobTotal[];
    jobTotals: JobTotal[];
    to: string;
  }
 * @returns {
    billableTotals: PopulatedBillableTotal[];
    from: string;
    activityTotals: ActivityTotal[];
    jobTotals: JobTotal[];
    to: string;
  }
 */
export const handleStatisticsByPeriods = ({
  payload,
}: {
  payload: Statistics;
}): StatisticsPiePeriod => {
  const calculatedBillableTotals: PopulatedBillableTotal[] = [];
  let totalDurationForNone = 0;

  payload.billableTotals.forEach((billable) => {
    // First only add information where nonBillableReason is not "None"
    if (billable.totalDuration > 0 && billable.nonBillableReason !== "None") {
      if (billable.totalDurationBillable > 0) {
        /**
         * Add (totalDuration - totalDurationBillable) to totalDurationForNone if
         * totalDurationBillable and totalDuration is greater than 0
         */
        totalDurationForNone += billable.totalDurationBillable;
      }
      calculatedBillableTotals.push({
        description: billable.nonBillableReason,
        totalDuration: billable.totalDuration,
        totalDurationBillable:
          billable.totalDuration - billable.totalDurationBillable,
        nonBillableReason: billable.nonBillableReason,
      });
    }
  });
  const billableReasonNone = payload.billableTotals.find(
    (billable) => billable.nonBillableReason === "None"
  );
  if (billableReasonNone || totalDurationForNone > 0) {
    // Finally add nonBillableReason "None" to calculatedBillableTotals with
    // totlaDuration from all billableReasons
    calculatedBillableTotals.push({
      description: "None",
      totalDuration: billableReasonNone
        ? billableReasonNone.totalDuration + Math.max(totalDurationForNone, 0)
        : 0,
      totalDurationBillable: billableReasonNone
        ? billableReasonNone.totalDurationBillable +
          Math.max(totalDurationForNone, 0)
        : 0,
      nonBillableReason: "None",
    });
  }

  calculatedBillableTotals.sort((a, b) => {
    if (a.totalDuration > b.totalDuration) {
      return -1;
    }
    if (a.totalDuration < b.totalDuration) {
      return 1;
    }
    return 0;
  });

  // Calculate total for 'other' projects
  const totalDurationFromPeriod = payload.billableTotals.reduce(
    (acc, cur) => acc + cur.totalDuration,
    0
  );
  const totalDurationFromJobs = payload.jobTotals.reduce(
    (acc, cur) => acc + cur.totalDuration,
    0
  );
  // Calculate total for 'other' activity
  const totalDurationFromActivities = payload.activityTotals.reduce(
    (acc, cur) => acc + cur.totalDuration,
    0
  );
  const otherJobsDuration = totalDurationFromPeriod - totalDurationFromJobs;
  const otherActivityDuration =
    totalDurationFromPeriod - totalDurationFromActivities;

  let additionJobTotal: JobTotal[] = [
    {
      id: "otherJobs",
      description: "Others",
      totalDuration: otherJobsDuration,
      no: "otherJobs",
    },
  ];
  let additionActivityTotal: ActivityTotal[] = [
    {
      description: "Others",
      totalDuration: otherActivityDuration,
    },
  ];

  // Filter all jobTotals and activityTotals with totalDuration === 0
  const newJobTotals = payload.jobTotals.filter((job) => job.totalDuration > 0);
  const newActivityTotals = payload.activityTotals.filter(
    (activity) => activity.totalDuration > 0
  );

  // Add 'other' projects to jobTotals if condition is true
  if (otherJobsDuration > 0) {
    additionJobTotal = [...newJobTotals, ...additionJobTotal];
  } else {
    additionJobTotal = [...newJobTotals];
  }
  // Add 'other' activity to activityTotals if condition is true
  if (otherActivityDuration > 0) {
    additionActivityTotal = [...newActivityTotals, ...additionActivityTotal];
  } else {
    additionActivityTotal = [...newActivityTotals];
  }

  const pieInfo: StatisticsPiePeriod = {
    billableTotals: calculatedBillableTotals,
    from: payload.from,
    jobTotals: additionJobTotal,
    activityTotals: additionActivityTotal,
    to: payload.to,
  };

  return pieInfo;
};

/**
 * Function to get the information if the capacity-day of the provided date
 * is a holiday or not
 * @param param0
 * @returns Total capa for selected Date
 */
export const handleCapaWithHolidays = ({
  dateToCheck,
  capacity,
  period,
}: {
  dateToCheck: string;
  capacity: Record<string, ResourcePlanning> | undefined;
  period: Periods;
}): number => {
  const date = DateTime.fromISO(dateToCheck);
  const workModelDates: string[] = capacity
    ? Object.keys(capacity).sort((a, b) => {
        return a > b ? 1 : -1;
      })
    : [];
  let totalCapa = 0;
  let workModelStart: string = workModelDates[0];

  // Set the right workModelStart
  workModelDates.forEach((workModelDate) => {
    if (date.toISODate() >= workModelDate) {
      workModelStart = workModelDate;
    }
  });

  const calendar = capacity && capacity[workModelStart].calendar;

  const workModelCalendarDays = calendar
    ? calendar.filter((day) => DateTime.fromISO(day).hasSame(date, "year"))
    : [];

  const recurringHolidays =
    capacity && capacity[workModelStart].recurringHolidays;
  const workModelRecuringHolidays = recurringHolidays
    ? recurringHolidays.map((holiday) =>
        DateTime.fromFormat(holiday, "MMdd")
          .set({ year: date.year })
          .toISODate()
      )
    : [];

  // Create a list of a new Set with all holidays
  const allHolidayDates = Array.from(
    new Set([...workModelCalendarDays, ...workModelRecuringHolidays])
  );

  const weekDays: Days[] = [];
  const weekDates: string[] = [];
  if (period !== "day") {
    const counter =
      period === "month"
        ? date.daysInMonth
        : period === "year"
        ? date.daysInYear
        : period === "workweek"
        ? 5
        : 7;
    for (let i = 0; i < 7; i++) {
      weekDays.push(
        DateTime.now()
          .setLocale("en")
          .startOf("week")
          .plus({ days: i })
          .toFormat("cccc")
          .toLowerCase() as Days
      );
    }
    for (let i = 0; i < counter; i++) {
      const weekDate = date.plus({ days: i }).toISODate();
      weekDates.push(weekDate);
    }

    const weekLoad: WeekLoad = {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    };

    if (period === "month" || period === "week") {
      const userCapa = capacity && capacity[workModelStart].capacity;
      if (userCapa) {
        weekDays.forEach((weekDay) => {
          weekLoad[weekDay] = userCapa[weekDay];
        });

        // Check weekDates with weekLoad (date to day (2023.02.16 -> thursday))
        // get Capa (number) and add it to total, if this day is not a holiday
        weekDates.forEach((weekDate) => {
          const dateIsHoliday = allHolidayDates.includes(weekDate);
          if (!dateIsHoliday) {
            const day = DateTime.fromISO(weekDate)
              .setLocale("en")
              .toFormat("cccc")
              .toLowerCase() as Days;
            totalCapa += weekLoad[day];
          }
        });
      }
    } else {
      // Period === "year"
      // Sort userCapacity by date desc
      const userCapas = capacity
        ? Object.entries(capacity).sort((a, b) => {
            return a[0] < b[0] ? 1 : -1;
          })
        : [];

      weekDates.forEach((date) => {
        // Find the right capacity period for the current date
        const userCapaPeriod = userCapas.find(
          (capa) => DateTime.fromISO(capa[0]) <= DateTime.fromISO(date)
        );
        if (userCapaPeriod) {
          // Get the capacity for the current date
          const userCapa = userCapaPeriod[1].capacity;
          if (userCapa) {
            // Set the capacity for the current week
            weekDays.forEach((weekDay) => {
              weekLoad[weekDay] = userCapa[weekDay];
            });
            const dateIsHoliday = allHolidayDates.includes(date);
            if (!dateIsHoliday) {
              const day = DateTime.fromISO(date)
                .setLocale("en")
                .toFormat("cccc")
                .toLowerCase() as Days;
              totalCapa += weekLoad[day];
            }
          }
        }
      });
    }
  } else {
    const dateIsHoliday = allHolidayDates.includes(date.toISODate());
    if (!dateIsHoliday) {
      const day = date.setLocale("en").toFormat("cccc").toLowerCase() as Days;
      const userCapa = capacity && capacity[workModelStart].capacity;
      if (userCapa) {
        totalCapa = userCapa[day];
      }
    }
  }

  return totalCapa;
};
