import { FunctionComponent } from "react";
// Helper
import { Redirect, Route } from "react-router-dom";
// Dyce-Lib
import { Subjects, useAbility } from "@dyce/auth";
interface IProtectedRouteProps {
  /**
   * Component to render
   */
  component: React.ElementType;
  /**
   * If true, path will be exact
   * @default false
   */
  exact?: boolean;
  /**
   * Path to route
   */
  path: string;
  /**
   * URL domain = "admin" | "timerec" | "resplan"
   */
  domain: Subjects;
}

export const ProtectedRoute: FunctionComponent<IProtectedRouteProps> = ({
  component: Component,
  exact = false,
  path,
  domain,
  ...rest
}) => {
  const { can } = useAbility();

  return (
    <Route
      exact={exact}
      path={path}
      {...rest}
      render={(props) =>
        can("read", domain) ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};
