import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { ThunkAction, ThunkMiddleware } from "redux-thunk";
import {
  configureStore,
  Action,
  AnyAction,
  MiddlewareArray,
  EnhancedStore,
  Middleware,
} from "@reduxjs/toolkit";
// State sync
import { createStateSyncMiddleware } from "redux-state-sync";
// Type
import { RootState } from "../types/types";
// Reducesers
import { handleReducers, ReducerType, SliceProps } from "./rootReducer";
import * as Sentry from "@sentry/react";

let store: EnhancedStore<
  ReducerType,
  AnyAction,
  MiddlewareArray<
    | ThunkMiddleware<any, AnyAction, null>
    | ThunkMiddleware<any, AnyAction, undefined>
    | Middleware<any>
  >
> = {} as any;

const sentryRedux = Sentry.createReduxEnhancer({});

const config = {
  channel: "dyce_foreignBroadcast_channel",
};
const middlewares = [createStateSyncMiddleware(config)];

export const createStore = ({
  selectSlices,
  persistedState,
  syncStore,
  syncAfterRefresh,
}: {
  selectSlices: SliceProps;
  persistedState: any;
  syncStore: boolean;
  syncAfterRefresh: boolean;
}) => {
  const reducers = handleReducers(selectSlices, syncAfterRefresh);

  store = configureStore({
    reducer: reducers,
    preloadedState: persistedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["**/**/rejected"],
        },
      }).concat(syncStore ? [...middlewares] : []),
    enhancers: [sentryRedux],
  });

  return store;
};

// see https://github.com/danr/react-redux-hot-module-reloading-webpack-typescript-boiler/blob/master/src/index.tsx
declare const module: any;

if (process.env["NODE_ENV"] === "development" && module.hot) {
  module.hot.accept("./rootReducer", () => {
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    const newRootReducer = require("./rootReducer").default;
    store.replaceReducer(newRootReducer);
  });
}

// see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// expose store when run in Cypress
(window as any).store = store;

export default store;
