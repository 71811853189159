import { AppDispatch, getBudget } from "@dyce/slices";
import { BudgetValues } from "@dyce/tnt-api";
import { unwrapResult } from "@reduxjs/toolkit";

// Budget
export const handleRecordBudget = async ({
  id,
  levelOfDetail = "jobTask",
  inHours = false,
  dispatch,
}: {
  id: string;
  levelOfDetail?: "jobTask" | "jobPlanningLine";
  inHours?: boolean;
  dispatch: AppDispatch;
}): Promise<BudgetValues> => {
  try {
    const budget = await dispatch(
      getBudget({ id: id, budgetOf: `${levelOfDetail}Id` })
    );
    const budgetUnwrapped = unwrapResult(budget);

    if (inHours) {
      budgetUnwrapped.total = Math.round(budgetUnwrapped.total / 60);
      budgetUnwrapped.used = Math.round(budgetUnwrapped.used / 60);
    }
    return budgetUnwrapped;
  } catch (error) {
    return {
      total: 0,
      used: 0,
    };
  }
};
