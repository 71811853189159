import { FunctionComponent } from "react";
// Helper
import clsx from "clsx";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Grid, Paper, useTheme } from "@mui/material";
import { red, lightBlue, green } from "@mui/material/colors";

// Styles for the paperless notification
const useStyle = makeStyles((theme: DyceTheme) =>
  createStyles({
    dangerBadge: {
      color: red[500],
    },
    infoBadge: {
      color: lightBlue[500],
    },
    successBadge: {
      color: green[500],
    },
    badgeBorder: {
      borderRadius: theme.shape.borderRadius + String(15),
    },
    dangerBadgeBorder: {
      border: `1px solid ${red[500]}`,
    },
    infoBadgeBorder: {
      border: `1px solid ${lightBlue[500]}`,
    },
    successBadgeBorder: {
      border: `1px solid ${green[500]}`,
    },
    text: {
      marginRight: 5,
    },
  })
);

interface ITBeatContainerProps {
  /**
   * If true, children will rendered on <Paper /> component
   */
  onPaper?: boolean;
  /**
   * Variant of border and color
   * @default 'info
   */
  borderVariant?: "info" | "success" | "danger";
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

type TBadgeBorderClassNames =
  | "successBadgeBorder"
  | "infoBadgeBorder"
  | "dangerBadgeBorder";
type TBadgeClassNames = "dangerBadge" | "infoBadge" | "successBadge";

/**
 * UI element that combines status indicator and description into one package
 */
export const BeatContainer: FunctionComponent<ITBeatContainerProps> = ({
  onPaper = false,
  borderVariant = "info",
  children,
}) => {
  const classes = useStyle();
  const theme: DyceTheme = useTheme();

  // provides two versions of the element, with a paper background and a one-line element
  if (onPaper) {
    return (
      <Paper
        variant={theme.palette.propsDyce.paperDesign.variant}
        elevation={theme.palette.propsDyce.paperDesign.elevation}
        style={{
          paddingBottom: 20,
          paddingTop: 20,
        }}
      >
        <Grid container direction="column" alignItems="center" spacing={3}>
          {children}
        </Grid>
      </Paper>
    );
  } else {
    return (
      <Grid
        direction="row"
        spacing={3}
        className={clsx(
          classes.badgeBorder,
          classes[`${borderVariant}BadgeBorder` as TBadgeBorderClassNames],
          classes[(borderVariant + "Badge") as TBadgeClassNames]
        )}
        style={{
          display: "flex",
          width: "max-content",
          paddingRight: 10,
        }}
      >
        <Paper
          variant={theme.palette.propsDyce.paperDesign.variant}
          elevation={theme.palette.propsDyce.paperDesign.elevation}
        >
          {children}
        </Paper>
      </Grid>
    );
  }
};
