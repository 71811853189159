import { FunctionComponent, Suspense, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
// Helper
import { useMsal } from "@azure/msal-react";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { setInvalidGrantLogin, useAppDispatch } from "@dyce/slices";
import { InvalidUser } from "@dyce/interfaces";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
// Components
import { Background, HeroHeader, InvalidGrantInfo, Navbar } from "@dyce/ui";

// For mobile the hero wrapper will always
// stay in middle of the screen
const windowHeight = window.innerHeight;
const setPaddingTop = (windowHeight - 284 - 56) / 2;

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    toolbar: {
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      paddingTop: theme.spacing(6),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down("xs")]: {
        paddingTop: setPaddingTop,
      },
    },
  })
);

interface IHeroHeaderProps {
  /**
   * User info from invalid grant access
   */
  invalidGrant: InvalidUser | null;
}

export const FrontPage: FunctionComponent<IHeroHeaderProps> = ({
  invalidGrant,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { instance } = useMsal();

  // UseEffects
  useEffect(() => {
    if (location.pathname === "/logout") {
      history.push("/");
    }
  }, []);

  useEffect(() => {
    handleInvalidUser();
    handleInvalidGrant();
  }, []);

  /**
   * Handle invalid user
   * Account is in AAD but not as user in DYCE
   */
  const handleInvalidUser = async () => {
    const userNotFound = await localStorage.getItem("userNotFound");
    if (userNotFound) {
      dispatch(setInvalidGrantLogin(JSON.parse(userNotFound)));
      localStorage.removeItem("userNotFound");
    }
  };

  /**
   * Handle invalid grant
   * Account is not in AAD from DYCE
   */
  const handleInvalidGrant = async () => {
    const invalidGrantInstance = await localStorage.getItem(
      "invalidGrantInstance"
    );
    if (invalidGrantInstance) {
      const { name, email, invalidGrant } = JSON.parse(invalidGrantInstance);
      dispatch(setInvalidGrantLogin({ name, email, invalidGrant }));
      localStorage.removeItem("invalidGrantInstance");
    }
  };

  return (
    <>
      <Suspense fallback="">
        <Navbar
          showInstrumentation={false}
          location={location.pathname}
          env={{
            instance: "",
            company: "",
            instances: [],
          }}
        />
        <div className={classes.content}>
          {!invalidGrant ? (
            <>
              <div className={classes.toolbar} />
              <HeroHeader
                title="DYCE"
                subtitle="Dynamic Cloud Experience"
                instance={instance}
              />
            </>
          ) : (
            <InvalidGrantInfo
              loginOptions={{
                aadScope: process.env["NX_API_SCOPE"] as string,
                instance: instance,
              }}
              invalidGrant={invalidGrant}
            />
          )}
        </div>
      </Suspense>
      <Background white={!invalidGrant} />
    </>
  );
};
