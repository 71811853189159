import { createSelector } from "@reduxjs/toolkit";
import { templatesAsTwoDim } from "./utils";
import { CategoriesWithTemplates, RecordTemplate } from "@dyce/tnt-api";
import { RootState } from "../../types/types";

export const selectTemplates = (state: RootState) => state.templates.entities;
export const selectTemplatesAsArray = createSelector(
  selectTemplates,
  (templates) => Object.values(templates)
);
export const selectRequestsTemplates = (state: RootState) =>
  state.templates.requests;

export const isLoadingEntriesTemplates = createSelector(
  (state: RootState) => state.templates,
  (templates) => templates.isLoadingEntries
);

export const selectTemplateById = createSelector(
  selectTemplates,
  (_: any, id: string) => id,
  (templates, id) => templates[id]
);

export const selectAnyTemplateRequestPending = createSelector(
  selectRequestsTemplates,
  (requests) => requests.length > 0
);

export const templatesAsTwoDimArray = createSelector(
  selectTemplatesAsArray,
  (template) => {
    return templatesAsTwoDim(template as RecordTemplate[]);
  }
);

/**
 * Select all categories as an object
 */
export const selectCategories = (state: RootState) =>
  state.templates.categories;

/**
 * Select all categories as an array
 */
export const selectCategoriesAsArray = createSelector(
  selectCategories,
  (categories) =>
    Object.values(categories).sort((a, b) => {
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1;
      }
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1;
      }
      return 0;
    })
);

/**
 * Selects all Categories with their entries
 */
export const selectCategoriesWithEntries = createSelector(
  selectCategoriesAsArray,
  selectTemplatesAsArray,
  (categories, templates) => {
    const results: CategoriesWithTemplates = {};

    categories.forEach((cat) => {
      const entries = templates.filter((t) => t.category.id === cat.id);

      results[cat.id] = entries;
    });

    return results;
  }
);

/**
 * Selects one category by name
 */
export const selectCategoryByName = createSelector(
  selectCategories,
  (_: any, name: string) => name,
  (categories, id) => categories[id]
);
