import { FunctionComponent, MutableRefObject } from "react";
// Helper
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
// Dyce-Lib
import {
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  StatusOptions,
} from "@dyce/tnt-api";
// Components
import {
  DescriptionField as DescField,
  IExpandButtonProps,
} from "../../inputs/descriptionField/descriptionField";

const textFieldBig = {
  width: "100%",
  display: "flex",
  zIndex: 1,
  resize: "none",

  "& .MuiInputBase-input": {
    height: "113px !important",
  },
};

interface IDescriptionFieldProps {
  /**
   * Number of sectionRows
   */
  rows?: number;
  /**
   * Define tabIndex to focus correct field
   */
  tabIndex?: number;
  /**
   * TimeInput object
   */
  timeInput: RecordEntry & Partial<RecordTemplate & RecordTimeRec>;
  /**
   * CSS style for common text field styling
   */
  commonTextFieldStyles: string;
  /**
   * Props for expand button
   */
  expandButton: IExpandButtonProps;
  /**
   * Callback fired when value changes (onChange)
   */
  handleDirtyField: () => void;
  /**
   * If true, components will be disabled;
   */
  pending: boolean;
  /**
   * Callback fired onBlur event, to save reference to state
   * @param ref MutableRefObject<HTMLInputElement | null>
   * @returns void
   */
  onInputBlur?: (ref: MutableRefObject<HTMLInputElement | null>) => void;
  /**
   * If true, the input element will be focused during the first mount
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Define size of textfield
   */
  size?: "small" | "medium";
  /**
   * If the border is set, background color is set to theme.palette.background.default
   */
  borderActive?: boolean;
}

export const DescriptionField: FunctionComponent<IDescriptionFieldProps> = ({
  rows,
  tabIndex,
  expandButton,
  timeInput,
  autoFocus = false,
  commonTextFieldStyles,
  handleDirtyField,
  pending,
  size,
  borderActive,
  onInputBlur,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name="description"
      control={control}
      render={({ field: { onChange, onBlur, ref } }) => (
        <DescField
          rows={rows}
          refHook={ref}
          testId={"description"}
          onChange={onChange}
          size={size}
          borderActive={borderActive}
          onBlur={(_, ref) => {
            onInputBlur && onInputBlur(ref);
            onBlur;
          }}
          autoFocus={autoFocus}
          isDisabled={
            (timeInput.status && timeInput.status !== StatusOptions.OPEN) ||
            pending
          }
          expandButton={expandButton}
          tabIndex={tabIndex}
          handleDirtyField={handleDirtyField}
          value={timeInput.description}
          style={classNames(textFieldBig, commonTextFieldStyles)}
          label={t("timerec.dailyRecording.add.description")}
        />
      )}
      defaultValue={timeInput.description}
    />
  );
};
