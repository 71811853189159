import { FunctionComponent, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { RefreshButton } from "../refresh-button/refresh-button";
// MUI
import { Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { ErrorOutline } from "@mui/icons-material";

interface IErrorProps {
  /**
   * UUID for INSIGHTS
   */
  uuid: string;
}

const useStyles = makeStyles(() => ({
  rootContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexFlow: "row",
    width: "100%",
  },
  messageContainer: {
    maxWidth: 420,
    verticalAlign: "center",
  },
  header: {
    marginBottom: "20px !important",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3rem",
  },
}));

export const ErrorMessage: FunctionComponent<IErrorProps> = ({ uuid }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  return (
    <div className={classes.rootContainer}>
      <div className={classes.messageContainer}>
        <ErrorOutline style={{ fontSize: 48 }} />
        <Typography variant="h2" className={classes.header}>
          {t("error.message.title")}
        </Typography>
        <Typography>
          {t("error.message.body")}
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={() => setOpen(false)}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title="Copied"
          >
            <b
              onClick={() => {
                navigator.clipboard.writeText(uuid);
                setOpen(true);
              }}
              style={{ cursor: "pointer" }}
            >
              {uuid}
            </b>
          </Tooltip>
        </Typography>
        <div className={classes.buttonContainer}>
          <RefreshButton buttonText={t("error.message.refresh")} />
        </div>
      </div>
    </div>
  );
};
