import { FunctionComponent, useState } from "react";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Divider, Fade, IconButton, Typography } from "@mui/material";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      transition: "all 0.5s ease",
      width: "100%",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: "1rem",
      paddingRight: "0.5rem",
      justifyContent: "space-between",
      width: "100%",
      cursor: "pointer",
      alignItems: "flex-end",
    },
    headerTitle: {},
    headerCollapsedInfo: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      gap: "1rem",
      flexWrap: "nowrap",
      width: "100%",
      paddingRight: "0.5rem",
    },
  })
);

interface ICollapsibleContainerProps {
  /**
   * Header title
   */
  title: string;
  /**
   * Info from values of collapsed components
   */
  collapsedInfo?: string[];
  /**
   * Show Icon at very end of component
   */
  infoIcon?: JSX.Element;
  /**
   * If true, container is shown open initial
   */
  initialOpen?: boolean;
  /**
   * Define size of textfield
   * @default "medium"
   */
  size?: "small" | "medium";
  /**
   * Callback function for onClick event
   * @param value Current state of collapsible
   * @returns void
   */
  onClickCollapsible?: (value: boolean) => void;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const CollapsibleContainer: FunctionComponent<
  ICollapsibleContainerProps
> = ({
  title,
  collapsedInfo,
  infoIcon,
  initialOpen = false,
  size = "medium",
  onClickCollapsible,
  children,
}) => {
  const classes = useStyles();

  // UseStates
  const [isOpen, setIsOpen] = useState<boolean>(initialOpen);

  // Handler
  const handleOnClickHeader = () => {
    onClickCollapsible && onClickCollapsible(!isOpen);
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={classes.container}
      style={{
        transition: "height 0.25s ease-in-out",
        height: isOpen
          ? size === "small"
            ? "115px"
            : "139px"
          : size === "small"
          ? "43px"
          : "51px",
        paddingBottom: size === "small" ? "0.5rem" : "1rem",
      }}
    >
      <div className={classes.header} onClick={handleOnClickHeader}>
        <Typography
          noWrap
          width={250}
          sx={{
            userSelect: "none",
          }}
          variant={size === "small" ? "body2" : "body1"}
        >
          {title}
        </Typography>
        <div
          className={classes.headerCollapsedInfo}
          style={{
            opacity: infoIcon?.type === "div" ? 1 : isOpen ? 0 : 1,
            transition: "opacity 0.2s ease-in-out",
          }}
        >
          {collapsedInfo &&
            collapsedInfo.map((info, index) => (
              <Typography
                key={`${info}_${index}`}
                variant="caption"
                sx={{
                  userSelect: "none",
                }}
              >
                {info}
              </Typography>
            ))}
          {infoIcon && <>{infoIcon}</>}
        </div>
        {isOpen ? (
          <IconButton size="small">
            <KeyboardArrowUpOutlinedIcon />
          </IconButton>
        ) : (
          <IconButton size="small">
            <KeyboardArrowDownOutlinedIcon />
          </IconButton>
        )}
      </div>
      <Divider color="lightblue" />
      <Fade in={isOpen} timeout={{ appear: 700, enter: 700, exit: 200 }}>
        <div>{children}</div>
      </Fade>
    </div>
  );
};
