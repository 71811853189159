import { RootState } from "../../types/types";
import { selectIsLoading } from "../settings/settingsSelector";
import { selectIsCurrentUserLoading } from "../user/userSelector";
import { createSelector } from "reselect";

export const selectInstanceError = (state: RootState) => state.network.status;
export const selectAllowNetworkWatcher = (state: RootState) =>
  state.network.allowNetworkWatcher;

export const selectIsEnvironmentLoaded = createSelector(
  [selectIsCurrentUserLoading, selectIsLoading],
  (loadingUser, loadingSettings) => {
    return Boolean(loadingUser === false && !loadingSettings);
  }
);
