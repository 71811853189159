// Helper
import { DateTime } from "luxon";
// Dyce-Lib
import { RecordTemplate, RecordEntry } from "@dyce/tnt-api";

/**
 * Function to prepare Template object to save as Timerecording with correct start,
 * end and date
 * @param newDate Selected date from DatePicker calendar
 * @param templateEntry RecordTemplate object from selected template
 * @returns RecordEntry object for Timerecordings
 */
export const prepareTemplateForTimeRec = (
  newDate: DateTime,
  templateEntry: RecordTemplate
): RecordEntry => {
  // Mutate start && end to new date
  const newStartDate = templateEntry.start
    ? DateTime.fromISO(templateEntry.start)
        .set({
          year: newDate.year,
          month: newDate.month,
          day: newDate.day,
        })
        .toISO()
    : null;

  let newEndDate: string | null = null;

  if (templateEntry.start && templateEntry.end) {
    // If end is 00:00 (end of day), day needs to be +1
    if (
      DateTime.fromISO(templateEntry.start).day <
      DateTime.fromISO(templateEntry.end).day
    ) {
      newEndDate = DateTime.fromISO(templateEntry.end)
        .set({
          year: newDate.year,
          month: newDate.month,
          day: newDate.day,
        })
        .plus({ days: 1 })
        .toISO();
    } else {
      newEndDate = DateTime.fromISO(templateEntry.end)
        .set({
          year: newDate.year,
          month: newDate.month,
          day: newDate.day,
        })
        .toISO();
    }
  }

  // Prepare object to save as Timerecording
  const timeRec = {
    ...templateEntry,
    start: newStartDate,
    end: newEndDate,
    date: newDate.toISO(),
  };
  delete (timeRec as any)["@odata.context"];
  delete (timeRec as any)["category"];
  delete (timeRec as any)["created"];
  delete (timeRec as any)["modified"];
  delete (timeRec as any)["id"];

  return timeRec;
};
