import {
  Customer,
  PopulatedJob,
  PopulatedJobTask,
  PopulatedJobPlanningLine,
  TntModelLine,
  StatusOptions,
} from "@dyce/tnt-api";

/**
 * Authentication
 */
export type InvalidUser = {
  name: string;
  email: string;
  invalidGrant?: boolean;
};

/**
 * Time-Tracking-Tool
 */
export enum DesignOptions {
  ALL = "all",
  COMPACT = "compact",
  SIMPLE = "simple",
  DYNAMIC = "dynamic",
  MOBILE_SIMPLE = "mobile-simple",
}

export type ToolStyle = {
  inputSection: SectionRow;
  moreOptions?: SectionRow;
};

type SectionRow = {
  sectionRows: SectionRowType[];
};

export type SectionRowType = {
  /**
   * Define if the section is collapsible, if yes, provide the header and info text/icon
   */
  collapsible?: CollapsibleInfo;
  wrappers: StyledComponents[];
  /**
   * Define rows for the section, affect flex wrapping and description-filed height
   */
  rows?: 1 | 2;
  /**
   * Please provide this prop only once, else it will be overwritten from last entry
   */
  autoFocus?: FieldTypes;
};

type CollapsibleInfo = {
  header: CollapsibleHeader;
  infoText: boolean;
  infoIcon: boolean;
  allowInitialOpen: boolean;
};

export type CollapsibleHeader = "mapping" | "billable" | string;

type StyledComponents = {
  components: FieldComponentType[];
  width?: {
    small: string;
    medium: string;
  };
};

export type FieldComponentType = {
  compName: FieldTypes;
  tabIndex: number | undefined;
};

export type FieldTypes =
  | "start"
  | "end"
  | "duration"
  | "break"
  | "description"
  | "customer"
  | "job"
  | "jobTask"
  | "jobPlanningLine"
  | "date"
  | "durationBillable"
  | "nonBillableReason"
  | "hidden";

/**
 * Date-Pagination
 */
export type Periods = "day" | "workweek" | "week" | "month" | "year";

/**
 * LookUp-Fields
 */
export type LookUpMapping = {
  customer: Customer | null;
  job: PopulatedJob | null;
  jobTask: PopulatedJobTask | null;
  jobPlanningLine: PopulatedJobPlanningLine | null;
  tntModelLine: TntModelLine | null;
  status?: StatusOptions;
};

export enum LookUpOptions {
  CUSTOMER = "customer",
  JOB = "job",
  JOBTASK = "jobTask",
  JOBPLANNINGLINE = "jobPlanningLine",
}

export enum LookUpActivityOptions {
  CUSTOMER = "customer",
  JOB = "job",
  JOBTASK = "jobTask",
  ACTIVITY = "activity",
}

export enum LookUpShortOptions {
  CUSTOMER = "customer",
  JOB = "job",
  JOBTASK = "jobTask",
}

export type MappingFieldTypes =
  | Customer
  | PopulatedJobTask
  | PopulatedJob
  | PopulatedJobPlanningLine;

export type ComponentTheme =
  | "muiComponent"
  | "azureComponent"
  | "jiraComponent";

export type SuggestionOptions = {
  id: string;
  value: string;
};

/**
 * Calendar
 */
export enum CalendarRepeatType {
  NONE = "None",
  DAILY = "Daily",
  WEEKLY = "Weekly",
  MONTHLY = "Monthly",
  YEARLY = "Yearly",
  CUSTOM = "Custom",
}

/**
 * Jira
 */
// Define the status of the Jira-Webhook
export enum WebHookState {
  INITIALIZE = 0,
  NEW = 1,
  CREATED = 2,
  WEBHOOK_MISSING = 3,
  SOMETHING_WENT_WRONG = 4,
}

export type ResourceList = {
  /**
   * The name of the resource (user)
   */
  resourceName: string;
  /**
   * The id of the resource (user)
   */
  resourceId: string;
};
