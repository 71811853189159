// Helper
import i18next from "i18next";
import { FormatterProps } from "react-data-grid";
// Dyce-Lib
import {
  JobTaskStatusOptions,
  Activity,
  NonBillableReasonOptions,
} from "@dyce/tnt-api";
import { IDatagridColumn, IDatagridRow } from "@dyce/ui";
import { MonetizationOffOutlinedIcon } from "@dyce/ui-assets";
// MUI
import { teal } from "@mui/material/colors";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";

const createColumns = (): Record<string, IDatagridColumn> => {
  // Specify column header in key: "name" and change editable to true if needed
  return {
    description: {
      name: i18next.t(
        "components.lookup.columnHeader.jobPlanningLine.description"
      ),
      key: "description",
      editable: true,
      width: 0.3,
      searchable: true,
      display: true,
    },
    jobTaskDescription: {
      name: i18next.t(
        "components.lookup.columnHeader.jobPlanningLine.jobTaskDescription"
      ),
      key: "jobTaskDescription",
      editable: true,
      searchable: false,
      display: true,
      width: 0.26,
    },
    jobPlanningLineDescription: {
      name: i18next.t(
        "components.lookup.columnHeader.jobPlanningLine.jobPlanningLineDescription"
      ),
      key: "jobPlanningLineDescription",
      editable: true,
      searchable: false,
      display: true,
    },
    billableInfo: {
      name: "",
      key: "billableInfo",
      editable: false,
      searchable: false,
      display: true,
      sortable: false,
      width: 0.01,
      formatter: (x: FormatterProps<any, unknown>) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "0.4rem",
              color: x.row.billableInfo ? teal["A400"] : "",
            }}
          >
            {x.row.skells ? (
              ""
            ) : x.row.billableInfo ? (
              <MonetizationOnOutlinedIcon />
            ) : (
              <MonetizationOffOutlinedIcon fill="grey" />
            )}
          </div>
        );
      },
    },
  };
};

function createRows<T>(entries: Activity[]): IDatagridRow<T>[] {
  // set column value with specified DataBase column
  const row: IDatagridRow[] = entries.map((entry) => {
    return {
      id:
        entry.jobPlanningLine.id.length === 72
          ? entry.jobPlanningLine.id
          : entry.jobPlanningLine.id + entry.tntModelLine.id,
      cells: {
        description: entry.tntModelLine ? entry.tntModelLine.description : "",
        jobTaskDescription: entry.jobTask ? entry.jobTask.description : "",
        jobPlanningLineDescription: entry.jobPlanningLine
          ? entry.jobPlanningLine.description
          : "",
        serviceBillingType: entry.jobPlanningLine
          ? entry.jobPlanningLine.serviceBillingType
          : null,
        tntId: entry.tntModelLine ? entry.tntModelLine.id : "",
        jplId: entry.jobPlanningLine.id,
        no: entry.no ? entry.no : 0,
        tntModelLine: {
          tntId: entry.tntModelLine ? entry.tntModelLine.id : null,
          description: entry.tntModelLine
            ? entry.tntModelLine.description
            : null,
          billable: entry.tntModelLine ? entry.tntModelLine.billable : false,
          nonBillableReason: entry.tntModelLine
            ? entry.tntModelLine.nonBillableReason
            : NonBillableReasonOptions.NONE,
          rounding: entry.tntModelLine ? entry.tntModelLine.rounding : 1,
        },
        skells: false,
        jobTask: {
          id: entry.jobTask ? entry.jobTask.id : "",
          description: entry.jobTask ? entry.jobTask.description : "",
          no: entry.jobTask ? entry.jobTask.no : "",
          status: entry.jobTask
            ? entry.jobTask.status
            : JobTaskStatusOptions.OPEN,
        },
        billableInfo: entry.tntModelLine ? entry.tntModelLine.billable : false,
      },
    };
  });

  return row;
}

const createSkeletonRows = (count: number): IDatagridRow<any>[] => {
  const row: IDatagridRow<any>[] = [...Array(count)].map((_, index) => {
    return {
      id: index.toString(),
      cells: {
        description: "",
        jobTaskDescription: "",
        jobPlanningLineDescription: "",
        tntId: "",
        serviceBillingType: "",
        jplId: index.toString(),
        tntModelLine: {
          tntId: "",
          description: "",
          billable: false,
          nonBillableReason: NonBillableReasonOptions.NONE,
          rounding: 1,
        },
        skells: true,
        jobTask: {
          id: "",
          description: "",
          no: "",
          status: "Open",
        },
        billableInfo: "",
      },
    };
  });

  return row;
};

const getSqlColumnName = (
  sortColumn: string,
  sortDirection: string
): string => {
  const sqlColumn = {
    description: "tntModelLine/description",
    jobTaskDescription: "jobTask/description",
    jobPlanningLineDescription: "jobPlanningLine/description",
    default: "no",
  };

  return `${
    sqlColumn[sortColumn as "description" | "jobTaskDescription"] ||
    sqlColumn["default"]
  } ${sortDirection}`;
};

export default {
  createColumns,
  createRows,
  createSkeletonRows,
  getSqlColumnName,
};
