import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { SetupLanguages } from "@dyce/slices";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Tooltip as MaterialTooltip,
  Typography,
  useTheme,
} from "@mui/material";
// Components
import { useTooltipContent } from "./tooltip-context";

const useStyle = makeStyles(() =>
  createStyles({
    title: {
      fontSize: 8,
      padding: 0,
      whiteSpace: "pre-line", // with \n in translation files line get break
    },
    link: {
      fontSize: 10,
      padding: 0,
      textDecoration: "none",
      fontStyle: "italic",
    },
  })
);

export interface ITooltipProps {
  /**
   * String or JSX.Elements: Provide only Fragments with Text and HTML-Code
   * e.g.: (<>Text &#8723;</>)
   */
  header?: string | JSX.Element;
  /**
   * String or JSX.Elements: Provide only Fragments with Text and HTML-Code
   * e.g.: (<>Text &#8723;</>)
   */
  label: string | JSX.Element;
  /**
   * Provide url link substring from /static/doc/docuLinks.json
   */
  urlPath: Record<SetupLanguages, string>;
  /**
   * Boolean, disable portal or not (The children will be under the DOM hierarchy of the parent component.)
   * @default false
   */
  disablePortal?: boolean;
  /**
   * Boolean if tooltip should follow cursor or not.
   * @default false
   */
  followCursor?: boolean;
  /**
   * Offset in pixels of the tooltip relative to its reference element.
   */
  modifierOffsets?: [number, number];
  /**
   * provide position where tooltip should be shown.
   * @default "bottom"
   */
  position?:
    | "top-start"
    | "top"
    | "top-end"
    | "right-start"
    | "right"
    | "right-end"
    | "bottom-start"
    | "bottom"
    | "bottom-end"
    | "left-start"
    | "left"
    | "left-end";
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const Tooltip: FunctionComponent<ITooltipProps> = ({
  header,
  label,
  urlPath,
  position = "bottom",
  disablePortal = false,
  followCursor = false,
  modifierOffsets,
  children,
}) => {
  const { baseUrl, language = "en" } = useTooltipContent();

  const classes = useStyle();
  const { t } = useTranslation();
  const theme = useTheme<DyceTheme>();

  // English has no subfolder in url path
  const urlLanguage = language === "en" ? "" : `${language}/`;
  const url = `https://docs.dyce.cloud/${urlLanguage}docs/${baseUrl}${urlPath[language]}`;

  return (
    <MaterialTooltip
      PopperProps={{
        disablePortal,
        modifiers: modifierOffsets
          ? [
              {
                name: "offset",
                enabled: true,
                options: {
                  offset: modifierOffsets,
                },
              },
            ]
          : undefined,
      }}
      title={
        <>
          {header && (
            <Typography className={classes.title} variant="subtitle2">
              {header}
            </Typography>
          )}
          <Typography className={classes.title} variant="caption">
            {label}
          </Typography>
          <br />
          {urlPath && urlPath[language].length > 0 && (
            <Typography className={classes.link} variant="caption">
              <a
                target="_blank"
                className={classes.link}
                style={{
                  color: theme.palette.primary.contrastText,
                }}
                href={url}
                rel="noreferrer"
              >
                {t("tooltip.readMore")}
              </a>
            </Typography>
          )}
        </>
      }
      disableInteractive={
        urlPath !== undefined && urlPath[language].length === 0
      }
      arrow
      placement={position}
      followCursor={followCursor}
    >
      {children as any}
    </MaterialTooltip>
  );
};
