import { createSelector } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { ResourceCapacity, Days, WeekLoad } from "@dyce/tnt-api";
import { RootState } from "../../types/types";

export const selectCapacityFromToday = (state: RootState) => {
  return state.resources.currentUser["capacity"];
};

export const selectCurrentUserResources = (state: RootState) =>
  state.resources.currentUser;

export const selectAllResources = (state: RootState) =>
  state.resources.entities;

export const selectCurrentResource = (state: RootState) =>
  state.resources.currentResource;

export const selectCapacity = createSelector(
  selectCurrentUserResources,
  (user) => {
    const capacityArray = Object.keys(user).sort(
      (a, b) =>
        DateTime.fromFormat(a, "yyyy-MM-dd").toMillis() -
        DateTime.fromFormat(b, "yyyy-MM-dd").toMillis()
    );
    let entry: string | undefined = undefined;
    capacityArray.forEach((e) => {
      const compareDate = DateTime.now();
      if (compareDate >= DateTime.fromJSDate(new Date(e))) {
        entry = DateTime.fromJSDate(new Date(e)).toISODate();
      }
    });

    if (!entry) return null;

    return user[entry].capacity;
  }
);

/**
 * Function to get the capacity for each day of the selected week
 * @param param0 { dateFromWeek: "2021-05-03" } - needs a date from the week you want to get the capacity for each day
 * @returns Object with weekdays as keys and capacity as values
 */
export const selectCurrentWeekCapacity = ({
  dateFromWeek,
}: {
  dateFromWeek: string;
}) =>
  createSelector(selectCurrentUserResources, (user): WeekLoad => {
    // Get all capacity dates
    const capacityArray = Object.keys(user).sort(
      (a, b) =>
        DateTime.fromFormat(a, "yyyy-MM-dd").toMillis() -
        DateTime.fromFormat(b, "yyyy-MM-dd").toMillis()
    );
    const weekStart = DateTime.fromFormat(dateFromWeek, "yyyy-MM-dd").startOf(
      "week"
    );
    const weekDates: string[] = []; // ["2021-05-03", "2021-05-04", ...]
    for (let i = 0; i < 7; i++) {
      // Fill weekDates with ISO dates for current week (weekStart)
      weekDates.push(weekStart.plus({ days: i }).toISODate());
    }

    const weekLoad: WeekLoad = {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    };

    weekDates.forEach((day) => {
      let capaDateForDay = "";
      // Find the latest capacity date before the current day
      capacityArray.forEach((capaDate) => {
        const weekDate = DateTime.fromFormat(day, "yyyy-MM-dd");
        const capaPeriod = DateTime.fromFormat(capaDate, "yyyy-MM-dd");
        if (weekDate >= capaPeriod) {
          capaDateForDay = capaDate;
        }
      });
      const dayCapacity = user[capaDateForDay];

      // Get weekDay for current day ('monday', 'tuesday', ...)
      const weekDay = DateTime.fromFormat(day, "yyyy-MM-dd")
        .setLocale("en")
        .toFormat("cccc")
        .toLowerCase() as Days;
      const capaForWeekDay = dayCapacity.capacity
        ? dayCapacity.capacity[weekDay]
        : 0;
      // Set capacity for current day in selected week
      weekLoad[weekDay] = capaForWeekDay;
    });

    return weekLoad;
  });

export const selectCapacities = createSelector(
  selectCurrentUserResources,
  (user): ResourceCapacity[] => {
    // Get all capacity dates
    const capacityArray = Object.keys(user).sort(
      (a, b) =>
        DateTime.fromFormat(a, "yyyy-MM-dd").toMillis() -
        DateTime.fromFormat(b, "yyyy-MM-dd").toMillis()
    );

    const weekDays: Days[] = []; // ["monday", "tuesday", ...]
    for (let i = 0; i < 7; i++) {
      weekDays.push(
        DateTime.now()
          .setLocale("en")
          .startOf("week")
          .plus({ days: i })
          .toFormat("cccc")
          .toLowerCase() as Days
      );
    }

    const weekLoad: WeekLoad = {
      monday: 0,
      tuesday: 0,
      wednesday: 0,
      thursday: 0,
      friday: 0,
      saturday: 0,
      sunday: 0,
    };

    const capacities: ResourceCapacity[] = [];

    capacityArray.forEach((cappaDay) => {
      const capa = user[cappaDay].capacity;
      if (capa) {
        weekDays.forEach((weekDay) => {
          weekLoad[weekDay] = capa[weekDay];
        });
        capacities.push({
          periodStart: cappaDay,
          capacity: { ...weekLoad },
        });
      } else {
        capacities.push({
          periodStart: cappaDay,
          capacity: { ...weekLoad },
        });
      }
    });

    return capacities;
  }
);

export const selectResourceCalendar = createSelector(
  selectCurrentUserResources,
  (user) => {
    const capacityArray = Object.keys(user).sort(
      (a, b) =>
        DateTime.fromFormat(a, "yyyy-MM-dd").toMillis() -
        DateTime.fromFormat(b, "yyyy-MM-dd").toMillis()
    );

    const entry = capacityArray.find(
      (e) => DateTime.now() >= DateTime.fromJSDate(new Date(e))
    );

    if (!entry) {
      return [];
    }

    return user[entry].calendar;
  }
);

export const selectRecurringHolidays = (currentYear: string) =>
  createSelector(selectCurrentUserResources, (user) => {
    const capacityArray = Object.keys(user).sort(
      (a, b) =>
        DateTime.fromFormat(a, "yyyy-MM-dd").toMillis() -
        DateTime.fromFormat(b, "yyyy-MM-dd").toMillis()
    );

    const entry = capacityArray.find(
      (e) => DateTime.now() >= DateTime.fromJSDate(new Date(e))
    );

    if (!entry) {
      return [];
    }

    const recurringHolidays = user[entry].recurringHolidays;
    const formatedRecurringHolidays = recurringHolidays
      ? recurringHolidays.map((date) =>
          DateTime.fromFormat(date, "MMdd")
            .plus({ years: parseInt(currentYear) - DateTime.now().year })
            .toISODate()
        )
      : [];

    return formatedRecurringHolidays;
  });
