import React, { Component } from "react";
// Dyce-Lib
import { CurrentUser, Resource } from "@dyce/tnt-api";
// Helper
import { v4 as uuid } from "uuid";
import * as Sentry from "@sentry/react";
export interface ErrorBoundaryProps {
  /**
   * Current user
   */
  user: CurrentUser;
  /**
   * Instance as string
   */
  instance: string;
  /**
   * Resource from user
   */
  resource: Resource;
  onError: (err: Error, uuid: string | null) => React.ReactElement;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

type ErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
  errorUuid: string | null;
};

export class ErrorBoundary extends Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorUuid: null };
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  override componentDidCatch(error: Error, info: any): void {
    const id = uuid();

    console.log("caught", error);

    this.setState({ hasError: true, error, errorUuid: id });

    Sentry.setContext("resource", this.props.resource);
    Sentry.setContext("instance", { name: this.props.instance });
    Sentry.captureException(error);
  }

  override render() {
    if (this.state.hasError) {
      const { onError } = this.props;
      const { error, errorUuid } = this.state;

      return onError(error as Error, errorUuid);
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
