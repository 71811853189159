import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { Tooltip } from "../../tooltip/tooltip";
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Collapse,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    menuText: {
      "& .MuiTypography-body1": {
        fontSize: theme.typography.body2.fontSize,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: theme.typography.body2.fontWeight,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
      },
    },
  })
);

interface CollapseMenuItemProps {
  /**
   * Name of the item
   */
  title: string;
  /**
   * Icon at the beginning
   */
  icon: React.ReactNode;
  /**
   * Wether the item is collapsed
   */
  collapsed: boolean;
  /**
   * Optional help context
   */
  tooltip?: string;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const CollapseMenuItems: React.FC<CollapseMenuItemProps> = ({
  title,
  icon,
  collapsed,
  tooltip,
  children,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // States
  const [toggle, setToggle] = useState(true);

  const expandedTooltip = () => {
    if (toggle) {
      return <>{tooltip} &#5123;</>;
    } else {
      return <>{tooltip} &#5121;</>;
    }
  };

  const kids = (
    <>
      <ListItemButton onClick={() => setToggle(!toggle)}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={t(title)} className={classes.menuText} />
        {toggle ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
    </>
  );

  const rest = (
    <>
      {!collapsed && <Divider />}
      <Collapse in={toggle} timeout="auto" unmountOnExit>
        {children}
        {!collapsed && <Divider />}
      </Collapse>
    </>
  );

  return (
    <>
      {tooltip ? (
        <>
          <Tooltip
            label={expandedTooltip()}
            position="right"
            urlPath={{ en: "", de: "" }}
          >
            <span>{kids}</span>
          </Tooltip>
          {rest}
        </>
      ) : (
        <>
          {kids}
          {rest}
        </>
      )}
    </>
  );
};
