import React, { FunctionComponent } from "react";
// MUI
import { Box } from "@mui/material";

interface IHiddenComponentsProps {
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const HiddenComponents: FunctionComponent<IHiddenComponentsProps> = ({
  children,
}) => {
  return <Box sx={{ display: "none" }}>{children}</Box>;
};
