import { DateTime } from "luxon";

/**
 * Function to build the date format for the group header
 * @param plannedDate Date of the task as DateTime object
 * @param language Selected language from user settings e.g. "de" or "en"
 * @returns Finale string for the group header
 */
export const createGroupHeaderDateFormat = (
  plannedDate: DateTime,
  language: string
): string => {
  const calendarWeekShort = language === "de" ? "KW" : "CW";
  const weekStartDate = plannedDate.startOf("week");
  const weekEndDate = plannedDate.endOf("week");
  const weekNumber = plannedDate.weekNumber;

  if (weekStartDate.hasSame(weekEndDate, "month")) {
    return `${weekStartDate.toLocaleString({
      day: "numeric",
    })} - ${weekEndDate.toLocaleString({
      day: "numeric",
      month: "long",
      year: "numeric",
    })} – ${calendarWeekShort} ${weekNumber}`;
  } else if (weekStartDate.hasSame(weekEndDate, "year")) {
    return `${weekStartDate.toLocaleString({
      day: "numeric",
      month: "long",
    })} - ${weekEndDate.toLocaleString({
      day: "numeric",
      month: "long",
      year: "numeric",
    })} – ${calendarWeekShort} ${weekNumber}`;
  } else {
    return `${weekStartDate.toLocaleString({
      day: "numeric",
      month: "long",
      year: "numeric",
    })} - ${weekEndDate.toLocaleString({
      day: "numeric",
      month: "long",
      year: "numeric",
    })} – ${calendarWeekShort} ${weekNumber}`;
  }
};
