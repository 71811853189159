import { CalendarEntry } from "@dyce/tnt-api";
import { DateTime } from "luxon";
import { ResourcePlanning } from "../../types/types";

/**
 * Filters the work calendar for work days
 * @param input The calendar
 * @returns A date string array of workdays
 */
export const getDateArray = (input?: CalendarEntry[]): string[] => {
  if (!input) {
    return [];
  }
  const returnValues: string[] = [];
  input.forEach((e) => {
    const d = e.date.split("T")[0];
    return !e.isWorking && returnValues.push(d);
  });

  return returnValues;
};

/**
 * Extract the correct Capacity allocation for each day
 * @param date Date to check
 * @param defaults Collection of ResourcePlannings
 * @returns either a ResourcePlanning or null if the date is out of bounds
 */
export const getDefaultFromDate = (
  date: Date,
  defaults: Record<string, ResourcePlanning>
): ResourcePlanning | null => {
  const capacityArray = Object.keys(defaults).sort(
    (a, b) =>
      DateTime.fromFormat(a, "yyyy-MM-dd").toMillis() -
      DateTime.fromFormat(b, "yyyy-MM-dd").toMillis()
  );

  let entry: string | undefined = undefined;
  capacityArray.forEach((e) => {
    const currentDate = DateTime.fromJSDate(date);
    const compareDate = DateTime.fromJSDate(new Date(e));
    if (currentDate.toISODate() >= compareDate.toISODate()) {
      entry = DateTime.fromJSDate(new Date(e)).toISODate();
    }
  });

  if (entry) {
    return defaults[entry];
  } else {
    return null;
  }
};

/**
 * Handle recurring dates for a timespan
 */
export const handleRecurring = (entries?: CalendarEntry[]): string[] => {
  if (!entries) {
    return [];
  }
  const returnValues: string[] = [];
  entries.forEach((e) => {
    const d = DateTime.fromISO(e.date).toFormat("MMdd");
    return !e.isWorking && e.recurrent && returnValues.push(d);
  });

  return returnValues;
};
