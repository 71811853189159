import { FunctionComponent, useEffect } from "react";
import { msalInstance, getMsalInstance } from "./instance";
import { MsalProvider } from "@azure/msal-react";
import { AccountInfo } from "@azure/msal-browser";
import { InvalidUser } from "@dyce/interfaces";

interface IAuthProviderProps {
  /**
   * Callback fired when user is logged in
   * @param account AccountInfo
   * @returns void
   */
  onUser?: (account: AccountInfo) => void;
  /**
   * Callback fired when token is refreshed
   * @param token string
   * @returns void
   */
  onToken?: (token: string) => void;
  /**
   * Callback fired when user is invalid in this tenant
   * @param param0 {name, email, invalidGrant} see @type {InvalidUser}
   * @returns void
   */
  invalidCallback?: ({ name, email, invalidGrant }: InvalidUser) => void;
  /**
   * Scope
   */
  scope: string;
  /**
   * Client ID
   */
  clientId: string;
  /**
   * Authority URL
   */
  authority: string;
  /**
   * Redirect URI
   */
  redirectUri?: string;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const AuthProvider: FunctionComponent<IAuthProviderProps> = ({
  children,
  scope,
  clientId,
  authority,
  redirectUri,
  invalidCallback,
  onToken,
  onUser,
}) => {
  // Periodically refresh the access token and update the parent component
  useEffect(() => {
    if (process.env["NX_TASK_TARGET_PROJECT"] === "dyce-app-e2e") {
      return;
    }

    const timer = setInterval(() => {
      msalInstance
        .refreshToken()
        .then((res) => {
          onToken && onToken(res.accessToken);
          res.account && onUser && onUser(res.account);
        })
        .catch(console.log);
    }, 10000);

    return () => clearInterval(timer);
  }, [msalInstance]);

  return (
    <MsalProvider
      instance={getMsalInstance(
        clientId,
        authority,
        scope,
        redirectUri,
        invalidCallback
      )}
    >
      {children}
    </MsalProvider>
  );
};
