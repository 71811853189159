import { ActionMeta, WorkItemType } from "@dyce/tnt-api";
import {
  DevOpsData,
  DevOpsWorkItem,
  DevOpsPayload,
  SubscriptionResponse,
  SubscriptionType,
  UpdateWorkItemType,
  DevOpsBackEndId,
  ProjectConfigurationResult,
  ProjectConfiguration,
  AreaMapping,
  ProjectingTree,
} from "@dyce/foreign-api";
import { DevOpsStateSlice, RootState } from "@dyce/slices";
import {
  AnyAction,
  createAsyncThunk,
  createSlice,
  PayloadAction,
  AsyncThunk,
} from "@reduxjs/toolkit";
import { foreignApiHelper } from "../../apiHelper/foreignApiHelper";
import { pull } from "lodash";
import { handleAreaPathMapping } from "./utils";

export const saveToDevOpsBackEnd: AsyncThunk<
  DevOpsPayload,
  {
    id: DevOpsBackEndId | string;
    value: any;
    collection?: string;
  },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  DevOpsPayload,
  {
    id: DevOpsBackEndId | string;
    value: any;
    collection?: string;
  },
  { state: RootState; rejectWithValue: Error }
>(
  "devOpsBackend/saveData",
  async ({ id, value, collection }, { rejectWithValue }) => {
    try {
      const client = await (
        await foreignApiHelper({
          foreignCollection: collection,
        })
      ).getDevOpsService();

      return client.saveToBackEnd(id, value);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getFromDevOpsBackEnd: AsyncThunk<
  DevOpsPayload,
  { id: DevOpsBackEndId | string; collection?: string },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  DevOpsPayload,
  { id: DevOpsBackEndId | string; collection?: string },
  { state: RootState; rejectWithValue: Error }
>("devOpsBackend/getData", async ({ id, collection }, { rejectWithValue }) => {
  try {
    const client = await (
      await foreignApiHelper({
        foreignCollection: collection,
      })
    ).getDevOpsService();

    return client.getDataFromBackEnd(id);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * DevOps Data (Organisation, Project, ...)
 */
export const getDevOpsData: AsyncThunk<
  DevOpsData,
  undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  DevOpsData,
  undefined,
  { state: RootState; rejectWithValue: Error }
>("devOpsBackend/getDevOpsData", async (_, { rejectWithValue }) => {
  try {
    const client = await (await foreignApiHelper()).getDevOpsService();

    return client.getDevOpsInformation();
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getDevOpsSettings: AsyncThunk<
  any,
  undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  any,
  undefined,
  { state: RootState; rejectWithValue: Error }
>("devOpsBackend/getDevOpsSettings", async (_, { rejectWithValue }) => {
  try {
    const client = await (await foreignApiHelper()).getDevOpsService();

    return client.getDevOpsSettings();
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * DevOps WorkItem info (Title, Id, ...)
 */
export const getWorkItemData: AsyncThunk<
  DevOpsWorkItem,
  /**
   * WorkItem id
   */
  number,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  DevOpsWorkItem,
  /**
   * WorkItem id
   */
  number,
  { state: RootState; rejectWithValue: Error }
>("devOpsBackend/getWorkItemData", async (id, { rejectWithValue }) => {
  try {
    const client = await (await foreignApiHelper()).getDevOpsService();

    return client.getWorkItemData(id);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * DevOps WorkItem info (Title, Id, ...)
 */
export const updateWorkItemData: AsyncThunk<
  DevOpsWorkItem,
  { id: number; payload: UpdateWorkItemType[] },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  DevOpsWorkItem,
  { id: number; payload: UpdateWorkItemType[] },
  { state: RootState; rejectWithValue: Error }
>(
  "devOpsBackend/updateWorkItemData",
  async ({ id, payload }, { rejectWithValue }) => {
    try {
      const client = await (await foreignApiHelper()).getDevOpsService();

      return client.updateWorkItemData(id, payload);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Create DevOps subscription (WebHook)
 */
export const createSubscription: AsyncThunk<
  SubscriptionResponse,
  { eventType: "updated" | "deleted" | "created"; token: string },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  SubscriptionResponse,
  { eventType: "updated" | "deleted" | "created"; token: string },
  { state: RootState; rejectWithValue: Error }
>(
  "devOpsBackend/updateWebHookData",
  async ({ eventType, token }, { getState, rejectWithValue }) => {
    try {
      const client = await (await foreignApiHelper()).getDevOpsService();
      const projectId = getState().devOps.devOpsData.project?.id;
      const instance =
        getState().devOps.collections["settings"]["mappedInstance"].instance;
      const company =
        getState().devOps.collections["settings"]["mappedInstance"].companyName;

      // Header string needs to be in this format for correct line breakings
      // FI: max length of complete string: <= 1024
      const authBearer = `Authorization: Bearer ${token}`;
      const dyceInstance = `X-Instance: ${instance}`;
      const dyceCompany = `X-Company: ${company}`;
      const finalHeader = authBearer + "\n" + dyceInstance + "\n" + dyceCompany;
      const endPoint =
        process.env["NODE_ENV"] === "development"
          ? "api.dyce.dev"
          : "api.dyce.cloud";

      const payload: SubscriptionType = {
        publisherId: "tfs",
        eventType: `workitem.${eventType}`,
        resourceVersion: "5.1-preview.3",
        consumerId: "webHooks",
        consumerActionId: "httpRequest",
        description: "DYCE-WebHook-Service",
        publisherInputs: {
          projectId: projectId ? projectId : "",
        },
        consumerInputs: {
          url: `https://${endPoint}/api/integration/AzureDevOps`,
          httpHeaders: finalHeader,
          resourceDetailsToSend: "all",
          messagesToSend: "all",
          detailedMessagesToSend: "all",
        },
        status: "enabled",
      };

      return client.createWebHookSubscription(payload);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Delete DevOps subscription (WebHook)
 */
export const deleteSubscription: AsyncThunk<
  number,
  { id: string },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  number,
  { id: string },
  { state: RootState; rejectWithValue: Error }
>("devOpsBackend/deleteWebHookData", async ({ id }, { rejectWithValue }) => {
  try {
    const client = await (await foreignApiHelper()).getDevOpsService();

    return client.deleteWebHookSubscription(id);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Get list of all DevOps subscriptions (WebHooks)
 */
export const getSubscriptions: AsyncThunk<
  {
    count: number;
    value: SubscriptionResponse[];
  },
  void,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  {
    count: number;
    value: SubscriptionResponse[];
  },
  void,
  { state: RootState; rejectWithValue: Error }
>("devOpsBackend/getWebHookDatas", async (_, { rejectWithValue }) => {
  try {
    const client = await (await foreignApiHelper()).getDevOpsService();

    return client.getWebHookSubscriptions();
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * DevOps area-path tree
 */
export const getAreaPathTree: AsyncThunk<
  ProjectConfigurationResult,
  /**
   * Query string e.g.: '$depth=2'
   */
  string,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  ProjectConfigurationResult,
  /**
   * Query string e.g.: '$depth=2'
   */
  string,
  { state: RootState; rejectWithValue: Error }
>("devOpsBackend/getAreaPathTree", async (query, { rejectWithValue }) => {
  try {
    const client = await (await foreignApiHelper()).getDevOpsService();

    return client.getAreaTree(query);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

const initialState: DevOpsStateSlice = {
  requests: [],
  areaTree: {
    area: null,
    iteration: null,
  },
  mappingTree: {
    areaPath: null,
    areaMappingTree: null,
  },
  collections: {
    "area-mapping": undefined,
  },
  workItemData: null,
  lastSavedWorkItemId: 0,
  lastLoadedWorkItemId: 0,
  workItemEffort: {
    originalEstimate: undefined,
    remaining: undefined,
    completed: undefined,
  },
  instance: undefined,
  company: undefined,
  instanceValidated: null,
  isLoading: false,
  isLoadingMandant: false,
  isValidatingInstance: false,
  devOpsData: {
    organization: null,
    project: null,
    currentUser: null,
  },
  workItemDyce: null,
  subscriptionList: null,
  darkMode: false,
};

export const devOpsSlice = createSlice({
  name: "devOps",
  initialState,
  reducers: {
    setDyceWorkItemInfo(state, action: PayloadAction<WorkItemType>) {
      state.workItemDyce = { ...action.payload };
    },
    setSavedWorkItemId(state, action: PayloadAction<number>) {
      state.lastSavedWorkItemId = action.payload;
    },
    setLoadedWorkItemId(state, action: PayloadAction<number>) {
      state.lastLoadedWorkItemId = action.payload;
    },
    setNewAreaPathId(
      state,
      action: PayloadAction<{
        areaId: number;
        areaPath: string;
      }>
    ) {
      if (state.workItemData) {
        state.workItemData.fields["System.AreaId"] = action.payload.areaId;
        state.workItemData.fields["System.AreaPath"] = action.payload.areaPath;
      }
    },
    setAreaMappingTree(
      state,
      action: PayloadAction<{
        areaPath: ProjectConfiguration;
        areaMapping: AreaMapping[];
      }>
    ) {
      state.mappingTree.areaMappingTree = handleAreaPathMapping({
        areaPath: action.payload.areaPath,
        areaMapping: action.payload.areaMapping,
      });
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(saveToDevOpsBackEnd.fulfilled, (state, action) => {
        state.collections[action.payload.id] = action.payload.value;
        if (action.payload.id === DevOpsBackEndId.AREAMAPPING) {
          if (state.mappingTree.areaPath) {
            setAreaMappingTree({
              areaPath: state.mappingTree.areaPath,
              areaMapping: action.payload.value,
            });
          }
        }
      })
      .addCase(saveToDevOpsBackEnd.rejected, (state, action) => {
        if (action.meta.arg.id === DevOpsBackEndId.SETTINGS) {
          state.isLoadingMandant = false;
          state.collections = {
            ...state.collections,
            settings: {
              mappedInstance: null,
            },
          };
        }
      })
      .addCase(getFromDevOpsBackEnd.fulfilled, (state, action) => {
        state.collections[action.payload["id"]] = action.payload.value;
        state.isLoadingMandant = false;
      })
      .addCase(getFromDevOpsBackEnd.pending, (state, action) => {
        if (action.meta.arg.id === DevOpsBackEndId.SETTINGS) {
          state.isLoadingMandant = true;
        }
      })
      .addCase(getFromDevOpsBackEnd.rejected, (state, action) => {
        if (action.meta.arg.id === DevOpsBackEndId.SETTINGS) {
          state.isLoadingMandant = false;
          state.collections = {
            ...state.collections,
            "area-mapping": state.collections["area-mapping"]
              ? state.collections["area-mapping"]
              : undefined,
            settings: {
              mappedInstance: null,
            },
          };
        }
        if (action.meta.arg.id === DevOpsBackEndId.AREAMAPPING) {
          state.collections = {
            ...state.collections,
            "area-mapping": [],
          };
        }
      })
      .addCase(getWorkItemData.fulfilled, (state, action) => {
        state.workItemData = action.payload;

        if (
          action.payload.fields["Microsoft.VSTS.Scheduling.OriginalEstimate"] >=
          0
        ) {
          state.workItemEffort.originalEstimate =
            action.payload.fields["Microsoft.VSTS.Scheduling.OriginalEstimate"];
        } else {
          state.workItemEffort.originalEstimate = undefined;
        }
        if (
          action.payload.fields["Microsoft.VSTS.Scheduling.RemainingWork"] >= 0
        ) {
          state.workItemEffort.remaining =
            action.payload.fields["Microsoft.VSTS.Scheduling.RemainingWork"];
        } else {
          state.workItemEffort.remaining = undefined;
        }
        if (
          action.payload.fields["Microsoft.VSTS.Scheduling.CompletedWork"] >= 0
        ) {
          state.workItemEffort.completed =
            action.payload.fields["Microsoft.VSTS.Scheduling.CompletedWork"];
        } else {
          state.workItemEffort.completed = undefined;
        }
      })
      .addCase(getWorkItemData.rejected, (state) => {
        state.workItemData = null;
      })
      .addCase(getAreaPathTree.fulfilled, (state, action) => {
        console.log(action);

        action.payload.value.forEach((projectConf) => {
          if (projectConf.structureType === "area") {
            state.areaTree.area = projectConf;
          }
          if (projectConf.structureType === "iteration") {
            state.areaTree.iteration = projectConf;
          }
        });
        const projectIteration = action.payload.value.find(
          (x) => x.structureType === "iteration"
        );
        const areaTree = action.payload.value.filter(
          (x) => x.structureType === "area"
        );

        if (projectIteration && areaTree) {
          const newTreeArea: ProjectingTree = {
            areaPath: projectIteration,
            areaMappingTree: null,
          };

          if (projectIteration.hasChildren && newTreeArea.areaPath) {
            newTreeArea.areaPath = {
              ...newTreeArea.areaPath,
              children: areaTree,
              hasChildren: true,
            };
          } else if (newTreeArea.areaPath) {
            newTreeArea.areaPath = {
              ...newTreeArea.areaPath,
              hasChildren: false,
            };
          }

          state.mappingTree.areaPath = newTreeArea.areaPath;
        }
      })
      .addCase(getDevOpsData.fulfilled, (state, action) => {
        state.devOpsData = action.payload;
      })
      .addCase(getSubscriptions.fulfilled, (state, action) => {
        state.subscriptionList = action.payload.value;
      })
      .addCase(getDevOpsSettings.fulfilled, (state, action) => {
        state.darkMode = action.payload.value["Theme"]
          ? action.payload.value["Theme"].includes("dark") ||
            action.payload.value["Theme"].includes("neptune") ||
            action.payload.value["Theme"].includes("night")
          : false;
      })
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("pending"),
        (state, action) => {
          state.requests.push(action.meta.requestId);
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("fulfilled") || action.type.endsWith("rejected"),
        (state, action) => {
          pull(state.requests, action.meta.requestId);
        }
      ),
});

// Action creators are generated for each case reducer function
export const {
  setDyceWorkItemInfo,
  setSavedWorkItemId,
  setLoadedWorkItemId,
  setNewAreaPathId,
  setAreaMappingTree,
} = devOpsSlice.actions;

export default devOpsSlice.reducer;
