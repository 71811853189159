import {
  ActionMeta,
  Activity,
  Customer,
  JobViewEntry,
  ODataArrayResponse,
  PopulatedJob,
  PopulatedJobTask,
} from "@dyce/tnt-api";
import {
  AnyAction,
  AsyncThunk,
  createAsyncThunk,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { tntApiHelper } from "../../apiHelper";
import { pull } from "lodash";
import { DateTime } from "luxon";
import { Expand, Filter, QueryOptions } from "odata-query";
import { RootState, TaskSettings, TaskStateSlice } from "../../types/types";

/**
 * Get all Tasks
 */
export const getAllTasks: AsyncThunk<
  ODataArrayResponse<JobViewEntry[]>,
  { search?: string; date?: string },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  ODataArrayResponse<JobViewEntry[]>,
  { search?: string; date?: string },
  { state: RootState; rejectWithError: Error }
>("tasks/getAll", async ({ search, date }, { getState, rejectWithValue }) => {
  try {
    const client = await (
      await tntApiHelper(getState, { withOdataContext: true })
    ).getResourceService();
    let filterGroupByDate = false;
    let filterByEndDate = false;
    const withEmptyDate = getState().settings.timerec.tasks.showWithoutDate;
    let top = getState().task.pagination.top;
    let skip = getState().task.pagination.skip;
    const count = true;
    let orderBy = "";
    switch (getState().settings.timerec.tasks.groupedBy) {
      case TaskSettings.CUSTOMER: {
        orderBy =
          "job/customer/name ASC, job/no ASC, plannedStartDate ASC, plannedEndDate ASC";
        break;
      }
      case TaskSettings.JOB: {
        orderBy =
          "job/no ASC, job/description ASC, plannedStartDate ASC, plannedEndDate ASC";
        break;
      }
      case TaskSettings.STARTDATE: {
        orderBy =
          "plannedStartDate ASC, plannedEndDate ASC, job/customer/name ASC, job/no ASC, jobtask/description ASC";
        filterGroupByDate = true;
        break;
      }
      case TaskSettings.ENDDATE: {
        orderBy =
          "plannedEndDate ASC, plannedStartDate ASC, job/customer/name ASC, job/no ASC, jobtask/description ASC";
        filterGroupByDate = true;
        filterByEndDate = true;
        break;
      }
      default:
        orderBy =
          "job/customer/name ASC, job/no ASC, plannedStartDate ASC, plannedEndDate ASC";
    }

    /**
     * When return to tasks from timerecs, set top to last pagination skip + top
     * for same values as before; So window.scroll to last group is possible
     */
    if (Object.values(getState().task.entries).length === 0) {
      if (getState().task.pagination.skip > 0) {
        top += getState().task.pagination.skip;
        skip = 0;
      }
    }

    const expand: Expand<Record<string, any>> = [
      { customer: { select: ["id", "name", "no"] } },
      { job: { select: ["id", "description", "no"] } },
      { jobTask: { select: ["id", "description", "no", "status"] } },
      {
        jobPlanningLine: {
          select: ["id", "description", "serviceBillingType"],
        },
      },
    ];
    const textFilter: Filter = search
      ? {
          and: [
            {
              or: [
                { customer: { name: { contains: search } } },
                { job: { no: { contains: search } } },
                { job: { description: { contains: search } } },
                { jobTask: { description: { contains: search } } },
                { jobPlanningLine: { description: { contains: search } } },
              ],
            },
            { jobTask: { status: "Open" } },
          ],
        }
      : { jobTask: { status: "Open" } };

    // Default filter for date(s)
    let dateFilter: Filter = {
      not: [
        { plannedStartDate: { eq: null } },
        { plannedEndDate: { eq: null } },
      ],
    };

    // Change filter for date if one or more conditions are true
    if (date) {
      if (filterGroupByDate) {
        if (filterByEndDate) {
          dateFilter = {
            plannedEndDate: { le: DateTime.fromISO(date).toJSDate() },
          };
        } else {
          dateFilter = {
            plannedStartDate: { ge: DateTime.fromISO(date).toJSDate() },
          };
        }
      } else {
        dateFilter = {
          or: [
            {
              and: [
                {
                  plannedStartDate: { le: DateTime.fromISO(date).toJSDate() },
                },
                { plannedEndDate: { ge: DateTime.fromISO(date).toJSDate() } },
              ],
            },
            {
              and: [
                { plannedStartDate: { eq: null } },
                { plannedEndDate: { ge: DateTime.fromISO(date).toJSDate() } },
              ],
            },
            {
              and: [
                {
                  plannedStartDate: { le: DateTime.fromISO(date).toJSDate() },
                },
                { plannedEndDate: { eq: null } },
              ],
            },
          ],
        };
      }
    } else if (withEmptyDate) {
      dateFilter = {
        or: [
          {
            and: [
              { plannedStartDate: { eq: null } },
              { plannedEndDate: { eq: null } },
            ],
          },
          {
            not: [{ plannedStartDate: { eq: null } }],
          },
          {
            not: [{ plannedEndDate: { eq: null } }],
          },
        ],
      };
    } else if (filterGroupByDate) {
      dateFilter = {
        and: [
          {
            not: [
              { plannedStartDate: { eq: null } },
              { plannedEndDate: { eq: null } },
            ],
          },
          filterByEndDate
            ? {
                not: [{ plannedEndDate: { eq: null } }],
              }
            : {
                not: [{ plannedStartDate: { eq: null } }],
              },
        ],
      };
    }

    const filter: Filter = { and: [{ ...textFilter }, { ...dateFilter }] };

    return client.getTasks({ expand, filter, count, top, skip, orderBy });
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Get all Customer
 */
export const getTaskedCustomer: AsyncThunk<
  Customer[],
  Partial<QueryOptions<Customer>> | undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  Customer[],
  Partial<QueryOptions<Customer>> | undefined,
  { state: RootState; rejectWithError: Error }
>("tasks/getCustomers", async (query, { getState, rejectWithValue }) => {
  try {
    const client = await (
      await tntApiHelper(getState)
    ).getJobAssignmentsService();

    return await client.getCustomers(query);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Fetch Jobs from API
 * @param query OData Query to insert
 */
export const getTaskedJobs: AsyncThunk<
  PopulatedJob[],
  Partial<QueryOptions<PopulatedJob>> | undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  PopulatedJob[],
  Partial<QueryOptions<PopulatedJob>> | undefined,
  { state: RootState; rejectWithValue: Error }
>("tasks/getJobs", async (query, { getState, rejectWithValue }) => {
  try {
    const client = await (
      await tntApiHelper(getState)
    ).getJobAssignmentsService();

    return (await client.getJobs(query)) as PopulatedJob[];
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Fetch JobTasks from API
 * @param query OData Query to insert
 */
export const getTaskedJobTasks: AsyncThunk<
  PopulatedJobTask[],
  Partial<QueryOptions<PopulatedJobTask>> | undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  PopulatedJobTask[],
  Partial<QueryOptions<PopulatedJobTask>> | undefined,
  { state: RootState; rejectWithValue: Error }
>("tasks/getJobTasks", async (query, { getState, rejectWithValue }) => {
  try {
    const client = await (
      await tntApiHelper(getState)
    ).getJobAssignmentsService();

    return (await client.getJobTasks(query)) as PopulatedJobTask[];
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Get all Activities for a Job and JobTask
 */
export const getTaskedActivities: AsyncThunk<
  Activity[],
  { jobId: string; jobTaskId: string | null; filter: string; orderBy: string },
  { state: RootState; rejectWithError: Error }
> = createAsyncThunk<
  Activity[],
  { jobId: string; jobTaskId: string | null; filter: string; orderBy: string },
  { state: RootState; rejectWithError: Error }
>(
  "tasks/getActivities",
  async (
    { jobId, jobTaskId, filter, orderBy },
    { getState, rejectWithValue }
  ) => {
    try {
      const client = await (
        await tntApiHelper(getState)
      ).getJobAssignmentsService();
      const currentResource = getState().user.currentUser?.resource?.id;

      if (currentResource) {
        return await client.getActivities(
          jobId,
          jobTaskId,
          filter,
          orderBy,
          currentResource
        );
      } else {
        return rejectWithValue(new Error("No current resource"));
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const initialStateTasks: TaskStateSlice = {
  requests: [],
  entries: {},
  totalCount: 0,
  initialResult: {
    loaded: false,
    hasValues: false,
  },
  pagination: {
    top: 50,
    skip: 0,
  },
};

export const taskSlice = createSlice({
  name: "tasks",
  initialState: initialStateTasks,
  reducers: {
    setResetState: (state) => {
      state.initialResult.loaded = false;
      state.initialResult.hasValues = false;
    },
    setClearTasks: (state) => {
      state.entries = {};
    },
    setTaskPagination: (
      state,
      action: PayloadAction<{ top?: number; skip: number }>
    ) => {
      state.pagination.top = action.payload.top ?? state.pagination.top;
      state.pagination.skip = action.payload.skip;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllTasks.fulfilled, (state, action) => {
        if (state.pagination.skip === 0) {
          state.entries = {};
        }
        action.payload.value.forEach((e) => (state.entries[e.id] = e));

        if (action.payload["@odata.count"] !== undefined) {
          state.totalCount = action.payload["@odata.count"];
        }

        const actionHasSearchValue = Object.values(action.meta.arg).length > 0;

        if (state.initialResult.loaded === false && !actionHasSearchValue) {
          state.initialResult.loaded = true;
          state.initialResult.hasValues = action.payload.value.length > 0;
        } else if (
          state.initialResult.loaded &&
          !actionHasSearchValue &&
          action.payload.value.length > 0
        ) {
          state.initialResult.hasValues = true;
        }
      })
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.startsWith("task") && action.type.endsWith("pending"),
        (state, action) => {
          state.requests.push(action.meta.requestId);
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          (action.type.startsWith("task") &&
            action.type.endsWith("fulfilled")) ||
          (action.type.startsWith("tasks") && action.type.endsWith("rejected")),
        (state, action) => {
          pull(state.requests, action.meta.requestId);
        }
      ),
});

export const { setResetState, setClearTasks, setTaskPagination } =
  taskSlice.actions;

export default taskSlice.reducer;
