import { createContext, FunctionComponent, useContext } from "react";
// Dyce-Lib
import { SetupLanguages } from "@dyce/slices";

type ToolTipContextType = {
  /**
   * Base URL from documentation, e.g. "time-tracking/"
   */
  baseUrl: string;
  /**
   * It is recommended to set the language in the top-level structure e.g. app.tsx
   * @default 'en'
   */
  language?: SetupLanguages;
};

/**
 * Use only one time at top level to set language, baseUrl can to be overwritten
 * with @TooltipProvider component
 */
export const TooltipContext = createContext<ToolTipContextType>({
  baseUrl: "",
  language: "en",
});

interface TooltipProviderProps {
  /**
   * Overwrite baseURL from TooltipContext, e.g. "time-tracking/"
   */
  baseUrl: string;
  /**
   * Provide a custom language or change the current one
   */
  language?: SetupLanguages;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

/**
 * TooltipProvider overwrites baseUrl and forwards language from ToolTipContext
 * @param baseUrl Overwrite baseUrl for documentation
 * @returns Children
 */
export const TooltipProvider: FunctionComponent<TooltipProviderProps> = ({
  baseUrl,
  language,
  children,
}) => {
  const { language: contextLanguage } = useTooltipContent();

  if (!language && !contextLanguage) {
    throw new Error("Could not get language for tooltip.");
  }

  const selectedLanguage = language ? language : contextLanguage;

  return (
    <TooltipContext.Provider value={{ baseUrl, language: selectedLanguage }}>
      {children}
    </TooltipContext.Provider>
  );
};

/**
 * Custom hook for the tooltip context
 * @returns Tooltip context
 */
export const useTooltipContent = (): ToolTipContextType => {
  const context = useContext(TooltipContext);

  return context;
};
