import { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Stepper as MuiStepper,
  Step,
  StepLabel,
  Typography,
  useMediaQuery,
  Breakpoint,
} from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    root: {
      width: "100%",
      marginBottom: theme.spacing(3),
    },
  })
);

interface IStepperHeaderProps {
  /**
   * Current step
   */
  activeStep: number;
  /**
   *
   */
  steps: string[];
  /**
   * Define at which breakpoint.up the Stepper should switch to text only for small screens
   * @default 'sm'
   */
  hideAtBreakpoint?: number | Breakpoint;
}

export const StepperHeader: FunctionComponent<IStepperHeaderProps> = ({
  activeStep,
  steps,
  hideAtBreakpoint = "sm",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const hidden = useMediaQuery((theme: DyceTheme) =>
    theme.breakpoints.up(hideAtBreakpoint)
  );

  return (
    <div className={classes.root}>
      {/* Stepper Header */}
      {hidden ? (
        <MuiStepper activeStep={activeStep - 1} alternativeLabel>
          {steps.map((label) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </MuiStepper>
      ) : (
        <Typography variant="h5">
          {t("steps.desc", {
            step: activeStep,
            sum: steps.length,
            active: steps[activeStep - 1],
          })}
        </Typography>
      )}
    </div>
  );
};
