import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  AsyncThunk,
} from "@reduxjs/toolkit";
import { pull } from "lodash";
import { ActionMeta } from "@dyce/tnt-api";
import {
  JiraBackEndId,
  JiraError,
  JiraOnboarding,
  JiraPayload,
  JiraWebHook,
} from "@dyce/foreign-api";
import { JiraStateSlice, RootState } from "../../types/types";
import { foreignApiHelper } from "../../apiHelper/foreignApiHelper";
import { handleAddonKey } from "./utils";

declare const AP: any;

/**
 * Set custom data in jira backend
 */
export const saveToJiraBackEnd: AsyncThunk<
  JiraPayload | JiraError,
  {
    id: JiraBackEndId | string;
    value: any;
  },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  JiraPayload | JiraError,
  {
    id: JiraBackEndId | string;
    value: any;
  },
  { state: RootState; rejectWithValue: Error }
>(
  "jiraBackend/saveData",
  async ({ id, value }, { getState, rejectWithValue }) => {
    try {
      const client = (await foreignApiHelper()).getJiraService();

      const project: string | null = getState().jira.jiraData["project"];
      const addon: string = getState().jira.jiraData["addonKey"];
      if (project) {
        return client.saveToBackEnd({
          id,
          value,
          project: project,
          addonKey: addon,
        });
      } else {
        return rejectWithValue(new Error("Jira Project not found!"));
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Delete custom data in jira backend
 */
export const deleteFromJiraBackEnd: AsyncThunk<
  unknown,
  {
    id: JiraBackEndId | string;
  },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  unknown,
  {
    id: JiraBackEndId | string;
  },
  { state: RootState; rejectWithValue: Error }
>("jiraBackend/deleteData", async ({ id }, { getState, rejectWithValue }) => {
  try {
    const client = (await foreignApiHelper()).getJiraService();

    const project: string | null = getState().jira.jiraData["project"];
    const addon: string = getState().jira.jiraData["addonKey"];
    if (project) {
      return client.deleteFromBackEnd({
        id,
        project: project,
        addonKey: addon,
      });
    } else {
      return rejectWithValue(new Error("Jira Project not found!"));
    }
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Get custom data from jira backend
 */
export const getFromJiraBackEnd: AsyncThunk<
  JiraPayload | JiraError,
  { id: JiraBackEndId | string },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  JiraPayload | JiraError,
  { id: JiraBackEndId | string },
  { state: RootState; rejectWithValue: Error }
>("jiraBackend/getData", async ({ id }, { getState, rejectWithValue }) => {
  try {
    const client = (await foreignApiHelper()).getJiraService();

    const project: string | null = getState().jira.jiraData["project"];
    const addon: string = getState().jira.jiraData["addonKey"];
    if (project) {
      return client.getDataFromBackEnd({
        id,
        project: project,
        addonKey: addon,
      });
    } else {
      return rejectWithValue(new Error("Jira Project not found!"));
    }
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Thunk to get all webhooks for the jira-project
 */
export const getAllProjectWebHooks: AsyncThunk<
  JiraWebHook[],
  undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  JiraWebHook[],
  undefined,
  { state: RootState; rejectWithValue: Error }
>("tntApi/getAllWebHooks", async (_, { rejectWithValue }) => {
  try {
    const jiraAppToken = await AP.context.getToken();
    const client = (
      await foreignApiHelper({
        individualBaseUrl: process.env["NX_JIRA_API_URL"],
        jiraAppToken: jiraAppToken,
      })
    ).getWebHookService();

    return client.getWebHook();
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Thunk to create a webhook for the jira-project
 */
export const createProjectWebHook: AsyncThunk<
  string,
  JiraOnboarding,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  string,
  JiraOnboarding,
  { state: RootState; rejectWithValue: Error }
>(
  "tntApi/createWebhook",
  async (
    { projectKey, clientToken, instance, company },
    { rejectWithValue }
  ) => {
    try {
      const jiraAppToken = await AP.context.getToken();
      const client = (
        await foreignApiHelper({
          individualBaseUrl: process.env["NX_JIRA_API_URL"],
          jiraAppToken: jiraAppToken,
        })
      ).getWebHookService();

      return client.createWebHook({
        projectKey,
        clientToken,
        instance,
        company,
      });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Thunk to delete a webhook from the jira-project
 */
export const deleteProjectWebHook: AsyncThunk<
  undefined,
  { projectKey: string },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  undefined,
  { projectKey: string },
  { state: RootState; rejectWithValue: Error }
>("tntApi/createWebhook", async ({ projectKey }, { rejectWithValue }) => {
  try {
    const jiraAppToken = await AP.context.getToken();
    const client = (
      await foreignApiHelper({
        individualBaseUrl: process.env["NX_JIRA_API_URL"],
        jiraAppToken: jiraAppToken,
      })
    ).getWebHookService();

    return client.deleteWebHook(projectKey);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

const initialState: JiraStateSlice = {
  requests: [],
  collections: {},
  issueData: null,
  lastSavedIssueId: 0,
  lastLoadedIssueId: 0,
  instance: undefined,
  company: undefined,
  companyId: null,
  instanceValidated: null,
  isLoading: false,
  isLoadingMandant: false,
  isValidatingInstance: false,
  jiraData: {
    organization: null,
    project: null,
    currentUser: null,
    addonKey: handleAddonKey(),
  },
  issueDyce: null,
  projectType: "software",
};

const jiraSlice = createSlice({
  name: "jira",
  initialState,
  reducers: {
    setJiraOrganization(state, action: PayloadAction<string>) {
      state.jiraData = {
        ...state.jiraData,
        organization: action.payload,
      };
    },
    setJiraProject(state, action: PayloadAction<string>) {
      state.jiraData = {
        ...state.jiraData,
        project: action.payload,
      };
    },
    setJiraUserId(state, action: PayloadAction<string>) {
      state.jiraData = {
        ...state.jiraData,
        currentUser: action.payload,
      };
    },
    setAssignedCompanyId(state, action: PayloadAction<string>) {
      state.companyId = action.payload;
    },
    setJiraProjectType(
      state,
      action: PayloadAction<"service_desk" | "software">
    ) {
      state.projectType = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveToJiraBackEnd.fulfilled, (state, action) => {
        if ((action.payload as JiraPayload).key === "success") {
          console.log((action.payload as any).message, action.meta.arg);
          state.collections[action.meta.arg.id] = action.meta.arg.value;
        }
      })
      .addCase(saveToJiraBackEnd.rejected, (state, action) => {
        if (action.meta.arg.id === JiraBackEndId.DYCE_COMPANY_MAPPING) {
          state.isLoadingMandant = false;
          state.collections = {
            ...state.collections,
            [JiraBackEndId.DYCE_COMPANY_MAPPING]: {
              mappedInstance: null,
            },
          };
        }
      })
      .addCase(getFromJiraBackEnd.fulfilled, (state, action) => {
        // Error handling
        if ((action.payload as JiraError).message) {
          if (action.meta.arg.id === JiraBackEndId.DYCE_COMPANY_MAPPING) {
            state.isLoadingMandant = false;
            state.collections = {
              ...state.collections,
              [JiraBackEndId.DYCE_COMPANY_MAPPING]: {
                mappedInstance: null,
              },
            };
          }
        } else if ((action.payload as JiraPayload).self) {
          const jiraPayload = action.payload as JiraPayload;
          // Remove project key with "-"
          const collectionKey = jiraPayload.key.substring(
            jiraPayload.key.indexOf("-") + 1,
            jiraPayload.key.length
          );
          state.collections[collectionKey] = jiraPayload.value;
          state.isLoadingMandant = false;
        }
      })
      .addCase(getFromJiraBackEnd.pending, (state, action) => {
        if (action.meta.arg.id === JiraBackEndId.DYCE_COMPANY_MAPPING) {
          state.isLoadingMandant = true;
        }
      })
      .addCase(getFromJiraBackEnd.rejected, (state, action) => {
        if (action.meta.arg.id === JiraBackEndId.DYCE_COMPANY_MAPPING) {
          state.isLoadingMandant = false;
          state.collections = {
            ...state.collections,
            [JiraBackEndId.DYCE_COMPANY_MAPPING]: {
              mappedInstance: null,
            },
          };
        }
      })
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("pending"),
        (state, action) => {
          state.requests.push(action.meta.requestId);
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("fulfilled") || action.type.endsWith("rejected"),
        (state, action) => {
          pull(state.requests, action.meta.requestId);
        }
      );
  },
});

// Action creators are generated for each case reducer function
export const {
  setAssignedCompanyId,
  setJiraOrganization,
  setJiraProject,
  setJiraUserId,
  setJiraProjectType,
} = jiraSlice.actions;

export default jiraSlice.reducer;
