import { FunctionComponent } from "react";
// Helpers
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { SubHeader, useStaticContent } from "@dyce/ui";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import {
  selectAdminSettings,
  selectCurrentCompany,
  useAppSelector,
} from "@dyce/slices";
// Components
import { TasksSettings } from "../../../components/admin/settings/TasksSettings";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: `calc(100vh - ${theme.palette.propsDyce.commonPageProps.marginTop} - 64px - 112px - 8px)`,
      marginRight: "1.75rem",
      marginLeft: "1.25rem",
      marginTop: "-1rem",
      [theme.breakpoints.down("sm")]: {
        height: `calc(100vh - ${theme.palette.propsDyce.commonPageProps.marginTop} - 56px - 112px - 8px)`,
      },
    },
  })
);

export const Settings: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { docuLinks } = useStaticContent();

  // Selectors
  const currentCompany = useAppSelector(selectCurrentCompany);
  const adminSettings = useAppSelector(selectAdminSettings);

  return (
    <>
      <SubHeader
        tooltipLabel={t("admin.settings.info")}
        tooltipUrlPath={docuLinks.administration.settings.subheader.info}
        title={t("admin.settings.title")}
        subtitle={t("admin.settings.subtitle", { company: currentCompany })}
      />
      <div className={classes.container}>
        <TasksSettings tasksSettingsValue={adminSettings.tasks.assignment} />
      </div>
    </>
  );
};
