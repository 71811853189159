import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { Tooltip } from "../../tooltip/tooltip";
// MUI
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";

interface ICopyToClipboardProps {
  /**
   * Value to be copied to the clipboard
   */
  valueTopCopy: string | null;
  /**
   * Span container wrapped around the IconButton; Provide some style if needed
   */
  spanStyle?: string;
}

export const CopyToClipboard: FunctionComponent<ICopyToClipboardProps> = ({
  valueTopCopy,
  spanStyle,
}) => {
  const { t } = useTranslation();
  // States
  const [copyClicked, setCopyClicked] = useState<boolean>(false);
  const [copyChecked, setCopyChecked] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    if (copyClicked) {
      setTimeout(() => {
        setCopyClicked(false);
        setCopyChecked(true);
      }, 250);
    }
  }, [copyClicked]);

  useEffect(() => {
    if (copyChecked) {
      setTimeout(() => {
        setCopyChecked(false);
      }, 1500);
    }
  }, [copyChecked]);

  return (
    <Tooltip
      label={
        valueTopCopy === null
          ? t("lib.ui.buttons.copyToClipboard.tooltipLabel.noValue")
          : t("lib.ui.buttons.copyToClipboard.tooltipLabel.value")
      }
      urlPath={{ de: "", en: "" }}
    >
      <span className={spanStyle}>
        <IconButton
          aria-label="copy"
          disabled={copyClicked || copyChecked || valueTopCopy === null}
          onClick={() => {
            valueTopCopy && navigator.clipboard.writeText(valueTopCopy);
            setCopyClicked(true);
          }}
        >
          {!copyChecked ? (
            <ContentCopyOutlinedIcon
              color={
                copyClicked || valueTopCopy === null ? "disabled" : "primary"
              }
            />
          ) : (
            <CheckCircleOutlinedIcon color="success" />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};
