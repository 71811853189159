import { FunctionComponent, useState } from "react";
// Helper
// Dyce-Lib
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import {
  Badge,
  BottomNavigation as MuiBottomNavigation,
  BottomNavigationAction,
  Paper,
} from "@mui/material";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import { incompleteCount, useAppSelector } from "@dyce/slices";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
  })
);

interface IBottomNavigationProps {}

export const BottomNavigation: FunctionComponent<
  IBottomNavigationProps
> = ({}) => {
  const classes = useStyles();
  const [value, setValue] = useState("dashboard");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <Paper
      sx={{
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        overflowX: "scroll",
        overflowY: "hidden",
      }}
      elevation={3}
    >
      <MuiBottomNavigation value={value} onChange={handleChange} showLabels>
        <BottomNavigationAction
          component={(props) => <Link to={"/timetracking"} {...props} />}
          label="Dashboard"
          value="dashboard"
          icon={<CalendarTodayOutlinedIcon />}
        />
        <BottomNavigationAction
          component={(props) => (
            <Link
              to={`/timetracking/dailyrecordings#${DateTime.now().toISODate()}`}
              {...props}
            />
          )}
          label="Timerecordings"
          value="timerecordings"
          icon={<MoreTimeOutlinedIcon />}
        />
        <BottomNavigationAction
          component={(props) => <Link to={"/timetracking/tasks"} {...props} />}
          label="Tasks"
          value="tasks"
          icon={<TaskAltOutlinedIcon />}
        />
        <BottomNavigationAction
          component={(props) => (
            <Link to={"/timetracking/templates"} {...props} />
          )}
          label="Templates"
          value="templates"
          icon={<BookmarksOutlinedIcon />}
        />
        <BottomNavigationAction
          component={(props) => (
            <Link to={"/timetracking/incomplete"} {...props} />
          )}
          label="Incomplete"
          value="incompleteTimes"
          icon={<IncompleteTimesIcon />}
        />
      </MuiBottomNavigation>
    </Paper>
  );
};

const IncompleteTimesIcon: FunctionComponent = () => {
  const count = useAppSelector(incompleteCount);

  if (count > 0) {
    return (
      <Badge badgeContent={count} color="error">
        <ErrorOutlineIcon fontSize="small" color="error" />
      </Badge>
    );
  } else {
    return <ErrorOutlineIcon fontSize="small" color="disabled" />;
  }
};
