import React from "react";
// Helper
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
// Dyce-Lib
import { CurrentUser, Instance } from "@dyce/tnt-api";
import { useStaticContent } from "../static-provider/static-provider";
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ExitToAppSharpIcon from "@mui/icons-material/ExitToAppSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FaceSharpIcon from "@mui/icons-material/FaceSharp";
import SettingsSharpIcon from "@mui/icons-material/SettingsSharp";
import BusinessIcon from "@mui/icons-material/Business";
// Components
import { InstanceTree } from "../instance-tree/instance-tree";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    paper: {
      minWidth: 300,
      maxWidth: 350,
      overflowX: "hidden",
    },
    close: {
      display: "flex",
      flexDirection: "row-reverse",
      margin: "16px 16px 0 0",
    },
    user: {
      display: "flex",
      width: "100%",
      height: "100px",
      marginLeft: theme.spacing(2),
    },
    userIcon: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(2.5),
      marginTop: theme.spacing(2),
    },
    userText: {
      overflow: "hidden",
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(2),
      marginRight: theme.spacing(1),
    },
    accordionContainer: {
      "& .MuiPaper-root-MuiAccordion-root.Mui-expanded": {
        margin: 0,
      },
    },
    accordion: {
      background: "rgba(255, 255, 255, 0.15)",
    },
    accordionSummary: {
      minHeight: "48px !important",
      height: 48,
    },
    overFlow: {
      overflowY: "auto",
      maxHeight: "calc(100vh - 405px)",
      background: "rgba(255, 255, 255, 0.1)",
    },
  })
);

interface UserMenuItemsProps {
  /**
   * the users current instance
   */
  instance: string;
  /**
   * the users currently company
   */
  company: string;
  /**
   * all instances available to the user
   */
  instances: Instance[];
  /**
   * callback when an element was selected
   */
  onClose?: () => void;
  /**
   * Current user information
   */
  currentUser: CurrentUser | null;
  /**
   * callback when a new instance/company combination is selected
   */
  onSelection?: (instance: Instance, company: string) => void;
}

export const UserMenuItems: React.FC<UserMenuItemsProps> = ({
  onClose,
  instance,
  instances,
  company,
  currentUser,
  onSelection,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { routes } = useStaticContent();

  const handleClose = () => {
    onClose && onClose();
  };

  return (
    <Box className={classes.paper}>
      <Box style={{ display: "flex", flexDirection: "column" }}>
        <div className={classes.close}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <FaceSharpIcon className={classes.userIcon} fontSize="large" />
        <Box className={classes.user}>
          <Box className={classes.userText}>
            <Typography variant="body2" noWrap>
              {currentUser && currentUser.name}
            </Typography>
            <Typography variant="body2" noWrap>
              {currentUser && currentUser.email}
            </Typography>
            <Typography variant="body2" color="primary.dark">
              {t("app.sidebar.instance")}: {instance}
            </Typography>
          </Box>
        </Box>
        <Divider />
        <div className={classes.accordionContainer}>
          <Accordion square elevation={0} className={classes.accordion}>
            <div className={classes.accordion}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
                className={classes.accordionSummary}
              >
                <ListItemIcon>
                  <BusinessIcon />
                </ListItemIcon>
                <Typography>{t("app.sidebar.switchInstance")}</Typography>
              </AccordionSummary>
            </div>
            <div className={classes.overFlow}>
              <AccordionDetails>
                <InstanceTree
                  onSelection={(instance, company) => {
                    onSelection && onSelection(instance, company);
                    onClose && onClose();
                  }}
                  instance={instance}
                  instances={instances}
                  company={company}
                />
              </AccordionDetails>
            </div>
          </Accordion>
        </div>
        <Divider />
        <ListItem
          button
          component={Link}
          to={routes.SETTINGS}
          onClick={handleClose}
        >
          <ListItemIcon>
            <SettingsSharpIcon />
          </ListItemIcon>
          <ListItemText>{t("app.sidebar.settings")}</ListItemText>
        </ListItem>
        <Divider />
        <ListItem button component={Link} to="/logout" onClick={handleClose}>
          <ListItemIcon>
            <ExitToAppSharpIcon />
          </ListItemIcon>
          <ListItemText>{t("app.sidebar.logout")}</ListItemText>
        </ListItem>
        <Divider />
      </Box>
    </Box>
  );
};
