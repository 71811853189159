import React, { FunctionComponent } from "react";
// Material-UI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Backdrop as MuiBackdrop,
  CircularProgress,
  Typography,
} from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    backdrop: {
      zIndex: 1500 + 1, // MUI Tooltip zIndex + 1
      color: "#fff",
    },
    loadingBox: {
      marginLeft: 15,
    },
  })
);

interface BackdropProps {
  /**
   * The status message to display
   */
  value: string;
  /**
   * If the backdrop is active
   */
  open: boolean;
}

/**
 * Fullscreen backdrop with a circular progress indicator
 */
export const Backdrop: FunctionComponent<BackdropProps> = ({ value, open }) => {
  const classes = useStyles();

  return (
    <MuiBackdrop className={classes.backdrop} open={open}>
      <CircularProgress color="inherit" />
      <div className={classes.loadingBox}>
        <Typography>{value}</Typography>
      </div>
    </MuiBackdrop>
  );
};
