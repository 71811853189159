import React, { FunctionComponent, useEffect } from "react";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { IconButton, TextField } from "@mui/material";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles(() =>
  createStyles({
    dialogCategory: {
      display: "flex",
      width: "100%",
      marginTop: "0.5rem",
      alignItems: "center",
    },
    dialogItemContainer: {
      display: "flex",
      flexDirection: "column",
    },
    dialogItemContainerItem: {
      display: "inherit",
      flexDirection: "row",
      alignItems: "center",
    },
    searchContainer: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
      width: "55%",
    },
    searchField: {
      width: "100%",
    },
  })
);

interface IDialogSearchFieldProps {
  /**
   * Textfield-label
   */
  label: string;
  /**
   * Show clear button
   * @default true
   */
  clearButton?: boolean;
  /**
   * Disable textfield
   * @default false
   */
  isDisabled?: boolean;
  /**
   * If true, textfield is required
   */
  required?: boolean;
  /**
   * Callback fired onChange event
   */
  onChange: (value: string) => void;
  /**
   * Callback fired onFocus event
   */
  onFocus: (
    e: React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
  ) => void;
  /**
   * Callback fired onKeyDown event
   */
  onKeyDown?: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  /**
   * React-Hook-Form reference hook, e.g. to make component focusable
   */
  refElement?: (inputRef: HTMLInputElement) => void;
  /**
   * Callback fired when user clicks on clear button
   * @returns void
   */
  onClearField?: () => void;
  /**
   * Remove inner styling
   */
  overrideStyling?: boolean;
  /**
   * Common styling for textfield - Can only used when overrideStyling is true
   */
  style?: string;
  /**
   * Value of textfield for controlled status
   */
  value?: string;
}

export const DialogSearchField: FunctionComponent<IDialogSearchFieldProps> = ({
  label,
  clearButton = true,
  isDisabled = false,
  required = false,
  onChange,
  onFocus,
  onKeyDown,
  refElement,
  onClearField,
  overrideStyling = false,
  style,
  value,
}) => {
  const classes = useStyles();

  // States
  const [internalValue, setInternalValue] = React.useState<string>(
    value ? value : ""
  );

  // UseEffects
  useEffect(() => {
    setInternalValue(value ? value : "");
    onChange(value ? value : "");
  }, [value]);

  return (
    <div className={overrideStyling ? style : classes.searchContainer}>
      <TextField
        inputRef={(e: HTMLInputElement) => refElement && refElement(e)}
        className={classes.searchField}
        disabled={isDisabled}
        label={
          label?.length === 0 ? (
            <ErrorOutlineIcon
              style={{
                color: "#f44336",
                transform: "scale(1.2)",
              }}
            />
          ) : (
            label
          )
        }
        value={internalValue}
        onChange={(e) => {
          setInternalValue(e.target.value);
          onChange(e.target.value);
        }}
        autoFocus={true}
        required={required}
        InputLabelProps={{
          shrink: true,
        }}
        onFocus={(e) => onFocus(e)}
        onKeyDown={(e) => onKeyDown && onKeyDown(e)}
        InputProps={{
          endAdornment: (
            <>
              {clearButton && internalValue.length > 0 ? (
                <IconButton
                  sx={{ marginRight: "-8px" }}
                  onClick={() => {
                    setInternalValue("");
                    onChange("");
                    onClearField && onClearField();
                  }}
                  tabIndex={-1}
                >
                  <ClearOutlinedIcon />
                </IconButton>
              ) : null}
            </>
          ),
        }}
      />
    </div>
  );
};
