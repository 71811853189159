import {
  useEffect,
  useState,
  useRef,
  FunctionComponent,
  MutableRefObject,
} from "react";
// Helper
import classNames from "classnames";
import { RefCallBack } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { handleBackgroundColor } from "../../time-tracking-tool/container/utils";
// Dyce-Lib
import { useHotkeys } from "react-hotkeys-hook";
import { useStaticContent } from "../../static-provider/static-provider";
import { Dialog } from "../../dialog/dialog";
import { Tooltip } from "../../tooltip/tooltip";
import { DyceTheme } from "@dyce/theme";
import { useUpdateEffect } from "@dyce/hooks";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Fade, IconButton, TextField, useTheme } from "@mui/material";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";

const useStyle = ({
  openDialog,
  rows,
  size,
}: {
  openDialog: boolean;
  rows: number;
  size: "small" | "medium";
}) =>
  makeStyles((theme: DyceTheme) =>
    createStyles({
      inputFieldDesign: {
        "& .MuiInputBase-root": {
          paddingRight: "4px",
        },
        "& .MuiInputBase-input": {
          height: !openDialog
            ? rows > 1
              ? size === "small"
                ? "78px !important"
                : "98px !important"
              : "24px !important"
            : undefined,
          cursor: "auto",
          "&::-webkit-scrollbar-track": {
            "-webkit-box-shadow": "inset 0 0 3px rgba(0,0,0,0)",
            borderRadius: "5px",
            backgroundColor: "rgba(0,0,0,0)",
            "scrollbar-color": "rgba(0,0,0,0)",
          },
          "&::-webkit-scrollbar": {
            width: "8px",
            backgroundColor: "rgba(0,0,0,0)",
          },
          "&::-webkit-scrollbar-thumb": {
            borderRadius: "5px",
            "-webkit-box-shadow": "inset 0 0 5px rgba(0,0,0,.3)",
            backgroundColor:
              theme.palette.mode === "dark"
                ? "rgb(125,125,125)"
                : "rgb(205,205,205)",
          },
        },
      },
    })
  );

export interface IExpandButtonProps {
  /**
   * If true, expand button shows in endAdornment and textfield is opening in Dialog
   * @default true
   */
  isExpandable?: boolean;
  /**
   * Callback to set isExpanded state;
   * Need prop isExpandable to true
   * @param isExpanded Value of isExpanded as boolean
   * @returns void
   */
  handleIsExpanded?: (isExpanded: boolean) => void;
  /**
   * If true, textfield is selected in time-tracking-tool
   */
  isActive: boolean;
}

interface IDescriptionFieldProps {
  /**
   * Textfield-label
   */
  label: string;
  /**
   * Common styling for textfield
   */
  style?: string;
  /**
   * Default and/or onChange value
   */
  value: string;
  /**
   * If true, Textfield is disabled
   * @default false
   */
  isDisabled?: boolean;
  /**
   * Max length of input
   */
  maxLength?: number;
  /**
   * Callback fired onChange event
   */
  onChange: (value: any) => void;
  /**
   * Callback fired onBlur event
   */
  onBlur: (value: any, ref: MutableRefObject<HTMLInputElement | null>) => void;
  /**
   * React-Hook-Form reference hook, e.g. to make component focusable
   */
  refHook?: RefCallBack;
  /**
   * Callback fired on every onChange event, used to mark field as dirty
   */
  handleDirtyField?: () => void;
  /**
   * Define tabIndex to focus correct field
   */
  tabIndex?: number;
  /**
   * Number of sectionRows
   */
  rows?: number;
  /**
   * Props for expand button
   */
  expandButton: IExpandButtonProps;
  /**
   * If true, textfield is focused on mount
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Unique ID for testing field
   * @default "test-cypress-id"
   */
  testId?: string;
  /**
   * Define size of textfield
   * @default "medium"
   */
  size?: "small" | "medium";
  /**
   * If the border is set, background color is set to theme.palette.background.default
   * @default false
   */
  borderActive?: boolean;
}

export const DescriptionField: FunctionComponent<IDescriptionFieldProps> = ({
  style,
  value,
  isDisabled = false,
  expandButton,
  maxLength,
  onChange,
  onBlur,
  label,
  refHook,
  handleDirtyField,
  tabIndex,
  rows = 1,
  autoFocus = false,
  testId = "test-cypress-id",
  size = "medium",
  borderActive = false,
  ...rest
}) => {
  const { t } = useTranslation();
  const { docuLinks, platform } = useStaticContent();
  const theme = useTheme<DyceTheme>();
  const { handleIsExpanded, isExpandable, isActive } = expandButton;

  // Refs
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dialogInputRef = useRef<HTMLInputElement | null>(null);

  // States
  const [description, setDescription] = useState<string>(value);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [dialogTextFieldValue, setDialogTextFieldValue] =
    useState<string>(value);

  // Styling for individual scrollbar
  const classes = useStyle({ openDialog, rows, size })();

  // Prop used for firefox browser
  const fireFoxStyle = {
    scrollbarColor: `${
      theme.palette.mode === "dark"
        ? "rgb(125,125,125) rgba(0,0,0,0)"
        : "rgb(205,205,205) rgba(0,0,0,0)"
    }`,
  };

  // UseEffects
  useEffect(() => {
    setDescription(value);
    setDialogTextFieldValue(value);
  }, [value]);

  useUpdateEffect(() => {
    onChange(description);
    handleDirtyField && handleDirtyField();
  }, [description]);

  useEffect(() => {
    if (!isActive && isTouched) {
      setIsTouched(false);
    }
  }, [isActive]);

  useEffect(() => {
    let focusDesc: NodeJS.Timeout;
    isExpandable && handleIsExpanded && handleIsExpanded(openDialog);
    if (openDialog) {
      // Set cursor to end of text
      focusDesc = setTimeout(() => {
        dialogInputRef &&
          dialogInputRef.current &&
          dialogInputRef.current.setSelectionRange(value.length, value.length);
        dialogInputRef &&
          dialogInputRef.current &&
          dialogInputRef.current.focus();
      }, 100);
    }
    return () => clearTimeout(focusDesc);
  }, [openDialog]);

  // Hotkeys
  useHotkeys(
    `${platform.modifiers.modifierAlt}+o`,
    (e) => {
      e.preventDefault();
      !isDisabled && isExpandable && setOpenDialog(true);
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"],
    }
  );

  useHotkeys(
    `${platform.modifiers.modifierCtrl}+enter`,
    (e) => {
      e.preventDefault();
      setDescription(dialogTextFieldValue);
      setOpenDialog(false);
    },
    {
      enabled: openDialog,
      enableOnTags: ["INPUT", "TEXTAREA"],
    }
  );

  // Handler
  const handleChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleBlur = () => {
    onChange(description);
    onBlur(description, inputRef);
  };

  const handleOnCancel = () => {
    setDialogTextFieldValue(value);
    setOpenDialog(false);
    handleFocusTextField();
  };

  const handleOnSubmit = () => {
    setDescription(dialogTextFieldValue);
    setOpenDialog(false);
    handleFocusTextField();
  };

  const handleFocusTextField = () => {
    const focusDesc = setTimeout(() => {
      inputRef &&
        inputRef.current &&
        inputRef.current.setSelectionRange(
          dialogTextFieldValue.length,
          dialogTextFieldValue.length
        );
      inputRef && inputRef.current && inputRef.current.focus();
    }, 100);
    return () => clearTimeout(focusDesc);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
        }}
      >
        <TextField
          {...rest}
          id={testId}
          size={size}
          inputRef={(e: HTMLInputElement) => {
            refHook && refHook(e);
            inputRef.current = e;
          }}
          autoFocus={autoFocus}
          sx={{ width: "100%" }}
          className={classNames(style, classes.inputFieldDesign)}
          style={fireFoxStyle}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onTouchStart={() => setIsTouched(true)}
          multiline={rows > 1}
          required
          rows={rows > 1 ? 3 : 1} // If more than 1 row in design, textfield gets 3 rows to fill in description
          disabled={isDisabled}
          inputProps={{ maxLength: maxLength, tabIndex: tabIndex }}
          value={description}
          onChange={handleChange}
          onBlur={handleBlur}
          label={label}
          InputLabelProps={{ shrink: true }}
          InputProps={{
            endAdornment: isExpandable && (
              <div
                style={{
                  height:
                    rows > 1 ? (size === "small" ? "78px" : "98px") : "24px",
                }}
              >
                <Tooltip
                  label={t("tooltip.editor.expand.description", {
                    modifier: platform.description.modifierAlt,
                  })}
                  urlPath={
                    docuLinks.timetracking.reusableComponents.editor
                      .expandButton
                  }
                  position="bottom-end"
                  modifierOffsets={[-20, 98]}
                >
                  <span>
                    <Fade
                      in={isHovered || isTouched}
                      timeout={{ enter: 500, exit: 250 }}
                    >
                      <div
                        style={{
                          position: "absolute",
                          bottom:
                            rows === 1 && size === "small" ? ".25rem" : "1rem",
                          right:
                            rows === 1 && size === "small" ? ".5rem" : "1rem",
                          backgroundColor: handleBackgroundColor({
                            theme,
                            active: isActive,
                            borderActive: borderActive,
                          }),
                          borderRadius: "50%",
                          height: "34px",
                          width: "34px",
                          zIndex: 1,
                        }}
                      >
                        <IconButton
                          size="small"
                          onClick={() => {
                            setOpenDialog(true);
                          }}
                          disabled={isDisabled}
                        >
                          <ModeEditOutlinedIcon color="primary" />
                        </IconButton>
                      </div>
                    </Fade>
                  </span>
                </Tooltip>
              </div>
            ),
          }}
        />
      </div>
      {isExpandable && openDialog && (
        <Dialog
          title={t("lib.ui.inputs.descriptionField.dialog.title")}
          open={openDialog}
          onCancel={() => handleOnCancel()}
          onSubmit={() => handleOnSubmit()}
          actionButtonLabels={{
            confirm: t("lib.ui.inputs.descriptionField.dialog.submitText"),
          }}
          expandOptions={{}}
        >
          <TextField
            {...rest}
            inputRef={(e: HTMLInputElement) => {
              dialogInputRef.current = e;
            }}
            style={fireFoxStyle}
            className={classNames(style, classes.inputFieldDesign)}
            fullWidth
            required
            label={label}
            value={dialogTextFieldValue}
            onChange={(e) => setDialogTextFieldValue(e.target.value)}
            InputLabelProps={{ shrink: true }}
            minRows={6}
            maxRows={20}
            multiline
          />
        </Dialog>
      )}
    </>
  );
};
