import {
  useState,
  MouseEvent,
  useEffect,
  useRef,
  FunctionComponent,
} from "react";
// Helper
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { DateTime } from "luxon";
// Dyce-Lib
import {
  DatePickerButton,
  RecordDatePicker,
  TooltipSwitcher,
  useStaticContent,
} from "@dyce/ui";
import { DyceTheme } from "@dyce/theme";
import {
  JobTaskStatusOptions,
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  StatusOptions,
  ResourceCapacity,
  ValidatedRecord,
} from "@dyce/tnt-api";
import {
  selectLocaleHyphen,
  selectTimeTrackingToolDesign,
  useAppSelector,
} from "@dyce/slices";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import ScheduleSharpIcon from "@mui/icons-material/ScheduleSharp";
import BookmarkBorderOutlinedIcon from "@mui/icons-material/BookmarkBorderOutlined";
import MoreVertSharpIcon from "@mui/icons-material/MoreVertSharp";
// Components
import { ItemEntriesInfo, ItemEntriesProgress } from "./components";
import { DesignOptions } from "@dyce/interfaces";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      flexWrap: "nowrap",
      gap: "1rem",
      minHeight: theme.palette.propsDyce.timeRecItemHeight.commonHeight,
    },
    clickContainer: {
      display: "inherit",
      width: "100%",
      cursor: "pointer",
    },
    timeContainer: {
      display: "flex",
      flexDirection: "row",
    },
    iconContainer: {
      display: "flex",
      minWidth: theme.palette.propsDyce.timeRecItemHeight.commonHeight,
      alignItems: "center",
      justifyContent: "center",
    },
    timeSpan: {
      display: "flex",
      flexWrap: "wrap",
      alignContent: "center",
      width: theme.palette.propsDyce.selectedLocale.includes("en")
        ? "8.5rem"
        : "5rem",
      justifyItems: "space-around",
      minHeight: theme.palette.propsDyce.timeRecItemHeight.commonHeight,
      transition: "all 0.3s ease",
      [theme.breakpoints.down("timeRecsMin")]: {
        width: theme.palette.propsDyce.selectedLocale.includes("en")
          ? "5rem"
          : "3rem",
      },
    },
    timeSpanItems: {
      display: "inherit",
      [theme.breakpoints.down("timeRecsMin")]: {
        flexDirection: "column",
      },
    },
    timeStart: {
      display: "inherit",
    },
    timeDivider: {
      display: "inherit",
      justifyContent: "center",
      paddingLeft: "0.25rem",
      paddingRight: "0.25rem",
    },
    timeEnd: {
      display: "flex",
      alignItems: "flex-start",
    },
    descriptionContainer: {
      display: "flex",
      width: "35%",
      justifyContent: "flex-start",
      margin: "0 1rem",
      [theme.breakpoints.down("timeRecsMin")]: {
        minWidth: 250,
      },
      [theme.breakpoints.down("timeRecsMidi")]: {
        minWidth: 175,
      },
    },
    descriptionText: {
      display: "flex",
      "& .MuiTypography-root": {
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      width: "100%",
      alignItems: "center",
      maxHeight: theme.palette.propsDyce.timeRecItemHeight.commonHeight,
    },
    projectTaskContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignContent: "end",
      width: "100%",
      gap: "3rem",
      maxWidth: 1030,
      justifyItems: "flex-start",
      [theme.breakpoints.down("timeRecsMax")]: {
        maxWidth: 825,
      },
      [theme.breakpoints.down("timeRecsMid")]: {
        maxWidth: 705,
      },
      [theme.breakpoints.down("timeRecsMin")]: {
        maxWidth: 600,
        gap: ".5rem",
      },
    },
    customerJob: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingTop: "3px",
      columnGap: "1rem",
      justifyItems: "center",
      minWidth: 180,
      width: "50%",
      [theme.breakpoints.down("timeRecsMid")]: {
        display: "grid",
      },
    },
    tasks: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      paddingTop: "3px",
      width: "50%",
      columnGap: "1rem",
      minWidth: 180,
      [theme.breakpoints.down("timeRecsMid")]: {
        display: "grid",
      },
    },
    textWithSeparator: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      minWidth: 180,
    },
    textSeparator: {
      display: "inherit",
      width: "1rem",
    },
    textSecond: {
      flex: 1,
      minWidth: 180,
      [theme.breakpoints.down("timeRecsMax")]: {
        width: "100%",
      },
    },
    symbolContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      backgroundColor: "transparent",
      borderTopRightRadius: 4,
      borderBottomRightRadius: 4,
      cursor: "pointer",
    },
    statusIcon: {
      display: "inherit",
      width: 50,
      alignItems: "center",
      justifyContent: "center",
      paddingLeft: 15,
    },
    noEntry: {
      color: theme.palette.error.main,
      fontSize: "1.2rem",
    },
    placeHolder: {
      height: 20,
    },
    isReleased: {
      opacity: 0.5,
    },
    buttonContainer: {
      height: "75%",
      display: "flex",
      alignSelf: "center",
    },
  })
);

interface IRecordListItemEntriesProps {
  /**
   * Single list-item entry object
   */
  record: RecordTimeRec | RecordTemplate;
  /**
   * Callback fired at onClick event, when user clicks a list-item
   */
  onRecordClicked: (id: string) => void;
  /**
   * Callback fired when menu icon is clicked; Used to eventually delay open menu
   */
  handleOpenMenuClick: (event: MouseEvent<HTMLElement>) => void;
  /**
   * Capacity from current user
   */
  resourceCapacity: ResourceCapacity[] | null;
  /**
   * Show JSX.Element left besides Info-icon || Template-Button
   */
  graphicalAddition?: ({
    isReleased,
    duration,
  }: {
    isReleased: boolean;
    duration: number;
  }) => JSX.Element;
  /**
   * Props forwarded to TemplateDatePicker; Provide props when needed in ListItemEntry
   */
  templateDatePickerProps?: {
    onSave: (
      entry: RecordEntry & Partial<RecordTemplate & RecordTimeRec>,
      isTimeRecord?: boolean
    ) => Promise<{
      newEntry: RecordEntry | RecordTemplate | null;
      error: boolean;
    }>;
    validateTemplateObject?: (
      template: RecordTemplate
    ) => Promise<ValidatedRecord>;
    clearLatestCreatedId: () => void;
    openCreatedRecording: (value: boolean) => void;
    deleteRecord: (id: string) => Promise<boolean>;
    openInTimerecordings: boolean;
  };
}

export const RecordListItemEntries: FunctionComponent<
  IRecordListItemEntriesProps
> = ({
  record,
  onRecordClicked,
  handleOpenMenuClick,
  resourceCapacity,
  graphicalAddition,
  templateDatePickerProps,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<DyceTheme>();
  const { docuLinks } = useStaticContent();
  const isIpadAndSmaller = useMediaQuery((theme: DyceTheme) =>
    theme.palette.propsDyce.drawerLeft.isOpen
      ? theme.breakpoints.down(1475)
      : theme.breakpoints.down(1275)
  );

  // References
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  // Selectors
  const localeHyphen = useAppSelector(selectLocaleHyphen);
  const designId = useAppSelector(selectTimeTrackingToolDesign);

  // States
  const [released, setReleased] = useState<boolean>(false);
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [renderCalendar, setRenderCalendar] = useState<boolean>(false);

  useEffect(() => {
    if (isRecord(record)) {
      setReleased(record.status !== StatusOptions.OPEN);
    } else {
      setIsTemplate(true);
    }
  }, [record]);

  const isRecord = (
    entry: RecordTimeRec | RecordTemplate
  ): entry is RecordTimeRec => {
    return (entry as RecordTimeRec).date !== undefined;
  };

  return (
    <>
      <Paper
        elevation={theme.palette.propsDyce.paperDesign.elevation}
        className={classes.container}
      >
        <div
          data-testid="open-editor-from-entry"
          className={classes.clickContainer}
          onClick={() => onRecordClicked(record.id)}
        >
          <div className={classes.iconContainer}>
            {isTemplate ? (
              <BookmarkBorderOutlinedIcon
                color={released ? "disabled" : "secondary"}
              />
            ) : (
              <ScheduleSharpIcon color={released ? "disabled" : "secondary"} />
            )}
          </div>
          <div className={classes.timeContainer}>
            {(designId === DesignOptions.DYNAMIC ||
              designId === DesignOptions.ALL) && (
              <div className={classes.timeSpan}>
                <div className={classes.timeSpanItems}>
                  <div
                    className={classNames(
                      classes.timeStart,
                      released ? classes.isReleased : ""
                    )}
                  >
                    <Typography variant="body2">
                      {record.start === null
                        ? ""
                        : DateTime.fromISO(record.start).toFormat("t", {
                            locale: localeHyphen,
                          })}
                    </Typography>
                    <div
                      className={classNames(
                        classes.timeDivider,
                        released ? classes.isReleased : ""
                      )}
                    >
                      <Typography variant="body2">
                        {record.start === null ? "" : "-"}
                      </Typography>
                    </div>
                  </div>
                  <div
                    className={classNames(
                      classes.timeEnd,
                      released ? classes.isReleased : ""
                    )}
                  >
                    <Typography variant="body2">
                      {record.end === null
                        ? ""
                        : record.start === null
                        ? "- " +
                          DateTime.fromISO(record.end).toFormat("t", {
                            locale: localeHyphen,
                          })
                        : DateTime.fromISO(record.end).toFormat("t", {
                            locale: localeHyphen,
                          })}
                    </Typography>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={classes.descriptionContainer}
            style={{
              width:
                designId === DesignOptions.SIMPLE ||
                designId === DesignOptions.COMPACT
                  ? "25%"
                  : "35%",
            }}
          >
            <div className={classes.descriptionText}>
              <Typography
                variant="body2"
                className={classNames(
                  isTemplate
                    ? ""
                    : record.description === null ||
                      record.description.length === 0
                    ? classes.noEntry
                    : "",
                  released ? classes.isReleased : ""
                )}
              >
                {record.description === null || record.description.length === 0
                  ? isTemplate
                    ? "–"
                    : "*"
                  : isIpadAndSmaller
                  ? record.description.length > 50
                    ? record.description.substring(0, 47) + "..."
                    : record.description
                  : record.description.length > 100
                  ? record.description.substring(0, 97) + "..."
                  : record.description}
              </Typography>
            </div>
          </div>
          <>
            {designId !== DesignOptions.SIMPLE ? (
              <div className={classes.projectTaskContainer}>
                <div className={classes.customerJob}>
                  <div className={classes.textWithSeparator}>
                    <Typography
                      variant="body2"
                      noWrap
                      className={classNames(
                        isTemplate
                          ? ""
                          : !record.customer
                          ? classes.noEntry
                          : "",
                        released ? classes.isReleased : ""
                      )}
                    >
                      {!record.customer
                        ? isTemplate
                          ? "–"
                          : "*"
                        : record.customer && record.customer.name}
                      {/* {"Customer"} */}
                    </Typography>
                    <div className={classes.textSeparator} />
                  </div>
                  <Typography
                    variant="body2"
                    noWrap
                    className={classNames(
                      classes.textSecond,
                      isTemplate ? "" : !record.job ? classes.noEntry : "",
                      released ? classes.isReleased : ""
                    )}
                  >
                    {!record.job
                      ? isTemplate
                        ? "–"
                        : "*"
                      : record.job.description}
                  </Typography>
                </div>
                <div className={classes.tasks}>
                  <div className={classes.textWithSeparator}>
                    <Typography
                      variant="body2"
                      noWrap
                      className={classNames(
                        isTemplate
                          ? ""
                          : !record.jobTask
                          ? classes.noEntry
                          : "",
                        released ? classes.isReleased : ""
                      )}
                    >
                      {!record.jobTask
                        ? isTemplate
                          ? "–"
                          : "*"
                        : record.jobTask.description}
                    </Typography>
                    <div className={classes.textSeparator} />
                  </div>
                  <Typography
                    variant="body2"
                    noWrap
                    className={classNames(
                      classes.textSecond,
                      !record.tntModelLine || !record.tntModelLine.id
                        ? classes.placeHolder
                        : "",
                      released ? classes.isReleased : ""
                    )}
                  >
                    {!record.tntModelLine
                      ? ""
                      : record.tntModelLine.description}
                  </Typography>
                </div>
              </div>
            ) : (
              <div className={classes.projectTaskContainer}>
                <Box
                  className={classes.customerJob}
                  sx={{
                    width: "65%",
                    [theme.breakpoints.down("timeRecsMid")]: {
                      display: "flex",
                      width: "65%",
                    },
                    [theme.breakpoints.down("timeRecsMidi")]: {
                      display: "grid",
                      width: "50%",
                    },
                  }}
                >
                  <div className={classes.textWithSeparator}>
                    <Typography
                      variant="body2"
                      noWrap
                      className={classNames(
                        isTemplate
                          ? ""
                          : record.job === null
                          ? classes.noEntry
                          : "",
                        released ? classes.isReleased : ""
                      )}
                    >
                      {record.job === null
                        ? isTemplate
                          ? "–"
                          : "*"
                        : record.job.description}
                      {/* {"Job"} */}
                    </Typography>
                    <div className={classes.textSeparator} />
                  </div>
                  <Typography
                    variant="body2"
                    noWrap
                    className={classNames(
                      classes.textSecond,
                      isTemplate
                        ? ""
                        : record.jobTask === null
                        ? classes.noEntry
                        : "",
                      released ? classes.isReleased : ""
                    )}
                  >
                    {record.jobTask === null
                      ? isTemplate
                        ? "–"
                        : "*"
                      : record.jobTask.description}
                  </Typography>
                </Box>
                <Box
                  className={classes.tasks}
                  sx={{
                    flex: "auto",
                    width: "35%",
                    justifyContent: "flex-end",
                    [theme.breakpoints.down("timeRecsMid")]: {
                      display: "flex",
                      width: "35%",
                    },
                    [theme.breakpoints.down("timeRecsMidi")]: {
                      display: "flex",
                      justifyContent: "center",
                      width: "50%",
                    },
                  }}
                >
                  <Typography
                    variant="body2"
                    noWrap
                    className={classNames(
                      !record.tntModelLine || !record.tntModelLine.id
                        ? classes.placeHolder
                        : "",
                      released ? classes.isReleased : ""
                    )}
                  >
                    {!record.tntModelLine
                      ? ""
                      : record.tntModelLine.description}
                  </Typography>
                </Box>
              </div>
            )}
          </>
        </div>
        <div className={classes.symbolContainer}>
          <div onClick={() => onRecordClicked(record.id)}>
            <div>
              {graphicalAddition ? (
                graphicalAddition({
                  isReleased: released,
                  duration: record.duration,
                })
              ) : (
                <ItemEntriesProgress
                  date={isRecord(record) ? record.date : ""}
                  duration={record.duration}
                  releasedStyle={released ? classes.isReleased : ""}
                  optionalView={isTemplate}
                  isReleased={released}
                  resourceCapacity={resourceCapacity}
                />
              )}
            </div>
          </div>
          {!isTemplate && templateDatePickerProps ? (
            <div
              className={classes.statusIcon}
              onClick={() => onRecordClicked(record.id)}
            >
              <ItemEntriesInfo
                record={record}
                isReleased={released}
                isComplete={isRecord(record) ? record.complete : false}
              />
            </div>
          ) : (
            <div className={classes.buttonContainer}>
              <TooltipSwitcher
                tooltip={
                  record.jobTask === null
                    ? null
                    : record.jobTask.status !== undefined &&
                      record.jobTask.status !== JobTaskStatusOptions.OPEN
                    ? t("tooltip.record.jobTaskStatusNotOpen")
                    : null
                }
                urlPath={
                  docuLinks.timetracking.timerecordings.records.contextMenu
                    .jobTaskNotOpen
                }
              >
                <DatePickerButton
                  getReference={(e) => (buttonRef.current = e)}
                  onClick={() => {
                    setRenderCalendar(true);
                    setOpenCalendar(true);
                  }}
                  disabled={
                    openCalendar ||
                    (record.jobTask !== null &&
                      record.jobTask.status !== undefined &&
                      record.jobTask.status !== JobTaskStatusOptions.OPEN)
                  }
                />
              </TooltipSwitcher>
            </div>
          )}
          <div className={classes.iconContainer}>
            <IconButton id="menuButton" onClick={handleOpenMenuClick}>
              <MoreVertSharpIcon />
            </IconButton>
          </div>
        </div>
      </Paper>
      {renderCalendar && templateDatePickerProps && (
        <RecordDatePicker
          open={openCalendar}
          anchorEl={buttonRef.current}
          onClose={({ openCalendar, renderCalendar }) => {
            setOpenCalendar(openCalendar);
            setRenderCalendar(renderCalendar);
          }}
          returnProps={{
            fromLocation: "Templates",
            scrollPosition: isRecord(record) ? 0 : window.scrollY,
          }}
          templateEntry={record as RecordTemplate}
          onSave={templateDatePickerProps.onSave}
          localeHyphen={localeHyphen}
          validateTemplateObject={
            templateDatePickerProps.validateTemplateObject
          }
          templateCategory={null}
          states={{
            openCreatedRecording: templateDatePickerProps.openCreatedRecording,
            clearLatestCreatedId: templateDatePickerProps.clearLatestCreatedId,
            deleteRecord: templateDatePickerProps.deleteRecord,
            isChecked: templateDatePickerProps.openInTimerecordings,
          }}
        />
      )}
    </>
  );
};
