import { JobViewEntry, ODataArrayResponse, Resource } from "../types";
import buildQuery, { QueryOptions } from "odata-query";
import axios, { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

export class ResourceService {
  public baseUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = combineURLs(baseUrl, "/Resources");

    this.axiosInstance = instance;
  }

  /**
   * Gets all resources, if this user has access to it
   * @param query OData query
   * @example
   * const client = new TnTClient(args).getResourceService()
   * await client.getAll()
   */
  public async getAll(
    query?: Partial<QueryOptions<Resource>>
  ): Promise<Resource[]> {
    const url = `${this.baseUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Resource[]>(url);

    return response.data;
  }

  /**
   * Update a single resource
   * @param id
   * @param payload
   * const client = new TnTClient(args).getResourceService()
   * await client.update("0000-0000-0000-0000", { name: "New Example" })
   */
  public async update(
    id: string,
    payload: Partial<Resource>
  ): Promise<Resource> {
    const url = `${this.baseUrl}`;

    const result = await this.axiosInstance.put<Resource>(url, {
      ...payload,
      id,
    });

    return result.data;
  }

  /**
   * Gets all assigned jobs to current resource
   * @param query OData query
   * @returns Assigned Jobs
   */
  public async getTasks(
    query?: Partial<QueryOptions<JobViewEntry>>
  ): Promise<ODataArrayResponse<JobViewEntry[]>> {
    const url = `${this.baseUrl}/jobassignments${buildQuery(query)}`;

    const result =
      await this.axiosInstance.get<ODataArrayResponse<JobViewEntry[]>>(url);

    return result.data;
  }
}
