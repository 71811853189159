import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  AnyAction,
} from "@reduxjs/toolkit";
import {
  CurrentUser,
  Instance,
  Stopwatch,
  PopulatedRole,
  User,
} from "@dyce/tnt-api";
import { tntApiHelper } from "../../apiHelper";
import { RootState, UserStateSlice } from "../../types/types";
import { InvalidUser } from "@dyce/interfaces";

const validateRequestedInstance = createAsyncThunk<
  void,
  { instance: string; company: string },
  { state: RootState; rejectValue: Error }
>(
  "user/validateInstance",
  async ({ instance, company }, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getInstanceService();

      const result: Instance = await client.getDefault();

      if (result.companies.find((c) => c.name === company) === undefined) {
        return rejectWithValue(new Error("not found"));
      } else {
        return void {};
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const getDefaultInstance = createAsyncThunk<
  Instance,
  undefined,
  { state: RootState; rejectValue: Error }
>("user/getDefaultInstanceStatus", async (_, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getInstanceService();

    return client.getDefault();
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

const getInstances = createAsyncThunk<
  Instance[],
  undefined,
  { state: RootState; rejectValue: Error }
>("user/getInstancesStatus", async (_, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getInstanceService();

    return await client.getAll();
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

const getCurrentUser = createAsyncThunk<
  CurrentUser & {
    stopwatch: Stopwatch | null;
  },
  Record<string, unknown>,
  { state: RootState; rejectValue: Error }
>("user/getCurrentUser", async (query, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getAdminService();

    return await client.users.getCurrent(query);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getUserRolesForInstance = createAsyncThunk<
  CurrentUser & {
    roles: PopulatedRole[];
  },
  { instance: string; company: string },
  { state: RootState; rejectValue: Error }
>(
  "user/getUserRoleForInstance",
  async ({ instance, company }, { getState, rejectWithValue }) => {
    try {
      const client = await (
        await tntApiHelper(getState, {
          preInstance: instance,
          preCompany: company,
        })
      ).getAdminService();
      const query = {
        expand: "resource, roles",
      };

      return await client.users.getCurrent(query);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    instance: localStorage.getItem("instance"),
    company: localStorage.getItem("mandate"),
    instanceValidated: null,
    instances: [],
    currentUser: {
      id: "",
      externalId: "",
      name: "",
      email: "",
      resource: null,
      roles: [
        {
          id: "",
          description: "",
          type: "",
        },
      ],
      stopwatch: null,
    },
    userById: {},
    query: "?$count=true&$expand=roles&$top=20",
    isLoading: false,
    isLoadingUser: null,
    isValidatingInstance: false,
    invalidGrant: false,
    invalidGrantUserInfo: null,
  } as UserStateSlice,
  reducers: {
    setInstance(
      state,
      action: PayloadAction<{ instance: Instance; refreshUser: boolean }>
    ) {
      if (action.payload.refreshUser) {
        // Refresh user per API-call by useEffect hook with new instance
        state.currentUser.id = "";
      }
      state.instance = action.payload.instance.name;
      if (state.company) state.instanceValidated = true;
      localStorage.setItem("instance", action.payload.instance.name);
    },
    setCompany(
      state,
      action: PayloadAction<{ company: string; refreshUser: boolean }>
    ) {
      if (action.payload.refreshUser) {
        // Refresh user per API-call by useEffect hook with new company
        state.currentUser.id = "";
      }
      state.company = action.payload.company;
      if (state.instance) state.instanceValidated = true;
      localStorage.setItem("mandate", action.payload.company);
    },
    setResetCompanyMapping(state) {
      state.instance = null;
      state.company = null;
      localStorage.removeItem("instance");
      localStorage.removeItem("mandate");
    },
    setValidateInstance(state, action: PayloadAction<boolean>) {
      state.instanceValidated = action.payload;
    },
    setUserById(state, action: PayloadAction<User>) {
      state.userById = action.payload;
    },
    setOdataQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
    setCurrentUserRoles(state, action: PayloadAction<PopulatedRole[]>) {
      state.currentUser = { ...state.currentUser, roles: action.payload };
    },
    setInvalidGrantLogin(
      state,
      action: PayloadAction<boolean | InvalidUser | null>
    ) {
      if (typeof action.payload === "object") {
        state.invalidGrantUserInfo = action.payload;
      } else if (action.payload === null) {
        state.invalidGrantUserInfo = null;
      } else {
        state.invalidGrant = action.payload;
      }
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(validateRequestedInstance.pending, (state) => {
        state.isValidatingInstance = true;
      })
      .addCase(validateRequestedInstance.fulfilled, (state, action) => {
        state.instance = action.meta.arg.instance;
        state.company = action.meta.arg.company;

        localStorage.setItem("instance", action.meta.arg.instance);
        localStorage.setItem("mandate", action.meta.arg.company);

        state.isValidatingInstance = false;
        state.instanceValidated = true;
      })
      .addCase(getDefaultInstance.fulfilled, (state, action) => {
        state.instance = action.payload.name;
        state.instanceValidated = true;
      })
      .addCase(getInstances.fulfilled, (state, action) => {
        state.instances = action.payload;
        state.isLoading = false;
      })
      .addCase(validateRequestedInstance.rejected, (state) => {
        state.instanceValidated = false;
        state.isValidatingInstance = false;
      })
      .addCase(getDefaultInstance.rejected, (state) => {
        state.instanceValidated = false;
      })
      .addCase(getCurrentUser.pending, (state) => {
        state.isLoadingUser = true;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        if (JSON.stringify(action.payload).length > 3) {
          state.currentUser = action.payload;
        } else {
          state.currentUser = {
            ...state.currentUser,
            name: "anonymous",
            id: "0815-1337",
          };
        }
        state.isLoadingUser = false;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        if ((action as any).payload.code === 403) {
          localStorage.removeItem("instance");
          localStorage.removeItem("mandate");
          window.location.reload();
        }
      })
      .addMatcher(
        (action: AnyAction): action is PayloadAction<null> =>
          action.type.endsWith("pending"),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<null> =>
          action.type.endsWith("fulfilled") || action.type.endsWith("rejected"),
        (state) => {
          state.isLoading = false;
        }
      ),
});

export const {
  setInstance,
  setCompany,
  setResetCompanyMapping,
  setValidateInstance,
  setUserById,
  setOdataQuery,
  setCurrentUserRoles,
  setInvalidGrantLogin,
} = userSlice.actions;

export {
  validateRequestedInstance,
  getDefaultInstance,
  getInstances,
  getCurrentUser,
};
export default userSlice.reducer;
function buildQuery(query: Record<string, unknown>) {
  throw new Error("Function not implemented.");
}
