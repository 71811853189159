import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Helper
import { useHotkeys } from "react-hotkeys-hook";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { LoadingSpinner, SubHeader, Tooltip, useStaticContent } from "@dyce/ui";
import { useUpdateEffect } from "@dyce/hooks";
import {
  clearTemplateList,
  getAllCategories,
  getAllTemplates,
  isLoadingEntriesTemplates,
  RootState,
  selectCategoriesAsArray,
  selectCategoriesWithEntries,
  selectCurrentWorkspace,
  selectHasResource,
  setAllowNetworkWatcher,
  useAppDispatch,
  useAppSelector,
} from "@dyce/slices";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Container, Fab, Button } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// Components
import { TemplateList } from "../../../components/timerecording";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    recordsContainer: { ...theme.palette.propsTimeTracking.recordsContainer },
    children: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexGrow: 1,
      height: "3.5rem",
      alignItems: "center",
    },
    weekContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      width: "100%",
    },
    buttons: {
      display: "flex",
      justifyContent: "space-between",
      width: "16rem",
      flexDirection: "row",
    },
    buttonsRight: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "auto",
    },
    addButton: {
      paddingBottom: "1.5rem",
      paddingTop: "1.5rem",
    },
  })
);

export const Templates: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const {
    docuLinks,
    platform,
    isMobile: { mobile },
  } = useStaticContent();

  // Selectors
  const templates = useAppSelector(selectCategoriesWithEntries);
  const categories = useAppSelector(selectCategoriesAsArray);
  const isLoading = useAppSelector(
    (state: RootState): unknown[] => state.templates.requests
  );
  const loadingEntries = useAppSelector(isLoadingEntriesTemplates);
  const workspace = useAppSelector(selectCurrentWorkspace);
  const resource = useAppSelector(selectHasResource);

  // UseStates
  const [addCategory, setAddCategory] = useState(false);
  const [addEntry, setAddEntry] = useState(false);
  const [initLoading, setInitLoading] = useState(true);

  // UseEffects
  useEffect(() => {
    //Initial ApiCall
    callApi();
  }, [workspace, resource]);

  // used to reset the state back to initial after button was pressed
  useEffect(() => {
    if (addEntry) {
      setAddEntry(false);
    }
  }, [addEntry]);

  // unset initial loading when all network requests are finished
  useUpdateEffect(() => {
    if (isLoading.length === 0) {
      setInitLoading(false);
    }
  }, [isLoading]);

  // UseHotKeys
  useHotkeys(
    // prevent refresh page && callApi again
    platform.modifiers.refreshPage,
    (e) => {
      if (!initLoading) {
        e.preventDefault();
        setInitLoading(true);
        dispatch(clearTemplateList());
        // API Call
        callApi();
      }
    },
    [initLoading]
  );

  const callApi = () => {
    dispatch(getAllTemplates());
    dispatch(getAllCategories());
  };

  return (
    <>
      <SubHeader
        tooltipLabel={t("timerec.subHeader.myTime.templates.info")}
        tooltipUrlPath={docuLinks.timetracking.templates.subheader.info}
        title={t("timerec.subHeader.myTime.templates.title")}
        subtitle={t("timerec.subHeader.myTime.templates.subtitle")}
        isLoading={isLoading.length > 0}
        textMobile={mobile}
      >
        <Tooltip
          label={t("timerec.myTime.templates.addEntry")}
          urlPath={
            docuLinks.timetracking.templates.subheader.createCategoryButton
          }
          position={"bottom-end"}
        >
          <Fab
            aria-label="day-picker"
            color="primary"
            onClick={() => setAddEntry(true)}
            style={{ marginLeft: 20 }}
          >
            <AddOutlinedIcon fontSize="medium" />
          </Fab>
        </Tooltip>
      </SubHeader>
      {initLoading ? (
        <LoadingSpinner
          allowNetworkWatcher={(value) =>
            dispatch(setAllowNetworkWatcher(value))
          }
        />
      ) : (
        <div className={classes.recordsContainer}>
          <Container maxWidth={false}>
            <div className={classes.addButton}>
              <Button
                variant="contained"
                onClick={() => setAddCategory(true)}
                startIcon={<AddOutlinedIcon />}
                disabled={addCategory}
                data-testid="add-category-button"
              >
                {t("timerec.subheader.myTime.templates.add")}
              </Button>
            </div>
            {!loadingEntries && (
              <TemplateList
                templates={templates}
                templateCategories={categories}
                addCategory={addCategory}
                addEntry={addEntry}
                onClose={() => setAddCategory(false)}
              />
            )}
          </Container>
        </div>
      )}
    </>
  );
};
