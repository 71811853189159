import { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

export class OnboardingService {
  public baseUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = combineURLs(baseUrl, "/Onboarding");

    this.axiosInstance = instance;
  }

  /**
   * Create new BC entry
   * @example
   * const client = new TnTClient(args).getOnboardingService()
   * await client.createNewEntry()
   */
  public async createNewEntry() {
    const url = `${this.baseUrl}/createnew`;

    const response = await this.axiosInstance.get(url);

    return response.data;
  }

  /**
   * Get the default instance of the current user
   * @param payload number
   * @example
   * const client = new TnTClient(args).getOnboardingService()
   * await client.getOnboardingStateEntry('number')
   */
  public async getOnboardingStateEntry(payload: number) {
    const url = `${this.baseUrl}/getstate`;

    const response = await this.axiosInstance.post<number>(url, payload);

    return response.data;
  }
}
