import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { Instance as InstanceType } from "@dyce/tnt-api";
import { InstanceTree, LoadingSpinner } from "@dyce/ui";
import {
  useAppDispatch,
  useAppSelector,
  fullUserSelector,
  getInstances,
  setInstance,
  setCompany,
  setAllowNetworkWatcher,
  setValidateInstance,
} from "@dyce/slices";
// MUI
import { Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    rootContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      flexFlow: "row",
      width: "100%",
      height: "100%",
    },
    messageContainer: {
      maxWidth: 540,
      verticalAlign: "center",
    },
    header: {
      marginBottom: "20px !important",
    },
    spacer: {
      height: 20,
    },
    error: {
      color: "red",
      marginBottom: 20,
    },
  })
);

export const Instance: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Selectors
  const { instances, instance, company, instanceValidated } =
    useAppSelector(fullUserSelector);

  // UseState
  const [didLoad, setDidLoad] = useState(false);

  // UseEffects
  useEffect(() => {
    dispatch(getInstances()).then((res) => {
      if (res.type.endsWith("rejected") && (res as any).payload.code >= 400) {
        sessionStorage.clear();
        window.location.href = "/?usernotfound=true";
      } else {
        setDidLoad(true);
      }
    });
  }, []);

  useEffect(() => {
    if (
      instance === null &&
      company === null &&
      instances.length === 1 &&
      instances[0].companies.length === 1
    ) {
      dispatch(setInstance({ instance: instances[0], refreshUser: true }));
      dispatch(
        setCompany({
          company: instances[0].companies[0].name,
          refreshUser: true,
        })
      );
      dispatch(setValidateInstance(true));
    }
  }, [instances]);

  // Handler
  const handleMandantSelection = (instance: InstanceType, company: string) => {
    dispatch(setInstance({ instance, refreshUser: true }));
    dispatch(setCompany({ company, refreshUser: true }));
  };

  return (
    <div className={classes.rootContainer}>
      <div className={classes.messageContainer}>
        {didLoad ? (
          <>
            <Typography variant="h2" className={classes.header}>
              {t("instance.message.title")}
            </Typography>
            <Typography className={classes.error}>
              {!instanceValidated && t("instance.message.error")}
            </Typography>
            <Typography>
              {t("instance.message.body")}
              <div className={classes.spacer} />
              <InstanceTree
                instance={instance ? instance : ""}
                company={company ? company : ""}
                instances={instances}
                onSelection={handleMandantSelection}
              />
            </Typography>
          </>
        ) : (
          <LoadingSpinner
            allowNetworkWatcher={(value) =>
              dispatch(setAllowNetworkWatcher(value))
            }
          />
        )}
      </div>
    </div>
  );
};
