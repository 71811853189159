import { FunctionComponent, useEffect, useRef, useState } from "react";
// Helper
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { handleDescription } from "./utils";
// Dyce-Lib
import { calculateHours } from "@dyce/utils";
import {
  ActivityTotal,
  JobTotal,
  PopulatedBillableTotal,
  StatisticsPiePeriod,
} from "@dyce/tnt-api";
import { DyceTheme } from "@dyce/theme";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import {
  Box,
  Grow,
  List,
  ListItem,
  ListItemText,
  Typography,
  useTheme,
} from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
    },
    listContent: {
      display: "flex",
      height: "100%",
      width: "100%",
      alignItems: "center",
    },
    listItem: {
      display: "flex",
      alignItems: "center",
    },
    pie: {
      "& .recharts-layer.recharts-pie-sector": {
        outline: "none",
      },
    },
  })
);

interface IStatisticsPlotProps {
  /**
   * Height of the container
   * @default 400
   */
  containerHeight?: number;
  /**
   * Data to be displayed in pie chart
   */
  data: StatisticsPiePeriod;
  /**
   * Colors to be used for pie chart; Should be in same length as data
   */
  colors: string[];
  /**
   * Is the device an iPad, then position of pie text above is adjusted
   * @default false
   */
  isIpad?: boolean;
  /**
   * Define which key to use for color coding
   */
  visualizationType: "jobTotals" | "activityTotals" | "billableTotals";
}

export const StatisticsPlot: FunctionComponent<IStatisticsPlotProps> = ({
  containerHeight = 400,
  colors,
  data,
  isIpad = false,
  visualizationType,
}) => {
  const classes = useStyles();
  const viewPortChange = 800;
  const longNamesSpace = 700;
  const theme = useTheme<DyceTheme>();

  // Refs
  const chartRef = useRef<HTMLDivElement | null>(null);

  // States
  const [render, setRender] = useState<boolean>(false);
  const [activePie, setActivePie] = useState<number>(0);

  // UseEffects
  useEffect(() => {
    let renderTimer: NodeJS.Timeout;
    if (!render) {
      renderTimer = setTimeout(() => setRender(true), 450);
    }
    return () => clearTimeout(renderTimer);
  }, []);

  // Handler
  const onPieEnter = (_: any, index: number) => {
    setActivePie(index);
  };

  return (
    <div ref={chartRef} className={classes.container}>
      {chartRef && chartRef.current && (
        <Box
          sx={{
            height: containerHeight || 400,
            padding:
              chartRef &&
              chartRef.current &&
              chartRef.current.clientWidth < viewPortChange
                ? "0"
                : "1rem",
            width:
              chartRef &&
              chartRef.current &&
              chartRef.current.clientWidth < viewPortChange
                ? "30%"
                : "40%",
          }}
        >
          <div className={classes.listContent}>
            <List
              dense={false}
              sx={{
                "& .MuiListItem-root":
                  chartRef &&
                  chartRef.current &&
                  chartRef.current.clientWidth < viewPortChange
                    ? {
                        paddingLeft: "0",
                        paddingRight: "0",
                        paddingTop:
                          data[visualizationType].length > 7
                            ? isIpad
                              ? "1px"
                              : "2px"
                            : "8px",
                        paddingBottom:
                          data[visualizationType].length > 7
                            ? isIpad
                              ? "1px"
                              : "2px"
                            : "8px",
                      }
                    : {
                        paddingTop:
                          data[visualizationType].length > 7
                            ? isIpad
                              ? "1px"
                              : "2px"
                            : "8px",
                        paddingBottom:
                          data[visualizationType].length > 7
                            ? isIpad
                              ? "0"
                              : "2px"
                            : "8px",
                      },
              }}
            >
              {data[visualizationType].length === 0 ? (
                <PieListEntries
                  index={0}
                  item={null}
                  internalColor={colors}
                  fromBillable={visualizationType === "billableTotals"}
                  sliceLongNames={
                    chartRef &&
                    chartRef.current &&
                    chartRef.current.clientWidth < longNamesSpace
                  }
                />
              ) : (
                data[visualizationType].map((item, index) => (
                  <PieListEntries
                    key={index}
                    item={item}
                    index={index}
                    internalColor={colors}
                    fromBillable={visualizationType === "billableTotals"}
                    sliceLongNames={
                      chartRef && chartRef.current
                        ? chartRef.current.clientWidth < longNamesSpace
                        : true
                    }
                  />
                ))
              )}
            </List>
          </div>
        </Box>
      )}
      <ResponsiveContainer
        id="project-plot"
        width={
          chartRef &&
          chartRef.current &&
          chartRef.current.clientWidth < viewPortChange
            ? "70%"
            : "60%"
        }
        height={containerHeight || 400}
      >
        <PieChart width={400} height={containerHeight || 400}>
          {render && (
            <Pie
              className={classes.pie}
              activeIndex={activePie}
              startAngle={90}
              endAngle={-270}
              onMouseEnter={onPieEnter}
              onMouseLeave={() => {}}
              data={
                data[visualizationType].length === 0
                  ? [
                      {
                        description: "No Data",
                        totalDuration: 0.001,
                      },
                    ]
                  : data[visualizationType]
              }
              activeShape={
                <RenderActiveShape
                  isIpad={isIpad}
                  internalColor={colors}
                  fromBillable={visualizationType === "billableTotals"}
                  darkMode={theme.palette.mode === "dark"}
                />
              }
              cx="50%"
              cy="50%"
              labelLine={false}
              outerRadius={
                chartRef &&
                chartRef.current &&
                chartRef.current.clientWidth < 650
                  ? 90
                  : 120
              }
              fill="#8884d8"
              dataKey={
                visualizationType === "billableTotals"
                  ? "totalDurationBillable"
                  : "totalDuration"
              }
            >
              {data[visualizationType].length === 0 ? (
                <Cell key={`cell-${0}`} fill={colors[colors.length - 1]} />
              ) : (
                data[visualizationType].map((_, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={colors[index % colors.length]}
                  />
                ))
              )}
            </Pie>
          )}
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

const RenderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
    isIpad,
    internalColor,
    fromBillable,
    darkMode,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchorHeader = isIpad ? (cos >= 0 ? "end" : "start") : "middle";
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text
        x={cx}
        y={5}
        dy={8}
        textAnchor={textAnchorHeader}
        fill={internalColor[0]}
      >
        {payload &&
          handleDescription({
            description: payload.description,
            fromBillable,
            showNoData: false,
            sliceLongNames: false,
          })}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={darkMode ? "#fff" : "#333"}
      >
        {`${calculateHours({ duration: value, allowMoreThanADay: true })} h`}
      </text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={24}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`${value === 0.001 ? "0" : (percent * 100).toFixed(2)}%`}
      </text>
    </g>
  );
};

const PieListEntries: FunctionComponent<{
  item: JobTotal | PopulatedBillableTotal | ActivityTotal | null;
  internalColor: string[];
  index: number;
  fromBillable: boolean;
  sliceLongNames: boolean;
}> = ({ item, internalColor, index, fromBillable, sliceLongNames }) => {
  const classes = useStyles();

  return (
    <ListItem
      key={item ? item.description : index}
      sx={{
        "& .MuiListItemIcon-root": { minWidth: "1rem" },
      }}
    >
      <Grow
        in={true}
        appear={true}
        timeout={{ appear: 800, enter: 800, exit: 300 }}
      >
        <div className={classes.listItem}>
          <Typography
            variant="h5"
            style={{
              color: item
                ? internalColor[index % internalColor.length]
                : "lightgray",
              marginRight: "0.5rem",
              marginBottom: "3px",
            }}
          >
            {"●"}
          </Typography>
          <ListItemText
            primary={
              item
                ? handleDescription({
                    description: item.description,
                    fromBillable,
                    showNoData: false,
                    sliceLongNames,
                  })
                : handleDescription({
                    description: "",
                    fromBillable,
                    showNoData: true,
                    sliceLongNames,
                  })
            }
          />
        </div>
      </Grow>
    </ListItem>
  );
};
