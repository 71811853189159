/* eslint-disable react-hooks/rules-of-hooks */
import {
  useEffect,
  useState,
  useMemo,
  PropsWithChildren,
  MutableRefObject,
} from "react";
import { flushSync } from "react-dom";
// Helpers
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { useHotkeys } from "react-hotkeys-hook";
import { DateTime } from "luxon";
import {
  compareChanges,
  definePrevStateObject,
  defineTimeInputs,
  defineYupSchema,
  handleLocationState,
  useYupValidationResolver,
  handleFillWithTemplate,
  foundValidationErrors,
  handleFocusField,
  sliceJobPlanningLineId,
  handleDurationBillableRounding,
  handleCollapsibleHeaderTitle,
} from "./utils";
import { checkForErrors } from "./helper/individualErrorHandler";
import { handleValidate } from "./validate";
// LookUp
import { useForm, FormProvider } from "react-hook-form";
import * as yup from "yup";
// Dyce-Lib
import {
  BudgetValues,
  NonBillableReasonOptions,
  PopulatedJobPlanningLine,
  PopulatedJobTask,
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  ServiceBillingTypeOptions,
  StatusOptions,
  TasksFilterStatus,
  TemplateCategory,
  ValidatedRecord,
  WorkItemType,
} from "@dyce/tnt-api";
import {
  useDebounce,
  useTimeTrackingToolDesign,
  useUpdateEffect,
} from "@dyce/hooks";
import { useStaticContent } from "../static-provider/static-provider";
import { Dialog } from "../dialog/dialog";
import { FormError } from "../formError/formError";
import {
  ErrorDef,
  InfiniteScrollSettings,
  PrevStateObject,
  ValidateRecordTask,
} from "../types";
import { DyceTheme } from "@dyce/theme";
import { selectTimeTrackingToolDesign, useAppSelector } from "@dyce/slices";
import {
  DesignOptions,
  LookUpOptions,
  MappingFieldTypes,
  FieldComponentType,
  FieldTypes,
  SectionRowType,
  ToolStyle,
} from "@dyce/interfaces";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Box, ClickAwayListener, useTheme } from "@mui/material";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { MonetizationOffOutlinedIcon } from "@dyce/ui-assets";
import { teal } from "@mui/material/colors";
// Self-holding components
import {
  ButtonsContainer,
  CollapsibleContainer,
  DescriptionField,
  DurationField,
  FieldsWrapper,
  HiddenComponents,
  HiddenFocusInput,
  MoreOptionsAction,
  MoreOptionsWrapper,
  NonBillableReasonField,
  MappingField,
  SectionWrapper,
  WorkDateField,
  WorkTimeField,
} from "./container";
import { defineValidateObject } from "./container/utils";
import { BudgetBar } from "./container/budget-bar";
import { extractNumberFromString } from "@dyce/utils";

const openEase = "all 0.5s ease";
const closeEase = "all 0.25s ease";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      borderRadius: theme.shape.borderRadius,
      padding: "1rem",
      "& .MuiFormLabel-root-MuiInputLabel-root.Mui-error": {
        color: theme.palette.primary.main,
        fontSize: 12,
        display: "flex",
      },
    },
    textFieldCommonStyle: {
      "& .MuiFilledInput-input": {
        padding: "22px 10px 6px",
      },
      "& .MuiFormLabel-root": {
        color: theme.palette.primary.main,
        fontSize: 12,
        display: "flex",
      },
      "& .MuiInputBase-input": {
        fontSize: 14,
        height: "1.5rem",
        color: theme.palette.text.primary,
      },
      "& .MuiFormHelperText-contained": {
        marginLeft: 2,
        marginRight: 2,
      },
      "& .MuiFormHelperText-root": {
        marginTop: 0,
      },
      "& .MuiFormHelperText-root.Mui-error": {
        whiteSpace: "nowrap",
      },
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      marginBlockEnd: "0px",
    },
  })
);

export interface ITimeTrackingToolProps<
  T extends RecordEntry & { date?: string } & {
    workItem?: WorkItemType | null;
  },
  U,
> {
  /**
   * Record-object when edit created timerec
   */
  record: T | null;
  /**
   * End-time from last created record at same day.
   * @default null
   */
  lastEndTime?: DateTime | null;
  /**
   * Callback fires when editor closes
   */
  onCloseEditor?: () => void;
  /**
   * Callback fired when editor closes with saving
   * @entry gives record-object with id when edited, without id when new created
   */
  onSave?: (
    entry: RecordEntry & Partial<RecordTemplate & RecordTimeRec>,
    isTimeRecord?: boolean
  ) => Promise<{
    newEntry: RecordEntry | RecordTemplate | null;
    error: boolean;
  }>;
  /**
   * If true, reset data to initial state
   * @default false
   */
  resetData?: boolean;
  /**
   * If true, the component is shown
   */
  open: boolean;
  /**
   * calendar date on which user interact with Time-Tracking-Tool in format yyyy-MM-dd
   */
  recordDate: string;
  /**
   * Callback to give back current height of Time-Tracking-Tool
   */
  currentHeight?: (value: number) => void;
  /**
   * Callback fired when hotkey ModifierAlt + PageUp | PageDown pressed.
   * Gives information about direction and current record id.
   */
  handleEditPerHotKey?: (direction: "up" | "down", id: string) => void;
  /**
   * Gives information from current category if Time-Tracking-Tool is used as Template
   * formatter and behaves in different way in some cases, e.g. 'SaveAndClose' | 'SaveAndNew'
   * @default null
   */
  templateCategory?: TemplateCategory | null;
  /**
   * Callback fired on 'SaveAndNew' to e.g. handle delay to let component time for closing
   */
  openNewEditor?: () => void;
  /**
   * Callback fired on ModifierAlt + N to open new Time-Tracking-Tool on today
   */
  openNewAtToday?: () => void;
  /**
   * If true 'SaveAndNew' will not served and following hotkeys will be ignored:
   * ModifierAlt + N (create new record);
   * ModifierCtrl + Shift + Enter (save and new);
   * @default false
   */
  ignoreOpenNewEditor?: boolean;
  /**
   * Callback to block closing from Editor e.g. when API call is pending
   */
  blockClosingCallback?: (value: boolean) => void;
  /**
   * State from @callback {blockClosingCallback}
   * Blocks close procedure if pending api calls started and logic needs
   * to be done afterwards. Mainly for ProjectingFields => Auto fill out
   */
  blockSaveRecord?: boolean;
  /**
   * If true, mapping fields can be changed dynamically
   * @default false
   */
  forceChangeMappingFields?: boolean;
  /**
   * If true, uri will be mutated on save (add #-value (date) to uri and location.state)
   * @default true
   */
  allowUriMutation?: boolean;
  /**
   * If true, ClickAwayListener will be triggered on click outside of component
   * @default true
   */
  allowClickAway?: boolean;
  /**
   * Callback fired to validate template-object to save as timerecording
   */
  validateTemplateObject?: (
    template: RecordTemplate
  ) => Promise<ValidatedRecord>;
  /**
   * If true, timerecording will always be saved, also on no changes
   * @default false
   */
  forceSave?: boolean;
  /**
   * Current selected language is 'en'?
   * @default true
   */
  isEnglish?: boolean;
  /**
   * If true, filter-button will be shown on top of datagrid;
   * This will cause different api endpoints;
   * @default "NONE"
   */
  workWithFilter?: TasksFilterStatus;
  /**
   * If true, component will be shown in demo-mode
   * @default false
   */
  isDemo?: boolean;
  /**
   * If true, expandable popper button will be hidden
   */
  hideExpandablePopperButton?: boolean;
  /**
   * Define a design that overwrites selected design from user
   */
  overwriteDesign?: ToolStyle;
  /**
   * Define size of all input fields in Time-Tracking-Tool
   * @default "medium"
   */
  size?: "small" | "medium";
  /**
   * Add optional border style to Time-Tracking-Tool
   */
  borderStyle?: string;
  /**
   * Callback function fired on every change in Time-Tracking-Tool
   * If onChange prop is provided => Action-buttons will be hidden, onSave is not possible!
   * @param timeInput RecordEntry object with all values from Time-Tracking-Tool
   * @returns void
   */
  onChange?: ({
    timeInput,
    internalError,
  }: {
    timeInput: RecordEntry & Partial<RecordTemplate & RecordTimeRec>;
    internalError?: boolean;
  }) => void;
  /*
   * Callbacks to get data for budget of selected jobTask
   * @param id Id from jobTask
   * @returns BudgetValues
   */
  getRecordBudget: ({
    id,
    levelOfDetail,
  }: {
    id: string;
    levelOfDetail: "jobTask" | "jobPlanningLine";
  }) => Promise<BudgetValues>;
  /**
   * Redux-States or States to mutate and get (read)
   */
  states: {
    setter?: {
      /**
       * Callback fired when time-tracking-tool opening/closing
       */
      timeTrackingToolOpen?: (value: boolean) => void;
      /**
       * Callback fired when user aborts - Clear date in state;
       * Date was set, when 'today' is not a work day and user pressed altModifier + n
       */
      clearTodayDate?: () => void;
      /**
       * Callback fired when TT-Tool gets closed or checkmark from DatePicker
       * gets triggered; - Will not be fired when {templateCategory} is not null (no effect in DatePicker)!
       */
      clearLatestCreatedId?: () => void;
      templates?: {
        /**
         * Callback fired when checkmark from DatePicker gets triggered
         */
        openCreatedRecording?: (value: boolean) => void;
        /**
         * Callback fired when user deletes created record by notification;
         * (Requires created record by template with openCreatedRecording === false)
         */
        deleteRecord?: (id: string) => Promise<boolean>;
      };
      templateList?: {
        /**
         * Callback fired when list of all templates required;
         */
        getAllTemplates?: () => void;
      };
    };
    getter: {
      /**
       * If true, checkmark in DatePicker will be set at: 'Open new timerec:'
       */
      openCreatedRecording?: boolean;
      /**
       * Current selected locale with hyphen, e.g. 'en-GB'
       */
      localeHyphen: string;
      /**
       * If true, Datagrid will be shown in darkMode style
       */
      darkMode: boolean;
      /**
       * Templates as 2-dim array
       */
      templates?: [string, RecordTemplate[]][];
    };
  };
}

/**
 * Time-Tracking-Tool is a component to create and/or edit timerecs.
 * Possible use also for create and/or edit templates that can be used for timerecs.
 *
 * Time-Tracking-Tool needs slice {TimerecSlice} initiated at top level.
 * @param param0 see @type {TimeTrackingToolProps}
 * @returns Time-Tracking-Tool component
 */
export function TimeTrackingTool<
  T extends RecordEntry & { date?: string } & {
    workItem?: WorkItemType | null;
  },
  U,
>({
  lastEndTime = null,
  record,
  onCloseEditor,
  onSave,
  resetData = false,
  open,
  recordDate,
  currentHeight,
  handleEditPerHotKey,
  templateCategory = null,
  openNewEditor,
  openNewAtToday,
  getRecordBudget,
  isEnglish = true,
  ignoreOpenNewEditor = false,
  blockClosingCallback,
  blockSaveRecord = false,
  allowClickAway = true,
  forceChangeMappingFields = false,
  allowUriMutation = true,
  validateTemplateObject,
  isDemo = false,
  hideExpandablePopperButton,
  overwriteDesign,
  size = "medium",
  borderStyle,
  onChange,
  states,
  workWithFilter = TasksFilterStatus.NONE,
  forceSave = false,
}: PropsWithChildren<
  ITimeTrackingToolProps<T, U extends DesignOptions ? DesignOptions : string>
>): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { platform } = useStaticContent();
  const theme = useTheme<DyceTheme>();
  const location = useLocation();
  const noActionButtons = onChange ? true : false;

  // Constant values for infinite scrolling
  const { initialTop, loadAbove, loadBelow, loadMore, triggerAtBottom } =
    InfiniteScrollSettings.values();

  // Hooks
  const designId = useAppSelector(selectTimeTrackingToolDesign);
  const design = overwriteDesign
    ? overwriteDesign
    : useTimeTrackingToolDesign({
        style: designId,
      });

  // States
  // Mark selected Container per activeChild
  const [activeWrapper, setActiveWrapper] = useState<string>("wrapper00");
  const [startAnimation, setStartAnimation] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(record ? false : true);
  const [moreOptions, setMoreOptions] = useState<boolean>(false);
  const [blockEscape, setBlockEscape] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(record ? false : true);
  const [fillFromTemplate, setFillFromTemplate] = useState<boolean>(false);
  const [validateTrigger, setValidateTrigger] = useState<boolean>(false);
  const [debounceEscaping, setDebounceEscaping] = useState<boolean>(false);
  const [blockClosing, setBlockClosing] = useState<boolean>(false);
  const [blockOnPending, setBlockOnPending] = useState<{
    isPending: boolean;
    triggerSave: boolean | "saveAndNew" | "saveAndClose";
  }>({
    isPending: false,
    triggerSave: false,
  });
  const [validateErrorIds, setValidateErrorIds] = useState<string[]>([]);
  const [validateErrors, setValidateErrors] = useState<ValidatedRecord | null>(
    null
  );
  const [pending, setPending] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [collapsibleContainers, setCollapsibleContainers] = useState<boolean[]>(
    []
  );
  const [reCalculate, setReCalculate] = useState<boolean>(false);
  const [startCalculation, setStartCalculation] = useState<boolean>(false);
  const [filterIsActive, setFilterIsActive] = useState<boolean>(false);
  const [editorHeight, setEditorHeight] = useState<number>(0);
  const [lastFieldRef, setLastFieldRef] =
    useState<MutableRefObject<HTMLInputElement | null>>();

  const [timeInput, setTimeInput] = useState<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >(defineTimeInputs(null, null, recordDate));
  // Set to same object for comparison (Escape)
  const [timeInputInitial, setTimeInputInitial] = useState<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >(timeInput);
  const [projectingInput, setProjectingInput] = useState<
    ValidateRecordTask<MappingFieldTypes>
  >(defineValidateObject({ initialTop, loadAbove, loadBelow, loadMore }));
  const [projectingInputPreState, setProjectingInputPreState] =
    useState<ValidateRecordTask<MappingFieldTypes>>(projectingInput);
  const [budget, setBudget] = useState<BudgetValues | null>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  // Debouncing HtmlElements
  const { debouncedValue, setValue } = useDebounce<boolean>(
    false,
    record ? 250 : 400 // milliseconds
  );

  const { debouncedValue: debounceError, setValue: setErrorValue } =
    useDebounce<number>(
      0,
      20 // milliseconds
    );

  // Input Memory
  const [prevDataState, setPrevDataState] = useState<PrevStateObject>(
    definePrevStateObject(null, recordDate)
  );

  // LookUp-Validation
  const validationProject = useMemo(() => defineYupSchema(yup), []);
  type Projects = yup.Asserts<typeof validationProject>;
  const resolver = useYupValidationResolver(validationProject);

  const methods = useForm<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >({
    mode: "onBlur",
    resolver,
  });

  const {
    formState: { errors },
    setError,
    setFocus,
    watch,
  } = methods;

  // Memos
  const heightProps: {
    gapSize: 8 | 16;
    textFieldHeight: 41 | 57;
    rowHeight: 73 | 89;
    moreOptions: 81 | 105;
    collapsedHeight: 43 | 51;
    buttonContainerHeight: 33 | 36.5 | 0;
  } = useMemo(() => {
    return {
      gapSize: size === "small" ? 8 : 16,
      textFieldHeight: size === "small" ? 41 : 57,
      rowHeight: size === "small" ? 73 : 89,
      moreOptions: size === "small" ? 81 : 105,
      collapsedHeight: size === "small" ? 43 : 51,
      buttonContainerHeight: noActionButtons ? 0 : size === "small" ? 33 : 36.5,
    };
  }, [size]);

  // UseEffects
  // Debounces to show Components after duration (open animation)
  useEffect(() => {
    if (open) {
      setValue(open);
      setStartAnimation(true);
    } else {
      setStartAnimation(false);
    }
  }, [open]);

  useEffect(() => {
    if (isDemo) {
      setCollapsibleContainers([]);
      setMoreOptions(false);
      setReCalculate(true);
    } else {
      setPending(false);
    }
  }, [isDemo, designId]);

  /**
   * Calculation and Callbacks for editorHeight for demoSite
   */
  useEffect(() => {
    if (reCalculate) {
      const initialHeight = handleHeightCalculation();
      setEditorHeight(initialHeight);
      currentHeight && currentHeight(initialHeight);
      setReCalculate(false);
      isDemo && setPending(true);
    }
  }, [reCalculate]);

  useEffect(() => {
    if (startCalculation) {
      const initialEditorHeight = handleHeightCalculation();
      const errorHeight = debounceError * 24;
      const newHeight = initialEditorHeight + errorHeight;
      setEditorHeight(newHeight);

      currentHeight && currentHeight(newHeight);
    }
  }, [debounceError, design, startCalculation]);

  useEffect(() => {
    setErrorValue(Object.values(errors).length);
  }, [Object.values(errors)]);

  /**
   * Get look-up filter status from local storage
   */
  useEffect(() => {
    const filterStatus = localStorage.getItem("lookUpFilter");
    if (workWithFilter === TasksFilterStatus.NONE) {
      setFilterIsActive(false);
    } else if (filterStatus && workWithFilter === TasksFilterStatus.OPTIONAL) {
      setFilterIsActive(JSON.parse(filterStatus));
    } else if (workWithFilter === TasksFilterStatus.REQUIRED) {
      setFilterIsActive(true);
    }
  }, []);

  // If new entry was done, last endTime === startTime (prefilled)
  useEffect(() => {
    if (lastEndTime && !record && !timeInput.start) {
      if (!timeInput.start) {
        timeInput.start = lastEndTime.toISO();
      } else if (timeInput.start !== lastEndTime.toISO()) {
        // Overwrite startTime with new lastEndTime, while last entry of current
        // date was corrected with lower endTime as before
        timeInput.start = lastEndTime.toISO();
        setTimeInputInitial({ ...timeInput });
      }
    }
  }, [lastEndTime]);

  // If MoreOptions is triggered, give information to edit timerec form
  useEffect(() => {
    if (debouncedValue) {
      if (moreOptions) {
        setValidateTrigger(true);
        handleFocusField(
          setFocus,
          timeInput.date ? "date" : "durationBillable"
        );
      } else {
        handleFocusField(setFocus, "start");
      }
    }
  }, [moreOptions]);

  // Validate again manually per trigger
  useEffect(() => {
    if (validateTrigger) {
      // Go through validate() with new entries in timeInput object
      validate(timeInput as Projects);
    }
  }, [validateTrigger]);

  // Need little debounce for escape handler when lookUp is open
  useEffect(() => {
    setDebounceEscaping(blockEscape);
  }, [blockEscape]);

  // If user is editing, all values from record (TimeRecord || Template) will be inserted
  useEffect(() => {
    if (record) {
      const recordEntries: RecordEntry &
        Partial<RecordTemplate & RecordTimeRec> = defineTimeInputs(
        record,
        null
      );
      setPrevDataState(definePrevStateObject(record, recordDate));
      setTimeInput(recordEntries);
      setTimeInputInitial(recordEntries);
      setFillFromTemplate(true);
      onChange &&
        onChange({ timeInput: sliceJobPlanningLineId(recordEntries) });
    }
    setTimeout(() => setStartCalculation(true), 50);
    states.setter &&
      states.setter.timeTrackingToolOpen &&
      states.setter.timeTrackingToolOpen(true);
  }, [
    record,
    record && record.tntModelLine,
    record && record.description,
    record && record.workItem,
  ]);

  /**
   * Handle budget for selected jobTask
   */
  useUpdateEffect(() => {
    const jobTask = watch("jobTask");
    const jobPlanningLine = watch("jobPlanningLine");

    // API Call function
    const handleBudget = async (
      id: string,
      levelOfDetail: "jobTask" | "jobPlanningLine"
    ) => {
      const budget = await getRecordBudget({
        id: id,
        levelOfDetail: levelOfDetail,
      });
      setBudget(budget);
    };

    if (watch("customer") && timeInput.customer === null) {
      const tmpTimeInput = {
        ...timeInput,
        customer: watch("customer"),
      };
      setTimeInput(tmpTimeInput);
      onChange && onChange({ timeInput: sliceJobPlanningLineId(tmpTimeInput) });
    }
    let budgetTimer: NodeJS.Timeout;
    // Check if jobPlanningLine or jobTask is selected
    if (!projectingInput.pending.isLoading) {
      budgetTimer = setTimeout(() => {
        if (
          jobPlanningLine !== null &&
          jobPlanningLine !== undefined &&
          jobPlanningLine &&
          jobPlanningLine.id !== null
        ) {
          handleBudget(jobPlanningLine.id.slice(0, 36), "jobPlanningLine");
        } else if (jobTask !== null && jobTask !== undefined) {
          if (jobTask.id !== null) {
            handleBudget(jobTask.id, "jobTask");
          } else {
            if (jobTask && jobTask.id === null) {
              setBudget(null);
            }
          }
        }
      }, 200);
    }
    return () => clearTimeout(budgetTimer);
  }, [
    watch("jobTask"),
    watch("jobPlanningLine"),
    watch("customer"),
    projectingInput.pending.isLoading,
  ]);

  useEffect(() => {
    if (fillFromTemplate) {
      setFillFromTemplate(false);
    }
  }, [fillFromTemplate]);

  // Handler
  const onSelectTemplate = async (template: RecordTemplate) => {
    if (validateTemplateObject) {
      const validateTemplate = await validateTemplateObject(template);
      if (validateTemplate.errors.length > 0) {
        setValidateErrors(validateTemplate);
        setBlockClosing(true);
      }
      const newTimeInput: RecordEntry = handleFillWithTemplate({
        template,
        timeInput: timeInput as RecordTimeRec,
        recordDate,
        propagateTime:
          overwriteDesign !== undefined
            ? true
            : designId !== DesignOptions.COMPACT &&
              designId !== DesignOptions.SIMPLE,
      });

      setPrevDataState(definePrevStateObject(newTimeInput, recordDate));
      setTimeout(() => {
        setTimeInput(newTimeInput);
        onChange &&
          onChange({ timeInput: sliceJobPlanningLineId(newTimeInput) });
        setValidateTrigger(true);
        // Trigger useEffect for onSubmit()
        setFillFromTemplate(true);
      }, 10);

      // Focus last focused field
      lastFieldRef && lastFieldRef.current && lastFieldRef.current.focus();
    }
  };

  // Mapping-Fields
  useEffect(() => {
    const copyStateTimer = setTimeout(() => {
      setProjectingInputPreState(projectingInput);
    }, 1);
    return () => clearTimeout(copyStateTimer);
  }, [projectingInput]);

  useEffect(() => {
    let deleteDependencyTimer: NodeJS.Timeout;
    if (projectingInput.dependency.deleteFields) {
      deleteDependencyTimer = setTimeout(() => {
        projectingInput.dependency.deleteFields = false;
      }, 10);
    }
    return () => {
      clearTimeout(deleteDependencyTimer);
    };
  }, [projectingInput.dependency.deleteFields]);

  useEffect(() => {
    if (projectingInput.infos.focusActivity) {
      handleFocusField(setFocus, "jobPlanningLine", 0);
      projectingInput.infos.focusActivity = false;
    }
  }, [projectingInput.infos.focusActivity]);

  // Force 'onChange' to automated filled fields
  useEffect(() => {
    if (projectingInput.forceChange.description) {
      const tmpTimeInput = {
        ...timeInput,
        description: projectingInput.forceChange.description,
      };
      setTimeInput(tmpTimeInput);
      onChange && onChange({ timeInput: sliceJobPlanningLineId(tmpTimeInput) });
      projectingInput.forceChange.description = null;
    }
  }, [projectingInput.forceChange.description]);

  useEffect(() => {
    if (
      blockOnPending.triggerSave !== false &&
      blockOnPending.isPending === false
    ) {
      const el = document.getElementById("saveButton");
      if (typeof blockOnPending.triggerSave === "string") {
        if (blockOnPending.triggerSave === "saveAndNew") {
          onCloseHandler(el, "saveAndNew");
        } else {
          onCloseHandler(el, "saveAndClose");
        }
        setBlockOnPending({
          isPending: false,
          triggerSave: false,
        });
      }
    }
    if (
      blockOnPending.triggerSave === true &&
      blockOnPending.isPending === false
    ) {
      // Error after pending - release pending and focus activity
      setPending(false);
      setBlockOnPending({
        isPending: false,
        triggerSave: false,
      });
      handleFocusField(setFocus, "jobPlanningLine");
    } else {
      if (blockOnPending.triggerSave === false) {
        setPending(false);
        setBlockOnPending({
          isPending: false,
          triggerSave: false,
        });
      }
    }
  }, [blockOnPending.triggerSave, blockOnPending.isPending]);

  const handleActivityChanged = ({
    jobPlanningLine,
    jobTask,
  }: {
    jobPlanningLine: PopulatedJobPlanningLine | null;
    jobTask: PopulatedJobTask | null;
  }) => {
    const jpl: PopulatedJobPlanningLine | null = jobPlanningLine
      ? {
          id: jobPlanningLine.id,
          description: jobPlanningLine.description,
          tntModelLine: jobPlanningLine.tntModelLine,
          jobTask: jobPlanningLine.jobTask,
          serviceBillingType: jobPlanningLine.serviceBillingType,
        }
      : null;
    const tntModelLine = jobPlanningLine
      ? jobPlanningLine.tntModelLine
        ? jobPlanningLine.tntModelLine
        : null
      : null;

    const oldActivityId = timeInput.tntModelLine?.id;
    timeInput.jobPlanningLine = jpl;
    timeInput.tntModelLine = tntModelLine;
    timeInput.nonBillableReason =
      tntModelLine && tntModelLine.billable === false
        ? tntModelLine.nonBillableReason
        : NonBillableReasonOptions.NONE;

    if (timeInput.nonBillableReason !== NonBillableReasonOptions.NONE) {
      timeInput.durationBillable = 0;
    } else if (tntModelLine) {
      timeInput.durationBillable = handleDurationBillableRounding(
        timeInput.duration,
        tntModelLine.rounding
      );
    }
    if (tntModelLine && timeInput.description.length === 0) {
      timeInput.description = tntModelLine.description || "";
    }

    // If tntModelLine gets cleared from jobTask or jobTask gets cleared
    // from job while job was changed to another job, clear activity and
    // focus jobTask. Also set durationBillable and nonBillableReason correct
    if (
      !tntModelLine ||
      !jobTask ||
      (tntModelLine &&
        tntModelLine.description === null &&
        jobTask &&
        jobTask.id === null)
    ) {
      timeInput.durationBillable = timeInput.duration;
      timeInput.nonBillableReason = NonBillableReasonOptions.NONE;
      setTimeInput({ ...timeInput });
      setFocus("hidden");
      handleFocusField(setFocus, "jobTask", 10);
      // Prevent focus to activity when jobTask is cleared
      blockClosingCallback && blockClosingCallback(false);
      onChange && onChange({ timeInput: sliceJobPlanningLineId(timeInput) });
      setTimeout(() => {
        setBlockOnPending((prev) => {
          return {
            isPending: false,
            triggerSave: prev.triggerSave,
          };
        });
      }, 200);
      return;
    }

    // Focus activity or duration (depending on designId) field with selecting
    // the complete text to avoid issues
    setTimeout(() => {
      if (
        (designId === DesignOptions.COMPACT ||
          designId === DesignOptions.SIMPLE) &&
        projectingInput.infos.filledFromJobTask === false &&
        tntModelLine !== null &&
        tntModelLine.description !== null
      ) {
        if (oldActivityId !== tntModelLine?.id) {
          setFocus("hidden");
          handleFocusField(setFocus, "duration", 0);
        }
      } else if (
        tntModelLine !== null &&
        projectingInput.infos.filledFromJobTask &&
        projectingInput.infos.memoryActivityId === tntModelLine.id
      ) {
        if (oldActivityId !== tntModelLine?.id) {
          setFocus("hidden");
          setFocus("duration");
          projectingInput.infos.filledFromJobTask = false;
        }
      } else {
        if (oldActivityId !== tntModelLine?.id) {
          setFocus("hidden");
          handleFocusField(setFocus, "jobPlanningLine", 65);
          projectingInput.infos.filledFromJobTask = false;
        }
      }
    }, 10);
    setTimeInput({ ...timeInput });
    onChange && onChange({ timeInput: sliceJobPlanningLineId(timeInput) });
    setTimeout(() => {
      setBlockOnPending((prev) => {
        return {
          isPending: false,
          triggerSave: prev.triggerSave,
        };
      });
    }, 200);
  };

  // Closing Handler
  const onCloseHandler = async (
    event: any,
    saveOption: "saveAndClose" | "saveAndNew" | "abort" = "saveAndClose"
  ) => {
    if (allowUriMutation) {
      checkForSearchQuery();
    }
    if (!open) {
      return;
    }
    if (blockOnPending.isPending) {
      setPending(true);
      setBlockOnPending({
        isPending: true,
        triggerSave: saveOption as "saveAndClose" | "saveAndNew",
      });
      return;
    }

    // Prevent closing if error exists
    if (!blockClosing || saveOption === "abort") {
      try {
        // If open/close button from DrawerLeft (Menu) is pressed
        if (
          (event && event.target && event.target.id.includes("drawerButton")) ||
          (event &&
            event.target &&
            event.target.parentNode.id.includes("drawerButton"))
        ) {
          return;
        }
        setPending(true);
        /**
         * On pending autofill and direct safe with hotkey, fill values
         * with watch() and save
         * Watch-hook needed for keyboard-changes (arrow-up/down -> select with enter -> save with hotkey)
         * Everything done without physically blurring any field (fast input/change of timerecording)
         */
        const customer =
          watch("customer")?.id === null ? null : watch("customer");
        const job = watch("job")?.id === null ? null : watch("job");
        const jobTask = watch("jobTask")?.id === null ? null : watch("jobTask");
        let jobPlanningLine = timeInput.jobPlanningLine;
        let tntModelLine = timeInput.jobPlanningLine
          ? timeInput.jobPlanningLine.tntModelLine
          : undefined;
        const jplTemp = watch("jobPlanningLine");
        if (
          jobPlanningLine === null &&
          jplTemp !== null &&
          jplTemp.id !== null
        ) {
          // Save jobPlanningLine and tntModelLine when timeInput.jobPlanningLine
          // is empty (null) but user filled information in activity field and did not blur
          jobPlanningLine = jplTemp
            ? {
                id: jplTemp.id,
                description: jplTemp.description,
                tntModelLine: jplTemp.tntModelLine,
                serviceBillingType: jplTemp.serviceBillingType,
              }
            : null;
          tntModelLine = jobPlanningLine
            ? jobPlanningLine.tntModelLine
            : undefined;
        } else if (
          jobTask &&
          jobTask.jobPlanningLine &&
          jobTask.jobPlanningLine.id !== null &&
          jobTask.jobPlanningLine.id.length > 0 &&
          jobPlanningLine === null &&
          tntModelLine === undefined
        ) {
          // JobPlanningLine comes from BC and should be saved again
          jobPlanningLine = { ...jobTask.jobPlanningLine };
        } else if (
          projectingInput.infos.storeJobPlanningLineInfo &&
          jobTask &&
          timeInputInitial.jobTask &&
          timeInputInitial.jobTask.id === jobTask.id
        ) {
          // JobPlanningLine comes from BC and JobTask was changed by user and user
          // changed JobTask back to initial value => save JobPlanningLine again
          jobPlanningLine = projectingInput.infos
            .storeJobPlanningLineInfo as PopulatedJobPlanningLine;
        }

        const watchTimeInput = {
          ...timeInput,
          customer,
          job,
          jobTask,
          jobPlanningLine,
          tntModelLine: tntModelLine || null,
        };
        // Save (only when changes made)
        const saveOnChange = (
          isEditing: boolean
        ): Promise<{
          newEntry: RecordEntry | null | RecordTemplate;
          error: boolean;
        }> | null => {
          if (
            compareChanges(watchTimeInput, timeInputInitial) ||
            initial ||
            !isEditing ||
            isDirty
          ) {
            if (isDirty) {
              return onSave
                ? onSave(
                    sliceJobPlanningLineId({
                      ...watchTimeInput,
                      description: watch("description"),
                    })
                  )
                : null;
            } else {
              return onSave
                ? onSave(
                    sliceJobPlanningLineId({
                      ...watchTimeInput,
                    })
                  )
                : null;
            }
          } else {
            if (forceSave && onSave) {
              return onSave(sliceJobPlanningLineId(timeInput));
            } else {
              return null;
            }
          }
        };

        // Close
        switch (saveOption) {
          case "saveAndClose": {
            await saveOnChange(record !== null);
            handleResetStates();
            onCloseEditor && onCloseEditor();
            break;
          }
          case "saveAndNew": {
            await saveOnChange(record !== null);
            flushSync(() => {
              handleResetStates();
              onCloseEditor && onCloseEditor();
            });
            // Open new Editor,
            openNewEditor && openNewEditor();
            break;
          }
          case "abort": {
            if (openDialog) {
              // Discard changes
              setOpenDialog(false);
              onCloseEditor && onCloseEditor();
              handleResetStates();
            } else if (
              compareChanges(watchTimeInput, timeInputInitial) &&
              timeInput.status === StatusOptions.OPEN
            ) {
              setOpenDialog(true);
            } else {
              onCloseEditor && onCloseEditor();
              handleResetStates();
            }
            setPending(false);
            states.setter &&
              states.setter.clearTodayDate &&
              states.setter.clearTodayDate();
            break;
          }
        }
      } catch (error) {
        console.log(error);
        setPending(false);
      }
    }
  };

  const checkForSearchQuery = () => {
    const returnState = handleLocationState(location);
    if (returnState) {
      history.push(`#${recordDate}`, { ...returnState });
    } else {
      history.push(`#${recordDate}`);
    }
  };

  const handleResetStates = () => {
    if (record) {
      currentHeight && currentHeight(58);
    } else {
      currentHeight && currentHeight(0);
    }
    setEditorHeight(0);

    if (
      templateCategory === null &&
      states.setter &&
      states.setter.clearLatestCreatedId
    ) {
      states.setter.clearLatestCreatedId();
    }
    setTimeout(() => {
      states.setter &&
        states.setter.timeTrackingToolOpen &&
        states.setter.timeTrackingToolOpen(false);
    }, 20);
    setPending(false);
    if (resetData) {
      setTimeInput(defineTimeInputs(null, null, recordDate));
      setTimeInputInitial(defineTimeInputs(null, null, recordDate));
      setPrevDataState(definePrevStateObject(null, recordDate));
      setMoreOptions(false);
    }
  };

  const handleDirtyField = () => {
    if (!blockClosing && !blockSaveRecord) {
      setIsDirty(true);
    }
  };

  const handleLastInputRef = (
    ref: MutableRefObject<HTMLInputElement | null>
  ) => {
    setLastFieldRef(ref);
  };

  /**
   * Hotkeys
   */
  // Close | Abort current state in Time-Tracking-Tool
  useHotkeys(
    "escape",
    (e) => {
      if (!blockSaveRecord) {
        if (!debounceEscaping) {
          onCloseHandler(e as any, "abort");
        }
      }
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"],
      filter: () => document.querySelectorAll('[role="grid"]').length === 0,
    }
  );

  // Save current status and close Time-Tracking-Tool
  useHotkeys(
    `${platform.modifiers.modifierCtrl}+enter`,
    (e) => {
      e.preventDefault();
      if (
        !blockClosing &&
        !blockSaveRecord &&
        !pending &&
        !blockOnPending.isPending
      ) {
        setPending(true);
        if (templateCategory || timeInput.status === StatusOptions.OPEN) {
          setTimeout(() => {
            onCloseHandler(e as any, "saveAndClose");
          }, 300);
        } else {
          setPending(false);
        }
      }
      if (blockOnPending.isPending) {
        setPending(true);
        setBlockOnPending((prev) => {
          return {
            isPending: prev.isPending,
            triggerSave: "saveAndClose",
          };
        });
      }
    },
    {
      enabled: !isExpanded,
      enableOnTags: ["INPUT", "TEXTAREA"],
    },
    [isExpanded, pending]
  );

  // Save current status and open new Time-Tracking-Tool for current day
  useHotkeys(
    `${platform.modifiers.modifierCtrl}+shift+enter`,
    (e) => {
      e.preventDefault();
      if (
        !blockClosing &&
        !blockSaveRecord &&
        !pending &&
        !blockOnPending.isPending
      ) {
        if (templateCategory || timeInput.status === StatusOptions.OPEN) {
          setPending(true);
          if (!ignoreOpenNewEditor) {
            onCloseHandler(e as any, "saveAndNew");
          } else {
            setPending(false);
          }
        } else {
          setPending(false);
        }
      }
      if (blockOnPending.isPending) {
        setPending(true);
        setBlockOnPending((prev) => {
          return {
            isPending: prev.isPending,
            triggerSave: "saveAndNew",
          };
        });
      }
    },
    {
      enabled: !isExpanded,
      enableOnTags: ["INPUT", "TEXTAREA"],
    },
    [pending]
  );

  // Open new Time-Tracking-Tool for 'today'
  useHotkeys(
    `${platform.modifiers.modifierAlt}+n`,
    (e) => {
      e.preventDefault();
      if (!blockClosing && !blockSaveRecord) {
        if (!debounceEscaping) {
          if (!ignoreOpenNewEditor) {
            onCloseHandler(e as any, "saveAndClose");
            openNewAtToday && openNewAtToday();
          }
        }
      }
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"],
    }
  );

  // Jump per hotkey to next (below current) time-record
  useHotkeys(
    `${platform.modifiers.modifierAlt}+down, ${platform.modifiers.modifierAlt}+pagedown`,
    (e) => {
      e.preventDefault();
      if (!blockClosing && !blockSaveRecord && handleEditPerHotKey) {
        if (record && record.date && record.id) {
          if (open) {
            onCloseHandler(e as any);
            handleEditPerHotKey("down", record.id);
          }
        }
      }
    },
    { enableOnTags: ["INPUT", "TEXTAREA"] }
  );

  // Jump per hotkey to previous (above current) time-record
  useHotkeys(
    `${platform.modifiers.modifierAlt}+up, ${platform.modifiers.modifierAlt}+pageup`,
    (e) => {
      if (!blockClosing && !blockSaveRecord && handleEditPerHotKey) {
        if (record && record.date && record.id) {
          if (open) {
            onCloseHandler(e as any);
            handleEditPerHotKey("up", record.id);
          }
        }
      }
    },
    { enableOnTags: ["INPUT", "TEXTAREA"] }
  );

  // OnSubmit handling
  const validate = (data: Projects) => {
    if (!open) {
      return;
    }

    // Manually Errorhandler
    const dataArray: any[] = Object.entries(data);
    const errorArray: ErrorDef[] = checkForErrors(dataArray);

    if (errorArray.length > 0) {
      errorArray.forEach((error) => {
        setError(error.ref as keyof RecordEntry, error.errorOption);
      });
      setBlockClosing(true);
      setIsDirty(false);
      onChange &&
        onChange({
          timeInput: sliceJobPlanningLineId(timeInput),
          internalError: true,
        });
      return;
    } else {
      setBlockClosing(false);
    }

    const submitCheck: PrevStateObject = handleValidate(
      data,
      prevDataState,
      record,
      recordDate
    );

    if (validateTrigger) {
      setValidateTrigger(false);
    }

    if (submitCheck.errors.length > 0) {
      submitCheck.errors.forEach((error) => {
        setError(error.ref as keyof RecordEntry, error.errorOption);
      });
      setBlockClosing(true);
      setIsDirty(false);
      onChange &&
        onChange({
          timeInput: sliceJobPlanningLineId(submitCheck.timeInput),
          internalError: true,
        });
    } else {
      setBlockClosing(false);
      setTimeInput(submitCheck.timeInput);
      setPrevDataState(submitCheck);
      onChange &&
        onChange({ timeInput: sliceJobPlanningLineId(submitCheck.timeInput) });
    }

    if (initial) {
      setInitial(false);
    }

    if (
      (projectingInput.dependency.deleteFields && timeInput.job === null) ||
      (projectingInput.dependency.deleteFields && timeInput.jobTask === null)
    ) {
      projectingInput.dependency.deleteFields = false;
    }

    if (validateErrors && validateErrors.errors.length > 0) {
      setBlockClosing(true);
      let finalPropName: keyof RecordEntry = "hidden";
      validateErrors.errors.forEach((error) => {
        const parsedPropName: string = error.PropertyName.replace(/\s/g, "")
          .replace(/^.{1}/g, error.PropertyName[0].toLowerCase())
          .replace(/\s/g, "")
          .trim();
        finalPropName = parsedPropName.substring(
          0,
          parsedPropName.length - 2
        ) as keyof RecordEntry;
        // Set Errors manually for validation
        setError(finalPropName, {
          type: "manual",
          message: error.errorMessage,
        });
        setValidateErrorIds((validateErrorIds) => [
          ...validateErrorIds,
          error.PropertyValue,
        ]);
      });

      if (!fillFromTemplate) {
        // No error anymore? => Reset validation errors
        if (!foundValidationErrors(validateErrorIds, submitCheck.timeInput)) {
          setValidateErrors(null);
          setValidateErrorIds([]);
          setBlockClosing(false);
        }
      }
      // Target "infected" field
      if (fillFromTemplate) {
        handleFocusField(setFocus, "description");
        handleFocusField(setFocus, finalPropName, 10);
      }
    }
  };

  /**
   * Calculate height of the editor by its design
   */
  const handleHeightCalculation = () => {
    const extractedBorderSize = borderStyle
      ? extractNumberFromString(borderStyle)
      : 0;
    const borderSize = Math.max(extractedBorderSize * 2 - 1, 0);
    let sectionHeights =
      heightProps.gapSize * 2 + heightProps.buttonContainerHeight + borderSize; // outer padding and buttons-container
    let gapCount = 0; // every gap = 1rem (16px)
    const collapseContainer: boolean[] = [];
    design.inputSection.sectionRows.forEach((row) => {
      gapCount += 1;
      if (row.rows) {
        sectionHeights +=
          row.rows * heightProps.textFieldHeight +
          row.rows * 16 +
          (noActionButtons ? 8 : 16);
      } else if (row.collapsible && collapsibleContainers.length === 0) {
        if (row.collapsible.allowInitialOpen) {
          // Get count of info rows in collapsible
          const infoCount = handleCollapsedInfo(row).length;
          if (infoCount < 4) {
            sectionHeights +=
              heightProps.rowHeight + heightProps.collapsedHeight; // MappingField + collapsibleContainer
            collapseContainer.push(true);
          } else {
            sectionHeights += heightProps.collapsedHeight; // collapsibleContainer
            collapseContainer.push(false);
          }
        } else {
          sectionHeights += heightProps.collapsedHeight;
          collapseContainer.push(false);
        }
      } else if (collapsibleContainers.length === 0) {
        sectionHeights += heightProps.rowHeight;
      }
    });
    if (moreOptions) {
      sectionHeights += heightProps.moreOptions;
    }

    if (collapseContainer.length === 0 && collapsibleContainers.length > 0) {
      collapsibleContainers.forEach((container) => {
        if (container) {
          sectionHeights += heightProps.rowHeight + heightProps.collapsedHeight;
        } else {
          sectionHeights += heightProps.collapsedHeight;
        }
      });
    }
    sectionHeights += gapCount * heightProps.gapSize;

    if (collapseContainer.length > 0 && collapsibleContainers.length === 0) {
      // Add initial boolean values to array for collapsible containers
      setCollapsibleContainers(collapseContainer);
    }

    return sectionHeights;
  };

  const handleCollapseContainerCalculation = (isOpen: boolean) => {
    setEditorHeight((prev) =>
      isOpen ? prev + heightProps.rowHeight : prev - heightProps.rowHeight
    );
    currentHeight &&
      currentHeight(
        isOpen
          ? editorHeight + heightProps.rowHeight
          : editorHeight - heightProps.rowHeight
      );
    const collapsedContainer: boolean[] = [];
    const oppositeIndex = collapsibleContainers.findIndex((x) => x !== isOpen);

    // remove value from array with index oppositeIndex
    collapsibleContainers.splice(oppositeIndex, 1);
    // Spread values into new array
    collapsedContainer.push(...collapsibleContainers, isOpen);

    setCollapsibleContainers(collapsedContainer);
  };

  const handleIsExpanded = (value: boolean) => {
    setIsExpanded(value);
  };

  const handleGenericComponents = ({
    componentInfo,
    rows,
    autoFocus,
    currentIndex,
    length,
    isActiveComponent = false,
  }: {
    componentInfo: FieldComponentType;
    rows: 1 | 2 | undefined;
    autoFocus: FieldTypes | undefined;
    currentIndex: number;
    length: number;
    isActiveComponent?: boolean;
  }): JSX.Element => {
    const { compName, tabIndex } = componentInfo;

    if (compName === "start" || compName === "end") {
      return (
        <WorkTimeField
          key={compName}
          size={size}
          fieldName={compName}
          timeInput={timeInput}
          commonTextFieldStyles={classes.textFieldCommonStyle}
          pending={pending}
          tabIndex={tabIndex}
          autoFocus={autoFocus === compName}
          onInputBlur={handleLastInputRef}
        />
      );
    } else if (
      compName === "duration" ||
      compName === "durationBillable" ||
      compName === "break"
    ) {
      return (
        <DurationField
          key={compName}
          size={size}
          fieldName={compName}
          timeInput={timeInput}
          commonTextFieldStyles={classes.textFieldCommonStyle}
          pending={pending}
          tabIndex={tabIndex}
          autoFocus={autoFocus === compName}
          onInputBlur={handleLastInputRef}
        />
      );
    } else if (compName === "description") {
      return (
        <DescriptionField
          key={compName}
          timeInput={timeInput}
          commonTextFieldStyles={classes.textFieldCommonStyle}
          handleDirtyField={handleDirtyField}
          borderActive={borderStyle !== undefined}
          expandButton={{
            handleIsExpanded: handleIsExpanded,
            isExpandable: true,
            isActive: isActiveComponent,
          }}
          pending={pending}
          tabIndex={tabIndex}
          size={size}
          rows={rows}
          autoFocus={autoFocus === compName}
          onInputBlur={handleLastInputRef}
        />
      );
    } else if (
      compName === "customer" ||
      compName === "job" ||
      compName === "jobTask" ||
      compName === "jobPlanningLine"
    ) {
      return (
        <MappingField<MappingFieldTypes>
          key={compName}
          fieldName={compName as LookUpOptions}
          fieldsPerWrapper={{
            currentIndex: currentIndex,
            summary: length,
          }}
          lookupSize={size}
          filter={{
            workWithFilter: workWithFilter,
            filterIsActive: filterIsActive,
            onChangeFilter: (value) => {
              setFilterIsActive(value);
            },
          }}
          onActivityChanged={handleActivityChanged}
          projectingInput={projectingInput}
          projectingInputPreState={projectingInputPreState}
          infiniteLoadingOpts={{
            loadAbove: loadAbove,
            triggerAtBottom: triggerAtBottom,
          }}
          tabIndex={tabIndex}
          validateProjectingInput={(value) => setProjectingInput(value)}
          mappingObject={timeInput}
          fillFromTemplate={fillFromTemplate}
          blockClosingCallback={(val) =>
            blockClosingCallback && blockClosingCallback(val)
          }
          blockOnPendingCallback={(value) =>
            setBlockOnPending({
              isPending: value,
              triggerSave: true,
            })
          }
          darkMode={states.getter.darkMode}
          allowDeleteCustomer={designId === DesignOptions.SIMPLE}
          pending={pending}
          forceChangeMappingFields={forceChangeMappingFields}
          onInputBlur={handleLastInputRef}
          autoFocus={autoFocus === compName}
          hideExpandable={hideExpandablePopperButton}
          focusFieldName={
            designId === DesignOptions.COMPACT ||
            designId === DesignOptions.SIMPLE
              ? "duration"
              : "jobPlanningLine"
          }
        />
      );
    } else if (compName === "date") {
      return (
        <WorkDateField
          key={compName}
          testId={compName}
          size={size}
          isEnglish={isEnglish}
          timeInput={timeInput}
          commonTextFieldStyles={classes.textFieldCommonStyle}
          pending={pending}
          tabIndex={tabIndex}
          onInputBlur={handleLastInputRef}
          autoFocus={autoFocus === compName}
        />
      );
    } else if (compName === "nonBillableReason") {
      return (
        <NonBillableReasonField
          key={compName}
          testId={compName}
          timeInput={timeInput}
          size={size}
          commonTextFieldStyles={classes.textFieldCommonStyle}
          pending={pending}
          tabIndex={tabIndex}
          onInputBlur={handleLastInputRef}
          isFromTimeTrackingTool={true}
          autoFocus={autoFocus === compName}
        />
      );
    } else {
      return <div>No component name found</div>;
    }
  };

  const handleHiddenComponents = (): FieldComponentType[] => {
    const knownCompNames: FieldComponentType[] = [];
    const fieldNames: FieldTypes[] = [
      "start",
      "end",
      "duration",
      "break",
      "description",
      "customer",
      "job",
      "jobTask",
      "jobPlanningLine",
      "date",
      "durationBillable",
      "nonBillableReason",
    ];

    // Find all specified components
    design.inputSection.sectionRows.map((section) => {
      section.wrappers.map((wrapper) => {
        wrapper.components.map((comps) => knownCompNames.push(comps));
      });
    });
    if (design.moreOptions) {
      design.moreOptions.sectionRows.map((section) => {
        section.wrappers.map((wrapper) => {
          wrapper.components.map((comps) => knownCompNames.push(comps));
        });
      });
    }

    const hiddenCompNames: FieldComponentType[] = [];

    // Define hidden components
    fieldNames.forEach((name) => {
      const foundValue = knownCompNames.find(
        (components) => components.compName === name
      );
      if (foundValue === undefined) {
        hiddenCompNames.push({
          compName: name,
          tabIndex: 0,
        });
      }
    });

    return hiddenCompNames;
  };

  const handleCollapsedInfo = (sectionRow: SectionRowType): string[] => {
    const collapsedInfos: string[] = [];

    if (sectionRow.collapsible && sectionRow.collapsible.infoText) {
      const sortByTabIndex = (a: number, b: number) => a - b;

      const clonedSectionsRow: SectionRowType = JSON.parse(
        JSON.stringify(sectionRow)
      );

      clonedSectionsRow.wrappers.forEach((wrapper) => {
        wrapper.components
          .sort((a, b) =>
            sortByTabIndex(
              a.tabIndex ? a.tabIndex : 0,
              b.tabIndex ? b.tabIndex : 0
            )
          )
          .forEach((component) => {
            if (
              component.compName === "customer" ||
              component.compName === "job" ||
              component.compName === "jobTask" ||
              component.compName === "jobPlanningLine"
            ) {
              const componentKey = component.compName;
              if (componentKey === "customer") {
                const customer = timeInput.customer;
                if (customer && customer.name && customer.name.length > 0) {
                  collapsedInfos.push(customer.name);
                }
              } else {
                const jobComponent = timeInput[componentKey];
                if (
                  jobComponent !== null &&
                  jobComponent.description !== null &&
                  jobComponent.description.length > 0
                ) {
                  collapsedInfos.push(jobComponent.description);
                }
              }
            } else {
              const componentKey = component.compName;
              const componentValue = timeInput[componentKey];
              if (componentValue && componentValue.toString().length > 0) {
                if (
                  componentKey.includes("duration") ||
                  componentKey === "break"
                ) {
                  if (
                    typeof componentValue !== "string" &&
                    componentValue > 0
                  ) {
                    collapsedInfos.push(componentValue.toString());
                  }
                } else if (
                  typeof componentValue === "string" &&
                  DateTime.fromISO(componentValue).isValid
                ) {
                  let dateTime = "";
                  if (componentKey !== "date") {
                    dateTime = DateTime.fromISO(componentValue)
                      .toISOTime()
                      .split(".")[0]
                      .slice(0, -3);
                    collapsedInfos.push(dateTime);
                  } else {
                    dateTime = DateTime.fromISO(componentValue, {
                      locale: states.getter.localeHyphen,
                    }).toLocaleString();
                    collapsedInfos.push(dateTime);
                  }
                } else {
                  collapsedInfos.push(componentValue.toString());
                }
              }
            }
          });
      });
    }

    return collapsedInfos;
  };

  const handleInfoIcon = (sectionRow: SectionRowType): JSX.Element => {
    if (sectionRow.collapsible && sectionRow.collapsible.infoIcon) {
      if (
        (timeInput.tntModelLine &&
          timeInput.tntModelLine.billable &&
          timeInput.durationBillable > 0) ||
        (timeInput.tntModelLine === null &&
          timeInput.duration > 0 &&
          timeInput.nonBillableReason === NonBillableReasonOptions.NONE &&
          timeInput.customer !== null &&
          timeInput.job !== null &&
          timeInput.jobTask !== null &&
          timeInput.description.length > 0)
      ) {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "36.5px",
              marginBottom: "-0.125rem",
            }}
          >
            <BudgetBar
              isHorizontal
              isDisabled={
                timeInput.jobTask === null ||
                budget === null ||
                (record !== null && timeInput.jobTask === null)
              }
              budgetValues={budget}
              size={size}
              borderActive={borderStyle !== undefined}
              canOverspend={
                timeInput.jobPlanningLine === null ||
                (timeInput.jobPlanningLine &&
                  timeInput.jobPlanningLine.id !== null &&
                  timeInput.tntModelLine !== null &&
                  timeInput.jobPlanningLine.serviceBillingType !==
                    ServiceBillingTypeOptions.TIME_MATERIAL)
                  ? true
                  : false
              }
            />
            <MonetizationOnOutlinedIcon style={{ color: teal["A400"] }} />
          </div>
        );
      } else {
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "36.5px",
              marginBottom: "-0.125rem",
            }}
          >
            <BudgetBar
              isHorizontal
              isDisabled={
                timeInput.jobTask === null ||
                budget === null ||
                (record !== null && timeInput.jobTask === null)
              }
              budgetValues={budget}
              size={size}
              borderActive={borderStyle !== undefined}
              canOverspend={
                timeInput.jobPlanningLine === null ||
                (timeInput.jobPlanningLine &&
                  timeInput.jobPlanningLine.id !== null &&
                  timeInput.tntModelLine !== null &&
                  timeInput.jobPlanningLine.serviceBillingType !==
                    ServiceBillingTypeOptions.TIME_MATERIAL)
                  ? true
                  : false
              }
            />
            <MonetizationOffOutlinedIcon fill={theme.palette.grey[500]} />
          </div>
        );
      }
    } else {
      return <></>;
    }
  };

  const handleInitialOpen = (
    sectionRow: SectionRowType
  ): boolean | undefined => {
    const infoCount = handleCollapsedInfo(sectionRow).length;
    let componentsCount = 0;

    if (sectionRow.collapsible && sectionRow.collapsible.infoText) {
      sectionRow.wrappers.map((x) => (componentsCount += x.components.length));
    }

    return (
      sectionRow.collapsible &&
      sectionRow.collapsible.allowInitialOpen &&
      infoCount < componentsCount
    );
  };

  return (
    <>
      <ClickAwayListener
        mouseEvent="onMouseUp"
        touchEvent="onTouchEnd"
        onClickAway={(e) => {
          allowClickAway &&
            !openDialog &&
            !blockSaveRecord &&
            !debounceEscaping &&
            onCloseHandler(e);
        }}
      >
        <Box
          className={classes.container}
          sx={{
            padding: size === "small" ? "0.5rem" : "1rem",
            minHeight: startAnimation ? editorHeight : 0,
            width: startAnimation ? "100%" : record ? "100%" : 0,
            transition: record ? closeEase : openEase,
            border: borderStyle,
            background:
              borderStyle !== undefined
                ? theme.palette.background.default
                : theme.palette.mode === "light"
                ? "#f0f0f0"
                : "#1a1a1a",
          }}
        >
          <FormError errors={Object.values(errors)} />
          {debouncedValue && (
            <FormProvider {...methods}>
              <form
                data-testid="editor-component"
                onBlur={methods.handleSubmit(validate)}
                className={classes.formContainer}
                style={{
                  gap: heightProps.gapSize,
                }}
              >
                {design.inputSection.sectionRows.map((sectionRow, sIndex) =>
                  sectionRow.collapsible ? (
                    <SectionWrapper isOpen={open} key={sIndex} size={size}>
                      <CollapsibleContainer
                        key={sIndex + "CollapsibleContainer"}
                        title={handleCollapsibleHeaderTitle(
                          sectionRow.collapsible.header
                        )}
                        collapsedInfo={handleCollapsedInfo(sectionRow)}
                        infoIcon={handleInfoIcon(sectionRow)}
                        initialOpen={handleInitialOpen(sectionRow)}
                        onClickCollapsible={(val) =>
                          handleCollapseContainerCalculation(val)
                        }
                        size={size}
                      >
                        {sectionRow.wrappers.map((wrapper, wIndex) => (
                          <FieldsWrapper
                            key={sIndex + wIndex}
                            active={
                              activeWrapper === `wrapper${sIndex}${wIndex}`
                            }
                            name={`wrapper${sIndex}${wIndex}`}
                            handleFocus={(name) => setActiveWrapper(name)}
                            width={
                              wrapper.width ? wrapper.width[size] : undefined
                            }
                            size={size}
                            borderActive={borderStyle !== undefined}
                            rows={sectionRow.rows}
                            textFieldHeight={heightProps.textFieldHeight}
                          >
                            {wrapper.components.map((component, cIndex) =>
                              handleGenericComponents({
                                componentInfo: component,
                                rows: sectionRow.rows,
                                autoFocus: sectionRow.autoFocus,
                                currentIndex: cIndex,
                                length: wrapper.components.length,
                                isActiveComponent:
                                  component.compName === "description" &&
                                  activeWrapper === `wrapper${sIndex}${wIndex}`,
                              })
                            )}
                          </FieldsWrapper>
                        ))}
                      </CollapsibleContainer>
                    </SectionWrapper>
                  ) : (
                    <SectionWrapper isOpen={open} key={sIndex} size={size}>
                      {sectionRow.wrappers.map((wrapper, wIndex) => (
                        <FieldsWrapper
                          key={sIndex + wIndex}
                          active={activeWrapper === `wrapper${sIndex}${wIndex}`}
                          name={`wrapper${sIndex}${wIndex}`}
                          handleFocus={(name) => setActiveWrapper(name)}
                          width={
                            wrapper.width ? wrapper.width[size] : undefined
                          }
                          size={size}
                          borderActive={borderStyle !== undefined}
                          rows={sectionRow.rows}
                          textFieldHeight={heightProps.textFieldHeight}
                        >
                          {wrapper.components.map((component, cIndex) =>
                            handleGenericComponents({
                              componentInfo: component,
                              rows: sectionRow.rows,
                              autoFocus: sectionRow.autoFocus,
                              currentIndex: cIndex,
                              length: wrapper.components.length,
                              isActiveComponent:
                                component.compName === "description" &&
                                activeWrapper === `wrapper${sIndex}${wIndex}`,
                            })
                          )}
                        </FieldsWrapper>
                      ))}
                      {designId !== DesignOptions.DYNAMIC &&
                        !overwriteDesign && (
                          <BudgetBar
                            isDisabled={
                              timeInput.jobTask === null ||
                              budget === null ||
                              (record !== null && timeInput.jobTask === null)
                            }
                            budgetValues={budget}
                            size={size}
                            borderActive={borderStyle !== undefined}
                            canOverspend={
                              timeInput.jobPlanningLine === null ||
                              (timeInput.jobPlanningLine &&
                                timeInput.jobPlanningLine.id !== null &&
                                timeInput.tntModelLine !== null &&
                                timeInput.jobPlanningLine.serviceBillingType !==
                                  ServiceBillingTypeOptions.TIME_MATERIAL)
                                ? true
                                : false
                            }
                          />
                        )}
                    </SectionWrapper>
                  )
                )}
                {!noActionButtons && (
                  <SectionWrapper
                    isOpen={open}
                    key={"buttonsContainer"}
                    size={size}
                  >
                    <ButtonsContainer
                      onSelectTemplate={onSelectTemplate}
                      setBlockEscape={setBlockEscape}
                      isDisabled={
                        timeInput.status
                          ? timeInput.status !== StatusOptions.OPEN
                          : false
                      }
                      onSave={onSave}
                      size={size}
                      jobTaskStatus={timeInput.jobTask as PopulatedJobTask}
                      templateCategory={templateCategory}
                      templateEntry={timeInput as RecordTemplate}
                      loading={blockSaveRecord}
                      onCloseHandler={onCloseHandler}
                      saveAndNew={ignoreOpenNewEditor}
                      blockClosingCallback={(val) =>
                        blockClosingCallback && blockClosingCallback(val)
                      }
                      osModifiers={{
                        modifiers: platform.modifiers,
                        modifiersDesc: platform.description,
                      }}
                      localeHyphen={states.getter.localeHyphen}
                      validateTemplateObject={validateTemplateObject}
                      pending={pending}
                      states={{
                        openCreatedRecording: (value) =>
                          states.setter &&
                          states.setter.templates &&
                          states.setter.templates.openCreatedRecording &&
                          states.setter.templates.openCreatedRecording(value),
                        clearLatestCreatedId: () =>
                          states.setter &&
                          states.setter.clearLatestCreatedId &&
                          states.setter.clearLatestCreatedId(),
                        deleteRecord: async (id) =>
                          states.setter &&
                          states.setter.templates &&
                          states.setter.templates.deleteRecord
                            ? states.setter.templates.deleteRecord(id)
                            : false,
                        isChecked: states.getter.openCreatedRecording
                          ? states.getter.openCreatedRecording
                          : false,
                        getAllTemplates:
                          states.setter &&
                          states.setter.templateList &&
                          states.setter.templateList.getAllTemplates,
                        templates: states.getter.templates || [],
                      }}
                    >
                      {design.moreOptions && (
                        <MoreOptionsAction
                          title={
                            moreOptions
                              ? t("timerecs.editor.options.showLess")
                              : t("timerecs.editor.options.showMore")
                          }
                          size={size}
                          onClick={() => {
                            if (moreOptions) {
                              setEditorHeight(
                                (prev) => prev - heightProps.moreOptions
                              );
                              currentHeight &&
                                currentHeight(
                                  editorHeight - heightProps.moreOptions
                                );
                              setMoreOptions(false);
                            } else {
                              setEditorHeight(
                                (prev) => prev + heightProps.moreOptions
                              );
                              currentHeight &&
                                currentHeight(
                                  editorHeight + heightProps.moreOptions
                                );
                              setMoreOptions(true);
                            }
                          }}
                        />
                      )}
                    </ButtonsContainer>
                  </SectionWrapper>
                )}
                {design.moreOptions &&
                  design.moreOptions.sectionRows.map((sectionRow, sIndex) =>
                    sectionRow.collapsible ? (
                      <SectionWrapper
                        key={sIndex + "moreOptions"}
                        isOpen={open}
                        size={size}
                        moreOptionsWrapper={
                          design.moreOptions &&
                          design.moreOptions.sectionRows.length === sIndex + 1
                        }
                      >
                        <MoreOptionsWrapper
                          key={sIndex + "moreOptionsWrapper"}
                          isOpen={open}
                          openWrapper={moreOptions}
                          size={size}
                        >
                          <CollapsibleContainer
                            key={sIndex + "moreOptionsCollapsibleContainer"}
                            title={handleCollapsibleHeaderTitle(
                              sectionRow.collapsible.header
                            )}
                            collapsedInfo={handleCollapsedInfo(sectionRow)}
                            infoIcon={handleInfoIcon(sectionRow)}
                            initialOpen={handleInitialOpen(sectionRow)}
                            onClickCollapsible={(val) =>
                              handleCollapseContainerCalculation(val)
                            }
                            size={size}
                          >
                            {sectionRow.wrappers.map((wrapper, wIndex) => (
                              <FieldsWrapper
                                key={sIndex + wIndex + "moreOptions"}
                                active={
                                  activeWrapper === `wrapper${sIndex}${wIndex}`
                                }
                                name={`wrapper${sIndex}${wIndex}`}
                                handleFocus={(name) => setActiveWrapper(name)}
                                width={
                                  wrapper.width
                                    ? wrapper.width[size]
                                    : undefined
                                }
                                size={size}
                                borderActive={borderStyle !== undefined}
                                rows={sectionRow.rows}
                                textFieldHeight={heightProps.textFieldHeight}
                              >
                                {wrapper.components.map((component, cIndex) =>
                                  handleGenericComponents({
                                    componentInfo: component,
                                    rows: sectionRow.rows,
                                    autoFocus: sectionRow.autoFocus,
                                    currentIndex: cIndex,
                                    length: wrapper.components.length,
                                    isActiveComponent:
                                      component.compName === "description" &&
                                      activeWrapper ===
                                        `wrapper${sIndex}${wIndex}`,
                                  })
                                )}
                              </FieldsWrapper>
                            ))}
                          </CollapsibleContainer>
                        </MoreOptionsWrapper>
                      </SectionWrapper>
                    ) : (
                      <SectionWrapper
                        key={sIndex + "moreOptions"}
                        isOpen={open}
                        size={size}
                        moreOptionsWrapper={
                          design.moreOptions &&
                          design.moreOptions.sectionRows.length === sIndex + 1
                        }
                      >
                        <MoreOptionsWrapper
                          key={sIndex + "moreOptionsWrapper"}
                          isOpen={open}
                          openWrapper={moreOptions}
                          size={size}
                        >
                          {sectionRow.wrappers.map((wrapper, wIndex) => (
                            <FieldsWrapper
                              key={sIndex + wIndex + "moreOptions"}
                              active={
                                activeWrapper === `wrapper${sIndex}${wIndex}`
                              }
                              name={`wrapper${sIndex}${wIndex}`}
                              handleFocus={(name) => setActiveWrapper(name)}
                              width={
                                wrapper.width ? wrapper.width[size] : undefined
                              }
                              size={size}
                              borderActive={borderStyle !== undefined}
                              rows={sectionRow.rows}
                              textFieldHeight={heightProps.textFieldHeight}
                            >
                              {wrapper.components.map((component, cIndex) =>
                                handleGenericComponents({
                                  componentInfo: component,
                                  rows: sectionRow.rows,
                                  autoFocus: sectionRow.autoFocus,
                                  currentIndex: cIndex,
                                  length: wrapper.components.length,
                                  isActiveComponent:
                                    component.compName === "description" &&
                                    activeWrapper ===
                                      `wrapper${sIndex}${wIndex}`,
                                })
                              )}
                            </FieldsWrapper>
                          ))}
                        </MoreOptionsWrapper>
                      </SectionWrapper>
                    )
                  )}
                <HiddenComponents>
                  {handleHiddenComponents().map((hiddenComp, hIndex) =>
                    handleGenericComponents({
                      componentInfo: hiddenComp,
                      rows: 1,
                      autoFocus: undefined,
                      currentIndex: hIndex,
                      length: handleHiddenComponents().length,
                    })
                  )}
                </HiddenComponents>
                {/** Hidden Focusable Component */}
                <HiddenFocusInput />
              </form>
            </FormProvider>
          )}
        </Box>
      </ClickAwayListener>
      <Dialog
        open={openDialog}
        title={t("timerecs.editor.dialog.title")}
        text={t("timerecs.editor.dialog.text")}
        actionButtonLabels={{
          confirm: t("timerecs.editor.options.saveAndClose"),
        }}
        onSubmit={(e) => {
          setOpenDialog(false);
          onCloseHandler(e as any, "saveAndClose");
        }}
        isDisabled={blockClosing}
        onCancel={() => setOpenDialog(false)}
        onDiscard={(e) => onCloseHandler(e as any, "abort")}
        size="sm"
      />
    </>
  );
}
