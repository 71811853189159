import { DateTime, Info } from "luxon";
import moment, { Moment } from "moment";

/**
 * Parse Value from outside to DateTime object
 * @param val Value (prop) from outside of the Component
 * @returns DateTime object
 */
export const parseValue = (
  val: string | DateTime | undefined,
  allowEmptyField: boolean
): Moment | null => {
  let parsedVal: Moment | null;

  if (typeof val === "string") {
    if (val.length === 0 && allowEmptyField) {
      parsedVal = null;
    } else {
      parsedVal = moment(val);
    }
  } else {
    if (val) {
      parsedVal = moment(val.toISO());
    } else {
      parsedVal = moment();
    }
  }

  return parsedVal;
};

/**
 * Function to find weekDay from input when fits to short day declaration (mo === monday)
 * @param val Userinput as string
 * @param locale Locale information ("en-US")
 * @returns Date as ISO String or user input
 */
export const parseFromString = (
  val: string,
  locale: string,
  allowEmptyField: boolean
): string => {
  if (val.length === 0 && allowEmptyField) {
    return val;
  }
  // Create array from days
  const weekdays: string[] = Info.weekdaysFormat(undefined, {
    locale: locale,
  }).map((l) => l.toLowerCase());

  const search: number = weekdays.findIndex((e) =>
    e.startsWith(val.toLowerCase())
  );

  if (search < 0) return val;

  const day = DateTime.now()
    .setLocale(locale)
    .startOf("week")
    .plus({ days: search });

  return day.toISO();
};

/**
 * Function to find the correct date from the user input
 * @param data Input from user as string
 * @param dateFormat Date format information e.g. "dd.MM.yyyy" | "MM/dd/yyyy" | "yyyy-MM-dd"
 * @param isEnglish Language information, if true, language is english
 * @returns Date as ISO string or value
 */
export const parseFromNumber = (
  data: string,
  dateFormat: string,
  isEnglish: boolean
): string => {
  const dateFormatRegion = dateFormat.split(/[.]|[/]|[-]/)[0].toLowerCase() as
    | "dd"
    | "mm"
    | "yyyy";

  // Replace all separators with empty string
  let replacedData = data.split(/[.]|[/]|[-]/).join("");

  /**
   * Regex to find date in string with input as format:
   * dd.mm.yyyy, dd.mm.yy, dd.mm., dd.mm ||
   * mm.dd.yyyy, mm.dd.yy, mm.dd., mm.dd ||
   * yyyy.mm.dd, yy.mm.dd, yyyy.mm., yyyy.mm
   * with this separators: "." || "-" || "/"
   * Does not allow input like 1.51.1
   */
  const regDate = new RegExp(
    "\\d{1,2}[./-](0[1-9]|1[0-2])([./-](\\d{4}|\\d{2})|[./-]?)|(0[1-9]|1[012])[-./](0[1-9]|[12][0-9]|3[01])([./-](\\d{4}|\\d{2})|[./-]?)|(\\d{4}|\\d{2})[-./](0[1-9]|1[012])([-./](0[1-9]|[12][0-9]|3[01])|[./-]?)"
  );
  if (regDate.test(data)) {
    switch (data.length) {
      // e.g. dd.mm.yyyy
      case 10: {
        return validLocaleDate(replacedData, dateFormatRegion);
      }
      // e.g. dd.mm.yy => dd.mm.yyyy
      case 8: {
        if (dateFormatRegion === "yyyy") {
          replacedData = replacedData = DateTime.fromFormat(
            replacedData,
            "yyMMdd"
          ).toFormat("yyyyMMdd");
        } else if (dateFormatRegion === "dd") {
          replacedData = DateTime.fromFormat(replacedData, "ddMMyy").toFormat(
            "ddMMyyyy"
          );
        } else {
          replacedData = DateTime.fromFormat(replacedData, "MMddyy").toFormat(
            "MMddyyyy"
          );
        }
        return validLocaleDate(replacedData, dateFormatRegion);
      }
      // e.g. dd.mm. => dd.mm.yyyy || .mm.dd => yyyy.mm.dd
      case 6: {
        if (dateFormatRegion !== "yyyy") {
          replacedData = replacedData + DateTime.now().toFormat("yyyy");
        } else {
          replacedData = DateTime.now().toFormat("yyyy") + replacedData;
        }
        return validLocaleDate(replacedData, dateFormatRegion);
      }
      // e.g. dd.mm => dd.mm.yyyy || mm.dd => yyyy.mm.dd
      case 5: {
        if (dateFormatRegion !== "yyyy") {
          replacedData = replacedData + DateTime.now().toFormat("yyyy");
        } else {
          replacedData = DateTime.now().toFormat("yyyy") + replacedData;
        }
        return validLocaleDate(replacedData, dateFormatRegion);
      }
      default: {
        return DateTime.fromISO(data).toISO();
      }
    }
  } else {
    // No separator in input found
    switch (data.length) {
      case 8: {
        // e.g. ddMMyyyy => dd.MM.yyyy || yyyyMMdd => yyyy.MM.dd
        return validLocaleDate(data, dateFormatRegion);
      }
      case 6: {
        // e.g. ddMMyy => dd.MM.yyyy || yyMMdd => yyyy.MM.dd
        if (dateFormatRegion === "yyyy") {
          data = DateTime.now().toFormat("yyyy").slice(0, 2) + data;
        } else if (dateFormatRegion === "dd") {
          data = DateTime.fromFormat(replacedData, "ddMMyy").toFormat(
            "ddMMyyyy"
          );
        } else {
          data = DateTime.fromFormat(replacedData, "MMddyy").toFormat(
            "MMddyyyy"
          );
        }
        return validLocaleDate(data, dateFormatRegion);
      }
      case 4: {
        // e.g. ddMM => dd.MM.yyyy || MMdd => MM.dd.yyyy || MMdd => yyyy.MM.dd
        if (dateFormatRegion !== "yyyy") {
          data = data + DateTime.now().toFormat("yyyy");
        } else {
          data = DateTime.now().toFormat("yyyy") + data;
        }
        return validLocaleDate(data, dateFormatRegion);
      }
      case 3: {
        /**
         * Allways autocomplete with current month and year like in BC in correct format
         */
        // Allow input like 15. or 15/ or 15-
        if (data.endsWith(".") || data.endsWith("/") || data.endsWith("-")) {
          // e.g. dd. => dd.MM.yyyy || MM.dd.yyyy || yyyy.MM.dd
          if (dateFormatRegion === "dd") {
            data =
              data.split(/[.]|[/]|[-]/).join("") +
              DateTime.now().toFormat("MMyyyy");
          } else if (dateFormatRegion === "mm") {
            data =
              DateTime.now().toFormat("MM") +
              data.split(/[.]|[/]|[-]/).join("") +
              DateTime.now().toFormat("yyyy");
          } else {
            data =
              DateTime.now().toFormat("yyyyMM") +
              data.split(/[.]|[/]|[-]/).join("");
          }
          return validLocaleDate(data, dateFormatRegion);
        } else {
          return validLocaleDate(data, dateFormatRegion);
        }
      }
      case 2: {
        if (isNaN(parseInt(data))) {
          return data;
        }
        /**
         * Allways autocomplete with current month and year like in BC in correct format
         */
        // e.g. dd => dd.MM.yyyy || MM.dd.yyyy || yyyy.MM.dd
        if (dateFormatRegion === "dd") {
          data = data + DateTime.now().toFormat("MMyyyy");
        } else if (dateFormatRegion === "mm") {
          data =
            DateTime.now().toFormat("MM") +
            data +
            DateTime.now().toFormat("yyyy");
        } else {
          data = DateTime.now().toFormat("yyyyMM") + data;
        }
        return validLocaleDate(data, dateFormatRegion);
      }
      case 1: {
        /**
         * Allways autocomplete with current month and year like in BC
         * in correct format;
         * Character 'h' | 't' is used to autocomplete with current day depending
         * on language;
         */
        if ((data === "t" && isEnglish) || (data === "h" && !isEnglish)) {
          const newDateFormat =
            dateFormatRegion === "dd"
              ? "ddMMyyyy"
              : dateFormatRegion === "mm"
              ? "MMddyyyy"
              : "yyyyMMdd";
          data = DateTime.now()
            .set({
              hour: 0,
              minute: 0,
              second: 0,
              millisecond: 0,
            })
            .toFormat(newDateFormat);
        } else {
          // e.g. d => dd.MM.yyyy || MM.dd.yyyy || yyyy.MM.dd
          if (dateFormatRegion === "dd") {
            data = "0" + data + DateTime.now().toFormat("MMyyyy");
          } else if (dateFormatRegion === "mm") {
            data =
              DateTime.now().toFormat("MM") +
              "0" +
              data +
              DateTime.now().toFormat("yyyy");
          } else {
            data = DateTime.now().toFormat("yyyyMM") + "0" + data;
          }
        }
        return validLocaleDate(data, dateFormatRegion);
      }
      case 0: {
        // Return empty string also possible for 'allowEmptyField'
        return data;
      }
      default: {
        return DateTime.fromISO(data).toISO();
      }
    }
  }
};

/**
 * Validate user input to correct date in individual locations
 * @param input Userinput: dateString as 8 digits (06152021)
 * @param locale location (en-US)
 * @returns Date in ISO format or empty string
 */
const validLocaleDate = (
  input: string,
  dateFormatRegion: "dd" | "mm" | "yyyy"
): string => {
  switch (dateFormatRegion) {
    case "dd":
      return DateTime.fromFormat(input, "ddMMyyyy").toISO();
    case "mm":
      return DateTime.fromFormat(input, "MMddyyyy").toISO();
    case "yyyy":
      return DateTime.fromFormat(input, "yyyyMMdd").toISO();
    default:
      return DateTime.invalid("not found").toISO();
  }
};
