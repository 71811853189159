import React, { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { createRolesView } from "../userList/utils";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Client, PopulatedRole } from "@dyce/tnt-api";
import { DeleteOutline } from "@mui/icons-material";
// ReactDataGrid
import ReactDataGrid, { Column } from "react-data-grid";
import {
  selectDarkMode,
  selectLoadingClients,
  useAppSelector,
} from "@dyce/slices";
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { handleExpirationDate } from "./utils";

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1rem",
      height: "100%",
    },
    noContent: {
      display: "flex",
      justifyContent: "center",
      marginTop: "3rem",
      width: "100%",
      height: "100px",
    },
  })
);

interface IClientsListProps {
  clients: Client[];
  roles: PopulatedRole[];
  getToken: (id: string) => void;
  deleteClient: (id: string) => void;
  editClient: (value: Client) => void;
}

export const ClientsList: FunctionComponent<IClientsListProps> = ({
  clients,
  roles,
  getToken,
  deleteClient,
  editClient,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // Selectors
  const isLoading = useAppSelector(selectLoadingClients);
  const darkMode: boolean = useAppSelector(selectDarkMode);

  // Data-Grid columns
  const columns: readonly Column<Client>[] = [
    {
      key: "name",
      name: "Name",
      width: 250,
    },
    {
      key: "roles",
      name: t("admin.users.grid.roles"),
      formatter: (props) => (
        <div>
          {createRolesView({
            currentRoles: props.row.roles,
            availableRoles: roles,
            clickable: false,
          })}
        </div>
      ),
      resizable: true,
    },
    {
      key: "resource",
      name: t("admin.users.grid.resource"),
      formatter: (props) => <div>{props.row.resource?.name}</div>,
      editorOptions: {
        editOnClick: true,
      },
      width: 270,
    },
    {
      key: "validUntil",
      name: t("admin.clients.grid.header.validUntil"),
      formatter: (props) => (
        <div style={{ color: handleExpirationDate(props.row.validUntil) }}>
          {props.row.validUntil
            ? DateTime.fromISO(props.row.validUntil).toISODate()
            : ""}
        </div>
      ),
      editorOptions: {
        editOnClick: true,
      },
      width: 150,
    },
    {
      key: "token",
      name: t("admin.clients.grid.header.clientKey"),
      formatter: (props) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "3px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            sx={{ padding: "2px 8px" }}
            onClick={() => getToken(props.row.id)}
          >
            {t("admin.clients.grid.showToken")}
          </Button>
        </div>
      ),
      width: 150,
    },
    {
      key: "edit",
      name: t("admin.clients.grid.header.edit"),
      formatter: (props) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "3px",
          }}
        >
          <Button
            variant="contained"
            size="small"
            color="inherit"
            sx={{ padding: "2px 8px" }}
            onClick={() => editClient(props.row)}
          >
            {t("admin.clients.grid.editClient")}
          </Button>
        </div>
      ),
      width: 150,
    },
    {
      key: "delete",
      name: t("admin.clients.grid.header.delete"),
      formatter: (props) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "3px",
          }}
        >
          <IconButton
            size="small"
            sx={{ padding: "3px" }}
            color="error"
            onClick={() => deleteClient(props.row.id)}
          >
            <DeleteOutline />
          </IconButton>
        </div>
      ),
      width: 50,
    },
  ];

  //Theme
  const darkTheme: string = darkMode ? "rdg-dark" : "rdg-light";

  return (
    <div className={classes.content}>
      <ReactDataGrid
        columns={columns}
        rows={clients}
        rowKeyGetter={(r) => r.id}
        emptyRowsRenderer={() =>
          isLoading ? (
            <div className={classes.noContent}>
              <CircularProgress size={32} />
            </div>
          ) : (
            <div className={classes.noContent}>
              <Typography>{t("admin.clients.grid.noContent")}</Typography>
            </div>
          )
        }
        selectedRows={null}
        className={`${darkTheme}`}
        style={{
          height: clients.length * 35 + 37,
          maxHeight: "calc(100% - 55px)",
        }}
      />
    </div>
  );
};
