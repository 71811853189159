import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Button, Typography } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
    footer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "1rem",
      marginTop: "4rem",
      marginBottom: "-1rem",
    },
  })
);

interface IConsentDialogProps {
  /**
   * Callback fired when onSubmit is clicked
   * @returns void
   */
  onSubmit: () => void;
  /**
   * Callback fired when onCancel is clicked
   * @returns void
   */
  onCancel: () => void;
}

export const ConsentDialog: FunctionComponent<IConsentDialogProps> = ({
  onSubmit,
  onCancel,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // UseStates
  const [timer, setTimer] = useState<number>(1);

  useEffect(() => {
    const countdown = setInterval(() => {
      if (timer < 10) {
        setTimer((timer) => timer + 1);
      }
    }, 1000);
    return () => {
      clearInterval(countdown);
    };
  }, []);

  return (
    <>
      <div className={classes.container}>
        <Typography sx={{ whiteSpace: "pre-wrap" }}>
          {t("timerec.calendar.dialog.consent.content.info")}
          <br />
          <br />
          {t("timerec.calendar.dialog.consent.content.consentInfo")}
          <br />
          <br />
          {t("timerec.calendar.dialog.consent.content.consentLaterOrNow")}
        </Typography>
      </div>
      <div className={classes.footer}>
        {timer >= 10 && (
          <Button
            onClick={() => onCancel()}
            color="inherit"
            variant="contained"
            size="small"
          >
            {t("timerec.calendar.dialog.cancel")}
          </Button>
        )}
        <Button
          onClick={() => onSubmit()}
          color="primary"
          variant="contained"
          size="small"
          disabled={timer < 10}
        >
          {timer < 10 ? timer : t("timerec.calendar.dialog.submit")}
        </Button>
      </div>
    </>
  );
};
