import { FunctionComponent, ReactNode } from "react";
// Jira
import { BackgroundColor, Box, type Space, xcss } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";
import { colors } from "@atlaskit/theme";

const blockStyles = xcss({
  display: "flex",
  borderRadius: "border.radius",
  minWidth: "2rem",
  minHeight: "2rem",
  borderWidth: "border.width",
  overflow: "auto",
});

interface IGridBoxProps {
  /**
   * Style for Box
   */
  style?: React.CSSProperties;
  /**
   * Padding for Box (Space)
   * @default "space.200"
   */
  padding?: Space;
  /**
   * BackgroundColor for Box
   * @default "transparent"
   */
  backgroundColor?: BackgroundColor;
  /**
   * Border for Box
   * @default false
   */
  withBorder?: boolean;
  /**
   * Children
   */
  children?: ReactNode;
}

export const GridBox: FunctionComponent<IGridBoxProps> = ({
  style,
  padding = "space.200",
  backgroundColor,
  withBorder = false,
  children,
}) => {
  return (
    <Box
      style={
        withBorder
          ? {
              borderColor: token("color.border", colors.N50),
              borderStyle: "solid",
              ...style,
            }
          : style
      }
      padding={padding}
      backgroundColor={backgroundColor}
      xcss={blockStyles}
    >
      {children}
    </Box>
  );
};
