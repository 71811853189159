import { FunctionComponent } from "react";
// Helper
import { Trans, useTranslation } from "react-i18next";
import { DyceTheme } from "@dyce/theme";
// MUI
import { Paper } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import JiraButton from "@atlaskit/button";
// Jira
import EmojiAtlassianIcon from "@atlaskit/icon/glyph/emoji/atlassian";
// ADO
import { Button as ADOButton } from "azure-devops-ui/Button";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    content: {
      backgroundColor: theme.palette.background.default,
      display: "flex",
      width: "100%",
      gap: "1rem",
      height: "100vh",
      justifyContent: "center",
      alignItems: "center",
      padding: "2rem",
      "& h1": {
        font: "Roboto",
        fontSize: "34px",
        fontWeight: "normal",
        margin: "0px 0px 8px 0px",
        color: theme.palette.primary.main,
        [theme.breakpoints.down("sm")]: {
          fontSize: "24px",
          margin: "0px 0px 4px 0px",
        },
      },
      "& h3": {
        font: "Roboto",
        fontSize: "20px",
        fontWeight: "medium",
        letterSpacing: "0.15px",
        margin: "0px 0px 8px 0px",
        color: theme.palette.text.primary,
        [theme.breakpoints.down("sm")]: {
          fontSize: "16px",
          margin: "0px 0px 4px 0px",
        },
      },
      "& p": {
        fontSize: "16px",
        fontWeight: "normal",
        letterSpacing: "0.5px",
        color: theme.palette.text.primary,
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      maxWidth: "400px",
      height: "450px",
      borderRadius: theme.shape.borderRadius,
      padding: "1.5rem 2rem",
      textAlign: "center",
    },
    buttonContainer: {
      height: "100%",
      width: "100%",
      display: "flex",
      justifyContent: "end",
      alignItems: "flex-end",
    },
  })
);

interface IForeignHomeProps {
  appName: "Jira" | "Azure DevOps";
}

export const ForeignHome: FunctionComponent<IForeignHomeProps> = ({
  appName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // Handler
  const handleMarketplaceLink = () => {
    switch (appName) {
      case "Jira":
        return "https://marketplace.atlassian.com/plugins/dyce-time-tracking";
      case "Azure DevOps":
        return "https://marketplace.visualstudio.com/items?itemName=DYCE.dyce-tnt";
      default:
        return "";
    }
  };

  const handleEnvironmentLogin = () => {
    switch (appName) {
      case "Jira": {
        window.location.href =
          "https://id.atlassian.com/login?continue=https%3A%2F%2Fstart.atlassian.com%2F&application=start";
        break;
      }
      case "Azure DevOps":
        window.location.href = "https://dev.azure.com/";
        break;
    }
  };

  return (
    <div className={classes.content}>
      <Paper elevation={6} className={classes.container}>
        <Trans>
          <h1>
            {t("home.foreignApp.info.title", {
              appName: appName,
            })}
          </h1>
          <h3>
            {t("home.foreignApp.info.caption", {
              appName: appName,
            })}
          </h3>
          <p
            style={{
              color: "grey",
            }}
          >
            {t("home.foreignApp.info.appInfo.start")}
            <a
              href={handleMarketplaceLink()}
              target="_blank"
              referrerPolicy="no-referrer"
            >
              {t("home.foreignApp.info.appInfo.marketplace")}
            </a>
            {t("home.foreignApp.info.appInfo.end", {
              appName: appName,
            })}
          </p>
        </Trans>
        <div className={classes.buttonContainer}>
          {appName === "Jira" ? (
            <JiraButton
              onClick={() => handleEnvironmentLogin()}
              iconBefore={<EmojiAtlassianIcon label="" size="medium" />}
              appearance="primary"
            >
              Login
            </JiraButton>
          ) : appName === "Azure DevOps" ? (
            <ADOButton
              primary
              iconProps={{ iconName: "AzureIcon" }}
              text="Login"
              onClick={() => handleEnvironmentLogin()}
            />
          ) : null}
        </div>
      </Paper>
    </div>
  );
};
