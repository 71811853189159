import { FunctionComponent } from "react";
// Helper
import { Line, Tooltip, Area, AreaChart } from "recharts";
import { DateTime } from "luxon";
// Dyce-Lib
import { IDataElement } from "@dyce/tnt-api";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Paper } from "@mui/material";

const useStyle = makeStyles(() =>
  createStyles({
    tooltipContainer: {
      padding: 10,
    },
    tooltipLine: {
      padding: 0,
      margin: 0,
    },
    plotContainer: {
      paddingTop: 20,
      display: "flex",
      justifyContent: "center",
    },
  })
);

interface ITooltipProps {
  active?: boolean;
  payload?: {
    payload: { timeStamp: string };
    value: string;
  }[];
}

const CustomTooltip: FunctionComponent<ITooltipProps> = ({
  active,
  payload,
}) => {
  const classes = useStyle();

  if (active && payload) {
    return (
      <Paper className={classes.tooltipContainer}>
        <p className={classes.tooltipLine}>{`${DateTime.fromISO(
          payload[0].payload.timeStamp
        ).toFormat("dd.MM.yyyy 'at' HH:mm")}`}</p>
        <p
          className={classes.tooltipLine}
        >{`${payload[0].value} Operations`}</p>
      </Paper>
    );
  } else {
    return null;
  }
};

interface ITPlotProps {
  /**
   * Width for chart
   * @default 300
   */
  width?: number;
  /**
   * Height for chart
   * @default 300
   */
  height?: number;
  /**
   * Data element array
   */
  chartData: IDataElement[];
}

export const PlotGraph: FunctionComponent<ITPlotProps> = ({
  width = 300,
  height = 300,
  chartData,
}) => {
  const classes = useStyle();

  return (
    <div className={classes.plotContainer}>
      <AreaChart width={width} height={height} data={chartData}>
        <defs>
          <linearGradient id="grd" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#7266FF" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#7266FF" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Line
          type="monotone"
          dataKey="value"
          stroke="#7266FF"
          activeDot={{ r: 8 }}
          data-testid="line"
        />
        <Area
          type="monotone"
          dataKey="value"
          stroke="#7266FF"
          fillOpacity={1}
          fill="url(#grd)"
        />
        <Tooltip content={<CustomTooltip />} />
      </AreaChart>
    </div>
  );
};
