import { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { useStaticContent } from "../static-provider/static-provider";
// MUI
import { Typography } from "@mui/material";

interface IConsentTextProps {
  /**
   * Show text with settings info
   * @default false
   */
  showWithSettingsInfo?: boolean;
  /**
   * Callback for settings link
   * @returns void
   */
  onSettingsLink?: () => void;
}

export const ConsentText: FunctionComponent<IConsentTextProps> = ({
  showWithSettingsInfo,
  onSettingsLink,
}) => {
  const { t } = useTranslation();
  const { routes } = useStaticContent();
  const dataPrivacy = "https://www.singhammer.com/datenschutz-sicherheit/";

  return (
    <Typography component="span" variant="body2" textAlign={"start"}>
      {t("cookieBanner.privacy.captionStart")}{" "}
      <a href={dataPrivacy} target="_blank" referrerPolicy="no-referrer">
        {t("cookieBanner.privacy.captionLink")}
      </a>{" "}
      {t("cookieBanner.privacy.captionEnd")}{" "}
      {showWithSettingsInfo ? (
        <>
          {t("cookieBanner.privacy.settingsInfo.settings.start")}{" "}
          <a
            href={`${routes.SETTINGS}?highlight=cookieConsent`}
            onClick={() => onSettingsLink && onSettingsLink()}
          >
            {t("cookieBanner.privacy.settingsInfo.settings.settingsLink")}
          </a>{" "}
          {t("cookieBanner.privacy.settingsInfo.settings.end")}
        </>
      ) : null}
    </Typography>
  );
};
