import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { unwrapResult } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
// Dyce-Lib
import { RecordEntry, TemplateCategory, WorkItemType } from "@dyce/tnt-api";
import {
  clearLatestCreatedId,
  getAllTemplates,
  getOneTemplate,
  getRecord,
  selectAdminSettingsTasks,
  selectDarkMode,
  selectLanguageCode,
  selectLocaleHyphen,
  selectOpenCreatedRecording,
  setOpenCreatedRecording,
  setOpenEditor,
  templatesAsTwoDimArray,
  useAppDispatch,
  useAppSelector,
} from "@dyce/slices";
import { LoadingSpinner, TimeTrackingTool, useStaticContent } from "@dyce/ui";
import {
  handleRecordBudget,
  handleSaveTimerecording,
  handleUpdateTemplate,
  validateTemplateObject,
} from "@dyce/utils";
import { DesignOptions } from "@dyce/interfaces";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { IconButton, Slide, Typography } from "@mui/material";
import { ArrowBackIosNewOutlined } from "@mui/icons-material";
// Components

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
    backButtonContainer: {
      display: "flex",
      alignItems: "center",
      margin: "0 0 1rem 1rem",
    },
  })
);

export const TimeTrackingWrapper: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation<{
    state: {
      fromLocation: string;
    };
  }>();
  const history = useHistory();
  const { routes } = useStaticContent();

  // Selectors
  const language = useAppSelector(selectLanguageCode);
  const localeHyphen = useAppSelector(selectLocaleHyphen);
  const darkMode = useAppSelector(selectDarkMode);
  const templates = useAppSelector(templatesAsTwoDimArray);
  const tasksRule = useAppSelector(selectAdminSettingsTasks);
  const openInTimerecordings = useAppSelector(selectOpenCreatedRecording);

  // States
  const [open, setOpen] = useState<boolean>(true);
  const [openForm, setOpenForm] = useState<boolean>(true);
  const [fromLocation, setFromLocation] = useState<string>("");
  const [date, setDate] = useState<string | undefined>(undefined);
  const [lastEndTime, setLastEndTime] = useState<DateTime | null>(null);
  const [category, setCategory] = useState<TemplateCategory | null>(null);
  const [blockSaveRecord, setBlockSaveRecord] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [height, setHeight] = useState<number>(0);

  const [record, setRecord] = useState<
    | (RecordEntry & { date?: string } & { workItem?: WorkItemType | null })
    | null
  >(null);

  // USeEffects
  useEffect(() => {
    setFromLocation(location.state.state.fromLocation);
    if (location.hash) {
      setLoading(false);
      const isDate = DateTime.fromISO(location.hash.split("#")[1]).isValid;
      if (isDate) {
        // Create new record
        const searchParams = new URLSearchParams(location.search);
        const lastEndTimeParam = searchParams.get("lastEndTime");
        if (lastEndTimeParam) {
          setLastEndTime(DateTime.fromISO(lastEndTimeParam));
        }
        setDate(location.hash.split("#")[1]);
      } else if (location.hash === "#category") {
        // Create new template
        const searchParams = new URLSearchParams(location.search);
        const category: TemplateCategory = {
          name: decodeURIComponent(searchParams.get("name") || ""),
          id: decodeURIComponent(searchParams.get("id") || ""),
          resource: "",
        };
        setCategory(category);
      }
    } else {
      const isTemplate = location.search.includes("fromTemplates");
      const regex = /.*\/([\w-]+)$/;
      const match = location.pathname.match(regex);
      if (match && !isTemplate) {
        // Edit timeRecord
        handleGetRecord(match[1]);
        // Edit templateRecord
      } else if (match) {
        handleGetTemplateRecord(match[1]);
      }
    }
  }, [location]);

  useEffect(() => {
    if (!loading) {
      setOpenForm(true);
    }
  }, [loading]);

  // Handler
  const handleGetRecord = async (recordId: string) => {
    try {
      const res = await dispatch(getRecord(recordId));
      const response = unwrapResult(res);

      setDate(response.date);
      setRecord(response);
      setLoading(false);
    } catch (error) {
      console.warn(error);
      setLoading(false);
    }
  };

  const handleGetTemplateRecord = async (recordId: string) => {
    try {
      const res = await dispatch(getOneTemplate(recordId));
      const response = unwrapResult(res);

      const category: TemplateCategory = {
        ...response.category,
        resource: response.resource?.id || "",
      };
      setCategory(category);
      setRecord(response);
      setLoading(false);
    } catch (error) {
      console.warn(error);
      setLoading(false);
    }
  };

  const handleGoBack = () => {
    if (fromLocation === routes.TNT_RECS && date) {
      history.push(routes.TNT_RECS + "#" + date);
    } else {
      history.push(fromLocation);
    }
  };

  const handleBackCaption = () => {
    switch (fromLocation) {
      case routes.TNT_RECS:
        return t("timeTrackingForm.general.header.back.tntRecs");
      case routes.TNT_TEMPLATES:
        return t("timeTrackingForm.general.header.back.tntTemplates");
      case routes.TNT_INCOMPLETE:
        return t("timeTrackingForm.general.header.back.tntIncomplete");

      default:
        return t("timeTrackingForm.general.header.back.tntRecs");
    }
  };

  return (
    <Slide
      in={open}
      direction="left"
      onTransitionEnd={() => !open && handleGoBack()}
    >
      <div className={classes.container}>
        <div className={classes.backButtonContainer}>
          <IconButton onClick={() => setOpen(false)}>
            <ArrowBackIosNewOutlined />
          </IconButton>
          <Typography variant="body2">{handleBackCaption()}</Typography>
        </div>
        {loading ? (
          <LoadingSpinner
            delay={0}
            withRefreshButton={false}
            style={{
              height: `calc(100vh - 136px)`,
            }}
            text={t("timeTrackingForm.general.loading")}
          />
        ) : (
          <div
            style={{
              paddingRight: "4px",
              height: height,
            }}
          >
            <TimeTrackingTool<RecordEntry, DesignOptions.MOBILE_SIMPLE>
              open={openForm}
              record={record}
              lastEndTime={lastEndTime}
              onCloseEditor={() => setOpen(false)}
              onSave={(entry) => {
                if (category) {
                  return handleUpdateTemplate(entry, category, dispatch);
                } else {
                  return handleSaveTimerecording(entry, dispatch);
                }
              }}
              getRecordBudget={async ({ id, levelOfDetail }) =>
                await handleRecordBudget({
                  dispatch,
                  id,
                  levelOfDetail,
                  inHours: true,
                })
              }
              recordDate={date ? date : DateTime.now().toISODate()}
              currentHeight={(val) => setHeight(val)}
              templateCategory={category}
              ignoreOpenNewEditor={true}
              blockClosingCallback={(val) => setBlockSaveRecord(val)}
              blockSaveRecord={blockSaveRecord}
              allowClickAway={false}
              validateTemplateObject={(val) => {
                return validateTemplateObject(val as any, dispatch);
              }}
              isEnglish={language === "en"}
              workWithFilter={tasksRule.assignment}
              states={{
                setter: {
                  timeTrackingToolOpen: (val) => dispatch(setOpenEditor(val)),
                  clearLatestCreatedId: () => dispatch(clearLatestCreatedId()),
                  templates: {
                    openCreatedRecording: (val) =>
                      dispatch(
                        setOpenCreatedRecording({
                          tasks: openInTimerecordings.tasks,
                          templates: val,
                        })
                      ),
                  },
                  templateList: {
                    getAllTemplates: () => dispatch(getAllTemplates()),
                  },
                },
                getter: {
                  localeHyphen: localeHyphen,
                  darkMode: darkMode,
                  templates: templates,
                },
              }}
            />
          </div>
        )}
      </div>
    </Slide>
  );
};
