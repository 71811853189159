import { makeStyles, createStyles } from "@mui/styles";

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      width: "100%",
      height: "auto",
      fontWeight: 100,
    },
    textField: {
      padding: 50,
    },
    errorMessage: {
      color: "red",
    },
    appSelection: {
      paddingLeft: "1.5rem",
    },
    appSelectionWrap: {
      display: "flex",
      justifyContent: "left",
    },

    dyceLogo: {
      position: "absolute",
      top: 180,
      left: 50,
      height: 300,
      width: "auto",
      zIndex: -1,
    },
    header: {
      fontSize: 48,
      marginBottom: 30,
    },
    animationBox: {
      display: "flex",
      alignItems: "center",
      justifyContent: "left",
      flexWrap: "wrap",
      marginLeft: 230,
      fontSize: 72,
    },
    textWrapper: {
      display: "flex",
    },
    typoDiceD: {},
    typoDyceYBox: {
      height: 96,
      marginRight: 32,
      top: 10,
    },
    typoDyceY: {
      position: "absolute",
      display: "inline",
      marginLeft: -5,
    },
    typoDyceYsmall: {
      position: "absolute",
      display: "inline",
      color: "#3e00ee",
    },
    typoNamic: {
      marginRight: 20,
      color: "#694cba",
    },
    typoLoud: {
      marginRight: 20,
      color: "#5cffd0",
    },
    typoXperience: {
      color: "#29ffff",
    },
  })
);
