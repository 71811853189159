import { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { Button } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    wizardButtons: {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
      marginTop: theme.spacing(6),
      gap: theme.spacing(4),
      "& > *": {
        margin: theme.spacing(1),
      },
    },
  })
);

interface IStepperFooterProps {
  /**
   * Callback fired on onClick event 'next'
   */
  next: () => void;
  /**
   * Callback fired on onClick event 'previous'
   */
  previous: () => void;
  /**
   * Callback to get Button text for 'next'
   */
  primaryButtonText: () => string;
  /**
   * If true, 'next' is disabled
   * @default false
   */
  isDisabled: boolean;
}

export const StepperFooter: FunctionComponent<IStepperFooterProps> = ({
  next,
  previous,
  primaryButtonText,
  isDisabled = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.wizardButtons}>
      <Button onClick={previous} color="secondary" variant="outlined">
        {t("button.previous")}
      </Button>
      <Button
        onClick={next}
        disabled={isDisabled}
        color="primary"
        variant="contained"
      >
        {primaryButtonText()}
      </Button>
    </div>
  );
};
