import React, { FunctionComponent, useEffect } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
// Dyce-Lib
import { InstanceStatus } from "@dyce/tnt-api";
import { selectInstanceError, useAppSelector } from "@dyce/slices";
// MUI
import { Button, Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    rootContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      flexFlow: "row",
      width: "100%",
      height: "80%",
    },
    messageContainer: {
      maxWidth: 540,
      verticalAlign: "center",
    },
    header: {
      marginBottom: "20px !important",
    },
    spacer: {
      height: 20,
    },
    error: {
      color: "red",
      marginBottom: 20,
    },
  })
);

export const InstanceError: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const statusCode = useAppSelector(selectInstanceError);

  useEffect(() => {
    if (statusCode === null) {
      history.push("/");
    }
  }, [statusCode]);

  return (
    <div className={classes.rootContainer}>
      <div className={classes.messageContainer}>
        <Typography variant="h2" className={classes.header}>
          {t("instance.error.message.title", { code: statusCode })}
        </Typography>
        <Typography>
          {statusCode && statusCode === InstanceStatus.UNAVAILABLE
            ? t("instance.error.message.unavailable")
            : t("instance.error.message.upgrading")}
        </Typography>
        <div className={classes.spacer} />
        <Button
          color="secondary"
          variant="contained"
          onClick={() => history.push(location.pathname)}
        >
          {t("instance.error.back")}
        </Button>
      </div>
    </div>
  );
};
