import { DateTime } from "luxon";

/**
 * Parses manual input without seperator
 * @param val time input as String
 * @returns parsed time as String
 */
export const parseInput = (val: string): string => {
  const input = Number(val);
  if (Number.isNaN(input) || val.includes(".") || val.includes(",")) {
    return val;
  }

  // Format val if already in correct length
  if (val.length === 4) {
    return `${val.slice(0, 2)}:${val.slice(2, 4)}`;
  }

  // If 0 at beginning
  if (val.startsWith("0")) {
    if (val.length === 2) {
      return `${val}:00`;
    } else {
      if (val.length === 3) {
        const time = (parseInt(val.slice(1, 3)) / 10).toString().split(".");
        return `0${time[0]}:${time[1]}0`;
      } else {
        let minutes = val.slice(2, 3);
        if (minutes.length === 1) {
          minutes = minutes + "0";
        }
        return `00:${minutes.length === 0 ? "00" : minutes}`;
      }
    }
  }

  // If full format (h:mm); add leading 0
  if (val.length === 3) {
    val = "0" + val;
    return `${val.slice(0, 2)}:${val.slice(2, 4)}`;
  }

  if (val.length < 3 && val.length > 0) {
    return `${val.length === 1 ? "0" : ""}${val}:00`;
  } else {
    return "00:00";
  }
};

/**
 * Checks if given time string is correctly formatted
 * @param val time string
 * @returns true if valid input
 */
export const checkTimeInput = (val: string): boolean => {
  const regexp = /^\d+?:?\d{0,2}$/;

  const [hoursStr, minutesStr] = val.split(":");

  if (!regexp.test(val)) {
    return false;
  }

  const hours = Number(hoursStr);
  const minutes = Number(minutesStr);

  const isValidHour = (hour: number) =>
    Number.isInteger(hour) && hour >= 0 && hour < 24;
  const isValidMinutes = (minutes: number) =>
    (Number.isInteger(minutes) && hours >= 0 && hours < 24) ||
    Number.isNaN(minutes);

  if (!isValidHour(hours) || !isValidMinutes(minutes)) {
    return false;
  }

  if (minutes < 10 && Number(minutesStr[0]) > 5) {
    return false;
  }

  const valArr = val.indexOf(":") !== -1 ? val.split(":") : [val];

  // check mm and HH
  if (
    valArr[0] &&
    valArr[0].length &&
    (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)
  ) {
    return false;
  }

  if (
    valArr[1] &&
    valArr[1].length &&
    (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)
  ) {
    return false;
  }

  return true;
};

/**
 * Function to parse correct Date to selected Time (If not today,
 * parser gives correct Date to time)
 * @param date Date from recordingDay as DateTime
 * @param time Selected time as DateTime
 * @returns Time with correct Date
 */
export const combineDateAndTimeMoments = (
  date: DateTime | null,
  time: DateTime
): DateTime | null => {
  if (!(DateTime.isDateTime(date) && date.isValid)) return null;
  if (!(DateTime.isDateTime(time) && time.isValid)) return null;

  return date.set({
    hour: time.get("hour"),
    minute: time.get("minute"),
    second: 0,
    millisecond: 0,
  });
};

export const dateFromString = (value: string): DateTime => {
  let date = DateTime.now();
  date = date.set({
    hour: parseInt(value.split(":")[0]),
    minute: parseInt(value.split(":")[1]),
    second: 0,
    millisecond: 0,
  });

  return date;
};
