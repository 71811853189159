// Helper
import i18next from "i18next";
// Dyce-Lib
import { Customer } from "@dyce/tnt-api";
import { IDatagridColumn, IDatagridRow } from "@dyce/ui";

const expand = {
  jobs: { select: ["id", "no", "description"], top: 2 },
};

const createColumns = (): Record<string, IDatagridColumn> => {
  // Specify Columnheader in Objectkey: "name" and change editable to true if needed
  return {
    name: {
      key: "name",
      name: i18next.t("components.lookup.columnHeader.customer.name"),
      searchable: true,
      editable: true,
      display: true,
      sortable: true,
    },
  };
};

function createRows<T>(entries: Customer[]): IDatagridRow<T>[] {
  // set Columnvalue with specified DataBase Column
  const row: IDatagridRow[] = entries.map((entry: Customer) => {
    return {
      id: entry.id,
      cells: {
        name: entry.name,
        no: entry.no,
        skells: false,
        jobs:
          entry.jobs && entry.jobs.length > 0
            ? entry.jobs.map(
                (job: any) =>
                  entry && {
                    id: job.id,
                    no: job.no,
                    description: job.description,
                  }
              )
            : [],
      },
    };
  });

  return row;
}

const createSkeletonRows = (count: number): IDatagridRow<any>[] => {
  const row: IDatagridRow<any>[] = [...Array(count)].map((_, index) => {
    return {
      id: index.toString(),
      cells: {
        name: "",
        no: "",
        skells: true,
        jobs: {
          id: "",
          no: "",
          description: "",
        },
      },
    };
  });

  return row;
};

const getDbColumnSearch = (inputQuery: string): any[] => {
  // Define DataBase Column Names for search Query (Objectkey)
  const firstDbColumn: { name: { contains: string } } = {
    name: { contains: inputQuery },
  };

  return [firstDbColumn];
};

const getSqlColumnName = (
  sortColumn: string,
  sortDirection: string
): string => {
  const sqlColumn = {
    name: `name ${sortDirection}`,
    no: `no ${sortDirection}`,
    default: `no ${sortDirection}`,
  };

  return `${sqlColumn[sortColumn as "name"] || sqlColumn["default"]}`;
};

export default {
  expand,
  createColumns,
  createRows,
  getDbColumnSearch,
  createSkeletonRows,
  getSqlColumnName,
};
