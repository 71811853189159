import { useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DateTime } from "luxon";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { useDebounce, useUpdateEffect } from "@dyce/hooks";
import {
  DatePagination,
  SubHeader,
  useStaticContent,
  PeriodSummary,
} from "@dyce/ui";
import {
  useAppDispatch,
  useAppSelector,
  selectLocaleHyphen,
  selectHasResource,
  selectTimeRecordingSettings,
  selectCurrentWorkspace,
  selectLanguageCode,
  setClearPeriodTimes,
  selectSummaryAsPeriod,
  setClearPeriodStatistics,
  selectStatisticAsPeriod,
  selectPeriodTimesAndStatistics,
  getDashboardSummary,
  selectCurrentUserResources,
} from "@dyce/slices";
import {
  handleBarChartDateFormat,
  handleLoadDashboardSelection,
  handlePeriodText,
  handleSaveDashboardSelection,
} from "@dyce/utils";
import { PeriodTime, StatisticsPiePeriod } from "@dyce/tnt-api";
import { Periods } from "@dyce/interfaces";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { useMediaQuery, useTheme } from "@mui/material";
// Components
import { Plots } from "../../../components/timerecording/dashboard/WeeklyTimePlot";

const useStyle = makeStyles(() =>
  createStyles({
    pagination: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginTop: "-8px",
    },
    summary: {
      marginTop: "-6px",
      marginRight: "-16px",
    },
  })
);

export const Dashboard: React.FC = () => {
  const classes = useStyle();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme<DyceTheme>();
  // Responsive adjustments
  const {
    docuLinks,
    routes,
    isMobile: { tablet, mobile },
  } = useStaticContent();
  const isIpadProAndSmaller = useMediaQuery((theme: DyceTheme) =>
    theme.palette.propsDyce.drawerLeft.isOpen
      ? theme.breakpoints.down(1450)
      : theme.breakpoints.down(1250)
  );
  const isIpad = useMediaQuery((theme: DyceTheme) =>
    theme.breakpoints.down(1190)
  );
  const isIpadPro = useMediaQuery((theme: DyceTheme) =>
    theme.breakpoints.down(1200)
  );

  // Selectors
  const dashboardPeriodEntries = useAppSelector(selectPeriodTimesAndStatistics);
  const timeEntries = useAppSelector(selectSummaryAsPeriod);
  const statisticEntries = useAppSelector(selectStatisticAsPeriod);
  const localeHyphen = useAppSelector(selectLocaleHyphen);
  const resource = useAppSelector(selectHasResource);
  const userCapacity = useAppSelector(selectCurrentUserResources);
  const {
    dashboard: { showDaysWithoutCapacity },
  } = useAppSelector(selectTimeRecordingSettings);
  const workspace = useAppSelector(selectCurrentWorkspace);
  const language = useAppSelector(selectLanguageCode);

  // States
  const [newDateFormat, setNewDateFormat] = useState<boolean>(false);
  const [doRender, setDoRender] = useState<boolean>(false);
  const [date, setDate] = useState<string>(handleLoadDashboardSelection().date);
  const [plotHeight, setPlotHeight] = useState<number>(400);
  const [fade, setFade] = useState<boolean>(false);
  const [period, setPeriod] = useState<Periods>(
    handleLoadDashboardSelection().period
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [plotData, setPlotDate] = useState<{
    periodTime: PeriodTime[];
    statistics: StatisticsPiePeriod;
  } | null>(null);

  const { debouncedValue: showUp, setValue } = useDebounce<{
    width: number;
    open: boolean;
  }>({ width: 0, open: theme.palette.propsDyce.drawerLeft.isOpen }, 400);

  // UseEffects
  useEffect(() => {
    if (dashboardPeriodEntries !== null) {
      setPlotDate(dashboardPeriodEntries);
    }
  }, [dashboardPeriodEntries]);

  useEffect(() => {
    window.addEventListener("resize", autoResize);
    autoResize();
    return () => {
      removeEventListener("resize", autoResize);
    };
  }, [theme.palette.propsDyce.drawerLeft.isOpen]);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    const capaAndWorkedDays = timeEntries.filter((e) =>
      showDaysWithoutCapacity
        ? true
        : e.duration > 0 || e.capacity > 0
        ? true
        : false
    );

    if (capaAndWorkedDays.length < 6 && !showDaysWithoutCapacity) {
      if (windowWidth <= theme.breakpoints.values.timeRecsMin - 150) {
        if (
          capaAndWorkedDays.length > 3 &&
          isIpadProAndSmaller &&
          windowWidth > theme.breakpoints.values.dashboardBreakPoint
        ) {
          setNewDateFormat(true);
        } else {
          setNewDateFormat(false);
        }
      } else {
        setNewDateFormat(false);
      }
    } else {
      if (
        windowWidth <=
        theme.breakpoints.values.timeRecsMid +
          (theme.palette.propsDyce.drawerLeft.isOpen ? -80 : 50)
      ) {
        setNewDateFormat(true);
      } else {
        setNewDateFormat(false);
      }
    }
  }, [timeEntries, fade]);

  useUpdateEffect(() => {
    if (showUp.width > 0 && !isLoading) {
      setFade(false);
      setValue({ width: 0, open: theme.palette.propsDyce.drawerLeft.isOpen });
    }
  }, [showUp, isLoading]);

  useUpdateEffect(() => {
    if (!doRender && timeEntries.length > 0 && statisticEntries !== null) {
      setDoRender(true);
    }
    if (isLoading && timeEntries.length > 0 && statisticEntries !== null) {
      setIsLoading(false);
    }
  }, [timeEntries, statisticEntries]);

  useEffect(() => {
    // Get TimeZone from browser e.g. "Europe/Berlin"
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Set weekStart date
    let periodStart = DateTime.fromISO(date).startOf(
      period === "workweek" ? "week" : period
    );
    if (period === "month") {
      periodStart = periodStart.startOf("week");
    }
    // Set weekEnd date
    let periodEnd = DateTime.fromISO(date).endOf(
      period === "workweek" ? "week" : period
    );
    if (period === "month") {
      periodEnd = periodEnd.endOf("week");
    }
    // DST at start of week?
    const dstPeriodStart = periodStart.endOf("day").setZone(tz).isInDST;
    // DST at end of week?
    const dstPeriodEnd = periodEnd.endOf("day").setZone(tz).isInDST;
    // Add 1 hour to weekEnd-date if DST changes at end of week (summer -> winter)
    if (dstPeriodStart && !dstPeriodEnd) {
      periodEnd = periodEnd.minus({ hour: 1 });
    }

    // Convert period to unit for backend
    // DateTime lib uses "week" for weeks, backend uses "day" for weeks
    // We take frontend prop {period} and convert it to backend unit
    const unitConvert = (unit: Periods) => {
      switch (unit) {
        case "week":
          return "day";
        case "month":
          return "week";
        case "year":
          return "month";
        default:
          return "day";
      }
    };

    dispatch(
      getDashboardSummary({
        from: periodStart.toISO(),
        to: periodEnd.plus({ milliseconds: 1 }).toISO(),
        unit: unitConvert(period),
      })
    );
  }, [date, workspace, resource, period]);

  useEffect(() => {
    return () => {
      dispatch(setClearPeriodTimes());
      dispatch(setClearPeriodStatistics());
    };
  }, []);

  useEffect(() => {
    if (tablet) {
      if (isIpad) {
        setPlotHeight(325);
      } else if (isIpadPro) {
        setPlotHeight(350);
      }
    } else {
      setPlotHeight(400);
    }
  }, [isIpad, isIpadPro]);

  // Handler
  const autoResize = () => {
    setFade(true);
    setValue({
      width: window.innerWidth,
      open: theme.palette.propsDyce.drawerLeft.isOpen,
    });
  };

  const addWeek = () => {
    setIsLoading(true);
    const newDate = DateTime.fromISO(date)
      .plus({ [period]: 1 })
      .toISODate();
    setDate(newDate);
    handleSaveDashboardSelection({ date: newDate, period });
  };

  const subWeek = () => {
    setIsLoading(true);
    const newDate = DateTime.fromISO(date)
      .minus({ [period]: 1 })
      .toISODate();
    setDate(newDate);
    handleSaveDashboardSelection({ date: newDate, period });
  };

  const today = () => {
    const newDate = DateTime.now().toISODate();
    setDate(newDate);
    handleSaveDashboardSelection({ date: newDate, period });
  };

  const handleBarClick = (nextState: CategoricalChartState) => {
    if (nextState && nextState.activeLabel) {
      const res = handleBarChartDateFormat({
        date: nextState.activeLabel,
        period,
        options: { getDate: true },
      });

      if (DateTime.fromISO(res).isValid) {
        history.push(`${routes.TNT}/dailyrecordings#${res}`, {
          fromDashboard: true,
        });
      }
    }
  };

  const handleTotalMinutes = (): number[] => {
    let minutes: number[] = [];
    plotData &&
      plotData.statistics.billableTotals.forEach((x) =>
        minutes.push(
          x.nonBillableReason === "None"
            ? x.totalDuration
            : x.totalDurationBillable
        )
      );

    return minutes;
  };

  return (
    <>
      <SubHeader
        tooltipLabel={t("timerec.subHeader.dashboard.info")}
        tooltipUrlPath={docuLinks.timetracking.dashboard.subheader.info}
        title={t("timerec.subHeader.dashboard.title")}
        subtitle={t("timerec.subHeader.dashboard.subtitle", {
          period: handlePeriodText(period),
        })}
        childrenMobile={mobile}
        textMobile={mobile}
        isLoading={isLoading}
      >
        <div className={classes.pagination}>
          <DatePagination
            forwardHandler={addWeek}
            backwardHandler={subWeek}
            todayHandler={today}
            date={date}
            localeHyphen={localeHyphen}
            withCalendar={{
              onSelect: (value) => value && setDate(value.toISODate()),
            }}
            periodOptions={{
              periods: ["week", "month", "year"],
              initialPeriod: period,
            }}
            periodSelection
            onChangePeriod={(value) => {
              if (value !== period) {
                handleSaveDashboardSelection({
                  date: date,
                  period: value,
                });
                setIsLoading(true);
                setPeriod(value);
              }
            }}
          />
        </div>
        <div className={classes.summary}>
          <PeriodSummary
            header={t("timerec.dashboard.timeWorked.total", {
              period: handlePeriodText(period),
            })}
            totalDuration={{
              period: period,
              date: date,
              capacity: userCapacity,
            }}
            totalMinutesWorked={handleTotalMinutes()}
          />
        </div>
      </SubHeader>
      <Plots
        plotsData={plotData}
        plotHeight={plotHeight}
        localeHyphen={localeHyphen}
        doRender={doRender}
        fade={fade}
        language={language}
        onBarClick={handleBarClick}
        newDateFormat={newDateFormat}
        showDaysWithoutCapacity={showDaysWithoutCapacity}
        period={period}
        responsive={{ isIpad, isIpadPro, tablet }}
      />
    </>
  );
};
