import React, { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Helper
import { useHotkeys } from "react-hotkeys-hook";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { AllDone, LoadingSpinner, SubHeader, useStaticContent } from "@dyce/ui";
import { useUpdateEffect } from "@dyce/hooks";
import {
  useAppDispatch,
  useAppSelector,
  selectIncompleteEntries,
  incompleteCount,
  selectCurrentWorkspace,
  selectHasResource,
  removeIncompleteEntries,
  getIncompleteRecs,
  setAllowNetworkWatcher,
  RootState,
} from "@dyce/slices";
import { getItemsArrayInfos } from "@dyce/utils";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
// Components
import { IncompleteRecordList } from "../../../components/timerecording";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    recordsContainer: { ...theme.palette.propsTimeTracking.recordsContainer },
    listContainer: {
      flexDirection: "column",
      display: "flex",
      justifyContent: "stretch",
    },
  })
);

export const IncompleteRecord: FunctionComponent = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const {
    platform,
    docuLinks,
    isMobile: { mobile },
  } = useStaticContent();

  // Selectors
  const dayRecords = useAppSelector(selectIncompleteEntries);
  const count = useAppSelector(incompleteCount);
  const isLoading = useAppSelector(
    (state: RootState): unknown[] => state.timerec.requests
  );
  const { instance, company } = useAppSelector(selectCurrentWorkspace);
  const resource = useAppSelector(selectHasResource);

  // States
  const [page, setPage] = useState<{ top: number; skip: number }>({
    top: 50,
    skip: 0,
  });
  const [itemsArrayInfos, setItemsArrayInfos] = useState<[string, number][]>(
    []
  );
  const [initLoading, setInitLoading] = useState<boolean>(true);

  // UseEffects
  useEffect(() => {
    callApi();
    return () => {
      dispatch(removeIncompleteEntries({ removeAll: false }));
    };
  }, [instance, company, resource]);

  useEffect(() => {
    setItemsArrayInfos(getItemsArrayInfos(dayRecords));
  }, [dayRecords]);

  useUpdateEffect(() => {
    if (isLoading.length === 0) {
      setInitLoading(false);
    }
  }, [isLoading]);

  // UseHotKeys
  useHotkeys(
    // prevent refresh page && callApi again
    platform.modifiers.refreshPage,
    (e) => {
      if (!initLoading) {
        e.preventDefault();
        setInitLoading(true);
        dispatch(removeIncompleteEntries({ removeAll: true }));
        // API Call
        callApi();
      }
    },
    [initLoading]
  );

  const callApi = () => {
    dispatch(getIncompleteRecs(page));
  };

  const loadMore = () => {
    let newPage = page;
    setPage((page) => {
      newPage = { top: page.top, skip: page.skip + 50 };
      return newPage;
    });

    dispatch(getIncompleteRecs(newPage));
  };

  return (
    <>
      <SubHeader
        tooltipLabel={t("timerec.subHeader.incompleteTimes.info")}
        tooltipUrlPath={docuLinks.timetracking.incompleteTimes.subheader.info}
        title={t("timerec.subHeader.incompleteTimes.title")}
        subtitle={t("timerec.subHeader.incompleteTimes.subtitle", {
          count: count,
        })}
        isLoading={isLoading.length > 0}
        textMobile={mobile}
      />
      {initLoading ? (
        <LoadingSpinner
          allowNetworkWatcher={(value) =>
            dispatch(setAllowNetworkWatcher(value))
          }
        />
      ) : (
        <>
          {count === 0 && <AllDone label={t("timerec.incompleteTimes.done")} />}
          <IncompleteRecordList
            styles={{ ...classes, recordsContainer: classes.recordsContainer }}
            itemsArrayInfos={itemsArrayInfos}
            dayRecords={dayRecords}
            pagination={{
              count: count,
              loadCount: page.top + page.skip,
              loadMore: loadMore,
            }}
          />
        </>
      )}
    </>
  );
};
