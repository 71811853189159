import { FunctionComponent, useEffect, useMemo, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { LookUpMapping, LookUpOptions } from "@dyce/interfaces";
import { handleSelectionFromDialog } from "@dyce/utils";
import { useUpdateEffect } from "@dyce/hooks";
import { JiraCompany } from "@dyce/foreign-api";
// MUI
import { Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
// Jira
import { Grid } from "@atlaskit/primitives";
import { token } from "@atlaskit/tokens";
import { colors } from "@atlaskit/theme";
import Button from "@atlaskit/button";
import SelectClearIcon from "@atlaskit/icon/glyph/select-clear";
// Components
import { EditableTitle } from "../title/editable-title";
import { GridBox } from "../grid/grid-box";
import { LoadingSpinner } from "../loading-spinner/loading-spinner";
import { Tooltip } from "../tooltip/tooltip";

declare const AP: any;

const useStyle = makeStyles(() =>
  createStyles({
    boxStyles: {
      display: "flex",
      borderStyle: "solid",
      borderRadius: token("border.radius", "3px"),
      borderWidth: token("border.width", "1px"),
      width: "100%",
    },
    mappingButton: {
      cursor: "pointer",
      height: "100%",
      width: "100%",
      backgroundColor: token(
        "color.background.accent.gray.subtle.pressed",
        colors.N40
      ),
      borderRadius: token("border.radius", "3px"),
      padding: token("space.050", "0.25rem"),
      display: "flex",
      alignItems: "center",
      transition: "background-color 0.2s ease-in-out",
      "&:hover": {
        backgroundColor: token(
          "color.background.accent.gray.subtle.hovered",
          colors.N60
        ),
      },
    },
  })
);

interface ICompanyMappingProps {
  company: JiraCompany;
  customStyle?: {
    backgroundColor: string | undefined;
    borderColor: string | undefined;
  };
  mapping?: LookUpMapping;
  onCompanyChange: (value: {
    jiraCompany: JiraCompany;
    mapping?: LookUpMapping;
  }) => Promise<void>;
}

export const CompanyMapping: FunctionComponent<ICompanyMappingProps> = ({
  company,
  mapping,
  customStyle,
  onCompanyChange,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  // States
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [customValue, setCustomValue] = useState<string | undefined>(
    company.customName
  );
  const [mappingDescriptions, setMappingDescriptions] = useState<
    {
      field: LookUpOptions;
      description: string;
      isDisabled?: boolean;
    }[]
  >([
    {
      field: LookUpOptions.CUSTOMER,
      description: "",
    },
    {
      field: LookUpOptions.JOBTASK,
      description: "",
    },
    {
      field: LookUpOptions.JOB,
      description: "",
    },
    {
      field: LookUpOptions.JOBPLANNINGLINE,
      description: "",
      isDisabled: true,
    },
  ]);
  const [internalMapping, setInternalMapping] = useState<LookUpMapping | null>(
    mapping ? { ...mapping } : null
  );

  // Memos
  const isDisabled = useMemo(() => {
    return mapping ? mapping.customer === null : true;
  }, [mapping]);

  // UseEffects
  useEffect(() => {
    if (mapping) {
      setMappingDescriptions([
        {
          field: LookUpOptions.CUSTOMER,
          description: mapping.customer?.name ?? "",
        },
        {
          field: LookUpOptions.JOBTASK,
          description: mapping.jobTask?.description ?? "",
        },
        {
          field: LookUpOptions.JOB,
          description: mapping.job?.description ?? "",
        },
        {
          field: LookUpOptions.JOBPLANNINGLINE,
          description: mapping.jobPlanningLine?.description ?? "",
          isDisabled: mapping.job === null,
        },
      ]);
    }
  }, [mapping]);

  useUpdateEffect(() => {
    setIsSaving(true);
    const newValue = {
      mapping: internalMapping ? { ...internalMapping } : undefined,
      jiraCompany: {
        displayName: company.displayName,
        emailDomain: company.emailDomain,
        customName: customValue,
        isInternal: company.isInternal,
      },
    };
    onCompanyChange(newValue)
      .then(() => setIsSaving(false))
      .catch(() => setIsSaving(false));
  }, [customValue, internalMapping]);

  // Handler
  const handleEditMapping = (field: LookUpOptions) => {
    AP.dialog
      .create({
        key: "dyce-issue-context-panel-dialog",
        header: t("jira.projectSettings.customerMapping.dialog.header"),
        submitText: t("jira.dialog.footer.button.submit"),
        cancelText: t("jira.dialog.footer.button.cancel"),
        customData: {
          mapping: {
            ...internalMapping,
          },
          focusField: field,
        },
      })
      .on("close", (data: any) => {
        if (data) {
          const { selected }: { selected: LookUpMapping | null } = data;
          if (selected) {
            const { newMapping } = handleSelectionFromDialog({
              selected:
                Object.entries(selected).length === 0 ? undefined : selected,
            });

            setInternalMapping(newMapping);
          }
        }
      });
  };

  return (
    <div
      className={classes.boxStyles}
      style={
        customStyle
          ? {
              display: "flex",
              justifyContent: "space-between",
              padding: token("space.050", "0.25rem"),
              backgroundColor: customStyle.backgroundColor,
              borderColor: customStyle.borderColor,
            }
          : {
              display: "flex",
              justifyContent: "space-between",
              padding: token("space.050", "0.25rem"),
              backgroundColor: token(
                "color.background.input.hovered",
                colors.N10
              ),
              borderColor: token("color.border", colors.N50),
            }
      }
    >
      {isSaving ? (
        <div
          style={{
            height: "70px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner
            text={t("jira.projectSettings.customerMapping.saving")}
          />
        </div>
      ) : (
        <>
          <div
            style={{
              width: "45%",
            }}
          >
            <EditableTitle
              customValue={company.customName}
              defaultValue={company.displayName}
              undoButton
              onChange={(value) => setCustomValue(value)}
            />
            <Typography
              variant="caption"
              sx={{
                paddingLeft: "8px",
              }}
              color="text.secondary"
            >
              {company.emailDomain}
            </Typography>
          </div>
          <div
            style={{
              width: "50%",
            }}
          >
            <Grid
              testId="grid-customer-assignment"
              gap="space.075"
              templateAreas={[
                "mappingField1 mappingField2 button",
                "mappingField3 mappingField4 button",
              ]}
              templateColumns="1fr 1fr 32px"
              autoFlow="column"
            >
              {mappingDescriptions.map((mappingDescription, index) => (
                <GridBox
                  key={index}
                  padding="space.0"
                  style={{
                    gridArea: `mappingField${index + 1}`,
                  }}
                >
                  <div
                    className={classes.mappingButton}
                    style={
                      mappingDescription.isDisabled
                        ? {
                            backgroundColor: token(
                              "color.background.accent.gray.subtlest",
                              colors.N20
                            ),
                            cursor: "not-allowed",
                          }
                        : {}
                    }
                    onClick={() =>
                      !mappingDescription.isDisabled &&
                      handleEditMapping(mappingDescription.field)
                    }
                  >
                    <Typography variant="caption" noWrap>
                      {mappingDescription.description}
                    </Typography>
                  </div>
                </GridBox>
              ))}
              <GridBox
                padding="space.0"
                style={{
                  gridArea: "button",
                }}
              >
                <Tooltip
                  content={t(
                    "jira.projectSettings.customerMapping.button.clear.tooltip",
                    {
                      company: company.customName
                        ? company.customName
                        : company.displayName,
                    }
                  )}
                  position="right"
                >
                  {(tooltipProps) => (
                    <Button
                      isDisabled={isDisabled}
                      {...tooltipProps}
                      onClick={() => {
                        const { newMapping } = handleSelectionFromDialog({
                          selected: undefined,
                        });
                        setInternalMapping(newMapping);
                      }}
                      iconBefore={
                        <SelectClearIcon
                          primaryColor={
                            isDisabled
                              ? token(
                                  "color.background.accent.orange.subtle.pressed",
                                  colors.Y200
                                )
                              : token("color.icon.warning", colors.Y300)
                          }
                          label="Clear icon"
                        />
                      }
                    />
                  )}
                </Tooltip>
              </GridBox>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};
