import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  useMemo,
  RefObject,
} from "react";
// Helper
import { defaultGridSort, EnterHelper, handleNewScrollPosition } from "./utils";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { useDoubleClick, useIntersectionObserver } from "@dyce/hooks";
import {
  ArrowKeys,
  IDatagridColumn,
  IDatagridRow,
  InfScrollProps,
} from "@dyce/ui";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
// React-Data-Grid
import ReactDataGrid, {
  DataGridHandle,
  Row,
  RowRendererProps,
  Column,
  EditorProps,
  SortColumn,
  SortDirection,
} from "react-data-grid";

const useStyles = makeStyles(() =>
  createStyles({
    emptyRows: {
      textAlign: "center",
    },
    selectedRowParent: {
      "& .rdg-cell": {
        boxShadow: "none",
      },
    },
    selectedRow: {
      backgroundColor: "#1a73bc",
      transition: "background-color 0.1s ease-in-out",
      "&:hover": {
        backgroundColor: "#165a92",
      },
      color: "white",
    },
    loadingAnimation: {
      "& .rdg-cell": {
        "&::before": {
          content: "''",
          display: "block",
          position: "absolute",
          left: "0px",
          top: 0,
          marginTop: "4px",
          height: "75%",
          width: "150px",
          background:
            "linear-gradient(to right, transparent 0%, #E8E8E8 50%, transparent 100%)",
          animation: "$load 1s cubic-bezier(0.5, 0.0, 0.6, 1) infinite",
        },
      },
    },
    "@keyframes load": {
      from: {
        left: "-150px",
      },
      to: {
        left: "100%",
      },
    },
  })
);

interface IDatagridProps<T = any> {
  /**
   * Height of a row
   * @default 35
   */
  rowHeight?: number;
  /**
   * Width of a row
   * @default 900
   */
  rowWidth?: number;
  /**
   * Height of the grid
   * @default 350
   */
  height?: number;
  /**
   * Column names and data
   */
  columns: (IDatagridColumn & any)[];
  /**
   * Row data
   */
  rows: IDatagridRow<T>[];
  /**
   * Selected row (will be highlighted if markRow is true)
   */
  selectedId: string | null;
  /**
   * Column name to sort on
   */
  sortColumn: string;
  /**
   * Sort direction
   */
  sortDirection: SortDirection;
  /**
   * Highlight first row to show auto-fill target
   */
  markFirstRow?: boolean;
  /**
   * Wether to add a marking to the selected row
   * @default false
   */
  markRow?: boolean;
  /**
   * Ref element for the datagrid
   */
  gridRef?: RefObject<DataGridHandle>;
  /**
   * Ref element from LookUp
   */
  lookUpRef?: React.MutableRefObject<HTMLInputElement | null>;
  /**
   * If focus should be given to the grid
   */
  isSelected: boolean;
  /**
   * Switch if Dialog with datagrid is open
   * @default false
   */
  dialogOpen?: boolean;
  /**
   * Provide a custom grid sorting function
   */
  onGridSort?: (
    initialRows: IDatagridRow[],
    key: string,
    direction: SortDirection
  ) => (rows: IDatagridRow[]) => IDatagridRow[];
  /**
   * Callback when an entry is selected
   */
  onRowSelected: (selectedId: string) => void;
  /**
   * Callback on cancel or escape
   */
  onCancel: () => void;
  /**
   * Provide onSubmit function from Dialog:
   * Callback function to close Dialog;
   */
  handleConfirmDialog?: (id: string) => void;
  /**
   * Possibility to provide custom row renderer
   */
  rowRenderer?: (props: RowRendererProps<any, any>) => JSX.Element;
  /**
   * Callback for infinite scrolling, takes direction and promises count for
   * skeleton rows if successful
   */
  infiniteScroll?: (props: { direction: "UP" | "DOWN" }) => void;
  /**
   * Summary for infinite scrolling provided props
   */
  infScrollProps: InfScrollProps;
  /**
   * Callback for individual rowFactory, e.g. filtered array
   */
  rowFactoryFilter?: (ids: string[], values: any[]) => IDatagridRow<any>[];
  /**
   * Callback to block closing from Editor e.g. when API call is pending
   */
  blockClosingCallback: (value: boolean) => void;
  /**
   * Callback fired when no rows are rendered
   * @returns JSX.Element
   */
  onEmptyRowsRenderer?: () => JSX.Element;
  /**
   * If true, Datagrid is disabled (not selectable)
   */
  disable: boolean;
  /**
   * If true, design is in darkMode style
   */
  darkMode: boolean;
  /**
   * If true, TextField has a value
   * @default false
   */
  textFieldHasValue?: boolean;
}

/**
 * Datagrid
 * @component
 */
export const Datagrid: React.FC<IDatagridProps> = ({
  rowHeight = 35,
  height,
  rowWidth = 900,
  columns,
  rows,
  selectedId,
  sortColumn,
  sortDirection,
  isSelected,
  onGridSort,
  onCancel,
  handleConfirmDialog,
  onRowSelected: onIDSelected,
  markFirstRow,
  markRow = false,
  gridRef: ref,
  lookUpRef,
  dialogOpen = false,
  rowRenderer,
  infiniteScroll,
  infScrollProps,
  rowFactoryFilter,
  blockClosingCallback,
  onEmptyRowsRenderer,
  disable,
  darkMode,
  textFieldHasValue = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const minHeight = 350;
  const {
    infScrollCanTriggerAt = 9,
    triggerAtBottom = 0,
    defaultColumnKey = "id",
    loadMoreCount = 0,
    controller,
    canLoadMoreUp = false,
    canLoadMoreDown = false,
    openWithSkeletons = false,
  } = infScrollProps;

  const internalColumns = () => {
    const cols: Column<IDatagridRow<any>>[] = columns.map((column) => ({
      key: column.key,
      name: column.name,
      width: column.width ? column.width * rowWidth : undefined,
      editable: column.editable,
      sortable: column.sortable,
      formatter: column.formatter
        ? (x) => {
            return column.formatter(x);
          }
        : undefined,
      editor: (x: EditorProps<any, unknown>) => {
        return (
          <EnterHelper
            value={x.row[column.key]}
            id={x.row["id"]}
            onEnter={(id) => {
              if (
                loadMore ||
                internalRows[0].id === "0" ||
                internalRows[internalRows.length - 1].id.length <= 4
              ) {
                blockClosingCallback(true);
                setSelectedRowId(id);
                return;
              } else {
                handleSelectionAndClose(id, dialogOpen);
              }
            }}
          />
        );
      },
    }));

    return cols;
  };

  const rowFactory = (rows: IDatagridRow<any>[]) => {
    let iRows: IDatagridRow<any>[] = [];
    if (rows.length > 0) {
      const ids = rows.map((x) => x.id);
      const values = rows.map((x) => x.cells);

      if (rowFactoryFilter) {
        iRows = [...rowFactoryFilter(ids, values)];
      } else {
        for (let i = 0; i < rows.length; i++) {
          iRows.push({
            id: ids[i],
            ...values[i],
          });
        }
      }
    }

    return iRows;
  };

  // State
  const [internalRows, setInternalRows] = useState<any[]>(rowFactory(rows));
  const [[scrollDirection, lastScrollPosition], setScrollMonitoring] = useState<
    ["up" | "down" | "none", number]
  >(["none", 0]);
  const [infScrolling, setInfScrolling] = useState<boolean>(false);
  const [[expectedRowCount, skellCount], setExpectedRowCount] = useState<
    [number, number]
  >([0, 0]);
  const [bottomIndex, setBottomIndex] = useState<number>(0);
  const [keyPressed, setKeyPressed] = useState<ArrowKeys>({
    arrowUp: false,
    arrowDown: false,
    hotkeyWasPressed: false,
  });
  const [loadMore, setLoadMore] = useState<boolean>(false);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [selectAgain, setSelectAgain] = useState<number>(-1);
  const [disableScrollToRow, setDisableScrollToRow] = useState<boolean>(false);
  const [canObserve, setCanObserve] = useState<boolean>(true);
  const [lastScrollTime, setLastScrollTime] = useState<number>(Date.now());

  // Refs
  const gridRef = ref ? ref : useRef<DataGridHandle>(null);
  const upperRef = createRef<HTMLDivElement>();
  const lowerRef = createRef<HTMLDivElement>();

  const handleDoubleClick = (id: string) => {
    handleOnClick(id, true);
  };
  const click = useDoubleClick(handleDoubleClick);

  // Hooks
  const entryUpper = useIntersectionObserver(upperRef, {
    doObservation: canObserve,
  });
  const entryLower = useIntersectionObserver(lowerRef, {
    doObservation: canObserve,
  });

  //Theme
  const darkTheme: string = darkMode ? "rdg-dark" : "rdg-light";

  useEffect(() => {
    const rowIdx = internalRows.findIndex((row) => row.id === selectedId);
    if (rowIdx >= 0 && !infScrolling) {
      gridRef.current && gridRef.current.selectCell({ idx: 0, rowIdx });
      !disableScrollToRow &&
        gridRef.current &&
        gridRef.current.scrollToRow(rowIdx);
    } else if (isSelected && !infScrolling) {
      const gridHasRows = internalRows.length > 1;
      gridRef.current &&
        gridRef.current.selectCell({
          idx: 0,
          rowIdx: gridHasRows && textFieldHasValue ? 1 : 0,
        });
      !dialogOpen && gridRef.current && gridRef.current.scrollToRow(rowIdx);
    } else if (selectedId && selectedId.length > 0 && rowIdx === -1) {
      // Scroll to top if selected row is not in list (filter switch by user)
      gridRef.current && gridRef.current.scrollToRow(0);
    } else if (openWithSkeletons) {
      gridRef.current && gridRef.current.scrollToRow(infScrollCanTriggerAt);
    }
    // If select per click activate arrow and enter key(s)
    if (dialogOpen && isSelected && rowIdx >= 0 && selectAgain < 0) {
      setTimeout(() => {
        setSelectAgain(rowIdx);
      }, 1);
    }
  }, [internalRows, selectedId, isSelected]);

  useEffect(() => {
    if (selectAgain >= 0) {
      gridRef.current &&
        gridRef.current.selectCell({ idx: 0, rowIdx: selectAgain });
    }
  }, [selectAgain]);

  useEffect(() => {
    setInternalRows(rowFactory(rows));
  }, [rows]);

  useEffect(() => {
    return () => {
      setInfScrolling(false);
      setSelectedRowId(null);
    };
  }, []);

  useEffect(() => {
    // Stop Observe after 3 seconds
    let observeTimer: NodeJS.Timeout;
    const observeInterval = Date.now() - 3000;
    if (canObserve && lastScrollTime > observeInterval) {
      observeTimer = setTimeout(() => {
        setCanObserve(false);
      }, 3000);
    }
    return () => clearTimeout(observeTimer);
  }, [canObserve, lastScrollTime]);

  useEffect(() => {
    if (infiniteScroll) {
      if (internalRows.length > 0 && infScrolling && skellCount) {
        // Correct scroll position UP when skeletons are not filled 100% with new entries
        if (!internalRows[0].skells && !keyPressed.arrowDown) {
          if (expectedRowCount !== internalRows.length) {
            // No new entries
            if (expectedRowCount - internalRows.length === skellCount) {
              handleNewScrollPosition(
                0,
                scrollDirection,
                keyPressed,
                rowHeight,
                gridRef
              );
            } else {
              // New entries but less then skeletons
              const topHeight =
                rowHeight *
                (internalRows.length - expectedRowCount + skellCount);
              handleNewScrollPosition(
                topHeight,
                scrollDirection,
                keyPressed,
                rowHeight,
                gridRef
              );
            }
            setExpectedRowCount([0, 0]);
          }
        } else if (
          scrollDirection === "down" &&
          !internalRows[internalRows.length - 1].skells &&
          keyPressed.arrowDown
        ) {
          // Correct scroll position down (keyboard) when reached end
          // and entries less then skeletons
          if (expectedRowCount !== internalRows.length) {
            handleNewScrollPosition(
              (expectedRowCount - skellCount - 1) * rowHeight,
              scrollDirection,
              keyPressed,
              rowHeight,
              gridRef
            );
            setExpectedRowCount([0, 0]);
          }
        }
      }
    }
  }, [expectedRowCount, internalRows]);

  useEffect(() => {
    if (infiniteScroll) {
      if (internalRows.length >= infScrollCanTriggerAt) {
        if (
          entryUpper &&
          entryUpper.isIntersecting &&
          scrollDirection === "up" &&
          !loadMore &&
          canLoadMoreUp &&
          internalRows[0].id !== "0" &&
          lastScrollPosition <= rowHeight * 2
        ) {
          infiniteScroll({
            direction: "UP",
          });
          setLoadMore(true);
          handleSkeletonRows();

          setInfScrolling(true);
          setExpectedRowCount([
            internalRows.length + loadMoreCount,
            loadMoreCount,
          ]);
        } else if (
          entryLower &&
          entryLower.isIntersecting &&
          scrollDirection === "down" &&
          !loadMore &&
          canLoadMoreDown &&
          internalRows[internalRows.length - 1].id.length > 4
        ) {
          setInfScrolling(true);
          skellCount > 0 &&
            !keyPressed.arrowDown &&
            setExpectedRowCount([0, 0]);
          // Be sure to only trigger when at bottom
          if (bottomIndex > internalRows.length - 2 - triggerAtBottom) {
            infiniteScroll({ direction: "DOWN" });
            setLoadMore(true);
            handleSkeletonRows();
            if (keyPressed.arrowDown) {
              setExpectedRowCount([
                internalRows.length + loadMoreCount,
                loadMoreCount,
              ]);
            }
          }
        }
      }
    }
  }, [scrollDirection, entryUpper, entryLower, lastScrollPosition]);

  useEffect(() => {
    if (loadMore) {
      setLoadMore(false);
    }
    if (loadMore && scrollDirection === "up") {
      handleNewScrollPosition(
        rowHeight * loadMoreCount + lastScrollPosition,
        scrollDirection,
        keyPressed,
        rowHeight,
        gridRef
      );
    }
    if (selectedRowId) {
      if (
        !loadMore &&
        internalRows[0].id !== "0" &&
        internalRows[internalRows.length - 1].id.length > 4
      ) {
        handleOnClick(selectedRowId, true);
      }
    }
  }, [loadMore, internalRows]);

  // Hotkeys
  useHotkeys("escape", () => {
    lookUpRef && lookUpRef.current && lookUpRef.current.focus();
    onCancel();
  });

  useHotkeys("down, pageDown", () => {
    setKeyPressed({
      arrowUp: false,
      arrowDown: true,
      hotkeyWasPressed: true,
    });
  });

  useHotkeys("up, pageUp", () => {
    setKeyPressed({
      arrowUp: true,
      arrowDown: false,
      hotkeyWasPressed: true,
    });
  });

  useHotkeys("shift+tab", () => {
    onCancel();
    setTimeout(() => {
      lookUpRef && lookUpRef.current && lookUpRef.current.focus();
    }, 10);
  });

  // Empty Rows?
  const emptyRowsRenderer = () => {
    if (onEmptyRowsRenderer) {
      return onEmptyRowsRenderer();
    } else {
      return (
        <div className={classes.emptyRows}>
          {t("components.lookup.noEntries")}
        </div>
      );
    }
  };

  const handleSkeletonRows = () => {
    const skeletonRows = controller.createSkeletonRows(loadMoreCount);
    if (scrollDirection === "up") {
      handleNewScrollPosition(
        rowHeight * loadMoreCount + lastScrollPosition,
        scrollDirection,
        keyPressed,
        rowHeight,
        gridRef
      );
      setInternalRows([...skeletonRows, ...internalRows]);
    } else {
      setInternalRows([...internalRows, ...skeletonRows]);
    }
  };

  const handleSort = (sortColumns: SortColumn[]) => {
    if (infiniteScroll) {
      setExpectedRowCount([0, 0]);
      setInfScrolling(false);
    }
    if (sortColumns.length > 0) {
      const { columnKey, direction } = sortColumns[0];
      onGridSort
        ? setInternalRows(onGridSort(rowFactory(rows), columnKey, direction))
        : setInternalRows(
            defaultGridSort(rowFactory(rows), columnKey, direction)
          );
    } else {
      onGridSort
        ? setInternalRows(onGridSort(rowFactory(rows), defaultColumnKey, "ASC"))
        : setInternalRows(defaultGridSort(rowFactory(rows), "id", "ASC"));
    }
  };

  const handleOnClick = (id: string, forceClosing = false) => {
    if (
      loadMore ||
      internalRows[0].id === "0" ||
      internalRows[internalRows.length - 1].id.length <= 4
    ) {
      if (dialogOpen && !forceClosing) {
        onIDSelected(id);
        click(id);
      } else if (!dialogOpen || (dialogOpen && forceClosing)) {
        blockClosingCallback(true);
        setSelectedRowId(id);
      }
      return;
    } else {
      handleSelectionAndClose(id, forceClosing);
      dialogOpen && click(id);
      setDisableScrollToRow(true);
    }
  };

  const handleSelectionAndClose = (id: string, forceClosing = false) => {
    setInfScrolling(false);
    if (!dialogOpen) {
      onIDSelected(id);
      onCancel();
      return;
    }
    if (forceClosing && handleConfirmDialog) {
      setTimeout(() => {
        lookUpRef && lookUpRef.current && lookUpRef.current.focus();
        handleConfirmDialog(id);
      }, 5);
    }
  };

  const handleRef = (index: number): React.RefObject<HTMLDivElement> | null => {
    if (infiniteScroll && internalRows.length >= infScrollCanTriggerAt) {
      if (index === 0) {
        return upperRef;
      } else if (index === internalRows.length - 1 - triggerAtBottom) {
        index !== bottomIndex && setBottomIndex(index);
        return lowerRef;
      } else return null;
    } else return null;
  };

  // RowRenderer for Keyboard and marking events
  const internalRowRenderer = (props: RowRendererProps<any, any>) => {
    // Skeletons
    if (props.row.cells ? props.row.cells.skells : props.row.skells) {
      if (
        props.selectedCellProps &&
        props.selectedCellProps.mode === "SELECT" &&
        !openWithSkeletons
      ) {
        <div className={classes.selectedRowParent}>
          <div className={classes.loadingAnimation}>
            <Row
              {...props}
              selectedCellProps={undefined}
              className={classes.selectedRow}
              ref={handleRef(-1)}
            />
          </div>
        </div>;
      } else {
        return (
          <div className={classes.loadingAnimation}>
            <Row {...props} selectedCellProps={undefined} ref={handleRef(-1)} />
          </div>
        );
      }
    }

    // If infinite scrolling, prevent DataGrid throws "SELECT" on Cells
    if (
      props.selectedCellProps &&
      props.selectedCellProps.mode === "SELECT" &&
      infScrolling &&
      selectedId &&
      props.row.id !== selectedId &&
      !keyPressed.arrowDown &&
      !keyPressed.arrowUp
    ) {
      return (
        <Row
          {...props}
          selectedCellProps={undefined}
          ref={handleRef(props.rowIdx)}
        />
      );
    }

    // Keyboard behavior here
    if (
      props.selectedCellProps &&
      props.selectedCellProps.idx >= 0 &&
      props.rowIdx >= 0 &&
      !markFirstRow &&
      (markRow || dialogOpen)
    ) {
      return (
        <div className={classes.selectedRowParent}>
          <Row
            {...props}
            onMouseDown={() => handleOnClick(props.row.id)}
            className={classes.selectedRow}
            ref={handleRef(props.rowIdx)}
          />
        </div>
      );
    } else if (markRow && !selectedId && !infScrolling) {
      return (
        <Row
          {...props}
          onMouseDown={() => handleOnClick(props.row.id)}
          ref={handleRef(props.rowIdx)}
          selectedCellProps={undefined}
        />
      );
    } else if (
      (props.rowIdx === 0 && markFirstRow && !markRow) ||
      (props.rowIdx === 0 && selectedId === "Error")
    ) {
      // Mark first row => will be used for automatic fill out
      return (
        <div className={classes.selectedRowParent}>
          <Row
            {...props}
            onMouseDown={() => handleOnClick(props.row.id)}
            className={classes.selectedRow}
            selectedCellProps={undefined}
          />
        </div>
      );
    }

    // Mark selected row per ID
    // un-mark on ArrowDown
    if (
      selectedId &&
      props.row.id === selectedId &&
      infScrolling &&
      !keyPressed.hotkeyWasPressed
    ) {
      return (
        <div className={classes.selectedRowParent}>
          <Row
            {...props}
            className={classes.selectedRow}
            onMouseDown={() => handleOnClick(props.row.id)}
            ref={handleRef(props.rowIdx)}
          />
        </div>
      );
    } else {
      return (
        <Row
          {...props}
          onMouseDown={() => handleOnClick(props.row.id)}
          ref={handleRef(props.rowIdx)}
          selectedCellProps={undefined}
        />
      );
    }
  };

  // Infinite Scrolling
  const handleScrollDirection = (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    setScrollMonitoring([
      lastScrollPosition <= target.scrollTop ? "down" : "up",
      target.scrollTop,
    ]);
    // Check if user switches from Keyboard to Mousewheel
    if (keyPressed.arrowUp || keyPressed.arrowDown) {
      event.target.addEventListener("mousewheel", () => {
        setKeyPressed({
          arrowUp: false,
          arrowDown: false,
          hotkeyWasPressed: true,
        });
      });
    } else {
      event.target.removeEventListener("mousewheel", null);
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (infiniteScroll) {
      handleScrollDirection(event);
      setCanObserve(true);
      setLastScrollTime(Date.now());
    }
  };

  const handleDisabler = (value: number): number => {
    if (selectedRowId || disable) {
      return value;
    } else return 0;
  };

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          height: handleDisabler(height ? height : minHeight),
          width: handleDisabler(rowWidth),
          backgroundColor: "lightgrey",
          opacity: 0.3,
          pointerEvents: "auto",
          zIndex: 1,
        }}
      />
      <ReactDataGrid
        rowKeyGetter={(key) => key.id}
        ref={gridRef}
        enableVirtualization={internalRows.length < 40 ? false : true}
        rowHeight={rowHeight}
        columns={internalColumns()}
        rows={internalRows}
        rowRenderer={
          rowRenderer
            ? rowRenderer
            : useMemo(() => internalRowRenderer, [upperRef, expectedRowCount])
        }
        defaultColumnOptions={{
          sortable: true,
          resizable: true,
        }}
        onScroll={handleScroll}
        onSortColumnsChange={handleSort}
        sortColumns={[{ columnKey: sortColumn, direction: sortDirection }]}
        emptyRowsRenderer={emptyRowsRenderer}
        className={`${darkTheme}`}
        style={{
          // Datagrid flickers when browser zoom is 90% || 110%. This need adjustment in height
          height:
            Math.round(window.devicePixelRatio * 10) === 9 ||
            Math.round(window.devicePixelRatio * 10) === 11
              ? height
                ? height - 6
                : minHeight - 6
              : height
              ? height
              : "100%",
          width: rowWidth,
          overflowY: "auto",
          overscrollBehavior: "contain",
          overflowAnchor: "none",
          transition: "width 0.5s ease-in-out",
        }}
      />
    </>
  );
};
