import { AnyAction, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionMeta, InstanceStatus, ResponseError } from "@dyce/tnt-api";
import { NetworkStateSlice, RootState } from "../../types/types";

const selectRequestsAsArray = (state: RootState) =>
  Object.values(state.network.requests);

const networkWatcherSlice = createSlice({
  name: "network",
  initialState: {
    requests: {},
    slowNetwork: false,
    status: null,
    allowNetworkWatcher: true,
  } as NetworkStateSlice,
  reducers: {
    setSlowNetwork(state) {
      state.slowNetwork = true;
    },
    resetNetworkState(state) {
      state.slowNetwork = false;
    },
    resetStatus(state) {
      state.status = null;
    },
    setAllowNetworkWatcher(state, action: PayloadAction<boolean>) {
      state.allowNetworkWatcher = action.payload;
    },
  },
  extraReducers: (builder) =>
    builder
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("pending"),
        (state, action) => {
          state.requests[action.meta.requestId] = {
            start: Date.now(),
          };
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("fulfilled") || action.type.endsWith("rejected"),
        (state, action) => {
          delete state.requests[action.meta.requestId];
          if (Object.values(state.requests).length === 0)
            state.slowNetwork = false;
        }
      )
      .addMatcher(
        (
          action: AnyAction
        ): action is PayloadAction<ResponseError, string, ActionMeta> =>
          action.type.endsWith("rejected"),
        (state, action) => {
          // Catch payload.code for DevOps env
          if (action.payload) {
            if (action.payload.code === 426)
              state.status = InstanceStatus.UPGRADING;
            if (action.payload.code === 503)
              state.status = InstanceStatus.UNAVAILABLE;
          }
        }
      ),
});

export const networkSelector = (state: RootState): NetworkStateSlice =>
  state.network;
export { selectRequestsAsArray };
export const { setSlowNetwork, resetNetworkState, setAllowNetworkWatcher } =
  networkWatcherSlice.actions;
export default networkWatcherSlice.reducer;
