import { Instance } from "../types";
import buildQuery, { QueryOptions } from "odata-query";
import { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

export class InstanceService {
  public baseUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = combineURLs(baseUrl, "/Instances");

    this.axiosInstance = instance;
  }

  /**
   * Get all Instances
   * @param query OData query
   * @example
   * const client = new TnTClient(args).getInstanceService()
   * await client.getAll()
   */
  public async getAll(query?: Partial<QueryOptions<Instance>>) {
    const url = `${this.baseUrl}/User${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Instance[]>(url);

    return response.data;
  }

  /**
   * Get one instance by its name
   * @param name The name of the instance, not the ID
   * @param query OData query
   * @example
   * const client = new TnTClient(args).getInstanceService()
   * await client.getOne("CRONUS DE")
   */
  public async getOne(name: string, query?: Partial<QueryOptions<Instance>>) {
    const url = `${this.baseUrl}/name/${name}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Instance>(url);

    return response.data;
  }

  /**
   * Get the default instance of the current user
   * @param query OData query
   * @example
   * const client = new TnTClient(args).getInstanceService()
   * await client.getDefault()
   */
  public async getDefault(query?: Partial<QueryOptions<Instance>>) {
    const url = `${this.baseUrl}/default`;

    const response = await this.axiosInstance.get<Instance>(url);

    return response.data;
  }
}
