import { FunctionComponent, MutableRefObject } from "react";
// Helper
import { Controller, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
// Dyce-Lib
import {
  NonBillableReasonOptions,
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  StatusOptions,
} from "@dyce/tnt-api";
import { AutoComplete } from "../../inputs/autocomplete/autoComplete";
import { getTitle } from "@dyce/utils";
// MUI
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    textFieldMoreOptions: {
      width: "15rem",
      padding: "0 1.5rem",
      "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
        transform: "translate(22px, -6px) scale(0.75)",
      },
    },
  })
);

interface INonBillableReasonFieldProps {
  /**
   * Timerecording object
   */
  timeInput: RecordEntry & Partial<RecordTemplate & RecordTimeRec>;
  /**
   * CSS for common style
   */
  commonTextFieldStyles: string;
  /**
   * If true, components will be disabled;
   */
  pending: boolean;
  /**
   * If true, onChange hook will fired when value changes from validation
   */
  isFromTimeTrackingTool: boolean;
  /**
   * Define tabIndex to focus correct field
   */
  tabIndex?: number;
  /**
   * If true, the input element will be focused during the first mount
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Callback fired onBlur event, to save reference to state
   * @param ref MutableRefObject<HTMLInputElement | null>
   * @returns void
   */
  onInputBlur?: (ref: MutableRefObject<HTMLInputElement | null>) => void;
  /**
   * Unique ID for testing field
   * @default "test-cypress-id"
   */
  testId?: string;
  /**
   * Define size of textfield
   */
  size?: "small" | "medium";
}

export const NonBillableReasonField: FunctionComponent<
  INonBillableReasonFieldProps
> = ({
  timeInput,
  commonTextFieldStyles,
  pending,
  isFromTimeTrackingTool,
  onInputBlur,
  tabIndex,
  testId = "test-cypress-id",
  size,
  autoFocus = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { control } = useFormContext();

  const values = [
    NonBillableReasonOptions.NONE,
    NonBillableReasonOptions.INTERNAL,
    NonBillableReasonOptions.GOODWILL,
    NonBillableReasonOptions.WARRANTY,
  ];

  return (
    <Controller
      control={control}
      name="nonBillableReason"
      render={({ field: { onChange, onBlur, ref } }) => (
        <AutoComplete
          testId={testId}
          refHook={ref}
          onChange={onChange}
          onBlur={(_, ref) => {
            onInputBlur && onInputBlur(ref);
            onBlur;
          }}
          size={size}
          autoFocus={autoFocus}
          tabIndex={tabIndex}
          value={timeInput.nonBillableReason}
          isDisabled={
            (timeInput.status && timeInput.status !== StatusOptions.OPEN) ||
            pending
          }
          values={values}
          getTitle={(option: string) => getTitle(option)}
          style={classNames(
            classes.textFieldMoreOptions,
            commonTextFieldStyles
          )}
          isFromTimeTrackingTool={isFromTimeTrackingTool}
          label={t("timerec.dailyRecording.add.nonbillableReason")}
        />
      )}
      defaultValue={timeInput.nonBillableReason}
    />
  );
};
