// Helper
import { unwrapResult } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import _ from "lodash";
// Dyce-Lib
import {
  AppDispatch,
  setLatestEndTime,
  updateRec,
  createRec,
  removeTemplate,
  removeRec,
  createCategory,
  createTemplate,
  validateRec,
  updateTemplate,
  ResourcePlanning,
} from "@dyce/slices";
import {
  RecordEntry,
  RecordTimeRec,
  StatusOptions,
  TemplateCategory,
  RecordTemplate,
  ValidatedRecord,
} from "@dyce/tnt-api";

/**
 * Function to get info about Items per day, for Alt+PageUp/Down handling
 * @param recs Timerecordings as [string, TimeRecording[]][]
 * @returns Two dimensional array with [Date (yyyy-MM-dd), number of Items per day]
 */
export const getItemsArrayInfos = (
  recs: [string, RecordTimeRec[]][]
): [string, number][] => {
  // Give single array.length to child component
  const arrLengthInfo: [string, number][] = recs.map(([x, y]) => [x, y.length]);
  return arrLengthInfo;
};

/**
 * Function to update || create Timerecordings
 * @param entry TimeInputs object
 * @param dispatch Dispatch hook from redux
 */
export const handleSaveTimerecording = async (
  entry: RecordEntry & Partial<RecordTimeRec>,
  dispatch: AppDispatch
): Promise<{ newEntry: RecordEntry | null; error: boolean }> => {
  if (entry.end) {
    dispatch(setLatestEndTime(entry.end));
  }
  const recordEntry: any = _.omit(
    {
      ...entry,
      id: entry.id ? entry.id : undefined,
    },
    "jobPlanningLine.tntModelLine",
    "jobPlanningLine.jobTask",
    "jobTask.jobPlanningLine",
    "jobTask.job",
    "created",
    "modified",
    "complete"
  );

  if (entry.id) {
    // Put to TimeRecordings
    if (entry.status === StatusOptions.OPEN && entry.date) {
      const result = await dispatch(updateRec(recordEntry as RecordTimeRec));
      const error = result.type.includes("fulfilled") ? false : true;
      if (error) {
        return { newEntry: null, error };
      } else {
        const newEntry: RecordEntry = unwrapResult(result) as RecordEntry;
        return { newEntry, error };
      }
    } else {
      console.info("StatusOptions is not 'open'");
      return { newEntry: null, error: true };
    }
  } else {
    if (entry.date) {
      const result = await dispatch(createRec(recordEntry as RecordTimeRec));
      const error = result.type.includes("fulfilled") ? false : true;
      if (error) {
        return { newEntry: null, error };
      } else {
        const newEntry: RecordEntry = unwrapResult(result);
        return { newEntry, error };
      }
    } else {
      console.warn("Date is null");
      return { newEntry: null, error: true };
    }
  }
};

/**
 * Function to delete record | template from List triggered by user
 * @param id Id from selected record/template
 * @param dispatch Dispatch from Dyce-Lib: AppDispatch
 * @param template If true, removeTemplate will called instead of removeRec
 * @returns Boolean, if true, remove was successful
 */
export const handleDeleteRecord = async (
  id: string,
  dispatch: AppDispatch,
  template?: boolean
): Promise<boolean> => {
  if (template) {
    try {
      const deletedTemplate = await dispatch(removeTemplate(id));
      const unwrapDeletion = unwrapResult(deletedTemplate);

      if (unwrapDeletion === undefined) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  } else {
    try {
      const deletedRecord = await dispatch(removeRec(id));
      const unwrapDeletion = unwrapResult(deletedRecord);

      if (unwrapDeletion === undefined) {
        return true;
      } else {
        return false;
      }
    } catch {
      return false;
    }
  }
};

export const handleCreateCategory = async (
  name: string,
  dispatch: AppDispatch
): Promise<TemplateCategory> => {
  const result = await dispatch(
    createCategory({
      name: name,
    })
  );
  const newCategory: TemplateCategory = unwrapResult(result);

  return newCategory;
};

export const handleCreateTemplate = (
  template: RecordTemplate,
  dispatch: AppDispatch
): void => {
  dispatch(createTemplate(template));
};

export const handleValidateTemplate = async (
  record: RecordTemplate,
  dispatch: AppDispatch
): Promise<ValidatedRecord> => {
  const result = await dispatch(validateRec(record));
  const validated: ValidatedRecord = unwrapResult(result);

  return validated;
};

/**
 * Function to call validate API endpoint
 * @param template Template object {@link RecordTemplate} from Template list
 * @param dispatch Redux Toolkit useDispatch() hook
 * @returns Validated Record object {@link ValidatedRecord}
 */
export const validateTemplateObject = async (
  template: RecordTemplate,
  dispatch: any
): Promise<ValidatedRecord> => {
  const newTemplate = _.omit(
    template,
    "category",
    "jobPlanningLine.description",
    "jobPlanningLine.serviceBillingType",
    "jobPlanningLine.tntModelLine",
    "jobPlanningLine.jobTask",
    "jobTask.jobPlanningLine",
    "jobTask.job"
  );

  const result = await dispatch(validateRec(newTemplate as RecordTemplate));
  const validated: ValidatedRecord = unwrapResult(result);

  return validated;
};

export const mutateRecordForTemplate = (
  copyRecordForTemplate: RecordTemplate,
  category: TemplateCategory | null,
  update = false
): RecordTemplate => {
  // Create new object for Template
  const mutateRecord: any = _.omit(
    copyRecordForTemplate,
    "date",
    "workType",
    "created",
    "modified",
    "status",
    "jobPlanningLine.tntModelLine",
    "jobPlanningLine.jobTask",
    "jobTask.jobPlanningLine",
    "complete",
    "workItem",
    "calendarEventId",
    !update ? "id" : ""
  );
  mutateRecord.category = category;

  return mutateRecord;
};

/**
 * Function to update or create Templates
 * @param entry TimeInputs object
 */
export const handleUpdateTemplate = async (
  entry: RecordEntry & Partial<RecordTemplate>,
  selectedCategory: TemplateCategory | null,
  dispatch: any
): Promise<{ newEntry: RecordTemplate; error: boolean }> => {
  if (entry.id) {
    const templateEntry: any = mutateRecordForTemplate(
      entry as RecordTemplate,
      entry.category ? entry.category : null,
      true
    );

    const result = await dispatch(updateTemplate(templateEntry));
    const newEntry = unwrapResult(result);
    const error = result.type.includes("fulfilled") ? false : true;

    return { newEntry, error };
  } else {
    // Post to Templates
    const templateEntry: any = mutateRecordForTemplate(
      entry as RecordTemplate,
      selectedCategory
    );

    const result = await dispatch(createTemplate(templateEntry));
    const newEntry = unwrapResult(result);
    const error = result.type.includes("fulfilled") ? false : true;

    return { newEntry, error };
  }
};

/**
 * Function to proof URL Hash information as Date => returns Date;
 * also proof is Date before entry Date from user => returns entry Date
 * If Hash is not valid => returns today as Date
 * @param locale locale string (de-DE)
 * @param entryDate Object from redux with user entry date information
 * @returns Date string in "yyyy-MM-dd" format
 */
export const validateLocationHashDate = (
  location: any,
  locale: string,
  entryDate?: Record<string, ResourcePlanning>
): string => {
  // TODO: get this from Selector
  const maximumPastDate = "2000-01-01";
  // Variables
  const today: string = DateTime.now().setLocale(locale).toISODate();
  const hashDate = DateTime.fromISO(location.hash.split("#")[1]);

  let validatedHashDate = "";
  let validEntryDate: string | null = null;

  if (entryDate) {
    const capacityArray = Object.keys(entryDate).sort(
      (a, b) =>
        DateTime.fromFormat(a, "yyyy-MM-dd").toMillis() -
        DateTime.fromFormat(b, "yyyy-MM-dd").toMillis()
    );
    validEntryDate = capacityArray[0];
  }

  if (location.hash.length > 0) {
    if (hashDate.isValid) {
      // Set Date to current location.hash while valid
      validatedHashDate = hashDate.toISODate();
      if (
        validEntryDate &&
        hashDate.diff(DateTime.fromISO(validEntryDate), "days").as("days") <= 0
      ) {
        // Check if selected location.hash Date is before users entryDate
        validatedHashDate = validEntryDate;
      } else if (
        !validEntryDate &&
        hashDate.diff(DateTime.fromISO(maximumPastDate), "days").as("days") <= 0
      ) {
        // No CurrentUser entry Date?
        validatedHashDate = maximumPastDate;
      }
    } else {
      validatedHashDate = today;
    }
  } else {
    validatedHashDate = today;
  }

  return validatedHashDate;
};
