import { FunctionComponent } from "react";
// Helper
import { useHotkeys } from "react-hotkeys-hook";
// Dyce-Lib
import { useStaticContent } from "../static-provider/static-provider";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    refreshButton: {
      width: "60%",
    },
  })
);

interface IRefreshButtonProps {
  /**
   * Text label for button;
   */
  buttonText: string;
  /**
   * If true, button showed up with transition of 300ms;
   * @default true
   */
  showRefreshButton?: boolean;
}

export const RefreshButton: FunctionComponent<IRefreshButtonProps> = ({
  buttonText,
  showRefreshButton = true,
}) => {
  const classes = useStyles();
  const { platform } = useStaticContent();

  // UseHotKeys
  useHotkeys(
    // refresh page
    platform.modifiers.refreshPage,
    () => {
      handleRefresh();
    }
  );

  // Handler
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <Button
        variant="outlined"
        color="error"
        className={classes.refreshButton}
        sx={{
          opacity: showRefreshButton ? 1 : 0,
          transition: "all ease 300ms",
        }}
        onClick={handleRefresh}
      >
        {buttonText}
      </Button>
    </>
  );
};
