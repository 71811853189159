import { AdminSettings, TasksFilterStatus } from "@dyce/tnt-api";

/**
 * Function to check if old key or/and value was found in object and
 * mutate it to new key or/and value
 * @param settings AdminSettings from backend (action.payload)
 * @returns AdminSettings with eventually updated tasks.filter
 */
export const handleOldSettings = (settings: AdminSettings): AdminSettings => {
  if ((settings.tasks as any).filter !== undefined) {
    const newValue =
      (settings.tasks as any).filter === "Never"
        ? TasksFilterStatus.NONE
        : ((settings.tasks as any).filter as TasksFilterStatus);
    const oldToNew = {
      ...settings,
      tasks: {
        assignment: newValue,
      },
    };
    return oldToNew;
  } else {
    return settings;
  }
};
