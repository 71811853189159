import { FunctionComponent } from "react";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { IconButton, List, Paper, Collapse } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const marginTop = 30;

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    root: {
      position: "fixed",
      display: "flex",
      height: "calc(100vh - 56px)",
      left: 0,
      top: 56,
      whiteSpace: "nowrap",
      zIndex: theme.zIndex.speedDial,
    },
    paper: {
      margin: theme.spacing(1),
    },
    paperStyle: {
      stroke: theme.palette.divider,
      strokeWidth: 1,
    },
    toolbarOpen: {
      padding: theme.spacing(0, 0.5),
      marginTop: marginTop,
    },
    toolbarClose: {
      transition: "all ease 300ms",
      padding: theme.spacing(0, 0.5),
      marginLeft: 3,
      marginTop: marginTop,
    },
  })
);

interface ISideNavProps {
  /**
   * controls the state
   */
  open: boolean;
  /**
   *
   */
  onDrawerToggle?: () => void;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

/**
 * the left side drawer. content varies depending on the are the nav is used in.
 */
export const SideNav: FunctionComponent<ISideNavProps> = ({
  open,
  onDrawerToggle,
  children,
}) => {
  const classes = useStyles();

  const toggleDrawer = () => {
    onDrawerToggle && onDrawerToggle();
  };

  return (
    <div className={classes.root}>
      <Paper
        square
        className={classes.paperStyle}
        elevation={2}
        sx={{ width: open ? 250 : 60, transition: "all ease 300ms" }}
      >
        <Collapse
          in={open}
          collapsedSize={60}
          orientation={"horizontal"}
          timeout={{ enter: 200, exit: 300 }}
          sx={{
            "& .MuiCollapse-wrapperInner": {
              width: !open ? 60 : "100%",
              transition: "all ease 300ms",
            },
          }}
        >
          <div className={open ? classes.toolbarOpen : classes.toolbarClose}>
            <IconButton
              onClick={toggleDrawer}
              aria-label="open drawer"
              id="drawerButton"
            >
              {open ? (
                <ChevronLeftIcon id="drawerButtonLeft" />
              ) : (
                <ChevronRightIcon id="drawerButtonRight" />
              )}
            </IconButton>
          </div>
          <List style={{ marginTop: 5 }}>{children}</List>
        </Collapse>
      </Paper>
    </div>
  );
};
