import { EventModel, EventModelConfig } from "@bryntum/calendar";
import {
  CalendarEntries,
  NonBillableReasonOptions,
  RecordTimeRec,
  StatusOptions,
} from "@dyce/tnt-api";
import { PopulatedEventModel } from "@dyce/ui";
import { t } from "i18next";
import { DateTime } from "luxon";

export const handleMutateEvents = (
  combinedEntries: (RecordTimeRec | CalendarEntries)[]
): Partial<EventModelConfig>[] => {
  const { events: combinedEvents, recordsWithoutTimespan } =
    handleEventMutation({
      entries: combinedEntries,
    });
  const recEvents: Partial<EventModelConfig>[] = [];
  recordsWithoutTimespan.forEach(([day, rec]) => {
    const event = {
      id: rec.id,
      name: rec.description,
      startDate: day,
      endDate: DateTime.fromISO(day).endOf("day").toISO(),
      cls:
        rec.status !== StatusOptions.OPEN
          ? "allday-record noPointer allday-record-text"
          : "allday-record allday-record-text",
      durationUnit: "minute",
      duration: rec.duration,
      editable: true,
      draggable: true,
      resizable: false,
      readOnly:
        rec.status === StatusOptions.POSTED ||
        rec.status === StatusOptions.RELEASED,
      timerecordingId: rec.id,
      timerecording: rec,
      allDay: true,
      date: day,
    };
    recEvents.push(event as Partial<EventModelConfig>);
  });

  return [...combinedEvents, ...recEvents];
};

export const handleEventMutation = ({
  entries,
}: {
  entries: (CalendarEntries | RecordTimeRec)[];
}): {
  events: Partial<EventModelConfig>[];
  recordsWithoutTimespan: [string, RecordTimeRec][];
} => {
  const isRecordTimeRec = (entry: any): entry is RecordTimeRec => {
    return (entry as RecordTimeRec).date !== undefined;
  };

  const recordsWithoutTimespan: [string, RecordTimeRec][] = [];
  const events: Partial<EventModelConfig>[] = [];
  entries.forEach((entry) => {
    if (entry.start === null || entry.end === null) {
      if (isRecordTimeRec(entry)) {
        recordsWithoutTimespan.push([
          DateTime.fromISO(entry.date).toISO(),
          entry,
        ]);
      }
    } else {
      const handleCalEventDuration = (entry: CalendarEntries) => {
        return entry.status === 1 ? "tentative noPointer" : "noPointer";
      };

      const handleTimerecDuration = (entry: RecordTimeRec) => {
        if (entry.status !== StatusOptions.OPEN) {
          return "not-open noPointer";
        } else {
          return undefined;
        }
      };

      /**
       * Calculate time for each event (in ISO-string)
       * @returns Start/Endtime as ISO-string
       */
      const handleEventTime = ({
        time,
        allDay,
      }: {
        /**
         * Start/End-time of event (timerecording | calendarEvent)
         */
        time: string | Date;
        /**
         * Is event an all-day event?
         */
        allDay: boolean;
      }) => {
        let newTime = null;
        if (typeof time === "string") {
          // Time is an ISO-string
          newTime = allDay
            ? DateTime.fromISO(time)
                .minus({
                  hours: DateTime.fromISO(time).offset / 60,
                })
                .toISO()
            : DateTime.fromISO(time).toISO();
        } else {
          // Time is a Date
          newTime = allDay
            ? DateTime.fromJSDate(time)
                .minus({
                  hours: DateTime.fromJSDate(time).offset / 60,
                })
                .toISO()
            : DateTime.fromJSDate(time).toISO();
        }

        return newTime;
      };

      /**
       * Calculate duration for each event (in minutes)
       * @returns duration as number
       */
      const handleEventDuration = ({
        start,
        end,
      }: {
        /**
         * Start time of event as ISO-string
         */
        start: string;
        /**
         * End time of event as ISO-string
         */
        end: string;
      }): number => {
        const startDate = DateTime.fromISO(start);
        const endDate = DateTime.fromISO(end);
        const duration = endDate.diff(startDate, "minutes").toObject()
          .minutes as number;
        return duration;
      };

      const event = {
        id: entry.id ? entry.id : undefined,
        name: entry.description,
        startDate: handleEventTime({
          time: entry.start
            ? entry.start
            : (entry as unknown as EventModel).startDate,
          allDay: isRecordTimeRec(entry) ? false : entry.isAllDay,
        }),
        endDate: handleEventTime({
          time: entry.end
            ? entry.end
            : (entry as unknown as EventModel).endDate,
          allDay: isRecordTimeRec(entry) ? false : entry.isAllDay,
        }),
        duration: isRecordTimeRec(entry)
          ? entry.duration
          : handleEventDuration({
              start: handleEventTime({
                time: entry.start
                  ? entry.start
                  : (entry as unknown as EventModel).startDate,
                allDay: entry.isAllDay,
              }),
              end: handleEventTime({
                time: entry.end
                  ? entry.end
                  : (entry as unknown as EventModel).endDate,
                allDay: entry.isAllDay,
              }),
            }),
        durationUnit: "minute",
        allDay: isRecordTimeRec(entry) ? false : entry.isAllDay,
        eventColor: isRecordTimeRec(entry)
          ? "green"
          : handleEventColor(entry.status),
        cls: isRecordTimeRec(entry)
          ? handleTimerecDuration(entry)
          : handleCalEventDuration(entry),
        timerecordingId: isRecordTimeRec(entry)
          ? entry.id.includes("_")
            ? "creating"
            : entry.id
          : entry.timerecordingId,
        timerecording:
          isRecordTimeRec(entry) && !entry.id.includes("_") ? entry : null,
        sensitivity: isRecordTimeRec(entry) ? null : entry.sensitivity,
        readOnly: isRecordTimeRec(entry)
          ? entry.status === StatusOptions.POSTED ||
            entry.status === StatusOptions.RELEASED
          : false, // Timerecordings with !StatusOptions.OPEN
        draggable: isRecordTimeRec(entry), // Block drag for calenderEvents
        resizable: isRecordTimeRec(entry), // Block resizable for calenderEvents
        editable: isRecordTimeRec(entry), // Block editable for calenderEvents
      };
      events.push(event as Partial<EventModelConfig>);
    }
  });
  // Sort from old to new
  recordsWithoutTimespan.sort(
    (a, b) =>
      DateTime.fromISO(a[0]).toMillis() - DateTime.fromISO(b[0]).toMillis()
  );

  return { events, recordsWithoutTimespan };
};

type EventColor =
  | "red"
  | "blue"
  | "cyan"
  | "gray"
  | "green"
  | "indigo"
  | "lime"
  | "orange"
  | "pink"
  | "purple"
  | "teal"
  | "violet"
  | "yellow"
  | "deep-orange"
  | "gantt-green"
  | undefined;

const handleEventColor = (status: number): EventColor => {
  switch (status) {
    case 0: // FREE
      return "cyan";
    case 1: // TENTATIVE
      return "blue";
    case 2: // ABSENT
      return "violet";
    case 3: // BUSY
      return "gray";
    case 4: // OUT_OF_OFFICE
      return "cyan";
    default:
      return "gray";
  }
};

export const handleEventSorting = ({
  events,
}: {
  events: PopulatedEventModel[];
}): { sortedEvents: PopulatedEventModel[] } => {
  const sortedEvents: PopulatedEventModel[] = events;
  let eventsInSameRange: [string, PopulatedEventModel[]][] = [];
  const compareEventsPerDay: [string, PopulatedEventModel[]][] = Object.entries(
    events.reduce(
      (eventsPerDay, event) => {
        const date = DateTime.fromISO(event.startDate as string).toISODate();
        eventsPerDay[date] = eventsPerDay[date]
          ? [...eventsPerDay[date], event]
          : [event];
        return eventsPerDay;
      },
      {} as { [key: string]: PopulatedEventModel[] }
    )
  );
  let copyCompareEventsPerDay: PopulatedEventModel[] = [];
  for (let i = 0; i < compareEventsPerDay.length; i++) {
    const eventsFromOneDate = compareEventsPerDay[i][1];
    // Remove last entry when at next day
    copyCompareEventsPerDay = [...eventsFromOneDate];
    let simpleRangeArray: PopulatedEventModel[] = [];
    for (let j = 0; j < eventsFromOneDate.length; j++) {
      const event = eventsFromOneDate[j];
      //
      for (let k = 0; k < copyCompareEventsPerDay.length; k++) {
        const copiedEvent = copyCompareEventsPerDay[k];
        if (event.allDay === false && copiedEvent.allDay === false) {
          if (event.id !== copiedEvent.id) {
            const startEventIndex = DateTime.fromISO(
              copiedEvent.startDate as string
            );
            const endEventIndex = DateTime.fromISO(
              copiedEvent.endDate as string
            );
            const startCopiedEventIndex = DateTime.fromISO(
              event.startDate as string
            );
            // Check if events are in the same range
            if (
              startEventIndex.toMillis() <= startCopiedEventIndex.toMillis() &&
              endEventIndex.toMillis() > startCopiedEventIndex.toMillis()
            ) {
              simpleRangeArray = Array.from(
                new Set([event, copiedEvent, ...simpleRangeArray])
              );
            }
          }
        }
      }
    }
    if (simpleRangeArray.length > 0) {
      eventsInSameRange = Array.from(
        new Set([
          [
            DateTime.fromISO(
              simpleRangeArray[0].startDate.toString()
            ).toISODate(),
            simpleRangeArray,
          ],
          ...eventsInSameRange,
        ])
      );
    }
    simpleRangeArray = [];
  }
  if (eventsInSameRange.length > 0) {
    for (let i = 0; i < eventsInSameRange.length; i++) {
      const eventsFromOneDates = eventsInSameRange[i][1];
      eventsFromOneDates.sort(
        (a, b) =>
          DateTime.fromISO(a.startDate.toString()).toMillis() -
          DateTime.fromISO(b.startDate.toString()).toMillis()
      );

      const arrays: PopulatedEventModel[][] = [];
      for (const eventsFromOneDate of eventsFromOneDates) {
        let added = false;
        for (const array of arrays) {
          const lastEventInArray = array[array.length - 1];
          if (
            DateTime.fromISO(
              eventsFromOneDate.startDate.toString()
            ).toMillis() <
            DateTime.fromISO(lastEventInArray.endDate.toString()).toMillis()
          ) {
            array.push(eventsFromOneDate);
            added = true;
            break;
          }
        }
        if (!added) {
          arrays.push([eventsFromOneDate]);
        }
      }
      // First sort by timerecording
      arrays.map((array) =>
        array.sort((a) => (a.timerecording !== null ? -1 : 1))
      );
      // Then add CLS classes
      arrays.forEach((event) => {
        if (event.length === 2) {
          const hasCalendarEvent = event.some(
            (ev) => ev.timerecording === null
          );
          const hasTimeRecording = event.some(
            (ev) => ev.timerecording !== null
          );
          if (hasCalendarEvent && hasTimeRecording) {
            const timerecordingsAt = event.map(
              (ev) => ev.timerecording !== null
            );
            timerecordingsAt.forEach((timerecordingAt, index) => {
              if (timerecordingAt) {
                event[index].cls =
                  event[index].cls + ` depend-two-${index + 1}`;
              } else {
                event[index].cls =
                  event[index].cls + ` depend-two-${index + 1}`;
              }
            });
          } else if (hasTimeRecording) {
            // Overlapping with an event but counted as 2-block
            // Second (earlier startDate) as first
            event[0].cls = event[0].cls + ` depend-two-2`;
            event[1].cls = event[1].cls + ` depend-two-1`;
          }
        }
        if (event.length === 3) {
          const hasCalendarEvent = event.some(
            (ev) => ev.timerecording === null
          );
          const hasTimeRecording = event.some(
            (ev) => ev.timerecording !== null
          );
          if (hasCalendarEvent && hasTimeRecording) {
            const handleRecordSorting = () => {
              const timerecordingsAt = event.map(
                (ev) => ev.timerecording !== null
              );
              timerecordingsAt.forEach((timerecordingAt, index) => {
                if (timerecordingAt) {
                  event[index].cls =
                    event[index].cls + ` depend-three-${index + 1}`;
                } else {
                  event[index].cls =
                    event[index].cls + ` depend-three-${index + 1}`;
                }
              });
            };
            if (
              event[0].timerecording !== null &&
              event[1].timerecording !== null
            ) {
              const firstTimerecording = event[1].timerecording;
              const secondTimerecording = event[0].timerecording;
              const firstRecEndtime = firstTimerecording.end
                ? DateTime.fromISO(firstTimerecording.end)
                : null;
              const secondRecStarttime = secondTimerecording.start
                ? DateTime.fromISO(secondTimerecording.start)
                : null;

              if (
                firstRecEndtime &&
                secondRecStarttime &&
                firstRecEndtime.toMillis() <= secondRecStarttime.toMillis()
              ) {
                event[0].cls = event[0].cls + ` depend-two-1`;
                event[1].cls = event[1].cls + ` depend-two-1`;
                event[2].cls = event[2].cls + ` depend-two-2`;
              } else {
                handleRecordSorting();
              }
            } else if (
              event[0].timerecording !== null &&
              event[1].timerecording === null
            ) {
              const firstEvent = event[1];
              const secondEvent = event[2];
              const firstRecEndtime = DateTime.fromISO(
                firstEvent.endDate as string
              );
              const secondRecStarttime = DateTime.fromISO(
                secondEvent.startDate as string
              );
              if (firstRecEndtime.toMillis() <= secondRecStarttime.toMillis()) {
                event[0].cls = event[0].cls + ` depend-two-1`;
                event[1].cls = event[1].cls + ` depend-two-2`;
                event[2].cls = event[2].cls + ` depend-two-2`;
              } else {
                handleRecordSorting();
              }
            } else {
              handleRecordSorting();
            }
          }
        }
        if (event.length === 4) {
          const hasCalendarEvent = event.some(
            (ev) => ev.timerecording === null
          );
          const hasTimeRecording = event.some(
            (ev) => ev.timerecording !== null
          );
          if (hasCalendarEvent && hasTimeRecording) {
            const handleRecordSorting = () => {
              const timerecordingsAt = event.map(
                (ev) => ev.timerecording !== null
              );
              timerecordingsAt.forEach((timerecordingAt, index) => {
                if (timerecordingAt) {
                  event[index].cls =
                    event[index].cls + ` depend-four-${index + 1}`;
                } else {
                  event[index].cls =
                    event[index].cls + ` depend-four-${index + 1}`;
                }
              });
            };
            // One Record - three events
            if (
              event[0].timerecording !== null &&
              event[1].timerecording === null
            ) {
              const firstEvent = event[1];
              const secondEvent = event[2];
              const thirdEvent = event[3];
              const firstEventEndtime = DateTime.fromISO(
                firstEvent.endDate as string
              );
              const secondEventStarttime = DateTime.fromISO(
                secondEvent.startDate as string
              );
              const secondEventEndtime = DateTime.fromISO(
                secondEvent.endDate as string
              );
              const thirdEventStarttime = DateTime.fromISO(
                thirdEvent.startDate as string
              );
              if (
                firstEventEndtime.toMillis() <=
                  secondEventStarttime.toMillis() &&
                firstEventEndtime.toMillis() <= thirdEventStarttime.toMillis()
              ) {
                event[0].cls = event[0].cls + ` depend-two-1`;
                event[1].cls = event[1].cls + ` depend-two-2`;
                event[2].cls = event[2].cls + ` depend-four-3`;
                event[3].cls = event[3].cls + ` depend-four-4`;
              } else if (
                secondEventEndtime.toMillis() <= thirdEventStarttime.toMillis()
              ) {
                event[0].cls = event[0].cls + ` depend-four-1`;
                event[1].cls = event[1].cls + ` depend-four-2`;
                event[2].cls = event[2].cls + ` depend-two-2`;
                event[3].cls = event[3].cls + ` depend-two-2`;
              } else {
                handleRecordSorting();
              }
              // Two Records - two events
            } else if (
              event[0].timerecording !== null &&
              event[1].timerecording !== null &&
              event[2].timerecording === null
            ) {
              const firstRecord = event[0]; // Timerecording
              const secondRecord = event[1]; // Timerecording
              const firstEvent = event[2]; // Calendar Event
              const secondEvent = event[3]; // Calendar Event
              const firstRectStarttime = DateTime.fromISO(
                firstRecord.startDate as string
              );
              const secondRectEndtime = DateTime.fromISO(
                secondRecord.endDate as string
              );
              const firstEventEndtime = DateTime.fromISO(
                firstEvent.endDate as string
              );
              const secondEventStarttime = DateTime.fromISO(
                secondEvent.startDate as string
              );

              if (
                firstRectStarttime.toMillis() >= secondRectEndtime.toMillis() &&
                firstEventEndtime.toMillis() <= secondEventStarttime.toMillis()
              ) {
                event[0].cls = event[0].cls + ` depend-two-1`;
                event[1].cls = event[1].cls + ` depend-two-1`;
                event[2].cls = event[2].cls + ` depend-two-2`;
                event[3].cls = event[3].cls + ` depend-two-2`;
              } else if (
                firstRectStarttime.toMillis() <= secondRectEndtime.toMillis() &&
                firstEventEndtime.toMillis() <= secondEventStarttime.toMillis()
              ) {
                event[0].cls = event[0].cls + ` depend-four-1`;
                event[1].cls = event[1].cls + ` depend-four-2`;
                event[2].cls = event[2].cls + ` depend-two-2`;
                event[3].cls = event[3].cls + ` depend-two-2`;
              } else if (
                firstRectStarttime.toMillis() >= secondRectEndtime.toMillis() &&
                firstEventEndtime.toMillis() >= secondEventStarttime.toMillis()
              ) {
                event[0].cls = event[0].cls + ` depend-two-1`;
                event[1].cls = event[1].cls + ` depend-two-1`;
                event[2].cls = event[2].cls + ` depend-four-3`;
                event[3].cls = event[3].cls + ` depend-four-4`;
              } else {
                handleRecordSorting();
              }
            }
            // Three Records - one events
            else if (
              event[0].timerecording !== null &&
              event[1].timerecording !== null &&
              event[2].timerecording !== null
            ) {
              const firstRecord = event[0]; // Timerecording
              const secondRecord = event[1]; // Timerecording
              const thirdRecord = event[2]; // Timerecording
              const firstRecStarttime = DateTime.fromISO(
                firstRecord.startDate as string
              );
              const secondRecStarttime = DateTime.fromISO(
                secondRecord.startDate as string
              );
              const thirdRecEndtime = DateTime.fromISO(
                thirdRecord.endDate as string
              );
              if (
                thirdRecEndtime.toMillis() <= firstRecStarttime.toMillis() &&
                thirdRecEndtime.toMillis() <= secondRecStarttime.toMillis()
              ) {
                event[0].cls = event[0].cls + ` depend-three-1`;
                event[1].cls = event[1].cls + ` depend-three-2`;
                event[2].cls = event[2].cls + ` depend-two-1`;
                event[3].cls = event[3].cls + ` depend-three-3`;
              } else if (
                thirdRecEndtime.toMillis() <= firstRecStarttime.toMillis() &&
                secondRecStarttime.toMillis() < thirdRecEndtime.toMillis()
              ) {
                event[0].cls = event[0].cls + ` depend-three-1`;
                event[1].cls = event[1].cls + ` depend-three-2`;
                event[2].cls = event[2].cls + ` depend-three-1`;
                event[3].cls = event[3].cls + ` depend-three-3`;
              } else {
                handleRecordSorting();
              }
            } else {
              handleRecordSorting();
            }
          }
        }
        if (event.length === 5) {
          const hasCalendarEvent = event.some(
            (ev) => ev.timerecording === null
          );
          const hasTimeRecording = event.some(
            (ev) => ev.timerecording !== null
          );
          if (hasCalendarEvent && hasTimeRecording) {
            const timerecordingsAt = event.map(
              (ev) => ev.timerecording !== null
            );
            timerecordingsAt.forEach((timerecordingAt, index) => {
              if (timerecordingAt) {
                event[index].cls =
                  event[index].cls + ` depend-five-${index + 1}`;
              } else {
                event[index].cls =
                  event[index].cls + ` depend-five-${index + 1}`;
              }
            });
          }
        }
      });
    }
  }

  eventsInSameRange = [];

  return { sortedEvents };
};

/**
 * Function to check if user paints to next day(s)
 * @param param0 See props
 * @returns object with error boolean
 */
export const handleEventDurationCheck = ({
  event,
}: {
  /**
   * Current calendar-event to check
   */
  event: EventModelConfig;
}): { error: boolean } => {
  const start = DateTime.fromJSDate(event.startDate as Date);
  const end = DateTime.fromJSDate(event.endDate as Date);
  if (start.day !== end.day) {
    // Event goes in the next day
    if (
      end.toISOTime({ suppressMilliseconds: true, includeOffset: false }) !==
      "00:00:00"
    ) {
      // Event is greater than 00:00:00 at next day => error
      return { error: true };
    }
  }

  return { error: false };
};
/**
 * Function to mutate event to timerecording and handle tntModel behavior
 * like (nonBillableReason and durationBillable) if duration changed by dragging or resizing
 * @param param0 See props
 * @returns RecordTimeRec
 */
export const handleEventToTimerecording = ({
  event,
  timerecordingId,
  updatePeriods = false,
  updateDate = false,
}: {
  /**
   * Current calendar-event to check (timerecording)
   */
  event: PopulatedEventModel;
  /**
   * Id of timerecording
   */
  timerecordingId: string | null | undefined;
  /**
   * Update periods of timerecording (start and end with duration) => Event has resized
   */
  updatePeriods?: boolean;
  /**
   * Update date of timerecording => Event has moved to an other day (only allDay events)
   */
  updateDate?: boolean;
}): RecordTimeRec => {
  const start = DateTime.fromJSDate(event.startDate as Date);
  let end = DateTime.fromJSDate(event.endDate as Date);
  const date = start.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
  let duration = end.diff(start, "minutes").toObject().minutes ?? 0;

  if (event.timerecording) {
    if (updatePeriods) {
      // Drag & Drop or Resize event (we have start and end)
      const recDuration = event.timerecording.duration;
      const calendarEventId = event.timerecording.calendarEventId;
      let nBR = event.timerecording.nonBillableReason;
      let durationBillable = event.timerecording.durationBillable;
      // Check for tntModelLine - handle nonBillableReason and durationBillable
      if (event.timerecording.tntModelLine) {
        const tntModelLine = event.timerecording.tntModelLine;
        if (!tntModelLine.billable) {
          // Check if event has resized (new start or new end)
          if (recDuration !== duration) {
            // Event has resized
            nBR = tntModelLine.nonBillableReason;
            durationBillable = 0;
          }
        } else {
          // Check if event has resized (new start or new end)
          if (recDuration !== duration) {
            // Event has resized
            nBR = NonBillableReasonOptions.NONE;
            durationBillable = duration;
          }
        }
      } else {
        // Check if event has resized (new start or new end)
        if (recDuration !== duration) {
          // Event has resized
          nBR = NonBillableReasonOptions.NONE;
          durationBillable = duration;
        }
      }

      if (event.cls && event.cls.toString().includes("allday-record")) {
        // Event was allDay => mutate end correct
        const minimumDuration = recDuration === 0 ? 60 : recDuration;
        end = start.plus({ minutes: minimumDuration });
        duration = minimumDuration;
      }

      return {
        ...event.timerecording,
        start: start.toISO(),
        end: end.toISO(),
        calendarEventId: calendarEventId,
        duration: duration,
        durationBillable: durationBillable,
        nonBillableReason: nBR,
        date: date.toISO(),
      };
    } else {
      // Drag & Drop an allDay event from one day to an other (Just change the date)
      return {
        ...event.timerecording,
        date: updateDate ? date.toISO() : event.timerecording.date,
      };
    }
  } else {
    // Create new timerecording from painting in Calendar
    const id = timerecordingId
      ? timerecordingId === "creating"
        ? ""
        : timerecordingId
      : "";
    // link eventId to timerecording if created from calendarEvent
    const calendarEventId = event.id.toString().includes("_generated")
      ? null
      : event.id.toString();
    // remove Description when create from painting in Calendar else use from calendarEvent
    const description =
      event.name === t("calendar.event.description.new") ? "" : event.name;

    return {
      id,
      start: start.toISO(),
      end: end.toISO(),
      calendarEventId: calendarEventId,
      date: date.toISO(),
      description: description,
      status: StatusOptions.OPEN,
      duration: duration,
      break: 0,
      created: DateTime.now().toISO(),
      customer: null,
      job: null,
      jobTask: null,
      jobPlanningLine: null,
      tntModelLine: null,
      nonBillableReason: NonBillableReasonOptions.NONE,
      durationBillable: duration,
      complete: false,
      modified: DateTime.now().toISO(),
    };
  }
};

/**
 * Function to prove if guid is valid; timerecording has a guid, calendar event does not
 * @param guid ID from event
 * @returns true if guid is valid
 */
export const handleGUIDCheck = (guid: string): boolean => {
  const regex =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
  return new RegExp(regex).test(guid);
};

/**
 * Function to check if event and timerecording is linked and at same day
 * @returns if button should be rendered
 */
export const handleRecordEventDependency = ({
  currentEvent,
  events,
}: {
  /**
   * Current calendar-event to check
   */
  currentEvent: PopulatedEventModel;
  /**
   * All events from calendar (calendarEvents and timerecordings)
   */
  events: PopulatedEventModel[];
}): boolean => {
  let renderAddButton = true;
  const eventId = currentEvent.id.toString();

  const eventIsCalendarEvent = !handleGUIDCheck(eventId);
  if (eventIsCalendarEvent) {
    (events as PopulatedEventModel[]).forEach((event) => {
      // Check if event is linked to a timerecording
      if (
        event.timerecording &&
        event.timerecording.calendarEventId === eventId
      ) {
        // Check if event is on the same day
        let currentEventDate = DateTime.fromJSDate(
          currentEvent.startDate as Date
        ).toISODate();
        if (!currentEventDate) {
          currentEventDate = DateTime.fromISO(
            currentEvent.startDate as string
          ).toISODate();
        }
        if (
          currentEventDate ===
          DateTime.fromISO(event.timerecording.date).toISODate()
        ) {
          renderAddButton = false;
        }
      } else if (
        event.allDay &&
        event.timerecording === null &&
        eventId === event.id
      ) {
        renderAddButton = false;
      }
    });
  }

  return renderAddButton;
};

export const handleTimeFormat = ({
  date,
  locale,
}: {
  date: Date;
  locale: string;
}): "HH:mm" | "H:mm A" => {
  const format = DateTime.fromISO(DateTime.fromJSDate(date).toISODate(), {
    locale: locale,
  })
    .toLocaleString(DateTime.TIME_SIMPLE)
    .includes("AM")
    ? "H:mm A"
    : "HH:mm";

  return format;
};

/**
 * Function to get width of calendar event container and return for buttonContainer
 * @param param0 see props
 * @returns string with width of button container
 */
export const handleButtonContainerWidth = ({
  calEventId,
}: {
  calEventId: string;
}) => {
  const calEvent = document.querySelector(
    `[data-event-id="${calEventId}"]`
  ) as HTMLDivElement;

  if (calEvent) {
    return `${calEvent.clientWidth}`;
  } else {
    return "100px";
  }
};
