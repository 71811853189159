import React from "react";
import { dyceTheme } from "../";
import { ThemeProvider, CssBaseline, Components, Theme } from "@mui/material";
import { OverwritePaletteOptions } from "./theme";

interface IThemeWrapperProps {
  /**
   * Overwrite default components
   * @default {}
   */
  customComponents?: Components<Omit<Theme, "components">> | undefined;
  /**
   * Overwrite Palette props
   */
  overwritePalette?: OverwritePaletteOptions;
  /**
   * Turn on/off dark mode
   * @default false
   */
  darkMode?: boolean;
  /**
   * Parse selected language
   * @default "en"
   */
  locale?: string;
  /**
   * Switch if left side drawer is 'open' or 'closed'
   * @default false => closed
   */
  sideDrawerLeft?: boolean;
  /**
   * Define border radius;
   */
  borderRadius?: number;
  /**
   * Define if the device is mobile or not
   * @default false
   */
  isMobile?: boolean;
  /*
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const ThemeWrapper: React.FC<IThemeWrapperProps> = ({
  customComponents,
  overwritePalette,
  darkMode,
  locale,
  sideDrawerLeft,
  borderRadius,
  isMobile = false,
  children,
}) => {
  const theme = dyceTheme({
    customComponents,
    overwritePalette,
    darkMode,
    locale,
    sideDrawerLeft,
    borderRadius,
    isMobile,
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
