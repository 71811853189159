import { SDK } from "@dyce/azure-sdk";
import {
  CommonServiceIds,
  IExtensionDataManager,
  IExtensionDataService,
  IProjectInfo,
  IProjectPageService,
} from "azure-devops-extension-api";

/**
 * Function to authorize at DevOps Backend
 * @returns DevOps ExtensionDataManager:
 * (getValue<T>(key: string, documentOptions?: IDocumentOptions): Promise<T>)
 * (setValue<T>(key: string, value: T, documentOptions?: IDocumentOptions): Promise<T>;)
 * (getDocument(collectionName: string, id: string, documentOptions?: IDocumentOptions): Promise<any>)
 * (getDocuments(collectionName: string, documentOptions?: IDocumentOptions): Promise<any[]>)
 * (createDocument(collectionName: string, doc: any, documentOptions?: IDocumentOptions): Promise<any>)
 * (setDocument(collectionName: string, doc: any, documentOptions?: IDocumentOptions): Promise<any>)
 * (updateDocument(collectionName: string, doc: any, documentOptions?: IDocumentOptions): Promise<any>)
 * (deleteDocument(collectionName: string, id: string, documentOptions?: IDocumentOptions): Promise<void>)
 * (queryCollectionsByName(collectionNames: string[]): Promise<ExtensionDataCollection[]>)
 * (queryCollections(collections: ExtensionDataCollection[]): Promise<ExtensionDataCollection[]>)
 */
export const handleDevOpsBackend = async (): Promise<IExtensionDataManager> => {
  return await SDK.getService<IExtensionDataService>(
    CommonServiceIds.ExtensionDataService
  ).then(async (service) =>
    service.getExtensionDataManager(
      SDK.getContributionId(),
      await SDK.getAccessToken()
    )
  );
};

/**
 * Function to authorize at DevOps Backend
 * @returns DevOps ProjectPageService:
 * (getProject(): Promise<IProjectInfo | undefined>)
 */
export const handleDevOpsInformation = async () => {
  return await SDK.getService<IProjectPageService>(
    CommonServiceIds.ProjectPageService
  ).then((service) => service.getProject());
};

/**
 * Function to get DevOps instance information's, needed to interact with DevOps API
 * @param withProject If true, fn will call devOps project information too
 * @returns DevOps information about Organization, Project?, users authToken as object
 */
export const handleDevOpsOAuth = async ({
  withProject,
}: {
  withProject: boolean;
}): Promise<{
  organization: SDK.IHostContext;
  authToken: string;
  project?: IProjectInfo | undefined;
}> => {
  if (withProject) {
    const organization: SDK.IHostContext = await SDK.getHost();
    const authToken = await SDK.getAccessToken();
    const project = await handleDevOpsInformation();
    return {
      organization,
      authToken,
      project,
    };
  } else {
    const organization: SDK.IHostContext = await SDK.getHost();
    const authToken = await SDK.getAccessToken();

    return {
      organization,
      authToken,
    };
  }
};

/**
 * Function to specify collection. If not defined, should return DevOps-ProjectId
 * @param collection If defined, this.collection will be returned
 * @returns Collection name or projectId if not defined
 */
export const handleCollection = async (collection?: string) => {
  const { project } = await handleDevOpsOAuth({
    withProject: true,
  });

  return collection ? collection : project ? project.id : "DefaultCollection";
};
