import React, { useEffect } from "react";
// Styles
import { useStyles } from "./homeStyle";
// Helper
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
// Dyce-lib
import { useAbility } from "@dyce/auth";
import { Content, useStaticContent } from "@dyce/ui";
import {
  selectCurrentUser,
  selectIsUserLoading,
  useAppSelector,
} from "@dyce/slices";

export const Home: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { routes } = useStaticContent();
  const { t } = useTranslation();
  const { can } = useAbility();

  // Selectors
  const user = useAppSelector(selectCurrentUser); //user
  const isLoading = useAppSelector(selectIsUserLoading);

  // if user is loaded and only has one role -> redirect him to matching area
  useEffect(() => {
    if (user) {
      if (user.roles.length === 1) {
        switch (user.roles[0].type) {
          case "timerec": {
            if (user.resource !== null) {
              history.push(routes.TNT);
            }
            break;
          }
          case "admin":
            history.push(routes.ADMIN);
            break;

          default:
            break;
        }
      }
    }
  }, [user]);

  return (
    <>
      {/* Only render content once user has been fetched from the backend */}
      {!isLoading && (
        <div className={classes.root}>
          <h2 style={{ paddingLeft: "1.5rem" }}>Apps</h2>
          <div className={classes.appSelection}>
            {user.roles.length === 0 ? (
              <p className={classes.errorMessage}>{t("home.noRoles")}</p>
            ) : (
              <>
                {/* 
                  Render error message if the user has correct role attached but can't access the are
                  That means the setup for the timerecording has not finished (ie no resource assigned)
                */}
                {!can("create", "timerec") &&
                  user.roles.findIndex((r) => r.type === "timerec") > -1 && (
                    <p className={classes.errorMessage}>
                      {t("home.setupUnfinished")}
                    </p>
                  )}
                <div className={classes.appSelectionWrap}>
                  <Content paddingRight />
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
