import {
  useState,
  useEffect,
  useRef,
  PropsWithChildren,
  MutableRefObject,
} from "react";
import { useTranslation } from "react-i18next";
// Helper
import { RefCallBack } from "react-hook-form";
import { SortDirection } from "react-data-grid";
import { useHotkeys } from "react-hotkeys-hook";
import { calculateHeight, findById } from "./utils";
// Dyce-Lib
import {
  Dialog,
  Datagrid,
  IDatagridColumn,
  IDatagridRow,
  Popper,
  InfScrollProps,
  OnChangeEvent,
  DialogSearchField,
  useStaticContent,
  LookUpFilter,
  SettingsElement,
} from "@dyce/ui";
import { DyceTheme } from "@dyce/theme";
import { useWindowVisibilityChange } from "@dyce/hooks";
// MUI
import { useStyles } from "./styles/tntEditorTextFields";
import { DialogContent } from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  TextField,
  useTheme,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import ArrowDropUpSharpIcon from "@mui/icons-material/ArrowDropUpSharp";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";

type ILookUpProps<T, U extends string> = {
  /**
   * Name of the lookup as string with @typeof generic U
   */
  name: U;
  /**
   * Default sorting of the underlying datagrid
   */
  defaultSorting?: [string, SortDirection];
  /**
   * Renders the lookup disabled
   */
  disabled?: boolean;
  /**
   * Mark text fields as required field
   */
  isRequired?: boolean;
  /**
   * selected value
   */
  selectedId?: string;
  /**
   * Array of values.
   * ID will be used to index the rows.
   * Cells constructs the columns.
   */
  values: IDatagridRow<T>[];
  /**
   * Key from the values object to display in the text field
   */
  displayKey?: keyof T;
  /**
   * Text Field Label
   */
  label?: string;
  /**
   * Clear field with onChange call
   */
  deleteDependency?: boolean;
  /**
   * Get props per column.
   * Columns are calculated by the cells of the values you passed.
   * Defaults to value key and true for editable and searchable
   */
  getColumnProps?: (key: string) => IDatagridColumn;
  /**
   * Gets called every time the input or selected value changes
   */
  onChange: (value: OnChangeEvent<T>) => void;
  /**
   * Gets called every time the component looses focus
   */
  onBlur: (value: OnChangeEvent<T>) => void;
  /**
   * Gets called when the datagrid gains focus.
   * Eg to collect data
   */
  onDataGridFocus: () => void;
  /**
   * Provide an optional filter function, that gets called when the user input changes
   */
  filter?: (data: IDatagridRow<T>[], value: string) => IDatagridRow<T>[];
  /**
   * Provide grid sorting information
   */
  onGridSort?: (
    name: U,
    key: string,
    direction: SortDirection,
    dataGridRows: IDatagridRow<any>[]
  ) => void;
  /**
   * Provide count of projecting-fields in one wrapper to calculate width & wrap
   */
  fieldsPerWrapper?: {
    summary: number;
    currentIndex: number;
  };
  /**
   * RefCallback from Controller to make setFocus possible
   */
  refHook?: RefCallBack;
  /**
   * Callback if user aborts Dialog
   */
  abortFromDialog?: (key: U) => void;
  /**
   * Callback for user input in searchfield from Dialog.
   * Use this if different behavior is needed as from TextField
   */
  searchQueryDialog?: (query: string) => void;
  /**
   * Callback for infinite scrolling in Datagrid
   */
  infiniteScroll?: (props: { direction: "UP" | "DOWN" }) => void;
  /**
   * Summary for infinite scrolling provided props
   */
  infScrollProps: InfScrollProps;
  /**
   * Callback for loading-Icon to show in TextField when wait for API get call
   */
  pendingData?: boolean;
  /**
   * Define tabIndex to focus correct field
   */
  tabIndex?: number;
  /**
   * Define size of TextField
   * @default "medium"
   */
  lookupSize?: "small" | "medium";
  /**
   * If true, the input element will be focused during the first mount
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Callback to set new focus on Enter key
   */
  focusOnHotkey: ({
    /**
     * Name of the field to focus from generic @U
     */
    field,
    /**
     * Can be used to force focus on itself, will be triggered in
     * hotKey FN 'CTRL+a' || 'COMMAND+a'
     */
    doFocusItself,
    /**
     * If true, input was selected during pending state
     */
    pending,
  }: {
    field: U;
    doFocusItself?: boolean;
    pending?: boolean;
  }) => void;
  /**
   * Callback fired when no rows are rendered
   * @returns JSX.Element
   */
  onEmptyRowsRenderer?: () => JSX.Element;
  /**
   * Callback for optional Icon at InputAdornment in TextField
   */
  additionInfoIcon?: (
    inputValue: string,
    theme: DyceTheme
  ) => JSX.Element | null;
  /**
   * Callback for individual filter to fill first entry from 'values'
   */
  findEntryByValueFilter?: (
    values: IDatagridRow<T>[],
    id: string | undefined,
    inputValue: string
  ) => IDatagridRow<T> | null;
  /**
   * Callback-loop for individual rowFactory in Datagrid
   */
  rowFactoryFilter?: (id: string[], value: any[]) => IDatagridRow<any>[];
  /**
   * Callback to block closing from Editor e.g. when API call is pending
   */
  blockClosingCallback: (value: boolean) => void;
  /**
   * If true, Datagrid is disabled (not selectable)
   * @default false
   */
  disableDatagrid?: boolean;
  /**
   * If true, Datagrid will shown in darkMode style
   */
  darkMode: boolean;
  /**
   * If true, popper is expandable in dialog
   * @default false
   */
  hideExpandable?: boolean;
  /**
   * Callback for filter button in LookUp (Popper and Dialog)
   * @returns void
   */
  onFilter?: (value: boolean) => void;
  /**
   * Callback fired onBlur event, to save reference to state
   * @param ref MutableRefObject<HTMLInputElement | null>
   * @returns void
   */
  onInputBlur?: (ref: MutableRefObject<HTMLInputElement | null>) => void;
};

export function LookUp<T, U extends string>({
  name,
  defaultSorting,
  getColumnProps,
  onChange,
  onBlur,
  onDataGridFocus,
  filter,
  onGridSort,
  fieldsPerWrapper = {
    summary: 3,
    currentIndex: 0,
  },
  tabIndex,
  lookupSize = "medium",
  focusOnHotkey,
  displayKey,
  disabled = false,
  isRequired = false,
  selectedId,
  deleteDependency,
  autoFocus = false,
  values,
  label,
  refHook,
  abortFromDialog,
  searchQueryDialog,
  infiniteScroll,
  infScrollProps,
  pendingData = false,
  additionInfoIcon,
  findEntryByValueFilter,
  rowFactoryFilter,
  blockClosingCallback,
  onEmptyRowsRenderer,
  disableDatagrid = false,
  darkMode,
  hideExpandable = false,
  onFilter,
  onInputBlur,
  ...rest
}: PropsWithChildren<ILookUpProps<T, U>>): JSX.Element {
  const classes = useStyles();
  const theme = useTheme<DyceTheme>();
  const { platform } = useStaticContent();
  const { t } = useTranslation();

  // References
  const didMount = useRef(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const dialogInputRef = useRef<HTMLInputElement | null>(null);

  // Hooks
  useWindowVisibilityChange(() => {
    if (openPopper) {
      setOpenPopper(false);
    }
  });

  // States
  const [selectedEntry, setSelectedEntry] = useState<IDatagridRow<any> | null>(
    null
  );
  const [columns, setColumns] = useState<IDatagridColumn[]>([]);
  const [rows, setRows] = useState<IDatagridRow<T>[]>([]);
  const [isFieldFocused, setIsFieldFocused] = useState(false);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const [isGridSelected, setIsGridSelected] = useState(false);
  const [openPopper, setOpenPopper] = useState(false);
  const [popperHeight, setPopperHeight] = useState(0);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [manualGridSort, setManualGridSort] = useState(false);
  const [[sortColumn, sortDirection], setGridSort] = useState<
    [string, SortDirection]
  >(defaultSorting ? defaultSorting : ["id", "ASC"]);
  // State Dialog
  const [searchFieldValue, setSearchFieldValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogExpanded, setDialogExpanded] = useState<number>(0);
  const [internalDialogId, setInternalDialogId] = useState<string>(
    selectedId !== undefined ? selectedId : ""
  );
  const [dialogHeight, setDialogHeight] = useState<number>(0);

  // UseEffects
  useEffect(() => {
    const id = selectedId !== undefined ? selectedId : "";
    setInternalDialogId(id);
  }, [selectedId, openDialog]);

  useEffect(() => {
    if (defaultSorting && !manualGridSort) {
      setGridSort(defaultSorting);
    }
  }, [defaultSorting]);

  useEffect(() => {
    let focusTimer: NodeJS.Timeout;
    if (values) {
      setRows(values);
      setPopperHeight(calculateHeight(values));
      if (isSearchFocused && !isGridSelected) {
        focusTimer = setTimeout(() => {
          dialogInputRef &&
            dialogInputRef.current &&
            dialogInputRef.current.focus();
        }, 0);
      }
    }
    return () => clearTimeout(focusTimer);
  }, [values, isSearchFocused]);

  useEffect(() => {
    if (!openPopper && values) {
      const selected = findById(selectedId ? selectedId : "", values);
      if (selected) {
        setSelectedEntry(selected);
      }

      // Initial set values on edit behavior
      if (
        !didMount.current &&
        selectedId &&
        selectedId.length > 0 &&
        selected
      ) {
        setRows(values);
        setTextFieldValue(
          displayKey && selected && selected.cells
            ? selected.cells[displayKey]
              ? selected.cells[displayKey]
              : ""
            : selected.id
        );
        onChange({
          rawInput:
            displayKey && selected && selected.cells
              ? selected.cells[displayKey]
              : selected.id,
          selectedValue: { ...selected.cells, id: selected.id },
        });
      }
    }
  }, [selectedId]);

  useEffect(() => {
    if (deleteDependency && values.length === 0) {
      setInternalDialogId("");
      setTextFieldValue("");
      setRows([]);
      onChange({ rawInput: null, selectedValue: null });
      setSelectedEntry(null);
    }
  }, [deleteDependency]);

  useEffect(() => {
    if (values && values[0] && values[0].cells) {
      const columns = Object.keys(values[0].cells)
        .map((k) => {
          const column = getColumnProps
            ? getColumnProps(k)
            : {
                key: k,
                name: k,
                editable: true,
                searchable: true,
                display: false,
              };

          if (column && column.display) {
            return column;
          }

          return null;
        })
        .filter((e) => e !== null);

      setColumns(columns as any);
    }
  }, [values]);

  useEffect(() => {
    if (pendingData === true && openPopper === true) {
      setOpenPopper(false);
    }
  }, [pendingData]);

  // HotKeys
  useHotkeys(
    // First close current popper, to open Template-Dialog
    `${platform.modifiers.modifierCtrl}+a`,
    () => {
      // Prevent wrong focus on textfield when select all
      // This causes issues with pending behavior
      if (name === "jobPlanningLine") {
        focusOnHotkey({ field: name, doFocusItself: true });
      } else {
        inputRef.current && inputRef.current.select();
      }
    },
    {
      enabled: document.activeElement === inputRef.current,
      enableOnTags: ["INPUT", "TEXTAREA"],
    }
  );

  useHotkeys(
    // First close current popper, to open Template-Dialog
    `${platform.modifiers.modifierAlt}+t`,
    (e) => {
      e.preventDefault();
      if (openPopper) {
        setOpenPopper(false);
        setIsGridSelected(false);
        setIsFieldFocused(true);
      }
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"],
      keydown: true,
    }
  );

  useHotkeys(
    // popper opens on arrow down if textfield has focus
    "down, pageDown",
    (event) => {
      event.preventDefault();
      if (isFieldFocused) {
        setIsFieldFocused(false);
        if (selectedId && selectedId.length > 0) {
          if (!openDialog) {
            onDataGridFocus();
          }
          setIsGridSelected(true);
          !openPopper && setOpenPopper(true);
        } else {
          setIsGridSelected(true);
          !openPopper && setOpenPopper(true);
          if (!openDialog && !openPopper) {
            onDataGridFocus();
          }
        }
      }
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"],
    }
  );

  useHotkeys(
    // popper closes on escape if textfield has focus
    "esc",
    () => {
      setOpenPopper(false);
      setIsGridSelected(false);
      setIsFieldFocused(true);

      // rollback if an entry has been selected before
      if (!selectedEntry) {
        setTextFieldValue("");
        onChange({ rawInput: "", selectedValue: null });
      } else {
        displayKey && setTextFieldValue(selectedEntry.cells[displayKey]);
      }
    },
    {
      enableOnTags: ["INPUT"],
      filter: () => isFieldFocused,
    },
    [selectedEntry, textFieldValue]
  );

  useHotkeys(
    // Selects first matching entry if enter or tab is pressed
    "shift+tab",
    () => {
      if (selectedId && selectedId.length > 0) {
        handleSelect(selectedId);
      } else if (textFieldValue.length > 0 && values.length > 0) {
        const foundEntry = handleFindEntryByValue(textFieldValue);

        foundEntry && handleSelect(foundEntry.id);
        if (!foundEntry && name === "jobPlanningLine") {
          focusOnHotkey({ field: name, doFocusItself: true });
        }
      }

      setOpenPopper(false);
      setIsGridSelected(false);
    },
    {
      enabled: !isGridSelected,
      enableOnTags: ["INPUT"],
      filter: () => isFieldFocused,
      filterPreventDefault: false,
    }
  );

  useHotkeys(
    // Selects first matching entry if tab is pressed
    "tab",
    () => {
      handleHotkeyTabEnter({ hotkey: "tab" });
    },
    {
      enableOnTags: ["INPUT"],
      filter: () => isFieldFocused,
      filterPreventDefault: false,
    },
    [isGridSelected, openPopper]
  );

  useHotkeys(
    // Selects first matching entry if enter is pressed
    "enter",
    () => {
      handleHotkeyTabEnter({ hotkey: "enter" });
    },
    {
      enableOnTags: ["INPUT"],
      filter: () => isFieldFocused,
      filterPreventDefault: false,
    },
    [isGridSelected, openPopper]
  );

  const handleHotkeyTabEnter = ({ hotkey }: { hotkey: "tab" | "enter" }) => {
    if (!openDialog || hotkey === "tab") {
      if (selectedId && selectedId.length > 0) {
        // User has selected an entry per hotkey
        handleSelect(selectedId);
        setOpenPopper(false);
        setIsGridSelected(false);
        // Fire callBack only when 'enter' was pressed, because 'tab' triggers blur itself
        hotkey === "enter" && focusOnHotkey({ field: name });
      } else if (textFieldValue.length > 0 && values.length > 0) {
        // User has typed something and values (Datagrid entries) are available
        const foundEntry = handleFindEntryByValue(textFieldValue);
        if (foundEntry) {
          handleSelect(foundEntry.id);
        } else {
          // No entry found by user input
          hotkey === "enter" && handleTextFieldBlur(undefined);
          if (
            name !== "jobTask" &&
            name !== "jobPlanningLine" &&
            hotkey === "tab"
          ) {
            // Trigger blur on tab to prevent unexpected behavior fo all fields except
            // jobTask and jobPlanningLine
            handleTextFieldBlur(undefined);
          } else {
            focusOnHotkey({ field: name, doFocusItself: true });
          }
        }

        setOpenPopper(false);
        setIsGridSelected(false);
      } else if (textFieldValue.length === 0) {
        // Empty text field and hotkeys pressed
        if (hotkey === "enter" && !openPopper) {
          setOpenPopper(true);
          onDataGridFocus();
        } else if (hotkey === "tab" && openPopper) {
          setIsFieldFocused(false);
          setIsGridSelected(true);
        } else {
          setOpenPopper(false);
        }
      } else {
        setOpenPopper(false);
      }
    } else {
      // Dialog is open => Search field handling
      if (searchFieldValue.length > 0 && values.length > 0) {
        const foundEntry = handleFindEntryByValue(searchFieldValue);

        if (foundEntry) {
          handleSelect(foundEntry.id);
          setIsFieldFocused(true);
          inputRef && inputRef.current && inputRef.current.focus();
          setOpenDialog(false);
          setOpenPopper(false);
          setIsGridSelected(false);
        }
      }
    }
  };

  const handleFindEntryByValue = (
    inputValue: string
  ): IDatagridRow<T> | null => {
    let foundEntry: IDatagridRow<T> | null = null;

    if ((values[0].cells as any).skells === false) {
      if (findEntryByValueFilter) {
        foundEntry = findEntryByValueFilter(values, selectedId, inputValue);
      } else {
        const entry = Object.entries(values[0].cells as any);
        for (let i = 0; i < entry.length; i++) {
          const columnValue = String(entry[i][1]);
          if (columnValue.toLowerCase().includes(inputValue.toLowerCase())) {
            foundEntry = values[0];
          }
        }
      }
    }

    return foundEntry;
  };

  const handleSelect = (id: string, fromDialog = false) => {
    if (!fromDialog) {
      let entry: IDatagridRow<T> | undefined;
      if (id.length > 0) {
        entry = values.find((x) => x.id === id);
      }

      if (entry) {
        const value: string = displayKey
          ? (entry.cells as any)[displayKey]
          : "Wrong displayKey";

        setSelectedEntry(entry);
        setTextFieldValue(value);
        setIsGridSelected(false);
        setSearchFieldValue("");

        onChange({
          rawInput: value,
          selectedValue: { ...entry.cells, id: entry.id },
        });
      }
    } else {
      setIsGridSelected(true);
      setIsSearchFocused(false);
      setInternalDialogId(id);
    }
  };

  const handleInputChange = (value: string, fromDialog = false) => {
    if (!fromDialog) {
      // update state and open popper
      setTextFieldValue(value);
      setOpenPopper(true);
      onChange({ rawInput: value, selectedValue: null });
    } else {
      setSearchFieldValue(value);
      searchQueryDialog && searchQueryDialog(value);
      setInternalDialogId("");
      setIsSearchFocused(false);
      if (value.length === 0) {
        setIsSearchFocused(true);
      } else {
        setIsGridSelected(false);
      }
    }

    if (filter) {
      setRows(filter(values, value));
    }
  };

  const handleTextFieldBlur = (
    e:
      | React.FocusEvent<HTMLTextAreaElement | HTMLInputElement, Element>
      | undefined
  ) => {
    e && e.preventDefault();
    if (!isGridSelected) {
      // Blur field
      setIsFieldFocused(false);
      onBlur({
        rawInput: textFieldValue,
        selectedValue: {
          ...(selectedEntry ? selectedEntry.cells : null),
          id: selectedEntry ? selectedEntry.id : null,
        },
      });
      onInputBlur && onInputBlur(inputRef);
    }
  };

  const handleGridSort = (
    gridRows: IDatagridRow<any>[],
    key: string,
    direction: SortDirection,
    dataGridRows: IDatagridRow<any>[]
  ): IDatagridRow<any>[] => {
    setGridSort([key, direction]);
    setManualGridSort(true);

    onGridSort && onGridSort(name, key, direction, gridRows);

    const skeletonsArray: IDatagridRow<any>[] = [];

    for (let i = 0; i < rows.length; i++) {
      skeletonsArray.push({
        id: i.toString(),
        cells: {
          skells: true,
        },
      });
    }

    return onGridSort ? skeletonsArray : dataGridRows;
  };

  const handleAbortDialog = (
    ref: React.MutableRefObject<HTMLInputElement | null> | null
  ) => {
    abortFromDialog && abortFromDialog(name);
    setIsGridSelected(false);
    setIsFieldFocused(true);
    ref && ref.current && ref.current.focus();
    setOpenDialog(false);
    setOpenPopper(false);
    setIsSearchFocused(false);
    if (selectedEntry && selectedEntry.id !== internalDialogId) {
      onChange({
        rawInput: textFieldValue,
        selectedValue: { ...selectedEntry.cells, id: selectedEntry.id },
      });
    }
    setInternalDialogId("");
  };

  const handleConfirmDialog = (id: string | null = null) => {
    setIsGridSelected(false);
    setIsFieldFocused(true);
    setInternalDialogId("");
    setOpenDialog(false);
    setOpenPopper(false);
    setIsSearchFocused(false);
    handleSelect(id ? id : internalDialogId);
  };

  const handleFieldWidth = (): string => {
    if (fieldsPerWrapper) {
      const { summary } = fieldsPerWrapper;
      if (summary > 2) {
        return `calc(50% - ${lookupSize === "small" ? "4px" : "8px"})`;
      } else {
        return "100%";
      }
    } else {
      return "100%";
    }
  };

  return (
    <>
      <TextField
        {...rest}
        data-testid="lookup"
        id={name}
        size={lookupSize}
        disabled={disabled}
        required={isRequired}
        className={classes.textFieldCommonStyle}
        inputRef={(e: HTMLInputElement) => {
          refHook && refHook(e);
          inputRef.current = e;
        }}
        autoFocus={autoFocus}
        value={textFieldValue}
        onChange={(e) => handleInputChange(e.target.value)}
        onClick={() => {
          if (textFieldValue.length === 0 && !disabled && isFieldFocused) {
            setOpenPopper(true);
            setIsFieldFocused(true);
            !openPopper && onDataGridFocus();
          }
        }}
        onBlur={handleTextFieldBlur}
        onFocus={(e) => {
          e.target.select();
          setIsFieldFocused(true);
          setIsGridSelected(false);
        }}
        autoComplete="off"
        label={
          label !== undefined ? (
            label.length === 0 ? (
              <ErrorOutlineIcon
                style={{
                  color: "#f44336",
                  transform: "scale(1.2)",
                }}
              />
            ) : (
              <Box
                style={{
                  color: disabled ? theme.palette.text.disabled : "",
                }}
              >
                {label}
              </Box>
            )
          ) : undefined
        }
        InputLabelProps={{
          shrink: true,
        }}
        variant="outlined"
        sx={{ width: handleFieldWidth() }}
        inputProps={{
          tabIndex: tabIndex,
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <>
                {pendingData && <SyncOutlinedIcon />}
                {additionInfoIcon
                  ? additionInfoIcon(
                      textFieldValue ? textFieldValue : "",
                      theme
                    )
                  : null}
                <IconButton
                  size="small"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsFieldFocused(false);
                    setOpenPopper(!openPopper);
                    !openPopper && onDataGridFocus();
                  }}
                  tabIndex={-1}
                  disabled={disabled}
                >
                  {openPopper ? (
                    <ArrowDropUpSharpIcon />
                  ) : (
                    <ArrowDropDownSharpIcon />
                  )}
                </IconButton>
              </>
            </InputAdornment>
          ),
        }}
      />
      {openDialog ? (
        <Dialog
          open={openDialog}
          title={label}
          expandOptions={{
            minWidth: 750,
            onExpand: (value) => {
              setDialogHeight(value.currentHeight);
              setDialogExpanded(value.currentWidth);
            },
          }}
          onSubmit={(ref) => {
            ref && ref.current && ref.current.focus();
            handleConfirmDialog();
          }}
          onCancel={(ref) => handleAbortDialog(ref)}
          fromReference={inputRef}
          isDisabled={internalDialogId.length === 0}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <DialogSearchField
              label={t("components.search")}
              onChange={(e) => handleInputChange(e, true)}
              onFocus={() => {
                setIsFieldFocused(true);
                setIsSearchFocused(true);
              }}
              refElement={(e) => {
                dialogInputRef.current = e;
              }}
            />
            <LookUpFilter
              onFilter={onFilter}
              individualElement={({ isDisabled, isActive, onInteraction }) => (
                <SettingsElement
                  header={
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0.25rem",
                      }}
                    >
                      <TaskAltOutlinedIcon
                        sx={{ height: "18px", width: "18px" }}
                      />
                      {t("timerec.subHeader.tasks.title")}
                    </div>
                  }
                  subtext={t("lib.ui.buttons.lookUpFilter.dialog.caption")}
                  isDisabled={isDisabled}
                  onChange={() => onInteraction()}
                  initialValue={isActive}
                  fullWidth
                />
              )}
            />
          </div>
          <DialogContent
            dividers
            sx={{
              paddingLeft: 0,
              paddingRight: 0,
              height: dialogHeight,
            }}
          >
            <Datagrid
              columns={columns}
              rows={rows}
              selectedId={internalDialogId.length > 0 ? internalDialogId : null}
              onCancel={() => {
                setOpenPopper(false);
                setOpenDialog(false);
                setIsGridSelected(false);
                setIsSearchFocused(false);
              }}
              sortColumn={sortColumn}
              sortDirection={sortDirection}
              onRowSelected={(id) => handleSelect(id, true)}
              isSelected={isGridSelected}
              rowWidth={dialogExpanded - 50}
              onGridSort={(gridRows, columnKey, direction) => (rowFactory) =>
                handleGridSort(gridRows, columnKey, direction, rowFactory)
              }
              markRow={!isFieldFocused}
              infScrollProps={infScrollProps}
              infiniteScroll={infiniteScroll}
              dialogOpen={openDialog}
              markFirstRow={
                internalDialogId.length === 0 &&
                searchFieldValue.length > 0 &&
                isFieldFocused
              }
              handleConfirmDialog={handleConfirmDialog}
              lookUpRef={inputRef}
              rowFactoryFilter={rowFactoryFilter}
              blockClosingCallback={blockClosingCallback}
              disable={disableDatagrid}
              darkMode={darkMode}
              onEmptyRowsRenderer={onEmptyRowsRenderer}
            />
          </DialogContent>
        </Dialog>
      ) : (
        <Popper
          dropShadow={true}
          open={openPopper}
          minHeight={popperHeight}
          anchorEl={inputRef.current?.parentNode}
          onClose={() => {
            setTimeout(() => {
              setOpenPopper(false);
            }, 20);
            setOpenDialog(false);
            setIsGridSelected(false);
          }}
          headerButtons={{
            individual: {},
            filter: {
              onFilter: onFilter,
              size: "medium",
            },
            expand: !hideExpandable
              ? {
                  onExpand: () => {
                    setOpenDialog(true);
                    setIsSearchFocused(true);
                  },
                  muiSvgIcon: <OpenInNewIcon color="info" />,
                  tooltipLabel: t("components.lookup.dialogButton"),
                }
              : {},
          }}
          header={{
            caption: label ? label : "",
          }}
        >
          <Datagrid
            columns={columns}
            rows={rows}
            selectedId={selectedId && selectedId.length > 0 ? selectedId : null}
            onCancel={() => {
              setOpenPopper(false);
              setOpenDialog(false);
              setIsGridSelected(false);
            }}
            sortColumn={sortColumn}
            sortDirection={sortDirection}
            onRowSelected={handleSelect}
            isSelected={isGridSelected}
            rowWidth={550}
            height={popperHeight}
            lookUpRef={inputRef}
            onGridSort={(gridRows, columnKey, direction) => (rowFactory) =>
              handleGridSort(gridRows, columnKey, direction, rowFactory)
            }
            markFirstRow={
              selectedId !== undefined &&
              selectedId !== null &&
              selectedId.length === 0 &&
              textFieldValue !== null &&
              textFieldValue.length > 0 &&
              isFieldFocused
            }
            markRow={!isFieldFocused}
            infiniteScroll={infiniteScroll}
            infScrollProps={infScrollProps}
            rowFactoryFilter={rowFactoryFilter}
            blockClosingCallback={blockClosingCallback}
            disable={disableDatagrid}
            darkMode={darkMode}
            onEmptyRowsRenderer={onEmptyRowsRenderer}
            textFieldHasValue={textFieldValue.length > 0}
          />
        </Popper>
      )}
    </>
  );
}
