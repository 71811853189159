import { Periods } from "@dyce/interfaces";
import { RecordTimeRec } from "@dyce/tnt-api";
import { t } from "i18next";
import { DateTime } from "luxon";

/**
 * Function to translate period value to current language
 * @param value Value from selected period as @type {Periods}
 * @returns String translated to current language
 */
export const handlePeriodText = (
  value: Periods,
  allowWorkweek?: boolean
): string => {
  switch (value) {
    case "day":
      return t("ui.datePagination.periodMenu.day");
    case "week":
      return t("ui.datePagination.periodMenu.week");
    case "workweek": {
      if (allowWorkweek) {
        return t("ui.datePagination.periodMenu.workweek");
      } else {
        return t("ui.datePagination.periodMenu.week");
      }
    }
    case "month":
      return t("ui.datePagination.periodMenu.month");
    case "year":
      return t("ui.datePagination.periodMenu.year");
    default:
      return t("ui.datePagination.periodMenu.week");
  }
};

/**
 * Function to calculate total minutes per day
 * @param param0 Timerecordings as @type {RecordTimeRec[]}
 * @returns Array of numbers with total minutes per day
 */
export const handleTotalMinutes = ({
  recs,
}: {
  recs: RecordTimeRec[];
}): number[] => {
  let minutes: number[] = [];
  const recordsByDate: [string, number[]][] = Object.entries(
    recs.reduce(
      (timeRecs, timeRec) => {
        const date = DateTime.fromISO(timeRec.date).toISODate();
        timeRecs[date] = timeRecs[date]
          ? [...timeRecs[date], timeRec.duration]
          : [timeRec.duration];
        return timeRecs;
      },
      {} as { [key: string]: number[] }
    )
  );
  recordsByDate
    .sort((a, b) => {
      return (
        DateTime.fromISO(a[0]).toMillis() - DateTime.fromISO(b[0]).toMillis()
      );
    })
    .forEach(([_, durations]) => {
      const totalMinutes = durations.reduce((acc, curr) => acc + curr, 0);
      minutes.push(totalMinutes);
    });

  return minutes;
};
