import { FunctionComponent } from "react";
// Helper
import { IPublicClientApplication } from "@azure/msal-browser";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { ComponentTheme, InvalidUser } from "@dyce/interfaces";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Button as MUIButton, Paper, Typography } from "@mui/material";
// Jira
import Button from "@atlaskit/button";
// ADO
import { Button as ADOButton } from "azure-devops-ui/Button";
import { setInvalidGrantLogin, useAppDispatch } from "@dyce/slices";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
      height: "calc(100vh - 64px)",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    paperWrapper: {
      display: "flex",
      flexDirection: "column",
      padding: "3rem",
      width: "50%",
      height: "auto",
      minHeight: "50%",
      [theme.breakpoints.down("sm")]: {
        height: "auto",
        minHeight: "50%",
        width: "80%",
        padding: "1rem",
      },
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      height: "100%",
      width: "100%",
      flex: 1,
      marginTop: "1rem",
      [theme.breakpoints.down("sm")]: {
        marginTop: "3rem",
        justifyContent: "center",
        alignSelf: "flex-end",
      },
    },
  })
);

interface IInvalidGrantInfoProps {
  /**
   * Provide options to be able to loginRedirect with prompt 'select_account';
   * If not provided, login option will not be shown
   */
  loginOptions?: {
    /**
     * Scope for AAD
     */
    aadScope: string;
    /**
     * Msal instance
     */
    instance: IPublicClientApplication;
  };
  /**
   * User info from invalid grant access
   */
  invalidGrant: InvalidUser;
  /**
   * Prefer which design should be loaded (LoadingSpinner)
   * @default muiComponent
   */
  componentTheme?: ComponentTheme;
}

export const InvalidGrantInfo: FunctionComponent<IInvalidGrantInfoProps> = ({
  loginOptions,
  invalidGrant,
  componentTheme = "muiComponent",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { aadScope = "", instance } = loginOptions || {};

  // Handlers
  const handleLogin = () => {
    const query = new URLSearchParams(location.search);
    if (query.get("usernotfound")) {
      history.push("/");
    }
    if (window.self !== window.top) {
      // In iFrame -> clear sessionStorage and loginPopup
      sessionStorage.clear();
      dispatch(setInvalidGrantLogin(null));
      instance &&
        instance.loginPopup({
          scopes: ["profile", "openid", aadScope],
          prompt: "select_account",
        });
    } else {
      instance &&
        instance.loginRedirect({
          scopes: ["profile", "openid", aadScope],
          prompt: "select_account",
        });
    }
  };

  return (
    <div className={classes.container}>
      <Paper className={classes.paperWrapper}>
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <Typography variant="h6">
            {t("login.invalid.header", { name: invalidGrant.name })}
          </Typography>
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.5rem" }}
          >
            <Typography>
              {t("login.invalid.info.start", { email: invalidGrant.email })}
            </Typography>
            <Typography sx={{ fontWeight: "500" }} noWrap>
              {invalidGrant.email}
            </Typography>
            <Typography sx={{ whiteSpace: "pre-line" }}>
              {invalidGrant.invalidGrant
                ? t("login.invalid.info.userNotValid")
                : t("login.invalid.info.userNotFound")}
            </Typography>
          </div>
        </div>
        {loginOptions && (
          <div className={classes.buttonContainer}>
            {componentTheme === "muiComponent" ? (
              <MUIButton
                variant="outlined"
                color="primary"
                onClick={handleLogin}
              >
                {t("login.signup")}
              </MUIButton>
            ) : componentTheme === "jiraComponent" ? (
              <Button appearance="primary" onClick={handleLogin}>
                {t("login.signup")}
              </Button>
            ) : (
              <ADOButton
                onClick={handleLogin}
                primary
                text={t("login.signup")}
              />
            )}
          </div>
        )}
      </Paper>
    </div>
  );
};
