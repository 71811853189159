/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
// Helper
import { Switch, Route } from "react-router-dom";
// Dyce-Lib
import { ProtectedRoute, useStaticContent } from "@dyce/ui";
import {
  selectIsInstanceValidating,
  selectWorkspaceLoaded,
  useAppSelector,
} from "@dyce/slices";
// Components
import { Logout } from "./pages/logout";
import { Home } from "./pages/home";
import { TimeRecMenuManagement } from "./pages/timerecording";
import { Administration } from "./pages/admin/Administration";
import { Settings } from "./pages/settings";
import { NotFound } from "./pages";
import { Instance } from "./pages/instance";
import { InstanceError } from "./pages/instance/Error";
import { UserListProvider } from "./components/admin/userList/context/userContext";

export default function Routes(): React.ReactElement {
  const isLoaded = useAppSelector(selectWorkspaceLoaded);
  const isValidating = useAppSelector(selectIsInstanceValidating);
  const { routes } = useStaticContent();

  return (
    <Switch>
      <Route exact={true} path={routes.LOGOUT} component={Logout} />
      <Route
        exact={false}
        path={routes.SETTINGS}
        component={isLoaded && !isValidating ? Settings : Instance}
      />
      <Route
        exact={true}
        path={routes.HOME}
        component={isLoaded && !isValidating ? Home : Instance}
      />
      <ProtectedRoute
        exact={false}
        path={routes.ADMIN}
        domain={"admin"}
        component={isLoaded && !isValidating ? WrappedAdmin : Instance}
      />
      <ProtectedRoute
        exact={false}
        path={routes.TNT}
        domain={"timerec"}
        component={isLoaded && !isValidating ? TimeRecMenuManagement : Instance}
      />
      <Route exact path={routes.ERROR} component={InstanceError} />
      <Route exact={true} path="*" component={NotFound} />
    </Switch>
  );
}

const WrappedAdmin = () => (
  <UserListProvider>
    <Administration />
  </UserListProvider>
);
