/**
 *
 * @param val
 * @returns
 */
export const parseValue = (val: number) => {
  if (isNaN(val)) {
    return "0:00";
  }

  let result = parseInput(val.toString());
  result = parseCommaSeparator(result);

  return result;
};

/**
 *
 * @param val
 * @returns
 */
export const parsePropsInMinutes = (val: number) => {
  let hours = 0;
  let minutes = val;
  let isNegative = false;

  if (minutes < -59) {
    minutes = minutes * -1;
    isNegative = true;
  }

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
  }

  return `${isNegative ? "-" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}`;
};

/**
 *
 * @param val
 * @returns
 */
export const parseBackToDec = (val: string) => {
  const hoursMinutes = val.split(/[.:]/);
  const hours = parseInt(hoursMinutes[0], 10) * 60;
  const minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;

  if (isNaN(hours) || isNaN(minutes)) {
    return 0;
  }

  return hours + minutes;
};

/**
 * Checks if given time string is correctly formatted
 * @param val time string
 * @returns true if valid input
 */
export const checkDurationInput = (val: string) => {
  const regexp = /^\d+?:?\d{0,2}$/;

  const [hoursStr, minutesStr] = val.split(":");

  if (!regexp.test(val)) {
    return false;
  }

  const hours = Number(hoursStr);
  const minutes = Number(minutesStr);

  const isValidHour = (hour: number) =>
    Number.isInteger(hour) && hour >= 0 && hour <= 24;
  const isValidMinutes = (minutes: number) =>
    (Number.isInteger(minutes) && hours >= 0 && hours <= 24) ||
    Number.isNaN(minutes);

  if (!isValidHour(hours) || !isValidMinutes(minutes)) {
    return false;
  }

  if (minutes < 10 && Number(minutesStr[0]) > 5) {
    return false;
  }

  const valArr = val.indexOf(":") !== -1 ? val.split(":") : [val];

  // check mm and HH
  if (
    valArr[0] &&
    valArr[0].length &&
    (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 24)
  ) {
    return false;
  }

  if (
    valArr[1] &&
    valArr[1].length &&
    (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)
  ) {
    return false;
  }

  return true;
};

/**
 * Parse for the minute inidicator
 * @param val time string
 * @returns parsed string
 */
export const checkForMinuteToken = (val: string) => {
  const regexp = /^(\d+?)([m]{1})$/gi;

  const result = Array.from(val.matchAll(regexp), (m) => m[1]);

  if (result.length === 0) {
    return val;
  }
  let hours = 0;
  let minutes = parseInt(result[0]);

  if (isNaN(minutes)) {
    throw Error(`IsNaN: minutes=${minutes}`);
  }

  if (minutes > 59) {
    hours = Math.floor(minutes / 60);
    minutes = minutes % 60;
  }

  return `${hours}:${minutes < 10 ? "0" : ""}${minutes}`;
};

/**
 * Parses manual input without seperator
 * @param val time input
 * @returns parsed time
 */
export const parseInput = (val: string) => {
  const input = Number(val);
  if (Number.isNaN(input) || val.includes(".") || val.includes(",")) {
    return val;
  }

  // format val if already in correct length
  if (val.length === 4) {
    if (val.startsWith("0")) {
      return `${val.slice(1, 2)}:${val.slice(2, 4)}`;
    } else {
      return `${val.slice(0, 2)}:${val.slice(2, 4)}`;
    }
  }

  // if 0 at beginning
  if (val.startsWith("0")) {
    let minutes = val.slice(1, 3);
    if (minutes.length === 1) {
      minutes = minutes + "0";
    }

    return `0:${minutes.length == 0 ? "00" : minutes}`;
  }

  // if full format (h:mm); add leading 0
  if (val.length === 3) {
    return `${val.slice(0, 1)}:${val.slice(1, 4)}`;
  }

  if (val.length < 3 && val.length > 0) {
    return `${val}:00`;
  } else {
    return "0:00";
  }
};

/**
 * Parse comma and point seperator from value string
 * @param val Time string
 * @returns parsed string
 */
export const parseCommaSeparator = (val: string) => {
  if (!val.includes(",") && !val.includes(".")) {
    return val;
  }

  let time: any = val.replace(",", ".");
  time = Number(time);

  if (Number.isNaN(time)) return val;

  const hours = Math.floor(time).toString();
  const minutes = Math.floor((time * 60) % 60).toString();

  return `${hours.length == 1 ? "0" : ""}${hours}:${
    minutes.length === 1 ? "0" : ""
  }${minutes}`;
};
