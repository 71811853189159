import React, { useState, useEffect, FunctionComponent } from "react";
// MUI
import { ListItem, ListItemAvatar, ListItemText, Switch } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
      paddingLeft: "1rem",
      paddingRight: "1rem",
      height: "100%",
      minHeight: "5rem",
    },
    avatarContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "50%",
    },
    avatar: {
      display: "flex",
      justifyContent: "center",
    },
    listItem: {
      flex: 1,
      marginRight: "4rem",
    },
  })
);

interface ISettingsElementProps {
  /**
   * The initial value to display. Passing a boolean will change the icon to be a toggle switch
   */
  initialValue: boolean | string;
  /**
   * Optionally provide an icon. Will not be displayed if the initial value is a boolean.
   */
  icon?: React.ReactNode;
  /**
   * Optionally provide a custom Element, that will be appended
   */
  renderItem?: (initialValue: any) => React.ReactElement;
  /**
   * Header text
   */
  header: string | JSX.Element;
  /**
   * Optional explanatory sub text
   */
  subtext?: string | JSX.Element;
  /**
   * If true, switch is disabled
   * @default false
   */
  isDisabled?: boolean;
  /**
   * If true, the element will take up the full width of the container
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Callback when the setting value changes
   */
  onChange: (v: any) => void;
}

export const SettingsElement: FunctionComponent<ISettingsElementProps> = ({
  initialValue,
  icon,
  isDisabled = false,
  fullWidth = false,
  onChange,
  renderItem,
  header,
  subtext = "",
}) => {
  const classes = useStyles();

  // States
  const [value, setValue] = useState(initialValue);

  // UseEffects
  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // Handler
  const handleValueChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    setValue(checked);
    onChange(checked);
  };

  return (
    <ListItem
      className={classes.listContainer}
      sx={
        fullWidth
          ? {
              width: "auto",
              minHeight: "0px",
              paddingRight: "0px",
            }
          : {}
      }
    >
      <div
        className={classes.avatarContainer}
        style={
          fullWidth
            ? {
                width: "100%",
              }
            : {}
        }
      >
        <ListItemAvatar className={classes.avatar}>
          {typeof initialValue === "boolean" ? (
            <Switch
              disabled={isDisabled}
              onChange={handleValueChange}
              checked={value as boolean}
            />
          ) : (
            icon
          )}
        </ListItemAvatar>
        <ListItemText
          className={classes.listItem}
          primary={header}
          secondary={subtext}
          sx={
            fullWidth
              ? {
                  marginRight: "0px",
                }
              : {}
          }
        />
      </div>
      {renderItem && renderItem(initialValue)}
    </ListItem>
  );
};
