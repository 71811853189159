// Helper
import _ from "lodash";
// Dyce-Lib
import { RecordTemplate } from "@dyce/tnt-api";

/**
 * Build a informationstring from values in Template items
 * @param record TemplateArray from Backend
 * @returns Complete information from Template as string
 */
export const getTemplateInfo = (record: RecordTemplate): string => {
  // Customer- ProjectFields
  const customer: string = record.customer ? `${record.customer.name}, ` : "";
  const job: string = record.job ? `${record.job.description}, ` : "";
  const jobTask: string = record.jobTask
    ? `${record.jobTask.description}, `
    : "";
  const projecting = `${customer}${job}${jobTask}`;

  const finalStringInfo = `${projecting}`.trimEnd();

  return finalStringInfo.substring(0, finalStringInfo.length - 1);
};

/**
 *
 * @param record
 * @param value
 * @returns
 */
export const handleTemplateListSearch = (
  record: [string, RecordTemplate[]][],
  value: string
): [string, RecordTemplate[]][] => {
  let onlyCategories: string[] = [];
  for (let i = 0; i < record.length; i++) {
    onlyCategories = [...onlyCategories, record[i][0]];
    // onlyCategories.concat(record[i][0]);
  }

  const records: RecordTemplate[][] = record.map(([, recs]) =>
    recs.filter(
      (rec) =>
        (rec.description &&
          rec.description.toLowerCase().includes(value.toLowerCase())) ||
        (rec.customer?.name &&
          rec.customer.name.toLowerCase().includes(value.toLowerCase())) ||
        (rec.job?.description &&
          rec.job.description.toLowerCase().includes(value.toLowerCase())) ||
        (rec.jobTask?.description &&
          rec.jobTask.description.toLowerCase().includes(value.toLowerCase()))
    )
  );

  let recordList: [string, RecordTemplate[]][] = [];
  for (let i = 0; i < records.length; i++) {
    if (records[i].length > 0) {
      recordList = [...recordList, [onlyCategories[i], records[i]]];
      // recordList.concat([onlyCategories[i], records[i]]);
    }
  }

  const categoryList: [string, RecordTemplate[]][] = record.filter(([cat]) =>
    cat.toLowerCase().includes(value.toLowerCase())
  );

  let finalList: [string, RecordTemplate[]][] = [];

  if (recordList.length === 0 && categoryList.length > 0) {
    finalList = [...categoryList];
    // finalList.concat(categoryList);
  } else if (recordList.length > 0 && categoryList.length === 0) {
    finalList = [...recordList];
    // finalList.concat(recordList);
    // console.log(recordList, categoryList);
  } else if (recordList.length > 0 && categoryList.length > 0) {
    // Combine both Arrays, with categoryList as prio against recordList
    for (let i = 0; i < categoryList.length; i++) {
      for (let j = 0; j < recordList.length; j++) {
        if (categoryList[i][0] === recordList[j][0]) {
          finalList = [...finalList, categoryList[i]];
          // finalList.concat(categoryList[i]);
        }
      }
    }

    // Bring rest of categories in finalList
    for (let i = 0; i < categoryList.length; i++) {
      for (let j = 0; j < recordList.length; j++) {
        if (categoryList[i][0] !== recordList[j][0]) {
          finalList = [...finalList, categoryList[i]];
          // finalList.concat(categoryList[i]);
          break;
        }
      }
    }

    // Bring rest of recordList in finalList
    for (let i = 0; i < recordList.length; i++) {
      for (let j = 0; j < categoryList.length; j++) {
        if (recordList[i][0] !== categoryList[j][0]) {
          finalList = [...finalList, recordList[i]];
          // finalList.concat(recordList[i]);
          break;
        }
      }
    }
  }
  finalList = _.uniqWith(finalList, _.isEqual);

  finalList.sort((a, b) => {
    if (a[0].toLocaleLowerCase() < b[0].toLocaleLowerCase()) {
      return -1;
    }
    if (a[0].toLocaleLowerCase() > b[0].toLocaleLowerCase()) {
      return 1;
    }
    return 0;
  });

  // Last check for double Category, splice first hit
  for (let i = 0; i < finalList.length; i++) {
    for (let j = 1; j < finalList.length - 1; j++) {
      if (
        finalList[i][0] === finalList[j][0] &&
        finalList[i][1].length !== finalList[j][1].length
      ) {
        finalList.splice(j, 1);
        break;
      }
    }
  }

  return finalList;
};
