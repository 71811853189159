// Helpers
import { t } from "i18next";
// Dyce-Lib
import { getTitle } from "@dyce/utils";

/**
 * Format the description of the data to show in the chart
 * @param description Description of the data to show in the chart
 * @param fromBillable If true, the description is a billable title
 * @param showNoData If true, the description is "No data" (empty array)
 * @returns string
 */
export const handleDescription = ({
  description,
  fromBillable,
  showNoData = false,
  sliceLongNames = false,
}: {
  description: string | undefined;
  fromBillable: boolean;
  showNoData: boolean;
  sliceLongNames: boolean;
}) => {
  if (!showNoData) {
    if (fromBillable && description) {
      return getTitle(description, true);
    } else if (description === "Others") {
      return t("timerec.dashboard.statistic.others");
    } else {
      return description && description.length > 17 && sliceLongNames
        ? description.slice(0, 14) + "..."
        : description;
    }
  } else {
    return t("timerec.dashboard.statistic.noData");
  }
};
