import React, { FunctionComponent, useState, MouseEvent } from "react";
// Helpers
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
// Dyce-Lib
import { PopOverMenu, Tooltip, useStaticContent } from "@dyce/ui";
import { DyceTheme } from "@dyce/theme";
import { ResourceCapacity, TemplateCategory } from "@dyce/tnt-api";
import { selectLanguageCode, useAppSelector } from "@dyce/slices";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Box,
  Divider,
  IconButton,
  MenuItem,
  Typography,
  useTheme,
} from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import MoreVertIcon from "@mui/icons-material/MoreVert";
// Components
import { ItemEntriesProgress } from "./components";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    containerDate: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      height: theme.palette.propsDyce.timeRecItemHeight.commonHeaderHeight,
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    date: {
      display: "inherit",
      minWidth: "262px",
      height: "2rem",
      marginLeft: "1rem",
      flexDirection: "row",
      alignItems: "center",
      marginRight: "10px",
    },
    dateText: {
      paddingTop: "1px",
      paddingLeft: "1rem",
    },
    contextMenu: {
      width: 50,
    },
    time: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      paddingRight: "6.5rem",
    },
    addButton: {
      display: "inherit",
      "& .MuiIconButton-root": {
        backgroundColor: theme.palette.primary.main,
        "&:hover": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    buttonPlus: {
      color: theme.palette.primary.contrastText,
    },
  })
);

interface IRecordListHeaderProps {
  /**
   * If true, Tooltip for 'plus-button' will be adjusted for templates
   */
  isTemplate?: boolean;
  /**
   * Date string provided as format 'yyyy-MM-dd'
   */
  date: string;
  /**
   * Header text for templates provided as @type {TemplateCategory}
   */
  headerText?: TemplateCategory;
  /**
   * Total hours for summary hour circle
   */
  totalHours: number;
  /**
   * If true, summary hour circle will be shown, also 'plus-button' if no individual Icon
   * is provided
   */
  hasAdditions?: boolean;
  /**
   * Capacity from current user
   */
  resourceCapacity?: ResourceCapacity[];
  /**
   * Props for optional context menu
   */
  contextMenu?: {
    /**
     * Name for menu
     */
    name: string;
    /**
     * Show Icon in front of menu name
     */
    icon?: JSX.Element;
    /**
     * If true, time-tracking-tool will be shown at onClick event
     */
    onClick?: boolean;
    /**
     * Callback fired when menu is clicked and provides prop name
     */
    action: (name: TemplateCategory | null) => void;
  }[];
  /**
   * Callback fired when 'plus-button' is clicked
   */
  openEditor: () => void;
  /**
   * If true, 'plus-button' is disabled
   */
  disableButton?: boolean;
  /**
   * If true, divider will be shown below header container
   */
  hasEntries: boolean;
  /**
   * If true, header will be shown in primary color gradient
   * @default false
   */
  markHeader?: boolean;
  /**
   * If provided an individual Icon, 'plus-icon' will not be shown
   */
  individualIcon?: JSX.Element;
  /**
   * If provided an individual element, it will be shown on right side of header bar
   */
  individualAddition?: (value: number) => JSX.Element;
}

export const RecordListHeader: FunctionComponent<IRecordListHeaderProps> = ({
  date,
  headerText,
  totalHours,
  openEditor,
  contextMenu,
  resourceCapacity,
  hasAdditions = true,
  disableButton = false,
  hasEntries,
  isTemplate = false,
  markHeader = false,
  individualIcon,
  individualAddition,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<DyceTheme>();
  const { docuLinks } = useStaticContent();

  // Selectors
  const language = useAppSelector(selectLanguageCode);

  // States
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpenMenuClick = (event: MouseEvent<HTMLElement>) => {
    //setDisableMenuButton(false);
    const contextMenu: EventTarget & HTMLElement = event.currentTarget;
    setAnchorEl(contextMenu);
  };

  return (
    <>
      <Box
        className={classes.containerDate}
        sx={{
          background:
            date === DateTime.now().toFormat("yyyy-MM-dd") || markHeader
              ? theme.palette.propsTimeTracking.dayHeader.gradientToday
              : theme.palette.propsTimeTracking.dayHeader.gradient,
        }}
        data-testid="container-header"
      >
        <div className={classes.date}>
          {hasAdditions && !individualIcon && (
            <div className={classes.addButton}>
              <Tooltip
                label={
                  isTemplate
                    ? t("timerec.myTime.templates.createNewTemplate")
                    : t("timerec.dailyRecording.createTimeRec")
                }
                urlPath={
                  docuLinks.timetracking.timerecordings.records.newRecordButton
                }
                position="bottom-end"
              >
                <span>
                  <IconButton
                    aria-label="add"
                    size="small"
                    onClick={() => openEditor()}
                    disabled={disableButton}
                    sx={{ padding: "3px" }}
                  >
                    <AddOutlinedIcon
                      fontSize="inherit"
                      className={classes.buttonPlus}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </div>
          )}
          {individualIcon && individualIcon}
          <Typography
            className={classes.dateText}
            color={
              date.length > 0 &&
              date === DateTime.now().toFormat("yyyy-MM-dd") &&
              theme.palette.mode === "dark"
                ? theme.palette.primary.contrastText
                : undefined
            }
            fontWeight={
              date.length > 0 && date === DateTime.now().toFormat("yyyy-MM-dd")
                ? 600
                : ""
            }
          >
            {date.length > 0
              ? date === DateTime.now().toFormat("yyyy-MM-dd")
                ? t("timerec.dailyRecording.today")
                : DateTime.fromFormat(date, "yyyy-MM-dd", {
                    locale: language,
                  })
                    .toFormat("DDDD")
                    .slice(0, language === "en" ? -6 : -5)
              : headerText?.name}
          </Typography>
        </div>
        {/* Contextmenu for TemplateHeader shown on right-end */}
        {contextMenu && (
          <div className={classes.contextMenu}>
            <IconButton aria-label="more-vert" onClick={handleOpenMenuClick}>
              <MoreVertIcon />
            </IconButton>
            <PopOverMenu
              id="contextMenu"
              onClose={setAnchorEl}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
            >
              {contextMenu.map((c, i) => (
                <MenuItem
                  key={i}
                  onClick={() => {
                    c.action(headerText ? headerText : null);
                    setAnchorEl(null);
                    c.onClick && openEditor();
                  }}
                >
                  {c.icon && c.icon}
                  {c.name}
                </MenuItem>
              ))}
            </PopOverMenu>
          </div>
        )}
        {hasAdditions && !contextMenu && !individualAddition && (
          <Tooltip
            label={t("timerec.dailyRecording.totalHours")}
            urlPath={docuLinks.timetracking.timerecordings.records.totalHours}
            position="left"
          >
            <div className={classes.time}>
              <ItemEntriesProgress
                date={date ? date : ""}
                duration={totalHours}
                asSummary={true}
                resourceCapacity={resourceCapacity ? resourceCapacity : null}
              />
            </div>
          </Tooltip>
        )}
        {individualAddition && individualAddition(totalHours)}
      </Box>
      {hasEntries && <Divider />}
    </>
  );
};
