import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UIStateSlice } from "../../types/types";

const initialState: UIStateSlice = {
  previousLanguage: null,
  userMenu: {
    visible: false,
    anchor: "right",
  },
  drawerLeft: {
    open: true,
  },
  drawerRight: {
    open: false,
  },
  drawerTop: {
    open: false,
    canConfirm: false,
    confirmed: false,
    fakeAccounts: 0,
  },
  dialogAadUsers: {
    open: false,
  },
  createUserDialog: {
    open: false,
  },
  updateUserRolesDialog: {
    open: false,
  },
  currentStep: 0,
  randomNumber: null,
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    // App
    toggleDialogAadUser(state) {
      state.dialogAadUsers.open = !state.dialogAadUsers.open;
    },
    toggleDrawerTop(state) {
      state.drawerTop.open = !state.drawerTop.open;
    },
    drawerTopCanConfirm(state, action: PayloadAction<boolean>) {
      state.drawerTop.canConfirm = action.payload;
    },
    drawerTopConfirmed(state, action: PayloadAction<boolean>) {
      state.drawerTop.confirmed = action.payload;
    },
    openCreateUserDialog(state, action: PayloadAction<boolean>) {
      state.createUserDialog.open = action.payload;
    },
    openUpdateUserRolesDialog(state, action: PayloadAction<boolean>) {
      state.updateUserRolesDialog.open = action.payload;
    },
    toggleUserMenu(state) {
      state.userMenu.visible = !state.userMenu.visible;
    },
    toggleDrawerRight(state) {
      state.drawerRight.open = !state.drawerRight.open;
    },
    // Onboarding
    setNextStep(state) {
      if (state.currentStep <= 3) {
        state.currentStep += 1;
      }
    },
    setPreviousStep(state) {
      if (state.currentStep > 1) {
        state.currentStep -= 1;
      } else {
        state.currentStep = 0;
      }
    },
    setStep(state, action) {
      if (action.payload == null || (action.payload > 0 && action.payload <= 4))
        state.currentStep = action.payload;
    },
    setRandomNumber(state, action) {
      state.randomNumber = action.payload;
    },
  },
});

export const {
  toggleDialogAadUser,
  toggleDrawerTop,
  drawerTopCanConfirm,
  drawerTopConfirmed,
  openCreateUserDialog,
  openUpdateUserRolesDialog,
  toggleUserMenu,
  toggleDrawerRight,
  setNextStep,
  setPreviousStep,
  setStep,
  setRandomNumber,
} = uiSlice.actions;

export default uiSlice.reducer;
