import React, { FunctionComponent, useEffect, useState } from "react";
// Helper
import { filterUser, EmptyRow } from "./utils";
// Dyce-Lib
import { useDebounce, useUpdateEffect } from "@dyce/hooks";
import { AadUser } from "@dyce/tnt-api";
import { SearchBox } from "../../inputs/searchbox/searchbox";
// MUI
import { makeStyles } from "@mui/styles";
// Components
import ReactDataGrid, { Column, SelectColumn } from "react-data-grid";

const useStyles = makeStyles(() => ({
  search: {
    marginBottom: 10,
  },
}));

/**
 * Represents a list entry holding AAD users
 */
interface Row {
  /**
   * AAD user ID
   */
  id: string;
  /**
   * Human readable identifier
   */
  displayName: string;
  /**
   * If given user is already in the DYCE DBs
   */
  isDyceUser: boolean;
  /**
   * User Mail Address
   */
  mail: string;
}

interface AADListProps {
  /**
   * Data to display
   */
  data: AadUser[];
  /**
   * Darkmode enabled
   */
  darkmode?: boolean;
  /**
   * Callback when user makes a selection, provides the selected users as a Set
   */
  onChange: (selected: Set<AadUser>) => void;
  /**
   * Callback when the component requests the data
   */
  onSearch: (search: string) => void;
}

/**
 * List for AAD Users
 */
export const AADList: FunctionComponent<AADListProps> = ({
  data,
  darkmode = false,
  onChange,
  onSearch,
}) => {
  const classes = useStyles();

  // States
  const [selectedRows, setSelectedRows] = useState<Set<React.Key>>();

  const { debouncedValue, setValue } = useDebounce("", 200);

  //Theme
  const darkTheme: string = darkmode ? "rdg-dark" : "rdg-light";

  // UseEffects
  useUpdateEffect(() => {
    onSearch(debouncedValue);
  }, [debouncedValue]);

  const columns: readonly Column<Row>[] = [
    SelectColumn,
    { key: "id", name: "ID", width: 80, resizable: true },
    { key: "displayName", name: "Name" },
    { key: "mail", name: "Mail" },
  ];

  return (
    <>
      <SearchBox onChange={setValue} className={classes.search} />
      <ReactDataGrid
        className={`${darkTheme}`}
        style={{ minWidth: 620 }}
        columns={columns}
        rows={data.map((v) => ({ ...v, checkBox: v.isDyceUser }))}
        rowKeyGetter={(r) => r.id}
        onSelectedRowsChange={(set) => {
          onChange(filterUser(set, data));
          setSelectedRows(set);
        }}
        selectedRows={selectedRows}
        emptyRowsRenderer={EmptyRow}
      />
    </>
  );
};
