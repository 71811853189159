import { FunctionComponent } from "react";
// Azure
import { Button as ADOButton } from "azure-devops-ui/Button";
// MUI
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import { IconButton } from "@mui/material";
// Jira
import SignOutIcon from "@atlaskit/icon/glyph/sign-out";
import Button from "@atlaskit/button";

type ILogoutButtonProps = {
  componentTheme: "materialUI" | "azureComponents" | "jiraComponent";
  handleLogout: () => void;
};

export const LogoutButton: FunctionComponent<ILogoutButtonProps> = ({
  componentTheme,
  handleLogout,
}) => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: "1rem",
        right: "1rem",
      }}
    >
      {componentTheme === "materialUI" ? (
        <IconButton
          aria-label="Logout"
          onClick={() => handleLogout()}
          color="inherit"
        >
          <LogoutOutlinedIcon />
        </IconButton>
      ) : componentTheme === "azureComponents" ? (
        <ADOButton
          ariaLabel="Logout"
          iconProps={{ iconName: "ChromeClose" }}
          onClick={() => handleLogout()}
        />
      ) : (
        <Button
          aria-label="Logout"
          iconBefore={<SignOutIcon label="" size="medium" />}
          appearance="default"
          onClick={() => handleLogout()}
        />
      )}
    </div>
  );
};
