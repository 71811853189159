import { FunctionComponent, useEffect, useRef, useState } from "react";
// RTK
import { unwrapResult } from "@reduxjs/toolkit";
// Helper
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { Controller, useForm } from "react-hook-form";
// Dyce-Lib
import {
  AutoComplete,
  ConsentInfo,
  DateFieldMoment,
  RadioButtons,
  SettingsElement,
  SettingsGroup,
  SubHeader,
  TimeTrackingTool,
  useStaticContent,
} from "@dyce/ui";
import {
  getRecsByRange,
  selectDarkMode,
  selectLanguageCode,
  selectLocaleHyphen,
  selectTasksGroupedBy,
  selectTimeRecordingSettings,
  selectTimeTrackingToolDesign,
  setTaskGrouping,
  setTimerecDashboardWOCapacity,
  setTimeTrackingDesign,
  TaskSettings,
  useAppDispatch,
  useAppSelector,
} from "@dyce/slices";
import { JobTaskStatusOptions, NonBillableReasonOptions } from "@dyce/tnt-api";
import { msalInstance } from "@dyce/auth";
import { DesignOptions } from "@dyce/interfaces";
// MUI
import { Share } from "@mui/icons-material";
import { createStyles, makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

const useStyles = makeStyles(() =>
  createStyles({
    dateField: {
      maxWidth: 200,
      marginRight: 10,
    },
    row: {
      display: "flex",
    },
  })
);

export const TimeRecSettings: FunctionComponent = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { docuLinks } = useStaticContent();
  const history = useHistory();

  // Set scope for calendar access
  const scopes = ["Calendars.Read"];

  const { control, handleSubmit } = useForm();

  // Refs
  const calendarRef = useRef<HTMLDivElement | null>(null);

  // Selectors
  const languageCode = useAppSelector(selectLanguageCode);
  const timeRecordingFormValue = useAppSelector(selectTimeTrackingToolDesign);
  const localHyphen = useAppSelector(selectLocaleHyphen);
  const darkMode = useAppSelector(selectDarkMode);

  // States
  const [consentGranted, setConsentGranted] = useState<boolean | undefined>(
    undefined
  );

  // UseEffects
  useEffect(() => {
    // Consent check
    handleConsentCheck();
  }, []);

  useEffect(() => {
    let scrollTimer: NodeJS.Timeout;
    if (
      calendarRef.current &&
      history.location.search === "?highlight=calendarsettings"
    ) {
      scrollTimer = setTimeout(() => {
        calendarRef.current?.scrollIntoView();
      }, 300);
    }
    return () => {
      clearTimeout(scrollTimer);
    };
  }, [calendarRef, history.location.search]);

  // Handler
  const handleConsentCheck = async () => {
    // Check if the user has already given consent
    try {
      const res = await msalInstance.acquireTokenSilent({
        scopes,
      });
      if (res.scopes.length > 0 && scopes.includes(scopes[0])) {
        setConsentGranted(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleExport = (data: any) => {
    console.log(data);

    dispatch(getRecsByRange({ start: data.start, end: data.end }))
      .then(unwrapResult)
      .then((res) => {
        const binaryData = [];
        binaryData.push(JSON.stringify(res.value));

        const href = URL.createObjectURL(
          new Blob(binaryData, { type: "application/json" })
        );
        const link = document.createElement("a");
        link.href = href;
        link.download = "export.json";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch(console.error);
  };

  const {
    dashboard: { showDaysWithoutCapacity },
  } = useAppSelector(selectTimeRecordingSettings);
  const groupedBy = useAppSelector(selectTasksGroupedBy);

  const getGroupName = (val: string) => {
    switch (val) {
      case TaskSettings.CUSTOMER:
        return t("settings.timerecs.tasks.grouping.values.customer");

      case TaskSettings.JOB:
        return t("settings.timerecs.tasks.grouping.values.job");

      case TaskSettings.STARTDATE:
        return t("settings.timerecs.tasks.grouping.values.startDate");

      case TaskSettings.ENDDATE:
        return t("settings.timerecs.tasks.grouping.values.endDate");

      default:
        return t("settings.timerecs.tasks.grouping.values.customer");
    }
  };

  const handleSelectedGrouping = (grouping: TaskSettings) => {
    dispatch(setTaskGrouping(grouping));
  };

  const demoRecord = {
    id: "1",
    date: DateTime.now().toISODate(),
    start: DateTime.now()
      .set({
        hour: 8,
        minute: 0,
        second: 0,
      })
      .toISO(),
    end: DateTime.now()
      .set({
        hour: 10,
        minute: 0,
        second: 0,
      })
      .toISO(),
    description: t(
      "settings.timerecs.timeRecordingForm.demoRecord.description"
    ),
    customer: {
      id: "12",
      name: t("settings.timerecs.timeRecordingForm.demoRecord.customer"),
      no: "demo-customer",
    },
    job: {
      id: "12",
      description: t("settings.timerecs.timeRecordingForm.demoRecord.job"),
      no: "demo-job",
    },
    jobTask: {
      id: "123",
      description: t("settings.timerecs.timeRecordingForm.demoRecord.jobTask"),
      no: "demo-task",
      status: JobTaskStatusOptions.OPEN,
      job: {
        id: "12",
        description: "Demo Job",
        no: "demo-job",
      },
      jobPlanningLine: null,
    },
    break: 0,
    duration: 120,
    durationBillable: 120,
    nonBillableReason: NonBillableReasonOptions.NONE,
    jobPlanningLine: null,
    tntModelLine: null,
  };

  const handleRadioButtonLabel = (val: DesignOptions): string => {
    switch (val) {
      case DesignOptions.ALL:
        return t("settings.timerecs.timeRecordingForm.design.values.all");
      case DesignOptions.COMPACT:
        return t("settings.timerecs.timeRecordingForm.design.values.compact");
      case DesignOptions.SIMPLE:
        return t("settings.timerecs.timeRecordingForm.design.values.simple");
      case DesignOptions.DYNAMIC:
        return t("settings.timerecs.timeRecordingForm.design.values.dynamic");
      default:
        return "Missing ENUM for new DesignOption";
    }
  };

  return (
    <>
      <SubHeader
        tooltipLabel={t("settings.header.timerec.info")}
        tooltipUrlPath={docuLinks.settings.tntSettings.subheader.info}
        title={t("settings.header.timerec.title")}
        subtitle={t("settings.header.timerec.subtitle")}
        honorMargin={true}
      />
      <SettingsGroup
        anchor="timeRecordingForm"
        groupHeader={t("settings.timerecording.groupHeader.timeTrackingForm")}
      >
        <div
          style={{
            display: "flex",
            padding: "1rem 2rem",
          }}
        >
          <RadioButtons<DesignOptions>
            tooltip={{
              tooltipLabels: [
                t("settings.timerecs.timeRecordingForm.tooltipLabels.all"),
                t("settings.timerecs.timeRecordingForm.tooltipLabels.compact"),
                t("settings.timerecs.timeRecordingForm.tooltipLabels.simple"),
                t("settings.timerecs.timeRecordingForm.tooltipLabels.dynamic"),
              ],
              urlPath: docuLinks.settings.tntSettings.timeTrackingForm,
            }}
            buttonLabels={[
              {
                id: DesignOptions.ALL,
                label: handleRadioButtonLabel(DesignOptions.ALL),
              },
              {
                id: DesignOptions.COMPACT,
                label: handleRadioButtonLabel(DesignOptions.COMPACT),
              },
              {
                id: DesignOptions.SIMPLE,
                label: handleRadioButtonLabel(DesignOptions.SIMPLE),
              },
              {
                id: DesignOptions.DYNAMIC,
                label: handleRadioButtonLabel(DesignOptions.DYNAMIC),
              },
            ]}
            value={timeRecordingFormValue}
            onChange={(e) => dispatch(setTimeTrackingDesign(e))}
          />
        </div>
        <div
          style={{
            padding: "0 2rem 1rem 2rem",
            minWidth: 1000,
            height: "100%",
          }}
        >
          <TimeTrackingTool
            allowClickAway={false}
            isDemo
            open={true}
            record={demoRecord}
            recordDate={DateTime.now().toISODate()}
            getRecordBudget={async () =>
              await new Promise((resolve) => {
                resolve({
                  total: 160,
                  used: 120,
                });
              })
            }
            states={{
              getter: {
                darkMode: darkMode,
                localeHyphen: localHyphen,
              },
            }}
          />
        </div>
      </SettingsGroup>
      <SettingsGroup
        anchor="tasks"
        groupHeader={t("settings.timerecording.groupHeader.tasks")}
      >
        <SettingsElement
          initialValue={groupedBy}
          icon={<TaskAltOutlinedIcon />}
          header={t("settings.timerecs.tasks.grouping.title")}
          subtext={t("settings.timerecs.tasks.grouping.caption")}
          renderItem={() => (
            <AutoComplete
              value={groupedBy}
              values={[
                TaskSettings.CUSTOMER,
                TaskSettings.JOB,
                TaskSettings.STARTDATE,
                TaskSettings.ENDDATE,
              ]}
              label={t("settings.timerecs.tasks.grouping.label")}
              getTitle={getGroupName}
              onChange={handleSelectedGrouping}
              onBlur={() => {}}
            />
          )}
          onChange={() => {}}
        />
      </SettingsGroup>
      <SettingsGroup
        anchor="timerec"
        groupHeader={t("settings.timerecording.groupHeader.export")}
      >
        <SettingsElement
          icon={<Share />}
          initialValue={DateTime.now().toISO()}
          header={t("settings.timerecs.timetracking.export.title")}
          subtext={t("settings.timerecs.timetracking.export.caption")}
          onChange={() => {}}
          renderItem={() => (
            <form onSubmit={handleSubmit(handleExport)}>
              <div className={classes.row}>
                <Controller
                  name="start"
                  control={control}
                  defaultValue={DateTime.now().startOf("week").toISO()}
                  render={({ field: { onChange, onBlur } }) => (
                    <div style={{ marginRight: 10 }}>
                      <DateFieldMoment
                        value={DateTime.now().startOf("week").toISO()}
                        style={classes.dateField}
                        label="FROM"
                        onChange={onChange}
                        onBlur={onBlur}
                        variant="outlined"
                        isEnglish={languageCode === "en"}
                      />
                    </div>
                  )}
                />
                <Controller
                  name="end"
                  control={control}
                  defaultValue={DateTime.now().endOf("week").toISO()}
                  render={({ field: { onChange, onBlur } }) => (
                    <DateFieldMoment
                      value={DateTime.now().endOf("week").toISO()}
                      style={classes.dateField}
                      label="TO"
                      onChange={onChange}
                      onBlur={onBlur}
                      variant="outlined"
                      isEnglish={languageCode === "en"}
                    />
                  )}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: 10,
                }}
              >
                <Button type="submit" variant="contained">
                  Export
                </Button>
              </div>
            </form>
          )}
        />
        <SettingsElement
          initialValue={showDaysWithoutCapacity}
          header={t(
            "settings.timerecs.dashboard.showDaysWithoutCapacity.title"
          )}
          subtext={t(
            "settings.timerecs.dashboard.showDaysWithoutCapacity.caption"
          )}
          onChange={(e) => dispatch(setTimerecDashboardWOCapacity(e))}
        />
      </SettingsGroup>
      <div
        ref={calendarRef}
        style={{
          paddingBottom: "1rem",
        }}
      >
        <SettingsGroup
          highlight={history.location.search === "?highlight=calendarsettings"}
          anchor="calendar"
          groupHeader={t("settings.user.groupHeader.calendarSettings")}
        >
          <SettingsElement
            icon={<CalendarMonthOutlinedIcon />}
            header={t("settings.calendar.title")}
            subtext={t("settings.calendar.subtitle")}
            onChange={() => {}}
            initialValue={""}
            renderItem={() => (
              <ConsentInfo
                consentButton={t("settings.calendar.consent.button")}
                scopes={scopes}
                consentGranted={consentGranted}
                language={languageCode}
                customWidth={"410px"}
              />
            )}
          />
        </SettingsGroup>
      </div>
    </>
  );
};
