import { useState, useEffect, MouseEvent, FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import { handleBcLink, handleExtensionLink } from "./helper/utils";
import _ from "lodash";
// Dyce-Lib
import { useUpdateEffect, useDebounce } from "@dyce/hooks";
import {
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  StatusOptions,
  TemplateCategory,
  ResourceCapacity,
  ValidatedRecord,
} from "@dyce/tnt-api";
import {
  Dialog,
  PopOverMenu,
  TooltipSwitcher,
  useStaticContent,
} from "@dyce/ui";
import { MenuOptions } from "./record-list";
import { JobTaskStatusOptions } from "@dyce/tnt-api";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Box,
  MenuItem,
  Divider,
  TextField,
  Select,
  FormControl,
  InputLabel,
  ListItemIcon,
} from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import DeleteIcon from "@mui/icons-material/Delete";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
// Components
import { RecordListItemEntries } from "./record-list-item-entries";

const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      margin: "2rem 0px 1rem 0px",
    },
    collapseContainerHeight: {
      transition: "all 0.125s ease-in-out",
    },
    collapseContainerOpacity: {
      transition: "all 0.125s ease-in-out",
    },
  })
);

type CurrentEntry = {
  date: string;
  index: number;
};

interface IRecordListItemProps {
  /**
   * Single list-item entry object
   */
  record: RecordTimeRec | RecordTemplate;
  /**
   * If true, <Divider /> will be shown at bottom of entry
   */
  showDivider: boolean;
  /**
   * If true, rendered items will be shown by fading in (depends on 'visible in viewport)
   * @default false
   */
  showFading?: boolean;
  /**
   * Mapped index from records[]
   */
  index: number;
  /**
   * Define which options shown to user
   * @default true to all options
   */
  menuOptions: MenuOptions;
  /**
   * Show JSX.Element left besides Info-icon || Template-Button
   */
  graphicalAddition?: ({
    isReleased,
    duration,
  }: {
    isReleased: boolean;
    duration: number;
  }) => JSX.Element;
  /**
   * ID from last saved timerecording
   * @default null
   */
  latestCreatedRecordId?: string | null;
  /**
   * If true, menu-options will be shown with delay of 500ms
   */
  timeTrackingToolIsOpen: boolean;
  /**
   * Current height of editor
   */
  editorHeight: number;
  /**
   * Capacity from current user
   */
  resourceCapacity?: ResourceCapacity[];
  /**
   * Props for template categories, used to save timerecording as template
   */
  templateCategories: {
    /**
     * Categories from templates as array
     */
    templateCategories: TemplateCategory[];
    /**
     * Callback to get all Categories for selection in Dialog
     */
    getAllCategories: () => void;
  };
  /**
   * Props to create template from current timerecording
   */
  createTemplateFromRecord?: {
    /**
     * Callback to validate current timerecording in backend
     */
    validateTemplate: (value: RecordTemplate) => Promise<ValidatedRecord>;
    /**
     * Callback to create a new category when no exists
     */
    createCategory: (value: string) => Promise<TemplateCategory>;
    /**
     * Callback to save validated timerecording as template with selected category
     */
    createTemplate: (value: RecordTemplate) => void;
  };
  /**
   * Callback to mutate Timerecording entry to Template entry
   */
  mutateRecordForTemplate?: (
    copyRecordForTemplate: RecordTemplate,
    category: TemplateCategory | null,
    update?: boolean
  ) => RecordTemplate;
  /**
   * Callback to check if record is Timerecording or Template, returns boolean
   * Used e.g. to determine if timerecording or template should deleted
   * @default () => true; means: record is a timerecording
   */
  isRecord?: (entry: RecordTimeRec | RecordTemplate) => entry is RecordTimeRec;
  /**
   * Callback fired at onClick event, when user clicks a list-item
   */
  onListItemClicked: (value: boolean) => void;
  /**
   * If true, children will be rendered
   */
  editListItem: boolean;
  /**
   *
   */
  delayListItem: ({ date, index }: { date: string; index: number }) => void;
  /**
   * If true, Time-Tracking-Tool opens when also record.id matches query id
   */
  fromTemplate?: boolean;
  /**
   * If true, children stay rendered
   */
  blockSaveRecord: boolean;
  /**
   * Callback fired on menu options click event, to prevent scrollToHash event.
   * Can be used to setState in redux store.
   */
  timeTrackingToolOpen: (value: boolean) => void;
  /**
   * Callback fired to delete latest saved record.id
   */
  clearLatestCreatedId?: () => void;
  /**
   * Callback fired when user copies timerecording; Also used for TemplateDatePicker
   */
  onSave: (
    entry: RecordEntry & Partial<RecordTemplate & RecordTimeRec>,
    isTimeRecord?: boolean
  ) => Promise<{
    newEntry: RecordEntry | RecordTemplate | null;
    error: boolean;
  }>;
  /**
   * Callback fired when user deletes record
   */
  deleteRecord: (id: string, template?: boolean) => Promise<boolean>;
  /**
   * Props forwarded to TemplateDatePicker; Provide props when needed in ListItemEntry
   */
  templateDatePickerProps?: {
    /**
     * Callback to validate template record
     */
    validateTemplateObject?: (
      template: RecordTemplate
    ) => Promise<ValidatedRecord>;
    /**
     * Callback to set hook for checkbox and react on selection after submit
     */
    openCreatedRecording: (value: boolean) => void;
    /**
     * If true, record will instantly shown in edit mode after creating/saving with
     * correct url.search query
     */
    openInTimerecordings: boolean;
  };
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const RecordListItem: FunctionComponent<IRecordListItemProps> = ({
  record,
  showDivider,
  showFading = false,
  index,
  templateCategories: { templateCategories, getAllCategories },
  menuOptions,
  graphicalAddition,
  latestCreatedRecordId = null,
  createTemplateFromRecord,
  resourceCapacity,
  mutateRecordForTemplate,
  timeTrackingToolIsOpen,
  editorHeight,
  isRecord,
  onListItemClicked,
  editListItem,
  delayListItem,
  fromTemplate = false,
  blockSaveRecord,
  timeTrackingToolOpen,
  clearLatestCreatedId,
  onSave,
  deleteRecord,
  templateDatePickerProps,
  children,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const {
    docuLinks,
    routes,
    isMobile: { mobile },
  } = useStaticContent();

  // States
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [disableMenuButton, setDisableMenuButton] = useState<boolean>(false);
  // Delete state
  const [isDelete, setIsDelete] = useState<boolean>(false);
  // Copy record states
  const [copyDialog, setCopyDialog] = useState<boolean>(false);
  const [copyRecord, setCopyRecord] = useState<null | RecordTimeRec>(null);
  const [copyDate, setCopyDate] = useState<DateTime>();
  // Template states
  const [createTemplate, setCreateTemplate] = useState<boolean>(false);
  const [templateCategory, setTemplateCategory] =
    useState<TemplateCategory | null>(null);
  const [templateError, setTemplateError] = useState<string | undefined>(
    undefined
  );
  const [copyRecordForTemplate, setCopyRecordForTemplate] =
    useState<null | RecordTemplate>(null);
  // Show children and react on state change from props
  const [showChildren, setShowChildren] = useState<boolean>(false);
  const [openEditRecord, setOpenEditRecord] = useState<boolean>(false);

  const [loadedEntry, setLoadedEntry] = useState<CurrentEntry>();

  // Debouncing HtmlElements
  const { debouncedValue, setValue } = useDebounce<boolean>(false, 125);

  // UseEffects
  useEffect(() => {
    if (isRecord && isRecord(record) && record.date) {
      const currentEntry: CurrentEntry = {
        date: record.date.split("T")[0],
        index: index,
      };
      setLoadedEntry(currentEntry);
    }
  }, []);

  // Preselect category if only one exists
  useEffect(() => {
    if (templateCategories.length === 1) {
      setTemplateCategory(templateCategories[0]);
    }
  }, [templateCategories]);

  useEffect(() => {
    if (loadedEntry) {
      const query = new URLSearchParams(location.search);

      if (
        latestCreatedRecordId &&
        latestCreatedRecordId === record.id &&
        (query.has("fromTemplates") || query.has("fromTasks"))
      ) {
        setShowChildren(true);
        onListItemClicked(true);
        clearLatestCreatedId && clearLatestCreatedId();
        return;
      }

      const openRecord = Boolean(query.get("editRecord"));
      const index: number | boolean =
        location.search.includes("index") &&
        Number(location.search.split("index=")[1].split("#")[0]);
      const hash = location.hash.substring(1);

      if (
        typeof index === "number" &&
        openRecord &&
        hash &&
        DateTime.fromISO(loadedEntry.date).hasSame(
          DateTime.fromISO(hash),
          "day"
        ) &&
        loadedEntry.index === index
      ) {
        setShowChildren(true);
        onListItemClicked(true);
      }
    }
  }, [location, loadedEntry]);

  // open the editor if entry id matches query and entry is a template
  useEffect(() => {
    if (fromTemplate) {
      const query = new URLSearchParams(location.search);
      const id = query.get("id");

      if (id && id === record.id) {
        setShowChildren(true);
        onListItemClicked(true);
      }
    }
  }, [location, fromTemplate, record]);

  useEffect(() => {
    if (editListItem) {
      setOpenEditRecord(true);
    } else {
      setOpenEditRecord(false);
    }
  }, [editListItem]);

  useEffect(() => {
    if (!openEditRecord) {
      setShowChildren(false);
    }
  }, [openEditRecord]);

  useUpdateEffect(() => {
    setValue(isDelete);
  }, [isDelete]);

  // Handler
  const timeRecordClicked = (id: string) => {
    if (mobile) {
      if (isRecord && isRecord(record)) {
        history.push(`${routes.TNT_RECS}/${id}`, {
          state: { fromLocation: location.pathname },
        });
      } else {
        history.push(`${routes.TNT_RECS}/${id}?fromTemplates=true`, {
          state: { fromLocation: location.pathname },
        });
      }
    } else if (!blockSaveRecord) {
      if (timeTrackingToolIsOpen && loadedEntry) {
        delayListItem(loadedEntry);
        // TODO: BLOCK SOMETHING HERE
      } else {
        setShowChildren(true);
        onListItemClicked(true);
      }
    }
  };

  const handleOpenMenuClick = (event: MouseEvent<HTMLElement>) => {
    setDisableMenuButton(false);
    let openContextMenu: NodeJS.Timeout;
    const contextMenu: EventTarget & HTMLElement = event.currentTarget;
    if (timeTrackingToolIsOpen) {
      // If editor is open, wait till it is closed to open Contextmenu
      openContextMenu = setTimeout(() => {
        setAnchorEl(contextMenu);
      }, 500);
    } else {
      setAnchorEl(contextMenu);
    }
    return () => {
      clearTimeout(openContextMenu);
    };
  };

  const handleCopyTime = (entry: RecordTimeRec) => {
    setDisableMenuButton(true);
    setAnchorEl(null);

    setCopyRecord(entry);
    setCopyDate(DateTime.fromISO(entry.date));
    setCopyDialog(true);
  };

  const submitCopyTime = () => {
    if (copyRecord && copyDate) {
      const timeUpdate: Record<string, any> = {};

      if (copyRecord.start && copyRecord.end) {
        const newStart = copyDate.set({
          hour: DateTime.fromISO(copyRecord?.start).get("hour"),
          minute: DateTime.fromISO(copyRecord?.start).get("minute"),
        });

        const newEnd = copyDate.set({
          hour: DateTime.fromISO(copyRecord?.end).get("hour"),
          minute: DateTime.fromISO(copyRecord?.end).get("minute"),
        });
        timeUpdate["start"] = newStart.toISO();
        timeUpdate["end"] = newEnd.toISO();
      }

      const recordEntry: any = _.omit(copyRecord, "id");
      onSave({
        ...recordEntry,
        ...timeUpdate,
        date: copyDate?.toISO(),
      });
      setCopyDialog(false);
    }
  };

  const handleSaveAsTemplate = (entry: RecordTemplate) => {
    setDisableMenuButton(true);
    setAnchorEl(null);

    getAllCategories();
    setCopyRecordForTemplate(entry);

    setCreateTemplate(true);
  };

  const handleChangeCategory = async (id: string) => {
    const selectedCategory: TemplateCategory | undefined =
      templateCategories.find((x) => x.id === id);
    if (selectedCategory !== undefined) {
      setTemplateCategory(selectedCategory);
    } else {
      setTemplateCategory(null);
      setCopyRecordForTemplate(null);
    }
  };

  const submitSaveAsTemplate = async () => {
    if (
      copyRecordForTemplate &&
      createTemplateFromRecord &&
      mutateRecordForTemplate
    ) {
      // Validate Timerecording
      const validated = await createTemplateFromRecord.validateTemplate(
        copyRecordForTemplate
      );

      if (validated && validated.errors.length === 0) {
        if (templateCategory) {
          // Create new object for Template
          const mutateRecord: RecordTemplate = mutateRecordForTemplate(
            copyRecordForTemplate,
            templateCategory
          );

          setCopyRecordForTemplate(mutateRecord);
          createTemplateFromRecord.createTemplate(mutateRecord);
        } else {
          // Create Category before safe template
          const newCategory: TemplateCategory =
            await createTemplateFromRecord?.createCategory(
              `${t("timerec.myTime.templates.category")} 1`
            );
          // Safe template in "New Category"
          const mutateRecord: RecordTemplate = mutateRecordForTemplate(
            copyRecordForTemplate,
            newCategory
          );
          createTemplateFromRecord.createTemplate(mutateRecord);
        }
        setCreateTemplate(false);
      } else {
        setTemplateError(t("timerec.template.validationError"));
        setTemplateCategory(null);
        setCopyRecordForTemplate(null);
      }
    } else {
      setTemplateCategory(null);
      setCopyRecordForTemplate(null);
      setTemplateError(undefined);
      setCreateTemplate(false);
    }
  };

  const handleDelete = (id: string, date: string | null) => {
    setDisableMenuButton(true);
    setIsDelete(true);
    setAnchorEl(null);

    // Delete Template if date === null, else delete record
    const isTemplate = date === null ? true : false;
    deleteRecord(id, isTemplate).then((isDeleted) => {
      if (!isDeleted) {
        setIsDelete(false);
      }
    });
  };

  return (
    <>
      <Box
        className={classes.collapseContainerHeight}
        sx={{
          height:
            showChildren && !debouncedValue
              ? editorHeight
              : debouncedValue
              ? 0
              : 58,
          transition: "height 0.3s ease-in-out",
        }}
      >
        <Box
          className={classes.collapseContainerOpacity}
          sx={{ opacity: isDelete ? 0 : 1, transition: "all 0.25s ease" }}
        >
          <>
            {!showChildren ? (
              <Box
                data-testid="test"
                sx={{
                  opacity: showFading ? 1 : 0,
                  transition: "all 0.5s ease",
                }}
              >
                <RecordListItemEntries
                  record={record}
                  onRecordClicked={timeRecordClicked}
                  handleOpenMenuClick={handleOpenMenuClick}
                  resourceCapacity={resourceCapacity ? resourceCapacity : null}
                  graphicalAddition={graphicalAddition}
                  templateDatePickerProps={
                    templateDatePickerProps
                      ? {
                          onSave: onSave,
                          deleteRecord: deleteRecord,
                          clearLatestCreatedId: clearLatestCreatedId
                            ? clearLatestCreatedId
                            : () => {},
                          openCreatedRecording:
                            templateDatePickerProps.openCreatedRecording,
                          validateTemplateObject:
                            templateDatePickerProps.validateTemplateObject,
                          openInTimerecordings:
                            templateDatePickerProps.openInTimerecordings,
                        }
                      : undefined
                  }
                />
              </Box>
            ) : (
              <>{children}</>
            )}
          </>
          {anchorEl && (
            <PopOverMenu
              id={"time-recordings-menu"}
              onClose={setAnchorEl}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "center",
                horizontal: "right",
              }}
            >
              {menuOptions.copy && (
                <TooltipSwitcher
                  tooltip={
                    record.jobTask === null
                      ? null
                      : record.jobTask.status !== undefined &&
                        record.jobTask.status !== JobTaskStatusOptions.OPEN
                      ? t("tooltip.record.jobTaskStatusNotOpen")
                      : null
                  }
                  urlPath={
                    docuLinks.timetracking.timerecordings.records.contextMenu
                      .jobTaskNotOpen
                  }
                >
                  <MenuItem
                    onClick={() => {
                      handleCopyTime(record as RecordTimeRec);
                    }}
                    disabled={
                      disableMenuButton ||
                      (record.jobTask !== null &&
                        record.jobTask.status !== undefined &&
                        record.jobTask.status !== JobTaskStatusOptions.OPEN)
                    }
                  >
                    <ListItemIcon style={{ minWidth: 36 }}>
                      <MoreTimeIcon fontSize="small" />
                    </ListItemIcon>
                    {t("timerec.dailyRecording.item.copyTime")}
                  </MenuItem>
                </TooltipSwitcher>
              )}
              {menuOptions.template && (
                <TooltipSwitcher
                  tooltip={
                    record.jobTask === null
                      ? t("tooltip.record.template")
                      : record.jobTask.status !== undefined &&
                        record.jobTask.status !== JobTaskStatusOptions.OPEN
                      ? t("tooltip.record.jobTaskStatusNotOpen")
                      : t("tooltip.record.template")
                  }
                  urlPath={
                    record.jobTask === null
                      ? docuLinks.timetracking.timerecordings.records
                          .contextMenu.saveAsTemplate
                      : record.jobTask.status !== undefined &&
                        record.jobTask.status !== JobTaskStatusOptions.OPEN
                      ? docuLinks.timetracking.timerecordings.records
                          .contextMenu.jobTaskNotOpen
                      : docuLinks.timetracking.timerecordings.records
                          .contextMenu.saveAsTemplate
                  }
                >
                  <MenuItem
                    onClick={() => {
                      handleSaveAsTemplate(record as RecordTemplate);
                    }}
                    disabled={
                      disableMenuButton ||
                      (record.jobTask !== null &&
                        record.jobTask.status !== undefined &&
                        record.jobTask.status !== JobTaskStatusOptions.OPEN)
                    }
                  >
                    <ListItemIcon style={{ minWidth: 36 }}>
                      <BookmarksIcon fontSize="small" />
                    </ListItemIcon>
                    {t("timerec.dailyRecording.item.saveAsTemplate")}
                  </MenuItem>
                </TooltipSwitcher>
              )}
              {menuOptions.bcLink && (
                <TooltipSwitcher
                  tooltip={
                    record.job !== null
                      ? t("tooltip.record.bcLink.jobSelected")
                      : t("tooltip.record.bcLink.noJob")
                  }
                  urlPath={
                    docuLinks.timetracking.timerecordings.records.contextMenu
                      .bcLink
                  }
                >
                  <MenuItem
                    onClick={() => {
                      handleBcLink(record.job);
                      setAnchorEl(null);
                    }}
                    disabled={disableMenuButton || record.job === null}
                  >
                    <ListItemIcon style={{ minWidth: 36 }}>
                      <OpenInNewOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    {t("timerec.dailyRecording.item.bcLink")}
                  </MenuItem>
                </TooltipSwitcher>
              )}
              {menuOptions.extensionLink && (
                <TooltipSwitcher
                  tooltip={
                    isRecord && isRecord(record) && record.workItem !== null
                      ? t("tooltip.record.extensionLink.linkInfo")
                      : t("tooltip.record.extensionLink.noInfo")
                  }
                  urlPath={
                    docuLinks.timetracking.timerecordings.records.contextMenu
                      .extensionLink
                  }
                >
                  <MenuItem
                    onClick={() => {
                      handleExtensionLink(
                        isRecord && isRecord(record) ? record : null
                      );
                      isRecord &&
                        isRecord(record) &&
                        record.workItem !== null &&
                        setAnchorEl(null);
                    }}
                    disabled={
                      disableMenuButton ||
                      (isRecord && isRecord(record) && record.workItem === null)
                    }
                  >
                    <ListItemIcon style={{ minWidth: 36 }}>
                      <OpenInNewOutlinedIcon fontSize="small" />
                    </ListItemIcon>
                    {t("timerec.dailyRecording.item.extensionLink")}
                  </MenuItem>
                </TooltipSwitcher>
              )}
              {Object.values(menuOptions).filter((x) => x === true).length >
                1 && <Divider />}
              {menuOptions.delete && (
                <MenuItem
                  onClick={() => {
                    handleDelete(
                      record.id,
                      isRecord && isRecord(record) ? record.date : null
                    );
                  }}
                  disabled={
                    isRecord && isRecord(record)
                      ? record.status !== StatusOptions.OPEN
                      : false || disableMenuButton
                  }
                >
                  <ListItemIcon style={{ minWidth: 36 }}>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  {t("timerec.dailyRecording.item.delete")}
                </MenuItem>
              )}
            </PopOverMenu>
          )}
        </Box>
      </Box>
      {showDivider && !debouncedValue && <Divider />}
      {copyDialog && (
        <Dialog
          open={copyDialog}
          title={t("timerecs.copy.title")}
          text={t("timerecs.copy.text")}
          actionButtonLabels={{ confirm: t("common.dialog.submit") }}
          onSubmit={submitCopyTime}
          onCancel={() => {
            setCopyDialog(false);
          }}
        >
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              openTo="day"
              showDaysOutsideCurrentMonth
              value={copyRecord?.date}
              onChange={(newValue) => {
                newValue && setCopyDate(newValue as any);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="standard" />
              )}
            />
          </LocalizationProvider>
        </Dialog>
      )}
      {createTemplate && (
        <Dialog
          open={createTemplate}
          title={t("timerecs.template.title")}
          text={t("timerecs.template.text")}
          actionButtonLabels={{ confirm: t("common.dialog.submit") }}
          onSubmit={submitSaveAsTemplate}
          onCancel={() => {
            setCreateTemplate(false);
            setTemplateCategory(null);
            setCopyRecordForTemplate(null);
            setTemplateError(undefined);
          }}
          isDisabled={
            templateCategories.length > 0 && templateCategory === null
          }
          errorText={templateError}
        >
          <div className={classes.formControl}>
            <FormControl
              variant="outlined"
              sx={{ m: 1, minWidth: 120 }}
              style={{
                width: "100%",
                margin: "0px",
              }}
            >
              <InputLabel
                id="demo-simple-select-outlined-label"
                variant="outlined"
              >
                {t("timerec.template.dialogLabel")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                label={t("timerec.template.dialogLabel")}
                autoFocus={true}
                value={templateCategory ? templateCategory.id : "0"}
                onChange={(_, e) => {
                  // create a dummy category if no category exists yet
                  if ((e as any).props.value == "0") {
                    handleChangeCategory("");
                    return;
                  }

                  // search category by the id we received from the select component
                  const cat = templateCategories.find(
                    (c) => c.id === (e as any).props.value
                  );
                  if (cat) handleChangeCategory(cat.id);
                }}
              >
                {templateCategories.length === 0 ? (
                  <MenuItem value={"0"}>{`${t(
                    "timerec.myTime.templates.category"
                  )} 1`}</MenuItem>
                ) : (
                  templateCategories.map((cat) => (
                    <MenuItem key={cat.id} value={cat.id}>
                      {cat.name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </div>
        </Dialog>
      )}
    </>
  );
};
