import { FunctionComponent, useEffect, useState } from "react";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTheme } from "@mui/styles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IChipSelectProps {
  label: string;
  value: {
    id: string;
    value: string;
  }[];
  values: {
    id: string;
    value: string;
  }[];
  onChange: (
    value: {
      id: string;
      value: string;
    }[]
  ) => void;
  onBlur: (
    value: {
      id: string;
      value: string;
    }[]
  ) => void;
}

export const ChipSelect: FunctionComponent<IChipSelectProps> = ({
  label,
  value,
  values,
  onChange,
  onBlur,
}) => {
  const theme = useTheme<DyceTheme>();

  // UseStates
  const [selectedValues, setSelectedValues] = useState<
    {
      id: string;
      value: string;
    }[]
  >(values);
  const [internalValues, setInternalValues] = useState<
    {
      id: string;
      value: string;
    }[]
  >([]);

  // UseEffects
  useEffect(() => {
    setSelectedValues(values);
    setInternalValues(values);
  }, [values]);

  useEffect(() => {
    setInternalValues(value);
  }, [value]);

  // Handler
  const getStyles = (
    value: string,
    values: {
      id: string;
      value: string;
    }[]
  ) => {
    const index = values.findIndex((x) => x.id === value);
    return {
      fontWeight:
        index === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  };

  const handleChange = (event: SelectChangeEvent<typeof selectedValues>) => {
    const {
      target: { value },
    } = event;
    // Get last Entry (string[])
    const preValue = value[value.length - 1];
    // Find value in all selected
    const foundValue = selectedValues.find((x) => x.value === preValue);
    if (foundValue) {
      // Check if duplicated
      const duplicated = internalValues.find((x) => x.id === foundValue.id);
      if (duplicated) {
        // If duplicated, remove entry
        const newArray = internalValues.filter((x) => x.id !== foundValue.id);
        setInternalValues(newArray);
        onChange(newArray);
      } else {
        // Add entry to array
        setInternalValues((old) => [...old, foundValue]);
        onChange([...internalValues, foundValue]);
      }
    }
  };

  const handleOnBlur = () => {
    onBlur(internalValues);
  };

  return (
    <FormControl
      sx={{
        width: 300,
      }}
    >
      <InputLabel id="multiple-chip-label" shrink={true}>
        {label}
      </InputLabel>
      <Select
        labelId="multiple-chip-label"
        id="multiple-chip"
        multiple
        value={internalValues}
        onChange={handleChange}
        onBlur={handleOnBlur}
        input={
          <OutlinedInput id="select-multiple-chip" label={label} notched />
        }
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value.id} label={value.value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {values.map((value) => (
          <MenuItem
            key={value.id}
            value={value.value}
            style={getStyles(value.id, selectedValues)}
          >
            {value.value}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
