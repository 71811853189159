import { FunctionComponent, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// Helper
import { DateTime } from "luxon";
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ResponsiveContainer,
  XAxis,
} from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { Tooltip, useStaticContent } from "@dyce/ui";
import { PeriodTime } from "@dyce/tnt-api";
import { calculateHours, handleBarChartDateFormat } from "@dyce/utils";
import { Periods } from "@dyce/interfaces";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    circle: {
      cursor: "pointer",
      fill: theme.palette.primary.main,
      "&:hover": {
        fill: theme.palette.primary.dark,
      },
    },
    text: {
      cursor: "pointer",
      pointerEvents: "none",
      fill: theme.palette.primary.contrastText,
    },
  })
);

interface ITimePlotProps {
  /**
   * Data to be displayed in the chart
   */
  data: PeriodTime[];
  /**
   * If true, all days will be displayed @property {showDaysWithoutCapacity}
   */
  showEmpty?: boolean;
  /**
   * Locale of the user e.g. "de-DE"
   */
  locale: string;
  /**
   * Language of the user e.g. "de"
   */
  language: string;
  /**
   * If true, the date format will be changed to the new format "ccc" instead of "cccc"
   */
  newDateFormat: boolean;
  /**
   * Height of the container
   * @default 400
   */
  containerHeight?: number;
  /**
   * Period of the chart
   * @default "week"
   */
  period?: Periods;
  /**
   * Object with information about the screen size
   */
  responsive: {
    isIpad: boolean;
    isIpadPro: boolean;
    tablet: boolean;
  };
  /**
   * Callback fired on click on a bar
   * @param nextState Props from BarChart @type {CategoricalChartState}
   * @returns void
   */
  onBarClick: (nextState: CategoricalChartState) => void;
}

type FormatDataType = {
  capacity: number;
  date: string;
  duration: number;
  durationBillable: number;
};

export const TimePlot: FunctionComponent<ITimePlotProps> = ({
  data,
  showEmpty = false,
  locale,
  language,
  newDateFormat,
  containerHeight,
  responsive,
  period = "week",
  onBarClick,
}) => {
  const theme = useTheme<DyceTheme>();
  const viewPortContainerWidth = 635;

  // Refs
  const chartRef = useRef<HTMLDivElement | null>(null);

  // States
  const [render, setRender] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    let renderTimer: NodeJS.Timeout;
    if (!render) {
      renderTimer = setTimeout(() => setRender(true), 450);
    }
    return () => clearTimeout(renderTimer);
  }, []);

  return (
    <div ref={chartRef}>
      <ResponsiveContainer
        id="test"
        width={
          chartRef &&
          chartRef.current &&
          chartRef.current.clientWidth < viewPortContainerWidth
            ? "93%"
            : "97%"
        }
        height={containerHeight || 400}
      >
        <BarChart
          data={formatData(data, showEmpty, period)}
          onClick={onBarClick}
          barGap={"-55%"}
          barSize={"90%"}
          margin={{
            left: 24,
            bottom: 4,
          }}
        >
          <XAxis
            dataKey="date"
            height={100}
            tick={
              <AxisTick
                locale={locale}
                language={language}
                newDateFormat={newDateFormat}
                period={period}
                darkMode={theme.palette.mode === "dark"}
              />
            }
          />
          <CartesianGrid vertical={false} horizontal={false} />
          {render && (
            <>
              <Bar
                dataKey="capacity"
                fill={"rgba(0, 180, 245, 0.1)"}
                style={{ cursor: "pointer" }}
                maxBarSize={90}
              >
                <LabelList
                  content={(values) => (
                    <TargetLabel
                      values={values}
                      period={period}
                      data={formatData(data, showEmpty, period)}
                      responsive={responsive}
                      theme={theme}
                    />
                  )}
                />
              </Bar>
              <Bar
                dataKey="duration"
                fill="rgb(135, 220, 255)"
                style={{ cursor: "pointer" }}
                maxBarSize={65}
              >
                <LabelList
                  content={(values) => (
                    <StateLabel
                      values={values}
                      data={formatData(data, showEmpty, period)}
                      period={period}
                      language={language}
                      responsive={responsive}
                    />
                  )}
                />
              </Bar>
            </>
          )}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const StateLabel = ({
  values,
  language,
  data,
  responsive,
  period,
}: {
  values: any;
  language: string;
  data: FormatDataType[];
  responsive: { isIpad: boolean; isIpadPro: boolean; tablet: boolean };
  period: Periods;
}) => {
  const { t } = useTranslation();
  const { x, width, value, index } = values;
  const { isIpad, isIpadPro, tablet } = responsive;

  const hourString = calculateHours({
    duration: data[index].duration * 60,
    allowMoreThanADay: period !== "week",
  });

  return (
    <text
      x={x}
      y={tablet && isIpad ? 215 : tablet && isIpadPro ? 238 : 287}
      // Center text
      dx={width / 2}
      fill={"#000"}
      fontSize={12}
      fontWeight={500}
      textAnchor="middle"
    >
      {value > 0
        ? `${t("timerec.dashboard.timeWorked.duration")
            .toUpperCase()
            .slice(0, language === "de" ? 3 : -3)} ${hourString}`
        : ""}
    </text>
  );
};

const TargetLabel = ({
  values,
  data,
  responsive,
  period,
  theme,
}: {
  values: any;
  data: FormatDataType[];
  responsive: { isIpad: boolean; isIpadPro: boolean; tablet: boolean };
  period: Periods;
  theme: DyceTheme;
}) => {
  const { t } = useTranslation();
  const { x, value, index } = values;
  const { isIpad, isIpadPro, tablet } = responsive;

  let targetString = "";
  if (Math.floor(value) === value) {
    targetString = value.toString();
  } else {
    targetString = calculateHours({
      duration: Math.round(parseFloat(data[index].capacity.toString()) * 60),
      allowMoreThanADay: period !== "week",
    });
  }

  const xValue =
    values.viewBox.width < 70 ? Math.round(x) + 10 : Math.round(x) + 13;

  let yValue = 292;
  if (isIpadPro && tablet) {
    yValue = 240;
  }
  if (isIpad && tablet) {
    yValue = 218;
  }

  return (
    <text
      fill={theme.palette.mode === "dark" ? "#fff" : "#000"}
      fontSize={12}
      fontWeight={500}
      textAnchor="start"
      transform={`translate(${xValue}, ${yValue}) rotate(270)`}
    >
      {value > 0
        ? `${t(
            "timerec.dashboard.timeWorked.capacity"
          ).toUpperCase()} ${targetString}`
        : ""}
    </text>
  );
};

const formatData = (
  data: PeriodTime[],
  showEmpty: boolean,
  period: Periods
): FormatDataType[] => {
  return data
    .filter((e) =>
      showEmpty ? true : e.duration > 0 || e.capacity > 0 ? true : false
    )
    .map((v) => {
      const dateInShortestFormat = handleBarChartDateFormat({
        period,
        date: v.date,
        options: { setFormat: true },
      });
      return {
        date: dateInShortestFormat,
        duration: v.duration / 60,
        durationBillable: v.durationBillable / 60,
        capacity: Number(v.capacity.toFixed(2)),
      };
    });
};

const AxisTick = (props: any) => {
  const classes = useStyles();
  const { x, y, payload, period, locale, language, newDateFormat, darkMode } =
    props;
  const history = useHistory();
  const { t } = useTranslation();
  const { docuLinks, routes } = useStaticContent();

  let barDate = "2000-01-01";
  const barValue: string | null =
    payload.value === "auto" ? null : payload.value;

  if (barValue) {
    barDate = handleBarChartDateFormat({
      period,
      date: barValue,
      options: {
        getDate: true,
      },
    });
  }

  let isToday = false;
  if (
    period === "week" &&
    DateTime.fromISO(barDate).toISODate() === DateTime.now().toISODate()
  ) {
    isToday = true;
  }

  const barDateUpper =
    period === "week"
      ? DateTime.fromISO(barDate).toFormat(newDateFormat ? "ccc" : "cccc", {
          locale: language,
        })
      : period === "month"
      ? `${language === "de" ? "KW" : "CW"} ${DateTime.fromISO(
          barDate
        ).toFormat("WW")}`
      : DateTime.fromISO(barDate).toFormat("MMM", { locale: language });

  const barDateLower =
    period === "week"
      ? DateTime.fromISO(barDate)
          .setLocale(locale)
          .toFormat("D")
          .slice(0, newDateFormat ? (locale.includes("de") ? -4 : -5) : 10)
      : DateTime.fromISO(barDate).toFormat("kkkk");

  const date = DateTime.fromISO(barDate)
    .setLocale(locale)
    .toFormat("D")
    .slice(0, locale.includes("de") ? -4 : -5);

  const handleFilling = (): string => {
    let fillColor = "#666";

    if (!darkMode) {
      if (isToday) {
        fillColor = "#2e2e2e";
      }
    } else {
      if (isToday) {
        fillColor = "#f1f1f1";
      } else {
        fillColor = "#bbb";
      }
    }

    return fillColor;
  };

  return (
    <Tooltip
      label={t("timerec.dashboard.createTimeRec", {
        date: date,
      })}
      urlPath={docuLinks.timetracking.dashboard.createRecord}
    >
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={15}
          textAnchor="middle"
          fill={handleFilling()}
          className="upperXTick"
          fontWeight={isToday ? 600 : 400}
        >
          {barDateUpper}
        </text>
        <text
          x={0}
          y={0}
          dy={35}
          textAnchor="middle"
          fill={handleFilling()}
          className="lowerXTick"
          fontWeight={isToday ? 600 : 400}
        >
          {barDateLower}
        </text>
        <circle
          onClick={() =>
            history.push(
              `${routes.TNT}/dailyrecordings?newRecord=true#${barDate}`,
              {
                fromDashboard: true,
              }
            )
          }
          r="16"
          cy={60}
          className={classes.circle}
        />
        <text dy={65} dx={-4} className={classes.text}>
          +
        </text>
      </g>
    </Tooltip>
  );
};
