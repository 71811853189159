import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  AsyncThunk,
} from "@reduxjs/toolkit";
import { pull } from "lodash";
import { ActionMeta, CalendarEntries } from "@dyce/tnt-api";
import { tntApiHelper } from "../../apiHelper";
import { CalendarStateSlice, RootState } from "../../types/types";

export const getCalendarEntries: AsyncThunk<
  CalendarEntries[],
  {
    from: string;
    to: string;
  },
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  CalendarEntries[],
  {
    from: string;
    to: string;
  },
  { state: RootState; rejectValue: Error }
>(
  "calendar/getCalendarEntries",
  async ({ from, to }, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getCalendarService();

      return await client.getEntries({
        from: encodeURIComponent(from),
        to: encodeURIComponent(to),
      });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const initialState: CalendarStateSlice = {
  entries: null,
  requests: [],
};

const calendarSlice = createSlice({
  name: "calendar",
  initialState: initialState,
  reducers: {
    resetCalendarEntries(state) {
      state.entries = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getCalendarEntries.fulfilled, (state, action) => {
        // If payload has: "The mailbox is either inactive, soft-deleted, or is hosted on-premise."
        if (typeof action.payload === "string") {
          state.entries = [];
          return;
        }

        state.entries = action.payload;
      })
      .addCase(getCalendarEntries.rejected, (state) => {
        state.entries = [];
      })
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.startsWith("calendar") && action.type.endsWith("pending"),
        (state, action) => {
          state.requests.push(action.meta.requestId);
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("fulfilled") || action.type.endsWith("rejected"),
        (state, action) => {
          pull(state.requests, action.meta.requestId);
        }
      ),
});

export const { resetCalendarEntries } = calendarSlice.actions;

export default calendarSlice.reducer;
