import { useState, useEffect } from "react";
// Helper
import { v4 as uuidv4 } from "uuid";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Box, Typography, useTheme } from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      transition: "all 0.4s ease",
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
    },
    errorText: {
      display: "flex",
      alignItems: "center",
      color: theme.palette.error.main,
    },
  })
);
interface IFormErrorProps {
  /**
   * Error array with message to show information what is wrong
   */
  errors: any[];
  /**
   * Callback fired for every index in error array
   */
  errorCount?: (count: number) => void;
  /**
   * If true, margin at top will have 4px
   * @default false
   */
  addMarginTop?: boolean;
  /**
   * If true, margin at top will have .75rem (12px);
   * Adds also borderRadius from theme to bottom
   * @default false
   */
  addMarginBottom?: boolean;
}

export const FormError: React.FunctionComponent<IFormErrorProps> = ({
  errors,
  errorCount,
  addMarginTop = false,
  addMarginBottom = false,
}) => {
  const classes = useStyles();
  const theme = useTheme<DyceTheme>();

  // States
  const [allHeight, setAllHeight] = useState<number>(errors.length * 24);

  useEffect(() => {
    if (errors.length > 0) {
      let countErrors = 0;
      errors.forEach((error: any) => {
        if (error && error.message) {
          countErrors++;
        } else if (Object.values(error).length > 0) {
          Object.values(error).forEach((err: any) => {
            if (err && err.message) {
              countErrors++;
            }
          });
        }
      });
      setAllHeight(countErrors * 24);
      errorCount && errorCount(errors.length);
    } else {
      errorCount && errorCount(errors.length);
    }
  }, [errors.length]);

  return (
    <Box
      className={classes.container}
      sx={{
        height: errors.length > 0 ? allHeight : 0,
        paddingLeft: errors.length > 0 ? "2rem" : "80%",
        background: errors.length > 0 ? "#ebe1aa" : "",
        marginTop: addMarginTop ? "4px" : 0,
        marginBottom: addMarginBottom && errors.length > 0 ? ".75rem" : 0,
        borderBottomLeftRadius: addMarginBottom ? theme.shape.borderRadius : 0,
        borderBottomRightRadius: addMarginBottom ? theme.shape.borderRadius : 0,
      }}
    >
      {errors.map((error: any) =>
        error && error.message ? (
          <Typography
            variant="body2"
            noWrap
            className={classes.errorText}
            key={uuidv4()}
          >
            {error.message.toString()}
          </Typography>
        ) : (
          Object.values(error).length > 0 &&
          Object.values(error).map((err: any) =>
            err && err.message ? (
              <Typography
                variant="body2"
                noWrap
                className={classes.errorText}
                key={uuidv4()}
              >
                {err.message.toString()}
              </Typography>
            ) : null
          )
        )
      )}
    </Box>
  );
};
