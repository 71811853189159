import { Periods } from "@dyce/interfaces";
import { DateTime } from "luxon";
import * as Sentry from "@sentry/react";

/**
 * Function to set the selection from dashboard to local storage
 */
export const handleSaveDashboardSelection = ({
  date,
  period,
}: {
  date: string;
  period: Periods;
}) => {
  const today = DateTime.now().toISODate();

  localStorage.setItem(
    "dashboard",
    JSON.stringify({ date: date, period: period, savedDate: today })
  );
};

/**
 * Function to get the selection for the dashboard from local storage
 * Proofs if the selection is from today or not, if not, initial values are returned
 * @returns saved inforamtion as {date: string, period: Periods}
 */
export const handleLoadDashboardSelection = (): {
  date: string;
  period: Periods;
} => {
  const today = DateTime.now().toISODate();
  const period: Periods = "week";

  const dashboard = localStorage.getItem("dashboard");
  if (dashboard) {
    const { date: d, period: p, savedDate: s } = JSON.parse(dashboard);
    if (today === s) {
      return { date: d, period: p };
    }
    return { date: today, period };
  }

  return { date: today, period };
};

/**
 * Function to save the selection from the calendar to local storage
 * @param period Selected period in the calendar
 */
export const handleSaveCalendarSelection = (period: "week" | "workweek") => {
  localStorage.setItem("calendar", period);
};

/**
 * Function to get the selection for the calendar from local storage or return default value
 * @returns saved inforamtion as "week" | "workweek"
 */
export const handleLoadCalendarSelection = (): "week" | "workweek" => {
  const savedPeriod = localStorage.getItem("calendar") as "week" | "workweek";
  if (savedPeriod && (savedPeriod === "week" || savedPeriod === "workweek")) {
    return savedPeriod;
  } else {
    return "week";
  }
};

const USER_CONSENT_KEY = "userConsent";
export enum UserConsent {
  "NOT_ANSWERED" = 0,
  "NOT_GIVEN" = 1,
  "GIVEN" = 2,
}

/**
 * Function to check if user has given consent for telemetry
 * @returns true if consent is given
 */
export const getUserConsent = (): UserConsent => {
  const localStorageConsent = localStorage.getItem(USER_CONSENT_KEY);

  if (localStorageConsent) {
    const consent = JSON.parse(localStorageConsent);

    if (consent === 1) return UserConsent.NOT_GIVEN;
    if (consent === 2) return UserConsent.GIVEN;
  }

  return UserConsent.NOT_ANSWERED;
};

/**
 * Function to set the user consent in local storage
 */
export const setUserConsent = (
  value: UserConsent.GIVEN | UserConsent.NOT_GIVEN
) => {
  localStorage.setItem(USER_CONSENT_KEY, JSON.stringify(value));

  if (value == UserConsent.GIVEN) {
    startSentry();
  } else {
    Sentry.close();
  }
};

const browserTracing = new Sentry.BrowserTracing();
const replay = new Sentry.Replay();
const profiling = new Sentry.BrowserProfilingIntegration();

export const startSentry = () => {
  if (process.env["NX_SENTRY_DSN"]) {
    Sentry.init({
      dsn: process.env["NX_SENTRY_DSN"],
      integrations: [browserTracing, replay, profiling],
      tracesSampleRate: 1,
      profilesSampleRate: 1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
      tracePropagationTargets: ["localhost", "https://api.dyce.*"],
      environment: process.env["NODE_ENV"],
      release: process.env["NX_VERSION"] || "",
    });
  }
};
