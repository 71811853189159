import { FunctionComponent } from "react";
// Helper
import { Route, useLocation } from "react-router-dom";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { useUpdateEffect } from "@dyce/hooks";
import { DrawerLeft, useStaticContent } from "@dyce/ui";
import {
  getAllClients,
  getAllUsers,
  getServerStatus,
  getSettings,
  selectCurrentWorkspace,
  selectSideDrawerLeft,
  setResetAdminSlice,
  setSideDrawerLeft,
  useAppDispatch,
  useAppSelector,
} from "@dyce/slices";
// MUI
import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
// Components
import { AdminMenuItems } from "./AdministrationMenuItems";
import { Dashboard } from "../../pages/admin/dashboard";
import { Users } from "./Users";
import { Clients } from "./client/Clients";
import { Settings } from "./settings";

export const Administration: FunctionComponent = () => {
  const theme = useTheme<DyceTheme>();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { routes } = useStaticContent();

  // Selectors
  const open = useAppSelector(selectSideDrawerLeft);
  const workspace = useAppSelector(selectCurrentWorkspace);

  // UseEffects
  useUpdateEffect(() => {
    // Clear redux states and load data new with new workspace
    dispatch(setResetAdminSlice());
    if (location.pathname === routes.ADMIN_USERS) {
      dispatch(getAllUsers({}));
    }
    if (location.pathname === routes.ADMIN_CLIENT) {
      dispatch(getAllClients());
    }
    if (location.pathname === routes.ADMIN_SETTINGS) {
      dispatch(getSettings());
    }
    // Get always new server status to show correct menu-icon-color for dashboard
    dispatch(getServerStatus());
  }, [workspace]);

  return (
    <>
      <DrawerLeft
        openClose={(value) => dispatch(setSideDrawerLeft(value))}
        open={open}
      >
        <AdminMenuItems />
      </DrawerLeft>
      <Box sx={theme.palette.propsDyce.commonPageProps}>
        <Route exact={true} path={routes.ADMIN} component={Dashboard} />
        <Route exact={true} path={routes.ADMIN_USERS} component={Users} />
        <Route exact={true} path={routes.ADMIN_CLIENT} component={Clients} />
        <Route exact={true} path={routes.ADMIN_SETTINGS} component={Settings} />
      </Box>
    </>
  );
};
