import { atlassianAppProperties } from "../helper/jiraHelper";
import { JiraError, JiraPayload } from "../types";

export class JiraService {
  /**
   * Service to save documents (data) to Jira Cloud
   * @returns {Promise<JiraPayload>} where in 'value' is Record<string, any>
   */
  public async saveToBackEnd({
    id,
    value,
    project,
    addonKey,
  }: {
    /**
     * id of the document e.g. JiraBackEndId.DYCE_COMPANY_MAPPING
     */
    id: string;
    /**
     * value to save
     */
    value: any;
    /**
     * project key from Jira e.g. "TT"
     */
    project: string;
    /**
     * addon key from DYCE-Addon e.g. "dyce-time-tracking"
     */
    addonKey: string;
  }): Promise<JiraPayload | JiraError> {
    const propertyKey = project;
    const url = `/rest/atlassian-connect/1/addons/${addonKey}/properties/${propertyKey}-${id}`;

    const response = await atlassianAppProperties({
      url: url,
      type: "PUT",
      data: value,
    });

    return response;
  }

  /**
   * Service to save documents (data) to Jira Cloud
   * @returns {Promise<JiraPayload>} where in 'value' is Record<string, any>
   */
  public async deleteFromBackEnd({
    id,
    project,
    addonKey,
  }: {
    /**
     * id of the document e.g. JiraBackEndId.DYCE_COMPANY_MAPPING
     */
    id: string;
    /**
     * project key from Jira e.g. "TT"
     */
    project: string;
    /**
     * addon key from DYCE-Addon e.g. "dyce-time-tracking"
     */
    addonKey: string;
  }): Promise<unknown> {
    const propertyKey = project;
    const url = `/rest/atlassian-connect/1/addons/${addonKey}/properties/${propertyKey}-${id}`;

    const response = await atlassianAppProperties({
      url: url,
      type: "DELETE",
    });

    return response;
  }

  /**
   * Service to get saved documents (data) from Jira Cloud
   * @returns {Promise<JiraPayload>} where in 'value' is Record<string, any>
   */
  public async getDataFromBackEnd({
    id,
    project,
    addonKey,
  }: {
    /**
     * id of the document e.g. JiraBackEndId.DYCE_COMPANY_MAPPING
     */
    id: string;
    /**
     * project key from Jira e.g. "TT"
     */
    project: string;
    /**
     * addon key from DYCE-Addon e.g. "dyce-time-tracking"
     */
    addonKey: string;
  }): Promise<JiraPayload | JiraError> {
    const url = `/rest/atlassian-connect/1/addons/${addonKey}/properties/${project}-${id}`;

    const response = await atlassianAppProperties({
      url: url,
      type: "GET",
    });

    return response;
  }
}
