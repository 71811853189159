import { useEffect, useRef } from "react";

function useInterval<T = unknown, R = unknown>(
  callback: (args?: T) => R,
  delay: number
): void {
  const savedCallback = useRef<(args?: T) => R>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    const tick = () =>
      savedCallback && savedCallback.current && savedCallback.current();

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    } else {
      return () => void {};
    }
  }, [delay]);
}

export { useInterval };
