import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { RefreshButton } from "../refresh-button/refresh-button";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Box, CircularProgress, Typography } from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "flex",
      width: "100%",
      height: `calc(100vh - ${theme.palette.propsDyce.commonPageProps.marginTop} - 3px - 64px - 112px)`,
      [theme.breakpoints.down("sm")]: {
        height: `calc(100vh - ${theme.palette.propsDyce.commonPageProps.marginTop} - 3px - 56px - 112px)`,
      },
    },
    content: {
      display: "inherit",
      flexDirection: "column",
      alignItems: "center",
      margin: "auto",
    },
    messageContainer: {
      display: "inherit",
      alignItems: "center",
      margin: "auto",
      marginBottom: "3rem",
    },
    extraContainer: {
      width: "100%",
    },
  })
);

interface ILoadingSpinnerProps {
  /**
   * Provide time when text info should be shown in milliseconds. default = 3000
   */
  delay?: number;
  /**
   * Provide height for additional container to show scrollBar also on "Loading"
   */
  extraContainer?: number;
  /**
   * If false, refresh button won't show up after 10 seconds
   * @default true
   */
  withRefreshButton?: boolean;
  /**
   * Provide individual text instead of standard text showing
   */
  text?: string;
  /**
   * Provide individual style to LoadingSpinner to overwrite default style
   */
  style?: React.CSSProperties;
  /**
   * Callback fired initial with value FALSE and fired when dismount with TRUE;
   * Can be used to disallow NetworkWatcher to not overlay on LoadingSpinner when API-Call
   * is pending longer then NetworkWatcher allows.
   */
  allowNetworkWatcher?: (value: boolean) => void;
}

export const LoadingSpinner: FunctionComponent<ILoadingSpinnerProps> = ({
  delay = 3000,
  extraContainer = 0,
  withRefreshButton = true,
  text,
  style,
  allowNetworkWatcher,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  // States
  const [showSpinner, setShowSpinner] = useState(false);
  const [showRefreshButton, setShowRefreshButton] = useState(false);

  // UseEffects
  useEffect(() => {
    allowNetworkWatcher && allowNetworkWatcher(false);
    const timer = setTimeout(() => setShowSpinner(true), delay);
    const refreshTimer = setTimeout(
      () => setShowRefreshButton(withRefreshButton),
      10000
    );

    return () => {
      allowNetworkWatcher && allowNetworkWatcher(true);
      clearTimeout(timer);
      clearTimeout(refreshTimer);
    };
  }, []);

  return (
    <>
      <div className={classes.container} style={style}>
        <div className={classes.content}>
          {showSpinner && (
            <div className={classes.messageContainer}>
              <CircularProgress
                style={{ marginRight: 20 }}
                size={24}
                color="primary"
              />
              <Typography variant="h5">
                {text ? text : t("loading.message")}
              </Typography>
            </div>
          )}
          {withRefreshButton && (
            <RefreshButton
              showRefreshButton={showRefreshButton}
              buttonText={t("loading.refresh")}
            />
          )}
        </div>
      </div>
      {extraContainer > 0 && (
        <Box
          className={classes.extraContainer}
          sx={{ height: extraContainer }}
        />
      )}
    </>
  );
};
