import { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Backdrop,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Drawer,
  Divider,
} from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer,
    },
    drawerPaper: {
      top: 64,
      marginLeft: "auto",
      marginRight: "auto",
      transform: "translateY(-50%, -50%) !important",
      width: 1000,
      //maxWidth: 1600,
      height: "auto",
    },
  })
);

interface IDrawerTopProps {
  /**
   * Header text information
   */
  headerText: string;
  /**
   * Header subtext information
   */
  headerSubText: string;
  /**
   * If true, drawer will be shown
   */
  open: boolean;
  /**
   * Callback fired on submitting
   */
  onSubmit: () => void;
  /**
   * Callback fired on abort or
   */
  onClose: () => void;
  /**
   * If true, submit is disabled
   * @default false
   */
  disableSubmit?: boolean;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const DrawerTop: FunctionComponent<IDrawerTopProps> = ({
  headerText,
  headerSubText,
  open,
  onSubmit,
  onClose,
  disableSubmit = false,
  children,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Drawer
        className={classes.root}
        classes={{
          paper: classes.drawerPaper,
        }}
        transitionDuration={{ enter: 400, exit: 200 }}
        anchor="top"
        aria-labelledby="transition-drawer-title"
        aria-describedby="transition-drawer-description"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
      >
        <DialogTitle id="confirmation-dialog-title">
          <Typography variant="h6">{headerText}</Typography>
          <Typography variant="subtitle2">{headerSubText}</Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>{children}</DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onClose} variant="outlined" color="primary">
            {t("admin.users.modal.abort")}
          </Button>
          <Button
            onClick={onSubmit}
            variant="outlined"
            disabled={disableSubmit}
          >
            {t("admin.users.modal.okay")}
          </Button>
        </DialogActions>
      </Drawer>
    </div>
  );
};
