import {
  AreaMapping,
  AreaMappingInfo,
  AreaMappingTree,
  MappingInfo,
  ProjectConfiguration,
  ProjectConfigurationChild,
} from "@dyce/foreign-api";

/**
 * Function to build area-path (tree) with user mappings with all
 * childrens to make visible in tree component
 * @param param0 AreaPath from DevOps and flat userMapping array
 * @returns AreaPath with mappings
 */
export const handleAreaPathMapping = ({
  areaPath,
  areaMapping,
}: {
  areaPath: ProjectConfiguration;
  areaMapping: AreaMapping[] | undefined;
}): AreaMappingTree[] => {
  const newAreaMapping: AreaMappingTree[] = [];
  if (areaMapping) {
    const firstMapping = areaMapping.find(
      (mapp) => mapp.areaId === areaPath.children[0].id
    );

    const handleAllChildren = (
      children: ProjectConfigurationChild[] | undefined
    ): AreaMappingTree[] | undefined => {
      if (children) {
        const childAreaMapping: AreaMappingTree[] = [];
        children.forEach((child) => {
          let childMapp: AreaMappingInfo | null = null;
          // Find user-mapping in flat array by childrens areaId
          areaMapping.forEach((mapp) => {
            if (mapp.areaId === child.id) {
              childMapp = { ...mapp.mapping };
            }
          });
          // Push areaTree with children
          childAreaMapping.push({
            areaId: child.id,
            areaName: child.name,
            mapping: childMapp ? childMapp : {},
            children: handleAllChildren(child.children),
          });
        });
        return childAreaMapping;
      } else {
        return undefined;
      }
    };

    // Push first children to area mapping
    if (areaPath.hasChildren) {
      newAreaMapping.push({
        areaId: areaPath.children[0].id,
        areaName: areaPath.children[0].name,
        mapping: firstMapping ? firstMapping.mapping : {},
        children: handleAllChildren(areaPath.children[0].children),
      });
    }

    return newAreaMapping;
  } else {
    return [];
  }
};

/**
 *
 * @param param0
 * @returns
 */
export const handleAreaInheriting = ({
  areaId,
  mapping,
}: {
  areaId: number;
  mapping: AreaMappingTree[];
}): AreaMappingInfo => {
  let mapps: AreaMappingInfo = {};
  let childMapps: AreaMappingInfo = {};
  let mappFound = false;

  mapping.forEach((mapp) => {
    // Check Arealevel: 1
    if (mapp.areaId === areaId) {
      childMapps = {};
      mapps = { ...mapp.mapping };
      mappFound = true;
    } else if (!mappFound) {
      if (mapp.children) {
        mapp.children.forEach((mapp1) => {
          // Check Arealevel: 2
          if (mapp1.areaId === areaId) {
            childMapps = {};
            mapps = {
              customer: mapp1.mapping.customer
                ? Object.entries(mapp1.mapping.customer).length > 0
                  ? mapp1.mapping.customer
                  : null
                : mapp.mapping.customer,
              job: mapp1.mapping.job
                ? Object.entries(mapp1.mapping.job).length > 0
                  ? mapp1.mapping.job
                  : null
                : mapp.mapping.job,
              jobTask: mapp1.mapping.jobTask
                ? Object.entries(mapp1.mapping.jobTask).length > 0
                  ? mapp1.mapping.jobTask
                  : null
                : mapp.mapping.jobTask,
              jobPlanningLine: mapp1.mapping.jobPlanningLine
                ? Object.entries(mapp1.mapping.jobPlanningLine).length > 0
                  ? mapp1.mapping.jobPlanningLine
                  : null
                : mapp.mapping.jobPlanningLine,
              tntModelLine: mapp1.mapping.tntModelLine
                ? Object.entries(mapp1.mapping.tntModelLine).length > 0
                  ? mapp1.mapping.tntModelLine
                  : null
                : mapp.mapping.tntModelLine,
            };
            mappFound = true;
          } else if (!mappFound) {
            if (mapp1.children) {
              mapp1.children.forEach((mapp2) => {
                // Check Arealevel: 3
                if (mapp2.areaId === areaId) {
                  // if (areaId === 133) {
                  //   console.log(mapp2, mapp1);
                  // }
                  childMapps = {};
                  mapps = {
                    customer: mapp2.mapping.customer
                      ? Object.entries(mapp2.mapping.customer).length > 0
                        ? mapp2.mapping.customer
                        : null
                      : mapp1.mapping.customer && mapp1.mapping.customer.id
                      ? mapp1.mapping.customer
                      : mapp.mapping.customer,
                    job: mapp2.mapping.job
                      ? Object.entries(mapp2.mapping.job).length > 0
                        ? mapp2.mapping.job
                        : null
                      : mapp1.mapping.job
                      ? mapp1.mapping.job
                      : mapp.mapping.job,
                    jobTask: mapp2.mapping.jobTask
                      ? Object.entries(mapp2.mapping.jobTask).length > 0
                        ? mapp2.mapping.jobTask
                        : null
                      : mapp1.mapping.jobTask && mapp1.mapping.jobTask.id
                      ? mapp1.mapping.jobTask
                      : mapp.mapping.jobTask,
                    jobPlanningLine: mapp2.mapping.jobPlanningLine
                      ? Object.entries(mapp2.mapping.jobPlanningLine).length > 0
                        ? mapp2.mapping.jobPlanningLine
                        : null
                      : mapp1.mapping.jobPlanningLine
                      ? mapp1.mapping.jobPlanningLine
                      : mapp.mapping.jobPlanningLine,
                    tntModelLine: mapp2.mapping.tntModelLine
                      ? Object.entries(mapp2.mapping.tntModelLine).length > 0
                        ? mapp2.mapping.tntModelLine
                        : null
                      : mapp1.mapping.tntModelLine
                      ? mapp1.mapping.tntModelLine
                      : mapp.mapping.tntModelLine,
                  };
                  mappFound = true;
                } else if (!mappFound) {
                  if (mapp2.children) {
                    mapp2.children.forEach((mapp3) => {
                      // Check Arealevel: 4
                      const activityMapping = {
                        customer: mapp3.mapping.customer
                          ? Object.entries(mapp3.mapping.customer).length > 0
                            ? mapp3.mapping.customer
                            : null
                          : mapp2.mapping.customer
                          ? mapp2.mapping.customer
                          : mapp1.mapping.customer
                          ? mapp1.mapping.customer
                          : mapp.mapping.customer,
                        job: mapp3.mapping.job
                          ? Object.entries(mapp3.mapping.job).length > 0
                            ? mapp3.mapping.job
                            : null
                          : mapp2.mapping.job
                          ? mapp2.mapping.job
                          : mapp1.mapping.job
                          ? mapp1.mapping.job
                          : mapp.mapping.job,
                        jobTask: mapp3.mapping.jobTask
                          ? Object.entries(mapp3.mapping.jobTask).length > 0
                            ? mapp3.mapping.jobTask
                            : null
                          : mapp2.mapping.jobTask
                          ? mapp2.mapping.jobTask
                          : mapp1.mapping.jobTask
                          ? mapp1.mapping.jobTask
                          : mapp.mapping.jobTask,
                        jobPlanningLine: mapp3.mapping.jobPlanningLine
                          ? Object.entries(mapp3.mapping.jobPlanningLine)
                              .length > 0
                            ? mapp3.mapping.jobPlanningLine
                            : null
                          : mapp2.mapping.jobPlanningLine
                          ? mapp2.mapping.jobPlanningLine
                          : mapp1.mapping.jobPlanningLine
                          ? mapp1.mapping.jobPlanningLine
                          : mapp.mapping.jobPlanningLine,
                        tntModelLine: mapp3.mapping.tntModelLine
                          ? Object.entries(mapp3.mapping.tntModelLine).length >
                            0
                            ? mapp3.mapping.tntModelLine
                            : null
                          : mapp2.mapping.tntModelLine
                          ? mapp2.mapping.tntModelLine
                          : mapp1.mapping.tntModelLine
                          ? mapp1.mapping.tntModelLine
                          : mapp.mapping.tntModelLine,
                      };
                      if (mapp3.areaId === areaId) {
                        mapps = { ...activityMapping };
                        mappFound = true;
                        childMapps = {};
                      } else if (!mappFound) {
                        if (mapp3.children) {
                          // Tree-Depth > 4
                          // TODO: Inheriting not perfect
                          let obj: AreaMappingInfo = { ...activityMapping };
                          const findNextChild = (
                            nextChild: AreaMappingTree[] | undefined,
                            newObj: AreaMappingInfo
                          ) => {
                            if (nextChild) {
                              nextChild.forEach((child) => {
                                if (child.areaId === areaId) {
                                  obj = {
                                    ...newObj,
                                    ...child.mapping,
                                  };
                                }
                                if (child.children) {
                                  findNextChild(child.children, obj);
                                }
                              });
                            }
                          };
                          findNextChild(mapp3.children, obj);
                          childMapps = { ...obj };
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        });
      }
    }
  });

  if (!mappFound) {
    mapps = {
      customer: null,
      job: null,
      jobTask: null,
      jobPlanningLine: null,
      tntModelLine: null,
    };
  }

  return { ...mapps, ...childMapps };
};

/**
 *
 * @param mapping
 * @returns
 */
export const handleMutateMapping = (mapping: AreaMappingInfo): MappingInfo => {
  return {
    customer: mapping.customer === undefined ? null : mapping.customer,
    job: mapping.job === undefined ? null : mapping.job,
    jobTask: mapping.jobTask === undefined ? null : mapping.jobTask,
    jobPlanningLine:
      mapping.jobPlanningLine === undefined ? null : mapping.jobPlanningLine,
    tntModelLine:
      mapping.tntModelLine === undefined ? null : mapping.tntModelLine,
  };
};
