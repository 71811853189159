import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { FormProvider, useForm } from "react-hook-form";
import { defineMapping, mapDataToProjectingInfos } from "./utils";
// Dyce-Lib
import { checkForErrors } from "libs/ui/src/lib/time-tracking-tool/helper/individualErrorHandler";
import {
  PopulatedJobPlanningLine,
  PopulatedJobTask,
  TasksFilterStatus,
} from "@dyce/tnt-api";
import { handleFocusField } from "libs/ui/src/lib/time-tracking-tool/utils";
import {
  defineValidateObject,
  ErrorDef,
  FieldsWrapper,
  FormError,
  HiddenFocusInput,
  InfiniteScrollSettings,
  MappingField,
  ValidateRecordTask,
} from "@dyce/ui";
import {
  LookUpMapping,
  LookUpOptions,
  MappingFieldTypes,
} from "@dyce/interfaces";
import { MappingInfo } from "@dyce/foreign-api";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Paper } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    projectTaskPaper: {
      display: "flex",
      width: "100%",
      height: "10.5rem",
      justifyContent: "center",
      flexGrow: 1,
    },
  })
);

export type InternalLookUpMapping = LookUpMapping & {
  hidden?: string | null;
};

interface IMappingProps {
  /**
   * Mapping object to display if available
   */
  mapping: MappingInfo | null;
  /**
   * Callback fired on change
   */
  onChange: (preSelection: LookUpMapping | null) => void;
  /**
   * Callback fired on error, use to block save
   */
  onError: (value: boolean) => void;
  /**
   * Set focus on initial field
   */
  initialFocus: LookUpOptions | null;
  /**
   * Loading state
   */
  isLoading: boolean;
  /**
   * Define dark mode
   */
  darkMode: boolean;
  /**
   * If true, filter-button will be shown on top of datagrid;
   * This will cause different api endpoints;
   * @default "NONE"
   */
  workWithFilter?: TasksFilterStatus;
  /**
   * If true, expandable will be hidden
   * @default false
   */
  hideExpandable?: boolean;
  /**
   * Define lookup size
   * @default "medium"
   */
  lookupSize?: "small" | "medium";
  /**
   * Callback to block closing from Editor e.g. when API call is pending
   */
  blockClosingCallback: (value: boolean) => void;
  /**
   * Fields to display
   * @default [LookUpOptions.CUSTOMER, LookUpOptions.JOBTASK, LookUpOptions.JOB, LookUpOptions.JOBPLANNINGLINE]
   */
  fieldNames?: LookUpOptions[];
}

export const Mapping: FunctionComponent<IMappingProps> = ({
  mapping,
  initialFocus,
  onChange,
  onError,
  darkMode,
  isLoading,
  lookupSize,
  hideExpandable = false,
  blockClosingCallback,
  workWithFilter = TasksFilterStatus.NONE,
  fieldNames = [
    LookUpOptions.CUSTOMER,
    LookUpOptions.JOBTASK,
    LookUpOptions.JOB,
    LookUpOptions.JOBPLANNINGLINE,
  ],
}) => {
  const classes = useStyles();

  // Constant values for infinite scrolling
  const { initialTop, loadAbove, loadBelow, loadMore, triggerAtBottom } =
    InfiniteScrollSettings.values();

  // UseStates
  const [filterIsActive, setFilterIsActive] = useState<boolean>(false);
  const [mappingSelection, setMappingSelection] =
    useState<InternalLookUpMapping>(defineMapping());
  const [fillWithPreSelection, setFillWithPreSelection] =
    useState<boolean>(false);
  const [projectingInput, setProjectingInput] = useState<
    ValidateRecordTask<MappingFieldTypes>
  >(defineValidateObject({ initialTop, loadAbove, loadBelow, loadMore }));
  const [projectingInputPreState, setProjectingInputPreState] =
    useState<ValidateRecordTask<MappingFieldTypes>>(projectingInput);

  // UseEffects
  useEffect(() => {
    if (mapping !== null) {
      setMappingSelection(defineMapping(mapping));
      setFillWithPreSelection(true);
    }
  }, [mapping]);

  useEffect(() => {
    if (fillWithPreSelection) {
      setFillWithPreSelection(false);
    }
  }, [fillWithPreSelection]);

  // Mapping-Fields
  useEffect(() => {
    setTimeout(() => {
      // Save new 'ValidateRecordTask' object to previous-state
      setProjectingInputPreState(projectingInput);
    }, 10);
  }, [projectingInput]);

  useEffect(() => {
    if (projectingInput.infos.focusActivity) {
      // Focus activity field with selecting the complete text to avoid issues
      handleFocusField(setFocus, "jobPlanningLine", 0);
      projectingInput.infos.focusActivity = false;
    }
  }, [projectingInput.infos.focusActivity]);

  useEffect(() => {
    let deleteDependencyTimer: NodeJS.Timeout;
    if (projectingInput.dependency.deleteFields) {
      // If 'deleteDependedFields' was triggered set state back to false after 10ms
      deleteDependencyTimer = setTimeout(() => {
        projectingInput.dependency.deleteFields = false;
      }, 10);
    }
    return () => {
      clearTimeout(deleteDependencyTimer);
    };
  }, [projectingInput.dependency.deleteFields]);

  const methods = useForm<InternalLookUpMapping>({
    mode: "onBlur",
    defaultValues: mappingSelection,
  });

  const {
    formState: { errors },
    setError,
    setFocus,
  } = methods;

  const validate = (data: InternalLookUpMapping) => {
    // Manually Errorhandler
    const dataArray: any[] = Object.entries(data);
    const errorArray: ErrorDef[] = checkForErrors(dataArray);

    if (errorArray.length > 0) {
      errorArray.forEach((error) => {
        setError(error.ref as any, error.errorOption);
      });
      onError(true);
      return;
    } else {
      onError(false);
    }

    if (
      (projectingInput.dependency.deleteFields &&
        mappingSelection.job === null) ||
      (projectingInput.dependency.deleteFields &&
        mappingSelection.jobTask === null)
    ) {
      projectingInput.dependency.deleteFields = false;
    }

    const mutatedData = mapDataToProjectingInfos(data);
    setMappingSelection(mutatedData);
    onChange(mutatedData);
  };

  const handleActivityChanged = ({
    jobPlanningLine,
    jobTask,
  }: {
    jobPlanningLine: PopulatedJobPlanningLine | null;
    jobTask: PopulatedJobTask | null;
  }) => {
    const jpl: PopulatedJobPlanningLine | null = jobPlanningLine
      ? {
          id: jobPlanningLine.id,
          description: jobPlanningLine.description,
          tntModelLine: jobPlanningLine.tntModelLine,
          jobTask: jobPlanningLine.jobTask,
          serviceBillingType: jobPlanningLine.serviceBillingType,
        }
      : null;
    const tntModelLine = jobPlanningLine
      ? jobPlanningLine.tntModelLine
        ? jobPlanningLine.tntModelLine
        : null
      : null;

    const oldActivityId = mappingSelection.tntModelLine?.id;
    mappingSelection.jobPlanningLine = jpl;
    mappingSelection.tntModelLine = tntModelLine;

    if (tntModelLine === null) {
      // Activity field cleared
      setMappingSelection({ ...mappingSelection });
      const mutatedData = mapDataToProjectingInfos(mappingSelection);
      onChange(mutatedData);
    }

    if (
      tntModelLine &&
      tntModelLine.description === null &&
      jobTask &&
      jobTask.id === null
    ) {
      return;
    }

    // Focus activity or duration (depending on designId) field with selecting
    // the complete text to avoid issues
    setTimeout(() => {
      if (oldActivityId !== tntModelLine?.id) {
        setFocus("hidden");
        handleFocusField(setFocus, "jobPlanningLine", 50);
        projectingInput.infos.filledFromJobTask = false;
      }
      const mutatedData = mapDataToProjectingInfos(mappingSelection);
      onChange(mutatedData);
    }, 10);
    setMappingSelection({ ...mappingSelection });
  };

  return (
    <div style={{ height: "150px" }}>
      <FormError errors={Object.values(errors)} />
      <FormProvider {...methods}>
        <form onBlur={methods.handleSubmit(validate)}>
          <Paper elevation={0} className={classes.projectTaskPaper}>
            <div
              style={{
                marginTop: "0.5rem",
              }}
            >
              <FieldsWrapper
                active={true}
                name={"MappingFieldsWrapper"}
                textFieldHeight={40}
                rows={2}
                size={lookupSize}
              >
                {fieldNames.map((fieldName, index) => (
                  <MappingField<MappingFieldTypes>
                    initialFocus={
                      initialFocus === null ? undefined : initialFocus
                    }
                    hideExpandable={hideExpandable}
                    key={fieldName}
                    fieldName={fieldName}
                    fieldsPerWrapper={{
                      currentIndex: index,
                      summary: 4,
                    }}
                    filter={{
                      workWithFilter: workWithFilter,
                      filterIsActive: filterIsActive,
                      onChangeFilter: (value) => {
                        setFilterIsActive(value);
                      },
                    }}
                    lookupSize={lookupSize}
                    projectingInput={projectingInput}
                    projectingInputPreState={projectingInputPreState}
                    infiniteLoadingOpts={{
                      loadAbove: loadAbove,
                      triggerAtBottom: triggerAtBottom,
                    }}
                    tabIndex={
                      fieldName === LookUpOptions.CUSTOMER
                        ? 2
                        : fieldName === LookUpOptions.JOBTASK
                        ? 4
                        : fieldName === LookUpOptions.JOB
                        ? 3
                        : 5
                    }
                    validateProjectingInput={(value) =>
                      setProjectingInput(value)
                    }
                    onActivityChanged={(value) => handleActivityChanged(value)}
                    mappingObject={mappingSelection}
                    fillFromTemplate={fillWithPreSelection}
                    blockClosingCallback={blockClosingCallback}
                    darkMode={darkMode}
                    pending={isLoading}
                    forceChangeMappingFields={false}
                  />
                ))}
              </FieldsWrapper>
            </div>
          </Paper>
          <HiddenFocusInput />
        </form>
      </FormProvider>
    </div>
  );
};
