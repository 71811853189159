import { DateTime } from "luxon";

const formatIntl = (
    value: Date | DateTime | number,
    format: string | undefined,
    language: string
): any => {
    if (typeof value === "undefined" || value === null) return value;
    if (value instanceof Date)
        return DateTime.fromJSDate(value)
            .setLocale(language)
            .toFormat(format ? format : "");
    if (value instanceof DateTime)
        return (value as DateTime)
            .setLocale(language)
            .toFormat(format ? format : "");
    if (format === "decimal2")
        return Intl.NumberFormat(language, { maximumFractionDigits: 2 }).format(
            value as number
        );
    if (typeof value === "number")
        return Intl.NumberFormat(language).format(value);

    return value;
};

export { formatIntl };
