import React, {
  Dispatch,
  SetStateAction,
  useState,
  useContext,
  FunctionComponent,
} from "react";
// Helper
import { UserRoleMap } from "../../roleDialog/RoleDialog";
// Dyce-Lib
import { PopulatedUser } from "@dyce/tnt-api";

type UserListContextState = {
  selectedUsers: Set<PopulatedUser>;
  setSelectedUsers: Dispatch<SetStateAction<Set<PopulatedUser>>>;
  userRoleMap: UserRoleMap;
  setUserRoleMap: Dispatch<SetStateAction<UserRoleMap>>;
};

export const UserListContext = React.createContext<
  UserListContextState | undefined
>(undefined);

interface IUserListProviderProps {
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const UserListProvider: FunctionComponent<IUserListProviderProps> = ({
  children,
}) => {
  const [selectedUsers, setSelectedUsers] = useState<Set<PopulatedUser>>(
    new Set()
  );

  const [userRoleMap, setUserRoleMap] = useState<UserRoleMap>({});

  const value = {
    selectedUsers,
    setSelectedUsers,
    userRoleMap,
    setUserRoleMap,
  };

  return (
    <UserListContext.Provider value={value}>
      {children}
    </UserListContext.Provider>
  );
};

export const useUserList = () => {
  const context = useContext(UserListContext);

  if (context === undefined) {
    throw new Error("useUserList must be used with a UserListProvider");
  }

  return context;
};
