/* eslint-disable react/jsx-no-useless-fragment */
import { FunctionComponent, useEffect, useState } from "react";
// Helper
import classNames from "classnames";
import { formatWorkHours } from "./utils";
import { DateTime } from "luxon";
// Dyce-Lib
import { ResourceCapacity } from "@dyce/tnt-api";
import { calculateHours, calculateValue, handleCapaPerDay } from "@dyce/utils";
import { Tooltip } from "../../../tooltip/tooltip";
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Typography, CircularProgress, useTheme } from "@mui/material";
import { blue, deepPurple } from "@mui/material/colors";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      position: "relative",
      display: "flex",
    },
    progresses: {
      display: "grid",
      gridTemplateColumns: "1fr",
    },
    progressesTime: {
      position: "relative",
      display: "flex",
      height: "100%",
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      gridRowStart: 1,
      gridColumnStart: 1,
      textAlign: "center",
    },
    progressFull: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gridRowStart: 1,
      gridColumnStart: 1,
      "& .MuiCircularProgress-root": {
        color: blue[100],
        opacity: 0.5,
      },
    },
    progressCalc: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gridRowStart: 1,
      gridColumnStart: 1,
      "& .MuiCircularProgress-root": {
        color: "#2a8fbd",
      },
    },
    progressCalcReleased: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      gridRowStart: 1,
      gridColumnStart: 1,
      "& .MuiCircularProgress-root": {
        color: deepPurple[100],
      },
    },
  })
);

interface IItemEntriesProgressProps {
  /**
   * Date of the item in ISO format
   */
  date: string;
  /**
   * Duration of the item in minutes
   */
  duration: number;
  /**
   * If true, the component will be displayed as a summary (for the header)
   */
  asSummary?: boolean;
  /**
   * If true, the component will be displayed as small
   * @note Not comparable with asSummary prop!
   * @default false
   */
  smallSize?: boolean;
  /**
   * Style to apply when the item is released
   */
  releasedStyle?: string;
  /**
   * If true, the component will be displayed as an optional view
   */
  optionalView?: boolean;
  /**
   * If true, the component will be displayed as released
   */
  isReleased?: boolean;
  /**
   * Resource capacity
   */
  resourceCapacity: ResourceCapacity[] | null;
  /**
   * If true, the component will only show CircularProgress
   * @default false
   */
  showOnlyGraphic?: boolean;
  /**
   * If true, the component will only show CircularProgressFull
   * @default false
   */
  showOnlyFullProgress?: boolean;
  /**
   * If true, the component will only show CircularProgress with value
   * @default false
   */
  showOnlyProgress?: boolean;
  /**
   * If true, the component will append a tooltip on hover
   * @default false
   */
  appendWithToolTip?: boolean;
}

export const ItemEntriesProgress: FunctionComponent<
  IItemEntriesProgressProps
> = ({
  date,
  duration,
  asSummary = false,
  smallSize = false,
  releasedStyle = "",
  optionalView = false,
  isReleased = false,
  resourceCapacity = null,
  showOnlyGraphic = false,
  showOnlyFullProgress = false,
  showOnlyProgress = false,
  appendWithToolTip = false,
}) => {
  const classes = useStyles();
  const theme = useTheme<DyceTheme>();
  const circularSize = asSummary ? 58 : smallSize ? 36 : 50;
  const circularThickness = asSummary ? 6 : smallSize ? 5 : 6;
  const progressVariant = asSummary ? "body2" : smallSize ? "caption" : "body2";
  const isToday = date === DateTime.now().toISODate();

  // States
  const [workHour, setWorkHour] = useState<number>(20);

  // UseEffects
  useEffect(() => {
    if (!showOnlyFullProgress) {
      setWorkHour(
        handleCapaPerDay({
          currentDate: date,
          capacities: resourceCapacity ? resourceCapacity : [],
        })
      );
    }
  }, [resourceCapacity]);

  const kids = (
    <div
      className={classes.container}
      style={{
        minHeight: circularSize + 8,
        width: circularSize,
      }}
    >
      {!optionalView ? (
        <>
          {showOnlyFullProgress ? (
            <div className={classes.progressFull}>
              <CircularProgress
                size={circularSize}
                thickness={circularThickness}
                variant="determinate"
                value={100}
              />
            </div>
          ) : (
            <div className={classes.progresses}>
              {showOnlyProgress ? (
                <div
                  className={
                    isReleased
                      ? classes.progressCalcReleased
                      : classes.progressCalc
                  }
                >
                  <CircularProgress
                    size={circularSize}
                    thickness={circularThickness}
                    variant="determinate"
                    value={calculateValue(duration, workHour)}
                  />
                </div>
              ) : (
                <>
                  <div className={classes.progressFull}>
                    <CircularProgress
                      size={circularSize}
                      thickness={circularThickness}
                      variant="determinate"
                      value={100}
                    />
                  </div>
                  <div
                    className={
                      isReleased
                        ? classes.progressCalcReleased
                        : classes.progressCalc
                    }
                  >
                    <CircularProgress
                      size={circularSize}
                      thickness={circularThickness}
                      variant="determinate"
                      value={calculateValue(duration, workHour)}
                    />
                  </div>
                  {!showOnlyGraphic && (
                    <div
                      className={classNames(
                        classes.progressesTime,
                        releasedStyle
                      )}
                    >
                      <Typography
                        variant={progressVariant}
                        sx={{
                          fontSize: asSummary
                            ? undefined
                            : smallSize
                            ? "11px"
                            : undefined,
                          lineHeight: smallSize ? "1px" : undefined,
                        }}
                        fontWeight={asSummary ? 600 : ""}
                        color={
                          calculateHours({ duration }).includes("++")
                            ? "red"
                            : theme.palette.mode === "dark" && isToday
                            ? theme.palette.primary.contrastText
                            : "inherit"
                        }
                      >
                        {calculateHours({ duration })}
                      </Typography>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </>
      ) : (
        <div
          className={classNames(classes.progressesTime, releasedStyle)}
          style={{
            marginTop: asSummary ? 24 : 20,
          }}
        >
          <Typography
            variant={progressVariant}
            sx={{
              fontSize: asSummary ? undefined : smallSize ? "11px" : undefined,
              lineHeight: smallSize ? "1px" : undefined,
            }}
            fontWeight={asSummary ? 600 : ""}
            color={
              calculateHours({ duration }).includes("++")
                ? "red"
                : theme.palette.mode === "dark" && isToday
                ? theme.palette.primary.contrastText
                : "inherit"
            }
          >
            {calculateHours({ duration })}
          </Typography>
        </div>
      )}
    </div>
  );

  return appendWithToolTip ? (
    <Tooltip
      label={`${calculateHours({ duration })} h / ${formatWorkHours(
        workHour
      )} h`}
      urlPath={{ de: "", en: "" }}
    >
      <div style={{ pointerEvents: "all" }}>{kids}</div>
    </Tooltip>
  ) : (
    kids
  );
};
