import React, { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
// MUI
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyItems: "center",
  },
}));

export enum RoleAssignmentOptions {
  ADD = "add",
  REMOVE = "remove",
  NONE = "none",
}

export type RoleUserMap = {
  /**
   * ID of the role
   */
  roleId: string;
  /**
   * How many users are affected by that role assignment
   */
  assignedUsers: number;
  /**
   * What action is being performed
   */
  action: RoleAssignmentOptions;
};

interface CheckboxDescriptionProps {
  /**
   * Name of the element that is edited
   */
  name: string;
  /**
   * The number of users generally selected
   */
  userCount: number;
  /**
   * Further information about the action
   */
  roleInfo: RoleUserMap;
}

/**
 * Checkbox Description for operations on a user role assignment
 */
export const CheckboxDescription: FunctionComponent<
  CheckboxDescriptionProps
> = ({ name, userCount, roleInfo }) => {
  const classes = useStyle();
  const { t } = useTranslation();

  const add = t("admin.users.modal.operation.add");
  const remove = t("admin.users.modal.operation.remove");

  return (
    <div className={classes.container}>
      <div>
        <b>{name}</b>
      </div>
      {roleInfo.action != RoleAssignmentOptions.NONE &&
        roleInfo.assignedUsers > 0 && (
          <div>
            {t("admin.users.modal.assignRole", {
              affected: roleInfo.assignedUsers,
              all: userCount,
              operation:
                roleInfo.action === RoleAssignmentOptions.ADD ? add : remove,
            })}
          </div>
        )}
    </div>
  );
};
