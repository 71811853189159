import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { getLocaleName, getLocales } from "./utils";
// Dyce-Lib
import {
  AutoComplete,
  ConsentText,
  SettingsElement,
  SettingsGroup,
  SubHeader,
  useStaticContent,
} from "@dyce/ui";
import {
  selectDarkMode,
  selectLanguageCode,
  selectLocale,
  toggleDarkMode,
  setLanguage,
  setLocale,
  useAppDispatch,
  useAppSelector,
  SetupLanguages,
} from "@dyce/slices";
import {
  getUserConsent,
  setUserConsent as changeUserConsent,
  UserConsent,
} from "@dyce/utils";
// MUI
import LanguageIcon from "@mui/icons-material/Language";
import TranslateIcon from "@mui/icons-material/Translate";

export const UserSettings: FunctionComponent = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { docuLinks } = useStaticContent();
  const history = useHistory();

  // Selectors
  const darkModeSwitch = useAppSelector(selectDarkMode);
  const language = useAppSelector(selectLanguageCode);
  const locale = useAppSelector(selectLocale);

  // States
  const [loc, setLoc] = useState<string>(locale);

  const [languageKey] = useState(Math.random());
  const [locKey] = useState(Math.random());
  const [userConsent, setUserConsent] = useState<boolean>(false);

  useEffect(() => {
    const consent = getUserConsent();
    setUserConsent(consent == UserConsent.GIVEN ? true : false);
  }, []);

  // Handler
  const setDarkMode = () => {
    dispatch(toggleDarkMode(!darkModeSwitch));
  };

  const updateLanguage = (val: SetupLanguages) => {
    dispatch(setLanguage(val));
    i18n.changeLanguage(val);
  };

  const updateLocale = (val: string) => {
    setLoc(val);
    dispatch(setLocale(val));
  };

  const getLanguage = (val: string) => {
    switch (val) {
      case "en":
        return t("settings.theme.language.option.en");

      case "de":
        return t("settings.theme.language.option.de");

      default:
        return t("settings.theme.language.option.en");
    }
  };

  return (
    <>
      <SubHeader
        tooltipLabel={t("settings.header.user.info")}
        tooltipUrlPath={docuLinks.settings.userSettings.subheader.info}
        title={t("settings.header.user.title")}
        subtitle={t("settings.header.user.subtitle")}
        honorMargin={true}
      />
      <SettingsGroup
        anchor="theme"
        groupHeader={t("settings.user.groupHeader.appSettings")}
      >
        <SettingsElement
          icon={<TranslateIcon />}
          initialValue={language}
          header={t("settings.theme.language.title")}
          subtext={t("settings.theme.language.subtitle")}
          renderItem={() => (
            <AutoComplete
              key={languageKey}
              value={language}
              testId="language-field"
              values={["de", "en"]}
              label={t("settings.theme.language.language")}
              getTitle={getLanguage}
              onChange={updateLanguage}
              onBlur={() => {}}
            />
          )}
          onChange={() => {}}
        />
        <SettingsElement
          icon={<LanguageIcon />}
          initialValue={loc}
          header={t("settings.theme.locale.title")}
          subtext={t("settings.theme.locale.caption")}
          renderItem={() => (
            <AutoComplete
              value={loc}
              key={locKey}
              testId="region-field"
              values={getLocales(language)}
              label={t("settings.theme.locale.locale")}
              getTitle={(val) => getLocaleName(val, language)}
              onChange={updateLocale}
              onBlur={() => {}}
            />
          )}
          onChange={updateLocale}
        />
        <SettingsElement
          initialValue={darkModeSwitch}
          header={t("settings.theme.darkMode.title")}
          subtext={t("settings.theme.darkMode.caption")}
          onChange={setDarkMode}
        />
      </SettingsGroup>
      <SettingsGroup
        anchor="cookieConsent"
        groupHeader={t("settings.user.groupHeader.cookieConsent")}
        highlight={history.location.search === "?highlight=cookieConsent"}
      >
        <SettingsElement
          initialValue={userConsent}
          header={t("settings.user.cookieConsent.title")}
          subtext={<ConsentText />}
          onChange={() => {
            if (userConsent) {
              changeUserConsent(UserConsent.NOT_GIVEN);
            } else {
              changeUserConsent(UserConsent.GIVEN);
            }
            setUserConsent(!userConsent);
          }}
        />
      </SettingsGroup>
    </>
  );
};
