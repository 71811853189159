import { t } from "i18next";
// Logos
import { TimetrackingLogo, DyceLogo } from "@dyce/ui-assets";

/**
 * Fn to provide location-area by url
 * @param urlString Receives url location.pathname.split("/")[1]
 * @returns Navbar header string description for current location
 */
export const setAppAreaAndDocTitle = (
  urlString: string,
  isLoggedIn: boolean
): string => {
  const areaTitles = {
    administration: t("nav.areaTitle.admin"),
    settings: t("nav.areaTitle.settings"),
  };

  const headerTitle = {
    administration: areaTitles.administration,
    timetracking: "Time Tracking",
    settings: areaTitles.settings,
    oidc: "OIDC",
    default: isLoggedIn ? "Home" : "Login",
  };

  // Check if time is running
  const stopwatchTime = document.title.includes(":")
    ? document.title.split("-")[0] + "- "
    : "";

  // Set document title
  document.title = `${stopwatchTime}DYCE ${
    headerTitle[
      urlString as "administration" | "timetracking" | "settings" | "oidc"
    ] || ""
  }`;

  return (
    headerTitle[
      urlString as "administration" | "timetracking" | "settings" | "oidc"
    ] || headerTitle["default"]
  );
};

/**
 * Function to check which App-Area is visited and returns correct Logo
 * @param urlPath Receives url path location.pathname.split("/")[1]
 * @returns JSX.Element with corresponding App-Logo
 */
export const getAppAreaLogo = (urlPath: string): React.ReactElement<any> => {
  const logoHeight = 32;
  const logoWidth = 32;

  let isNext = false;
  if ((DyceLogo as any).src) {
    isNext = true;
  }

  switch (urlPath) {
    case "timetracking":
      return (
        <img
          src={isNext ? (TimetrackingLogo as any).src : TimetrackingLogo}
          alt="timetracking-logo"
          height={logoHeight}
          width={logoWidth}
        />
      );
    default:
      return (
        <img
          src={isNext ? (DyceLogo as any).src : DyceLogo}
          alt="dyce-logo"
          height={logoHeight}
          width={logoWidth}
        />
      );
  }
};
