import React, { FunctionComponent, ReactNode } from "react";
// MUI
import { Box, SvgIconTypeMap } from "@mui/material";
import { green } from "@mui/material/colors";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

interface IFunctionComponentProps {
  /**
   * Label text shown besides check-icon
   */
  label: string;
  /**
   * If provided, individual icon will be shown instead of standard check-icon
   */
  IndividualIcon?: (color: string) => ReactNode;
}

export const AllDone: FunctionComponent<IFunctionComponentProps> = ({
  label,
  IndividualIcon,
}) => {
  return (
    <Box
      sx={{
        alignSelf: "center",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        marginTop: "20px",
      }}
    >
      {IndividualIcon ? (
        IndividualIcon(green[400])
      ) : (
        <CheckCircleOutlineIcon style={{ fontSize: 64, color: green[400] }} />
      )}
      <p style={{ whiteSpace: "break-spaces", textAlign: "center" }}>{label}</p>
    </Box>
  );
};
