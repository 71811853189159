import { FunctionComponent } from "react";
// RTK
import { Provider } from "react-redux";
// Store
import { createStore } from "./store";
// Types
import { SliceProps } from "./rootReducer";
// Helper
import { loadState, saveState } from "../sessionStorage/sessionStorage";
// Sync State
import { initStateWithPrevTab } from "redux-state-sync";

interface StoreProviderProps {
  /**
   * Activate slices with boolean 'true' to provide Slice for all children
   */
  selectSlices: SliceProps;
  /**
   * Subscribe store to save/load from sessionStorage
   * @default false
   */
  persists?: boolean;
  /**
   * If true, redux store will be synced over all tabs
   * @default undefined
   */
  syncStore?: {
    /**
     * Persist all tabs with synced state after refreshing one (load state from open tabs)
     */
    persistAfterRefresh?: boolean;
  };
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

let providedDyceStore: any = "";

export const StoreProvider: FunctionComponent<StoreProviderProps> = ({
  selectSlices,
  persists = false,
  syncStore = undefined,
  children,
}) => {
  const {
    admin = false,
    calendar = false,
    network = false,
    notification = false,
    onboarding = false,
    resources = false,
    settings = false,
    task = false,
    templates = false,
    timerec = false,
    tnt = false,
    ui = false,
    user = false,
    devOps = false,
    jira = false,
  } = selectSlices;

  let persistedState: any | undefined = undefined;

  // Load store-tree from sessionStorage if wanted
  if (persists === true) {
    persistedState = loadState();
  }

  // Get store with selected slices
  const dyceStore = createStore({
    selectSlices: {
      admin,
      calendar,
      network,
      notification,
      onboarding,
      resources: timerec === true ? true : resources,
      settings: timerec === true ? true : settings,
      task,
      templates,
      timerec,
      tnt,
      ui,
      user: resources === true || timerec === true ? true : user,
      devOps,
      jira,
    },
    persistedState: persistedState,
    syncStore: syncStore ? true : false,
    syncAfterRefresh:
      syncStore && syncStore.persistAfterRefresh
        ? syncStore.persistAfterRefresh
        : false,
  });

  // Save store-tree to sessionStorage if wanted
  dyceStore.subscribe(() => {
    if (persists === true) {
      saveState(dyceStore.getState());
    }
  });

  if (syncStore) {
    initStateWithPrevTab(dyceStore);
  }

  providedDyceStore = dyceStore;

  return <Provider store={dyceStore}>{children}</Provider>;
};

export { providedDyceStore };
