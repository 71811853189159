import { ApiType, JiraError, JiraPayload } from "../types";

declare const AP: any;

async function callFrontendApi<T>({
  url,
  type,
  data,
  isExperimental,
}: {
  url: string;
  type: ApiType;
  data: T;
  isExperimental: boolean;
}): Promise<T> {
  try {
    const response = await AP.request({
      url,
      type,
      ...(data && { data: JSON.stringify(data) }),
      contentType: "application/json",
      experimental: isExperimental,
    });

    return JSON.parse(response.body);
  } catch (e: any) {
    if (e instanceof SyntaxError) {
      const error = {
        message: "JSON Body could not be parsed!",
        "status-code": 400,
      };

      throw error;
    } else {
      const error = {
        message: JSON.parse(e.err).errorMessages[0],
        "status-code": e.xhr.status,
      };

      throw error;
    }
  }
}

export async function callAtlassianApi<T>({
  url,
  type = "GET",
  data = {} as T,
  isExperimental = false,
}: {
  /**
   * Url for the api call request e.g. "/rest/api/3/issue"
   */
  url: string;
  /**
   * Type of the api call request e.g. "GET", "POST", "PUT", "DELETE"
   */
  type?: ApiType;
  /**
   * Data for the api call request e.g. { fields: { summary: "Test" } }
   */
  data?: T;
  /**
   * Flag to enable experimental features in API calls if needed
   */
  isExperimental?: boolean;
}): Promise<{ data: T; error: JiraError | undefined }> {
  let internalData: T | JiraError = {} as T;

  try {
    if (window) {
      internalData = await callFrontendApi<T | JiraError>({
        url,
        type,
        data,
        isExperimental,
      });
      // check if internalData is an error
      if ((internalData as JiraError)["status-code"] !== undefined) {
        return {
          data: {} as T,
          error: internalData as JiraError,
        };
      } else {
        return {
          data: internalData as T,
          error: undefined,
        };
      }
    } else {
      return {
        data: internalData,
        error: {
          message: "No window object found!",
          "status-code": 400,
        },
      };
    }
  } catch (e) {
    return {
      data: {} as T,
      error: e as JiraError,
    };
  }
}

/**
 * Get and put/post to jira backend (save app properties)
 * @see https://developer.atlassian.com/cloud/jira/platform/rest/v3/api-group-app-properties/#api-group-app-properties
 */
export const atlassianAppProperties = async ({
  url,
  type,
  data,
}: {
  url: string;
  type: ApiType;
  data?: Record<string, any>;
}): Promise<JiraPayload | JiraError> => {
  try {
    const response = await AP.request({
      url,
      type,
      ...(data && { data: JSON.stringify(data) }),
      contentType: "application/json",
    });

    return JSON.parse(response.body);
  } catch (e: any) {
    if (e instanceof SyntaxError) {
      const error = {
        message: "JSON Body could not be parsed!",
        "status-code": 400,
        key: "error",
      };

      throw error;
    } else {
      const error = {
        message: JSON.parse(e.err).message,
        "status-code": JSON.parse(e.err)["status-code"],
        key: "error",
      };

      throw error;
    }
  }
};
