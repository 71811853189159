import React, { useEffect } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import {
  useAppDispatch,
  useAppSelector,
  selectSideDrawerLeft,
  serverHealthSelector,
  getServerStatus,
} from "@dyce/slices";
import { MenuItemCreator } from "@dyce/ui";
// MUI
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import DashboardIcon from "@mui/icons-material/DashboardOutlined";
import WebhookOutlinedIcon from "@mui/icons-material/WebhookOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import { makeStyles, createStyles } from "@mui/styles";
import { Badge } from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    content: theme.palette.propsDyce.drawerLeftContent,
  })
);

export const AdminMenuItems = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  // Selectors
  const drawerLeftToggled = useAppSelector(selectSideDrawerLeft);
  const serverStatus = useAppSelector(serverHealthSelector);

  // Get server status to update chip on icon
  useEffect(() => {
    dispatch(getServerStatus());
  }, []);

  return (
    <>
      <MenuItemCreator
        cssItem={classes.content}
        url="/administration"
        icon={
          <Badge
            color={
              serverStatus.bcErrors > 0
                ? "warning"
                : serverStatus.status === "danger"
                ? "error"
                : (serverStatus.status as any)
            }
            variant="dot"
          >
            <DashboardIcon />
          </Badge>
        }
        label={t("admin.nav.dashboard")}
        tooltipLabel={!drawerLeftToggled ? t("admin.nav.dashboard") : undefined}
      />
      <MenuItemCreator
        cssItem={classes.content}
        url="/administration/users"
        icon={<PersonOutlineIcon />}
        label={t("admin.nav.users")}
        tooltipLabel={!drawerLeftToggled ? t("admin.nav.users") : undefined}
      />
      <MenuItemCreator
        cssItem={classes.content}
        url="/administration/clients"
        icon={<WebhookOutlinedIcon />}
        label={t("admin.nav.client")}
        tooltipLabel={!drawerLeftToggled ? t("admin.nav.client") : undefined}
      />
      <MenuItemCreator
        cssItem={classes.content}
        url="/administration/settings"
        icon={<SettingsOutlinedIcon />}
        label={t("admin.nav.settings")}
        tooltipLabel={!drawerLeftToggled ? t("admin.nav.settings") : undefined}
      />
    </>
  );
};
