import { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Typography, Grid } from "@mui/material";

type TTextSizeOptions = "medium" | "small" | "large";

const useStyle = makeStyles(() =>
  createStyles({
    captionColor: {
      color: "grey",
      fontSize: "0.8rem",
    },
    heading: ({ textSize }: { textSize: TTextSizeOptions }) => ({
      fontSize:
        textSize === "small" ? "12px" : textSize === "large" ? "32px" : "24px",
    }),
  })
);

interface ITBeatLabelProps {
  /**
   * Show label in header
   * @default 'Database'
   */
  label?: string;
  /**
   * Info label to label in header
   */
  infoLabel?: string | null;
  /**
   * If true, Grid will be shown in columns
   * @default false
   */
  vertical?: boolean;
  /**
   * Size for label
   * @default 'medium'
   */
  textSize?: TTextSizeOptions;
  /**
   * Provide a human readable time stamp. When empty, loading text will be shown.
   */
  timestamp: string;
}

/**
 * UI element that shows a name and status description for a service
 */
export const BeatLabel: FunctionComponent<ITBeatLabelProps> = ({
  label = "Database",
  infoLabel,
  vertical = false,
  textSize = "medium",
  timestamp,
}) => {
  const classes = useStyle({ textSize });
  const { t } = useTranslation();

  return (
    <Grid
      container
      direction={vertical ? "column" : "row"}
      alignItems="center"
      sx={{ display: "flex" }}
    >
      <Typography variant="h5" className={classes.heading}>
        {label}
      </Typography>
      {infoLabel && (
        <Typography variant="subtitle1" className={classes.captionColor}>
          {infoLabel}
        </Typography>
      )}
      <Typography
        variant="subtitle1"
        className={classes.captionColor}
        style={{ paddingBottom: 12 }}
      >
        {timestamp ? timestamp : t("admin.dashboard.queue.loading")}
      </Typography>
    </Grid>
  );
};
