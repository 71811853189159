import { useEffect, useState } from "react";
// Helper
import { useLocation, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useHotkeys } from "react-hotkeys-hook";
import {
  calcDivContainerHeight,
  loopToNextWorkday,
  handleScrollHeight,
} from "./utils";
// Dyce-Lib
import {
  useUpdateEffect,
  useNotification,
  useViewPortHeight,
} from "@dyce/hooks";
import { DyceTheme } from "@dyce/theme";
import {
  LoadingSpinner,
  RecordList,
  ScrollTop,
  useStaticContent,
} from "@dyce/ui";
import {
  handleCreateCategory,
  handleCreateTemplate,
  handleDeleteRecord,
  handleValidateTemplate,
  mutateRecordForTemplate,
  validateTemplateObject,
  handleSaveTimerecording,
  validateLocationHashDate,
} from "@dyce/utils";
import {
  selectErrorOnUpdate,
  setClearErrorOnUpdate,
  getRecord,
  getRecsByWeek,
  selectLocaleHyphen,
  useAppDispatch,
  useAppSelector,
  clearLatestCreatedId,
  setAllowNetworkWatcher,
  selectCategoriesAsArray,
  getAllCategories,
  selectLatestRecId,
  templatesAsTwoDimArray,
  selectLatestEndTime,
  getAllTemplates,
  selectLanguageCode,
  selectCurrentUserResources,
  selectCapacities,
  selectAdminSettingsTasks,
} from "@dyce/slices";
import { RecordTimeRec, StatusOptions } from "@dyce/tnt-api";
import { handleRecordBudget } from "@dyce/utils";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    listContainer: {
      flexDirection: "column",
      display: "flex",
      justifyContent: "stretch",
    },
  })
);

interface IProps {
  dayRecords: [string, RecordTimeRec[]][];
  itemsArrayInfos: [string, number][];
  setInitLoading: React.Dispatch<React.SetStateAction<boolean>>;
  additionHeight: number;
  initLoading: boolean;
  allowScroll: boolean;
  openNewAtToday: () => void;
  timeTrackingToolOpen: (value: boolean) => void;
  timeTrackingToolIsOpen: boolean;
  clearTodayDate?: () => void;
}

export const TimerecordingList: React.FunctionComponent<IProps> = ({
  dayRecords,
  itemsArrayInfos,
  setInitLoading,
  additionHeight,
  initLoading,
  allowScroll,
  openNewAtToday,
  timeTrackingToolOpen,
  timeTrackingToolIsOpen,
  clearTodayDate,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const windowDim = useViewPortHeight();
  const location = useLocation();
  const history = useHistory();
  const theme = useTheme<DyceTheme>();
  const notification = useNotification();
  const {
    platform: { modifiers },
  } = useStaticContent();
  const scrollContainer = document.getElementById("rootContainer") ?? undefined;

  // Selectors
  const capacities = useAppSelector(selectCapacities);
  const userEntryDate = useAppSelector(selectCurrentUserResources);
  const localeHyphen = useAppSelector(selectLocaleHyphen);
  const errorOnUpdate = useAppSelector(selectErrorOnUpdate);
  const templateCategories = useAppSelector(selectCategoriesAsArray);
  const latestId = useAppSelector(selectLatestRecId);
  const templates = useAppSelector(templatesAsTwoDimArray);
  const latestCreatedEndTime = useAppSelector(selectLatestEndTime);
  const languageCode = useAppSelector(selectLanguageCode);
  const tasksRule = useAppSelector(selectAdminSettingsTasks);

  // States
  const [locationStartDate] = useState<string>(
    validateLocationHashDate(location, localeHyphen, userEntryDate)
  );
  const [startDate, setStartDate] = useState<string>(locationStartDate);
  const [saveHashDate, setSaveHashDate] = useState<string>(
    validateLocationHashDate(location, localeHyphen, userEntryDate)
  );
  const [initialScroll, setInitialScroll] = useState<boolean>(false);
  const [loop, setLoop] = useState<number>(0);
  const [smoothScrolling, setSmoothScrolling] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    scrollToHashDate();

    const insideWeek = dayRecords.findIndex(
      (x) => x[0] === history.location.hash.split("#")[1]
    );
    if (insideWeek >= 0) {
      setInitialScroll(true);
    }
  }, [dayRecords]);

  useEffect(() => {
    scrollToHashDate();
    setSaveHashDate(
      validateLocationHashDate(location, localeHyphen, userEntryDate)
    );

    const disableSmoothScroll: NodeJS.Timeout = setTimeout(() => {
      if (allowScroll) {
        setSmoothScrolling(true);
      }
    }, 250);
    return () => {
      clearTimeout(disableSmoothScroll);
      dispatch(setClearErrorOnUpdate(undefined));
    };
  }, [history.location.hash, allowScroll]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    if (
      (itemsArrayInfos.length > 0 && allowScroll) ||
      query.has("fromTemplates") ||
      query.has("fromTasks")
    ) {
      handleInitialScrollPosition();
    }
    if (!allowScroll || initLoading) {
      setSmoothScrolling(false);
    }
  }, [
    initialScroll,
    itemsArrayInfos,
    initLoading,
    allowScroll,
    smoothScrolling,
  ]);

  useEffect(() => {
    // Logic to call recEntry by id on rejected updated and check if entry was deleted
    // or compare status after fulfilled api call.
    if (errorOnUpdate && errorOnUpdate.deleted) {
      // If entry is already deleted => notify
      notification(t("timerec.notification.timerecWasDeleted"), {
        variant: "error",
        canClose: true,
      });
      setTimeout(() => {
        dispatch(setClearErrorOnUpdate(errorOnUpdate.id));
      }, 500);
    } else if (errorOnUpdate && errorOnUpdate.newStatus === null) {
      // Something mismatched, get record again from backend
      dispatch(getRecord(errorOnUpdate.id));
    } else if (errorOnUpdate && errorOnUpdate.newStatus) {
      if (
        errorOnUpdate.newStatus !== errorOnUpdate.status &&
        errorOnUpdate.newStatus !== StatusOptions.OPEN
      ) {
        // Status changed => notify!
        notification(t("timerec.notification.timerecStatusChanged"), {
          variant: "info",
          canClose: true,
        });
      } else if (
        errorOnUpdate.newStatus === errorOnUpdate.status &&
        errorOnUpdate.unknownIssue
      ) {
        // Unknown Issue, e.g. validation error => notify!
        notification(t("timerec.notification.errorOnUpdate"), {
          variant: "warning",
          canClose: true,
        });
        setTimeout(() => {
          dispatch(setClearErrorOnUpdate());
        }, 500);
      }
      // dispatch(setClearErrorOnUpdate(errorOnUpdate.id));
    }
  }, [errorOnUpdate]);

  useUpdateEffect(() => {
    const query = new URLSearchParams(location.search);
    if (!query.has("fromTemplates") || !query.has("fromTasks")) {
      setInitLoading(true);
      callApi(startDate);
    }
  }, [startDate]);

  const callApi = (date: string) => {
    dispatch(
      getRecsByWeek({
        start: new Date(date),
      })
    );
  };

  // HotKeys
  useHotkeys(
    `${modifiers.modifierCtrl}+end`,
    () => {
      const capacityArray = Object.keys(userEntryDate).sort(
        (a, b) =>
          DateTime.fromFormat(a, "yyyy-MM-dd").toMillis() -
          DateTime.fromFormat(b, "yyyy-MM-dd").toMillis()
      );
      const validStartDay: string = capacityArray[0];

      dispatch(
        getRecsByWeek({
          start: new Date(validStartDay),
        })
      );
      history.push(
        `${location.search ? location.search : ""}#${validStartDay}`
      );
    },
    [saveHashDate]
  );

  useHotkeys(
    `${modifiers.modifierCtrl}+home`,
    (e) => {
      e.preventDefault();
      const currentHashDate = DateTime.fromFormat(saveHashDate, "yyyy-MM-dd");
      const today = DateTime.now();
      if (currentHashDate.diff(today, "days").days < 0) {
        history.push(
          `${location.search ? location.search : ""}#${DateTime.now().toFormat(
            "yyyy-MM-dd"
          )}`
        );
      } else {
        return;
      }
    },
    [saveHashDate]
  );

  // Handler
  const handleInitialScrollPosition = () => {
    if (itemsArrayInfos.length > 0 && scrollContainer) {
      const height = handleScrollHeight({
        hashId: history.location.hash.split("#")[1],
        arrayLength: itemsArrayInfos.length,
        headerCaption: (n) => itemsArrayInfos[n][0],
        secondArray: (n) => itemsArrayInfos[n][1],
        theme: theme,
      });

      scrollContainer.scrollTo({
        top: height,
        left: 0,
        behavior: smoothScrolling ? "smooth" : "auto",
      });
      if (scrollContainer.scrollHeight === height && !initLoading) {
        setInitialScroll(false);
      }
    }
  };

  const scrollToHashDate = () => {
    const validDate: string = validateLocationHashDate(
      location,
      localeHyphen,
      userEntryDate
    );

    if (dayRecords.length > 0) {
      // Check if validDate is in current dayRecords[] week
      const inWeek =
        DateTime.fromISO(dayRecords[0][0]).startOf("week") <=
          DateTime.fromISO(validDate) &&
        DateTime.fromISO(dayRecords[0][0]).endOf("week") >=
          DateTime.fromISO(validDate);

      if (inWeek) {
        if (dayRecords.some((dayRecord) => dayRecord[0] === validDate)) {
          if (location.hash && location.hash.split("#")[1] !== validDate) {
            history.push(
              `${location.search ? location.search : ""}#${validDate}`
            );
          }

          setLoop(0);
        } else {
          setLoop((prevLoop) => (prevLoop += 1));

          setStartDate(DateTime.fromISO(validDate).toISODate());
          // Find next valid Workday
          if (loop > 0) {
            const hashDate: string = loopToNextWorkday(
              dayRecords,
              validDate,
              location.search ? location.search : null
            );

            if (
              hashDate.length > 0 &&
              location.hash &&
              location.hash !== hashDate
            ) {
              history.push(hashDate);
            }
          } else {
            if (
              Object.entries(userEntryDate).length === 0 &&
              location.hash &&
              location.hash.split("#")[1] !== validDate
            ) {
              history.push(`#${validDate}`);
            }
          }
        }
      } else {
        setStartDate(validDate);
      }
    } else {
      setStartDate(validDate);
    }
  };

  return (
    <>
      {initLoading ? (
        <LoadingSpinner
          extraContainer={additionHeight - 7}
          allowNetworkWatcher={(value) =>
            dispatch(setAllowNetworkWatcher(value))
          }
        />
      ) : (
        <div className={classes.listContainer} id="recEntries">
          {dayRecords.map(([day, records]) => (
            <div key={day} id={`#${day}`}>
              <RecordList
                workWithFilter={tasksRule.assignment}
                openNewAtToday={openNewAtToday}
                date={day}
                records={records}
                isEnglish={languageCode === "en"}
                resourceCapacity={capacities}
                templateList={templates}
                latestCreatedEndTime={latestCreatedEndTime}
                itemsArrayInfos={itemsArrayInfos}
                timeTrackingToolIsOpen={timeTrackingToolIsOpen}
                clearTodayDate={clearTodayDate}
                getRecordBudget={async ({ id, levelOfDetail }) =>
                  await handleRecordBudget({
                    dispatch,
                    id,
                    levelOfDetail,
                    inHours: true,
                  })
                }
                latestCreatedRecordId={latestId}
                getAllTemplates={() => dispatch(getAllTemplates())}
                validateTemplateObject={async (template) =>
                  await validateTemplateObject(template, dispatch)
                }
                templateCategories={{
                  templateCategories: templateCategories,
                  getAllCategories: () => dispatch(getAllCategories()),
                }}
                deleteRecord={(id) => handleDeleteRecord(id, dispatch)}
                mutateRecordForTemplate={mutateRecordForTemplate}
                createTemplateFromRecord={{
                  createCategory: (name) =>
                    handleCreateCategory(name, dispatch),
                  createTemplate: (template) =>
                    handleCreateTemplate(template, dispatch),
                  validateTemplate: (record) =>
                    handleValidateTemplate(record, dispatch),
                }}
                timeTrackingToolProps={{
                  onSave: (entry) => handleSaveTimerecording(entry, dispatch),
                  timeTrackingToolOpen: (value) => timeTrackingToolOpen(value),
                  clearLatestCreatedId: () => dispatch(clearLatestCreatedId()),
                  localeHyphen: localeHyphen,
                }}
              />
            </div>
          ))}
          <ScrollTop />
          {dayRecords.length > 0 && (
            <Box
              sx={{ height: calcDivContainerHeight(dayRecords, windowDim) }}
            />
          )}
        </div>
      )}
    </>
  );
};
