import React, { FunctionComponent, useState, useEffect } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { isRoleChecked, isRoleIndeterminate, usersWithRole } from "./utils";
import { useUserList } from "../userList/context/userContext";
// Types
import {
  CheckboxDescription,
  RoleAssignmentOptions,
} from "./CheckboxDescription";
// Dyce-Lib
import { selectAllRolesAsArray, useAppSelector } from "@dyce/slices";
import { PopulatedUser, Role, PopulatedRole } from "@dyce/tnt-api";
// MUI
import { Checkbox, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    minWidth: "360px",
  },
  subHeader: {
    marginBottom: "20px",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "5px 0",
  },
  checkBox: {
    paddingLeft: "0 !important",
  },
}));

export type UserRoleMap = { [key: string]: Set<string> };

enum CheckboxState {
  OFF = "off",
  ON = "on",
  INDETERMINATE = "indeterminate",
}

export const RoleDialog: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  // States
  //const [selectedRoles, setSelectedRoles] = useState<UserRoleMap>({});
  const [roleState, setRoleState] = useState<{ [key: string]: CheckboxState }>({
    admin: CheckboxState.OFF,
    timerec: CheckboxState.OFF,
    resplan: CheckboxState.OFF,
  });
  const { userRoleMap, setUserRoleMap, selectedUsers } = useUserList();
  const [users] = useState<PopulatedUser[]>(Array.from(selectedUsers));

  // Selectors
  const allRoles = useAppSelector(selectAllRolesAsArray);

  //useEffect
  useEffect(() => {
    // set user role map when provided user array changes
    const values: UserRoleMap = {};
    users.forEach((user) => {
      values[user.id] = new Set(user.roles.map((r: Role) => r.id));
    });

    setUserRoleMap(values);
  }, [users]);

  useEffect(() => {
    let tempRoleState: { [key: string]: CheckboxState } = {};

    allRoles.forEach((role) => {
      const roles = {
        ...tempRoleState,
        [role.type]: isRoleChecked(role.id, users)
          ? CheckboxState.ON
          : isRoleIndeterminate(role.id, users)
          ? CheckboxState.INDETERMINATE
          : CheckboxState.OFF,
      };

      tempRoleState = roles;

      setRoleState(roles);
    });
  }, [allRoles, users]);

  useEffect(() => {});

  // Handler
  const handleCheckboxSelection = (checked: boolean, role: PopulatedRole) => {
    const values: UserRoleMap = { ...userRoleMap };

    setRoleState({
      ...roleState,
      [role.type]: checked ? CheckboxState.ON : CheckboxState.OFF,
    });

    Object.keys(values).forEach((id) => {
      if (checked) {
        if (!values[id].has(role.id)) values[id].add(role.id);
      } else {
        if (values[id].has(role.id)) values[id].delete(role.id);
      }
    });

    setUserRoleMap(values);
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.subHeader}>
        {t("admin.users.modal.numberSelected", { number: users.length })}
      </Typography>
      {allRoles.map((role, i) => (
        <div className={classes.row} key={i}>
          <Checkbox
            classes={{ root: classes.checkBox }}
            defaultChecked={isRoleChecked(role.id, users)}
            indeterminate={roleState[role.type] === CheckboxState.INDETERMINATE}
            onChange={(e) => handleCheckboxSelection(e.target.checked, role)}
          />
          <CheckboxDescription
            name={role.description}
            userCount={users.length}
            roleInfo={{
              roleId: role.id,
              assignedUsers: usersWithRole(
                role.id,
                users,
                roleState[role.type] === CheckboxState.ON
                  ? RoleAssignmentOptions.ADD
                  : roleState[role.type] === CheckboxState.INDETERMINATE
                  ? RoleAssignmentOptions.NONE
                  : RoleAssignmentOptions.REMOVE
              ),
              action:
                roleState[role.type] === CheckboxState.ON
                  ? RoleAssignmentOptions.ADD
                  : roleState[role.type] === CheckboxState.INDETERMINATE
                  ? RoleAssignmentOptions.NONE
                  : RoleAssignmentOptions.REMOVE,
            }}
          />
        </div>
      ))}
    </div>
  );
};
