import React, { FunctionComponent, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { AutoComplete, SettingsElement, SettingsGroup } from "@dyce/ui";
import { AdminSettings, TasksFilterStatus } from "@dyce/tnt-api";
import { updateSettings, useAppDispatch } from "@dyce/slices";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
// Components

const useStyles = makeStyles(() =>
  createStyles({
    container: {},
  })
);

interface ITasksSettingsProps {
  tasksSettingsValue: TasksFilterStatus;
}

export const TasksSettings: FunctionComponent<ITasksSettingsProps> = ({
  tasksSettingsValue,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [error, setError] = useState<boolean>(false);

  // Handler
  const getGroupName = (val: string) => {
    switch (val) {
      case TasksFilterStatus.NONE:
        return t("admin.settings.tasks.select.values.none");

      case TasksFilterStatus.OPTIONAL:
        return t("admin.settings.tasks.select.values.optional");

      case TasksFilterStatus.REQUIRED:
        return t("admin.settings.tasks.select.values.required");

      default:
        return t("admin.settings.tasks.select.values.optional");
    }
  };

  const handleSelectedGrouping = (selection: TasksFilterStatus) => {
    const updateAdminSettings: AdminSettings = {
      // ...adminSettings,
      tasks: {
        assignment: selection,
      },
    };

    dispatch(updateSettings(updateAdminSettings)).then((check) => {
      if (check.meta.requestStatus === "rejected") {
        setError(true);
        alert("Error on saving settings!");
      } else {
        setError(false);
      }
    });
  };

  return (
    <>
      <div className={classes.container}>
        <SettingsGroup
          groupHeader={t("admin.settings.groups.header.tasks")}
          anchor="tasks"
          fullWidth
        >
          <SettingsElement
            header={t("admin.settings.tasks.select.header")}
            subtext={t("admin.settings.tasks.select.caption")}
            initialValue={tasksSettingsValue}
            icon={<TaskAltOutlinedIcon />}
            renderItem={() => (
              <AutoComplete
                value={tasksSettingsValue}
                values={[
                  TasksFilterStatus.NONE,
                  TasksFilterStatus.OPTIONAL,
                  TasksFilterStatus.REQUIRED,
                ]}
                label={error ? "" : t("admin.settings.tasks.select.label")}
                getTitle={getGroupName}
                onChange={handleSelectedGrouping}
                onBlur={() => {}}
              />
            )}
            onChange={() => {}}
          />
        </SettingsGroup>
      </div>
    </>
  );
};
