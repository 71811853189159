import { PopulatedUser } from "@dyce/tnt-api";
import { createSelector } from "@reduxjs/toolkit";
import { AdminStateSlice, RootState } from "../../types/types";

export const selectAdminAreaLoading = (state: RootState) =>
  state.admin.requests.length > 0;
export const selectAadUsersAsArray = (state: RootState) =>
  Object.values(state.admin.users.aadUsers);
export const selectAadUsersNotInDyce = createSelector(
  [selectAadUsersAsArray],
  (aad) => aad.filter((user) => !user.isDyceUser)
);

export const selectAllResourcesAsArray = (state: RootState) =>
  state.admin.users.resources;

export const selectAadUsers = (state: RootState): Record<string, unknown> =>
  state.admin.users.aadUsers;
export const selectRequests = (state: RootState) => state.admin.requests;
export const selectAllUsers = (
  state: RootState
): Record<string, PopulatedUser> => state.admin.users.dyceUsers;

export const selectAllUsersAsArray = (state: RootState) =>
  Object.values(state.admin.users.dyceUsers);

export const selectAnyAdminRequestPending = createSelector(
  selectRequests,
  (requests) => requests.length > 0
);

export const selectAllRolesAsArray = (state: RootState) =>
  Object.values(state.admin.users.userRoles);

export const fullAdminSelector = (state: RootState): AdminStateSlice =>
  state.admin;

export const userRolesSelector = createSelector([fullAdminSelector], (admin) =>
  Object.values(admin.users.userRoles)
);

export const adminRequestsSelector = createSelector(
  [fullAdminSelector],
  (admin) => admin.requests
);
export const serverStatusSelector = createSelector(
  [fullAdminSelector],
  (admin) => admin.sync.serverStatus
);

// Clients
export const selectAllClients = (state: RootState) =>
  Object.values(state.admin.clients.allClients);
export const selectLoadingClients = (state: RootState) =>
  state.admin.clients.loadingClients;

export const serverHealthSelector = createSelector(
  [serverStatusSelector],
  ({ status, receiveStats, sendStats }) => {
    const bcErrors = status?.processingErrorCount || 0;
    const latency = status?.latency || 0;
    const stats = { receiveStats, sendStats, bcErrors, latency };

    if (!status || status.health === undefined) {
      return {
        status: "info",
        info: "Server information couldn't be verified",
        ...stats,
      };
    }

    switch (status.health) {
      case 0:
        return {
          status: "danger",
          info: "Server couldn't be reached in quite some time",
          ...stats,
        };

      case 1:
        return {
          status: "info",
          info: "Server is showing some anomalities",
          ...stats,
        };

      case 2:
        return {
          status: "success",
          info: "Server is up and healthy",
          ...stats,
        };

      default:
        return {
          status: "info",
          info: "Server information couldn't be verified",
          ...stats,
        };
    }
  }
);

// Settings
export const selectAdminSettings = (state: RootState) => state.admin.settings;
export const selectAdminSettingsTasks = (state: RootState) =>
  state.admin.settings.tasks;
