import { FunctionComponent } from "react";
// MUI
import {
  Drawer,
  Box,
  Typography,
  IconButton,
  Divider,
  List,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() =>
  createStyles({
    root: { zIndex: 1050 },
    close: {
      display: "flex",
      flexDirection: "row-reverse",
      margin: "0 -1rem 1rem 0",
    },
    drawerHeader: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
    headerText: {
      display: "flex",
      flexDirection: "column",
    },
    drawerBody: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
    drawerPaper: {
      marginTop: 72,
      width: 500,
    },
    footer: {
      position: "absolute",
      height: "4rem",
      bottom: 0,
      width: "100%",
    },
    footerContainer: {
      display: "flex",
      justifyContent: "flex-end",
      height: "99%",
    },
  })
);

interface IDrawerRightProps {
  /**
   * Header information text
   */
  drawerHeader: string;
  /**
   * Header sub information text
   */
  drawerHeaderDescription: string;
  /**
   * Body information text
   */
  drawerBodyHeader: string;
  /**
   * If true, drawer will be shown
   */
  open: boolean;
  /**
   * Callback fired on closing/abort
   */
  onClose: () => void;
  /**
   * Body JSX Element
   */
  body: JSX.Element;
  /**
   * Footer JSX Element
   */
  footer: JSX.Element;
}

/**
 * Prepared for Adminarea => Define user roles
 * @param param0
 * @returns
 */
export const DrawerRight: FunctionComponent<IDrawerRightProps> = ({
  drawerHeader,
  drawerHeaderDescription,
  drawerBodyHeader,
  open,
  onClose,
  body,
  footer,
}) => {
  const classes = useStyles();

  return (
    <div>
      <Drawer className={classes.root} anchor="right" open={open}>
        <div className={classes.drawerPaper}>
          <div className={classes.drawerHeader}>
            <div className={classes.close}>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </div>
            <Box>
              <div className={classes.headerText}>
                <Typography variant="h5" gutterBottom noWrap={true}>
                  {drawerHeader}
                </Typography>
                <Typography variant="h6" gutterBottom noWrap={true}>
                  {drawerHeaderDescription}
                </Typography>
              </div>
            </Box>
          </div>
          <Divider />
          <div className={classes.drawerBody}>
            <List>
              <Typography variant="h6" gutterBottom>
                {drawerBodyHeader}
              </Typography>
              {body}
            </List>
          </div>
          <div className={classes.footer}>
            <Divider />
            <div className={classes.footerContainer}>{footer}</div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
