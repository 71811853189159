export const ROUTES = {
  /* HOME */
  HOME: "/",
  LOGOUT: "/logout",
  LOGIN: "/login",
  /* Settings */
  SETTINGS: "/settings",
  get SETTINGS_TNT() {
    return `${this.SETTINGS}/timetracking`;
  },
  /* Admin Area */
  ADMIN: "/administration",
  get ADMIN_USERS() {
    return `${this.ADMIN}/users`;
  },
  get ADMIN_CLIENT() {
    return `${this.ADMIN}/clients`;
  },
  get ADMIN_SETTINGS() {
    return `${this.ADMIN}/settings`;
  },
  get ADMIN_AADUSERS() {
    return `${this.ADMIN}/users/aadusers`;
  },
  OIDC: "/oidc",
  /* T&T Area */
  TNT: "/timetracking",
  get TNT_RECS() {
    return `${this.TNT}/dailyrecordings`;
  },
  get TNT_TEMPLATES() {
    return `${this.TNT}/templates`;
  },
  get TNT_INCOMPLETE() {
    return `${this.TNT}/incomplete`;
  },
  get TNT_TASKS() {
    return `${this.TNT}/tasks`;
  },
  get TNT_CALENDAR() {
    return `${this.TNT}/calendar`;
  },
  /* Foreign Area */
  TNT_DEVOPS: "/tnt",
  DETAILS_DEVOPS: "/details",
  JIRA_GENERAL_PAGE: "/page",
  JIRA_DASHBOARD: "/dashboard",
  JIRA_ISSUE_CONTEXT: "/fast-tracking",
  JIRA_ISSUE_CONTEXT_DIALOG: "/fast-tracking-dialog",
  JIRA_WORKLOG: "/worklog",
  JIRA_WORKLOG_DIALOG: "/worklog-dialog",
  JIRA_PROJECT_SETTINGS: "/project-settings",
  get JIRA_PROJECT_SETTINGS_INFO() {
    return `${this.JIRA_PROJECT_SETTINGS}/info`;
  },
  JIRA_PROJECT_SETTINGS_DIALOG: "/project-settings-dialog",
  /* Instance Error */
  ERROR: "/error",
};
