// Bryntum
import { EventModelConfig, EventStoreConfig } from "@bryntum/calendar";
import { BryntumCalendarProps } from "@bryntum/calendar-react";

export const storeConfig = (
  events: Partial<EventModelConfig>[]
): Partial<EventStoreConfig> => {
  return {
    tree: false,
    data: events,
    allowNoId: true,
    autoLoad: false,
    syncDataOnLoad: false,
    autoCommit: false,
    id: "dyce-calendar-store",
    fields: [
      {
        name: "id",
        type: "string",
        defaultValue: undefined,
      },
      {
        name: "name",
        type: "string",
      },
      {
        name: "startDate",
        type: "date",
      },
      {
        name: "endDate",
        type: "date",
      },
      {
        name: "allDay",
        type: "boolean",
        defaultValue: false,
      },
      {
        name: "eventColor",
        type: "string",
      },
      {
        name: "cls",
        type: "string",
        defaultValue: undefined,
      },
      {
        name: "timerecordingId",
        type: "string",
        defaultValue: "",
      },
      {
        name: "timerecording",
        type: "object",
        defaultValue: null,
      },
      {
        name: "readOnly",
        type: "boolean",
      },
      {
        name: "draggable",
        type: "boolean",
      },
      {
        name: "resizable",
        type: "boolean",
      },
      {
        name: "editable",
        type: "boolean",
      },
    ],
  };
};

// new EventStore(storeConfig({ events: events }));

export const calendarConfig: BryntumCalendarProps = {
  tbar: {
    hidden: true,
  },
  nonWorkingDays: {
    0: true, // Sunday
    1: false, // Monday
    2: false, // Tuesday
    3: false, // Wednesday
    4: false, // Thursday
    5: false, // Friday
    6: true, // Saturday
  },
};
