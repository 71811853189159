import {
  AadUser,
  AdminSettings,
  PopulatedUser,
  CurrentUser,
  User,
  Role,
  ServerStatusResponse,
  PopulatedRole,
  Client,
} from "../types";
import buildQuery, { QueryOptions } from "odata-query";
import { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

// User Action Types
type user_getAllAAD = (
  query?: Partial<QueryOptions<AadUser>>
) => Promise<AadUser[]>;
type user_getAll = (
  query?: Partial<QueryOptions<PopulatedUser>>
) => Promise<Partial<PopulatedUser>[]>;
type user_getOne = (
  id: string,
  query?: Partial<QueryOptions<PopulatedUser>>
) => Promise<Partial<PopulatedUser>>;
type user_getCurrent = (
  query?: Partial<QueryOptions<CurrentUser>>
) => Promise<CurrentUser>;
type user_create = (
  payload: User,
  query?: Partial<QueryOptions<User>>
) => Promise<Partial<PopulatedUser>>;
type user_update = (
  id: string,
  payload: Partial<PopulatedUser>
) => Promise<PopulatedUser>;
type user_setRoles = (id: string, roles: Role[]) => Promise<void>;
type user_remove = (id: string) => Promise<void>;

// Role Action Types
type roles_getAll = (
  query?: Partial<QueryOptions<Role>>
) => Promise<Partial<PopulatedRole>[]>;
type roles_getOne = (
  is: string,
  query?: Partial<QueryOptions<Role>>
) => Promise<Partial<PopulatedRole>>;

// Sync Action Types
type sync_getStatus = () => Promise<ServerStatusResponse>;

// User Action Types
type clients_getAllClients = (
  query?: Partial<QueryOptions<Client>>
) => Promise<Client[]>;
type clients_create = (payload: Partial<Client>) => Promise<Client>;
type clients_update = (payload: Partial<Client>, id: string) => Promise<Client>;
type clients_remove = (id: string) => Promise<void>;
type clients_getToken = (id: string) => Promise<string>;

// Settings Action Types
type settings_get = () => Promise<AdminSettings | undefined>;
type settings_create = (payload: AdminSettings) => Promise<string>;

interface UserAlias {
  /**
   * Gets all Active Directory User
   * @param query OData Query
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.users.getAllAD()
   */
  getAllAAD: user_getAllAAD;
  /**
   * Gets all DYCE users
   * @param query OData Query
   * @example
   * // Get every single user
   * const client = new TnTClient(args).getAdminService()
   * await client.users.getAll()
   * // Only get the first 10 users
   * const client = new TnTClient(args).getAdminService()
   * await client.users.getAll({ top: 10 })
   */
  getAll: user_getAll;
  /**
   * Gets only one user by ID
   * @param id The ID of the user
   * @param query OData Query
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.users.getOne("0000-0000-0000-0000")
   */
  getOne: user_getOne;
  /**
   * Gets the currently logged in user
   * @param query OData Query
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.users.getOne("0000-0000-0000-0000")
   */
  getCurrent: user_getCurrent;
  /**
   * Creates a new user from AD
   * @param payload
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.users.create({ name: "Example", resource: { id: "0000-0000-0000-0000" } })
   */
  create: user_create;
  /**
   * Update a single user
   * @param id
   * @param payload
   * const client = new TnTClient(args).getAdminService()
   * await client.users.update("0000-0000-0000-0000", { name: "New Example" })
   */
  update: user_update;
  /**
   * Assign the roles to the user
   * @param id User ID
   * @param roles The roles the user should have
   * const client = new TnTClient(args).getAdminService()
   * await client.users.setRoles("0000-0000-0000-0000", [{ id: "001-001-001" }])
   */
  setRoles: user_setRoles;
  /**
   * Deletes a user from the DYCE user management.
   * The user will still remain in AD.
   * @param id
   * const client = new TnTClient().getAdminService()
   * await client.users.remove("0000-0000-0000-0000")
   */
  remove: user_remove;
}

interface RolesAlias {
  /**
   * Gets all roles in DYCE
   * @param query OData Query
   * @example
   * // Get every single role
   * const client = new TnTClient(args).getAdminService()
   * await client.roles.getAll()
   * // Only get the first 10 roles
   * const client = new TnTClient(args).getAdminService()
   * await client.roles.getAll({ top: 10 })
   */
  getAll: roles_getAll;
  /**
   * Gets only one role by ID
   * @param id The ID of the role
   * @param query OData Query
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.roles.getOne("0000-0000-0000-0000")
   */
  getOne: roles_getOne;
}

interface SyncAlias {
  /**
   * Gets the server status with heartbeat and events.
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.sync.getStatus()
   */
  getStatus: sync_getStatus;
}

interface ClientAlias {
  /**
   * Gets the server status with heartbeat and events.
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.clients.getAll()
   */
  getAll: clients_getAllClients;
  /**
   * Creates new client.
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.clients.create()
   */
  create: clients_create;
  /**
   * Update existing client.
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.clients.update()
   */
  update: clients_update;
  /**
   * Deletes a user from the DYCE user management.
   * The user will still remain in AD.
   * @param id
   * const client = new TnTClient().getAdminService()
   * await client.clients.remove({ name: "Example", resource: { id: "0000-0000-0000-0000" } })
   */
  remove: clients_remove;
  /**
   * Gets the server status with heartbeat and events.
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.clients.getAll()
   */
  getToken: clients_getToken;
}

interface SettingsAlias {
  /**
   * Gets the settings for selected company.
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.settings.getStatus()
   */
  get: settings_get;
  /**
   * Gets the settings for selected company.
   * @example
   * const client = new TnTClient(args).getAdminService()
   * await client.settings.getStatus()
   */
  create: settings_create;
}

export class AdminService {
  public baseUrl: string;
  private rolesUrl: string;
  private syncUrl: string;
  private clientsUrl: string;
  private settingsUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = combineURLs(baseUrl, "/users");
    this.rolesUrl = combineURLs(baseUrl, "/roles");
    this.syncUrl = combineURLs(baseUrl, "/sync");
    this.clientsUrl = combineURLs(baseUrl, "/clients");
    this.settingsUrl = combineURLs(baseUrl, "/settings");

    this.axiosInstance = instance;
  }

  public get users(): UserAlias {
    return {
      getAllAAD: this.userGetAllAAD,
      getAll: this.userGetAll,
      getOne: this.userGetOne,
      getCurrent: this.userGetCurrent,
      create: this.userCreate,
      update: this.userUpdate,
      setRoles: this.userSetRoles,
      remove: this.userRemove,
    };
  }

  public get roles(): RolesAlias {
    return {
      getAll: this.rolesGetAll,
      getOne: this.rolesGetOne,
    };
  }

  public get sync(): SyncAlias {
    return {
      getStatus: this.syncGetStatus,
    };
  }

  public get clients(): ClientAlias {
    return {
      getAll: this.clientsGetAll,
      create: this.clientsCreate,
      update: this.clientsUpdate,
      remove: this.clientsRemove,
      getToken: this.clientsGetToken,
    };
  }

  public get settings(): SettingsAlias {
    return {
      get: this.settingsGetAll,
      create: this.settingsCreate,
    };
  }

  // User Functions
  private userGetAllAAD: user_getAllAAD = async (query) => {
    const url = `${this.baseUrl}/aad${buildQuery(query)}`;

    const response = await this.axiosInstance.get<AadUser[]>(url);

    return response.data;
  };

  private userGetAll: user_getAll = async (query) => {
    const url = `${this.baseUrl}${buildQuery(query)}`;

    const response =
      await this.axiosInstance.get<Partial<PopulatedUser>[]>(url);

    return response.data;
  };

  private userGetOne: user_getOne = async (id, query) => {
    const url = `${this.baseUrl}/${id}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Partial<PopulatedUser>>(url);

    return response.data;
  };

  private userGetCurrent: user_getCurrent = async (query) => {
    const url = `${this.baseUrl}/current${buildQuery(query)}`;

    const response = await this.axiosInstance.get<CurrentUser>(url);

    return response.data;
  };

  private userCreate: user_create = async (payload, query) => {
    const url = `${this.baseUrl}${buildQuery(query)}`;

    const result = await this.axiosInstance.post<User>(url, payload);

    return result.data;
  };

  private userUpdate: user_update = async (id, payload) => {
    const url = `${this.baseUrl}/${id}`;

    const result = await this.axiosInstance.put<PopulatedUser>(url, payload);

    return result.data;
  };

  private userSetRoles: user_setRoles = async (id, roles) => {
    const url = `${this.baseUrl}/${id}/setRoles`;

    await this.axiosInstance.post(url, { roles });

    return;
  };

  private userRemove: user_remove = async (id) => {
    const url = `${this.baseUrl}/${id}`;

    await this.axiosInstance.delete(url);

    return;
  };

  // Role Functions
  private rolesGetAll: roles_getAll = async (query) => {
    const url = `${this.rolesUrl}${buildQuery(query)}`;

    const response =
      await this.axiosInstance.get<Partial<PopulatedRole>[]>(url);

    return response.data;
  };

  private rolesGetOne: roles_getOne = async (id, query) => {
    const url = `${this.rolesUrl}/${id}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Partial<PopulatedRole>>(url);

    return response.data;
  };

  // Sync Functions
  private syncGetStatus: sync_getStatus = async () => {
    const query = buildQuery({ top: 100 });

    const url = `${this.syncUrl}/info${query}`;

    const response = await this.axiosInstance.get<ServerStatusResponse>(url);

    return response.data;
  };

  // Client Functions
  private clientsGetAll: clients_getAllClients = async (query) => {
    const url = `${this.clientsUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Client[]>(url);

    return response.data;
  };

  private clientsCreate: clients_create = async (payload) => {
    const url = `${this.clientsUrl}`;

    const response = await this.axiosInstance.post<Client>(url, payload);

    return response.data;
  };

  private clientsUpdate: clients_update = async (payload, id) => {
    const url = `${this.clientsUrl}/${id}`;

    const response = await this.axiosInstance.put<Client>(url, payload);

    return response.data;
  };

  private clientsRemove: clients_remove = async (id) => {
    const url = `${this.clientsUrl}/${id}`;

    await this.axiosInstance.delete(url);

    return;
  };

  private clientsGetToken: clients_getToken = async (id) => {
    const url = `${this.clientsUrl}/${id}/CreateApiKey`;

    const response = await this.axiosInstance.get<string>(url);

    return response.data;
  };

  // Settings Functions
  private settingsGetAll: settings_get = async () => {
    const url = `${this.settingsUrl}`;

    const response = await this.axiosInstance.get<string>(url);

    return typeof response.data === "string"
      ? undefined
      : (response.data as AdminSettings);
  };

  private settingsCreate: settings_create = async (value) => {
    const url = `${this.settingsUrl}`;

    this.axiosInstance.interceptors.request.use((config) => {
      // add access token from DevOps-User
      config.headers = {
        ...config.headers,
        contentType: "text/plain",
      };

      return config;
    });
    const payload = JSON.stringify(value);

    const res = await this.axiosInstance.post<string>(url, payload);

    return res.data;
  };
}
