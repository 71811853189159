import { QueryOptions } from "odata-query";
import { FormatterProps } from "react-data-grid";
import { EventModelConfig } from "@bryntum/calendar";
// Dyce-Lib
import {
  CalendarSensitivity,
  JobPlanningLine,
  NonBillableReasonOptions,
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
} from "@dyce/tnt-api";
import {
  CalendarRepeatType,
  LookUpActivityOptions,
  LookUpOptions,
  LookUpShortOptions,
} from "@dyce/interfaces";

/**
 * A row in our datagrid. Must at least provide an ID.
 */
export interface IDatagridRow<T = any> {
  id: string;
  /**
   * Individual cells as a key/value pair
   */
  cells: T;
}

/**
 * A column in our datagrid. Must provide an ID and a display name
 */
export interface IDatagridColumn {
  /**
   * Object key of the column
   */
  key: string;
  /**
   * Name to display in Header
   */
  name: string;
  /**
   * Whether column is shown in Datagrid or not
   */
  display: boolean;
  /**
   * If the column is editable or not
   */
  editable?: boolean;
  /**
   * Width for column in ratio (1 === 100%); last column === undefined
   */
  width?: number;
  /**
   * If column is searchable or not by user input
   */
  searchable: boolean;
  /**
   * If column is sortable or not
   */
  sortable?: boolean;
  /**
   * Individual Cell-rendering needs a formatter
   */
  formatter?: (
    value: FormatterProps<IDatagridRow<any>, unknown>
  ) => JSX.Element;
}

/**
 * Datagrid Prop-Types for Infinite Scrolling
 */
export type InfScrollProps = {
  /**
   * Serve minimum row count at which infScroll can be triggered
   */
  infScrollCanTriggerAt?: number;
  /**
   * Set the row number at which infinite scrolling should trigger at
   */
  triggerAtBottom?: number;
  /**
   * Select column for ordering on direction "NONE", default = id, s
   */
  defaultColumnKey?: string;
  /**
   * Provide count of how much rows should be loaded more at inf. scrolling
   */
  loadMoreCount?: number;
  /**
   * LookUp controller to can generate skeleton rows
   */
  controller?: any;
  /**
   * Provide switch if end of list is reached or not in upper direction
   */
  canLoadMoreUp?: boolean;
  /**
   * Provide switch if end of list is reached or not in lower direction
   */
  canLoadMoreDown?: boolean;
  /**
   * Provide switch if skeletons are shown on open LookUp/Datagrid
   */
  openWithSkeletons?: boolean;
};

/**
 * Datagrid RenderProps Type
 */
export interface RenderProps {
  column: IDatagridColumn;
  row: Record<string, any>;
  rowIdx: number;
}

/**
 * Datagrid onEnterHelper Type
 */
export interface IEnterHelperProps {
  value: string;
  id: string;
  onEnter: (id: string) => void;
}

/**
 * Datagrid ArrowKey typing
 */
export type ArrowKeys = {
  arrowUp: boolean;
  arrowDown: boolean;
  hotkeyWasPressed: boolean;
};

/**
 * LookUp OnChangeEvent Type
 */
export type OnChangeEvent<T> = {
  rawInput: string | null;
  selectedValue: (T & { id: string }) | null;
};

//-----------------
// TimeTrackingTool
// ----------------
export type ErrorDef = {
  /**
   * Reference to controlled field
   */
  ref: string;
  /**
   * Message for Error (individual) and type (manually)
   */
  errorOption: {
    type: string;
    message: string;
  };
};

export type MemoryState = {
  duration: number;
  pause: number;
  billableDuration: number;
  lastStart: string | null;
  lastEnd: string | null;
  tntId: string;
  nonBillableReasonChanged: boolean;
  nonBillableReason: NonBillableReasonOptions;
};

export type PrevStateObject = {
  timeInput: RecordEntry & Partial<RecordTemplate & RecordTimeRec>;
  memory: MemoryState;
  errors: ErrorDef[];
};

//----------------------------
// TimeTrackingTool components
//----------------------------
export class InfiniteScrollSettings {
  static values(): DatagridSettings {
    return {
      initialTop: 20,
      loadAbove: 12,
      loadBelow: 18,
      loadMore: 12,
      triggerAtBottom: 5,
    };
  }
}

type DatagridSettings = {
  initialTop: number;
  loadAbove: number;
  loadBelow: number;
  loadMore: number;
  triggerAtBottom: number;
};

export type ValidateRecordTask<T> = {
  entries: LookUpEntries<T>;
  memoryEntries: LookUpEntry<T>;
  forceChange: {
    description: string | null;
    jobTaskFilledFromActivity: boolean;
  };
  ids: {
    customer: string;
    job: string;
    jobTask: string;
    activity: string;
  };
  memoryIds: {
    customer: string;
    job: string;
    jobTask: string;
    activity: string;
  };
  pending: {
    field: LookUpOptions | LookUpShortOptions | null;
    isLoading: boolean;
    pendingApiCalls: number;
    blurred: BlurrInfos | null;
  };
  infos: {
    customerIsConfirming: boolean;
    activityHasValue: boolean;
    initialJobTask: boolean;
    initialActivity: boolean;
    fromController: LookUpOptions | null;
    memoryJobTaskId: string;
    memoryActivityId: string;
    deletedByUser: boolean;
    changedJobTask: boolean;
    jobTaskLoading: boolean;
    activityLoading: boolean;
    openWithSkeletons: boolean;
    focusActivity: boolean;
    customerCallCompleted: boolean;
    activityCallCompleted: boolean;
    checkForSameTnTModelId: string;
    filledFromJobTask: boolean;
    storeJobPlanningLineInfo: JobPlanningLine | null;
    memoryLastJobId: string;
    memoryLastJobTaskId: string;
    blockMemoryLastJobTaskId: boolean;
    activityIdBeforeError: string;
    jobHadError: boolean;
    jobTaskBlurred: boolean;
  };
  dependency: {
    deleteFields: boolean;
  };
  odataFilter: {
    filterForCustomer: OdataJobFilter | null;
    filterForJob: OdataJobTaskFilter | null;
    topAbove: number;
    topBelow: number;
    loadMoreCount: number;
    topInitial: number;
    loadMoreUp: boolean;
    loadMoreDown: boolean;
  };
  orderBy: {
    customer: string;
    job: string;
    jobTask: string;
    activity: string;
  };
};

export type BlurrInfos = {
  field: LookUpOptions | null;
  inputValue: string | null;
  blurFn: () => void;
  onChangeFn: (...event: any[]) => void;
};

export type LookUpEntries<T> = {
  customer: IDatagridRow<T>[];
  job: IDatagridRow<T>[];
  jobTask: IDatagridRow<T>[];
  activity: IDatagridRow<T>[];
};

export type LookUpEntry<T> = {
  customer: IDatagridRow<T> | null;
  job: IDatagridRow<T> | null;
  jobTask: IDatagridRow<T> | null;
  activity: IDatagridRow<T> | null;
};

export type HandleNewEntry<T> = ({
  newEntries,
  key,
  combineEntries,
}: {
  newEntries: IDatagridRow<T>[];
  key: LookUpActivityOptions | LookUpShortOptions;
  combineEntries?: CombineEntries<T> | undefined;
}) => void;

export type InitialInfScrollFilter = [
  {
    or: [
      {
        [x: string]: { [x: string]: string };
      },
      SameEntry?,
    ];
  },
  (OdataJobFilter | OdataJobTaskFilter)?,
];

export type InfScrollFilter = {
  or: [
    { [x: string]: { [x: string]: string } }, // { [columnKey]: { [gtLt]: [firstOrLastValue] } }
    {
      and: [
        { [x: string]: { eq: string } }, // { [columnKey]: { eq: [firstOrLastValue] } }
        { no: { [x: string]: string } }, // { no: { [gtLt]: [firstOrLastValue] } }
      ];
    },
  ];
  and?: [(OdataJobFilter | OdataJobTaskFilter)?, { or: any[] }?];
};

export type FieldFilter = [
  (OdataJobFilter | OdataJobTaskFilter)?,
  {
    no: string | null;
  }?,
  { or: any[] }?,
];

export type OdataJobFilter = {
  customer: { id: { eq: { type: "guid"; value: string } } };
};

export type OdataJobTaskFilter = {
  job: { id: { eq: { type: "guid"; value: string } } };
};

export type InfScrollOptions<T> = {
  direction: "UP" | "DOWN" | "NONE";
  defaultQuery: QueryOptions<T>;
  additionQuery?: QueryOptions<T>;
};

export type CombineEntries<T> = {
  oldEntries: IDatagridRow<T>[];
  direction: "UP" | "DOWN" | "NONE";
  loadMoreUp: boolean;
  loadMoreDown: boolean;
};

export type SameEntry = {
  id: { eq: { type: "guid"; value: string } };
};

//----------------------------
// Calendar components
//----------------------------
export type EventInput = {
  id: string | null;
  title: string;
  start: {
    startDate: string;
    startTime: string;
  };
  end: {
    endDate: string;
    endTime: string;
  };
  allDay: boolean;
  repeat: {
    recurrenceCombo: CalendarRepeatType;
    recurrenceRule?: string;
  };
};

export type PopulatedEventModel = EventModelConfig & {
  timerecordingId: string | null | undefined;
  timerecording: RecordTimeRec | null;
  sensitivity: CalendarSensitivity | null;
};
