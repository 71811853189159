import { FunctionComponent } from "react";
// Helper
import clsx from "clsx";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { red, lightBlue, green, yellow } from "@mui/material/colors";
import { alpha as fade } from "@mui/material/styles";

type TSizeClassNames = "smallSize" | "mediumSize" | "largeSize";
type TCircleClassNames = "successCircle" | "dangerCircle" | "infoCircle";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      paddingTop: "12px",
      paddingBottom: "12px",
    },
    badge: {
      position: "absolute",
      right: 10,
      top: 10,
      display: "flex",
      flexBasis: "1 0 100px",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "row",
    },
    dangerBadge: {
      color: red[500],
    },
    infoBadge: {
      color: lightBlue[500],
    },
    successBadge: {
      color: green[500],
    },
    circle: {
      margin: 5,
      borderRadius: "50%",
    },
    smallSize: {
      width: 10,
      height: 10,
    },
    mediumSize: {
      width: 25,
      height: 25,
    },
    largeSize: {
      width: 30,
      height: 30,
    },
    dangerCircle: {
      backgroundColor: red[500],
      boxShadow: `0 0 0 ${fade(red[500], 0.5)}`,
    },
    warningCircle: {
      backgroundColor: yellow[500],
      boxShadow: `0 0 0 ${fade(yellow[500], 0.5)}`,
      animation: `$pulsing-warning 1500ms ${theme.transitions.easing.easeOut} infinite`,
    },
    infoCircle: {
      backgroundColor: lightBlue[500],
      boxShadow: `0 0 0 ${fade(lightBlue[500], 0.5)}`,
      animation: `$pulsing-info 1500ms ${theme.transitions.easing.easeOut} infinite`,
    },
    successCircle: {
      backgroundColor: green[500],
      boxShadow: `0 0 0 ${fade(green[500], 0.5)}`,
      animation: `$pulsing-success 1500ms ${theme.transitions.easing.easeOut} infinite`,
    },
    dangerPulse: {
      animation: `$pulsing-danger 1500ms ${theme.transitions.easing.easeOut} infinite`,
    },

    "@keyframes pulsing-danger": {
      "0%": {
        boxShadow: `0 0 0 0 ${fade(red[500], 0.5)}`,
      },
      "70%": {
        boxShadow: `0 0 0 8px ${fade(red[500], 0.0)}`,
      },
      "100%": {
        boxShadow: `0 0 0 0 ${fade(red[500], 0.0)}`,
      },
    },
    "@keyframes pulsing-info": {
      "0%": {
        boxShadow: `0 0 0 0 ${fade(lightBlue[500], 0.5)}`,
      },
      "70%": {
        boxShadow: `0 0 0 8px ${fade(lightBlue[500], 0.0)}`,
      },
      "100%": {
        boxShadow: `0 0 0 0 ${fade(lightBlue[500], 0.0)}`,
      },
    },
    "@keyframes pulsing-success": {
      "0%": {
        boxShadow: `0 0 0 0 ${fade(green[500], 0.5)}`,
      },
      "70%": {
        boxShadow: `0 0 0 8px ${fade(green[500], 0.0)}`,
      },
      "100%": {
        boxShadow: `0 0 0 0 ${fade(green[500], 0.0)}`,
      },
    },
    "@keyframes pulsing-warning": {
      "0%": {
        boxShadow: `0 0 0 0 ${fade(yellow[500], 0.5)}`,
      },
      "70%": {
        boxShadow: `0 0 0 8px ${fade(yellow[500], 0.0)}`,
      },
      "100%": {
        boxShadow: `0 0 0 0 ${fade(yellow[500], 0.0)}`,
      },
    },
  })
);

interface ITBeatProps {
  /**
   * Variants of design
   * @default "info"
   */
  variant?: "info" | "success" | "danger" | "warning";
  /**
   * If true, pulse is shown in red with variant 'danger'
   * @default false
   */
  pulseOnDanger?: boolean;
  /**
   * Sizes of heart-beat circle
   * @default 'medium'
   */
  size?: "small" | "medium" | "large";
  /**
   * If true, container has vertical padding of 12px
   * @default true
   */
  withPadding?: boolean;
}

/**
 * UI element that represents the pulsing beat of a Heartbeat indicator. It can have various colors and will receive a beat trigger in the future.
 */
export const Beat: FunctionComponent<ITBeatProps> = ({
  variant = "info",
  pulseOnDanger = false,
  size = "medium",
  withPadding = true,
}) => {
  const classes = useStyles();

  return (
    <div className={withPadding ? classes.container : ""}>
      <div
        data-testid="beat"
        className={clsx(
          classes.circle,
          classes[(size + "Size") as TSizeClassNames],
          classes[(variant + "Circle") as TCircleClassNames],
          {
            [classes["dangerPulse"]]: pulseOnDanger && variant === "danger",
          }
        )}
      />
    </div>
  );
};
