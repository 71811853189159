import {
  useState,
  useEffect,
  ChangeEvent,
  FunctionComponent,
  useRef,
  MutableRefObject,
} from "react";
// Helper
import {
  parseBackToDec,
  parseCommaSeparator,
  parseInput,
  parsePropsInMinutes,
  parseValue,
  checkDurationInput,
  checkForMinuteToken,
} from "./utils";
import { RefCallBack } from "react-hook-form";
// MUI
import { TextField } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface IDurationFieldProps {
  /**
   * Textfield-label
   */
  label: string;
  /**
   * Common styling for textfield
   */
  style?: string;
  /**
   * Default and/or input value
   */
  value: number;
  /**
   * If true, Textfield is disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * If true, Textfield is marked as required (form)
   * @default false
   */
  isRequired?: boolean;
  /**
   * Callback fired onChange event
   */
  onChange: (value: any) => void;
  /**
   * Callback fired onBlur event
   * @param value Internal value
   * @param ref MutableRefObject<HTMLInputElement | null>
   * @returns void
   */
  onBlur?: (value: any, ref: MutableRefObject<HTMLInputElement | null>) => void;
  /**
   * React-Hook-Form reference hook, e.g. to make component focusable
   */
  refHook?: RefCallBack;
  /**
   * Define tabIndex to focus correct field
   */
  tabIndex?: number;
  /**
   * If true, the input element will be focused during the first mount
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Unique ID for testing field
   * @default "test-cypress-id"
   */
  testId?: string;
  /**
   * Define size of textfield
   * @default "medium"
   */
  size?: "small" | "medium";
}

export const DurationField: FunctionComponent<IDurationFieldProps> = ({
  style,
  value,
  label,
  onChange,
  onBlur,
  disabled = false,
  isRequired = false,
  refHook,
  tabIndex,
  autoFocus = false,
  size = "medium",
  testId = "test-cypress-id",
  ...rest
}) => {
  // Refs
  const inputRef = useRef<HTMLInputElement | null>(null);

  // States
  const [, setTime] = useState<string>(parseValue(value));
  const [internalTime, setInternalTime] = useState<string>(parseValue(value));

  useEffect(() => {
    setTime(parsePropsInMinutes(value));
    setInternalTime(parsePropsInMinutes(value));
    // Needs to trigger when input come from outside (like Template)
    const parsedValue = parsePropsInMinutes(value);
    onChange(parseBackToDec(parsedValue));
  }, [value]);

  const handleChange = (
    val: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setInternalTime(val.target.value);
  };

  const handleBlur = () => {
    let v = checkForMinuteToken(internalTime);
    v = parseCommaSeparator(v);
    v = parseInput(v);

    if (checkDurationInput(v)) {
      setTime(v);
      setInternalTime(v);

      onChange(parseBackToDec(v));
      onBlur && onBlur(parseBackToDec(v), inputRef);
    } else {
      setInternalTime(v);

      onChange("Error");
      onBlur && onBlur("Error", inputRef);
    }
  };

  return (
    <TextField
      {...rest}
      id={testId}
      inputProps={{
        tabIndex: tabIndex,
      }}
      size={size}
      data-testid="timefield"
      inputRef={(e: HTMLInputElement) => {
        refHook && refHook(e);
        inputRef.current = e;
      }}
      autoFocus={autoFocus}
      onFocus={(e) => e.target.select()}
      className={style}
      value={internalTime === "0:00" ? "" : internalTime}
      placeholder={disabled ? "" : "h:mm"}
      required={isRequired}
      onChange={handleChange}
      onBlur={handleBlur}
      autoComplete="off"
      disabled={disabled}
      label={
        label.length === 0 ? (
          <ErrorOutlineIcon
            style={{
              color: "#f44336",
              transform: "scale(1.2)",
            }}
          />
        ) : (
          label
        )
      }
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};
