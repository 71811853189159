import { useEffect, useState, useRef, FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { getTemplateInfo, handleTemplateListSearch } from "./utils";
import { useHotkeys } from "react-hotkeys-hook";
// Dyce-Lib
import {
  AllDone,
  Dialog,
  DialogSearchField,
  LoadingSpinner,
  useStaticContent,
  useTooltipContent,
} from "@dyce/ui";
import { DyceTheme } from "@dyce/theme";
import { useDoubleClick, useUpdateEffect } from "@dyce/hooks";
import { JobTaskStatusOptions, RecordTemplate } from "@dyce/tnt-api";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Avatar,
  Box,
  List,
  ListItemAvatar,
  ListItemText,
  Typography,
  DialogContent,
  ListItemButton,
  Icon,
} from "@mui/material";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    dialogCategory: {
      display: "flex",
      width: "100%",
      alignItems: "center",
      padding: "0.5rem",
      borderTopLeftRadius: theme.shape.borderRadius,
      borderTopRightRadius: theme.shape.borderRadius,
      background:
        "linear-gradient(180deg, rgba(156,153,204,0.25) 0%, rgba(156,153,204,0.025) 100%)",
    },
    dialogItemContainer: {
      display: "flex",
      flexDirection: "column",
    },
    dialogItemContainerItem: {
      display: "inherit",
      flexDirection: "row",
      alignItems: "center",
    },
    searchContainer: {
      marginTop: "0.5rem",
      marginBottom: "0.5rem",
      width: "50%",
    },
    searchField: {
      width: "100%",
    },
    infoOptionsWrapper: {
      marginTop: "1.25rem",
    },
    infoOptionsContainer: {
      display: "flex",
      alignItems: "center",
      paddingTop: "0.25rem",
    },
    infoOptionsIcon: {
      marginBottom: "1rem",
    },
    infoOptionsText: {
      marginLeft: "0.5rem",
      marginRight: "0.25rem",
      marginTop: "0.25rem",
    },
    hyperLink: {
      textDecoration: "underline",
      cursor: "pointer",
      marginTop: "0.25rem",
    },
  })
);

interface ITemplateListProps {
  /**
   * Callback fired when dialog open/close
   */
  showTemplateDialog: (value: boolean) => void;
  /**
   * If true, dialog will be shown
   */
  openTemplateDialog: boolean;
  /**
   * Callback fired when template is submitted
   */
  onSelectTemplate: (template: RecordTemplate) => void;
  /**
   * Callback fired initial when dialog opens
   */
  getAllTemplates?: () => void;
  /**
   * Templates as 2-dim array
   */
  templates: [string, RecordTemplate[]][];
}

export const TemplateList: FunctionComponent<ITemplateListProps> = ({
  showTemplateDialog,
  openTemplateDialog,
  onSelectTemplate,
  getAllTemplates,
  templates,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // Refs
  const divRef = useRef<null | HTMLDivElement>(null);
  const dialogInputRef = useRef<HTMLInputElement | null>(null);

  const handleDoubleClick = () => {
    // handleOnClick(id, true);
    submitTemplate();
  };
  const click = useDoubleClick(handleDoubleClick);

  // UseStates
  const [selectedTemplateId, setSelectedTemplateId] = useState<string>("");
  const [selectedIndex, setSelectedIndex] = useState<[number, number]>([
    -1, -1,
  ]);
  const [selectedTemplate, setSelectedTemplate] =
    useState<RecordTemplate | null>(null);
  const [templatesCopy, setTemplatesCopy] =
    useState<[string, RecordTemplate[]][]>(templates);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(true);

  // UseEffects
  useEffect(() => {
    if (openTemplateDialog) {
      callApi();
    }
  }, [openTemplateDialog]);

  useUpdateEffect(() => {
    if (templates.length > 0) {
      setTemplatesCopy(templates);
    }
    setLoading(false);
  }, [templates]);

  const callApi = async () => {
    getAllTemplates && getAllTemplates();
  };

  useEffect(() => {
    if (selectedIndex[0] === -1) {
      return;
    }
    setSelectedTemplate(templatesCopy[selectedIndex[0]][1][selectedIndex[1]]);
    setSelectedTemplateId(
      templatesCopy[selectedIndex[0]][1][selectedIndex[1]].id
    );
    // Focus current selected Item
    document.getElementById(`${selectedIndex[0]}${selectedIndex[1]}`)?.focus();
  }, [selectedIndex]);

  /**
   * Hotkey behavior
   */
  useHotkeys(
    "escape",
    () => {
      if (openTemplateDialog) {
        cancelTemplate();
      }
    },
    {},
    [selectedIndex, selectedTemplate, openTemplateDialog]
  );

  useHotkeys(
    "enter",
    () => {
      if (openTemplateDialog) {
        submitTemplate();
      }
    },
    {},
    [selectedIndex, selectedTemplate, openTemplateDialog]
  );

  useHotkeys(
    "down",
    () => {
      if (openTemplateDialog) {
        const lastFirstNum: number = selectedIndex[0];
        if (selectedIndex[0] === -1) {
          setSelectedIndex([0, 0]);
        } else if (
          selectedIndex[1] <
          templatesCopy[selectedIndex[0]][1].length - 1
        ) {
          setSelectedIndex(([, j]) => [lastFirstNum, (j += 1)]);
        } else if (selectedIndex[0] < templatesCopy.length - 1) {
          setSelectedIndex(([i]) => [(i += 1), 0]);
        }
      }
    },
    {},
    [selectedIndex, selectedTemplate, openTemplateDialog]
  );

  useHotkeys(
    "up",
    () => {
      if (openTemplateDialog) {
        const lastSecondNum: number = selectedIndex[0] - 1;
        const lastFirstNum: number = selectedIndex[0];
        if (selectedIndex[0] === -1) {
          setSelectedIndex([templatesCopy.length - 1, 0]);
        } else if (selectedIndex[1] === 0) {
          if (selectedIndex[0] === 0) {
            return;
          }
          setSelectedIndex(([i]) => [
            (i -= 1),
            templatesCopy[lastSecondNum][1].length - 1,
          ]);
        } else if (selectedIndex[1] > 0) {
          setSelectedIndex(([, j]) => [lastFirstNum, (j -= 1)]);
        }
      }
    },
    {},
    [selectedIndex, selectedTemplate, openTemplateDialog]
  );

  // Handler
  /**
   * Set states for selected object and id, also state update for
   * selected index (2-Dim. Array)
   * @param record RecordTemplate object
   * @param firstDim index counter i from map loop
   * @param secondDim index counter j from map loop
   */
  const handleListItemClick = (
    record: RecordTemplate,
    firstDim: number,
    secondDim: number
  ) => {
    setSelectedIndex([firstDim, secondDim]);
    setSelectedTemplate(record);
    setSelectedTemplateId(record.id ? record.id : "");
    click(record.id ? record.id : "");
    if (selectedTemplateId !== record.id) {
      setErrorText(undefined);
    }
  };

  /**
   * Handle submit function
   */
  const submitTemplate = () => {
    if (selectedTemplate) {
      if (
        selectedTemplate.jobTask !== null &&
        selectedTemplate.jobTask.status !== JobTaskStatusOptions.OPEN
      ) {
        setErrorText(t("timerecs.editor.template.error"));
      } else {
        // CallbackFunction to set entries from Template in Record
        onSelectTemplate(selectedTemplate);

        cancelTemplate();
      }
    }
  };

  /**
   * Handle cancel function
   */
  const cancelTemplate = () => {
    setErrorText(undefined);
    setSelectedTemplate(null);
    showTemplateDialog(false);
    setSelectedTemplateId("");
    setSelectedIndex([-1, -1]);
  };

  /**
   * Function to set state with filtered template list by user query
   * @param value User input as string query
   */
  const handleSearch = (value: string): void => {
    if (value.length > 0) {
      setTemplatesCopy([...handleTemplateListSearch(templates, value)]);
    } else {
      setTemplatesCopy([...templates]);
      dialogInputRef.current && dialogInputRef.current.focus();
    }
  };

  /**
   * Function to handle focus in textfield
   */
  const handleFocus = () => {
    setSelectedIndex([-1, -1]);
  };

  /**
   * Function to handle keyboard events in textfield
   * @param event KeyboardEvent from Textfield
   */
  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "ArrowDown") {
      event.stopPropagation();
      event.preventDefault();
      setSelectedIndex([0, 0]);
    }
  };

  return (
    <Dialog
      open={openTemplateDialog}
      title={t("timerecs.editor.template.title")}
      text={t("timerecs.editor.template.text")}
      actionButtonLabels={{ confirm: t("common.dialog.select") }}
      onSubmit={submitTemplate}
      isDisabled={selectedTemplateId.length === 0}
      onCancel={() => cancelTemplate()}
      errorText={errorText}
      size="lg"
    >
      <DialogSearchField
        isDisabled={templates.length === 0}
        label={t("components.search")}
        onChange={handleSearch}
        onFocus={() => handleFocus()}
        onKeyDown={(event) => handleOnKeyDown(event)}
        refElement={(e) => {
          dialogInputRef.current = e;
        }}
      />
      <DialogContent dividers>
        <List
          sx={{
            width: "100%",
            maxWidth: 550,
            maxHeight: 450,
          }}
        >
          {templates.length > 0 ? (
            templatesCopy.length > 0 ? (
              templatesCopy.map(([cat, records], i) => (
                <Box key={cat} sx={{ marginBottom: "1rem" }}>
                  <div className={classes.dialogCategory}>
                    <ListItemAvatar>
                      <Avatar>
                        <CollectionsBookmarkIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <div>
                      <Typography noWrap>{cat}</Typography>
                    </div>
                  </div>
                  <div className={classes.dialogItemContainer}>
                    {records.map((record, j) => (
                      <ListItemButton
                        key={record.id}
                        id={`${i}${j}`}
                        selected={
                          selectedTemplateId === record.id ||
                          (selectedIndex[0] === i && selectedIndex[1] === j)
                        }
                        onClick={() => handleListItemClick(record, i, j)}
                        onKeyDown={(e) => {
                          if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                            e.preventDefault();
                            return false;
                          }
                          return true;
                        }}
                      >
                        <div
                          className={classes.dialogItemContainerItem}
                          ref={
                            selectedIndex[0] === i && selectedIndex[1] === j
                              ? divRef
                              : null
                          }
                        >
                          <ListItemText
                            primary={
                              record.description &&
                              record.description.length > 30
                                ? record.description.substring(0, 30) + "..."
                                : record.description
                            }
                            secondary={getTemplateInfo(record)}
                          />
                        </div>
                      </ListItemButton>
                    ))}
                  </div>
                </Box>
              ))
            ) : (
              <AllDone
                label={t("timerec.tasks.noTasksFound")}
                IndividualIcon={() => (
                  <HelpOutlineOutlinedIcon
                    style={{ fontSize: 64, color: "orange" }}
                  />
                )}
              />
            )
          ) : (
            <>
              {loading ? <LoadingSpinner delay={1000} /> : <NoTemplatesInfo />}
            </>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

const NoTemplatesInfo: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { docuLinks, routes } = useStaticContent();
  const { baseUrl, language = "en" } = useTooltipContent();

  // English has no subfolder in url path
  const urlPath =
    docuLinks.timetracking.reusableComponents.editor.createTemplates;
  const urlLanguage = language === "en" ? "" : `${language}/`;
  const urlToDocs = `https://docs.dyce.cloud/${urlLanguage}docs/${baseUrl}${urlPath[language]}`;

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t("timerecs.editor.template.noTemplates.title")}
      </Typography>
      <Typography gutterBottom>
        {t("timerecs.editor.template.noTemplates.caption")}
      </Typography>
      <div className={classes.infoOptionsWrapper}>
        <div className={classes.infoOptionsContainer}>
          <Icon className={classes.infoOptionsIcon}>
            <BookmarkAddOutlinedIcon color="primary" />
          </Icon>
          <Typography>
            <p className={classes.infoOptionsText}>
              {t("timerecs.editor.template.noTemplates.toTemplates.caption")}
            </p>
          </Typography>
          <Typography color="secondary">
            <a href={String(routes.TNT_TEMPLATES)}>
              <p className={classes.hyperLink}>
                {t("timerecs.editor.template.noTemplates.toTemplates.link")}
              </p>
            </a>
          </Typography>
        </div>
        <div className={classes.infoOptionsContainer}>
          <Icon className={classes.infoOptionsIcon}>
            <InfoOutlinedIcon color="success" />
          </Icon>
          <Typography>
            <p className={classes.infoOptionsText}>
              {t(
                "timerecs.editor.template.noTemplates.toDocumentation.caption"
              )}
            </p>
          </Typography>
          <Typography>
            <a target="_blank" href={urlToDocs} rel="noreferrer">
              <p className={classes.hyperLink}>
                {t("timerecs.editor.template.noTemplates.toDocumentation.link")}
              </p>
            </a>
          </Typography>
        </div>
      </div>
    </>
  );
};
