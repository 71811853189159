import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "../../types/types";

export const fullUISelector = (state: RootState) => state.ui;
export const drawerTopSelector = createSelector(
  [fullUISelector],
  (ui) => ui.drawerTop
);
export const drawerLeftSelector = createSelector(
  [fullUISelector],
  (ui) => ui.drawerLeft
);
export const drawerRightSelector = createSelector(
  [fullUISelector],
  (ui) => ui.drawerRight
);
export const createUserDialogSelector = createSelector(
  [fullUISelector],
  (ui) => ui.createUserDialog
);
export const dialogAadUsers = createSelector(
  [fullUISelector],
  (ui) => ui.dialogAadUsers
);
export const userMenuSelector = createSelector(
  [fullUISelector],
  (ui) => ui.userMenu
);

export const selectCurrentStep = (state: RootState) => state.ui.currentStep;
