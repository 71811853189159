import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { Tooltip } from "../tooltip/tooltip";
import { useStaticContent } from "../static-provider/static-provider";
import { Periods } from "@dyce/interfaces";
import { calculateHours } from "@dyce/utils";
import { ResourcePlanning, handleCapaWithHolidays } from "@dyce/slices";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";

const useStyle = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      height: 82,
      width: "180px",
      marginTop: "-.5rem",
      border:
        theme.palette.mode === "light" ? "1px solid #e0e0e0" : "1px solid gray",
      borderRadius: theme.shape.borderRadius,
    },
    flexContainer: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: "100%",
    },
    summaryContainer: {
      display: "flex",
      flexDirection: "column",
    },
    header: {
      height: "1.5rem",
      paddingTop: "0.25rem",
    },
    contentContainer: {
      display: "flex",
      flexDirection: "row",
      height: "100%",
      width: "auto",
      minWidth: "160px",
    },
    hoursContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
    },
    digitsContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      // height: "50%",
    },
    stateHours: {
      display: "flex",
      paddingTop: "0.25rem",
      paddingLeft: 4,
    },
    targetHours: {
      display: "flex",
      justifyContent: "end",
      alignItems: "flex-end",
      paddingBottom: ".25rem",
    },
    barsContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "end",
      paddingRight: "0.775rem",
      paddingBottom: "0.25rem",
      width: "100%",
      height: "100%",
    },
    stateBar: {
      position: "absolute",
      width: 14,
      marginRight: -5,
      backgroundColor: "rgb(135, 220, 255)",
    },
    targetBar: {
      width: 16,
      backgroundColor: "rgba(0, 180, 245, 0.1)",
    },
    svgLine: {
      stroke: theme.palette.mode === "light" ? "#000" : "#fafafa",
      strokeWidth: 1,
      marginBottom: 4,
    },
  })
);

interface IPeriodSummaryProps {
  /**
   * Title shown in header
   */
  header: string;
  /**
   * Array with total billable hours for every day/week/year as periodArray
   */
  totalMinutesWorked: number[];
  /**
   * Total capacity information
   */
  totalDuration: {
    /**
     * Selected Period
     */
    period: "day" | Periods;
    /**
     * Selected (current) Date
     */
    date: string;
    /**
     * Capacity information from User
     */
    capacity: Record<string, ResourcePlanning> | null;
  };
  /**
   * If true, inner width && innerWidth getting smaller
   * @default false
   */
  shrinkWidth?: boolean;
}

export const PeriodSummary: FunctionComponent<IPeriodSummaryProps> = ({
  header,
  totalMinutesWorked,
  totalDuration,
  shrinkWidth = false,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { docuLinks } = useStaticContent();

  const labels: Record<string, string> = {
    duration: t("timerec.dashboard.timeWorked.duration"),
    capacity: t("timerec.dashboard.timeWorked.capacity"),
  };

  // States
  const [totalCapaHours, setTotalCapaHours] = useState<number>(0);
  const [totalMinutes, setTotalMinutes] = useState<number>(0);

  // UseEffects
  useEffect(() => {
    setTotalCapaHours(handleTotalCapacity());
    setTotalMinutes(handleTotalMinutes());
  }, [totalDuration.period, totalDuration.date, totalMinutesWorked]);

  useEffect(() => {
    if (totalMinutesWorked.length === 0) {
      setTotalMinutes(0);
    }
  }, [totalMinutesWorked]);

  const handleTotalMinutes = (): number => {
    let minutes = 0;
    totalMinutesWorked.forEach((x) => (minutes += x));

    return minutes;
  };

  // Handler
  const handleTotalCapacity = (): number => {
    const { period, date, capacity } = totalDuration;
    let total = 0;
    let newDate = DateTime.fromISO(date).startOf("week").toISODate();
    if (period === "month") {
      newDate = DateTime.fromISO(date).startOf("month").toISODate();
    }
    if (period === "year") {
      newDate = DateTime.fromISO(date).startOf("year").toISODate();
    }

    if (capacity && Object.entries(capacity).length > 0) {
      total = handleCapaWithHolidays({
        dateToCheck: newDate,
        capacity: capacity,
        period: period,
      });
    }

    return Math.round(total * 60);
  };

  const calculateHeight = (targetBar = false): number => {
    const fullBarHeight = 56;
    let stateBarHeight = 0;

    if (totalCapaHours > 0) {
      stateBarHeight = Math.round(
        (fullBarHeight / totalCapaHours) * totalMinutes
      );
    }
    if (targetBar && totalMinutes > 0) {
      if (stateBarHeight > 72) {
        // Recalculate Target-Bar-Height if state is greater then 100px (max)
        const percent = stateBarHeight / 72 - 1;
        const height = Math.round(
          fullBarHeight - (fullBarHeight / 100) * Math.round(percent * 100)
        );
        // Return 2px if height is less then 2px (more then 200% of target)
        return Math.max(height, 2);
      } else if (stateBarHeight === 0) {
        return 0;
      } else {
        if (stateBarHeight === 0) {
          return 0;
        }
        return fullBarHeight;
      }
    } else if (targetBar) {
      return 0;
    } else if (stateBarHeight === 0 && totalMinutes > 0) {
      // User has no workHourTemplate, set stateBarHeight to '100%'
      return fullBarHeight;
    }
    // Return 90px if state is greater then 72px (max)
    return Math.min(stateBarHeight, 72);
  };

  return (
    <Box className={classes.container} sx={{ width: shrinkWidth ? 150 : 204 }}>
      <div className={classes.flexContainer}>
        <div className={classes.summaryContainer}>
          <Typography
            noWrap
            variant="body2"
            className={classes.header}
            sx={{
              width: shrinkWidth ? 110 : 160,
              paddingLeft: shrinkWidth ? "0.125rem" : "0.5rem",
            }}
          >
            {header}
          </Typography>
          <div className={classes.contentContainer}>
            <div className={classes.hoursContainer}>
              <div className={classes.digitsContainer}>
                <Typography variant="body1" className={classes.stateHours}>
                  {`${calculateHours({
                    duration: totalMinutes,
                    allowMoreThanADay: true,
                  })} h`}
                </Typography>
                <div>
                  <svg height="40" width="11">
                    <line
                      x1="10"
                      y1="7"
                      x2="1"
                      y2="38"
                      className={classes.svgLine}
                    />
                  </svg>
                </div>
                <Typography variant="body1" className={classes.targetHours}>
                  {`${calculateHours({
                    duration: totalCapaHours,
                    allowMoreThanADay: true,
                  })} h`}
                </Typography>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.barsContainer}>
          <Tooltip
            label={`${labels["capacity"]}: ${calculateHours({
              duration: totalCapaHours,
              allowMoreThanADay: true,
            })} h`}
            urlPath={docuLinks.timetracking.dashboard.weeklySummary.totalBar}
            position={"right"}
            followCursor={true}
          >
            <Box
              className={classes.targetBar}
              sx={{
                height: `${calculateHeight(true)}px`,
                transition: "all 0.5s ease",
              }}
            />
          </Tooltip>
          <Tooltip
            label={`${labels["duration"]}: ${calculateHours({
              duration: totalMinutes,
              allowMoreThanADay: true,
            })} h`}
            urlPath={docuLinks.timetracking.dashboard.weeklySummary.stateBar}
            position={"right"}
            followCursor={true}
          >
            <Box
              className={classes.stateBar}
              sx={{
                height: `${calculateHeight()}px`,
                transition: "all 0.5s ease",
              }}
            />
          </Tooltip>
        </div>
      </div>
    </Box>
  );
};
