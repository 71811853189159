import { FunctionComponent } from "react";
// Components
import { Tooltip } from "../tooltip/tooltip";
import { SetupLanguages } from "@dyce/slices";

type TooltipSwitcherProps = {
  /**
   * Show children with tooltip? Then provide Tooltip-Label here.
   * Want's to see only {children} without tooltip, then provide NULL
   */
  tooltip: string | null;
  /**
   * Provide url link substring from /static/doc/docuLinks.json
   */
  urlPath: Record<SetupLanguages, string>;
  /**
   * @default "left-start"
   */
  position?:
    | "left"
    | "right"
    | "top"
    | "bottom"
    | "top-start"
    | "top-end"
    | "right-start"
    | "right-end"
    | "bottom-start"
    | "bottom-end"
    | "left-start"
    | "left-end";
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
};

/**
 * Switch to turn on/off Tooltip, depending on situation
 * @param param0 TooltipSwitcherProps
 * @returns Children with or without Tooltip
 */
export const TooltipSwitcher: FunctionComponent<TooltipSwitcherProps> = ({
  tooltip,
  urlPath,
  position = "left-start",
  children,
}) => {
  return (
    <>
      {tooltip ? (
        <Tooltip label={tooltip} urlPath={urlPath} position={position}>
          <div>{children}</div>
        </Tooltip>
      ) : (
        <>{children}</>
      )}
    </>
  );
};
