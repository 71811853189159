import React, { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { Button, IconButton, useMediaQuery } from "@mui/material";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";

interface IDatePickerProps {
  /**
   * If true, button get's disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * Callback for onClick event
   */
  onClick: () => void;
  /**
   * Callback to get reference from button
   */
  getReference?: (buttonRef: HTMLButtonElement | null) => void;
  /**
   * Button label
   */
  label?: string;
  /**
   * If true, lineHeight will set to 100% else 133%
   */
  resetLineHeight?: boolean;
  /**
   * The size of the component. small is equivalent to the dense button styling.
   * @default "small"
   */
  size?: "small" | "medium" | "large";
}

export const DatePickerButton: FunctionComponent<IDatePickerProps> = ({
  disabled = false,
  onClick,
  getReference,
  label,
  resetLineHeight,
  size = "small",
}) => {
  const { t } = useTranslation();
  const isIpadAndSmaller = useMediaQuery((theme: DyceTheme) =>
    theme.breakpoints.down(1190)
  );

  return (
    <>
      {isIpadAndSmaller ? (
        <IconButton
          color="primary"
          ref={(e) => getReference && getReference(e)}
          onClick={() => onClick()}
          disabled={disabled}
        >
          <IosShareOutlinedIcon />
        </IconButton>
      ) : (
        <Button
          variant="outlined"
          color="primary"
          size={size}
          sx={{ lineHeight: resetLineHeight ? "100%" : "133%" }}
          ref={(e) => getReference && getReference(e)}
          onClick={() => onClick()}
          disabled={disabled}
        >
          {label ? label : t("templates.createNewTimerec")}
        </Button>
      )}
    </>
  );
};
