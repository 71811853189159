import { useState, FunctionComponent } from "react";
// Helpers
import { DateTime, Interval } from "luxon";
import clsx from "clsx";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { createStyles, makeStyles } from "@mui/styles";
import { TextField } from "@mui/material";
import {
  StaticDatePicker,
  PickersDay,
  PickersDayProps,
  LocalizationProvider,
} from "@mui/x-date-pickers";
import { Periods } from "@dyce/interfaces";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    datePickerContainer: {
      "& .MuiPickersDay-root.Mui-selected": {
        backgroundColor: theme.palette.primary.dark,
      },
      "& .PrivatePickersSlideTransition-root": {
        minHeight: 232,
      },
      "& .MuiCalendarPicker-root": {
        backgroundColor: theme.palette.mode === "dark" ? "#2f2f2f" : "#fff",
      },
      "& .MuiButtonBase-root": {
        backgroundColor: theme.palette.mode === "dark" ? "#2f2f2f" : "#fff",
      },
    },
    datePicker: {
      "& .MuiPickersDay-hiddenDaySpacingFiller": {
        width: 36,
        margin: 0,
      },
    },
    highlight: {
      "& .MuiPickersDay-root": {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        "&:hover, &:focus": {
          backgroundColor: theme.palette.primary.dark,
        },
      },
    },
    firstHighlight: {
      "& .MuiPickersDay-root": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
      },
    },
    endHighlight: {
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
      "& .MuiPickersDay-root": {
        backgroundColor: theme.palette.primary.light,
        color: theme.palette.common.white,
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
      },
    },
  })
);

interface ICalendarPickerProps {
  /**
   * Initial date
   */
  date: DateTime;
  /**
   * Title on mobile view
   * @default 'Select Date'
   */
  title?: string;
  /**
   * Callback on selection
   */
  onSelect: (newValue: DateTime | null) => void;
  /**
   * Markup for the week including the date
   * @default true
   */
  renderCurrentWeek?: boolean;
  /**
   * Disable user input
   * @default false
   */
  disable?: boolean;
  /**
   * Current selected locale with hyphen, e.g. 'en-GB'
   * @default 'de-DE'
   */
  localeHyphen: string;
  /**
   * Period to highlight in calendar
   * @default 'week'
   */
  period?: Periods;
}

/**
 * Calendar Datepicker
 */
export const CalendarPicker: FunctionComponent<ICalendarPickerProps> = ({
  date,
  title = "Select Date",
  onSelect,
  renderCurrentWeek = true,
  disable = false,
  localeHyphen = "de-DE",
  period = "week",
}) => {
  const classes = useStyles();

  // States
  const [value, setValue] = useState<DateTime | null>(date);

  // Handler
  const onChange = (newValue: DateTime | null) => {
    // Set date to save last user selection
    if (!disable) {
      setValue(newValue);
      onSelect(newValue);
    }
  };

  const renderWeekPickerDay = (
    date: DateTime,
    _selectedDates: Array<DateTime | null>,
    pickersDayProps: PickersDayProps<DateTime>
  ) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = value.startOf(period === "workweek" ? "week" : period);
    const end = value.endOf(period === "workweek" ? "week" : period);

    const dayIsBetween = Interval.fromDateTimes(start, end).contains(date);
    const isFirstDay =
      date.day === start.day &&
      date.month === start.month &&
      date.year === start.year;
    const isLastDay =
      date.day === end.day &&
      date.month === end.month &&
      date.year === end.year;

    return (
      <div
        key={date.toString()}
        className={clsx({
          [classes.highlight]: dayIsBetween,
          [classes.firstHighlight]: isFirstDay,
          [classes.endHighlight]: isLastDay,
        })}
      >
        <PickersDay {...pickersDayProps} disableMargin />
      </div>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} locale={localeHyphen}>
      <div className={classes.datePickerContainer}>
        <StaticDatePicker
          className={classes.datePicker}
          disabled={disable}
          displayStaticWrapperAs="desktop"
          toolbarTitle={title}
          showToolbar={false}
          openTo="day"
          views={["day"]}
          showDaysOutsideCurrentMonth={true}
          value={value}
          renderDay={renderCurrentWeek ? renderWeekPickerDay : undefined}
          onChange={onChange}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      </div>
    </LocalizationProvider>
  );
};
