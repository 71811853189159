import { TemplateCategory } from "../types";
import buildQuery, { QueryOptions } from "odata-query";
import { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

export class CategoryService {
  public baseUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = combineURLs(baseUrl, "/TemplateCategories");

    this.axiosInstance = instance;
  }

  /**
   * Gets all Template Categories
   * @param query OData Query
   * @example
   * // Get every single Template category
   * const client = new TnTClient(args).getCategoryService()
   * await client.getAll()
   * // Only get the first 10 entries
   * const client = new TnTClient(args).getCategoryService()
   * await client.getAll({ top: 10 })
   */
  public async getAll(
    query?: Partial<QueryOptions<TemplateCategory>>
  ): Promise<TemplateCategory[]> {
    const url = `${this.baseUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<TemplateCategory[]>(url);

    return response.data;
  }

  /**
   * Gets only one Template Category by ID
   * @param id The ID of the category
   * @param query OData Query
   * @example
   * const client = new TnTClient(args).getCategoryService()
   * await client.getOne("0000-0000-0000-0000")
   */
  public async getOne(
    id: string,
    query?: Partial<QueryOptions<TemplateCategory>>
  ): Promise<TemplateCategory> {
    const url = `${this.baseUrl}/${id}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<TemplateCategory>(url);

    return response.data;
  }

  /**
   * Creates a new Template Category
   * @param payload
   * @example
   * const client = new TnTClient(args).getCategoryService()
   * await client.create({ name: "Example", resource: { id: "0000-0000-0000-0000" } })
   */
  public async create(
    payload: Partial<TemplateCategory>
  ): Promise<TemplateCategory> {
    const url = `${this.baseUrl}`;

    const result = await this.axiosInstance.post<TemplateCategory>(
      url,
      payload
    );

    return result.data;
  }

  /**
   * Update a single Template Category
   * @param id
   * @param payload
   * const client = new TnTClient(args).getCategoryService()
   * await client.update("0000-0000-0000-0000", { name: "New Example" })
   */
  public async update(
    id: string,
    payload: Partial<TemplateCategory>
  ): Promise<void> {
    const url = `${this.baseUrl}(${id})`;

    await this.axiosInstance.put<TemplateCategory>(url, {
      ...payload,
      id,
    });

    return;
  }

  /**
   * Deletes a Template Category
   * @param id
   * const client = new TnTClient().getCategoryService()
   * await client.remove("0000-0000-0000-0000")
   */
  public async remove(id: string): Promise<void> {
    const url = `${this.baseUrl}/${id}`;

    await this.axiosInstance.delete(url);

    return;
  }

  // public getHook(state: () => any) {
  //   const { authHeader, authHeaderWithContentType } = getHttpHeaders(getState);

  //   let getAll = this.getAll;

  //   return {
  //     getAll,
  //   };
  // }
}
