import { FunctionComponent, useState } from "react";
// Helper
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useHotkeys } from "react-hotkeys-hook";
import { DateTime } from "luxon";
// Dyce-Lib
import { Tooltip } from "../tooltip/tooltip";
import { useStaticContent } from "../static-provider/static-provider";
import { PopOverMenu } from "../popover-menu/popover-menu";
import { CalendarPicker } from "../calendar-picker/calendar-picker";
import { DyceTheme } from "@dyce/theme";
import { Periods } from "@dyce/interfaces";
import { handlePeriodText } from "@dyce/utils";
// MUI
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import { Box, Button, IconButton, MenuItem, Typography } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import DateRangeIcon from "@mui/icons-material/DateRange";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
    },
    buttons: {
      display: "flex",
      justifyContent: "space-between",
      width: 214,
      flexDirection: "row",
    },
    todayButton: {
      "& :hover": {
        "& .MuiButton-label": {
          transform: "translate(0, -1px)",
        },
      },
      "& .MuiButton-root": {
        minWidth: 128,
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        borderBottomLeftRadius: "0px",
      },
    },
    arrowButtons: {
      "& .MuiButton-root": {
        minWidth: "1.5rem",
        maxWidth: "2.625rem",
        height: 42,
      },
    },
    arrowNext: {
      marginLeft: "-1px",
      "& :hover": {
        "& .MuiSvgIcon-root": {
          transform: "translate(6px, 0)",
        },
      },
      "& .MuiButton-root": {
        borderTopLeftRadius: "0px",
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
        borderBottomLeftRadius: "0px",
      },
    },
    arrowLast: {
      marginRight: "-1px",
      "& :hover": {
        "& .MuiSvgIcon-root": {
          transform: "translate(-6px, 0)",
        },
      },
      "& .MuiButton-root": {
        borderTopLeftRadius: "50%",
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
        borderBottomLeftRadius: "50%",
      },
    },
    weekDateAndCal: {
      display: "flex",
      justifyContent: "center",
      height: "2.5rem",
      flexDirection: "row",
      alignItems: "center",
    },
    buttonContainer: {
      marginBottom: ".25rem",
    },
  })
);

interface IDatePaginationProps {
  /**
   * Current date in ISO format; Will show date-range from start of week to end of week
   * e.g. "23.1.2023 – 29.1.2023"
   */
  date?: string;
  /**
   * Takes the date prop and shows the date range in selected period from
   * start of period to end of period (e.g. "year" => "01.01.2023 – 31.12.2023")
   * @default "week"
   */
  onChangePeriod?: (value: Periods) => void;
  /**
   * If true, the IconButton with period selection will be shown
   */
  periodSelection?: boolean;
  /**
   * Define periods for the period selection
   * Should be injected with @prop {periodSelection} true
   */
  periodOptions?: {
    /**
     * Array of periods for the period selection, eg. ["week", "month", "year"]
     */
    periods: Periods[];
    /**
     * Initial period for the period selection
     * @default "week"
     */
    initialPeriod: Periods;
  };
  /**
   * Provide locale for the datepicker (e.g. "de-DE")
   * @default "en-GB"
   */
  localeHyphen?: string;
  /**
   * If true, the component will be disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * If true, the workweek will be shown in the period selection
   * @default false
   */
  allowWorkweek?: boolean;
  /**
   * Provide this prop to show a calendar picker
   * @property {date} is required
   */
  withCalendar?: {
    /**
     * Callback fired when a date is selected in CalendarPicker
     * @param newValue DateTime object send from the CalendarPicker
     * @returns void
     */
    onSelect: (newValue: DateTime | null) => void;
  };
  /**
   * Callback for the next week
   */
  forwardHandler: () => void;
  /**
   * Callback for the previous week
   */
  backwardHandler: () => void;
  /**
   * Callback for today
   */
  todayHandler: () => void;
}

/**
 * Component for showing details of the user.
 * @category Components
 * @component
 * @example
 * return (
 *   <DatePagination />
 * )
 */
export const DatePagination: FunctionComponent<IDatePaginationProps> = ({
  date,
  localeHyphen = "en-GB",
  withCalendar,
  periodSelection,
  periodOptions,
  disabled = false,
  allowWorkweek = false,
  onChangePeriod,
  forwardHandler,
  backwardHandler,
  todayHandler,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<DyceTheme>();
  const { platform, docuLinks } = useStaticContent();
  const { onSelect = () => {} } = withCalendar || {};
  const { initialPeriod = "week", periods = [] } = periodOptions || {};

  // States
  const [anchorElCalendar, setAnchorElCalendar] = useState<null | HTMLElement>(
    null
  );
  const [anchorElPeriod, setAnchorElPeriod] = useState<null | HTMLElement>(
    null
  );
  const [innerPeriod, setInnerPeriod] = useState<Periods>(
    periodSelection ? initialPeriod : "week"
  );

  // Hotkeys
  useHotkeys(
    `${platform.modifiers.modifierAlt}+left`,
    () => backwardHandler(),
    [backwardHandler]
  );

  useHotkeys(
    `${platform.modifiers.modifierAlt}+right`,
    () => forwardHandler(),
    [forwardHandler]
  );

  // Handler
  const tooltipText = (direction: string) => {
    switch (direction) {
      case "last": {
        if (periodSelection) {
          switch (innerPeriod) {
            case "week":
              return t("ui.datePagination.button.lastPeriod.week", {
                modifier: platform.description.modifierAlt,
              });
            case "workweek":
              return t("ui.datePagination.button.lastPeriod.workweek", {
                modifier: platform.description.modifierAlt,
              });
            case "month":
              return t("ui.datePagination.button.lastPeriod.month", {
                modifier: platform.description.modifierAlt,
              });
            case "year":
              return t("ui.datePagination.button.lastPeriod.year", {
                modifier: platform.description.modifierAlt,
              });
            default:
              return t("ui.datePagination.button.lastPeriod.week", {
                modifier: platform.description.modifierAlt,
              });
          }
        } else {
          return t("ui.datePagination.button.lastWeek", {
            modifier: platform.description.modifierAlt,
          });
        }
      }
      case "next":
        if (periodSelection) {
          switch (innerPeriod) {
            case "week":
              return t("ui.datePagination.button.nextPeriod.week", {
                modifier: platform.description.modifierAlt,
              });
            case "workweek":
              return t("ui.datePagination.button.nextPeriod.workweek", {
                modifier: platform.description.modifierAlt,
              });
            case "month":
              return t("ui.datePagination.button.nextPeriod.month", {
                modifier: platform.description.modifierAlt,
              });
            case "year":
              return t("ui.datePagination.button.nextPeriod.year", {
                modifier: platform.description.modifierAlt,
              });
            default:
              return t("ui.datePagination.button.nextPeriod.week", {
                modifier: platform.description.modifierAlt,
              });
          }
        } else {
          return t("ui.datePagination.button.nextWeek", {
            modifier: platform.description.modifierAlt,
          });
        }
      default:
        return "";
    }
  };

  const handleDateFormat = (date: string): string => {
    if (!periodSelection) {
      const dateString = `${DateTime.fromISO(date)
        .setLocale(localeHyphen)
        .startOf(innerPeriod === "workweek" ? "week" : innerPeriod)
        .toFormat("D")}
     – 
    ${DateTime.fromISO(date)
      .setLocale(localeHyphen)
      .endOf(innerPeriod === "workweek" ? "week" : innerPeriod)
      .toFormat("D")}`;
      return dateString;
    } else {
      let startDate = "";
      if (
        DateTime.fromISO(date).startOf(
          innerPeriod === "workweek" ? "week" : innerPeriod
        ).year ===
        DateTime.fromISO(date).endOf(
          innerPeriod === "workweek" ? "week" : innerPeriod
        ).year
      ) {
        startDate = DateTime.fromISO(date)
          .setLocale(localeHyphen)
          .startOf(innerPeriod === "workweek" ? "week" : innerPeriod)
          .toFormat(`d${localeHyphen.includes("de") ? "." : ""} MMM`);
      } else {
        startDate = DateTime.fromISO(date)
          .setLocale(localeHyphen)
          .startOf(innerPeriod === "workweek" ? "week" : innerPeriod)
          .toFormat("DD");
      }

      const endDate = DateTime.fromISO(date)
        .setLocale(localeHyphen)
        .endOf(innerPeriod === "workweek" ? "week" : innerPeriod)
        .toFormat("DD");

      const dateRange =
        innerPeriod === "day" ? `${endDate}` : `${startDate} – ${endDate}`;

      return dateRange;
    }
  };

  return (
    <>
      <Box
        className={classes.container}
        sx={{
          height: !date ? "2.75rem" : "5.25rem",
        }}
      >
        <div className={classes.buttons}>
          <div className={classNames(classes.arrowButtons, classes.arrowLast)}>
            <Tooltip
              label={tooltipText("last")}
              urlPath={
                docuLinks.timetracking.reusableComponents.datePagination
                  .lastWeek
              }
              position="bottom-end"
            >
              <Button
                variant="outlined"
                component="span"
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? "primary"
                      : theme.palette.primary.dark,
                }}
                onClick={backwardHandler}
                disableElevation
                size="large"
                data-testid="backwards"
                disabled={disabled}
              >
                <NavigateBeforeIcon
                  style={{ transition: "all 0.3s ease-out" }}
                />
              </Button>
            </Tooltip>
          </div>
          <div className={classes.todayButton}>
            <Button
              variant="outlined"
              component="span"
              sx={{
                color:
                  theme.palette.mode === "dark"
                    ? "primary"
                    : theme.palette.primary.dark,
              }}
              onClick={todayHandler}
              style={{ transition: "all 0.3s ease-out" }}
              disableElevation
              size="large"
              disabled={disabled}
            >
              {t("ui.datePagination.button.today")}
            </Button>
          </div>
          <div className={classNames(classes.arrowButtons, classes.arrowNext)}>
            <Tooltip
              label={tooltipText("next")}
              urlPath={
                docuLinks.timetracking.reusableComponents.datePagination
                  .nextWeek
              }
              position="bottom-start"
            >
              <Button
                variant="outlined"
                component="span"
                sx={{
                  color:
                    theme.palette.mode === "dark"
                      ? "primary"
                      : theme.palette.primary.dark,
                }}
                onClick={forwardHandler}
                disableElevation
                size="large"
                disabled={disabled}
              >
                <NavigateNextIcon style={{ transition: "all 0.3s ease-out" }} />
              </Button>
            </Tooltip>
          </div>
        </div>
        {date && (
          <div className={classes.weekDateAndCal}>
            <Typography
              sx={{
                textAlign: "center",
                marginRight:
                  periodSelection && withCalendar
                    ? "-5rem"
                    : periodSelection || withCalendar
                    ? "-2.5rem"
                    : "0",
                width:
                  periodSelection && withCalendar
                    ? "22.5rem"
                    : periodSelection || withCalendar
                    ? "18rem"
                    : "12rem",
              }}
            >
              {handleDateFormat(date)}
            </Typography>
            <div className={classes.buttonContainer}>
              {withCalendar && (
                <Tooltip
                  label={t("ui.datePagination.tooltip.datePicker")}
                  position={"bottom"}
                  urlPath={
                    docuLinks.timetracking.timerecordings.subheader.calendar
                  }
                >
                  <IconButton
                    aria-label="day-picker"
                    color="default"
                    onClick={(event) =>
                      setAnchorElCalendar(event.currentTarget)
                    }
                  >
                    <DateRangeIcon />
                  </IconButton>
                </Tooltip>
              )}
              {periodSelection && (
                <Tooltip
                  label={t("ui.datePagination.tooltip.period")}
                  position={"bottom"}
                  urlPath={
                    docuLinks.timetracking.timerecordings.subheader.calendar
                  }
                >
                  <IconButton
                    aria-label="period-picker"
                    color="default"
                    onClick={(event) => setAnchorElPeriod(event.currentTarget)}
                  >
                    <KeyboardArrowDownOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </div>
          </div>
        )}
      </Box>
      {withCalendar && date && anchorElCalendar && (
        <PopOverMenu
          id={"time-recordings-menu"}
          onClose={setAnchorElCalendar}
          anchorEl={anchorElCalendar}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <CalendarPicker
            onSelect={(value) => {
              onSelect(value);
              setAnchorElCalendar(null);
            }}
            title={t("ui.datePagination.datePicker.title")}
            date={DateTime.fromISO(date)}
            localeHyphen={localeHyphen}
            period={innerPeriod}
          />
        </PopOverMenu>
      )}
      {periodSelection && anchorElPeriod && (
        <PopOverMenu
          id={"time-recordings-period-menu"}
          onClose={setAnchorElPeriod}
          anchorEl={anchorElPeriod}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {periods.map((x) => (
            <MenuItem
              key={x}
              selected={x === innerPeriod}
              onClick={() => {
                setAnchorElPeriod(null);
                setInnerPeriod(x);
                onChangePeriod && onChangePeriod(x);
              }}
            >
              {handlePeriodText(x, allowWorkweek)}
            </MenuItem>
          ))}
        </PopOverMenu>
      )}
    </>
  );
};
