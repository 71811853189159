import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  AsyncThunk,
} from "@reduxjs/toolkit";
import { Expand } from "odata-query";
import { pull } from "lodash";
import { DateTime } from "luxon";
import {
  ActionMeta,
  CreateReturnTemplate,
  RecordTemplate,
  TemplateCategory,
} from "@dyce/tnt-api";
import { tntApiHelper } from "../../apiHelper";
import { RootState, TemplateStateSlice } from "../../types/types";

const expand: Expand<Record<string, any>> = {
  customer: { select: ["id", "no", "name"] },
  job: { select: ["id", "no", "description"] },
  jobtask: { select: ["id", "description", "no", "status"] },
  jobplanningline: { select: ["id", "description", "serviceBillingType"] },
  tntModelLine: {
    select: ["id", "description", "billable", "nonBillableReason", "rounding"],
  },
  resource: { select: ["id", "name"] },
  category: { select: ["id", "name"] },
};

/**
 * Gets all templates
 */
export const getAllTemplates: AsyncThunk<
  RecordTemplate[],
  undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  RecordTemplate[],
  undefined,
  { state: RootState; rejectWithValue: Error }
>("templates/getAllTemplates", async (_, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getTemplateService();

    return await client.getAll({ expand });
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Gets a single template by its ID
 */
export const getOneTemplate: AsyncThunk<
  RecordTemplate,
  string,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  RecordTemplate,
  string,
  { state: RootState; rejectWithValue: Error }
>("templates/getOneTemplate", async (id, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getTemplateService();

    return await client.getOne(id, { expand });
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Creates a new template
 */
export const createTemplate: AsyncThunk<
  RecordTemplate,
  RecordTemplate,
  { state: RootState; rejectWithError: Error }
> = createAsyncThunk<
  RecordTemplate,
  RecordTemplate,
  { state: RootState; rejectWithError: Error }
>(
  "templates/createTemplates",
  async (template, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getTemplateService();

      return await client.create(template);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Updates a template
 */
export const updateTemplate: AsyncThunk<
  RecordTemplate,
  RecordTemplate,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  RecordTemplate,
  RecordTemplate,
  { state: RootState; rejectWithValue: Error }
>(
  "templates/updateTemplate",
  async (template, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getTemplateService();

      return await client.update(template.id, template);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Deletes a template by ID
 */
export const removeTemplate: AsyncThunk<
  void,
  string,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  void,
  string,
  { state: RootState; rejectWithValue: Error }
>("templates/removeTemplate", async (id, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getTemplateService();

    return await client.remove(id);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Fetch all template categories
 */
export const getAllCategories: AsyncThunk<
  TemplateCategory[],
  undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  TemplateCategory[],
  undefined,
  { state: RootState; rejectWithValue: Error }
>("templates/getAllCategories", async (_, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getCategoryService();
    const orderBy = "name ASC";

    return await client.getAll({ orderBy });
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Fetch one template category
 */
export const getOneCategory: AsyncThunk<
  TemplateCategory,
  string,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  TemplateCategory,
  string,
  { state: RootState; rejectWithValue: Error }
>("templates/getOneCategory", async (id, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getCategoryService();

    return await client.getOne(id);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Update template category
 */
export const updateCategory: AsyncThunk<
  void,
  TemplateCategory,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  void,
  TemplateCategory,
  { state: RootState; rejectWithValue: Error }
>("templates/updateCategory", async (cat, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getCategoryService();

    return await client.update(cat.id, cat);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Create template category
 */
export const createCategory: AsyncThunk<
  TemplateCategory,
  Partial<TemplateCategory>,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  TemplateCategory,
  Partial<TemplateCategory>,
  { state: RootState; rejectWithValue: Error }
>("templates/createCategory", async (cat, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getCategoryService();

    return await client.create(cat);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Remove template category
 */
export const removeCategory: AsyncThunk<
  void,
  string,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  void,
  string,
  { state: RootState; rejectWithValue: Error }
>("templates/removeCategory", async (id, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getCategoryService();

    return await client.remove(id);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const initialStateTemplates = {
  entities: {},
  requests: [],
  templates: [],
  categories: {},
  errors: {},
  sort: {
    lookUp: {
      orderBy: "id",
      direction: "asc",
    },
  },
  entriesLookUp: {},
  sorting: null,
  jobTasks: {},
  jobs: {},
  jobPlanningLines: {},
  isLoadingEntries: false,
};

const tempSlice = createSlice({
  name: "templates",
  initialState: initialStateTemplates as TemplateStateSlice,
  reducers: {
    removeEntities(state) {
      state.entities = {};
    },
    clearTemplateList(state) {
      state.entities = {};
      state.categories = {};
    },
    addTemplate(state, action: PayloadAction<RecordTemplate>) {
      state.templates.push(action.payload);
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createTemplate.fulfilled, (state, action) => {
        const entry = {
          ...new CreateReturnTemplate({
            ...action.meta.arg,
            ...action.payload,
          }),
          created: DateTime.now().toISO(),
        } as unknown as RecordTemplate;
        delete (entry as any)["@odata.context"];

        state.entities[action.payload.id] = entry;
      })
      .addCase(getAllTemplates.pending, (state) => {
        state.isLoadingEntries = true;
      })
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.isLoadingEntries = false;
        state.entities = {};
        action.payload.forEach((e) => {
          state.entities[e.id] = e;
        });
      })
      .addCase(getOneTemplate.fulfilled, (state, action) => {
        state.entities[action.payload.id] = action.payload;
      })
      .addCase(updateTemplate.fulfilled, (state, action) => {
        state.entities[action.meta.arg.id as string] = {
          ...new CreateReturnTemplate(action.meta.arg),
        } as RecordTemplate;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        state.categories = {};
        action.payload.forEach((e) => (state.categories[e.id] = e));
      })
      .addCase(getOneCategory.fulfilled, (state, action) => {
        state.categories[action.payload.id] = action.payload;
      })
      .addCase(updateCategory.fulfilled, (state, action) => {
        state.categories[action.meta.arg.id] = action.meta.arg;
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.categories[action.payload.id] = action.payload;
      })
      .addCase(removeCategory.fulfilled, (state, action) => {
        delete state.categories[action.meta.arg];
      })
      .addCase(removeCategory.rejected, (state, action) => {
        delete state.categories[action.meta.arg];
      })
      .addCase(removeTemplate.fulfilled, (state, action) => {
        delete state.entities[action.meta.arg];
      })
      .addCase(removeTemplate.rejected, (state, action) => {
        delete state.entities[action.meta.arg];
      })
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.startsWith("temp") && action.type.endsWith("pending"),
        (state, action) => {
          state.requests.push(action.meta.requestId);
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          (action.type.startsWith("temp") &&
            action.type.endsWith("fulfilled")) ||
          (action.type.startsWith("temp") && action.type.endsWith("rejected")),
        (state, action) => {
          pull(state.requests, action.meta.requestId);
        }
      ),
});

export const { removeEntities, clearTemplateList } = tempSlice.actions;

export default tempSlice.reducer;
