import { FunctionComponent } from "react";
// Helper
import classNames from "classnames";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { calculateHours, calculateValue } from "@dyce/utils";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Typography, CircularProgress, Box, useTheme } from "@mui/material";
import { blue, deepPurple } from "@mui/material/colors";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "relative",
    },
    progresses: {
      position: "absolute",
    },
    progressesTime: {
      position: "relative",
      marginTop: 20,
      textAlign: "center",
    },
    progressFull: {
      "& .MuiCircularProgress-root": {
        color: blue[100],
        opacity: 0.5,
      },
    },
    progressCalc: {
      "& .MuiCircularProgress-root": {
        color: "#2a8fbd",
      },
    },
    progressCalcReleased: {
      "& .MuiCircularProgress-root": {
        color: deepPurple[100],
      },
    },
  })
);

interface IProgressInfoProps {
  /**
   * Duration in minutes for single recordEntry
   */
  duration: number;
  /**
   * Total hours in minutes to calculate percentage filling from circular
   */
  totalHours: number;
  /**
   * If true, design is shown as bigger graphical unit
   */
  asSummary?: boolean;
  /**
   * If true, item will be shown as grayed out (disabled)
   */
  isReleased?: boolean;
  /**
   * Size of circular progress
   * @default "medium"
   */
  size?: "small" | "medium";
  /**
   * Individual color for circular progress
   */
  individualColor?: string;
  /**
   * If true, text will be bold
   */
  boldText?: boolean;
}

export const ProgressInfo: FunctionComponent<IProgressInfoProps> = ({
  duration,
  totalHours,
  asSummary = false,
  isReleased = false,
  size = "medium",
  individualColor,
  boldText,
}) => {
  const classes = useStyles();
  const theme = useTheme<DyceTheme>();
  const isSmall = size === "small";

  return (
    <div
      className={classes.container}
      style={{
        minHeight: asSummary ? (isSmall ? 42 : 66) : isSmall ? 40 : 58,
        width: asSummary ? (isSmall ? 46 : 66) : isSmall ? 40 : 58,
      }}
    >
      <div
        className={classes.progresses}
        style={{
          marginTop: isSmall ? 0 : 4,
          marginLeft: asSummary ? 0 : 4,
        }}
      >
        <div className={classes.progressFull}>
          <CircularProgress
            size={asSummary ? (isSmall ? 42 : 58) : isSmall ? 40 : 50}
            thickness={6}
            variant="determinate"
            value={100}
          />
        </div>
      </div>
      <div
        className={classes.progresses}
        style={{
          marginLeft: asSummary ? 0 : 4,
        }}
      >
        <Box
          className={
            isReleased ? classes.progressCalcReleased : classes.progressCalc
          }
          sx={
            individualColor
              ? {
                  "& .MuiCircularProgress-root": {
                    color: individualColor,
                  },
                }
              : {}
          }
        >
          <CircularProgress
            size={asSummary ? (isSmall ? 42 : 58) : isSmall ? 40 : 50}
            thickness={6}
            variant="determinate"
            value={calculateValue(duration, totalHours)}
          />
        </Box>
      </div>
      <div
        className={classNames(classes.progressesTime)}
        style={{
          marginTop: asSummary ? (isSmall ? 13 : 24) : isSmall ? 12 : 20,
          opacity: isReleased ? 0.5 : 1,
          marginLeft: isSmall ? (asSummary ? "-3px" : ".5rem") : 0,
        }}
      >
        <Typography
          variant="body2"
          fontWeight={asSummary || boldText ? 600 : ""}
          fontSize={isSmall ? "12px" : undefined}
          sx={{
            textShadow: boldText
              ? theme.palette.mode === "dark"
                ? "0px 0px 1px rgb(82, 82, 82)"
                : "0px 1px 3px rgb(255, 255, 255)"
              : "",
          }}
        >
          {calculateHours({ duration, allowMoreThanADay: true })}
        </Typography>
      </div>
    </div>
  );
};
