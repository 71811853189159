import { FunctionComponent, useRef, useState } from "react";
// Helper
import { handleDisplayDate, handleMutateTaskToRecord } from "./utils";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { DatePickerButton } from "../../buttons/date-picker/date-picker-button";
import {
  JobViewEntry,
  JobTaskStatusOptions,
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  ValidatedRecord,
} from "@dyce/tnt-api";
import { RecordDatePicker } from "../../time-tracking-tool/template-date-picker/record-date-picker";
import { TaskSettings } from "@dyce/slices";
// MUI
import { createStyles, makeStyles, useTheme } from "@mui/styles";
import { Box, Divider, Typography } from "@mui/material";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row",
      height: 58,
      width: "100%",
      paddingRight: "1rem",
      justifyContent: "space-between",
      paddingTop: "4px",
    },
    iconMappingContainer: {
      display: "flex",
      width: "100%",
    },
    iconContainer: {
      display: "flex",
      minWidth: theme.palette.propsDyce.timeRecItemHeight.commonHeight,
      alignItems: "center",
      justifyContent: "center",
    },
    mappingContainer: {
      display: "flex",
      width: "100%",
      alignItems: "start",
    },
    mappingColumns: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      padding: "4px 4px 0 4px",
    },
    mappingColumnsTasks: {
      display: "flex",
      flexDirection: "column",
      gap: "4px",
      padding: "4px 4px 0 4px",
      width: "100%",
    },
    dateContainer: {
      display: "flex",
      flexDirection: "column",
      minWidth: "10rem",
      maxWidth: "10rem",
      marginRight: "1rem",
    },
    dateFields: {
      display: "flex",
      alignItems: "end",
      flexDirection: "column",
      gap: "4px",
      justifyContent: "center",
      height: "100%",
    },
    datePickerButton: {
      height: "75%",
      display: "flex",
      alignSelf: "center",
      justifySelf: "end",
    },
  })
);

interface ITaskListItemProps {
  /**
   * Single task entry object
   */
  task: JobViewEntry;
  /**
   * If true, list is grouped by customer
   */
  groupedBy: TaskSettings;
  /**
   * If true, divider will be shown below list-item
   */
  showDivider: boolean;
  /**
   * Location information, e.g. "en-US"
   */
  locale: string;
  /**
   * If true, rendered items will be shown by fading in (depends on 'visible in viewport)
   * @default false
   */
  showFading?: boolean;
  /**
   * Props forwarded to TemplateDatePicker; Provide props when needed in ListItemEntry
   */
  saveAsTimeRecordProps: {
    onSave: (
      entry: RecordEntry & Partial<RecordTemplate & RecordTimeRec>,
      isTimeRecord?: boolean
    ) => Promise<{
      newEntry: RecordEntry | RecordTemplate | null;
      error: boolean;
    }>;
    validateTemplateObject?: (
      template: RecordTemplate
    ) => Promise<ValidatedRecord>;
    clearLatestCreatedId: () => void;
    openCreatedRecording: (value: boolean) => void;
    deleteRecord: (id: string) => Promise<boolean>;
    openInTimerecordings: boolean;
  };
}

export const TaskListItem: FunctionComponent<ITaskListItemProps> = ({
  task,
  groupedBy,
  showDivider,
  locale,
  showFading = false,
  saveAsTimeRecordProps,
}) => {
  const classes = useStyles();
  const theme = useTheme<DyceTheme>();
  const {
    onSave,
    clearLatestCreatedId,
    deleteRecord,
    openCreatedRecording,
    openInTimerecordings,
    validateTemplateObject,
  } = saveAsTimeRecordProps;

  // UseRefs
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  // UseStates
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [renderCalendar, setRenderCalendar] = useState<boolean>(false);

  return (
    <>
      <Box
        sx={{
          opacity: showFading ? 1 : 0,
          transition: "all 0.25s ease",
        }}
      >
        <div className={classes.container}>
          <div className={classes.iconContainer}>
            <TaskAltOutlinedIcon color="secondary" />
          </div>
          <div className={classes.mappingContainer}>
            {/** Customer & Job */}
            <Box className={classes.mappingColumns}>
              {groupedBy !== TaskSettings.CUSTOMER && (
                <Typography
                  variant="body2"
                  sx={{
                    width: "350px",
                    minWidth: "200px",
                    [theme.breakpoints.down("timeRecsMid")]: {
                      width: "300px",
                    },
                    [theme.breakpoints.down("timeRecsMin")]: {
                      width: "275px",
                    },
                    [theme.breakpoints.down(1190)]: {
                      width: "200px",
                      minWidth: "200px",
                    },
                  }}
                  noWrap
                >
                  {task.customer && task.customer.name
                    ? task.customer.name
                    : "-"}
                </Typography>
              )}
              <Typography
                variant="body2"
                sx={{
                  width: "550px",
                  minWidth: "200px",
                  [theme.breakpoints.down("timeRecsMid")]: {
                    width: "300px",
                  },
                  [theme.breakpoints.down("timeRecsMin")]: {
                    width: "275px",
                  },
                  [theme.breakpoints.down(1190)]: {
                    width: "200px",
                  },
                }}
                noWrap
              >
                {task.job && task.job.no && task.job.description
                  ? `${task.job.no} - ${task.job.description}`
                  : "-"}
              </Typography>
            </Box>
            {/** JobTask & JobPlanningLine */}
            <Box
              sx={{
                minWidth: "350px",
                alignItems: "start",
                [theme.breakpoints.down("timeRecsMax")]: {
                  minWidth: "300px",
                },
                [theme.breakpoints.down("timeRecsMid")]: {
                  alignItems: "center",
                },
                [theme.breakpoints.down(1190)]: {
                  minWidth: "200px",
                },
              }}
              className={classes.mappingColumnsTasks}
            >
              <Typography
                variant="body2"
                sx={{
                  width: "550px",
                  minWidth: "300px",
                  [theme.breakpoints.down("timeRecsMax")]: {
                    width: "300px",
                  },
                  [theme.breakpoints.down(1190)]: {
                    width: "200px",
                    minWidth: "200px",
                  },
                }}
                noWrap
              >
                {task.jobTask && task.jobTask.description
                  ? task.jobTask.description
                  : "-"}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  width: "550px",
                  minWidth: "300px",
                  [theme.breakpoints.down("timeRecsMax")]: {
                    width: "300px",
                  },
                  [theme.breakpoints.down(1190)]: {
                    width: "200px",
                    minWidth: "200px",
                  },
                }}
                noWrap
              >
                {task.jobPlanningLine && task.jobPlanningLine.description
                  ? task.jobPlanningLine.description
                  : "-"}
              </Typography>
            </Box>
          </div>
          <div className={classes.dateContainer}>
            <div className={classes.dateFields}>
              {handleDisplayDate(
                task.plannedStartDate,
                task.plannedEndDate,
                locale
              )}
            </div>
          </div>
          <div className={classes.datePickerButton}>
            <DatePickerButton
              getReference={(e) => (buttonRef.current = e)}
              onClick={() => {
                setRenderCalendar(true);
                setOpenCalendar(true);
              }}
              disabled={
                openCalendar ||
                (task.jobTask !== undefined &&
                  task.jobTask !== null &&
                  task.jobTask.status !== undefined &&
                  task.jobTask.status !== JobTaskStatusOptions.OPEN)
              }
            />
          </div>
        </div>
      </Box>
      {showDivider && <Divider />}
      {renderCalendar && (
        <RecordDatePicker
          open={openCalendar}
          anchorEl={buttonRef.current}
          onClose={({ openCalendar, renderCalendar }) => {
            setOpenCalendar(openCalendar);
            setRenderCalendar(renderCalendar);
          }}
          returnProps={{
            fromLocation: "Tasks",
            scrollPosition: window.scrollY,
          }}
          templateEntry={handleMutateTaskToRecord(task)}
          onSave={onSave}
          localeHyphen={locale}
          templateCategory={null}
          validateTemplateObject={validateTemplateObject}
          states={{
            openCreatedRecording: openCreatedRecording,
            clearLatestCreatedId: clearLatestCreatedId,
            deleteRecord: deleteRecord,
            isChecked: openInTimerecordings,
          }}
        />
      )}
    </>
  );
};
