import { FunctionComponent, useEffect, useRef, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { useHotkeys } from "react-hotkeys-hook";
import { createRolesView, filterUser } from "./utils";
import { useUserList } from "./context/userContext";
// Dyce-Lib
import { SearchBox, Tooltip, useStaticContent } from "@dyce/ui";
import { PopulatedRole, PopulatedUser } from "@dyce/tnt-api";
import { useDebounce, useUpdateEffect } from "@dyce/hooks";
import {
  useAppDispatch,
  useAppSelector,
  selectDarkMode,
  selectAllUsersAsArray,
  userRolesSelector,
  selectCurrentWorkspace,
  getAllUsers,
  getAllRoles,
  getAllResources,
  updateUser,
  selectAllResourcesAsArray,
  setClearAllUsers,
  selectAnyAdminRequestPending,
} from "@dyce/slices";
// MUI
import { Edit, DeleteOutline } from "@mui/icons-material";
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
// ReactDataGrid
import ReactDataGrid, {
  Column,
  EditorProps,
  RowsChangeData,
  SelectColumn,
  SortColumn,
  SortDirection,
} from "react-data-grid";

const useStyles = makeStyles(() => ({
  headContainer: {
    display: "flex",
    flexDirection: "row",
    height: 45,
    marginBottom: 10,
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionButton: {
    padding: "0 10px",
  },
  editor: {
    appearance: "none",
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    verticalAlign: "top",
    fontSize: "14px !important",
    border: "none",
    "&:focus": {
      outline: "none",
    },
    "&::placeholder": {
      color: "#999",
      opacity: 1,
    },
  },
  font: {
    fontSize: "14px !important",
    margin: "0 !important",
    padding: "0 !important",
  },
  resetMargin: {
    margin: "0 !important",
    padding: "0 !important",
  },
  noContent: {
    display: "flex",
    justifyContent: "center",
    marginTop: "3rem",
    width: "100%",
    height: "100px",
  },
}));

interface IDYCEListProps {
  /**
   * Callback for delete action
   */
  onDelete: () => void;
  /**
   * Callback for edit action
   */
  onEdit: () => void;
  /**
   * If true, users get loaded by current sort settings
   * @default false
   */
  loadListNew?: boolean;
  /**
   * Calculate user count for pagination
   */
  loadUserCount: number;
}

/**
 * List for DYCE users
 */
export const DYCEList: FunctionComponent<IDYCEListProps> = ({
  onDelete,
  onEdit,
  loadListNew = false,
  loadUserCount,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { docuLinks } = useStaticContent();

  // Selectors
  const darkMode: boolean = useAppSelector(selectDarkMode);
  const allUsers = useAppSelector(selectAllUsersAsArray);
  const allRoles = useAppSelector(userRolesSelector);
  const isLoading = useAppSelector(selectAnyAdminRequestPending);
  const { instance, company } = useAppSelector(selectCurrentWorkspace);

  const { debouncedValue, setValue } = useDebounce("", 300);

  // States
  const [selectedRows, setSelectedRows] = useState<Set<React.Key>>(new Set());
  const [sortDirection, setSortDirection] = useState<SortDirection>("ASC");
  const [sortColumn, setSortColumn] = useState<string>("name");
  const [page, setPage] = useState<number>(1);
  const [[scrollCheckX, scrollCheckY], setScrollCheck] = useState<
    [number, number]
  >([0, 0]);
  const { selectedUsers, setSelectedUsers } = useUserList();

  // Theme
  const darkTheme: string = darkMode ? "rdg-dark" : "rdg-light";

  // UseEffects
  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllResources());
  }, [instance, company]);

  useUpdateEffect(() => {
    handleUserList();

    return () => {
      dispatch(setClearAllUsers());
    };
  }, [loadUserCount]);

  useEffect(() => {
    setSelectedRows(new Set(Array.from(selectedUsers).map((user) => user.id)));
  }, [selectedUsers]);

  useUpdateEffect(() => {
    handleUserList();
  }, [debouncedValue, sortDirection, sortColumn]);

  const handleUserList = () => {
    setPage(1);
    dispatch(setClearAllUsers());
    // Search only with selected sorting column and direction
    dispatch(
      getAllUsers({
        count: loadUserCount,
        search: debouncedValue.length > 0 ? debouncedValue : undefined,
        sort: `${sortColumn} ${sortDirection}`,
      })
    );
  };

  useUpdateEffect(() => {
    if (loadListNew) {
      dispatch(setClearAllUsers());
      dispatch(
        getAllUsers({
          count: loadUserCount,
          sort: `${sortColumn} ${sortDirection}`,
        })
      );
    }
  }, [loadListNew]);

  const columns: readonly Column<PopulatedUser>[] = [
    SelectColumn,
    {
      key: "id",
      name: "ID",
      headerRenderer: (props) => (
        <Tooltip
          label={t("admin.users.grid.tooltip.id")}
          urlPath={docuLinks.administration.users.userList.id}
        >
          <span {...props}>{props.column.name}</span>
        </Tooltip>
      ),
      width: 80,
      resizable: true,
    },
    {
      key: "name",
      name: t("admin.users.grid.name"),
      width: 270,
      sortable: true,
      resizable: true,
    },
    {
      key: "email",
      name: t("admin.users.grid.mail"),
      sortable: true,
      resizable: true,
      width: 420,
    },
    {
      key: "roles",
      name: t("admin.users.grid.roles"),
      formatter: (props) => (
        <div style={{ cursor: "pointer" }}>
          {createRolesView({
            currentRoles: props.row.roles as PopulatedRole[],
            availableRoles: allRoles,
          })}
        </div>
      ),
      headerRenderer: (props) => (
        <Tooltip
          label={t("admin.users.grid.tooltip.roles")}
          urlPath={docuLinks.administration.users.userList.roles}
        >
          <span {...props}>{props.column.name}</span>
        </Tooltip>
      ),
      resizable: true,
    },
    {
      key: "resource",
      name: t("admin.users.grid.resource"),
      formatter: (props) => <div>{props.row.resource?.name}</div>,
      headerRenderer: (props) => (
        <Tooltip
          label={t("admin.users.grid.tooltip.resource")}
          urlPath={docuLinks.administration.users.userList.resource}
        >
          <span {...props}>{props.column.name}</span>
        </Tooltip>
      ),
      editor: Editor,
      editorOptions: {
        editOnClick: true,
      },
      width: 270,
    },
  ];

  // Handlers
  const handleOnRoleSelection = (id: string) => {
    const selected = allUsers.find((user) => user.id === id);
    if (selected) {
      const set = new Set([selected.id]);
      setSelectedUsers(filterUser(set, allUsers));
      setSelectedRows(set);
      onEdit();
    }
  };

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;

    // Need to check that pagination does not trigger on scroll in X direction
    setScrollCheck([target.scrollLeft, target.scrollTop]);
    if (
      target.clientHeight + target.scrollTop === target.scrollHeight &&
      scrollCheckX === target.scrollLeft
    ) {
      setPage(page + 1);
      await dispatch(
        getAllUsers({
          page: page,
          search: debouncedValue.length > 0 ? debouncedValue : undefined,
          sort: `${sortColumn} ${sortDirection}`,
        })
      );
    }
  };

  const handleChange = (
    rows: PopulatedUser[],
    data: RowsChangeData<PopulatedUser, unknown>
  ) => {
    const user = rows[data.indexes[0]];
    dispatch(
      updateUser({
        ...user,
        resource: user.resource ? user.resource : null,
      })
    );
  };

  return (
    <>
      <div className={classes.headContainer}>
        <SearchBox onChange={setValue} />
        <div>
          <Tooltip
            label={t("admin.users.tooltip.edit")}
            urlPath={docuLinks.administration.users.userListHeader.editButton}
            position="left"
          >
            <span>
              <IconButton
                color="secondary"
                disabled={selectedRows?.size == 0}
                size="small"
                className={classes.actionButton}
                onClick={onEdit}
              >
                <Edit />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip
            label={t("admin.users.tooltip.remove")}
            urlPath={docuLinks.administration.users.userListHeader.deleteButton}
            position="left"
          >
            <span>
              <IconButton
                color="inherit"
                disabled={selectedRows?.size == 0}
                size="small"
                className={classes.actionButton}
                onClick={onDelete}
              >
                <DeleteOutline />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      </div>
      <ReactDataGrid
        columns={columns}
        rows={allUsers}
        rowKeyGetter={(r) => r.id}
        onSelectedRowsChange={(set) => {
          setSelectedUsers(filterUser(set, allUsers));
          setSelectedRows(set);
        }}
        onSortColumnsChange={(sortColumns: SortColumn[]) => {
          if (sortColumns.length > 0) {
            const { columnKey, direction } = sortColumns[0];
            setSortDirection(direction);
            setSortColumn(columnKey);
          } else {
            setSortDirection("ASC");
            setSortColumn("id");
          }
        }}
        onRowClick={(_, row, column) =>
          column.key === "roles" && handleOnRoleSelection(row.id)
        }
        emptyRowsRenderer={() =>
          isLoading ? (
            <div className={classes.noContent}>
              <CircularProgress size={32} />
            </div>
          ) : (
            <div className={classes.noContent}>
              <Typography>
                {debouncedValue.length > 0
                  ? t("admin.users.grid.noResult")
                  : t("admin.users.grid.noContent")}
              </Typography>
            </div>
          )
        }
        onRowsChange={handleChange}
        sortColumns={[{ columnKey: sortColumn, direction: sortDirection }]}
        selectedRows={selectedRows}
        onScroll={handleScroll}
        className={`${darkTheme}`}
        style={{
          height: allUsers.length * 35 + 37,
          maxHeight: "calc(100% - 55px)",
        }}
      />
    </>
  );
};

/**
 * Autocomplete component for React Datagrid editor
 */
const Editor: FunctionComponent<EditorProps<PopulatedUser>> = (cell) => {
  const allResources = useAppSelector(selectAllResourcesAsArray);
  const classes = useStyles();
  const { t } = useTranslation();

  useHotkeys("esc", () => cell.onClose(false));

  const firstRef = useRef(true);

  useEffect(() => {
    // prevent instant close on first open
    firstRef.current = false;
  }, []);

  return (
    <Autocomplete
      // adding dummy resource so users can assign "None"
      options={[{ id: 1, name: t("admin.users.noResource") }, ...allResources]}
      isOptionEqualToValue={(option, value) => {
        if (option.id == 1 && value.name == t("admin.users.noResource")) {
          return true;
        }
        return value.id === option.id;
      }}
      style={{ fontSize: "14px", width: "100%" }}
      getOptionLabel={(option) => option.name}
      value={cell.row.resource ? cell.row.resource : null}
      onChange={(_, value) => {
        // notify cell about the changes but only commit them after the first time (instantly closes otherwise)
        cell.onRowChange(
          {
            ...cell.row,
            resource: allResources.find((r) => r.name === value?.name) || null,
          },
          !firstRef.current
        );
      }}
      classes={{ inputRoot: classes.resetMargin + " " + classes.font }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={classes.editor}
          style={{ fontSize: "14px" }}
          classes={{ root: classes.resetMargin }}
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          autoFocus
        />
      )}
    />
  );
};
