import axios, { Axios } from "axios";
import { DevOpsService, JiraService, WebHookService } from "../services";

interface ForeignClientOpts {
  /**
   * Specify the base URL
   */
  url?: string;
  /**
   * Authentication token for foreign API
   */
  jiraAppToken?: string;
  /**
   * Name of foreign collection
   * @default "DefaultCollection"
   */
  foreignCollection?: string;
}

export class ForeignClient {
  private devOpsService: DevOpsService | undefined;
  private jiraService: JiraService | undefined;
  private webHookService: WebHookService | undefined;

  private axiosInstance: Axios;

  constructor(private opts: ForeignClientOpts) {
    this.axiosInstance = axios.create();

    // Add interceptor to add headers
    this.axiosInstance.interceptors.request.use((config) => {
      // add access token
      config.headers = this.opts.jiraAppToken
        ? {
            ...config.headers,
            Authorization: `JWT ${this.opts.jiraAppToken}`,
          }
        : {
            ...config.headers,
          };

      // add content type to requests with outgoing data
      if (["post", "patch", "put"].indexOf(config.method as any) >= 0) {
        config.headers = {
          ...config.headers,
          "Content-Type": "application/json",
          Accept: "application/json",
          crossOrigin: true,
        };
      }

      return config;
    });

    // add interceptors to axios response
    this.axiosInstance.interceptors.response.use(
      (resp) => {
        return resp;
      },
      (error) => {
        if (axios.isAxiosError(error)) {
          throw new Error(error.message);
        } else {
          throw error;
        }
      }
    );
  }

  public set jiraAppToken(v: string | undefined) {
    this.opts.jiraAppToken = v;
  }

  public get jiraAppToken(): string | undefined {
    return this.opts.jiraAppToken;
  }

  public getJiraService() {
    if (!this.jiraService) {
      this.jiraService = new JiraService();
    }

    return this.jiraService;
  }

  public getWebHookService() {
    if (!this.webHookService) {
      this.webHookService = new WebHookService(
        this.opts.url ? this.opts.url : "",
        this.axiosInstance
      );
    }

    return this.webHookService;
  }

  public getDevOpsService() {
    if (!this.devOpsService) {
      this.devOpsService = new DevOpsService(this.opts.foreignCollection);
    }

    return this.devOpsService;
  }
}
