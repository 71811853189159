// Helper
import i18next from "i18next";
// Dyce-Lib
import { PopulatedJobTask } from "@dyce/tnt-api";
import { IDatagridColumn, IDatagridRow } from "@dyce/ui";

const expand = {
  job: { select: ["id", "no", "description"] },
};

const createColumns = (isSortable = true): Record<string, IDatagridColumn> => {
  // Specify Columnheader in Objectkey: "name" and change editable to true if needed
  return {
    description: {
      name: i18next.t("components.lookup.columnHeader.jobTask.description"),
      key: "description",
      editable: true,
      width: 0.4,
      searchable: true,
      display: true,
      sortable: true,
    },
    jobNo: {
      name: i18next.t("components.lookup.columnHeader.jobTask.jobNo"),
      key: "jobNo",
      editable: true,
      width: 0.25,
      searchable: true,
      display: true,
      sortable: isSortable,
    },
    jobDescription: {
      name: i18next.t("components.lookup.columnHeader.jobTask.jobDescription"),
      key: "jobDescription",
      editable: true,
      searchable: true,
      display: true,
      sortable: isSortable,
    },
  };
};

function createRows<T>(entries: PopulatedJobTask[]): IDatagridRow<T>[] {
  // set Columnvalue with specified DataBase Column
  const row: IDatagridRow[] = entries.map((entry) => {
    return {
      id: entry.id,
      cells: {
        description: entry.description,
        jobNo: entry.job && entry.job.no,
        jobDescription: entry.job && entry.job.description,
        no: entry.no,
        skells: false,
        job: {
          id: entry.job && entry.job.id,
          no: entry.job && entry.job.no,
          description: entry.job && entry.job.description,
        },
        status: entry.status,
      },
    };
  });

  return row;
}

const createSkeletonRows = (count: number): IDatagridRow<any>[] => {
  const row: IDatagridRow<any>[] = [...Array(count)].map((_, index) => {
    return {
      id: index.toString(),
      cells: {
        description: "",
        jobNo: "",
        jobDescription: "",
        no: "",
        skells: true,
        job: {
          id: "",
          no: "",
          description: "",
        },
        status: "Open",
      },
    };
  });

  return row;
};

const getDbColumnSearch = (debouncedValue: string): any[] => {
  // Define DataBase Column Names for search Query (Objectkey)
  const firstDbColumn: { description: { contains: string } } = {
    description: { contains: debouncedValue },
  };
  const secondDbColumn: { job: { no: { contains: string } } } = {
    job: { no: { contains: debouncedValue } },
  };
  const thirdDbColumn: { job: { description: { contains: string } } } = {
    job: { description: { contains: debouncedValue } },
  };

  return [firstDbColumn, secondDbColumn, thirdDbColumn];
};

const getSqlColumnName = (
  sortColumn: string,
  sortDirection: string
): string => {
  const sqlColumn = {
    description: `description ${sortDirection}, job/no ${sortDirection}, no ${sortDirection}`,
    jobNo: `job/no ${sortDirection}, no ${sortDirection}, description ${sortDirection}, job/description ${sortDirection}`,
    jobDescription: `job/description ${sortDirection}, job/no, no ${sortDirection}, description ${sortDirection}`,
    no: `no ${sortDirection}`,
    default: `no ${sortDirection}`,
  };

  return `${
    sqlColumn[
      sortColumn as "description" | "jobNo" | "jobDescription" | "no"
    ] || sqlColumn["default"]
  }`;
};

export default {
  expand,
  getDbColumnSearch,
  createColumns,
  createRows,
  getSqlColumnName,
  createSkeletonRows,
};
