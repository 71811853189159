import { useEffect } from "react";

type WindowBlurCallback = () => void;

/**
 * Hook with eventlistener to check if user left the browser window (blurred complete window);
 * E.g. User has another tab open or the browser is minimized
 * @param onChange Boolean to check if window (browser) is active or not
 */
export const useWindowVisibilityChange = (onBlur: WindowBlurCallback) => {
  useEffect(() => {
    function handleBlur() {
      onBlur();
    }

    window.addEventListener("blur", handleBlur);

    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  }, [onBlur]);
};
