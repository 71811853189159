import { FunctionComponent } from "react";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Menu, PopoverOrigin } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    listItem: {
      "& .MuiPaper-root": {
        minWidth: "180px !important",
      },
    },
  })
);

interface IPopOverMenuProps {
  /**
   * Provide id for test purposes
   */
  id?: string;
  /**
   * Callback fired onClose event
   */
  onClose: (selection: any) => void;
  /**
   * An HTML element, or a function that returns one.
   * It's used to set the position of the menu.
   */
  anchorEl: HTMLElement | null;
  /**
   * This is the point on the anchor where the popover's anchorEl will attach to. This is not used when the anchorReference is 'anchorPosition'.
   * Options: vertical: [top, center, bottom]; horizontal: [left, center, right].
   * @default
   * {
   * vertical: 'top',
   * horizontal: 'left',
   * }
   */
  anchorOrigin?: PopoverOrigin;
  /**
   * This is the point on the popover which will attach to the anchor's origin.
   * Options: vertical: [top, center, bottom, x(px)]; horizontal: [left, center, right, x(px)].
   * @default
   * {
   * vertical: 'top',
   * horizontal: 'left',
   * }
   */
  transformOrigin?: PopoverOrigin;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const PopOverMenu: FunctionComponent<IPopOverMenuProps> = ({
  id,
  onClose,
  anchorEl,
  anchorOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  transformOrigin = {
    vertical: "top",
    horizontal: "left",
  },
  children,
}) => {
  const classes = useStyles();

  return (
    <Menu
      id={id}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={() => onClose(null)}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      className={classes.listItem}
    >
      {children}
    </Menu>
  );
};
