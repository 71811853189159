import axios, { Axios } from "axios";
import { BatchResponse, Resource } from "../types";
import {
  CategoryService,
  InstanceService,
  ResourceService,
  TemplateService,
  TnTService,
  AdminService,
  RecService,
  OnboardingService,
  WorkItemService,
  BatchService,
  CalendarService,
} from "../services";
import { JobAssignmentsService } from "../services/useJobAssignments";

interface TnTClientOpts {
  /**
   * Specify the base URL
   */
  url: string;
  /**
   * Function to provide a valid JWT to access the API. Will be called every time a request is dispatched.
   */
  accessToken: () => string;
  /**
   * Populate outgoing data with this resource
   */
  populateResource?: Resource;
  /**
   * Set the instance of BC you want to target. Can be changed later.
   */
  instance?: string;
  /**
   * Set the company in BC you want to target. Can be changed later.
   */
  company?: string;
  /**
   * Explicit receive OData information, e.g. @odata.count
   * @description should be typed with ODataArrayResponse<T> || ODataResponse<T>
   */
  odataInfo?: boolean;
}

export class TnTClient {
  private categoryService: CategoryService | undefined;
  private templateService: TemplateService | undefined;
  private resourceService: ResourceService | undefined;
  private tntService: TnTService | undefined;
  private jobAssignmentsService: JobAssignmentsService | undefined;
  private instanceService: InstanceService | undefined;
  private adminService: AdminService | undefined;
  private recService: RecService | undefined;
  private onboardingService: OnboardingService | undefined;
  private workItemService: WorkItemService | undefined;
  private batchService: BatchService | undefined;
  private calendarService: CalendarService | undefined;

  private axiosInstance: Axios;

  constructor(private opts: TnTClientOpts) {
    this.axiosInstance = axios.create();

    // Add interceptor to add headers
    this.axiosInstance.interceptors.request.use((config) => {
      // add access token
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${this.opts.accessToken()}`,
      };

      // add instance and company if set
      // allow api calls to 'instances' endpoint without instance and company
      if (this.opts?.instance && !config.url?.includes("Instances"))
        config.headers["X-Instance"] = this.opts.instance;
      if (this.opts?.company && !config.url?.includes("Instances"))
        config.headers["X-Company"] = this.opts.company;

      // add content type to requests with outgoing data
      if (["post", "patch", "put"].indexOf(config.method as any) >= 0) {
        config.headers = {
          ...config.headers,
          "Content-Type": "application/json",
          Accept: "application/json",
        };
      }

      // add resource to data if set in options
      if (
        ["post", "patch", "put"].indexOf(config.method as any) >= 0 &&
        this.opts.populateResource &&
        !config.url?.includes("stopwatches") &&
        !config.url?.includes("setRoles") &&
        !config.url?.includes("clients") &&
        !config.url?.includes("users") &&
        !config.url?.includes("$batch") &&
        !config.url?.includes("jira") &&
        !config.url?.includes("settings")
      ) {
        if (config.url?.includes("validate")) {
          config.data = {
            timerecording: {
              ...config.data,
              resource: { id: this.opts.populateResource.id },
            },
          };
        } else {
          config.data = {
            ...config.data,
            resource: { id: this.opts.populateResource.id },
          };
        }
      }

      return config;
    });

    // add interceptors to axios response
    this.axiosInstance.interceptors.response.use(
      (resp) => {
        // If response payload is an Odata array and no info wants to be received,
        // remove redundant information
        if (resp.data.value && !this.opts.odataInfo) {
          resp.data = resp.data.value;
        }
        if (resp.data["@odata.context"] && !this.opts.odataInfo) {
          delete resp.data["@odata.context"];
        }
        if (resp.data.responses) {
          resp.data.responses.forEach((response: BatchResponse<any>) => {
            if (response.body.value && !this.opts.odataInfo) {
              response.body = response.body.value;
            } else if (
              response.body["@odata.context"] &&
              !this.opts.odataInfo
            ) {
              delete response.body["@odata.context"];
            }
          });
        }

        return resp;
      },
      (error) => {
        if (axios.isAxiosError(error)) {
          throw new Error(error.message);
        } else {
          throw error;
        }
      }
    );
  }

  public set company(v: string | undefined) {
    this.opts.company = v;
  }

  public get company(): string | undefined {
    return this.opts.company;
  }

  public set instance(v: string | undefined) {
    this.opts.instance = v;
  }

  public get instance(): string | undefined {
    return this.opts.instance;
  }

  public set odataInfo(v: boolean | undefined) {
    this.opts.odataInfo = v;
  }

  public get odataInfo(): boolean | undefined {
    return this.opts.odataInfo;
  }

  public getCategoryService(): CategoryService {
    if (!this.categoryService) {
      this.categoryService = new CategoryService(
        this.opts.url,
        this.axiosInstance
      );
    }

    return this.categoryService;
  }

  public getTemplateService() {
    if (!this.templateService) {
      this.templateService = new TemplateService(
        this.opts.url,
        this.axiosInstance
      );
    }

    return this.templateService;
  }

  public getResourceService() {
    if (!this.resourceService) {
      this.resourceService = new ResourceService(
        this.opts.url,
        this.axiosInstance
      );
    }

    return this.resourceService;
  }

  public getTnTService() {
    if (!this.tntService) {
      this.tntService = new TnTService(this.opts.url, this.axiosInstance);
    }

    return this.tntService;
  }

  public getInstanceService() {
    if (!this.instanceService) {
      this.instanceService = new InstanceService(
        this.opts.url,
        this.axiosInstance
      );
    }

    return this.instanceService;
  }

  public getAdminService() {
    if (!this.adminService) {
      this.adminService = new AdminService(this.opts.url, this.axiosInstance);
    }

    return this.adminService;
  }

  public getRecService() {
    if (!this.recService) {
      this.recService = new RecService(this.opts.url, this.axiosInstance);
    }

    return this.recService;
  }

  public getJobAssignmentsService() {
    if (!this.jobAssignmentsService) {
      this.jobAssignmentsService = new JobAssignmentsService(
        this.opts.url,
        this.axiosInstance
      );
    }

    return this.jobAssignmentsService;
  }

  public getOnboardingService() {
    if (!this.onboardingService) {
      this.onboardingService = new OnboardingService(
        this.opts.url,
        this.axiosInstance
      );
    }

    return this.onboardingService;
  }

  public getWorkItemService() {
    if (!this.workItemService) {
      this.workItemService = new WorkItemService(
        this.opts.url,
        this.axiosInstance
      );
    }

    return this.workItemService;
  }

  public getCalendarService() {
    if (!this.calendarService) {
      this.calendarService = new CalendarService(
        this.opts.url,
        this.axiosInstance
      );
    }

    return this.calendarService;
  }

  public getBatchService() {
    if (!this.batchService) {
      this.batchService = new BatchService(this.opts.url, this.axiosInstance);
    }

    return this.batchService;
  }
}
