import {
  CalendarEntries,
  CalendarSensitivity,
  RecordTimeRec,
} from "@dyce/tnt-api";
import { DateTime } from "luxon";

/**
 * Function to get the number of open appointments
 * @param param0 see props
 * @returns number of open appointments
 */
export const handleOpenAppointments = ({
  calendarEntries,
  timeRecordings,
}: {
  /**
   * Array of all time recordings
   */
  timeRecordings: RecordTimeRec[];
  /**
   * Array of all calendar entries
   */
  calendarEntries: CalendarEntries[] | null;
}) => {
  const eventMatchIdCount: string[] = [];
  if (calendarEntries) {
    calendarEntries.forEach((cal) => {
      const timeRec = timeRecordings.filter(
        (rec) => rec.calendarEventId === cal.id
      );
      if (cal.isAllDay && cal.id) {
        // Add all eventIds that are all day events
        eventMatchIdCount.push(cal.id);
      } else if (cal.sensitivity === CalendarSensitivity.PRIVATE && cal.id) {
        // Add all eventIds that are private
        eventMatchIdCount.push(cal.id);
      }
      timeRec.forEach((rec) => {
        if (rec.start && cal.id) {
          const timeRecStart = DateTime.fromISO(rec.start!);
          const calendarStart = DateTime.fromISO(cal.start);
          if (timeRecStart.day === calendarStart.day) {
            // Add all eventIds that match with linked record at same day
            eventMatchIdCount.push(cal.id);
          }
        }
      });
    });
  }
  let countOfEventMatchIdCount = 0;

  if (calendarEntries) {
    // Filter out records (plural) with linked id at same day (do not count double...)
    countOfEventMatchIdCount = calendarEntries.filter((cal) => {
      if (cal.id && !eventMatchIdCount.includes(cal.id)) {
        return cal;
      } else {
        return null;
      }
    }).length;
  }

  return countOfEventMatchIdCount;
};
