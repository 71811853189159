import React, { useEffect } from "react";
// Types
import { DataGridHandle, SortDirection } from "react-data-grid";
// DYCE-Lib
import { ArrowKeys, IDatagridRow, IEnterHelperProps } from "@dyce/ui";
// MUI
import { Box } from "@mui/material";

/**
 * Helper component
 * Callback on Enter; returns ID and shows selected
 * value in Datagrid instead of empty cell.
 * @memberof Datagrid
 * @returns ID of selected entry
 */
export const EnterHelper: React.FC<IEnterHelperProps> = ({
  value,
  id,
  onEnter,
}) => {
  useEffect(() => {
    if (id?.length) {
      onEnter(id);
    }
  }, [id]);

  return <Box sx={{ padding: "0px 8px" }}>{value}</Box>;
};

/**
 * Default sorting if no custom sorting is provided.
 * Its encouraged to provide a custom sorting, as this will most likely cause problems with most data types.
 * @param initialRows The rows the grid is initiated with
 * @param sortColumn The key of the column to sort
 * @param sortDirection Sort direction
 * @returns Sorted rows
 */
export const defaultGridSort =
  (
    initialRows: IDatagridRow<any>[],
    sortColumn: string,
    sortDirection: SortDirection
  ) =>
  (rows: IDatagridRow<any>[]): IDatagridRow<any>[] => {
    const comparer = (a: IDatagridRow, b: IDatagridRow) => {
      if (sortDirection === "ASC") {
        return (a as any)[sortColumn] > (b as any)[sortColumn] ? 1 : -1;
      } else {
        return (a as any)[sortColumn] < (b as any)[sortColumn] ? 1 : -1;
      }
    };
    return sortColumn === "id" ? initialRows : [...rows].sort(comparer as any);
  };

/**
 * Function to set Scroll-Position and select Cell if hotkeys are used
 * @param topHeight Height in pixels where scroll position has to be
 * @param scrollDirection String "up" | "down" | "none"
 * @param keyPressed @see {ArrowKeys} Boolean of arrowUp
 * @param rowHeight Height of rows
 * @param gridRef Reference from RDG
 */
export const handleNewScrollPosition = (
  topHeight: number,
  scrollDirection: "up" | "down" | "none",
  keyPressed: ArrowKeys,
  rowHeight: number,
  gridRef: React.RefObject<DataGridHandle>
) => {
  if (keyPressed.arrowDown || keyPressed.arrowUp) {
    const columnHeight = Math.round(topHeight / rowHeight);
    setTimeout(() => {
      gridRef &&
        gridRef.current &&
        gridRef.current.selectCell({
          idx: 0,
          rowIdx: columnHeight,
        });
      if (scrollDirection === "up") {
        gridRef &&
          gridRef.current &&
          gridRef.current.element &&
          gridRef.current.element.scrollTo({
            top: topHeight,
            left: 0,
          });
      }
    }, 1);
  } else {
    gridRef &&
      gridRef.current &&
      gridRef.current.element &&
      gridRef.current.element.scrollTo({
        top: topHeight,
        left: 0,
      });
  }
};
