import React, { useState, useEffect, useRef } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { useHotkeys } from "react-hotkeys-hook";
// Dyce-Lib
import { OSDescription, OSModifiersClass } from "../../static-provider/types";
import { useStaticContent } from "../../static-provider/static-provider";
import { Tooltip } from "../../tooltip/tooltip";
import { TooltipSwitcher } from "../../tooltip/tooltip-switcher";
import {
  JobTaskStatusOptions,
  PopulatedJobTask,
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  TemplateCategory,
  ValidatedRecord,
} from "@dyce/tnt-api";
import { SaveAndCloseOutlined, SaveAndNewOutlined } from "@dyce/ui-assets";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import BookmarkAddOutlinedIcon from "@mui/icons-material/BookmarkAddOutlined";
import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { LoadingButton } from "@mui/lab";
import { DyceTheme } from "@dyce/theme";
// Components
import { TemplateList } from "../template-list/template-list";
import { RecordDatePicker } from "../template-date-picker/record-date-picker";
import { ButtonGroupSwitcher } from "./button-group-switcher";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
    },
    buttonsContainer: {
      display: "flex",
      flexDirection: "row-reverse",
      alignItems: "flex-end",
    },
    saveButton: {
      display: "inherit",
      justifyContent: "center",
      alignItems: "center",
    },
    templateButton: {
      display: "flex",
    },
    abortButton: {
      display: "flex",
    },
    loadingButton: {
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
    },
    saveOptions: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-start",
      gap: 8,
    },
  })
);

interface IButtonsContainerProps {
  /**
   * Callback fired when template is selected
   */
  onSelectTemplate: (template: RecordTemplate) => void;
  /**
   * StateSetter to block escape behavior
   */
  setBlockEscape: React.Dispatch<React.SetStateAction<boolean>>;
  /**
   * Current template category, if null template list get's blocked;
   */
  templateCategory: TemplateCategory | null;
  /**
   * Entry from template selection
   */
  templateEntry: RecordTemplate;
  /**
   * If true, loading animation (buttons etc.) rendered and buttons are disabled
   */
  loading: boolean;
  /**
   * If true, buttons are disabled
   * @default false
   */
  isDisabled: boolean;
  /**
   * Status from jobTask, used for different toolTips and | or disable saving
   */
  jobTaskStatus: PopulatedJobTask;
  /**
   * Callback fired onClose
   */
  onCloseHandler: (
    event: any,
    value: "saveAndNew" | "saveAndClose" | "abort"
  ) => void;
  /**
   * Callback fired onSave
   */
  onSave?: (
    entry: RecordEntry & Partial<RecordTemplate & RecordTimeRec>,
    isTimeRecord?: boolean
  ) => Promise<{
    newEntry: RecordEntry | RecordTemplate | null;
    error: boolean;
  }>;
  /**
   * If true, 'Save and new' option is selectable
   */
  saveAndNew: boolean;
  /**
   * Modifiers for keyboard-modifiers and modifier-description
   */
  osModifiers: {
    modifiers: OSModifiersClass;
    modifiersDesc: OSDescription;
  };
  /**
   * Callback to block closing from Editor e.g. when API call is pending;
   * Will send false to disable blocking
   */
  blockClosingCallback: (value: boolean) => void;
  /**
   * Locale with hyphen, e.g. 'en-GB'
   */
  localeHyphen: string;
  /**
   * If true, components will be disabled;
   */
  pending: boolean;
  /**
   * Define size of buttons
   * @default "medium"
   */
  size?: "small" | "medium";
  /**
   * Callback fired when template is submitted to validate object;
   */
  validateTemplateObject?: (
    template: RecordTemplate
  ) => Promise<ValidatedRecord>;
  /**
   * States for template list with callbacks
   */
  states: {
    openCreatedRecording: (value: boolean) => void;
    clearLatestCreatedId: () => void;
    isChecked: boolean;
    deleteRecord: (id: string) => Promise<boolean>;
    getAllTemplates?: () => void;
    templates: [string, RecordTemplate[]][];
  };
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const ButtonsContainer: React.FC<IButtonsContainerProps> = ({
  onSelectTemplate,
  setBlockEscape,
  templateCategory,
  templateEntry,
  onSave,
  isDisabled = false,
  loading,
  jobTaskStatus,
  onCloseHandler,
  pending,
  saveAndNew,
  osModifiers,
  blockClosingCallback,
  localeHyphen,
  size = "medium",
  validateTemplateObject,
  states,
  children,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<DyceTheme>();
  const { docuLinks } = useStaticContent();
  const lgViewport = useMediaQuery(
    theme.breakpoints.down(
      theme.palette.propsDyce.drawerLeft.isOpen ? 1450 : 1200
    )
  );

  const labels: Record<string, string> = {
    save: t("tooltip.editor.saveAndClose", {
      modifier: osModifiers.modifiersDesc.modifierCtrl,
    }),
    pending: t("tooltip.editor.pending"),
    abort: t("tooltip.editor.abort"),
  };

  // References
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  // UseStates
  const [openSaveOptions, setOpenSaveOptions] = useState<boolean>(false);
  const [openTemplateDialog, setOpenTemplateDialog] = useState<boolean>(false);
  const [openCalendar, setOpenCalendar] = useState<boolean>(false);
  const [renderCalendar, setRenderCalendar] = useState<boolean>(false);
  const [forceCancel, setForceCancel] = useState<boolean>(false);
  const [darkMode] = useState<boolean>(theme.palette.mode === "dark");

  // UseEffects
  useEffect(() => {
    if (openTemplateDialog || openCalendar || renderCalendar) {
      setBlockEscape(true);
    } else {
      setBlockEscape(false);
    }
  }, [openTemplateDialog, openCalendar, renderCalendar]);

  useEffect(() => {
    let cancelTimer: NodeJS.Timeout;
    if (loading) {
      cancelTimer = setTimeout(() => {
        setForceCancel(true);
      }, 10000);
    }
    return () => clearTimeout(cancelTimer);
  }, [loading]);

  // Hotkeys
  useHotkeys(
    `${osModifiers.modifiers.modifierAlt}+t`,
    (e) => {
      e.preventDefault();
      if (templateCategory === null && !isDisabled && !pending) {
        setOpenTemplateDialog(true);
      }
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"],
      keyup: true,
    }
  );

  // Handler
  const handleForceAbortAndSave = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setForceCancel(false);
    blockClosingCallback(false);
    onCloseHandler(event, "saveAndClose");
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>
  ) => {
    onCloseHandler(event, "saveAndNew");
    setOpenSaveOptions(false);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpenSaveOptions(false);
  };

  const handleBorderRadius = (): string | number => {
    return openSaveOptions ? 0 : theme.shape.borderRadius;
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <TemplateList
        showTemplateDialog={setOpenTemplateDialog}
        openTemplateDialog={openTemplateDialog}
        onSelectTemplate={onSelectTemplate}
        getAllTemplates={states.getAllTemplates}
        templates={states.templates}
      />
      {renderCalendar && validateTemplateObject && (
        <RecordDatePicker
          returnProps={{
            fromLocation: "Templates",
            scrollPosition: window.scrollY,
          }}
          open={openCalendar}
          anchorEl={buttonRef.current}
          onClose={({ openCalendar, renderCalendar }) => {
            setOpenCalendar(openCalendar);
            setRenderCalendar(renderCalendar);
          }}
          templateEntry={templateEntry}
          onSave={onSave}
          localeHyphen={localeHyphen}
          validateTemplateObject={validateTemplateObject}
          states={states}
          templateCategory={templateCategory}
        />
      )}
      <div className={classes.container}>
        <div
          className={classes.buttonsContainer}
          style={{
            gap:
              states.getAllTemplates === undefined &&
              validateTemplateObject === undefined
                ? size === "small"
                  ? "0.5rem"
                  : "0.65rem"
                : size === "small"
                ? "0.75rem"
                : "1.25rem",
          }}
        >
          <div className={classes.saveButton}>
            <Tooltip
              label={
                forceCancel
                  ? labels["abort"]
                  : loading
                  ? labels["pending"]
                  : labels["save"]
              }
              urlPath={
                docuLinks.timetracking.reusableComponents.editor.saveButton
              }
              position={openSaveOptions ? "right" : "bottom"}
            >
              <span>
                <ButtonGroupSwitcher
                  size={size}
                  asButtonGroup={!saveAndNew}
                  anchorRef={anchorRef}
                  sx={{
                    "& .MuiLoadingButton-root": {
                      color: "#fff",
                    },
                    borderBottomLeftRadius: handleBorderRadius(),
                    borderBottomRightRadius: handleBorderRadius(),
                  }}
                >
                  {forceCancel ? (
                    <Button
                      size={size}
                      tabIndex={51}
                      ref={(e) => (buttonRef.current = e)}
                      onClick={(e) => handleForceAbortAndSave(e)}
                      startIcon={<CancelOutlinedIcon />}
                      color="error"
                      disabled={isDisabled || pending}
                    >
                      {t("tooltip.editor.abort")}
                    </Button>
                  ) : (
                    <LoadingButton
                      size={size}
                      id="saveAndClose-timeRecording"
                      tabIndex={50}
                      ref={(e) => (buttonRef.current = e)}
                      onClick={(e) => onCloseHandler(e, "saveAndClose")}
                      disabled={isDisabled || pending}
                      variant="contained"
                      color="primary"
                      loading={loading}
                      loadingPosition="start"
                      startIcon={
                        <SaveAndCloseOutlined
                          width={20}
                          height={20}
                          fill="white"
                        />
                      }
                      sx={{
                        borderBottomLeftRadius: handleBorderRadius(),
                      }}
                    >
                      {t("timerecs.editor.options.saveAndClose")}
                    </LoadingButton>
                  )}
                  {!saveAndNew && (
                    <Button
                      size={size}
                      tabIndex={52}
                      aria-controls={
                        openSaveOptions ? "split-button-menu" : undefined
                      }
                      aria-expanded={openSaveOptions ? "true" : undefined}
                      aria-label="select merge strategy"
                      aria-haspopup="menu"
                      onClick={() =>
                        setOpenSaveOptions((prevOpen) => !prevOpen)
                      }
                      disabled={loading || forceCancel || isDisabled || pending}
                      sx={{
                        backgroundColor: theme.palette.primary.light,
                        ":hover": {
                          backgroundColor: theme.palette.primary.main,
                        },
                        borderBottomRightRadius: handleBorderRadius(),
                      }}
                    >
                      <ArrowDropDownIcon />
                    </Button>
                  )}
                </ButtonGroupSwitcher>
              </span>
            </Tooltip>
            {/* This Popper serves options "Save & New" for option button above */}
            <Popper
              open={openSaveOptions}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              style={{
                zIndex: 1,
              }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper
                    sx={{
                      zIndex: 2000,
                      width: anchorRef.current
                        ? anchorRef.current.clientWidth
                        : 294,
                      borderTopLeftRadius: 0,
                      borderTopRightRadius: 0,
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList id="split-button-menu">
                        <Tooltip
                          label={t("tooltip.editor.saveAndNew", {
                            modifier: osModifiers.modifiersDesc.modifierCtrl,
                          })}
                          urlPath={
                            docuLinks.timetracking.reusableComponents.editor
                              .saveButton
                          }
                          position={"right"}
                        >
                          <MenuItem
                            id="saveAndNew-timeRecording"
                            tabIndex={53}
                            style={{
                              fontWeight: 500,
                              fontSize: "0.875rem",
                              lineHeight: 1.75,
                              letterSpacing: "0.02857em",
                              textTransform: "uppercase",
                              paddingLeft: 12,
                            }}
                            onClick={(e) => handleMenuItemClick(e)}
                          >
                            <div className={classes.saveOptions}>
                              <SaveAndNewOutlined
                                height={20}
                                width={20}
                                fill={darkMode ? "white" : "#363636"}
                              />
                              {t("timerecs.editor.options.saveAndNew")}
                            </div>
                          </MenuItem>
                        </Tooltip>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
          <div className={classes.templateButton}>
            {states.getAllTemplates && templateCategory === null ? (
              <Tooltip
                label={t("tooltip.editor.template", {
                  modifier: osModifiers.modifiersDesc.modifierAlt,
                })}
                urlPath={
                  docuLinks.timetracking.reusableComponents.editor
                    .templateButton
                }
                position="bottom"
              >
                <span>
                  <Button
                    size={size}
                    id="openTemplates-timeRecording"
                    tabIndex={54}
                    onClick={() => setOpenTemplateDialog(true)}
                    disabled={openTemplateDialog || isDisabled || pending}
                    variant={darkMode ? "outlined" : "contained"}
                    color="inherit"
                    startIcon={<BookmarkAddOutlinedIcon />}
                  >
                    {lgViewport
                      ? t("timerecs.editor.options.template.small")
                      : t("timerecs.editor.options.template.normal")}
                  </Button>
                </span>
              </Tooltip>
            ) : (
              <TooltipSwitcher
                tooltip={
                  jobTaskStatus === null
                    ? t("tooltip.editor.share")
                    : jobTaskStatus.status !== undefined &&
                      jobTaskStatus.status !== JobTaskStatusOptions.OPEN
                    ? t("tooltip.record.jobTaskStatusNotOpen")
                    : t("tooltip.editor.share")
                }
                position="bottom-start"
                urlPath={
                  jobTaskStatus === null
                    ? docuLinks.timetracking.reusableComponents.editor
                        .createTimerecordingButton
                    : jobTaskStatus.status !== undefined &&
                      jobTaskStatus.status !== JobTaskStatusOptions.OPEN
                    ? docuLinks.timetracking.timerecordings.records.contextMenu
                        .jobTaskNotOpen
                    : docuLinks.timetracking.reusableComponents.editor
                        .createTimerecordingButton
                }
              >
                {validateTemplateObject && (
                  <span>
                    <Button
                      size={size}
                      id="calendar-timeRecording"
                      tabIndex={54}
                      ref={(e) => (buttonRef.current = e)}
                      onClick={() => {
                        setRenderCalendar(true);
                        setOpenCalendar(true);
                      }}
                      disabled={
                        openCalendar ||
                        (jobTaskStatus !== null &&
                          jobTaskStatus.status !== undefined &&
                          jobTaskStatus.status !== JobTaskStatusOptions.OPEN) ||
                        pending
                      }
                      color="inherit"
                      variant={darkMode ? "outlined" : "contained"}
                      startIcon={<IosShareOutlinedIcon />}
                    >
                      {t("templates.createNewTimerec")}
                    </Button>
                  </span>
                )}
              </TooltipSwitcher>
            )}
          </div>
          <div className={classes.abortButton}>
            <Tooltip
              label={t("tooltip.editor.options.abortTooltip")}
              urlPath={
                docuLinks.timetracking.reusableComponents.editor.abortButton
              }
              position="bottom"
            >
              <span>
                <Button
                  size={size}
                  id="abort-timeRecording"
                  tabIndex={55}
                  variant={darkMode ? "outlined" : "contained"}
                  color="inherit"
                  disabled={loading || pending}
                  startIcon={<CancelOutlinedIcon />}
                  onClick={(e) => onCloseHandler(e, "abort")}
                >
                  {t("timerecs.editor.options.abort")}
                </Button>
              </span>
            </Tooltip>
          </div>
        </div>
        {children}
      </div>
    </Box>
  );
};
