import React, { useEffect, useState, FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
// Dyce-Lib
import {
  AdminCard,
  Beat,
  BeatContainer,
  BeatLabel,
  PlotContainer,
  PlotGraph,
  PlotHeader,
  SubHeader,
  Tooltip,
  useStaticContent,
} from "@dyce/ui";
import { DyceTheme } from "@dyce/theme";
import { useViewPortHeight } from "@dyce/hooks";
import {
  useAppDispatch,
  useAppSelector,
  serverStatusSelector,
  serverHealthSelector,
  selectCurrentWorkspace,
  getServerStatus,
  selectLanguageCode,
} from "@dyce/slices";
import { msalInstance } from "@dyce/auth";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Box, Fab, Grid, Typography, useTheme } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";

const useStyles = makeStyles(() =>
  createStyles({
    plotContainer: {
      width: "49%",
      justifyContent: "space-between",
    },
    plotGrid: {
      flexDirection: "row",
      display: "flex",
      justifyContent: "space-between",
    },
    content: {
      display: "flex",
      padding: "20px 15px 10px 15px",
      justifyContent: "space-between",
    },
    row: {
      display: "flex",
      flexDirection: "column",
    },
    url: {
      padding: "10px 15px 20px 15px",
      display: "flex",
      flexDirection: "column",
    },
  })
);

/**
 * Admin Dashboard Page
 * @class
 * Contains several info widget
 */
export const Dashboard: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const theme = useTheme<DyceTheme>();
  const winDim = useViewPortHeight();
  const { docuLinks } = useStaticContent();

  // Selectors
  const serverInfo = useAppSelector(serverStatusSelector);
  const serverStatus = useAppSelector(serverHealthSelector);
  const language = useAppSelector(selectLanguageCode);
  const workspace = useAppSelector(selectCurrentWorkspace);

  const handleGraphWidth = (): number => {
    if (theme.palette.propsDyce.drawerLeft.isOpen) {
      if (winDim.width < 1250) {
        return 300;
      } else if (winDim.width >= 1250 && winDim.width < 1570) {
        return 400;
      } else {
        return 500;
      }
    } else {
      if (winDim.width < 1050) {
        return 300;
      } else if (winDim.width >= 1050 && winDim.width < 1370) {
        return 400;
      } else {
        return 500;
      }
    }
  };

  // UseStates
  const [statusText, setStatusText] = useState<string>("");
  const [graphWidth, setGraphWidth] = useState<number>(handleGraphWidth());
  const [tenantId, setTenantId] = useState<string>("");
  const [bcPageNumber] = useState<string>("&page=70921525");

  // UseEffects
  useEffect(() => {
    msalInstance.refreshToken().then((res) => setTenantId(res.tenantId));
  }, []);

  const url = `https://businesscentral.dynamics.com/${tenantId}/${encodeURIComponent(
    workspace.instance || ""
  )}/?company=${encodeURIComponent(workspace.company || "")}`;

  // Reload the whole page
  const handleRefresh = () => {
    window.location.reload();
  };

  // UseEffects
  useEffect(() => {
    setGraphWidth(handleGraphWidth());
  }, [winDim.width, theme.palette.propsDyce.drawerLeft.isOpen]);

  useEffect(() => {
    // Update the "last checked" description from time to time to get descriptive readouts
    // clear on unmount
    const textUpdateTimer = setInterval(() => {
      serverInfo.status &&
        setStatusText(
          t("admin.dashboard.queue.lastSeen") +
            " " +
            DateTime.fromISO(serverInfo.status.lastHeartbeat)
              .setLocale(language || "en")
              .toUTC()
              .toRelative()
        );
    }, 2000);

    return () => {
      clearInterval(textUpdateTimer);
    };
  }, [serverInfo, serverStatus, language]);

  // get server status on initial render
  useEffect(() => {
    dispatch(getServerStatus());

    const timer = setInterval(() => {
      dispatch(getServerStatus());
    }, 60000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <SubHeader
        tooltipLabel={t("admin.dashboard.info")}
        tooltipUrlPath={docuLinks.administration.dashboard.subheader.info}
        title={t("admin.dashboard.title")}
        subtitle={
          serverStatus.status == "danger"
            ? t("admin.dashboard.subtitleError")
            : serverStatus.bcErrors > 0
            ? t("admin.dashboard.subtitleWarning", {
                num: serverStatus.bcErrors,
              })
            : t("admin.dashboard.subtitleSuccess")
        }
      >
        <Tooltip
          label={t("admin.dashboard.refresh")}
          urlPath={docuLinks.administration.dashboard.subheader.refreshButton}
          position="bottom-start"
        >
          <Fab color="primary" aria-label="refresh" onClick={handleRefresh}>
            <RefreshIcon />
          </Fab>
        </Tooltip>
      </SubHeader>
      <Box
        sx={{
          marginTop: "25px",
          marginRight: "28px",
          marginLeft: "23px",
          marginBottom: "25px",
        }}
        style={{
          marginBottom: "25px",
        }}
      >
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <BeatContainer onPaper={true} borderVariant="info">
              <Beat
                size="medium"
                variant={
                  serverStatus.bcErrors > 0
                    ? "warning"
                    : (serverStatus.status as "info")
                }
              />
              <BeatLabel
                textSize="medium"
                label={t("admin.dashboard.queue.title")}
                infoLabel={
                  serverStatus.status == "danger"
                    ? null
                    : serverStatus.bcErrors > 0
                    ? t("admin.dashboard.queueWarning")
                    : t("admin.dashboard.queueSuccess")
                }
                timestamp={statusText}
                vertical
              />
            </BeatContainer>
          </Grid>

          <Grid container item direction="row" className={classes.plotGrid}>
            <Grid item className={classes.plotContainer}>
              <PlotContainer>
                <PlotHeader
                  title={t("admin.dashboard.receiveStats.title")}
                  description={t("admin.dashboard.receiveStats.desc")}
                />
                {serverInfo.receiveStats ? (
                  <PlotGraph
                    width={graphWidth}
                    height={200}
                    chartData={serverInfo.receiveStats.details
                      .slice(1)
                      .slice(-12)}
                  />
                ) : (
                  <Typography style={{ padding: 20 }}>Loading...</Typography>
                )}
              </PlotContainer>
            </Grid>
            <Grid item className={classes.plotContainer}>
              <PlotContainer>
                <PlotHeader
                  title={t("admin.dashboard.sendStats.title")}
                  description={t("admin.dashboard.sendStats.desc")}
                />
                {serverInfo.receiveStats ? (
                  <PlotGraph
                    width={graphWidth}
                    height={200}
                    chartData={serverInfo.sendStats.details.slice(1).slice(-12)}
                  />
                ) : (
                  <Typography style={{ padding: 20 }}>Loading...</Typography>
                )}
              </PlotContainer>
            </Grid>
          </Grid>
          <Grid item>
            {/** environment details */}
            <AdminCard title={t("admin.dashboard.envDetail.title")}>
              <>
                <div className={classes.content}>
                  <div className={classes.row}>
                    <Typography>
                      {t("admin.dashboard.envDetail.tenant")}
                    </Typography>
                    <Typography variant="caption">{tenantId}</Typography>
                  </div>
                  <div className={classes.row}>
                    <Typography>
                      {t("admin.dashboard.envDetail.instance")}
                    </Typography>
                    <Typography variant="caption">
                      {workspace.instance || ""}
                    </Typography>
                  </div>
                  <div className={classes.row}>
                    <Typography>
                      {t("admin.dashboard.envDetail.company")}
                    </Typography>
                    <Typography variant="caption">
                      {workspace.company || ""}
                    </Typography>
                  </div>
                </div>
                <div className={classes.url}>
                  <Typography>Business Central URL</Typography>
                  <Typography variant="caption">
                    <a
                      href={url + bcPageNumber}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {url}
                    </a>
                  </Typography>
                </div>
              </>
            </AdminCard>
          </Grid>
          <Grid
            item
            container
            direction="row"
            className={classes.plotGrid}
            style={{ marginBottom: 25 }}
          >
            <Grid item className={classes.plotContainer}>
              {/** sync errors */}
              <AdminCard
                title={t("admin.dashboard.syncError.title")}
                titleAdornment={() => (
                  <Beat
                    withPadding={false}
                    variant={serverStatus.bcErrors > 0 ? "warning" : "success"}
                  />
                )}
              >
                <div className={classes.content}>
                  <div className={classes.row}>
                    <Tooltip
                      label={t("admin.dashboard.syncError.sendErrorsTooltip")}
                      urlPath={
                        docuLinks.administration.dashboard.subheader.info
                      }
                      position="right-end"
                    >
                      <Typography>
                        {t("admin.dashboard.syncError.sendErrors")}
                      </Typography>
                    </Tooltip>
                    <Typography variant="caption">
                      <a
                        href={
                          url +
                          `${bcPageNumber}&filter=%27DYCE%20Processed%20Message%27.Success%20IS%20%27Nein%27`
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        {t("admin.dashboard.syncError.count", {
                          num: serverStatus.bcErrors,
                        })}
                      </a>
                    </Typography>
                  </div>
                  <div className={classes.row}>
                    <Tooltip
                      label={t(
                        "admin.dashboard.syncError.receiveErrorsTooltip"
                      )}
                      urlPath={
                        docuLinks.administration.dashboard.subheader.info
                      }
                      position="left-end"
                    >
                      <Typography>
                        {t("admin.dashboard.syncError.receiveErrors")}
                      </Typography>
                    </Tooltip>
                    <Typography variant="caption">
                      {/** TODO: implement when defined */}
                      {t("admin.dashboard.syncError.count", {
                        num: 0,
                      })}
                    </Typography>
                  </div>
                </div>
              </AdminCard>
            </Grid>
            <Grid item className={classes.plotContainer}>
              {/** heartbeat */}
              <AdminCard
                title={t("admin.dashboard.heartbeat.title")}
                titleAdornment={() => (
                  <Beat
                    withPadding={false}
                    variant={serverStatus.status as "info"}
                  />
                )}
              >
                <div className={classes.content}>
                  <div className={classes.row}>
                    <Typography>
                      {t("admin.dashboard.receiveStats.heartbeat")}
                    </Typography>
                    <Typography variant="caption">
                      {serverStatus.sendStats
                        ? DateTime.fromISO(serverStatus.sendStats.to)
                            .setLocale(language || "en")
                            .toLocaleString(
                              DateTime.DATETIME_SHORT_WITH_SECONDS
                            )
                        : ""}
                    </Typography>
                  </div>
                  <div className={classes.row}>
                    <Typography>
                      {t("admin.dashboard.sendStats.heartbeat")}
                    </Typography>
                    <Typography variant="caption">
                      {serverStatus.sendStats
                        ? DateTime.fromISO(serverStatus.sendStats.to)
                            .setLocale(language || "en")
                            .toLocaleString(
                              DateTime.DATETIME_SHORT_WITH_SECONDS
                            )
                        : ""}
                    </Typography>
                  </div>
                </div>
                <div className={classes.content}>
                  <div className={classes.row}>
                    <Typography>{t("admin.dashboard.latency")}</Typography>
                    <Typography variant="caption">
                      {serverStatus.sendStats ? serverStatus.latency : ""}
                    </Typography>
                  </div>
                </div>
              </AdminCard>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
