import { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { CurrentUser, Instance } from "@dyce/tnt-api";
import { Tooltip } from "../tooltip/tooltip";
import { useStaticContent } from "../static-provider/static-provider";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Drawer, IconButton } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
// Components
import { UserMenuItems } from "./user-menu-items";

const useStyles = makeStyles(() =>
  createStyles({
    userButton: {
      color: "white",
    },
  })
);

export interface UserMenuProps {
  /**
   * the users current instance
   */
  instance: string;
  /**
   * the users currently company
   */
  company: string;
  /**
   * all instances available to the user
   */
  instances: Instance[];

  /**
   * is user menu expanded
   */
  isVisible: boolean;
  /**
   * fired when the user menu is collapsed or expanded
   */
  onToggle: () => void;
  /**
   * Current user information
   */
  currentUser: CurrentUser | null;
  /**
   * callback when a new instance/company combination is selected
   */
  onSelection?: (instance: Instance, company: string) => void;
}

export const UserMenu: FunctionComponent<UserMenuProps> = ({
  isVisible,
  instance,
  instances,
  company,
  onToggle,
  currentUser,
  onSelection,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { docuLinks } = useStaticContent();

  return (
    <>
      <Tooltip
        label={t("nav.usermenu.tooltip")}
        urlPath={docuLinks.navbar.usermenu}
        position="bottom-end"
      >
        <IconButton
          aria-label="user account"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={() => onToggle()}
        >
          <AccountCircleIcon className={classes.userButton} />
        </IconButton>
      </Tooltip>
      <Drawer anchor={"right"} open={isVisible} onClose={onToggle}>
        <div role="presentation">
          <UserMenuItems
            onClose={onToggle}
            instance={instance}
            instances={instances}
            company={company}
            currentUser={currentUser}
            onSelection={onSelection}
          />
        </div>
      </Drawer>
    </>
  );
};
