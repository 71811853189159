// Types
import { RoleAssignmentOptions } from "./CheckboxDescription";
// Dyce-Lib
import { PopulatedUser } from "@dyce/tnt-api";

/**
 * Checks if role is assigned to all selected users
 * @param roleId Role ID
 * @param userList List of all selected user
 */
export const isRoleChecked = (
  roleId: string,
  userList: PopulatedUser[]
): boolean => {
  const usersWithRole = userList.filter((u) =>
    u.roles.some((r) => r.id === roleId)
  );

  return usersWithRole.length === userList.length;
};

/**
 * Checks if role is assigned to some users
 * @param roleId Role ID
 * @param userList List of all selected users
 */
export const isRoleIndeterminate = (
  roleId: string,
  userList: PopulatedUser[]
): boolean => {
  const usersWithRole = userList.filter((u) =>
    u.roles.some((r) => r.id === roleId)
  );

  return (
    usersWithRole.length > 0 && !(usersWithRole.length === userList.length)
  );
};

/**
 * Calculates number of affected users by an assignement action
 * @param roleId Role ID that is to assign/remove
 * @param userList List of all affected users
 * @param action Remove/Add
 */
export const usersWithRole = (
  roleId: string,
  userList: PopulatedUser[],
  action: RoleAssignmentOptions
): number => {
  switch (action) {
    case RoleAssignmentOptions.ADD:
      return userList.filter((user) => !user.roles.some((r) => r.id === roleId))
        .length;
      break;
    case RoleAssignmentOptions.REMOVE:
      return userList.filter((user) => user.roles.some((r) => r.id === roleId))
        .length;
      break;
    default:
      return 0;
      break;
  }
};
