import { RootState, SetupLanguages } from "../../types/types";

export const selectSideDrawerLeft = (state: RootState) =>
  state.settings.ui.sideDrawerLeft;

export const selectDarkMode = (state: RootState) => state.settings.ui.darkMode;

export const selectTimeRecordingSettings = (state: RootState) =>
  state.settings.timerec;

export const selectLanguageCode = (state: RootState): SetupLanguages => {
  if (state.settings.general.language === undefined) {
    return "en";
  } else {
    return state.settings.general.language.includes("_")
      ? (state.settings.general.language.split("_")[0] as SetupLanguages)
      : (state.settings.general.language as SetupLanguages);
  }
};

export const selectLocale = (state: RootState) => state.settings.general.locale;

export const selectLocaleHyphen = (state: RootState) =>
  state.settings.general.locale.replace("_", "-");

export const selectOpenCreatedRecording = (state: RootState) =>
  state.settings.timerec.templates.openCreatedRecording;

export const selectIsLoading = (state: RootState) => state.settings.isLoading;

export const selectTimeTrackingToolDesign = (state: RootState) =>
  state.settings.ui.timeTrackingToolDesign;

export const selectTasksGroupedBy = (state: RootState) =>
  state.settings.timerec.tasks.groupedBy;

export const selectTasksWODate = (state: RootState) =>
  state.settings.timerec.tasks.showWithoutDate;

export const selectTasksFilter = (state: RootState) =>
  state.settings.timerec.tasks.filter;
