import {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
// Helper
import { useTranslation } from "react-i18next";
import { Controller, useFormContext } from "react-hook-form";
import { Expand, QueryOptions } from "odata-query";
import {
  apiCallLookUp,
  apiCallActivity,
  checkFurtherInfScrolling,
  errorCheck,
  getFieldData,
  handleFieldWithSelection,
  handlePendingCustomer,
  handlePendingJob,
  handlePendingActivity,
  handleResetPending,
  handlePendingJobTask,
  handleGetFieldController,
  handleOnGridSort,
  infScrollFieldHandler,
  showSkeletonsOnOpen,
  handleGetFieldFilter,
  handleRowFactoryFilter,
  handelFindEntryByValueFilter,
} from "./utils";
import {
  customerSelected,
  jobSelected,
  jobTaskSelected,
  activitySelected,
} from "./validateRecordTask";
import { InitialData } from "./initial-data";
import { handleFocusField } from "../utils";
// DYCE-Lib
import { DyceTheme } from "@dyce/theme";
import { useDebounce, useUpdateEffect } from "@dyce/hooks";
import { useAppDispatch } from "@dyce/slices";
import customerController from "../../look-up/tableDefinition/customerController";
import jobController from "../../look-up/tableDefinition/jobController";
import jobTaskController from "../../look-up/tableDefinition/jobTaskController";
import activityController from "../../look-up/tableDefinition/activityController";
import {
  CombineEntries,
  IDatagridRow,
  OnChangeEvent,
  ValidateRecordTask,
} from "../../types";
import {
  LookUpActivityOptions,
  LookUpMapping,
  LookUpOptions,
  LookUpShortOptions,
} from "@dyce/interfaces";
import {
  NonBillableReasonOptions,
  StatusOptions,
  TasksFilterStatus,
  JobTaskStatusOptions,
  PopulatedJobPlanningLine,
  PopulatedJobTask,
} from "@dyce/tnt-api";
import { MonetizationOffOutlinedIcon } from "@dyce/ui-assets";
import { FieldTypes } from "@dyce/interfaces";
// MUI
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { teal } from "@mui/material/colors";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
// Components
import { LookUp } from "../../look-up/look-up";

interface IMappingFieldProps<T> {
  /**
   * Name for field that should be rendered
   */
  fieldName: LookUpOptions;
  /**
   * Object state for projecting field @type {ValidateRecordTask}
   */
  projectingInput: ValidateRecordTask<T>;
  /**
   * Object state for projecting field @type {ValidateRecordTask} as copy (pre-state)
   */
  projectingInputPreState: ValidateRecordTask<T>;
  /**
   * Set projectingInput state callback
   */
  validateProjectingInput: (object: ValidateRecordTask<T>) => void;
  /**
   * mappingObject object from time-tracking-tool with all information (start, end, date, ...)
   */
  mappingObject: LookUpMapping;
  /**
   * If true, useEffect fires to fill object with new entries
   */
  fillFromTemplate: boolean;
  /**
   * If true, mapping fields can be changed dynamically
   * @default false
   */
  forceChangeMappingFields?: boolean;
  /**
   * If true, Datagrid will shown in darkMode style
   */
  darkMode: boolean;
  /**
   * If true, customer will be deleted when job is deleted
   * @default false
   */
  allowDeleteCustomer?: boolean;
  /**
   * If true, the input element will be focused during the first mount
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Provide count of projecting-fields in one wrapper to calculate width & wrap
   */
  fieldsPerWrapper?: {
    summary: number;
    currentIndex: number;
  };
  /**
   * If true, components will be disabled;
   */
  pending: boolean;
  /**
   * Counting information for infinite scrolling
   */
  infiniteLoadingOpts: {
    loadAbove: number;
    triggerAtBottom: number;
  };
  /**
   * Define tabIndex to focus correct field
   */
  tabIndex?: number;
  /**
   * Callback to block closing from Editor e.g. when API call is pending
   */
  blockClosingCallback: (value: boolean) => void;
  /**
   * Callback to block closing from Editor while validate by pending input
   */
  blockOnPendingCallback?: (value: boolean) => void;
  /**
   * If provided, field is directly focused on render
   */
  initialFocus?: LookUpOptions;
  /**
   * Define which field should be focused next for jobPlanningLine pending behavior
   * is finished with filling all necessary fields
   * @default 'jobPlanningLine'
   */
  focusFieldName?: FieldTypes;
  /**
   * Define size of lookup component
   */
  lookupSize?: "small" | "medium";
  /**
   * If true, expandable button will be hidden
   */
  hideExpandable?: boolean;
  /**
   * Callback fired when activity changed
   * @returns void
   */
  onActivityChanged?: ({
    jobPlanningLine,
    jobTask,
  }: {
    jobPlanningLine: PopulatedJobPlanningLine | null;
    jobTask: PopulatedJobTask | null;
  }) => void;
  /**
   * Props for filter list by tasks
   */
  filter?: {
    /**
     * If true, filter-button will be shown on top of datagrid;
     * This will cause different api endpoints;
     * @default false
     */
    workWithFilter?: TasksFilterStatus;
    /**
     * If true, list will be filtered by tasks
     * @default false
     */
    filterIsActive?: boolean;
    /**
     * Callback to handle filter state over all mapping field components
     * @param value Boolean value to set filter state
     */
    onChangeFilter?: (value: boolean) => void;
  };
  /**
   * Callback fired onBlur event, to save reference to state
   * @param ref MutableRefObject<HTMLInputElement | null>
   * @returns void
   */
  onInputBlur?: (ref: MutableRefObject<HTMLInputElement | null>) => void;
}

export function MappingField<T>({
  fieldName,
  projectingInput,
  projectingInputPreState,
  validateProjectingInput,
  mappingObject,
  fillFromTemplate,
  infiniteLoadingOpts,
  tabIndex,
  darkMode,
  allowDeleteCustomer = false,
  fieldsPerWrapper,
  autoFocus = false,
  pending,
  onActivityChanged,
  forceChangeMappingFields = false,
  blockClosingCallback,
  blockOnPendingCallback,
  initialFocus,
  filter,
  lookupSize,
  focusFieldName = "duration",
  hideExpandable,
  onInputBlur,
}: PropsWithChildren<IMappingFieldProps<T>>): JSX.Element {
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
    setFocus,
  } = useFormContext();
  const { loadAbove, triggerAtBottom } = infiniteLoadingOpts;
  const dispatch = useAppDispatch();
  const translationObj = {
    customer: t("timerec.dailyRecording.add.customer"),
    job: t("timerec.dailyRecording.add.job"),
    jobTask: t("timerec.dailyRecording.add.jobTask"),
    jobPlanningLine: t("timerec.dailyRecording.add.jobPlanningLine"),
  };
  const {
    workWithFilter = false,
    filterIsActive = false,
    onChangeFilter = () => undefined,
  } = filter ? filter : {};

  const mappedFieldName: LookUpActivityOptions | LookUpShortOptions =
    fieldName === "jobPlanningLine"
      ? ("activity" as LookUpActivityOptions)
      : (fieldName as unknown as LookUpShortOptions);

  // Debounce
  const { debouncedValue, setValue } = useDebounce<string>("", 300);

  // States
  const [activityDeletedByUser, setActivityDeletedByUser] =
    useState<boolean>(false);

  // UseEffects
  // If editing, set information for Cross-Dependencies
  useEffect(() => {
    // LookUpFields
    const fieldEntry = mappingObject[fieldName];
    let fieldValue: { id: string; entry: IDatagridRow<T>[] } = {
      id: "",
      entry: [],
    };

    if (fieldEntry && fieldEntry.id && fieldEntry.id.length > 0) {
      switch (fieldName) {
        case "customer":
          fieldValue = InitialData.getCustomer<T>(mappingObject);
          projectingInput.odataFilter.filterForCustomer = {
            customer: {
              id: { eq: { type: "guid", value: fieldValue.id } },
            },
          };
          break;
        case "job":
          fieldValue = InitialData.getJob<T>(mappingObject);
          projectingInput.odataFilter.filterForJob = {
            job: { id: { eq: { type: "guid", value: fieldValue.id } } },
          };
          break;
        case "jobTask":
          fieldValue = InitialData.getJobTask<T>(mappingObject);
          projectingInput.infos.memoryJobTaskId = fieldValue.id;
          projectingInput.infos.storeJobPlanningLineInfo =
            mappingObject.jobTask && mappingObject.jobTask.jobPlanningLine
              ? { ...mappingObject.jobTask.jobPlanningLine }
              : null;
          break;
        case "jobPlanningLine":
          fieldValue = InitialData.getActivity<T>(mappingObject);
          projectingInput.infos.memoryActivityId = fieldValue.id;
          projectingInput.infos.initialActivity = false;
          if (mappingObject.tntModelLine && mappingObject.tntModelLine.id) {
            projectingInput.infos.checkForSameTnTModelId =
              mappingObject.tntModelLine.id;
          }
          break;
      }

      projectingInput.ids[mappedFieldName] = fieldValue.id;
      validateProjectingInput({
        ...projectingInput,
        entries: {
          ...projectingInput.entries,
          [mappedFieldName]: fieldValue.entry,
        },
        memoryEntries: {
          ...projectingInput.memoryEntries,
          [mappedFieldName]: fieldValue.entry[0],
        },
        memoryIds: {
          ...projectingInput.memoryIds,
          [mappedFieldName]: fieldValue.id,
        },
      });
      if (fieldName === "jobTask") {
        projectingInput.orderBy.jobTask = jobTaskController.getSqlColumnName(
          "description",
          "ASC"
        );
      }
      projectingInput.entries[mappedFieldName] = fieldValue.entry;
      projectingInput.memoryEntries[mappedFieldName] = fieldValue.entry[0];
      projectingInput.memoryIds[mappedFieldName] = fieldValue.id;
    } else if (
      forceChangeMappingFields &&
      !fillFromTemplate &&
      fieldEntry === null
    ) {
      // Clear field(s) if e.g. area changed in extensions (DevOps)
      projectingInput.ids[mappedFieldName] = "";
      validateProjectingInput({
        ...projectingInput,
        entries: { ...projectingInput.entries, [mappedFieldName]: [] },
        memoryEntries: {
          ...projectingInput.memoryEntries,
          [mappedFieldName]: null,
        },
        memoryIds: {
          ...projectingInput.memoryIds,
          [mappedFieldName]: "",
        },
      });
      if (fieldName === "jobTask") {
        projectingInput.orderBy.jobTask =
          "job/no ASC, no ASC, description ASC, job/description ASC";
      }
      projectingInput.entries[mappedFieldName] = [];
      projectingInput.memoryEntries[mappedFieldName] = null;
      projectingInput.memoryIds[mappedFieldName] = "";
      projectingInput.dependency.deleteFields = true;
    }
  }, [fillFromTemplate]);

  // API Call handler
  const getActivityData = async ({
    inputQuery,
    filterValue,
  }: {
    inputQuery: string;
    filterValue: boolean;
  }) => {
    projectingInput.infos.activityLoading = true;
    const jobTaskId: string | null =
      projectingInput.ids.jobTask.length > 0
        ? projectingInput.ids.jobTask
        : null;

    const entries: IDatagridRow<T>[] = await apiCallActivity<IDatagridRow<T>[]>(
      {
        doFilter: inputQuery.length > 0,
        debouncedValue: inputQuery,
        orderBy: projectingInput.orderBy.activity,
        jobId: projectingInput.ids.job,
        jobTaskId: jobTaskId,
        getTaskedList: filterValue,
        dispatch: dispatch,
        controller: activityController,
      }
    );

    handleNewEntries({ newEntries: entries, key: mappedFieldName });
    projectingInput.infos.activityLoading = false;
    // If no entries found, set activityHasValue to false
    return entries.length === 0 ? false : true;
  };

  useEffect(() => {
    if (initialFocus) {
      handleFocusField(setFocus, initialFocus, 50);
    }
  }, [initialFocus]);

  /**
   * Triggers API call, when User cleared (deleted) value in Activity field.
   * Could be also possible, that field is cleared from logic when jobTask was
   * cleared by user, then no API call should triggered!
   */
  useUpdateEffect(() => {
    if (activityDeletedByUser) {
      getActivityData({ inputQuery: "", filterValue: filterIsActive });
      setActivityDeletedByUser(false);
    }
  }, [
    activityDeletedByUser,
    projectingInput.infos.memoryJobTaskId,
    projectingInput.infos.activityHasValue,
  ]);

  /**
   * Triggers API calls with new order-direction when user clicked on column-header
   * of Datagrid in Look-Up field
   */
  useEffect(() => {
    if (projectingInput.infos.fromController === fieldName) {
      if (fieldName !== LookUpOptions.JOBPLANNINGLINE) {
        handleSelection(filterIsActive);
      } else {
        getActivityData({
          inputQuery: debouncedValue,
          filterValue: filterIsActive,
        });
      }
      handleSkeletons();
    }
  }, [projectingInput.orderBy]);

  /**
   * Triggers API calls on user input after debounced value is set (300ms)
   */
  useEffect(() => {
    if (debouncedValue.length > 0) {
      handleCallWithDebounce();
    }
  }, [debouncedValue]);

  /**
   * Hook to check for pending input/Api call
   */
  useEffect(() => {
    handlePendingBehavior();
  }, [
    projectingInput.pending.field,
    projectingInput.pending.isLoading,
    projectingInput.pending.pendingApiCalls,
  ]);

  // Handler
  /**
   * If user typed into field and blurred before infos are
   * received from BackEnd => UseEffect triggers
   */
  const handlePendingBehavior = async () => {
    // Check if input is pending
    if (
      projectingInput.pending.isLoading === true &&
      projectingInput.pending.blurred !== null &&
      projectingInput.pending.pendingApiCalls === 0
    ) {
      // Check for customer-field
      if (
        projectingInput.pending.blurred !== null &&
        projectingInput.pending.blurred.field === LookUpOptions.CUSTOMER &&
        projectingInput.entries.customer.length > 0 &&
        (projectingInput.entries.customer[0].cells as any).skells === false
      ) {
        setFocus("hidden");
        const newProjectingInput = await handlePendingCustomer({
          projectingInput,
          setFocus,
          mappingObject,
          setValue,
          dispatch,
        });
        validateProjectingInput(newProjectingInput);
      } else if (
        projectingInput.pending.blurred !== null &&
        projectingInput.pending.blurred.field === LookUpOptions.CUSTOMER &&
        projectingInput.entries.customer.length === 0 &&
        projectingInput.ids["customer"] !== "Error"
      ) {
        const customerId = errorCheck({
          columns: customerController.createColumns(),
          entries: projectingInput.entries.customer,
          rawInput: projectingInput.pending.blurred.inputValue,
          valueId: "",
        });
        if (customerId === null) {
          projectingInput.pending.blurred.onChangeFn({
            id: "Error",
            name: null,
            no: null,
          });
          projectingInput.ids.customer = "Error";
          projectingInput.entries.customer = [];
          handleFocusField(setFocus, "hidden");
          handleFocusField(setFocus, LookUpOptions.CUSTOMER);
          validateProjectingInput(
            handleResetPending({ projectingInput, setValue })
          );
        }
      }
      // Check for job-field
      if (
        projectingInput.pending.blurred !== null &&
        projectingInput.pending.blurred.field === LookUpOptions.JOB &&
        projectingInput.entries.job.length > 0 &&
        (projectingInput.entries.job[0].cells as any).skells === false
      ) {
        setFocus("hidden");
        const newProjectInput = await handlePendingJob({
          projectingInput,
          validateObjectCopy: projectingInputPreState,
          setFocus,
          allowDeleteCustomer,
          mappingObject,
          setValue,
          dispatch,
        });
        validateProjectingInput(newProjectInput);
      } else if (
        projectingInput.pending.blurred !== null &&
        projectingInput.pending.blurred.field === LookUpOptions.JOB &&
        projectingInput.entries.job.length === 0 &&
        projectingInput.ids["job"] !== "Error"
      ) {
        const jobId = errorCheck({
          columns: jobController.createColumns(),
          entries: projectingInput.entries.job,
          rawInput: projectingInput.pending.blurred.inputValue,
          valueId: "",
        });
        if (jobId === null) {
          projectingInput.infos.jobHadError = true;
          projectingInput.pending.blurred.onChangeFn({
            id: "Error",
            description: null,
            no: null,
          });
          projectingInput.infos.memoryLastJobId = "";
          projectingInput.ids.job = "Error";
          projectingInput.entries.job = [];
          handleFocusField(setFocus, "hidden");
          validateProjectingInput(
            handleResetPending<T>({ projectingInput, setValue })
          );
        }
      }
      // Check for jobTask-field
      if (
        projectingInput.pending.blurred !== null &&
        projectingInput.pending.blurred.field === LookUpOptions.JOBTASK &&
        projectingInput.entries.jobTask.length > 0 &&
        (projectingInput.entries.jobTask[0].cells as any).skells === false &&
        projectingInput.infos.jobTaskLoading === false
      ) {
        setFocus("hidden");
        await asyncPendingJobTask();
      } else if (
        projectingInput.pending.blurred !== null &&
        projectingInput.pending.blurred.field === LookUpOptions.JOBTASK &&
        projectingInput.entries.jobTask.length === 0 &&
        projectingInput.infos.jobTaskLoading === false &&
        projectingInput.ids["jobTask"] !== "Error"
      ) {
        const idJobTask = errorCheck({
          columns: jobTaskController.createColumns(),
          entries: projectingInput.entries.jobTask,
          rawInput: projectingInput.pending.blurred.inputValue,
          valueId: "",
        });
        if (idJobTask === null) {
          projectingInput.pending.blurred.onChangeFn({
            id: "Error",
            description: null,
          });
          projectingInput.infos.memoryLastJobTaskId = "";
          projectingInput.ids.jobTask = "Error";
          projectingInput.entries.jobTask = [];
          handleFocusField(setFocus, "hidden");
          handleFocusField(setFocus, LookUpOptions.JOBTASK);
          validateProjectingInput(
            handleResetPending<T>({ projectingInput, setValue })
          );
        }
      }
      // Check for activity-field
      if (
        projectingInput.pending.blurred !== null &&
        projectingInput.pending.blurred.field ===
          LookUpOptions.JOBPLANNINGLINE &&
        projectingInput.entries.activity.length > 0 &&
        projectingInput.entries.activity[0] &&
        (projectingInput.entries.activity[0].cells as any).skells === false
      ) {
        setFocus("hidden");
        const newProjectingInput = await handlePendingActivity<T>({
          projectingInput,
          setValue,
        });
        validateProjectingInput(newProjectingInput);
        handleFocusField(setFocus, LookUpOptions.JOBPLANNINGLINE, 25);
      } else if (
        projectingInput.pending.blurred !== null &&
        projectingInput.pending.blurred.field ===
          LookUpOptions.JOBPLANNINGLINE &&
        projectingInput.entries.activity[0] &&
        (projectingInput.entries.activity[0].cells as any).skells === true
      ) {
        blockOnPendingCallback && blockOnPendingCallback(true);
      } else if (
        projectingInput.pending.blurred !== null &&
        projectingInput.pending.blurred.field ===
          LookUpOptions.JOBPLANNINGLINE &&
        projectingInput.entries.activity.length === 0 &&
        projectingInput.ids["activity"] !== "Error"
      ) {
        const idActivity = errorCheck({
          columns: activityController.createColumns(),
          entries: projectingInput.entries.activity,
          rawInput: projectingInput.pending.blurred.inputValue,
          valueId: "",
        });
        if (idActivity === null) {
          blockOnPendingCallback && blockOnPendingCallback(false);
          projectingInput.infos.activityIdBeforeError =
            projectingInput.infos.memoryActivityId;
          projectingInput.pending.blurred.onChangeFn({
            id: "Error",
            description: null,
            tntModelLine: {
              id: "",
              description: null,
              billable: false,
              nonBillableReason: NonBillableReasonOptions.NONE,
            },
          });
          projectingInput.ids.activity = "Error";
          projectingInput.entries.activity = [];
          handleFocusField(setFocus, "hidden");
          handleFocusField(setFocus, "jobPlanningLine", 250);
          validateProjectingInput(
            handleResetPending<T>({ projectingInput, setValue })
          );
        }
      }
    }
  };

  useEffect(() => {
    // If an id has "Error" and pendingApiCalls is 0,
    // then set focus to field after Api call is finished
    if (
      projectingInput.ids[mappedFieldName] === "Error" &&
      projectingInput.pending.pendingApiCalls === 0
    ) {
      handleFocusField(setFocus, fieldName);
    }
  }, [projectingInput.ids, projectingInput.pending.pendingApiCalls]);

  /**
   * Handle to call API with debounced input value
   * @returns void
   */
  const handleCallWithDebounce = async () => {
    projectingInput.pending.pendingApiCalls += 1;

    if (mappedFieldName !== "activity") {
      if (
        projectingInput.ids[mappedFieldName].length === 0 ||
        projectingInput.ids[mappedFieldName] === "Error"
      ) {
        const newFieldData = await apiCallLookUp<IDatagridRow<T>[], T>({
          orderBy: projectingInput.orderBy[mappedFieldName],
          filterColumns: handleGetFieldController({
            mappedFieldName,
          }).getDbColumnSearch(debouncedValue),
          expand: handleGetFieldController({ mappedFieldName })
            .expand as Expand<T>,
          top: projectingInput.odataFilter.topInitial,
          filterPreviousField:
            allowDeleteCustomer && fieldName === "job"
              ? null
              : handleGetFieldFilter<T>({
                  fieldName: mappedFieldName,
                  projectingInput,
                }),
          getTaskedList: filterIsActive,
          odataQuery: null,
          dispatch: dispatch,
          lookUpField: mappedFieldName as LookUpShortOptions,
          withCells: true,
        });

        setTimeout(() => {
          // Need timeout to trigger hook
          projectingInput.pending.pendingApiCalls -= 1;
        }, 10);
        handleNewEntries({ newEntries: newFieldData, key: mappedFieldName });
      } else {
        projectingInput.pending.pendingApiCalls -= 1;
      }
    } else {
      const check = await getActivityData({
        inputQuery: debouncedValue,
        filterValue: filterIsActive,
      });
      if (!check && projectingInput.pending.isLoading === true) {
        if (errors["jobPlanningLine"] !== undefined) {
          setFocus("jobPlanningLine");
        } else {
          setFocus("hidden");
        }
      }
      projectingInput.pending.pendingApiCalls -= 1;
    }
  };

  const handleNewEntries = ({
    newEntries,
    key,
    combineEntries,
  }: {
    newEntries: IDatagridRow<T>[];
    key: LookUpActivityOptions | LookUpShortOptions;
    combineEntries?: CombineEntries<T>;
  }) => {
    if (combineEntries === undefined) {
      validateProjectingInput({
        ...projectingInput,
        entries: {
          ...projectingInput.entries,
          [key]: newEntries,
        },
        odataFilter: {
          ...projectingInput.odataFilter,
          loadMoreDown:
            newEntries.length >= projectingInput.odataFilter.loadMoreCount,
        },
      });
      projectingInput.entries[key] = newEntries;
      projectingInput.odataFilter.loadMoreDown =
        newEntries.length >= projectingInput.odataFilter.loadMoreCount;
    } else {
      const { oldEntries, direction, loadMoreUp, loadMoreDown } =
        combineEntries;
      if (direction === "UP") {
        validateProjectingInput({
          ...projectingInput,
          entries: {
            ...projectingInput.entries,
            [key]: [...newEntries, ...oldEntries],
          },
          odataFilter: {
            ...projectingInput.odataFilter,
            loadMoreUp,
            loadMoreDown,
          },
        });
        projectingInput.entries[key] = [...newEntries, ...oldEntries];
        projectingInput.odataFilter.loadMoreUp = loadMoreUp;
        projectingInput.odataFilter.loadMoreDown = loadMoreDown;
      } else if (direction === "DOWN") {
        validateProjectingInput({
          ...projectingInput,
          entries: {
            ...projectingInput.entries,
            [key]: [...oldEntries, ...newEntries],
          },
          odataFilter: {
            ...projectingInput.odataFilter,
            loadMoreUp,
            loadMoreDown,
          },
        });
        projectingInput.entries[key] = [...oldEntries, ...newEntries];
        projectingInput.odataFilter.loadMoreUp = loadMoreUp;
        projectingInput.odataFilter.loadMoreDown = loadMoreDown;
      }
      projectingInput.infos.openWithSkeletons = false;
    }
    // Check if this is fine here!!!
    projectingInput.pending.field = null;
  };

  /**
   * Function to create skeletons in Datagrid as long as API Calls are pending
   */
  const handleSkeletons = (specifiedRowsCount = true) => {
    // Only render skeletons if LookUp-Field has no error
    if (errors[fieldName] === undefined) {
      showSkeletonsOnOpen<T>({
        key: mappedFieldName as LookUpActivityOptions,
        projectingInput,
        hasValue: projectingInput.ids[mappedFieldName].length > 0,
        handleNewEntries: handleNewEntries,
        lastRowLength: specifiedRowsCount
          ? projectingInput.entries[mappedFieldName].length > 0
            ? projectingInput.entries[mappedFieldName].length
            : 6
          : 0,
      });
    }
  };

  /**
   * Function to handle Odata query to fetch entries above and below current one
   */
  const handleSelection = (filterValue: boolean) => {
    handleFieldWithSelection<T>({
      field: mappedFieldName as LookUpShortOptions,
      debouncedValue,
      projectingInput,
      filterIsActive: filterValue,
      dispatch,
      handleNewEntries: handleNewEntries,
      allowDeleteCustomer: allowDeleteCustomer && fieldName === "job",
    });
  };

  /**
   * Function to provide saved entries for corresponding LookUp field if
   * user aborts dialog. This is needed when user changed selection or searched
   * for entries.
   */
  const handleAbortDialog = () => {
    const idCheck = projectingInput.ids[mappedFieldName];
    const memoryEntry = projectingInput.memoryEntries[mappedFieldName];
    const memoryId = memoryEntry ? memoryEntry.id : "";

    if (memoryEntry && idCheck !== memoryId) {
      projectingInput.ids[mappedFieldName] = memoryId;
      projectingInput.entries[mappedFieldName] = [memoryEntry];
      handleNewEntries({ newEntries: [memoryEntry], key: mappedFieldName });
    }
  };

  const asyncPendingJobTask = async () => {
    validateProjectingInput(
      await handlePendingJobTask<T>({
        projectingInput,
        validateObjectCopy: projectingInputPreState,
        setFocus,
        filterIsActive,
        mappingObject,
        setValue,
        dispatch,
      })
    );
  };

  /**
   * Function is reacting to onChange event
   * @param value Value from Datagrid selection
   * @param onChange onChange event callback
   */
  const handelOnChangeField = async (
    value: OnChangeEvent<T>,
    onChange: (...event: any[]) => void
  ) => {
    if (value.selectedValue !== null) {
      switch (fieldName) {
        case "customer":
          {
            const customer = await customerSelected<T>({
              customerData: value.selectedValue as any,
              onChange,
              validateObject: projectingInputPreState,
              setFocus,
              filterIsActive,
              allowDeleteCustomer,
              mappingObject,
              dispatch,
            });
            validateProjectingInput(customer);
          }
          break;
        case "job":
          {
            const job = await jobSelected<T>({
              jobData: value.selectedValue as any,
              onChange,
              validateObject: projectingInputPreState,
              setFocus,
              allowDeleteCustomer,
              mappingObject,
              dispatch,
            });
            validateProjectingInput(job);
          }
          break;
        case "jobTask":
          {
            const jobTask = await jobTaskSelected<T>({
              jobTaskData: value.selectedValue as any,
              onChange,
              validateObject: projectingInputPreState,
              setFocus,
              filterIsActive,
              mappingObject,
              dispatch,
              blockClosingCallback,
              validateProjectingInput,
            });
            validateProjectingInput(jobTask);
          }
          break;
        case "jobPlanningLine":
          if (onActivityChanged) {
            const activity = await activitySelected<T>({
              activityData: value.selectedValue as any,
              onChange,
              validateObject: projectingInputPreState,
              onActivityChanged,
              mappingObject,
            });
            validateProjectingInput(activity);
          }
          break;
      }
      // Set debounceValue to empty string, that same input can trigger API call again
      setValue("");
    } else if (value.rawInput === null) {
      // Dependency deletion
      switch (fieldName) {
        case "customer": {
          const customer = await customerSelected<T>({
            customerData: null,
            onChange,
            validateObject: projectingInputPreState,
            setFocus,
            filterIsActive,
            allowDeleteCustomer,
          });
          validateProjectingInput(customer);
          break;
        }
        case "job": {
          const job = await jobSelected<T>({
            jobData: null,
            onChange,
            validateObject: projectingInputPreState,
            setFocus,
            allowDeleteCustomer,
          });
          validateProjectingInput(job);
          break;
        }
        case "jobTask": {
          const jobTask = await jobTaskSelected<T>({
            jobTaskData: null,
            onChange,
            validateObject: projectingInputPreState,
            setFocus,
            filterIsActive,
          });
          validateProjectingInput(jobTask);
          break;
        }
        case "jobPlanningLine":
          if (onActivityChanged) {
            const activity = await activitySelected<T>({
              activityData: null,
              onChange,
              onActivityChanged,
              validateObject: projectingInputPreState,
            });
            validateProjectingInput(activity);
          }
          break;
      }
    } else if (value.rawInput.length === 0) {
      switch (fieldName) {
        case "customer":
          {
            const customer = await customerSelected<T>({
              customerData: null,
              onChange,
              validateObject: projectingInputPreState,
              setFocus,
              filterIsActive,
              allowDeleteCustomer,
            });
            validateProjectingInput(customer);
          }
          break;
        case "job": {
          const job = await jobSelected<T>({
            jobData: null,
            onChange,
            validateObject: projectingInputPreState,
            setFocus,
            allowDeleteCustomer,
          });
          validateProjectingInput(job);
          break;
        }
        case "jobTask": {
          if (projectingInput.ids.jobTask.length > 0) {
            projectingInput.infos.blockMemoryLastJobTaskId = true;
          }
          const jobTask = await jobTaskSelected<T>({
            jobTaskData: null,
            onChange,
            validateObject: projectingInputPreState,
            setFocus,
            filterIsActive,
          });
          validateProjectingInput(jobTask);
          break;
        }
        case "jobPlanningLine":
          if (onActivityChanged) {
            projectingInput.infos.checkForSameTnTModelId = "";
            const activity = await activitySelected<T>({
              activityData: null,
              onChange,
              onActivityChanged,
              validateObject: projectingInputPreState,
            });
            validateProjectingInput(activity);
          }
          break;
      }
      projectingInput.infos.fromController = fieldName;
      setValue(value.rawInput ? value.rawInput : "");
      if (fieldName !== "jobPlanningLine") {
        getFieldData<T>({
          inputQuery: "",
          infScrolling: {
            direction: "NONE",
            defaultQuery: {} as QueryOptions<T>,
          },
          field: fieldName as unknown as LookUpShortOptions,
          projectingInput,
          filterIsActive,
          dispatch,
          handleNewEntries: handleNewEntries,
          allowDeleteCustomer,
        });
        projectingInput.odataFilter.loadMoreUp = false;
        projectingInput.odataFilter.loadMoreDown = true;
      } else {
        getActivityData({ inputQuery: "", filterValue: filterIsActive });
        setActivityDeletedByUser(true);
      }
    } else {
      handleSkeletons();
      if (projectingInput.ids[mappedFieldName] !== "Error") {
        projectingInput.ids[mappedFieldName] = "";
      }
      // Save ID to focus correct on same input as value before
      if (
        mappingObject.job !== null &&
        fieldName === "job" &&
        projectingInput.ids.job !== "Error"
      ) {
        projectingInput.infos.memoryLastJobId = mappingObject.job.id;
      }
      if (
        mappingObject.jobTask !== null &&
        fieldName === "jobTask" &&
        projectingInput.ids.jobTask !== "Error"
      ) {
        projectingInput.infos.memoryLastJobTaskId = mappingObject.jobTask.id;
      }
      // Set pending information
      projectingInput.pending.field = fieldName;
      // Debounce userInput
      projectingInput.infos.fromController = fieldName;
      setValue(value.rawInput ? value.rawInput : "");
    }
  };

  /**
   * Function to provide icon at endAdornment in LookUp
   * @param input Current user input
   * @param theme MUI (DYCE) theme
   * @returns JSX.Element (Icon) | null
   */
  const handleAdditionInfoIcon = (
    input: string,
    theme: DyceTheme
  ): JSX.Element | null => {
    const tntModelLine =
      projectingInput.memoryEntries.activity !== null &&
      (
        projectingInput.memoryEntries.activity
          .cells as unknown as PopulatedJobPlanningLine
      ).tntModelLine;

    if (tntModelLine) {
      const isBillable = tntModelLine.billable;

      if (
        input.length > 0 &&
        projectingInput.memoryIds.activity &&
        projectingInput.memoryIds.activity.length > 0 &&
        isBillable
      ) {
        return <MonetizationOnOutlinedIcon style={{ color: teal["A400"] }} />;
      } else if (
        input.length > 0 &&
        projectingInput.memoryIds.activity &&
        projectingInput.memoryIds.activity.length > 0 &&
        !isBillable
      ) {
        return <MonetizationOffOutlinedIcon fill={theme.palette.grey[500]} />;
      }
      return null;
    } else {
      return null;
    }
  };

  /**
   * Function to set new focus on Enter key, handled when mapping field
   * is already filled
   * @param field String with field name e.g. 'customer' | 'job' ...
   * @param doFocusItself Boolean to focus itself or another field
   */
  const handleFocusOnHotkey = ({
    field,
    doFocusItself,
    pending,
  }: {
    field: string;
    doFocusItself?: boolean;
    pending?: boolean;
  }) => {
    switch (field) {
      case LookUpOptions.CUSTOMER:
        if (doFocusItself) {
          setFocus("hidden");
        } else {
          handleFocusField(setFocus, LookUpOptions.JOB, 0);
        }
        break;
      case LookUpOptions.JOB:
        if (doFocusItself) {
          setFocus("hidden");
        } else {
          handleFocusField(setFocus, LookUpOptions.JOBTASK, 0);
        }
        break;
      case LookUpOptions.JOBTASK: {
        projectingInput.infos.jobTaskBlurred = true;
        if (doFocusItself) {
          setFocus("hidden");
        } else {
          handleFocusField(setFocus, LookUpOptions.JOBPLANNINGLINE, 0);
        }
        break;
      }
      case LookUpOptions.JOBPLANNINGLINE: {
        if (doFocusItself) {
          setFocus("hidden");
        } else {
          pending ? setFocus("hidden") : setFocus(focusFieldName);
        }
        break;
      }
    }
  };

  /**
   * Function to proof if field has changed by user
   * @param value LookUp field value {rawInput, selectedValue}
   * @returns True, if field has changed (dirty)
   */
  const handleIsFieldDirty = (value: OnChangeEvent<T>): boolean => {
    const memoryEntry = projectingInput.memoryEntries[mappedFieldName];
    // Get memory text value
    const fieldTextValue: string | null =
      memoryEntry && memoryEntry.cells
        ? mappedFieldName === "customer"
          ? (memoryEntry.cells as any).name
          : (memoryEntry.cells as any).description
        : null;
    // Get memory id
    const memoryId = memoryEntry ? memoryEntry.id : null;
    if (
      memoryId &&
      fieldTextValue &&
      value.rawInput === fieldTextValue &&
      value.selectedValue &&
      value.selectedValue.id === memoryId
    ) {
      // Field had value and has not changed
      return false;
    } else if (
      memoryId === null &&
      typeof value.rawInput === "string" &&
      value.rawInput.length === 0 &&
      value.selectedValue &&
      value.selectedValue.id === null
    ) {
      // Field was empty and is still empty
      return false;
    } else {
      return true;
    }
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, onBlur, ref } }) => (
        <LookUp<T, LookUpOptions>
          name={fieldName as LookUpOptions}
          refHook={ref}
          displayKey={
            fieldName !== "customer" ? "description" : ("name" as any)
          }
          autoFocus={autoFocus}
          lookupSize={lookupSize}
          onFilter={
            (workWithFilter === TasksFilterStatus.OPTIONAL ||
              workWithFilter === TasksFilterStatus.REQUIRED) &&
            onChangeFilter
              ? (value) => {
                  onChangeFilter(value);
                  if (fieldName !== "jobPlanningLine") {
                    handleSelection(value);
                    handleSkeletons(false);
                  } else {
                    getActivityData({
                      inputQuery: debouncedValue,
                      filterValue: value,
                    });
                    handleSkeletons(false);
                  }
                }
              : undefined
          }
          tabIndex={tabIndex}
          onInputBlur={onInputBlur}
          focusOnHotkey={handleFocusOnHotkey}
          fieldsPerWrapper={fieldsPerWrapper}
          isRequired={fieldName !== "jobPlanningLine"}
          pendingData={
            projectingInput.pending.isLoading &&
            projectingInput.pending.blurred !== null &&
            projectingInput.pending.blurred.field === fieldName
          }
          deleteDependency={projectingInput.dependency.deleteFields}
          selectedId={projectingInput.ids[mappedFieldName]}
          defaultSorting={
            fieldName === "customer"
              ? ["name", "ASC"]
              : fieldName === "job"
              ? ["no", "ASC"]
              : fieldName === "jobTask"
              ? [
                  projectingInput.orderBy.jobTask.split(" ")[0] === "job/no"
                    ? "jobNo"
                    : "description",
                  "ASC",
                ]
              : ["description", "ASC"]
          }
          getColumnProps={(key) =>
            handleGetFieldController({ mappedFieldName }).createColumns()[key]
          }
          onGridSort={(name, key, direction) => {
            return validateProjectingInput(
              handleOnGridSort({
                name,
                key: key as LookUpActivityOptions,
                direction,
                projectingInput: projectingInputPreState,
              })
            );
          }}
          disableDatagrid={projectingInput.pending.pendingApiCalls > 0}
          onChange={(value) => handelOnChangeField(value, onChange)}
          onBlur={(value) => {
            if (projectingInput.infos.customerIsConfirming === false) {
              if (
                projectingInput.pending.field &&
                projectingInput.pending.field.length > 0 &&
                projectingInput.pending.field === fieldName
              ) {
                projectingInput.pending.isLoading = true;
                projectingInput.pending.blurred = {
                  field: fieldName,
                  inputValue: value.rawInput,
                  blurFn: onBlur,
                  onChangeFn: onChange,
                };
                return;
              }
              if (projectingInput.pending.pendingApiCalls === 0) {
                const filteredByStatus = () => {
                  if (fieldName === "jobTask") {
                    projectingInput.infos.jobTaskBlurred = true;
                    return value.selectedValue && value.selectedValue.id
                      ? projectingInput.entries.jobTask
                      : projectingInput.entries.jobTask.filter(
                          (entry: IDatagridRow<any>) =>
                            entry.cells.status === JobTaskStatusOptions.OPEN
                        );
                  } else if (fieldName === "jobPlanningLine") {
                    return value.selectedValue &&
                      value.selectedValue.id &&
                      (value.selectedValue as any).tntModelLine &&
                      (value.selectedValue as any).tntModelLine.id &&
                      (value.selectedValue as any).tntModelLine.id.length > 0
                      ? projectingInput.entries.activity
                      : projectingInput.entries.activity.filter(
                          (entry: IDatagridRow<any>) =>
                            entry.cells.jobTask?.status ===
                            JobTaskStatusOptions.OPEN
                        );
                  } else {
                    return projectingInput.entries[mappedFieldName];
                  }
                };
                if (handleIsFieldDirty(value)) {
                  // error handling: Check rawInput
                  const id = errorCheck({
                    columns: handleGetFieldController({
                      mappedFieldName,
                    }).createColumns(),
                    entries: filteredByStatus(),
                    rawInput: value.rawInput,
                    valueId: value.selectedValue ? value.selectedValue.id : "",
                  });
                  if (id === null) {
                    const handleOnChangeObject = () => {
                      switch (fieldName) {
                        case "customer":
                          return {
                            id: "Error",
                            name: null,
                            no: null,
                          };
                        case "job": {
                          projectingInput.infos.jobHadError = true;
                          return {
                            id: "Error",
                            description: null,
                            no: null,
                          };
                        }
                        case "jobTask":
                          return {
                            id: "Error",
                            description: null,
                          };
                        case "jobPlanningLine": {
                          projectingInput.infos.activityIdBeforeError =
                            projectingInput.infos.memoryActivityId;
                          return {
                            id: "Error",
                            description: null,
                            tntModelLine: {
                              id: "",
                              description: null,
                              billable: false,
                              nonBillableReason: NonBillableReasonOptions.NONE,
                            },
                          };
                        }
                        default:
                          return undefined;
                      }
                    };
                    onChange(handleOnChangeObject());
                    projectingInput.ids[mappedFieldName] = "Error";
                    projectingInput.entries[mappedFieldName] = [];
                  } else {
                    projectingInput.ids[mappedFieldName] = id;
                    setValue("");
                    onBlur();
                  }
                } else {
                  onBlur();
                }
              }
            }
          }}
          onDataGridFocus={() => {
            if (fieldName !== "jobPlanningLine") {
              setValue("");
              handleSelection(filterIsActive);
              handleSkeletons(false);
            } else {
              getActivityData({ inputQuery: "", filterValue: filterIsActive });
              handleSkeletons(false);
            }
          }}
          infiniteScroll={
            fieldName !== "jobPlanningLine"
              ? ({ direction }) => {
                  if (checkFurtherInfScrolling(direction, projectingInput)) {
                    const query = infScrollFieldHandler<T>({
                      direction,
                      debouncedValue,
                      projectingInput,
                      key: mappedFieldName as LookUpShortOptions,
                    });
                    // API Call
                    getFieldData<T>({
                      inputQuery: "",
                      infScrolling: {
                        direction,
                        defaultQuery: query,
                      },
                      field: mappedFieldName as LookUpShortOptions,
                      projectingInput,
                      filterIsActive,
                      dispatch,
                      handleNewEntries: handleNewEntries,
                      allowDeleteCustomer:
                        allowDeleteCustomer && fieldName === "job",
                    });
                  }
                }
              : undefined
          }
          infScrollProps={
            fieldName !== "jobPlanningLine"
              ? {
                  infScrollCanTriggerAt: loadAbove,
                  triggerAtBottom: triggerAtBottom,
                  defaultColumnKey: "no",
                  loadMoreCount: projectingInput.odataFilter.loadMoreCount,
                  controller: handleGetFieldController({ mappedFieldName }),
                  canLoadMoreUp: projectingInput.odataFilter.loadMoreUp,
                  canLoadMoreDown: projectingInput.odataFilter.loadMoreDown,
                  openWithSkeletons: projectingInput.infos.openWithSkeletons,
                }
              : {
                  defaultColumnKey: "no",
                }
          }
          searchQueryDialog={(value) => {
            if (value.length === 0) {
              projectingInput.ids[mappedFieldName] =
                projectingInput.memoryIds[mappedFieldName];
              projectingInput.infos.fromController = fieldName;
              setValue(value);
              if (fieldName !== "jobPlanningLine") {
                getFieldData<T>({
                  inputQuery: "",
                  infScrolling: {
                    direction: "NONE",
                    defaultQuery: {} as QueryOptions<T>,
                  },
                  field: mappedFieldName as LookUpShortOptions,
                  projectingInput,
                  filterIsActive,
                  dispatch,
                  handleNewEntries: handleNewEntries,
                  allowDeleteCustomer:
                    allowDeleteCustomer && fieldName === "job",
                });
                projectingInput.odataFilter.loadMoreUp = false;
                projectingInput.odataFilter.loadMoreDown = true;
              } else {
                getActivityData({
                  inputQuery: "",
                  filterValue: filterIsActive,
                });
              }
            } else {
              handleSkeletons();
              projectingInput.ids[mappedFieldName] = "";
              // Set pending information
              if (fieldName === "jobTask") {
                projectingInput.pending.field = fieldName;
              }
              // Debounce userInput
              projectingInput.infos.fromController = fieldName;
              setValue(value);
            }
          }}
          abortFromDialog={handleAbortDialog}
          values={projectingInput.entries[mappedFieldName]}
          blockClosingCallback={blockClosingCallback}
          disabled={
            (mappingObject.status &&
              mappingObject.status !== StatusOptions.OPEN) ||
            (fieldName === "customer" &&
              projectingInput.pending.blurred !== null &&
              (projectingInput.pending.blurred.field === LookUpOptions.JOB ||
                projectingInput.pending.blurred.field ===
                  LookUpOptions.JOBTASK)) ||
            (fieldName === "job" &&
              projectingInput.pending.blurred !== null &&
              (projectingInput.pending.blurred.field ===
                LookUpOptions.CUSTOMER ||
                projectingInput.pending.blurred.field ===
                  LookUpOptions.JOBTASK)) ||
            (fieldName === "jobTask" &&
              projectingInput.pending.blurred !== null &&
              (projectingInput.pending.blurred.field ===
                LookUpOptions.CUSTOMER ||
                projectingInput.pending.blurred.field === LookUpOptions.JOB ||
                projectingInput.pending.blurred.field ===
                  LookUpOptions.JOBPLANNINGLINE)) ||
            (fieldName === "jobPlanningLine" &&
              projectingInput.odataFilter.filterForJob === null) ||
            (fieldName === "jobPlanningLine" &&
              projectingInput.pending.blurred !== null &&
              (projectingInput.pending.blurred.field ===
                LookUpOptions.CUSTOMER ||
                projectingInput.pending.blurred.field === LookUpOptions.JOB ||
                projectingInput.pending.blurred.field ===
                  LookUpOptions.JOBTASK)) ||
            pending
          }
          additionInfoIcon={
            fieldName === "jobPlanningLine"
              ? (input, theme) => handleAdditionInfoIcon(input, theme)
              : undefined
          }
          rowFactoryFilter={
            fieldName !== "customer" && fieldName !== "job"
              ? (ids, values) =>
                  handleRowFactoryFilter({ ids, values, fieldName })
              : undefined
          }
          findEntryByValueFilter={
            fieldName !== "customer" && fieldName !== "job"
              ? (values, id, inputValue) =>
                  handelFindEntryByValueFilter<T>({
                    values,
                    id,
                    fieldName,
                    inputValue,
                  })
              : undefined
          }
          onEmptyRowsRenderer={() => {
            if (
              workWithFilter === TasksFilterStatus.OPTIONAL &&
              filterIsActive
            ) {
              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    alignItems: "center",
                    gap: ".5rem",
                    marginTop: ".5rem",
                  }}
                >
                  {t("components.lookup.entries.workWithFilter.optional")}
                  <TaskAltOutlinedIcon color="info" />
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  {t("components.lookup.noEntries")}
                </div>
              );
            }
          }}
          darkMode={darkMode}
          hideExpandable={hideExpandable}
          label={errors[fieldName] ? "" : translationObj[fieldName]}
        />
      )}
      defaultValue={mappingObject[fieldName]}
    />
  );
}
