import { FunctionComponent, useEffect, useState } from "react";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { Tooltip } from "../tooltip/tooltip";
import { useStaticContent } from "../static-provider/static-provider";
import {
  ILookUpFilterProps,
  LookUpFilter,
} from "../buttons/look-up-filter/look-up-filter";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Popper as MUIPopper,
  Fade,
  Paper,
  ClickAwayListener,
  Box,
  Typography,
  IconButton,
  PopperPlacementType,
  useTheme,
  SxProps,
} from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    popperWidth: {
      zIndex: 10,
    },
    popperHeight: {
      minHeight: 291,
    },
    headerStyle: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      margin: 2,
    },
    buttonContainer: {
      paddingTop: "1px",
    },
    button: {
      width: 40,
      height: 40,
    },
    typography: {
      marginTop: 2,
      padding: theme.spacing(1),
    },
    popper: {
      '&[data-popper-placement*="bottom"] $arrow': {
        top: 0,
        left: 0,
        width: "3em",
        "&::before": {
          borderWidth: "0 1em 1em 1em",
          borderColor: `transparent transparent ${theme.palette.background.default} transparent`,
        },
      },
      '&[data-popper-placement*="top"] $arrow': {
        bottom: 0,
        left: 0,
        width: "3em",
        "&::before": {
          borderWidth: "1em 1em 0 1em",
          borderColor: `${theme.palette.background.default} transparent transparent transparent`,
        },
      },
      '&[data-popper-placement*="right"] $arrow': {
        left: 0,
        height: "3em",
        "&::before": {
          borderWidth: "1em 1em 1em 0",
          borderColor: `transparent ${theme.palette.background.default} transparent transparent`,
        },
      },
      '&[data-popper-placement*="left"] $arrow': {
        right: 0,
        height: "3em",
        "&::before": {
          borderWidth: "1em 0 1em 1em",
          borderColor: `transparent transparent transparent ${theme.palette.background.default}`,
        },
      },
    },
    arrow: {
      position: "absolute",
      width: "3em",
      height: "3em",
      zIndex: -1,
      "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: "100%",
        height: "100%",
        boxShadow: "2px 2px 6px #0000001a",
        backgroundColor: theme.palette.background.default,
        transform: "rotate(45deg)",
      },
    },
  })
);

export interface IPopperProps {
  /**
   * If popper is open
   */
  open: boolean;
  /**
   * Element to anchor the popper to
   */
  anchorEl: any;
  /**
   * Title of the content. Will not be rendered if not provided
   */
  header?: {
    /**
     * Caption for the header
     */
    caption: string;
    /**
     * Individual styles for the header
     */
    sx?: SxProps<any & DyceTheme>;
  };
  /**
   * Popper placement, defaults to bottom-start. See {@link https://material-ui.com/api/popper/}
   */
  placement?: PopperPlacementType;
  /**
   * Width of the popper in px; Overrides popperWidth (minWidth)
   */
  popperWidth?: number | string;
  /**
   * Min-height of the popper in px
   */
  minHeight?: number | string;
  /**
   * Max width of the popper in px
   */
  maxWidth?: number | string;
  /**
   * Switch if Paper should elevate
   */
  dropShadow?: boolean;
  /**
   * Switch if Popper should prevent overflow
   * @default true
   */
  altBoundaryPreventOverflow?: boolean;
  /**
   * Add arrow to popper with offset
   * @default false
   */
  arrow?: boolean;
  /**
   * Overwrite z-index of popper
   */
  overwriteZIndex?: number;
  /**
   * Force min-height as height
   * @default false
   */
  forceMinHeightAsHeight?: boolean | number;
  /**
   * Props for header buttons
   */
  headerButtons: {
    /**
     * Props for filter Popper @type {ILookUpFilterProps}
     */
    filter: ILookUpFilterProps;
    /**
     * Props for expandable Popper
     */
    expand?: {
      /**
       * Gets called if Popper expand button is clicked
       */
      onExpand?: () => void;
      /**
       * Provide Icon for onExpand section
       */
      muiSvgIcon?: JSX.Element;
      /**
       * Label to show in Tooltip
       */
      tooltipLabel?: string;
    };
    /**
     * Individual render function for header button
     */
    individual: {
      /**
       * Callback used to render individual button
       * @returns JSX.Element
       */
      renderIndividual?: () => JSX.Element;
    };
  };
  /**
   * Gets called if popper is closed
   */
  onClose: () => void;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const Popper: FunctionComponent<IPopperProps> = ({
  open,
  anchorEl,
  header,
  popperWidth = 500,
  minHeight,
  maxWidth,
  overwriteZIndex,
  dropShadow = false,
  placement = "bottom-start",
  forceMinHeightAsHeight = false,
  altBoundaryPreventOverflow = true,
  arrow = false,
  headerButtons,
  onClose,
  children,
}) => {
  const classes = useStyles();
  const { docuLinks } = useStaticContent();
  const theme = useTheme<DyceTheme>();
  const { expand, filter, individual } = headerButtons;

  // UseStates
  const [position, setPosition] = useState<PopperPlacementType>("bottom-start");
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);

  // UseEffects
  useEffect(() => {
    const handleScroll = () => {
      const element = document.querySelector('[role="tooltip"]');
      if (element) {
        const pos = element.getAttribute("data-popper-placement");
        if (pos) {
          setPosition(pos as PopperPlacementType);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <>
      {anchorEl !== null && (
        <ClickAwayListener
          mouseEvent="onMouseUp"
          touchEvent="onTouchEnd"
          onClickAway={onClose}
        >
          <MUIPopper
            className={classes.popper}
            open={open}
            anchorEl={anchorEl}
            placement={placement}
            transition
            style={{
              zIndex: overwriteZIndex
                ? overwriteZIndex
                : position.includes("bottom")
                ? 20
                : 1000,
              boxShadow: arrow ? "2px 2px 6px #0000001a" : "none",
            }}
            disablePortal={true}
            modifiers={[
              {
                name: "flip",
                enabled: true,
                options: {
                  altBoundary: true,
                  rootBoundary: "viewport",
                  padding: 8,
                },
              },
              {
                name: "preventOverflow",
                enabled: true,
                options: {
                  elementContext: "popper",
                  altAxis: true,
                  altBoundary: altBoundaryPreventOverflow,
                  tether: false,
                  rootBoundary: "viewport",
                  padding: 16,
                },
              },
              {
                name: "offset",
                enabled: arrow,
                options: {
                  offset: [0, 10],
                },
              },
              {
                name: "arrow",
                enabled: arrow,
                options: {
                  element: arrowRef,
                  padding: 10,
                },
              },
            ]}
          >
            {({ TransitionProps }) => {
              return (
                <Fade {...TransitionProps} timeout={350}>
                  <div>
                    {arrow && (
                      <span className={classes.arrow} ref={setArrowRef} />
                    )}
                    <Paper
                      elevation={dropShadow && !arrow ? 6 : 0}
                      style={{
                        height: forceMinHeightAsHeight
                          ? typeof forceMinHeightAsHeight === "number"
                            ? forceMinHeightAsHeight
                            : minHeight
                          : "100%",
                        minHeight: minHeight,
                        minWidth: maxWidth ? maxWidth : popperWidth,
                        maxWidth: maxWidth,
                        background: arrow
                          ? theme.palette.background.default
                          : undefined,
                      }}
                      square={!arrow}
                      data-testid="lookup-paper"
                    >
                      {header && (
                        <Box className={classes.headerStyle} sx={header.sx}>
                          <Typography className={classes.typography}>
                            {header.caption}
                          </Typography>
                          <div className={classes.buttonContainer}>
                            {filter.onFilter && <LookUpFilter {...filter} />}
                            {expand && expand.onExpand && (
                              <Tooltip
                                label={
                                  expand.tooltipLabel ? expand.tooltipLabel : ""
                                }
                                urlPath={
                                  docuLinks.timetracking.reusableComponents
                                    .lookUp.headerButton
                                }
                                position="right"
                              >
                                <IconButton
                                  tabIndex={-1}
                                  size="small"
                                  className={classes.button}
                                  onClick={expand.onExpand}
                                >
                                  {expand.muiSvgIcon && expand.muiSvgIcon}
                                </IconButton>
                              </Tooltip>
                            )}
                            {individual.renderIndividual &&
                              individual.renderIndividual()}
                          </div>
                        </Box>
                      )}

                      {children}
                    </Paper>
                  </div>
                </Fade>
              );
            }}
          </MUIPopper>
        </ClickAwayListener>
      )}
    </>
  );
};
