import {
  DevOpsData,
  DevOpsWorkItem,
  ProjectConfiguration,
  ProjectingTree,
  SubscriptionResponse,
  WorkItemEffortType,
} from "@dyce/foreign-api";
import { DesignOptions, InvalidUser } from "@dyce/interfaces";
import {
  AadUser,
  Activity,
  AdminSettings,
  CalendarEntries,
  Client,
  CurrentUser,
  Customer,
  Instance,
  InstanceStatus,
  JobViewEntry,
  PeriodTime,
  PopulatedJob,
  PopulatedJobPlanningLine,
  PopulatedJobTask,
  PopulatedRole,
  PopulatedUser,
  RecordTemplate,
  RecordTimeRec,
  Resource,
  ServerStatusResponse,
  Statistics,
  StatusOptions,
  Stopwatch,
  TasksFilterStatus,
  TemplateCategory,
  TntModelLine,
  User,
  WorkItemType,
  WorkTimes,
} from "@dyce/tnt-api";

/**
 * Types for all slices
 */
export type AdminStateSlice = {
  requests: any[];
  response: Record<string, unknown>;
  sync: {
    serverStatus: ServerStatusResponse | Record<string, any>;
  };
  users: {
    aadUsers: Record<string, AadUser>;
    dyceUsers: Record<string, PopulatedUser>;
    userRoles: Record<string, PopulatedRole>;
    userById: Record<string, unknown>;
    currentUser: User | Record<string, unknown>;
    resources: Resource[];
  };
  clients: {
    allClients: Record<string, Client>;
    loadingClients: boolean;
  };
  settings: AdminSettings;
};

export type JobStateSlice = {
  customers: Record<string, Customer>;
  jobs: Record<string, PopulatedJob>;
  jobTasks: Record<string, PopulatedJobTask>;
  jobPlanningLines: Record<string, PopulatedJobPlanningLine>;
  tntModelLines: Record<string, Activity>;
};

export type ResourceStateSlice = {
  entities: Resource[];
  currentUser: Record<string, ResourcePlanning>;
  currentResource: Resource | null;
};

export type SettingsStateSlice = {
  isLoading: boolean;
  timerec: {
    dashboard: {
      showDaysWithoutCapacity: boolean;
    };
    templates: {
      openCreatedRecording: {
        tasks: boolean;
        templates: boolean;
      };
    };
    tasks: {
      groupedBy: TaskSettings;
      showWithoutDate: boolean;
      rule: TasksFilterStatus;
      filter: {
        searchKeyWord: string;
        startDate: string;
        paginationSkip: number;
      };
    };
  };
  ui: {
    darkMode: boolean;
    sideDrawerLeft: boolean;
    timeTrackingToolDesign: DesignOptions;
  };
  general: {
    language: string;
    locale: string;
  };
};

export type SetupLanguages = "en" | "de";

export type UIStateSlice = {
  previousLanguage: string | null;
  userMenu: {
    visible: boolean;
    anchor: "left" | "right";
  };
  drawerLeft: OpenOptions;
  drawerRight: OpenOptions;
  drawerTop: OpenOptions & {
    canConfirm: boolean;
    confirmed: boolean;
    fakeAccounts: number;
  };
  dialogAadUsers: OpenOptions;
  createUserDialog: OpenOptions;
  updateUserRolesDialog: OpenOptions;
  currentStep: number;
  randomNumber: any;
};

type OpenOptions = {
  open: boolean;
};

export type TemplateStateSlice = {
  entities: Record<string, RecordTemplate>;
  requests: unknown[];
  templates: RecordTemplate[];
  categories: Record<string, TemplateCategory>;
  errors: unknown;
  isLoadingEntries: boolean;
};

export type TimerecStateSlice = {
  entries: Record<string, RecordTimeRec>;
  requests: unknown[];
  periodTimes: PeriodTime[];
  periodStatistics: Statistics | null;
  loaded: string;
  incompleteEntries: {
    count: number;
    entries: Record<string, RecordTimeRec>;
  };
  stopwatch: Stopwatch | null;
  stopwatchLoading: boolean;
  latestCreatedId: string | null;
  latestCreatedEndTime: string | null;
  openEditor: boolean;
  errors: unknown;
  entriesLookUp: unknown;
  sorting: any;
  errorOnUpdate: {
    id: string;
    status: StatusOptions;
    newStatus: StatusOptions | null;
    unknownIssue: boolean;
    deleted: boolean;
  } | null;
  // FLAGS
  isLoadingEntries: boolean;
  addDateToSelector: string | null;
  // loaded: string;
};

export type TntStateSlice = {
  requests: string[];
  customers: Record<string, Customer>;
  jobs: Record<string, PopulatedJob>;
  jobTasks: Record<string, PopulatedJobTask>;
  jobPlanningLines: Record<string, PopulatedJobPlanningLine>;
  tntModelLines: Record<string, Activity>;
};

export type UserStateSlice = {
  instance: string | null;
  company: string | null;
  instanceValidated: boolean | null;
  instances: Instance[];
  currentUser: CurrentUser;
  userById: Record<string, unknown>;
  query: string;
  isLoading: boolean;
  isLoadingUser: boolean | null;
  isValidatingInstance: boolean;
  invalidGrant: boolean;
  invalidGrantUserInfo: InvalidUser | null;
};

export type NetworkStateSlice = {
  requests: Record<string, Request>;
  status: InstanceStatus | null;
  slowNetwork: boolean;
  allowNetworkWatcher: boolean;
};

export type OnboardingStateSlice = {
  entities: Record<string, never>;
  requests: any[];
  errors: Record<string, never>;
  randomNumber: number | null;
  instanceState: number | null;
};

export type CalendarStateSlice = {
  entries: CalendarEntries[] | null;
  requests: any[];
};

export type DevOpsStateSlice = {
  areaTree: {
    area: ProjectConfiguration | null;
    iteration: ProjectConfiguration | null;
  };
  mappingTree: ProjectingTree;
  collections: Record<string, any>;
  workItemData: DevOpsWorkItem | null;
  lastSavedWorkItemId: number;
  lastLoadedWorkItemId: number;
  workItemEffort: WorkItemEffortType;
  requests: any[];
  instance: string | undefined;
  company: string | undefined;
  devOpsData: DevOpsData;
  instanceValidated: boolean | null;
  isLoading: boolean;
  isLoadingMandant: boolean;
  isValidatingInstance: boolean;
  workItemDyce: WorkItemType | null;
  subscriptionList: null | SubscriptionResponse[];
  darkMode: boolean;
};

export type JiraStateSlice = {
  requests: any[];
  collections: Record<string, any>;
  issueData: Record<string, any> | null;
  lastSavedIssueId: number;
  lastLoadedIssueId: number;
  instance: string | undefined;
  company: string | undefined;
  companyId: string | null;
  instanceValidated: boolean | null;
  isLoading: boolean;
  isLoadingMandant: boolean;
  isValidatingInstance: boolean;
  jiraData: Record<string, any>;
  issueDyce: WorkItemType | null;
  projectType: "software" | "service_desk";
};

export type NotificationStateSlice = {
  current: any[];
  past: any[];
};

export type TaskStateSlice = {
  requests: any[];
  entries: Record<string, JobViewEntry>;
  totalCount: number;
  initialResult: {
    loaded: boolean;
    hasValues: boolean;
  };
  pagination: {
    top: number;
    skip: number;
  };
};

/**
 * RootState type
 */
export type RootState = {
  admin: AdminStateSlice;
  calendar: CalendarStateSlice;
  network: NetworkStateSlice;
  notification: NotificationStateSlice;
  onboarding: OnboardingStateSlice;
  resources: ResourceStateSlice;
  settings: SettingsStateSlice;
  task: TaskStateSlice;
  templates: TemplateStateSlice;
  timerec: TimerecStateSlice;
  tnt: TntStateSlice;
  ui: UIStateSlice;
  user: UserStateSlice;
  devOps: DevOpsStateSlice;
  jira: JiraStateSlice;
};
/**
 * Typings used in Slices
 */
type Request = {
  start: number;
};

export type ResourcePlanning = {
  calendar?: Holidays;
  capacity?: WorkTimes;
  recurringHolidays?: string[];
};

export type Holidays = string[];

export enum TaskSettings {
  CUSTOMER = "Customer",
  JOB = "Job",
  STARTDATE = "StartDate",
  ENDDATE = "EndDate",
}
