import { t } from "i18next";

/**
 * Customized Errorhandler to find Errors in onSubmit Data fired back in onChange()
 * @param dataArray Yup onSubmit Data as array
 * @param t Translation function from i18next
 * @returns Object with @see {ErrorOption} infos ready for @see {FieldError} || undefiend
 */
export const checkForErrors = (
  dataArray: any[]
): {
  ref: string;
  errorOption: {
    type: string;
    message: string;
  };
}[] => {
  const errorArray = [];
  // LookUp error handling => Error (Input !== Id)
  if (dataArray.some((x) => x.some((y: any) => y && y.id === "Error"))) {
    const projectsArray: any[] = dataArray.filter(
      (x) =>
        (x[0] === "customer" ||
          x[0] === "job" ||
          x[0] === "jobTask" ||
          x[0] === "jobPlanningLine" ||
          x[0] === "nonBillableReason") &&
        x[1] &&
        x[1].id === "Error"
    );

    const errorMessages = {
      customer: t("timerec.dailyRecording.validate.fieldName.customer"),
      job: t("timerec.dailyRecording.validate.fieldName.job"),
      jobTask: t("timerec.dailyRecording.validate.fieldName.jobTask"),
      jpl: t("timerec.dailyRecording.validate.fieldName.jobPlanningLine"),
      nbr: t("timerec.dailyRecording.validate.fieldName.nonBillableReason"),
    };

    if (projectsArray.some((x) => x[0] === "customer")) {
      errorArray.push({
        ref: "customer",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.error", {
            error: errorMessages.customer,
          }),
        },
      });
    }
    if (projectsArray.some((x) => x[0] === "job")) {
      errorArray.push({
        ref: "job",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.error", {
            error: errorMessages.job,
          }),
        },
      });
    }
    if (projectsArray.some((x) => x[0] === "jobTask")) {
      errorArray.push({
        ref: "jobTask",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.error", {
            error: errorMessages.jobTask,
          }),
        },
      });
    }
    if (projectsArray.some((x) => x[0] === "jobPlanningLine")) {
      errorArray.push({
        ref: "jobPlanningLine",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.error", {
            error: errorMessages.jpl,
          }),
        },
      });
    }
    if (projectsArray.some((x) => x[0] === "nonBillableReason")) {
      errorArray.push({
        ref: "nonBillableReason",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.error", {
            error: errorMessages.nbr,
          }),
        },
      });
    }
  }
  // TimeFields error handling => Error (Input !== Number)
  if (dataArray.some((x) => x.some((y: any) => y === "Error"))) {
    const timeareaArray: any[] = dataArray.filter(
      (x) =>
        (x[0] === "duration" ||
          x[0] === "break" ||
          x[0] === "start" ||
          x[0] === "durationBillable" ||
          x[0] === "end") &&
        x[1] === "Error"
    );

    if (timeareaArray.some((x) => x[0] === "duration")) {
      errorArray.push({
        ref: "duration",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.duration"),
        },
      });
    }
    if (timeareaArray.some((x) => x[0] === "break")) {
      errorArray.push({
        ref: "break",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.break"),
        },
      });
    }

    if (timeareaArray.some((x) => x[0] === "start")) {
      errorArray.push({
        ref: "start",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.startValid"),
        },
      });
    }

    if (timeareaArray.some((x) => x[0] === "end")) {
      errorArray.push({
        ref: "end",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.endValid"),
        },
      });
    }

    if (timeareaArray.some((x) => x[0] === "durationBillable")) {
      errorArray.push({
        ref: "durationBillable",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.durationBillable"),
        },
      });
    }
  }

  // Date Errors
  if (dataArray.some((x) => x.some((y: any) => y === "Error"))) {
    const dateareaArray: any[] = dataArray.filter(
      (x) => x[0] === "date" && x[1] === "Error"
    );

    if (dateareaArray.some((x) => x[0] === "date")) {
      errorArray.push({
        ref: "date",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.dateValid"),
        },
      });
    }
  }

  return errorArray;
};
