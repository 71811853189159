import { FunctionComponent, useState } from "react";
// Helper
import clsx from "clsx";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { Tooltip } from "../tooltip/tooltip";
import { SetupLanguages } from "@dyce/slices";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Box,
  Chip,
  Paper,
  Typography,
  useTheme,
  LinearProgress,
  Fab,
  Collapse,
  // Popper,
} from "@mui/material";
import { ArrowUpwardOutlined } from "@mui/icons-material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    subHeader: {
      position: "fixed",
      top: 64,
      left: 0,
      right: 0,
      zIndex: theme.zIndex.mobileStepper,
      [theme.breakpoints.down("sm")]: {
        top: 56,
      },
    },
    paper: {
      paddingTop: ".625rem",
      paddingBottom: "0.5rem",
      height: "6rem",
    },
    headerBar: {
      paddingTop: "0.25rem",
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
    },
    headerContainer: {
      display: "inherit",
    },
    headerText: {
      display: "flex",
      flexDirection: "column",
      minWidth: 230,
      whiteSpace: "nowrap",
    },
    infoChip: {
      marginLeft: "1rem",
      marginTop: "0.125rem",
    },
    childrenContainer: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      marginRight: 32,
      marginTop: 6,
      justifyContent: "flex-end",
    },
    childrenMobileButton: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      height: "70px",
      alignItems: "center",
    },
    childrenContainerButtonIcon: {
      height: "25px",
      transition: "transform 0.2s",
    },
    childrenContainerMobile: {
      display: "flex",
      flexDirection: "row",
      paddingRight: "1rem",
      paddingTop: "1.5rem",
      justifyContent: "center",
      height: "112px",
    },
    collapsedContainer: {
      position: "fixed",
      top: 112 + 57,
      zIndex: theme.zIndex.mobileStepper,
      width: "100%",
    },
    progressBar: {
      marginTop: "-4px",
    },
  })
);

interface ISubHeaderProps {
  /**
   * Title to show in sub-header
   * @default ''
   */
  title: string | JSX.Element;
  /**
   * Subtitle to show in sub-header
   * @default ''
   */
  subtitle: string | JSX.Element;
  /**
   * Label shown in tooltip
   */
  tooltipLabel: string;
  /**
   * URL path for documentation in tooltip
   */
  tooltipUrlPath: Record<SetupLanguages, string>;
  /**
   * Use depending margin for expandable drawer left
   * @default true
   */
  honorMargin?: boolean;
  /**
   * If true, loading animation <LinearProgress /> will be shown at bottom of sub-header
   * @default false
   */
  isLoading?: boolean;
  /**
   * If true, sub-header will be shown with mobile styles
   * @default false
   */
  childrenMobile?: boolean;
  /**
   * If true, text will be shown with mobile styles
   * @default false
   */
  textMobile?: boolean;
  /**
   * Children as React.ReactNode
   */
  children?: React.ReactNode;
}

export const SubHeader: FunctionComponent<ISubHeaderProps> = ({
  title = "",
  subtitle = "",
  tooltipLabel,
  tooltipUrlPath,
  honorMargin = true,
  isLoading = false,
  childrenMobile = false,
  textMobile = false,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme<DyceTheme>();

  // States
  const [open, setOpen] = useState<boolean>(false);

  return (
    <>
      <div className={classes.subHeader}>
        <div>
          <Paper
            className={classes.paper}
            style={
              honorMargin
                ? {
                    paddingLeft:
                      theme.palette.propsDyce.subHeader.marginForDrawer,
                    transition:
                      theme.palette.propsDyce.drawerTransitions.transCommon,
                  }
                : { paddingLeft: 25 }
            }
            square
          >
            <Box className={clsx(classes.headerBar, "mui-fixed")}>
              <div className={classes.headerText}>
                <div className={classes.headerContainer}>
                  <Typography
                    variant="h6"
                    gutterBottom={!textMobile}
                    data-testid="header-title"
                  >
                    {title}
                  </Typography>
                  <Tooltip
                    label={tooltipLabel}
                    urlPath={tooltipUrlPath}
                    position="right-start"
                  >
                    <Chip className={classes.infoChip} size="small" label="?" />
                  </Tooltip>
                </div>
                <Typography
                  variant="subtitle1"
                  gutterBottom={!textMobile}
                  sx={
                    textMobile
                      ? {
                          whiteSpace: "break-spaces",
                        }
                      : {}
                  }
                >
                  {subtitle}
                </Typography>
              </div>
              {childrenMobile ? (
                <div className={classes.childrenMobileButton}>
                  <Fab
                    onClick={() => setOpen(!open)}
                    size="large"
                    sx={{
                      marginRight: 2,
                      height: 48,
                      width: 48,
                      boxShadow:
                        "0px 3px 5px -1px rgb(0 0 0 / 10%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
                    }}
                    color="primary"
                  >
                    <div
                      className={classes.childrenContainerButtonIcon}
                      style={{
                        transform: !open ? "rotate(-180deg)" : "rotate(0deg)",
                      }}
                    >
                      <ArrowUpwardOutlined />
                    </div>
                  </Fab>
                </div>
              ) : (
                <div className={classes.childrenContainer}>{children}</div>
              )}
            </Box>
          </Paper>
        </div>
        <div className={classes.progressBar}>
          {isLoading && (
            <LinearProgress data-testid="progress-bar" color="primary" />
          )}
        </div>
      </div>
      <Collapse
        className={classes.collapsedContainer}
        in={open}
        timeout={{
          enter: 200,
          exit: 200,
          appear: 200,
        }}
      >
        <Paper square className={classes.childrenContainerMobile}>
          {children}
        </Paper>
      </Collapse>
    </>
  );
};
