import React, { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
// DYCE-Lib
import { JobViewEntry } from "@dyce/tnt-api";
import { LoadMore, TaskList as TaskListLib } from "@dyce/ui";
import {
  TaskSettings,
  clearLatestCreatedId,
  selectLocaleHyphen,
  selectOpenCreatedRecording,
  setOpenCreatedRecording,
  useAppDispatch,
  useAppSelector,
} from "@dyce/slices";
import {
  handleDeleteRecord,
  handleSaveTimerecording,
  validateTemplateObject,
} from "@dyce/utils";

type IncompleteTimesStyles = {
  recordsContainer: string;
  listContainer: string;
};

interface ITaskListProps {
  styles: IncompleteTimesStyles;
  groupedBy: TaskSettings;
  tasks: [string, JobViewEntry[]][];
  pagination: {
    count: number;
    loadCount: number;
    loadMore: () => void;
  };
}

export const TaskList: FunctionComponent<ITaskListProps> = ({
  styles,
  groupedBy,
  pagination,
  tasks,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // Selectors
  const locale = useAppSelector(selectLocaleHyphen);
  const openInTimerecordings = useAppSelector(selectOpenCreatedRecording);

  // Handler
  const handleGroupName = (name: string) => {
    if (groupedBy === TaskSettings.CUSTOMER) {
      if (name.split(" - ").length > 1) {
        return name.split(" - ")[1];
      } else {
        return t("timerec.tasks.list.header.noCustomer");
      }
    } else {
      if (name === "Not Defined") {
        switch (groupedBy) {
          case TaskSettings.JOB:
            return t("timerec.tasks.list.header.noJob");
          case TaskSettings.STARTDATE:
            return t("timerec.tasks.list.header.noStartDate");
          case TaskSettings.ENDDATE:
            return t("timerec.tasks.list.header.noEndDate");
          default:
            return name + " - Expand ENUM";
        }
      } else {
        return name;
      }
    }
  };

  return (
    <div className={styles.recordsContainer} id="taskEntries">
      {tasks.map(([name, tasks]) => (
        <div className={styles.listContainer} key={name} id={`#${name}`}>
          <TaskListLib
            tasks={tasks}
            locale={locale}
            groupedBy={groupedBy}
            headerText={{
              name: handleGroupName(name),
              id: "",
              resource: "",
            }}
            saveAsTimeRecordProps={{
              onSave: (entry) => handleSaveTimerecording(entry, dispatch),
              clearLatestCreatedId: () => dispatch(clearLatestCreatedId()),
              openCreatedRecording: (value) =>
                dispatch(
                  setOpenCreatedRecording({
                    templates: openInTimerecordings.templates,
                    tasks: value,
                  })
                ),
              deleteRecord: (id) => handleDeleteRecord(id, dispatch),
              openInTimerecordings: openInTimerecordings.tasks,
              validateTemplateObject: async (template) =>
                await validateTemplateObject(template, dispatch),
            }}
          />
        </div>
      ))}
      <LoadMore
        count={pagination.count}
        loadCount={pagination.loadCount}
        loadMore={pagination.loadMore}
        listLength={tasks.length}
      />
    </div>
  );
};
