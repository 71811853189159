import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { useUpdateEffect } from "@dyce/hooks";
import { SettingsStateSlice } from "@dyce/slices";
import { Tooltip } from "../../tooltip/tooltip";
import { useStaticContent } from "../../static-provider/static-provider";
import { NoTask } from "@dyce/ui-assets";
import { TasksFilterStatus } from "@dyce/tnt-api";
// MUI
import { IconButton, useTheme } from "@mui/material";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { DyceTheme } from "@dyce/theme";

/**
 * Props for filter Popper
 */
export interface ILookUpFilterProps {
  /**
   * Gets called if Popper filter button is clicked
   */
  onFilter?: (value: boolean) => void;
  /**
   * If true, button is disabled
   * @default false
   */
  isDisabled?: boolean;
  /**
   * Optional icon to use in button with current state of filter information
   * @param value Current state of filter
   * @example JSX.Element (e.g. (value) => <FilterAltOutlinedIcon color={value ? 'green' : 'red'} />)
   * @returns JSX.Element
   */
  muiSvgIcon?: (value: boolean) => JSX.Element;
  /**
   * Label to show in Tooltip
   */
  tooltipLabel?: string;
  /**
   * Size of button
   * @default "small"
   */
  size?: "small" | "medium" | "large";
  /**
   * Callback to render custom component
   * @param param0 Callback props to handle states like label, isDisabled, ... in custom component
   * @returns JSX.Element
   */
  individualElement?: ({
    label,
    isDisabled,
    onInteraction,
  }: {
    /**
     * Dynamic label to use as text in custom component;
     * Value depends on state of filter
     */
    label: string;
    /**
     * If true, button is disabled
     */
    isDisabled: boolean;
    /**
     * Current state of filter
     */
    isActive: boolean;
    /**
     * Callback for individual element called on 'onChange' or 'onClick'
     * @returns void
     */
    onInteraction: () => void;
  }) => JSX.Element;
}

export const LookUpFilter: FunctionComponent<ILookUpFilterProps> = ({
  isDisabled = false,
  muiSvgIcon,
  onFilter,
  tooltipLabel,
  size = "small",
  individualElement,
}) => {
  const { docuLinks } = useStaticContent();
  const { t } = useTranslation();
  const theme = useTheme<DyceTheme>();

  // States
  const [workWithTasks, setWorkWithTasks] = useState<TasksFilterStatus>(
    TasksFilterStatus.NONE
  );
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    // Get look-up filter status from local storage
    const filterStatus = localStorage.getItem("lookUpFilter");
    if (workWithTasks && filterStatus) {
      setIsFilterActive(JSON.parse(filterStatus));
    } else if (workWithTasks && !filterStatus) {
      localStorage.setItem("lookUpFilter", JSON.stringify(false));
    }
    // Get user-settings from local storage
    const settings = localStorage.getItem("settings");
    if (settings) {
      const settingsObj: SettingsStateSlice = JSON.parse(settings);
      if (
        settingsObj.timerec &&
        settingsObj.timerec.tasks &&
        settingsObj.timerec.tasks.rule
      ) {
        setWorkWithTasks(settingsObj.timerec.tasks.rule);
      }
    }
  }, []);

  useUpdateEffect(() => {
    // Save filter status in local storage
    localStorage.setItem("lookUpFilter", JSON.stringify(isFilterActive));
  }, [isFilterActive]);

  // Handler
  const handleOnClick = () => {
    if (workWithTasks === TasksFilterStatus.OPTIONAL) {
      setIsFilterActive(!isFilterActive);
    }
    if (workWithTasks !== TasksFilterStatus.REQUIRED) {
      onFilter && onFilter(!isFilterActive);
    }
  };

  return (
    <>
      {workWithTasks !== TasksFilterStatus.NONE ? (
        <>
          {individualElement ? (
            individualElement({
              label:
                workWithTasks === TasksFilterStatus.REQUIRED
                  ? t("lib.ui.buttons.lookUpFilter.filterIsActive.required")
                  : isFilterActive
                  ? t("lib.ui.buttons.lookUpFilter.filterIsActive.true")
                  : t("lib.ui.buttons.lookUpFilter.filterIsActive.false"),
              isDisabled: workWithTasks === TasksFilterStatus.REQUIRED,
              isActive: isFilterActive,
              onInteraction: handleOnClick,
            })
          ) : (
            <Tooltip
              label={
                tooltipLabel
                  ? tooltipLabel
                  : workWithTasks === TasksFilterStatus.REQUIRED
                  ? t("lib.ui.buttons.lookUpFilter.filterIsActive.required")
                  : isFilterActive
                  ? t("lib.ui.buttons.lookUpFilter.filterIsActive.true")
                  : t("lib.ui.buttons.lookUpFilter.filterIsActive.false")
              }
              urlPath={
                docuLinks.timetracking.reusableComponents.lookUp.headerButton
              }
              position="right"
            >
              <span>
                <IconButton
                  tabIndex={-1}
                  size={size}
                  sx={size === "small" ? { w: 40, h: 40 } : {}}
                  onClick={handleOnClick}
                  disabled={
                    isDisabled || workWithTasks === TasksFilterStatus.REQUIRED
                  }
                >
                  {muiSvgIcon ? (
                    muiSvgIcon(isFilterActive)
                  ) : (
                    <>
                      {isFilterActive ? (
                        <TaskAltOutlinedIcon color="info" />
                      ) : (
                        <NoTask fill={theme.palette.secondary.main} />
                      )}
                    </>
                  )}
                </IconButton>
              </span>
            </Tooltip>
          )}
        </>
      ) : null}
    </>
  );
};
