import { Activity, Customer, PopulatedJob, PopulatedJobTask } from "../types";
import buildQuery, { QueryOptions } from "odata-query";
import { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

export class JobAssignmentsService {
  public baseUrl: string;
  private customerUrl: string;
  private jobUrl: string;
  private jobTaskUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = combineURLs(baseUrl, "/jobs");
    this.customerUrl = combineURLs(
      baseUrl,
      "/resourcejobassignments/customers"
    );
    this.jobUrl = combineURLs(baseUrl, "/resourcejobassignments/jobs");
    this.jobTaskUrl = combineURLs(baseUrl, "/resourcejobassignments/jobtasks");

    this.axiosInstance = instance;
  }

  /**
   * Gets all assigned Customers
   * @param query OData Query
   * @example
   * const client = new TnTClient(args).getJobAssignmentsService()
   * await client.getAll()
   */
  public async getCustomers(
    query?: Partial<QueryOptions<Customer>>
  ): Promise<Customer[]> {
    const url = `${this.customerUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Customer[]>(url);

    return response.data;
  }

  /**
   * Gets all assigned Jobs
   * @param query OData query
   * @example
   * const client = new TnTClient(args).getJobAssignmentsService()
   * await client.getJobs()
   */
  public async getJobs(
    query?: Partial<QueryOptions<PopulatedJob>>
  ): Promise<Partial<PopulatedJob>[]> {
    const url = `${this.jobUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Partial<PopulatedJob>[]>(url);

    return response.data;
  }

  /**
   * Gets all assigned Job Tasks
   * @param query OData query
   * @example
   * const client = new TnTClient(args).getJobAssignmentsService()
   * await client.getJobTasks()
   */
  public async getJobTasks(
    query?: Partial<QueryOptions<PopulatedJobTask>>
  ): Promise<Partial<PopulatedJobTask>[]> {
    const url = `${this.jobTaskUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Partial<PopulatedJobTask>[]>(
      url
    );

    return response.data;
  }

  /**
   * Gets all assigned Activites for only Job or Job && JobTask
   * @param jobId The ID of selected Job
   * @param jobTaskId The ID of selected JobTask if selected, else null
   * @param filter String for final filtering
   * @param orderBy String in which order entries should be result
   * @example
   * const client = new TnTClient(args).getJobAssignmentsService()
   * await client.getActivities()
   */
  public async getActivities(
    jobId: string,
    jobTaskId: string | null,
    filter: string,
    orderBy: string,
    assignedToResourceId: string
  ): Promise<Activity[]> {
    const setFilter: string = filter.length > 0 ? filter : "?";
    const url = `${this.baseUrl}(${jobId})/GetActivities${
      "(jobTaskId=" +
      jobTaskId +
      ",assignedToResourceId=" +
      assignedToResourceId +
      ")"
    }${setFilter}$expand=jobTask,jobPlanningLine,tntModelLine&$orderBy=${orderBy}`;

    const response = await this.axiosInstance.get<Activity[]>(url);

    return response.data;
  }
}
