// Helper
import { DateTime } from "luxon";
// Dyce-Lib
import { NonBillableReasonOptions, RecordTemplate } from "@dyce/tnt-api";
import { JobViewEntry } from "@dyce/tnt-api";
// MUI
import { Typography } from "@mui/material";

/**
 * Function to create final timerecording to safe
 * @param duration Value of input duration
 * @param task Task object
 * @returns Timerecording object with value
 */
export const handleMutateTaskToRecord = (
  task: JobViewEntry
): RecordTemplate => {
  const newRecord: RecordTemplate = {
    id: "",
    break: 0,
    duration: 0,
    description: "",
    start: null,
    end: null,
    customer: task.customer ? { ...task.customer } : null,
    job: task.job ? { ...task.job } : null,
    jobTask: task.jobTask
      ? {
          ...task.jobTask,
          job: task.job ? { ...task.job } : null,
          jobPlanningLine: task.jobPlanningLine
            ? { ...task.jobPlanningLine }
            : null,
        }
      : null,
    jobPlanningLine: task.jobPlanningLine ? { ...task.jobPlanningLine } : null,
    tntModelLine: null,
    durationBillable: 0,
    nonBillableReason: NonBillableReasonOptions.NONE,
    category: {
      id: "",
      name: "",
      resource: "",
    },
  };

  const record = { ...newRecord };

  return record;
};

/**
 * Function to format start- and end date in readable format
 * @param startDate Startdate of task
 * @param endDate Enddate of task
 * @param locale Locale of user
 * @returns JSX.Element with formatted date
 */
export const handleDisplayDate = (
  startDate: string | null | undefined,
  endDate: string | null | undefined,
  locale: string
) => {
  const finaleStartDate = startDate ? DateTime.fromISO(startDate) : null;
  const finaleEndDate = endDate ? DateTime.fromISO(endDate) : null;
  const currentYearStart = finaleStartDate
    ? finaleStartDate.hasSame(DateTime.now(), "year")
    : false;
  const currentYearEnd = finaleEndDate
    ? finaleEndDate.hasSame(DateTime.now(), "year")
    : false;

  if (finaleStartDate && finaleEndDate) {
    if (finaleStartDate.hasSame(finaleEndDate, "day")) {
      return (
        <Typography variant="body2">
          {finaleEndDate.setLocale(locale).toLocaleString({
            day: "2-digit",
            month: "numeric",
            year: currentYearEnd ? undefined : "2-digit",
          })}
        </Typography>
      );
    } else if (finaleStartDate.hasSame(finaleEndDate, "month")) {
      return (
        <Typography variant="body2">
          {finaleStartDate.setLocale(locale).toLocaleString({ day: "2-digit" })}
          {locale.includes("de") ? ". – " : " – "}
          {finaleEndDate.setLocale(locale).toLocaleString({
            day: "2-digit",
            month: "numeric",
            year: currentYearEnd ? undefined : "2-digit",
          })}
        </Typography>
      );
    } else if (finaleStartDate.hasSame(finaleEndDate, "year")) {
      return (
        <>
          <Typography variant="body2">
            {finaleStartDate
              .setLocale(locale)
              .toLocaleString({ day: "2-digit", month: "numeric" })}
            {" – "}
            {finaleEndDate.setLocale(locale).toLocaleString({
              day: "2-digit",
              month: "numeric",
              year: currentYearEnd ? undefined : "2-digit",
            })}
          </Typography>
        </>
      );
    } else if (!finaleStartDate.hasSame(finaleEndDate, "year")) {
      return (
        <>
          <Typography variant="body2">
            {finaleStartDate.setLocale(locale).toLocaleString({
              day: "2-digit",
              month: "numeric",
              year: "2-digit",
            })}
            {" – "}
            {finaleEndDate.setLocale(locale).toLocaleString({
              day: "2-digit",
              month: "numeric",
              year: "2-digit",
            })}
          </Typography>
        </>
      );
    } else {
      return (
        <>
          <Typography variant="body2">{finaleStartDate.toISODate()}</Typography>
          <Typography variant="body2">{finaleEndDate.toISODate()}</Typography>
        </>
      );
    }
  } else if (finaleStartDate && !finaleEndDate) {
    return (
      <>
        <Typography variant="body2">
          {finaleStartDate.setLocale(locale).toLocaleString({
            day: "2-digit",
            month: "numeric",
            year: currentYearStart ? undefined : "2-digit",
          })}
          {" –"}
        </Typography>
      </>
    );
  } else if (!finaleStartDate && finaleEndDate) {
    return (
      <>
        <Typography variant="body2">
          {"– "}
          {finaleEndDate.setLocale(locale).toLocaleString({
            day: "2-digit",
            month: "numeric",
            year: currentYearEnd ? undefined : "2-digit",
          })}
        </Typography>
      </>
    );
  } else {
    return (
      <>
        <Typography variant="body2">{"-"}</Typography>
        <Typography variant="body2">{"-"}</Typography>
      </>
    );
  }
};
