import { FunctionComponent, useEffect, useMemo, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { EventModel } from "@bryntum/calendar";
import { Controller, ErrorOption, useForm } from "react-hook-form";
import {
  getGroupName,
  handleErrors,
  handleInitialEventInput,
  handleValidation,
} from "./utils";
import { DateTime } from "luxon";
import { useHotkeys } from "react-hotkeys-hook";
import { CalendarRepeatType } from "@dyce/interfaces";
// Dyce-Lib
import { DialogSearchField } from "../../inputs/dialogSearchField/dialogSearchField";
import { DateFieldMoment } from "../../inputs/dateField/moment/dateField";
import { TimeFieldMoment } from "../../inputs/timeField/moment/timeField";
import { AutoComplete } from "../../inputs/autocomplete/autoComplete";
import { EventInput } from "../../types";
import { FormError } from "../../formError/formError";
import { useStaticContent } from "../../static-provider/static-provider";
// MUI
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: "1rem",
    },
    formContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
    },
    timeRangeContainer: {
      display: "flex",
      flexDirection: "row",
      gap: "1rem",
    },
    textfieldBackground: {
      backgroundColor: "white",
    },
    buttonContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      paddingTop: "1rem",
      gap: "1rem",
    },
    button: {
      width: 120,
    },
  })
);

interface IEventFormProps {
  /**
   * EventEntry infos
   */
  event: Partial<EventModel>;
  /**
   * Callback fired on submit
   * @returns void
   */
  onSubmit: () => void;
  /**
   * Callback fired on cancel
   * @returns void
   */
  onCancel: () => void;
  /**
   * Callback fired on delete
   * @returns void
   */
  onDelete: (id: string) => void;
}

export const EventForm: FunctionComponent<IEventFormProps> = ({
  event,
  onSubmit,
  onCancel,
  onDelete,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    platform: { modifiers },
  } = useStaticContent();

  // UseStates
  const [allDay, setAllDay] = useState<boolean>(
    event.allDay ? event.allDay : false
  );
  const [hasError, setHasError] = useState<boolean>(
    event.allDay ? event.allDay : false
  );
  const [eventInput, setEventInput] = useState<EventInput>(
    handleInitialEventInput(event)
  );
  const [timeSpanErrors, setTimeSpanErrors] = useState<
    {
      name: keyof EventInput;
      errorOption: ErrorOption;
    }[]
  >([]);
  const [timeSpan, setTimeSpan] = useState<number>(0);

  // React-Hook-Form
  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
    setFocus,
    watch,
  } = useForm<EventInput>({
    mode: "onBlur",
    defaultValues: eventInput,
  });

  // UseEffects
  useEffect(() => {
    console.log(event.id);

    const initialEventInput = handleInitialEventInput(event);
    setEventInput(initialEventInput);
    setAllDay(initialEventInput.allDay);
    // Save initial timeSpan (end - start)
    const timeSpan =
      DateTime.fromISO(initialEventInput.end.endDate).toMillis() -
      DateTime.fromISO(initialEventInput.start.startDate).toMillis();
    setTimeSpan(timeSpan / 1000 / 60); // Minutes
  }, [event]);

  useEffect(() => {
    if (timeSpanErrors.length > 0) {
      // Throw errors
      timeSpanErrors.forEach((error) => {
        setError(error.name, error.errorOption);
      });
      setHasError(true);
    } else {
      setHasError(false);
    }
  }, [timeSpanErrors]);

  useEffect(() => {
    const isAllDayChecked = watch("allDay");
    const startTime = watch("start.startTime");
    const endTime = watch("end.endTime");
    if (isAllDayChecked) {
      if (startTime === "Error" || endTime === "Error") {
        clearErrors("start.startTime");
        clearErrors("end.endTime");
      }
    } else {
      if (startTime === "Error") {
        setError("start.startTime", {
          type: "manual",
          message: t("lib.ui.calendar.event.eventForm.error.start.startTime"),
        });
      }
      if (endTime === "Error") {
        setError("end.endTime", {
          type: "manual",
          message: t("lib.ui.calendar.event.eventForm.error.end.endTime"),
        });
      }
    }
    setAllDay(isAllDayChecked);
  }, [watch("allDay")]);

  // Hotkeys
  useHotkeys(
    "esc",
    () => {
      onCancel();
    },
    {
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    }
  );

  useHotkeys(
    `${modifiers.modifierCtrl}+enter`,
    () => {
      validateInput(eventInput, true);
    },
    {
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"],
    }
  );

  // Handlers
  const validateInput = (data: EventInput, fromHotkey = false) => {
    console.log("validateInput");

    // Check for Error in data (wrong input from user)
    const validateErrors = handleErrors({
      formInput: data,
      withTimeFields: !allDay,
    });

    if (validateErrors.length > 0) {
      // Throw errors
      validateErrors.forEach((error) => {
        setError(error.name, error.errorOption);
      });
      setHasError(true);
      return;
    }

    // Validate Input
    const { validateInput, errors } = handleValidation({
      formInput: data,
      oldInput: eventInput,
      isAllDayChecked: allDay,
      timeSpan: timeSpan,
    });

    // Save new formInput
    setEventInput(validateInput);
    // Check for Error in validation (e.g. endTime < startTime)
    if (errors) {
      setTimeSpanErrors(errors);
    } else {
      // No Errors
      setTimeSpanErrors([]);
      // Save new TimeSpan
      const newTimeSpan =
        DateTime.fromISO(validateInput.end.endTime).toMillis() -
        DateTime.fromISO(validateInput.start.startTime).toMillis();
      setTimeSpan(newTimeSpan / 1000 / 60); // Minutes

      if (fromHotkey) {
        // onSubmit(validateInput);
        onSubmit();
      }
    }
  };

  return (
    <div className={classes.container}>
      <form onBlur={handleSubmit((val) => validateInput(val as EventInput))}>
        <FormError
          errors={Object.values(errors) ? (Object.values(errors) as any) : []}
          addMarginBottom
        />
        <div className={classes.formContainer}>
          <Controller
            name="title"
            control={control}
            defaultValue={eventInput.title}
            render={({ field: { onChange, ref } }) => (
              <DialogSearchField
                refElement={ref}
                onChange={onChange}
                value={eventInput.title}
                onFocus={(e) => setTimeout(() => e.target.select(), 0)}
                overrideStyling
                style={classes.textfieldBackground}
                onClearField={() => setFocus("title")}
                label={t("lib.ui.calendar.event.eventForm.title")}
              />
            )}
          />
          <div>
            <Controller
              name="allDay"
              control={control}
              defaultValue={eventInput.allDay}
              render={({ field: { onChange, onBlur } }) => (
                <FormControlLabel
                  control={<Checkbox onClick={() => onBlur()} />}
                  value={eventInput.allDay}
                  onChange={(e: any) => onChange(e.target.checked)}
                  onBlur={onBlur}
                  onClick={() => onBlur()}
                  label={t("lib.ui.calendar.event.eventForm.allDay")}
                />
              )}
            />
          </div>
          <div className={classes.timeRangeContainer}>
            <Controller
              name="start.startDate"
              control={control}
              defaultValue={eventInput.start.startDate}
              render={({ field: { onChange, onBlur } }) => (
                <DateFieldMoment
                  label={
                    errors["start"]?.startDate
                      ? ""
                      : t("lib.ui.calendar.event.eventForm.startDate")
                  }
                  variant="outlined"
                  isEnglish={false}
                  value={eventInput.start.startDate}
                  onChange={onChange}
                  onBlur={onBlur}
                  style={classes.textfieldBackground}
                  fullWidth
                />
              )}
            />
            <Controller
              name="start.startTime"
              control={control}
              defaultValue={eventInput.start.startTime}
              render={({ field: { onChange, onBlur } }) => (
                <div
                  style={{
                    width: "100%",
                    display: !allDay ? "flex" : "none",
                  }}
                >
                  <TimeFieldMoment
                    label={
                      errors["start"]?.startTime
                        ? ""
                        : t("lib.ui.calendar.event.eventForm.startTime")
                    }
                    variant="outlined"
                    value={eventInput.start.startTime}
                    onChange={onChange}
                    onBlur={onBlur}
                    style={classes.textfieldBackground}
                    fullWidth
                    required
                  />
                </div>
              )}
            />
          </div>
          <div className={classes.timeRangeContainer}>
            <Controller
              name="end.endDate"
              control={control}
              defaultValue={eventInput.end.endDate}
              render={({ field: { onChange, onBlur } }) => (
                <DateFieldMoment
                  label={
                    errors["end"]?.endDate
                      ? ""
                      : t("lib.ui.calendar.event.eventForm.endDate")
                  }
                  variant="outlined"
                  isEnglish={false}
                  value={eventInput.end.endDate}
                  onChange={onChange}
                  onBlur={onBlur}
                  style={classes.textfieldBackground}
                  fullWidth
                />
              )}
            />
            <Controller
              name="end.endTime"
              control={control}
              defaultValue={eventInput.end.endTime}
              render={({ field: { onChange, onBlur } }) => (
                <div
                  style={{
                    width: "100%",
                    display: !allDay ? "flex" : "none",
                  }}
                >
                  <TimeFieldMoment
                    label={
                      errors["end"]?.endTime
                        ? ""
                        : t("lib.ui.calendar.event.eventForm.endTime")
                    }
                    variant="outlined"
                    value={eventInput.end.endTime}
                    onChange={onChange}
                    onBlur={onBlur}
                    style={classes.textfieldBackground}
                    fullWidth
                    required
                  />
                </div>
              )}
            />
          </div>
          <Controller
            name="repeat"
            control={control}
            defaultValue={eventInput.repeat}
            render={({ field: { onChange, onBlur } }) => (
              <AutoComplete
                label={
                  errors["repeat"]
                    ? ""
                    : t("lib.ui.calendar.event.eventForm.repeat")
                }
                value={eventInput.repeat.recurrenceCombo}
                values={[
                  CalendarRepeatType.NONE,
                  CalendarRepeatType.DAILY,
                  CalendarRepeatType.WEEKLY,
                  CalendarRepeatType.MONTHLY,
                  CalendarRepeatType.YEARLY,
                ]}
                onChange={(val) =>
                  onChange({
                    ...eventInput.repeat,
                    recurrenceCombo: val,
                  })
                }
                onBlur={onBlur}
                getTitle={getGroupName}
                style={classes.textfieldBackground}
                width="100%"
              />
            )}
          />
        </div>
      </form>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="contained"
          color="inherit"
          onClick={() => onCancel()}
          fullWidth
        >
          {t("lib.ui.calendar.event.eventForm.abort")}
        </Button>
        {eventInput.id && (
          <Button
            className={classes.button}
            variant="contained"
            color="secondary"
            onClick={() => onDelete(eventInput.id!)}
            fullWidth
          >
            {t("lib.ui.calendar.event.eventForm.delete")}
          </Button>
        )}
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => onSubmit()}
          fullWidth
          disabled={hasError}
        >
          {t("lib.ui.calendar.event.eventForm.save")}
        </Button>
      </div>
    </div>
  );
};
