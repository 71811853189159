import { useEffect, useState } from "react";
// Dyce-Lib
import { DesignOptions, ToolStyle } from "@dyce/interfaces";
// Designs
import { default as dynamicDesign } from "./helpers/designModels/dynamic.json";
import { default as allDesign } from "./helpers/designModels/all.json";
import { default as compactDesign } from "./helpers/designModels/compact.json";
import { default as simpleDesign } from "./helpers/designModels/simple.json";
import { default as mobileSimpleDesign } from "./helpers/designModels/mobile-simple.json";

/**
 * Hook to define selected design for time-tracking-tool.
 * @returns JSON design model to render in selected design
 */
export const useTimeTrackingToolDesign = ({
  style,
}: {
  style: DesignOptions | string;
}): ToolStyle => {
  // States
  const [design, setDesign] = useState<ToolStyle>(allDesign as ToolStyle);

  useEffect(() => {
    switch (style) {
      case DesignOptions.DYNAMIC:
        setDesign(dynamicDesign as ToolStyle);
        break;
      case DesignOptions.ALL:
        setDesign(allDesign as ToolStyle);
        break;
      case DesignOptions.COMPACT:
        setDesign(compactDesign as ToolStyle);
        break;
      case DesignOptions.SIMPLE:
        setDesign(simpleDesign as ToolStyle);
        break;
      case DesignOptions.MOBILE_SIMPLE:
        setDesign(mobileSimpleDesign as ToolStyle);
        break;
      default:
        setDesign(allDesign as ToolStyle);
        break;
    }
  }, [style]);

  return design;
};
