import { useEffect, useRef } from "react";

/**
 * Hook to return callbacks after first render
 * @param callback Function to call back if condition is fulfilled
 * @param dependencies useEffect dependency array
 */
export const useUpdateEffect = (callback: () => void, dependencies: any[]) => {
  const firstRenderRef = useRef(true);

  useEffect(() => {
    if (firstRenderRef.current) {
      firstRenderRef.current = false;
      return;
    }
    return callback();
  }, dependencies);
};
