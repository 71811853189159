import { PopulatedRole, Resource } from "@dyce/tnt-api";
import { DateTime } from "luxon";
import { MappedRoles } from "./CreateClient";

export const handleRoleMapping = (roles: PopulatedRole[]): MappedRoles[] => {
  const createRoles: MappedRoles[] = [];
  roles.forEach((role) => {
    createRoles.push({
      id: role.id,
      value: role.description,
    });
  });

  return createRoles;
};

export const handleResourceMapping = (resources: Resource[]): string[] => {
  const createResources: string[] = [];
  resources.forEach((res) => {
    createResources.push(res.name);
  });

  return createResources;
};

export const handleExpirationDate = (date: string | undefined): string => {
  if (date) {
    const parsedDate = DateTime.fromISO(date);
    const warnOneMonth = parsedDate.minus({ months: 1 });

    if (parsedDate <= DateTime.now()) {
      return "red";
    } else if (DateTime.now() >= warnOneMonth) {
      return "darkorange";
    } else {
      return "";
    }
  } else {
    return "";
  }
};
