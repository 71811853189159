// Dyce-Lib
import {
  RecordTimeRec,
  RecordTemplate,
  ForeignSource,
  Instance,
  PopulatedJob,
} from "@dyce/tnt-api";
import { dyceStore } from "@dyce/slices";

/**
 * Function to find "search query" for URL to open below || above
 * current Time-entry per hotkey
 * @param direction Which key was pressed (PageUp === "up" || PageDown === "down")
 * @param id Id of current selected record entry
 * @param itemsArrayInfos @see {getItemsArrayInfos}
 * @param date stringDate from current selected Day
 * @param records All timerecordings for current Day
 * @returns Finale searchQuery as string or null
 */
export const handleUrlSearchQuery = (
  direction: string,
  id: string,
  itemsArrayInfos: [string, number][] | undefined,
  date: string,
  records: RecordTimeRec[] | RecordTemplate[]
): string | null => {
  const currentIndex: number = records.findIndex((x: any) => x.id === id);
  if (currentIndex === -1) {
    return null;
  }

  if (itemsArrayInfos !== undefined) {
    if (direction === "up") {
      if (currentIndex > 0) {
        return `?editRecord=true&index=${currentIndex - 1}#${date}`;
      } else {
        // Jump in day after current (+1) && end of array
        const dayBeforeIndex =
          itemsArrayInfos.findIndex(([x]) => x === date) - 1;
        if (dayBeforeIndex === -1) {
          return `#${date}`;
        }

        let dayBefore: any = [];
        for (let i = dayBeforeIndex; i >= 0; i--) {
          if (itemsArrayInfos[i][1] > 0) {
            dayBefore = itemsArrayInfos[i];
            break;
          } else {
            dayBefore = [];
          }
        }
        if (dayBefore.length > 0) {
          const higherArrayEnd: number = dayBefore[1] - 1;
          return `?editRecord=true&index=${higherArrayEnd}#${dayBefore[0]}`;
        } else {
          // If last day of week => stay there
          return `#${date}`;
        }
      }
    } else {
      if (records.length - 1 > currentIndex) {
        return `?editRecord=true&index=${currentIndex + 1}#${date}`;
      } else {
        // Jump in day before current (-1) && end of array
        const dayBeforIndex =
          itemsArrayInfos.findIndex(([x]) => x === date) + 1;

        let dayBefore: any = [];
        for (let i = dayBeforIndex; i < itemsArrayInfos.length; i++) {
          if (itemsArrayInfos[i][1] > 0) {
            dayBefore = itemsArrayInfos[i];
            break;
          } else {
            dayBefore = [];
          }
        }
        if (dayBefore.length > 0) {
          return `?editRecord=true&index=${0}#${dayBefore[0]}`;
        } else {
          // If first day of week => stay there
          return `#${date}`;
        }
      }
    }
  } else {
    return null;
  }
};

/**
 * Function to create link to bc and open in new tab
 * @param selectedJob Job from current timerecording
 */
export const handleBcLink = (selectedJob: PopulatedJob | null) => {
  const instance: string | null = dyceStore.getState().user.instance;
  const company: string | null = dyceStore.getState().user.company;
  const instances: Instance[] = dyceStore.getState().user.instances;

  if (instance && company) {
    const foundInstance = instances.find((ini) => ini.name === instance);
    if (foundInstance) {
      const tenantId = foundInstance.tenantId;
      const domain = "https://businesscentral.dynamics.com";

      if (selectedJob && selectedJob.no) {
        // Link to BC with job => show 'jobcart'
        // Sample:
        // https://businesscentral.dynamics.com/1d6dbeb4-ba36-4a89-bd8e-605d154a3b93/Sandbox-Ort/?page=88&filter='No.' is 'JOB00030'&company=CRONUS DE
        const jobNo = selectedJob.no;
        const url = `${domain}/${tenantId}/${instance}/?page=88&filter='No.' is '${jobNo}'&company=${company}`;
        window.open(url, "_blank", "noreferrer");
      } else {
        // Link to BC without job
        // Sample:
        // https://businesscentral.dynamics.com/1d6dbeb4-ba36-4a89-bd8e-605d154a3b93/Sandbox-Ort/?page=89&company=CRONUS DE
        const url = `${domain}/${tenantId}/${instance}/?page=89&company=${company}`;
        window.open(url, "_blank", "noreferrer");
      }
    }
  }
};

/**
 * Function to create url link to foreign extension
 * @param record RecordTimeRec obj with foreign infos
 */
export const handleExtensionLink = (record: RecordTimeRec | null) => {
  if (record) {
    let subDomain = "";
    let domain = "";
    let folder = "";
    let targetItem = "";

    if (record.workItem) {
      const source = record.workItem.source;
      const organization = record.workItem.organization;
      const project = record.workItem.project;
      const id = record.workItem.id;

      if (source && organization && project && id) {
        switch (source) {
          case ForeignSource.AZUREDEVOPS:
            // Sample:
            // https://dev.azure.com/DevOps-Play/TnT-Integration-Test/_workitems/edit/731/
            subDomain = "dev";
            domain = "azure.com";
            folder = `${organization}/${project}/_workitems/edit`;
            targetItem = id;
            break;
          case ForeignSource.JIRA:
            // Sample:
            // https://dyce-dev.atlassian.net/browse/TT-1
            subDomain = organization;
            domain = "atlassian.net";
            folder = "browse";
            targetItem = id;
            break;
        }

        // Build url with all information
        const url = `https://${subDomain}.${domain}/${folder}/${targetItem}`;

        // Open link in new browser tab
        window.open(url, "_blank", "noreferrer");
      } else {
        console.warn("Missing foreign infos!");
      }
    }
  }
};
