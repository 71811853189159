/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Helper
import { DateTime } from "luxon";

/**
 * Checks if endTime is before startTime or endTime is set to 0:00 AM/24:00h
 * @param start startTime as ISO string
 * @param end endTime as ISO string
 * @param pause break (pause) in minutes as number
 * @param date current Date as ISO string
 * @returns Array with [number, string] / [new duration, newEndTime as ISO]
 */
export const checkEndBeforeStartTime = (
  start: string,
  end: string,
  pause: number,
  date: string | null | undefined
): [number, string] => {
  // Set correct Date to selected Time
  if (date === null || date === undefined || date.length === 0) {
    date = DateTime.now().toISO();
  }
  // ISO to start with correct date
  start = date.split("T")[0].concat("T" + start.split("T")[1]);

  let newEndTime = "";
  // Check if end is end of day (0:00 o'clock)
  if (end.split("T")[1].split("+")[0] === "00:00:00.000") {
    date = DateTime.fromISO(date).plus({ days: 1 }).toISO();
    end = date.split("T")[0].concat("T" + end.split("T")[1]);
    newEndTime = end;
  } else {
    end = date.split("T")[0].concat("T" + end.split("T")[1]);
    newEndTime = end;
  }

  const newDuration: number =
    Math.round(
      DateTime.fromISO(end)
        .diff(DateTime.fromISO(start), "minutes")
        .as("minutes")
    ) - pause;

  return [newDuration, newEndTime];
};

/**
 * Calculates endTime if startTime is set and duration is greater 0
 * @param start startTime as ISO string
 * @param duration duration in minutes as number
 * @returns endTime (Iso String)
 */
export const createEndTime = (start: DateTime, duration: number): string => {
  const endTime = start.plus({ minutes: duration }).toISO();

  return endTime;
};

/**
 * Calculates startTime if endTime is set and duration is greater 0
 * @param end endTime as ISO string
 * @param duration duration in minutes as number
 * @returns startTime as ISO string
 */
export const createStartTime = (end: DateTime, duration: number): string => {
  const startTime = end.minus({ minutes: duration }).toISO();

  return startTime;
};

/**
 * Checks if duration fits to start- and endTime and gives back corrected
 * @param start startTime as ISO string
 * @param end endTime as ISO string
 * @param duration duration in minutes as number
 * @param pause break (pause) in minutes as number
 * @returns endTime as ISO string, duration as number
 */
export const calculateNewEndTime = (
  start: DateTime,
  end: DateTime,
  duration: number,
  pause: number
) => {
  let newEndTime: DateTime = end;

  // End minus Start in minutes
  const timeDifference: number = Math.round(
    (end.toMillis() - start.toMillis()) / 60 / 1000
  );

  if (timeDifference !== duration - pause) {
    if (typeof duration === "number") {
      newEndTime = end.plus({ minutes: duration + pause - timeDifference });
    }
  }

  return { end: newEndTime.toISO(), duration: duration };
};
