import React, { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    rootContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      flexFlow: "row",
      width: "100%",
      height: "100%",
    },
    messageContainer: {
      maxWidth: 540,
      verticalAlign: "center",
    },
    header: {
      marginBottom: "20px !important",
    },
  })
);

export const NotFound: FunctionComponent = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();

  return (
    <div>
      {location.pathname === "/authentication/callback" ? null : (
        <div className={classes.rootContainer}>
          <div className={classes.messageContainer}>
            <Typography variant="h2" className={classes.header}>
              {t("notfound.message.title")}
            </Typography>
            <Typography>{t("notfound.message.body")}</Typography>
          </div>
        </div>
      )}
    </div>
  );
};
