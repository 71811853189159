import { createTheme } from "@mui/material/styles";
import { Breakpoint, Components, Theme } from "@mui/material";

export type OverwritePaletteOptions = {
  common?: {
    black: string;
    white: string;
  };
  primary?: {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
  };
  secondary?: {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
  };
  error?: {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
  };
  warning?: {
    light: string;
    main: string;
    dark: string;
    contrastText: string;
  };
  background?: {
    paper: string;
    default: string;
  };
};

export const dyceTheme = ({
  customComponents = {},
  overwritePalette,
  darkMode = false,
  locale = "en-GB",
  sideDrawerLeft = true,
  borderRadius,
  isMobile = false,
}: {
  /**
   * Overwrite default components
   * @default {}
   */
  customComponents?: Components<Omit<Theme, "components">> | undefined;
  /**
   * Overwrite Palette props
   */
  overwritePalette?: OverwritePaletteOptions;
  /**
   * Turn on/off dark mode
   * @default false
   */
  darkMode?: boolean;
  /**
   * Parse selected locale
   * @default "en-GB"
   */
  locale?: string;
  /**
   * Switch if left side drawer is 'open' or 'closed'
   * @default false => closed
   */
  sideDrawerLeft?: boolean;
  /**
   * Define border radius;
   */
  borderRadius?: number;
  /**
   * Define if the device is mobile or not
   * @default false
   */
  isMobile?: boolean;
}): Theme => {
  const customTheme = createTheme({
    components: customComponents,
    palette: {
      mode: darkMode ? "dark" : "light",
      common: {
        ...(overwritePalette?.common ?? {
          black: "#000",
          white: "#fff",
        }),
      },
      primary: {
        ...(overwritePalette?.primary ?? {
          light: "#33C3F7",
          main: "#00B4F5",
          dark: "#1e88b9",
          contrastText: darkMode ? "#1b1b1b" : "#fff",
        }),
      },
      secondary: {
        ...(overwritePalette?.secondary ?? {
          light: "#8E84FF",
          main: "#7266FF",
          dark: "#4F47B2",
          contrastText: "#fff",
        }),
      },
      error: {
        ...(overwritePalette?.error ?? {
          light: "#e57373",
          main: "#f44336",
          dark: "#d32f2f",
          contrastText: "#fff",
        }),
      },
      warning: {
        ...(overwritePalette?.error ?? {
          light: "#e4d46b",
          main: "#f4db36",
          dark: "#c9b639",
          contrastText: "#fff",
        }),
      },
      background: {
        ...(overwritePalette?.background ?? {
          paper: darkMode ? "#1b1b1b" : "#fff",
          default: darkMode ? "#121212" : "rgb(248, 248, 248)",
        }),
      },
      propsTimeTracking: {
        dayHeader: {
          gradient: "rgba(0, 180, 245, 0.1)",
          gradientToday: "rgb(135, 220, 255)",
        },
        recordsContainer: {
          paddingTop: "3px",
          maxWidth: 2432,
          marginRight: "3rem",
          "& .MuiContainer-root": {
            marginLeft: "0",
          },
        },
      },
      propsDyce: {
        contentVisualHeight: {
          height: "calc(100vh - 64px - 112px - 16px)",
          "@media screen and (max-width: 654px)": {
            height: "calc(100vh - 56px - 112px - 16px)",
          },
        },
        commonPageProps: {
          marginTop: "1rem",
          transition: sideDrawerLeft ? "all 0.5s ease" : "all 0.3s ease",
          marginLeft: isMobile ? "0" : sideDrawerLeft ? "260px" : "64px",
        },
        drawerWidthOpen: {
          width: "200px", //Check drawerPageMargin
        },
        drawerPageMargin: {
          value: sideDrawerLeft ? "260px" : "64px",
        },
        paperDesign: {
          variant: darkMode ? "elevation" : "outlined",
          elevation: darkMode ? 1 : 0,
        },
        subHeader: {
          marginForDrawer: isMobile
            ? "1rem"
            : sideDrawerLeft
            ? "17.75rem"
            : "5.5rem",
        },
        drawerTransitions: {
          transCommon: sideDrawerLeft ? "all 0.5s ease" : "all 0.3s ease",
        },
        timeRecItemHeight: {
          commonHeaderHeight: 66,
          commonHeight: 58,
        },
        drawerLeft: {
          isOpen: sideDrawerLeft,
        },
        selectedLocale: locale,
        overwriteBaseStyle: Object.entries(customComponents).length > 0,
        drawerLeftContent: {
          "&:hover": {
            paddingLeft: "1.5rem",
            transition: "ease-in-out",
            transitionDuration: 200,
          },
        },
        drawerLeftContentSmall: {
          "& .MuiButtonBase-root": {
            paddingLeft: "1.15rem",
            "&:hover": {
              paddingLeft: "1.5rem",
              transition: "ease-in-out",
              transitionDuration: 200,
            },
          },
        },
        drawerLeftContentCollapse: {
          "& .MuiButtonBase-root": {
            paddingLeft: "2rem",
            "&:hover": {
              paddingLeft: "2.5rem",
              transition: "ease-in-out",
              transitionDuration: 200,
            },
          },
        },
      },
    },
    shape:
      borderRadius !== undefined || borderRadius === 0
        ? {
            borderRadius: borderRadius,
          }
        : {},
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1380,
        xl: 1920,
        tablet: 640,
        laptop: 1024,
        desktop: 1280,
        iPad: 1024,
        iPadPro: 1366,
        timeRecsMax: sideDrawerLeft ? 2310 : 2110,
        timeRecsMid: sideDrawerLeft ? 1830 : 1610,
        timeRecsMin: sideDrawerLeft ? 1700 : 1510,
        dashboardBreakPoint: sideDrawerLeft ? 1355 : 1195,
        timeRecsMidi: sideDrawerLeft ? 1475 : 1275,
      },
    },
  });

  return customTheme;
};

export type DyceTheme = Theme & {
  palette: {
    propsTimeTracking: {
      dayHeader: {
        gradient: string;
        gradientToday: string;
      };
      recordsContainer: Record<string, any>;
    };
    propsDyce: {
      contentVisualHeight: {
        height: string;
        "@media screen and (max-width: 654px)": {
          height: string;
        };
      };
      commonPageProps: {
        marginTop: string | number;
        transition: string;
        marginLeft: string | number;
      };
      drawerWidthOpen: {
        width: string | number;
      };
      drawerPageMargin: {
        value: number | string;
      };
      paperDesign: {
        variant: "elevation" | "outlined";
        elevation: number | undefined;
      };
      subHeader: {
        marginForDrawer: string | number;
      };
      drawerTransitions: {
        transCommon: string;
      };
      timeRecItemHeight: {
        commonHeaderHeight: number;
        commonHeight: number;
      };
      drawerLeft: {
        isOpen: boolean;
      };
      selectedLocale: string;
      overwriteBaseStyle: boolean;
      drawerLeftContent: {
        "&:hover": {
          paddingLeft: string | number;
          transition: string;
          transitionDuration: number;
        };
      };
      drawerLeftContentSmall: {
        "& .MuiButtonBase-root": {
          paddingLeft: string | number;
          "&:hover": {
            paddingLeft: string | number;
            transition: string;
            transitionDuration: number;
          };
        };
      };
      drawerLeftContentCollapse: {
        "& .MuiButtonBase-root": {
          paddingLeft: string | number;
          "&:hover": {
            paddingLeft: string | number;
            transition: string;
            transitionDuration: number;
          };
        };
      };
    };
  };
  breakpoints: {
    keys: Breakpoint[];
    values: {
      xs: number;
      sm: number;
      md: number;
      lg: number;
      xl: number;
      tablet: number;
      laptop: number;
      desktop: number;
      iPad: number;
      iPadPro: number;
      timeRecsMax: number;
      timeRecsMid: number;
      timeRecsMin: number;
      dashboardBreakPoint: number;
      timeRecsMidi: number;
    };
  };
};
