import React, { FunctionComponent } from "react";
// Helper
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { MenuItemCreator, useStaticContent } from "@dyce/ui";
import {
  selectSideDrawerLeft,
  incompleteCount,
  useAppSelector,
  selectAdminSettingsTasks,
} from "@dyce/slices";
import { TasksFilterStatus } from "@dyce/tnt-api";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Badge } from "@mui/material";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";
import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import BookmarksOutlinedIcon from "@mui/icons-material/BookmarksOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    content: theme.palette.propsDyce.drawerLeftContent,
  })
);

export const TimeRecMenuItems: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { routes } = useStaticContent();
  const location = useLocation();

  // Selectors
  const drawerLeftToggled = useAppSelector(selectSideDrawerLeft);
  const tasksRule = useAppSelector(selectAdminSettingsTasks);

  const handleHashDate = (): string => {
    if (location.hash.length > 0) {
      return location.hash;
    } else {
      return `#${DateTime.now().toISODate()}`;
    }
  };

  return (
    <>
      <MenuItemCreator
        cssItem={classes.content}
        url={routes.TNT}
        icon={<CalendarTodayOutlinedIcon />}
        label={t("timerec.nav.dashboard")}
        tooltipLabel={
          !drawerLeftToggled ? t("timerec.nav.dashboard") : undefined
        }
      />
      <MenuItemCreator
        cssItem={classes.content}
        url={`${routes.TNT_RECS}${handleHashDate()}`}
        icon={<MoreTimeOutlinedIcon />}
        label={t("timerec.nav.dailyrecording")}
        tooltipLabel={
          !drawerLeftToggled ? t("timerec.nav.dailyrecording") : undefined
        }
      />
      <MenuItemCreator
        cssItem={classes.content}
        url={routes.TNT_CALENDAR}
        icon={<CalendarMonthOutlinedIcon />}
        label={t("timerec.nav.calendar")}
        tooltipLabel={
          !drawerLeftToggled ? t("timerec.nav.calendar") : undefined
        }
      />
      {tasksRule.assignment !== TasksFilterStatus.NONE && (
        <MenuItemCreator
          cssItem={classes.content}
          url={routes.TNT_TASKS}
          icon={<TaskAltOutlinedIcon />}
          label={t("timerec.nav.tasks")}
          tooltipLabel={!drawerLeftToggled ? t("timerec.nav.tasks") : undefined}
        />
      )}
      <MenuItemCreator
        cssItem={classes.content}
        url={routes.TNT_TEMPLATES}
        icon={<BookmarksOutlinedIcon />}
        label={t("timerec.nav.templates")}
        tooltipLabel={
          !drawerLeftToggled ? t("timerec.nav.templates") : undefined
        }
      />
      <MenuItemCreator
        cssItem={classes.content}
        url={routes.TNT_INCOMPLETE}
        icon={<IncompleteTimesIcon />}
        label={t("timerec.nav.incompleteTimes")}
        tooltipLabel={
          !drawerLeftToggled ? t("timerec.nav.incompleteTimes") : undefined
        }
      />
    </>
  );
};

const IncompleteTimesIcon: FunctionComponent = () => {
  const count = useAppSelector(incompleteCount);

  if (count > 0) {
    return (
      <Badge badgeContent={count} color="error">
        <ErrorOutlineIcon fontSize="small" color="error" />
      </Badge>
    );
  } else {
    return <ErrorOutlineIcon fontSize="small" color="disabled" />;
  }
};
