// Redux Toolkit, Slices
import { unwrapResult } from "@reduxjs/toolkit";
// Helper
import { t } from "i18next";
import { Expand, Filter, OrderBy, QueryOptions } from "odata-query";
import { SortDirection } from "react-data-grid";
import { handleFocusField } from "../utils";
import { UseFormSetFocus } from "react-hook-form";
import {
  clearDependingFields,
  getCustomerInformation,
  jobSelected,
  jobTaskSelected,
} from "./validateRecordTask";
import { InitialData } from "./initial-data";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import {
  Customer,
  JobTaskStatusOptions,
  NonBillableReasonOptions,
  PopulatedJob,
  PopulatedJobPlanningLine,
  PopulatedJobTask,
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
} from "@dyce/tnt-api";
import {
  AppDispatch,
  getActivities,
  getCustomer,
  getJobs,
  getJobTasks,
  getTaskedActivities,
  getTaskedCustomer,
  getTaskedJobs,
  getTaskedJobTasks,
} from "@dyce/slices";
import activityController from "../../look-up/tableDefinition/activityController";
import customerController from "../../look-up/tableDefinition/customerController";
import jobController from "../../look-up/tableDefinition/jobController";
import jobTaskController from "../../look-up/tableDefinition/jobTaskController";
import {
  OdataJobTaskFilter,
  OdataJobFilter,
  InfScrollOptions,
  ValidateRecordTask,
  HandleNewEntry,
  IDatagridRow,
  IDatagridColumn,
  InfScrollFilter,
  SameEntry,
} from "../../types";
import {
  LookUpActivityOptions,
  LookUpMapping,
  LookUpOptions,
  LookUpShortOptions,
} from "@dyce/interfaces";

/**
 * Function to fetch data from backend with correct builded OData query,
 * or serve final prepared OData query from inf scroll functions
 * @param orderBy String in which order entries should be result
 * @param filterColumns Array of columns that contains user query or null
 * @param expand Expand rules for odata query, prepared for "buildQuery"
 * @param top Number for OData query-prop: top
 * @param filterPreviousField Object for addition filter from previous field or null
 * @param odataQuery Final odata query from infScroll functions or null
 * @param dispatch useDispatch hook
 * @param lookUpField Define for which lookUp field this function is called
 * @param withCells If true, result will be provided with key: "cells:" {...}
 * @param jobNoFilter Addition filter for Job call to look for Customer
 * @returns Data array from backend from selected Field
 */
export async function apiCallLookUp<T, U>({
  orderBy,
  filterColumns,
  expand,
  top,
  filterPreviousField,
  odataQuery,
  dispatch,
  lookUpField,
  withCells = false,
  jobNoFilter,
  getTaskedList,
}: {
  orderBy: OrderBy<U>;
  filterColumns: any[] | null;
  expand: Expand<U>;
  top: number;
  filterPreviousField: OdataJobTaskFilter | OdataJobFilter | null;
  odataQuery: Partial<QueryOptions<U>> | null;
  dispatch: AppDispatch;
  lookUpField: LookUpShortOptions;
  getTaskedList: boolean;
  withCells?: boolean;
  jobNoFilter?: {
    no: string | null;
  };
}): Promise<T> {
  try {
    const queryFilter = filterColumns ? { or: [...filterColumns] } : undefined;
    let entriesDispatch: any = null;
    let entriesUnwrapped: any = null;
    let query: Partial<QueryOptions<U>>;

    // Build OData query string
    if (odataQuery) {
      query = odataQuery;
    } else {
      const filter: Filter = [
        filterPreviousField ? filterPreviousField : undefined,
        jobNoFilter,
        queryFilter,
      ];
      query = { expand, orderBy, filter, top };
    }
    // Call API
    switch (lookUpField) {
      case LookUpShortOptions.CUSTOMER: {
        if (!getTaskedList) {
          entriesDispatch = await dispatch(
            getCustomer(query as Partial<QueryOptions<Customer>>)
          );
        } else {
          entriesDispatch = await dispatch(
            getTaskedCustomer(query as Partial<QueryOptions<Customer>>)
          );
        }
        break;
      }
      case LookUpShortOptions.JOB: {
        if (!getTaskedList) {
          entriesDispatch = await dispatch(
            getJobs(query as Partial<QueryOptions<PopulatedJob>>)
          );
        } else {
          entriesDispatch = await dispatch(
            getTaskedJobs(query as Partial<QueryOptions<PopulatedJob>>)
          );
        }
        break;
      }
      case LookUpShortOptions.JOBTASK: {
        if (!getTaskedList) {
          entriesDispatch = await dispatch(
            getJobTasks(query as Partial<QueryOptions<PopulatedJobTask>>)
          );
        } else {
          entriesDispatch = await dispatch(
            getTaskedJobTasks(query as Partial<QueryOptions<PopulatedJobTask>>)
          );
        }
        break;
      }
    }
    entriesUnwrapped = unwrapResult(entriesDispatch);

    return withCells
      ? handleGetFieldController({ mappedFieldName: lookUpField }).createRows(
          entriesUnwrapped
        )
      : entriesUnwrapped;
  } catch {
    return [] as any;
  }
}

/**
 * Function to fetch data from jobPlanningLine and tntModelLine
 * @param doFilter Boolean to force filter for user input containing in columns
 * @param debouncedValue String from user input in field to search for
 * @param orderBy String in which order entries should be result
 * @param jobId Id from current job
 * @param jobTaskId Id from current jobTask or null
 * @param dispatch useDispatch hook
 * @param controller Provide LookUp field controller for activity => IDataGridRow<JobPlanningLine[]>
 * @returns Activity array from backend
 */
export async function apiCallActivity<T>({
  doFilter,
  debouncedValue,
  orderBy,
  jobId,
  jobTaskId,
  getTaskedList,
  dispatch,
  controller,
}: {
  doFilter: boolean;
  debouncedValue: string;
  orderBy: string;
  jobId: string;
  jobTaskId: string | null;
  getTaskedList: boolean;
  dispatch: AppDispatch;
  controller?: any | undefined;
}): Promise<T> {
  if (jobId.length > 0) {
    try {
      let filter = "";
      if (doFilter) {
        if (jobTaskId) {
          filter = `?$filter=contains(tolower(tntModelLine/description),'${debouncedValue.toLowerCase()}')&`;
        } else {
          filter = `?$filter=((contains(tolower(tntModelLine/description),'${debouncedValue.toLowerCase()}'))%20or%20(contains(tolower(jobTask/description),'${debouncedValue.toLowerCase()}'))%20or%20(contains(tolower(jobPlanningLine/description),'${debouncedValue.toLowerCase()}')))&`;
        }
      }

      // Call API
      const entriesDispatch = getTaskedList
        ? await dispatch(
            getTaskedActivities({
              jobId,
              jobTaskId,
              filter,
              orderBy,
            })
          )
        : await dispatch(
            getActivities({
              jobId,
              jobTaskId,
              filter,
              orderBy,
            })
          );
      const entriesUnwrapped: T = unwrapResult(entriesDispatch as any);

      if (controller !== undefined) {
        return controller.createRows(entriesUnwrapped) as T;
      } else {
        return entriesUnwrapped as T;
      }
    } catch {
      return [] as any;
    }
  } else {
    return [] as any;
  }
}

/**
 * Function to fetch entries for current selected LookUp field and handle infinite
 * scrolling options if needed to add new entries in correct position
 * of current array
 * @param inputQuery String of user input in field
 * @param infScrolling Options for infinite scrolling @type {InfScrollOptions}
 * @param field String from current LookUp field as @type {LookUpShortOptions}
 * @param additionFilter Filter for job or jobTask if exists or null
 * @param controller Provide LookUp field controller
 * @param projectingInput Current ValidatedRecordTask Object
 * @param dispatch useDispatch hook
 * @param handleNewEntries Function to set entries in correct array position
 */
export async function getFieldData<T>({
  inputQuery,
  infScrolling,
  field,
  projectingInput,
  filterIsActive,
  dispatch,
  handleNewEntries,
  allowDeleteCustomer,
}: {
  inputQuery: string;
  infScrolling: InfScrollOptions<T>;
  field: LookUpShortOptions;
  projectingInput: ValidateRecordTask<T>;
  filterIsActive: boolean;
  dispatch: AppDispatch;
  handleNewEntries: HandleNewEntry<T>;
  allowDeleteCustomer?: boolean;
}) {
  if (field === "jobTask") {
    projectingInput.infos.jobTaskLoading = true;
  }

  const { direction, defaultQuery, additionQuery } = infScrolling;
  let lowerEntries: IDatagridRow<T>[] = [];

  const defaultEntries = await apiCallLookUp<IDatagridRow<T>[], T>({
    orderBy: projectingInput.orderBy[field],
    filterColumns:
      inputQuery.length > 0
        ? handleGetFieldController({
            mappedFieldName: field,
          }).getDbColumnSearch(inputQuery)
        : null,
    expand: handleGetFieldController({ mappedFieldName: field }).expand,
    top: projectingInput.odataFilter.topInitial,
    filterPreviousField:
      allowDeleteCustomer && field !== "jobTask"
        ? null
        : handleGetFieldFilter({ fieldName: field, projectingInput }),
    odataQuery:
      allowDeleteCustomer && field !== "jobTask"
        ? null
        : defaultQuery.filter
        ? defaultQuery
        : null,
    dispatch: dispatch,
    lookUpField: field,
    withCells: true,
    getTaskedList: filterIsActive,
  });

  if (additionQuery !== undefined) {
    lowerEntries = await apiCallLookUp<IDatagridRow<T>[], T>({
      orderBy: projectingInput.orderBy[field],
      filterColumns:
        inputQuery.length > 0
          ? handleGetFieldController({
              mappedFieldName: field,
            }).getDbColumnSearch(inputQuery)
          : null,
      expand: handleGetFieldController({ mappedFieldName: field }).expand,
      top: projectingInput.odataFilter.topInitial,
      filterPreviousField: allowDeleteCustomer
        ? null
        : handleGetFieldFilter({
            fieldName: field,
            projectingInput,
          }),
      odataQuery: additionQuery,
      dispatch: dispatch,
      lookUpField: field,
      withCells: true,
      getTaskedList: filterIsActive,
    });
  }

  if (projectingInput.entries[field].length > 0 && direction !== "NONE") {
    if (additionQuery) {
      // Initial API Call with load above && below
      // const newLowerEntries = controller.createRows(lowerEntries);

      handleNewEntries({
        newEntries: defaultEntries.reverse(),
        key: field,
        combineEntries: {
          oldEntries: lowerEntries,
          direction,
          loadMoreUp:
            defaultEntries.length >= projectingInput.odataFilter.topAbove,
          loadMoreDown:
            lowerEntries.length >= projectingInput.odataFilter.topBelow,
        },
      });
    } else {
      // Infinite scrolling behavior
      if (direction === "DOWN") {
        handleNewEntries({
          newEntries: defaultEntries,
          key: field,
          combineEntries: {
            oldEntries: projectingInput.entries[field],
            direction,
            loadMoreUp: projectingInput.odataFilter.loadMoreUp,
            loadMoreDown:
              defaultEntries.length >=
              projectingInput.odataFilter.loadMoreCount,
          },
        });
      } else {
        handleNewEntries({
          newEntries: defaultEntries.reverse(),
          key: field,
          combineEntries: {
            oldEntries: projectingInput.entries[field],
            direction,
            loadMoreUp:
              defaultEntries.length >=
              projectingInput.odataFilter.loadMoreCount,
            loadMoreDown: projectingInput.odataFilter.loadMoreDown,
          },
        });
      }
    }
  } else {
    handleNewEntries({ newEntries: defaultEntries, key: field });
  }
  projectingInput.infos.jobTaskLoading = false;
}

/**
 * Serves correct controller
 * @returns Controller for LookUpField
 */
export const handleGetFieldController = ({
  mappedFieldName,
}: {
  mappedFieldName: LookUpActivityOptions | LookUpShortOptions;
}) => {
  let fieldController: any = customerController;

  switch (mappedFieldName) {
    case "job":
      fieldController = jobController;
      break;
    case "jobTask":
      fieldController = jobTaskController;
      break;
    case "activity":
      fieldController = activityController;
      break;
  }
  return fieldController;
};

/**
 * Serves correct OData filter
 * @returns Filter for LookUpField or null
 */
export function handleGetFieldFilter<T>({
  fieldName,
  projectingInput,
}: {
  fieldName: LookUpShortOptions | LookUpActivityOptions;
  projectingInput: ValidateRecordTask<T>;
}): OdataJobFilter | OdataJobTaskFilter | null {
  let fieldFilter: OdataJobFilter | OdataJobTaskFilter | null = null;
  switch (fieldName) {
    case "job":
      fieldFilter = projectingInput.odataFilter.filterForCustomer;
      break;
    case "jobTask":
      fieldFilter = projectingInput.odataFilter.filterForJob;
      break;
  }
  return fieldFilter;
}

/**
 * Function to handle Odata query to fetch entries above and below current one
 * @param field String from current LookUp field as @type {TimeRecKeyValuesShort}
 * @param debouncedValue String from user input in field to search for
 * @param projectingInput Current ValidatedRecordTask Object
 * @param filterIsActive Boolean if filter is active
 * @param dispatch useDispatch hook
 * @param handleNewEntries Function to set entries in correct array position
 */
export function handleFieldWithSelection<
  T = Customer | PopulatedJob | PopulatedJobTask,
>({
  field,
  debouncedValue,
  projectingInput,
  filterIsActive,
  dispatch,
  handleNewEntries,
  allowDeleteCustomer,
}: {
  field: LookUpShortOptions;
  debouncedValue: string;
  projectingInput: ValidateRecordTask<T>;
  filterIsActive: boolean;
  dispatch: AppDispatch;
  handleNewEntries: HandleNewEntry<T>;
  allowDeleteCustomer: boolean;
}) {
  const fieldDataWOPagination = () => {
    projectingInput.odataFilter.loadMoreUp = false;
    getFieldData<T>({
      inputQuery: debouncedValue.length > 0 ? debouncedValue : "",
      infScrolling: {
        direction: "NONE",
        defaultQuery: {} as QueryOptions<T>,
      },
      field,
      projectingInput,
      filterIsActive,
      dispatch,
      handleNewEntries,
      allowDeleteCustomer,
    });
  };

  if (projectingInput.ids[field].length > 0) {
    // Safely have only one entry to do not trigger infScroll initially
    const fieldEntry: T | undefined = (
      projectingInput.entries[field] as any
    ).find((x: any) => x.id === projectingInput.ids[field]);

    if (fieldEntry !== undefined) {
      projectingInput.entries[field] = [fieldEntry] as any[];

      const [queryAbove, queryBelow] = initialInfScrollFilter<T>({
        count: projectingInput.odataFilter,
        currentOrderBy: projectingInput.orderBy[field],
        expand: handleGetFieldController({ mappedFieldName: field }).expand,
        entry: projectingInput.entries[field],
        additionFilter: allowDeleteCustomer
          ? null
          : handleGetFieldFilter<T>({
              fieldName: field,
              projectingInput,
            }),
      });
      projectingInput.odataFilter.loadMoreUp = true;
      // Get field data with pagination from current field entry
      getFieldData<T>({
        inputQuery: "",
        infScrolling: {
          direction: "UP",
          defaultQuery: queryAbove,
          additionQuery: queryBelow,
        },
        field,
        projectingInput,
        filterIsActive,
        dispatch,
        handleNewEntries,
        allowDeleteCustomer,
      });
    } else {
      /**
       * Field value is not in current Datagrid, while filter is active;
       * Check that memoryValue is available, if yes, load data from backend;
       */
      const mem: IDatagridRow<T> | null = projectingInput.memoryEntries[field];
      if (mem) {
        // Safely have only one entry to do not trigger infScroll initially
        projectingInput.entries[field] = mem as any;
        fieldDataWOPagination();
      }
    }
  } else {
    fieldDataWOPagination();
  }
  projectingInput.odataFilter.loadMoreDown = true;
}

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

/**
 * Function to proof current entries to validate with user input
 * @param columns columns object from LookUp-field
 * @param entries Entries array from LookUp-field
 * @param rawInput userQuery from LookUp-Field
 * @returns ID as string or null when no match (error = true)
 */
export const errorCheck = ({
  columns,
  entries,
  rawInput,
  valueId,
}: {
  columns: Record<string, IDatagridColumn>;
  entries: IDatagridRow<any>[];
  rawInput: string | null;
  valueId: string;
}): string | null => {
  let id: string | null = null;
  const keys: string[] = [];
  const newEntries: any[] = [];

  if (entries.length > 0 && rawInput && rawInput.length > 0) {
    // Create Entries object flattened (without "cells" key)
    if (entries.length > 0) {
      const ids = entries.map((x) => x.id);
      const values = entries.map((x) => x.cells);

      for (let i = 0; i < entries.length; i++) {
        newEntries.push({
          id: ids[i],
          ...values[i],
        });
      }
    }

    // Get all [keys] from columns with searchable === true
    for (const [key, value] of Object.entries(columns)) {
      if (value.searchable) {
        keys.push(key);
      }
    }
    // Compare rawInput in rows with all [keys] and save id
    let foundIncluding = false;
    let foundExact = false;
    for (let i = 0; i < newEntries.length; i++) {
      for (let j = 0; j < keys.length; j++) {
        if (
          !foundExact &&
          newEntries[i][keys[j]] !== undefined &&
          newEntries[i][keys[j]] !== null &&
          newEntries[i][keys[j]].toLowerCase() === rawInput.toLowerCase()
        ) {
          id = valueId && valueId.length > 0 ? valueId : newEntries[i].id;
          foundExact = true;
          i = 0;
          j = 0;
        } else if (
          !foundExact &&
          !foundIncluding &&
          newEntries[i][keys[j]] !== undefined &&
          newEntries[i][keys[j]] !== null &&
          newEntries[i][keys[j]].toLowerCase().includes(rawInput.toLowerCase())
        ) {
          id = newEntries[i].id;
          foundIncluding = true;
        } else if (!foundIncluding && !foundExact) {
          id = null;
        }
      }
    }
  } else if (rawInput && rawInput.length > 0 && rawInput !== "Error") {
    id = null;
  } else {
    id = "";
  }

  return id;
};

export function defineValidateObject<T>({
  initialTop,
  loadAbove,
  loadBelow,
  loadMore,
}: {
  initialTop: number;
  loadAbove: number;
  loadBelow: number;
  loadMore: number;
}): ValidateRecordTask<T> {
  const projectingInput = {
    entries: {
      customer: [],
      job: [],
      jobTask: [],
      activity: [],
    },
    memoryEntries: {
      customer: null,
      job: null,
      jobTask: null,
      activity: null,
    },
    forceChange: {
      description: null,
      jobTaskFilledFromActivity: false,
    },
    ids: {
      customer: "",
      job: "",
      jobTask: "",
      activity: "",
    },
    memoryIds: {
      customer: "",
      job: "",
      jobTask: "",
      activity: "",
    },
    pending: {
      field: null,
      isLoading: false,
      pendingApiCalls: 0,
      blurred: null,
    },
    infos: {
      customerIsConfirming: false,
      activityHasValue: false,
      initialJobTask: true,
      initialActivity: true,
      fromController: null,
      memoryJobTaskId: "",
      memoryActivityId: "",
      deletedByUser: false,
      changedJobTask: false,
      jobTaskLoading: false,
      activityLoading: false,
      openWithSkeletons: false,
      focusActivity: false,
      customerCallCompleted: false,
      activityCallCompleted: false,
      checkForSameTnTModelId: "",
      filledFromJobTask: false,
      storeJobPlanningLineInfo: null,
      memoryLastJobId: "",
      memoryLastJobTaskId: "",
      blockMemoryLastJobTaskId: false,
      activityIdBeforeError: "",
      jobHadError: false,
      jobTaskBlurred: false,
    },
    dependency: {
      deleteFields: false,
    },
    odataFilter: {
      filterForCustomer: null,
      filterForJob: null,
      topAbove: loadAbove,
      topBelow: loadBelow,
      loadMoreCount: loadMore,
      topInitial: initialTop,
      loadMoreUp: true,
      loadMoreDown: true,
    },
    orderBy: {
      customer: "name ASC",
      job: "no ASC",
      jobTask: "job/no ASC, no ASC, description ASC, job/description ASC",
      activity: "tntModelLine/description ASC",
    },
  };

  return projectingInput;
}

/**
 * Function to set 'OrderBy' direction and column key for ODATA Api calls
 * @param name Name from given LookUp
 * @param key Column key
 * @param direction Order direction (ASC | DESC)
 * @param projectingInput State object for business logic as copy (prevState)
 * @returns ValidateRecordTask with new entries
 */
export function handleOnGridSort<T>({
  name,
  key,
  direction,
  projectingInput,
}: {
  name: LookUpOptions;
  key: LookUpActivityOptions;
  direction: SortDirection;
  projectingInput: ValidateRecordTask<T>;
}): ValidateRecordTask<T> {
  return {
    ...projectingInput,
    infos: {
      ...projectingInput.infos,
      fromController: name,
    },
    orderBy: {
      customer:
        name === LookUpOptions.CUSTOMER
          ? customerController.getSqlColumnName(key, direction)
          : projectingInput.orderBy.customer,
      job:
        name === LookUpOptions.JOB
          ? jobController.getSqlColumnName(key, direction)
          : projectingInput.orderBy.job,
      jobTask:
        name === LookUpOptions.JOBTASK
          ? jobTaskController.getSqlColumnName(key, direction)
          : projectingInput.orderBy.jobTask,
      activity:
        name === LookUpOptions.JOBPLANNINGLINE
          ? activityController.getSqlColumnName(key, direction)
          : projectingInput.orderBy.activity,
    },
    odataFilter: {
      ...projectingInput.odataFilter,
      loadMoreUp: true,
      loadMoreDown: true,
    },
  };
}

/**
 * Function to create OData filter for infinite scrolling => Load more
 * entries above (up) or below (down) current entries
 * @param firstLastId Server first id of array entry or last entry
 * @param firstOrLastValue Server first value of array entry or last entry
 * @param currentOrderBy Serve current order direction
 * @param direction Direction for infinite scrolling "UP" | "DOWN"
 * @param additionFieldFilter Filter for job or jobTask if exists or null
 * @param filterColumns Server containing Columns filter from controller
 * @returns Object with filter: @type {InfScrollFilter} and orderBy string
 */
export const getPagingFilter = ({
  noValue,
  firstOrLastValue,
  currentOrderBy,
  direction,
  additionFieldFilter,
  filterColumns,
}: {
  noValue: string;
  firstOrLastValue: string;
  currentOrderBy: string;
  direction: "UP" | "DOWN";
  additionFieldFilter: OdataJobTaskFilter | OdataJobFilter | null;
  filterColumns: any[] | null;
}): {
  filter: Filter;
  orderBy: OrderBy<any>;
} => {
  const queryFilter = filterColumns ? { or: [...filterColumns] } : undefined;
  let orderBy = currentOrderBy;
  let orderByAddition = orderBy.includes(",")
    ? `,${orderBy.split(",")[1]}`
    : "";

  if (direction === "UP") {
    // Swap order direction
    orderByAddition = orderByAddition.includes("ASC")
      ? orderByAddition.replace("ASC", "DESC")
      : orderByAddition.replace("DESC", "ASC");

    orderBy = `${currentOrderBy.split(" ")[0]} ${
      currentOrderBy.split(" ")[1].includes("ASC") ? "DESC" : "ASC"
    }${orderByAddition}`;
  }
  const gtLt = orderBy.includes("ASC") ? "gt" : "lt";

  const columnKey: string = orderBy.split(" ")[0];

  const filter: InfScrollFilter = {
    or: [
      { [columnKey]: { [gtLt]: firstOrLastValue } },
      {
        and: [
          { [columnKey]: { eq: firstOrLastValue } },
          { no: { [gtLt]: noValue } },
        ],
      },
    ],
    and: [additionFieldFilter ? additionFieldFilter : undefined, queryFilter],
  };

  return { filter, orderBy };
};

/**
 *
 * @param entries Serve current entries from selected LookUp field as array
 * @param orderByValues Current orderBy string from selected LookUp field
 * @param direction Serve direction for infinite scrolling "UP" | "DOWN"
 * @returns Object with noValue: number Value and firstValue: Value from column
 * that is ordered (description ASC)
 */
export const getCorrectEntry = ({
  entries,
  orderByValues,
  direction,
}: {
  entries: IDatagridRow[];
  orderByValues: string;
  direction: "UP" | "DOWN";
}): {
  noValue: string;
  firstValue: string;
} => {
  const orderByColumnSplit: string[] = [];
  let firstValue = "";
  let noValue = "";

  const orderByColumn = orderByValues.split(" ")[0];

  if (orderByColumn.includes("/")) {
    orderByColumnSplit.push(orderByColumn.split("/")[0]);
    orderByColumnSplit.push(orderByColumn.split("/")[1]);
  }

  if (direction === "UP") {
    noValue = entries[0].cells.no;
    if (orderByColumnSplit.length > 0) {
      firstValue =
        entries[0].cells[orderByColumnSplit[0]][orderByColumnSplit[1]];
    } else {
      firstValue = entries[0].cells[orderByColumn];
    }
  } else if (direction === "DOWN" && entries[entries.length - 1]) {
    noValue = entries[entries.length - 1].cells.no;
    if (orderByColumnSplit.length > 0) {
      firstValue =
        entries[entries.length - 1].cells[orderByColumnSplit[0]][
          orderByColumnSplit[1]
        ];
    } else {
      firstValue = entries[entries.length - 1].cells[orderByColumn];
    }
  }

  return { noValue, firstValue };
};

/**
 *
 * @param count Serve odataFilter from projectingInput
 * @param currentOrderBy Server orderBy string from selected LookUp field
 * @param expand Serve expand object from selected LookUp field
 * @param entry Serve current selected Object as array
 * @param additionFilter Filter for job or jobTask if exists or null
 * @returns String array with odata query above and below current selection
 */
export function initialInfScrollFilter<T>({
  count,
  currentOrderBy,
  expand,
  entry,
  additionFilter,
}: {
  count: { topAbove: number; topBelow: number };
  currentOrderBy: string;
  expand: Expand<T>;
  entry: IDatagridRow<T>[];
  additionFilter: OdataJobFilter | OdataJobTaskFilter | null;
}): QueryOptions<T>[] {
  const { topAbove, topBelow } = count;
  // Current selected Column for ordering
  const selectedId = entry[0].id;
  const queries: QueryOptions<T>[] = [];
  const columnKey = currentOrderBy.split(" ")[0];
  const { firstValue } = getCorrectEntry({
    entries: entry,
    orderByValues: currentOrderBy,
    direction: "UP",
  });
  let top = topAbove;

  let swapOrderBy = currentOrderBy.split(",");
  swapOrderBy = swapOrderBy.map((x) =>
    x.includes("ASC") ? x.replace("ASC", "DESC") : x.replace("DESC", "ASC")
  );

  let orderBy: OrderBy<any> = swapOrderBy.join(",");
  let getSameEntry: SameEntry | undefined = undefined;
  let gtLt = orderBy.includes("ASC") ? "gt" : "lt";

  for (let i = 0; i < 2; i++) {
    const filter: Filter = [
      {
        or: [{ [columnKey]: { [gtLt]: firstValue } }, getSameEntry],
      },
      additionFilter ? additionFilter : undefined,
    ];

    // Build Odata query string
    queries.push({ filter, expand, orderBy, top } as QueryOptions<T>);
    // Swap values for next loop
    getSameEntry =
      getSameEntry === undefined
        ? { id: { eq: { type: "guid", value: selectedId } } }
        : undefined;
    gtLt = gtLt === "lt" ? "gt" : "lt";
    top = top === topAbove ? topBelow : topAbove;
    orderBy = currentOrderBy;
  }

  return queries;
}

/**
 * Function to load entries above or below current entries with providing skeletons
 * in current direction added to entries
 * @param direction Direction for infinite scrolling "UP" | "DOWN"
 * @param debouncedValue String from user input in field to search for
 * @param projectingInput Current ValidatedRecordTask Object
 * @param key String from current LookUp field as @type {LookUpShortOptions}
 * @param additionFilter Filter for job or jobTask if exists or null
 * @returns String with odata query for infinite scrolling above or below
 * current entries
 */
export function infScrollFieldHandler<T>({
  direction,
  debouncedValue,
  projectingInput,
  key,
}: {
  direction: "UP" | "DOWN";
  debouncedValue: string;
  projectingInput: ValidateRecordTask<T>;
  key: LookUpShortOptions;
}): QueryOptions<T> {
  const top = projectingInput.odataFilter.loadMoreCount;
  const filterColumns = handleGetFieldController({
    mappedFieldName: key,
  }).getDbColumnSearch(debouncedValue);
  projectingInput.infos.openWithSkeletons = false;

  const { noValue, firstValue } = getCorrectEntry({
    entries: projectingInput.entries[key],
    orderByValues: projectingInput.orderBy[key],
    direction,
  });

  const { filter, orderBy } = getPagingFilter({
    noValue,
    firstOrLastValue: firstValue,
    currentOrderBy: projectingInput.orderBy[key],
    direction,
    additionFieldFilter: handleGetFieldFilter({
      fieldName: key,
      projectingInput,
    }),
    filterColumns: debouncedValue.length > 0 ? filterColumns : null,
  });

  const expand: Expand<T> = handleGetFieldController({
    mappedFieldName: key,
  }).expand;
  const query = { expand, orderBy, filter, top } as QueryOptions<T>;

  return query;
}

/**
 * Function to check if infinite scrolling is still allowed or not
 * @param direction Direction for infinite scrolling "UP" | "DOWN"
 * @param projectingInput Current ValidatedRecordTask Object
 * @returns Boolean
 */
export function checkFurtherInfScrolling<T>(
  direction: "UP" | "DOWN",
  projectingInput: ValidateRecordTask<T>
): boolean {
  if (direction === "UP" && !projectingInput.odataFilter.loadMoreUp) {
    return false;
  }
  if (direction === "DOWN" && !projectingInput.odataFilter.loadMoreDown) {
    return false;
  }
  return true;
}

/**
 * Function to create and provide skeleton rows for LookUp Datagrid dependent on
 * empty field or field with value
 * @param controller Provide LookUp field controller
 * @param key String from current LookUp field as @type {TimeRecActivityValues}
 * @param projectingInput Current ValidatedRecordTask Object
 * @param hasValue Boolean if field has already a value
 * @param handleNewEntries Function to set rows to state object
 * @param lastRowLength Number from last array length of current field entries
 * @default 0
 */
export function showSkeletonsOnOpen<T>({
  key,
  projectingInput,
  hasValue,
  handleNewEntries,
  lastRowLength = 0,
}: {
  key: LookUpActivityOptions;
  projectingInput: ValidateRecordTask<T>;
  hasValue: boolean;
  handleNewEntries: HandleNewEntry<T>;
  lastRowLength?: number;
}) {
  let rowCount = 0;
  if (hasValue) {
    rowCount =
      projectingInput.odataFilter.topAbove +
      projectingInput.odataFilter.topBelow;
    projectingInput.infos.openWithSkeletons = true;
  } else {
    projectingInput.infos.openWithSkeletons = false;
    if (lastRowLength > 0) {
      rowCount = lastRowLength;
    } else {
      rowCount = projectingInput.odataFilter.topInitial;
    }
  }

  const skeletonRows: IDatagridRow<T>[] = handleGetFieldController({
    mappedFieldName: key,
  }).createSkeletonRows(rowCount);

  handleNewEntries({ newEntries: skeletonRows, key });
}

/**
 * Function to mutate provided key in the opposite of provided key with
 * 'jobPlanningLine' to 'activity' and vise versa
 * @param key Name from LookUp field with @type {LookUpActivityOptions | LookUpOptions}
 * @returns Mutated key in the opposite of provided type
 */
export const mutateLookUpKey = ({
  key,
}: {
  key: LookUpOptions | LookUpActivityOptions;
}): LookUpActivityOptions | LookUpOptions => {
  if (key === LookUpOptions.JOBPLANNINGLINE) {
    const mutatedActivityKey: LookUpActivityOptions =
      LookUpActivityOptions.ACTIVITY;
    return mutatedActivityKey;
  } else if (key === LookUpActivityOptions.ACTIVITY) {
    const mutatedKey: LookUpOptions = LookUpOptions.JOBPLANNINGLINE;
    return mutatedKey;
  } else {
    return key;
  }
};

/**
 * Function to set all parameters for Customer and Job
 * after last API call is finished
 * => This get's only called, when input was set and field is blurred
 * during API call(s)
 * @param projectingInput Current ValidatedRecordTask Object
 * @param validateObjectCopy Last ValidatedRecordTask Object
 * @param setFocus CallbackFn from React-Use-Form Context
 * @param mappingObject State object from Editor
 * @param filterIsActive Boolean if filter is active
 * @param setValue Debounce State setter
 * @param dispatch Dispatch hook from redux toolkit
 * @returns Final validation object to set state
 */
export async function handlePendingCustomer<T>({
  projectingInput,
  setFocus,
  mappingObject,
  setValue,
}: {
  projectingInput: ValidateRecordTask<T>;
  setFocus: UseFormSetFocus<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >;
  mappingObject: LookUpMapping;
  setValue: (value: string) => void;
  dispatch: AppDispatch;
}): Promise<ValidateRecordTask<T>> {
  if (projectingInput.pending.blurred !== null) {
    const idCustomer = errorCheck({
      columns: customerController.createColumns(),
      entries: projectingInput.entries.customer,
      rawInput: projectingInput.pending.blurred.inputValue,
      valueId: "",
    });
    if (idCustomer !== null) {
      const findValue = projectingInput.entries.customer.find(
        (x) => x.id === idCustomer
      );
      const mockedValue: Customer | null = findValue
        ? { ...(findValue.cells as any), id: findValue.id }
        : null;

      const mockTimeInput = { customer: { ...mockedValue } };
      const { id, entry } = InitialData.getCustomer<T>(
        mockTimeInput as RecordEntry
      );
      projectingInput.ids.customer = id;
      projectingInput.entries.customer = entry;

      // Delete Job if Customer is different
      if (mappingObject.customer && mappingObject.customer.id !== idCustomer) {
        await clearDependingFields({
          deleteCustomer: false,
          validateObject: projectingInput,
        });
      }
      // Set filterForJob
      projectingInput.odataFilter.filterForCustomer = {
        customer: {
          id: { eq: { type: "guid", value: idCustomer } },
        },
      };

      // Fill Job if only one was found
      if (
        mockedValue !== null &&
        mockedValue.jobs &&
        mockedValue.jobs.length === 1
      ) {
        const mockTimeInput = { job: { ...mockedValue.jobs[0] } };
        const { id, entry } = InitialData.getJob<T>(
          mockTimeInput as RecordEntry
        );
        projectingInput.ids.job = id;
        projectingInput.entries.job = entry;
        // Set filter for JobTask
        projectingInput.odataFilter.filterForJob = {
          job: { id: { eq: { type: "guid", value: id } } },
        };
        handleFocusField(setFocus, "jobTask", 20);
      } else {
        handleFocusField(setFocus, "job", 160);
      }
      // Execute blur
      projectingInput.pending.blurred &&
        projectingInput.pending.blurred.blurFn();
    }
    return handleResetPending({ projectingInput, setValue });
  }
  return projectingInput;
}

/**
 * Function to set all parameters for Job and Customer
 * after last API call is finished
 * => This get's only called, when input was set and field is blurred
 * during API call(s)
 * @param projectingInput Current ValidatedRecordTask Object
 * @param validateObjectCopy Last ValidatedRecordTask Object
 * @param setFocus CallbackFn from React-Use-Form Context
 * @param mappingObject State object from Editor
 * @param setValidateObject UseState setter for business logic
 * @param setValue Debounce State setter
 * @param dispatch Dispatch hook from redux toolkit
 * @returns Final validation object to set state
 */
export async function handlePendingJob<T>({
  projectingInput,
  validateObjectCopy,
  setFocus,
  allowDeleteCustomer,
  mappingObject,
  setValue,
  dispatch,
}: {
  projectingInput: ValidateRecordTask<T>;
  validateObjectCopy: ValidateRecordTask<T>;
  setFocus: UseFormSetFocus<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >;
  allowDeleteCustomer: boolean;
  mappingObject: LookUpMapping;
  setValue: (value: string) => void;
  dispatch: AppDispatch;
}): Promise<ValidateRecordTask<T>> {
  if (projectingInput.pending.blurred !== null) {
    const idJob = errorCheck({
      columns: jobController.createColumns(),
      entries: projectingInput.entries.job,
      rawInput: projectingInput.pending.blurred.inputValue,
      valueId: "",
    });
    if (idJob !== null) {
      const findValue = projectingInput.entries.job.find((x) => x.id === idJob);
      const mockedValue: PopulatedJob | null = findValue
        ? { ...(findValue.cells as any), id: findValue.id }
        : null;

      const mockTimeInput = { job: { ...mockedValue } };
      const { id, entry } = InitialData.getJob<T>(mockTimeInput as RecordEntry);
      projectingInput.ids.job = id;
      projectingInput.entries.job = entry;
      // Fill Customer
      if (
        mockedValue !== null &&
        projectingInput.entries.customer.length === 0
      ) {
        const mockTimeInput = { customer: { ...mockedValue.customer } };
        const { id, entry } = InitialData.getCustomer<T>(
          mockTimeInput as RecordEntry
        );
        projectingInput.ids.customer = id;
        projectingInput.entries.customer = entry;
      }
      await jobSelected<T>({
        jobData: mockedValue,
        onChange: projectingInput.pending.blurred.onChangeFn,
        validateObject: validateObjectCopy,
        setFocus,
        allowDeleteCustomer,
        mappingObject,
        dispatch,
      });

      // Set filter for JobTask
      projectingInput.odataFilter.filterForJob = {
        job: { id: { eq: { type: "guid", value: id } } },
      };
      // Execute onChange
      if (mockedValue && projectingInput.pending.blurred) {
        const obj = {
          id: mockedValue.id,
          description: mockedValue.description,
          no: mockedValue.no,
        };
        projectingInput.pending.blurred.onChangeFn(obj);
      }
      // Execute blur
      projectingInput.pending.blurred &&
        projectingInput.pending.blurred.blurFn();
    }
    return handleResetPending<T>({ projectingInput, setValue });
  }
  return projectingInput;
}

/**
 * Function to set all parameters for JobTask, Job and Customer
 * after last API call is finished
 * => This get's only called, when input was set and field is blurred
 * during API call(s)
 * @param projectingInput Current ValidatedRecordTask Object
 * @param validateObjectCopy Last ValidatedRecordTask Object
 * @param setFocus CallbackFn from React-Use-Form Context
 * @param filterIsActive Boolean if filter is active
 * @param mappingObject State object from Editor
 * @param setValue Debounce State setter
 * @param dispatch Dispatch hook from redux toolkit
 * @returns Final validation object to set state
 */
export async function handlePendingJobTask<T>({
  projectingInput,
  validateObjectCopy,
  setFocus,
  filterIsActive,
  mappingObject,
  setValue,
  dispatch,
}: {
  projectingInput: ValidateRecordTask<T>;
  validateObjectCopy: ValidateRecordTask<T>;
  setFocus: UseFormSetFocus<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >;
  filterIsActive: boolean;
  mappingObject: LookUpMapping;
  setValue: (value: string) => void;
  dispatch: AppDispatch;
}): Promise<ValidateRecordTask<T>> {
  if (projectingInput.pending.blurred !== null) {
    projectingInput.infos.jobTaskLoading = true;

    // Filter to JobTasks with status is open
    const filteredStatus = projectingInput.entries.jobTask.filter(
      (entry: any) => entry.cells.status === JobTaskStatusOptions.OPEN
    );
    const idJobTask = errorCheck({
      columns: jobTaskController.createColumns(),
      entries: filteredStatus,
      rawInput: projectingInput.pending.blurred.inputValue,
      valueId: "",
    });
    if (idJobTask === null) {
      projectingInput.pending.blurred.onChangeFn({
        id: "Error",
        description: null,
      });
      projectingInput.ids.jobTask = "Error";
      projectingInput.entries.jobTask = [];
      handleFocusField(setFocus, "jobTask", 10);
      projectingInput.infos.jobTaskLoading = false;
      return projectingInput;
    } else {
      const findValue = projectingInput.entries.jobTask.find(
        (x) => x.id === idJobTask
      );
      const mockedValue: PopulatedJobTask | null = findValue
        ? { ...(findValue.cells as any), id: findValue.id }
        : null;

      // Fill Customer
      if (
        projectingInput.entries.job.length === 0 &&
        mockedValue &&
        mockedValue.job !== null
      ) {
        if (mockedValue !== null && mockedValue.job !== null) {
          // Get customer
          await getCustomerInformation<T>({
            jobData: mockedValue.job,
            validateObject: projectingInput,
            mappingObject,
            filterIsActive,
            dispatch,
            setFocus,
          });
        }
      }

      await jobTaskSelected<T>({
        jobTaskData: mockedValue,
        onChange: projectingInput.pending.blurred.onChangeFn,
        validateObject: validateObjectCopy,
        setFocus,
        filterIsActive,
        mappingObject,
        dispatch,
      });

      // Fill jobTask
      const mockTimeInputJobTask = { jobTask: { ...mockedValue } };
      const { id, entry } = InitialData.getJobTask<T>(
        mockTimeInputJobTask as RecordEntry
      );

      projectingInput.ids.jobTask = id;
      projectingInput.entries.jobTask = entry;

      // Fill job
      if (
        projectingInput.entries.job.length === 0 &&
        mockedValue &&
        mockedValue.job !== null
      ) {
        const mockTimeInputJob = { job: { ...mockedValue.job } };
        const { id, entry } = InitialData.getJob<T>(
          mockTimeInputJob as RecordEntry
        );
        projectingInput.ids.job = id;
        projectingInput.entries.job = entry;
      }
      // Execute onChange
      if (mockedValue && projectingInput.pending.blurred) {
        const obj = {
          id: mockedValue.id,
          description: mockedValue.description,
          no: mockedValue.no,
          job: mockedValue.job,
          status: mockedValue.status,
        };
        projectingInput.pending.blurred.onChangeFn(obj);
      }
      // Execute blur
      projectingInput.pending.blurred &&
        projectingInput.pending.blurred.blurFn();
    }
    projectingInput.infos.jobTaskLoading = false;
    return handleResetPending({ projectingInput, setValue });
  }
  return projectingInput;
}

/**
 * Function to set all parameters for Activity and JobTask
 * after last API call is finished
 * => This get's only called, when input was set and field is blurred
 * during API call(s)
 * @param projectingInput Current ValidatedRecordTask Object
 * @param validateObjectCopy Last ValidatedRecordTask Object
 * @param setFocus CallbackFn from React-Use-Form Context
 * @param mappingObject State object from Editor
 * @param setValidateObject UseState setter for business logic
 * @param setValue Debounce State setter
 * @returns Final validation object to set state
 */
export async function handlePendingActivity<T>({
  projectingInput,
  setValue,
}: {
  projectingInput: ValidateRecordTask<T>;
  setValue: (value: string) => void;
}): Promise<ValidateRecordTask<T>> {
  if (projectingInput.pending.blurred !== null) {
    projectingInput.infos.activityLoading = true;

    // Filter to JobTasks with status is open
    const filteredStatus = projectingInput.entries.activity.filter(
      (entry: any) => entry.cells.jobTask?.status === JobTaskStatusOptions.OPEN
    );
    const idActivity = errorCheck({
      columns: activityController.createColumns(),
      entries: filteredStatus,
      rawInput: projectingInput.pending.blurred.inputValue,
      valueId: "",
    });
    if (idActivity === null) {
      projectingInput.pending.blurred.onChangeFn({
        id: "Error",
        description: null,
        serviceBillingType: null,
        tntModelLine: {
          tntId: null,
          description: null,
          billable: false,
          nonBillableReason: NonBillableReasonOptions.NONE,
          rounding: 1,
        },
      });
      projectingInput.ids.activity = "Error";
      projectingInput.entries.activity = [];
      projectingInput.infos.activityLoading = false;

      return projectingInput;
    } else {
      const findValue = projectingInput.entries.activity.find(
        (x) => x.id === idActivity
      );
      const mockedValue: PopulatedJobPlanningLine | null = findValue
        ? { ...(findValue.cells as any), id: findValue.id }
        : null;

      // Fill jobTask
      if (
        projectingInput.entries.jobTask.length === 0 &&
        mockedValue !== null &&
        mockedValue.jobTask !== null
      ) {
        const mockTimeInputJobTask = { jobTask: { ...mockedValue.jobTask } };
        const { id, entry } = InitialData.getJobTask<T>(
          mockTimeInputJobTask as RecordEntry
        );

        projectingInput.ids.jobTask = id;
        projectingInput.entries.jobTask = entry;
        if (projectingInput.ids.jobTask.length > 0) {
          projectingInput.forceChange.jobTaskFilledFromActivity = true;
        }
      }

      // Fill activity
      if (mockedValue !== null) {
        const mockTimeInputJob = { jobPlanningLine: { ...mockedValue } };
        const { id, entry } = InitialData.getActivity<T>(
          mockTimeInputJob as RecordEntry
        );
        projectingInput.ids.activity = id;
        projectingInput.entries.activity = entry;
      }

      // Execute blur
      projectingInput.pending.blurred &&
        projectingInput.pending.blurred.blurFn();
    }
    projectingInput.infos.activityLoading = false;
    return handleResetPending({ projectingInput, setValue });
  }
  return projectingInput;
}

/**
 * Function to reset pending props after everything is filled correct
 */
export function handleResetPending<T>({
  projectingInput,
  setValue,
}: {
  projectingInput: ValidateRecordTask<T>;
  setValue?: (value: string) => void;
}): ValidateRecordTask<T> {
  projectingInput.pending.field = null;
  projectingInput.pending.isLoading = false;
  projectingInput.pending.pendingApiCalls = 0;
  projectingInput.pending.blurred = null;
  setValue && setValue("");
  return projectingInput;
}

/**
 * Function to filter visible Datagrid entries by jobTask status
 * @param ids Array of all ids from datagrid rows
 * @param values entries from Datagrid
 * @param fieldName Name for field that should be rendered
 * @returns Filtered array of entries where jobTask status is open
 */
export const handleRowFactoryFilter = ({
  ids,
  values,
  fieldName,
}: {
  ids: string[];
  values: any[];
  fieldName: LookUpOptions.JOBTASK | LookUpOptions.JOBPLANNINGLINE;
}): IDatagridRow<any>[] => {
  const filteredRows: IDatagridRow<any>[] = [];
  for (let i = 0; i < values.length; i++) {
    if (fieldName === "jobTask") {
      if (values[i].status === JobTaskStatusOptions.OPEN) {
        filteredRows.push({
          id: ids[i],
          ...values[i],
        });
      }
    } else {
      if (
        values[i].jobTask &&
        values[i].jobTask.status === JobTaskStatusOptions.OPEN
      ) {
        filteredRows.push({
          id: ids[i],
          ...values[i],
        });
      }
    }
  }
  return filteredRows;
};

/**
 * Function to filter datagrid entries by jobTask status
 * @param values Datagrid values
 * @param fieldName Name for field that should be rendered
 * @returns Datagrid entry
 */
export function handelFindEntryByValueFilter<T>({
  values,
  id,
  fieldName,
  inputValue,
}: {
  values: IDatagridRow<T>[];
  id: string | undefined;
  fieldName: LookUpOptions.JOBTASK | LookUpOptions.JOBPLANNINGLINE;
  inputValue: string;
}): IDatagridRow<T> | null {
  let foundEntry: IDatagridRow<T> | null = null;
  const filteredValues = values.filter((x: IDatagridRow<any>) =>
    fieldName === "jobPlanningLine"
      ? x.cells.jobTask && x.cells.jobTask.status === JobTaskStatusOptions.OPEN
      : x.cells.status === JobTaskStatusOptions.OPEN
  );

  if (filteredValues.length > 0) {
    if (id && id.length > 0) {
      foundEntry =
        filteredValues.find((x: IDatagridRow<any>) => x.id === id) || null;
    } else {
      foundEntry =
        filteredValues.find((x: IDatagridRow<any>) =>
          x.cells.description.toLowerCase().includes(inputValue.toLowerCase())
        ) || null;
    }
  }

  return foundEntry;
}

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

/**
 * Function to handle budget units, calculate hours to days and add suffix
 * @param param0 See props
 * @returns Value with or without suffix e.g. 30 d or 30
 */
export const handleBudgetUnits = ({
  value,
  dayHourSwitch,
  unitSuffix,
}: {
  /**
   * Value to calculate from budget or spentBudget
   */
  value: number;
  /**
   * Switch to show suffix in spent- and fullBudget
   */
  dayHourSwitch: boolean;
  /**
   * Switch to show suffix at fullBudget
   */
  unitSuffix?: boolean;
}): string => {
  if (value >= 8) {
    const unit = value / 8;
    let unitSnipped = unit.toString();
    if (unit % 1 !== 0) {
      unitSnipped = unit.toFixed(1);
    }
    if (unitSuffix) {
      return `${unitSnipped} d`;
    } else {
      return `${unitSnipped}${dayHourSwitch ? " d" : ""}`;
    }
  } else {
    if (value <= -8) {
      const unit = value / 8;
      let unitSnipped = unit.toString();
      if (unit % 1 !== 0) {
        unitSnipped = unit.toFixed(1);
      }
      if (unitSuffix) {
        return `${unitSnipped} d`;
      } else {
        return `${unitSnipped}${dayHourSwitch ? " d" : ""}`;
      }
    } else {
      let valueSnipped = value.toString();
      if (value % 1 !== 0) {
        valueSnipped = value.toFixed(1);
      }
      if (!unitSuffix) {
        // Rest budget position
        return `${valueSnipped}${dayHourSwitch ? " h" : ""}`;
      } else {
        return `${valueSnipped} h`;
      }
    }
  }
};

/**
 * Function to calculate length of restBudgetBar in px based on budget and restBudget
 * @param param0 See props
 * @returns Length of restBudgetBar in px as string
 */
export const handleSpentBarLength = ({
  used,
  total,
  budgetBarLength,
  newBudgetBarLength,
  overSpent = false,
}: {
  /**
   * RestBudget in hours
   */
  used: number;
  /**
   * Full budget for project in hours
   */
  total: number;
  /**
   * Length of budgetBar in px (e.g. 132px)
   */
  budgetBarLength: string;
  /**
   * Length of budgetBar in px (e.g. 100px | 200px) => from horizontal
   */
  newBudgetBarLength?: string;
  /**
   * Boolean if project is overSpent
   * @default false
   */
  overSpent?: boolean;
}): string => {
  if (total === 0 && used === 0) {
    return "0px";
  }

  const budgetBarLengthNumber = parseInt(
    newBudgetBarLength ? newBudgetBarLength : budgetBarLength
  ); // 100 | 200 | 132
  const budgetUnitNumber = total; // Full budget for project in hours

  if (total <= 0) {
    return `${budgetBarLengthNumber - 2}px`;
  }

  let usedBarHeight =
    Math.round((budgetBarLengthNumber / budgetUnitNumber) * used) - 2;

  let overSpentBarHeight = 0;
  if (overSpent) {
    overSpentBarHeight =
      Math.round((budgetBarLengthNumber / budgetUnitNumber) * used) -
      budgetBarLengthNumber;
  }

  if (usedBarHeight <= 0 && used > 0) {
    usedBarHeight = 1;
  }

  return `${Math.min(
    Math.max(0, overSpent ? overSpentBarHeight : usedBarHeight),
    budgetBarLengthNumber - 2
  )}px`;
};

// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------
// -----------------------------------------------------------------------------

/**
 * Function to provide Option label for Autocomplete field in correct language
 * @param key Value from selected option at Autocomplete field
 * @param t Translation function
 * @returns String from selected option in correct language
 */
export const getTitle = (key: string) => {
  switch (key) {
    case NonBillableReasonOptions.NONE:
      return t("timerec.dailyRecording.add.nonbillable.none");
    case NonBillableReasonOptions.INTERNAL:
      return t("timerec.dailyRecording.add.nonbillable.internal");
    case NonBillableReasonOptions.GOODWILL:
      return t("timerec.dailyRecording.add.nonbillable.goodwill");
    case NonBillableReasonOptions.WARRANTY:
      return t("timerec.dailyRecording.add.nonbillable.warranty");

    default:
      return t("timerec.dailyRecording.add.nonbillable.none");
  }
};

/**
 * Handle which backgroundColor each container is shown
 * @param theme Material-UI Theme
 * @param active Boolean if current container is selected or not
 * @returns Color hash for styling as string
 */
export const handleBackgroundColor = ({
  theme,
  active,
  borderActive,
}: {
  theme: DyceTheme;
  active: boolean;
  borderActive: boolean;
}) => {
  let background = "#ffffff";
  if (borderActive) {
    background = theme.palette.background.default;
  } else {
    if (theme.palette.mode === "light") {
      if (active) {
        background = "#ffffff";
      } else {
        background = "#f7f7f7";
      }
    } else {
      if (active) {
        background = "#292929";
      } else {
        background = "#303030";
      }
    }
  }
  return background;
};
