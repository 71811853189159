import { FunctionComponent } from "react";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { useStaticContent } from "../static-provider/static-provider";
import { MobileInfo } from "../static-provider/types";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Grid, Paper, useTheme } from "@mui/material";

const useStyle = makeStyles(() =>
  createStyles({
    grid: {
      paddingBottom: 40,
      paddingTop: "1.5rem",
      height: "100%",
      width: "100%",
      paddingLeft: "1.5rem",
    },
    container: {
      padding: "1rem 2rem",
    },
  })
);

interface IPlotContainerProps {
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

/**
 * Component to render children in a MUI-Grid with MUI-Paper element, adjusting
 * to darkMode DYCE-design
 */
export const PlotContainer: FunctionComponent<IPlotContainerProps> = ({
  children,
}) => {
  const classes = useStyle();
  const theme = useTheme<DyceTheme>();
  const {
    isMobile: { tablet },
  } = useStaticContent();

  return (
    <div className={classes.grid}>
      <Grid container direction="column" alignItems="stretch" spacing={3}>
        <Paper
          sx={
            tablet
              ? {
                  [theme.breakpoints.down(1200)]: {
                    height: 520,
                  },
                  [theme.breakpoints.down(1190)]: {
                    height: 465,
                  },
                }
              : {}
          }
          variant={theme.palette.propsDyce.paperDesign.variant}
          elevation={theme.palette.propsDyce.paperDesign.elevation}
          className={classes.container}
        >
          {children}
        </Paper>
      </Grid>
    </div>
  );
};
