/**
 * Converts a string array to a string divided by a divider (default: "|")
 * @returns String with divider
 */
export const stringArrayToString = ({
  value,
  divider = "|",
  wrapTextAtLength,
}: {
  /**
   * String array to convert
   */
  value: string[];
  /**
   * Divider to use
   * @default "|"
   */
  divider?: string;
  /**
   * Wrap text at length and add "..." at the end
   * e.g.: 10 means "1234567890ABCDE" -> "1234567890..."
   */
  wrapTextAtLength?: number;
}) => {
  return value
    .filter((x) => x.length > 0)
    .map((x) => {
      if (wrapTextAtLength && x.length > wrapTextAtLength + 3) {
        return x.substring(0, wrapTextAtLength) + "...";
      } else {
        return x;
      }
    })
    .join(` ${divider ? divider : "|"} `);
};

/**
 * Function to extract a number from a string
 * @param str String to extract number from, e.g. "123abc" || "2px solid #000"
 * @returns Number extracted from string, e.g. 123 || 2
 */
export const extractNumberFromString = (str: string) => {
  // Use a regular expression to extract the number
  const numberMatch = str.match(/\d+/);

  if (numberMatch) {
    // If a match is found, use parseInt to convert it into an integer and return it
    return parseInt(numberMatch[0], 10);
  } else {
    // Else return null
    return 0;
  }
};
