import React, { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "row",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: "lightgrey",
      width: "300px",
      height: "50px",
    },
    barContent: {
      display: "flex",
      flexDirection: "column",
      minWidth: "116px",
    },
    timeBar: {
      height: "6px",
      margin: "0.5rem",
      transition: "width 0.5s cubic-bezier(0.25, 0.8, 0.25, 1)",
    },
    textContent: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      flexGrow: 1,
      justifyContent: "space-between",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    text: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
    },
  })
);

interface ITimeSummaryProps {
  /**
   * Summary of total minutes from all timerecordings
   */
  summary: number;
  /**
   * Summary of total hours from all timerecordings
   */
  summaryHours: string;
  /**
   * Total estimated hours from Workitem
   */
  estimatedHours: string;
  /**
   * Total estimated hours from Workitem unformatted
   */
  estimated: number;
  /**
   * If true, colors from bars getting adjusted to darkmode
   */
  darkMode: boolean;
}

export const TimeSummary: FunctionComponent<ITimeSummaryProps> = ({
  summary,
  summaryHours,
  estimated,
  estimatedHours,
  darkMode,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  // Handler
  const handleTotalWidth = (): string => {
    const preEstimated = estimated === 0 ? 100 : estimated;
    const totalWidth = Math.round((100 / preEstimated) * summary);

    if (summary > estimated) {
      return "100px";
    } else if (totalWidth > 0.5) {
      return `${totalWidth}px`;
    } else {
      return "1px";
    }
  };

  const handleEstimatedWidth = (): string => {
    const estimatedWidth = Math.round((100 / summary) * estimated);

    if (summary > estimated) {
      return `${estimatedWidth}px`;
    } else if (estimatedWidth < 0.6) {
      return "1px";
    } else if (estimated === 0) {
      return "1px";
    } else {
      return "100px";
    }
  };

  return (
    <div className={classes.content}>
      <div className={classes.barContent}>
        <div
          className={classes.timeBar}
          style={{
            width: handleEstimatedWidth(),
            backgroundColor: `rgba(0, 20, 255, ${darkMode ? "0.8" : "1"})`,
          }}
        />
        <div
          className={classes.timeBar}
          style={{
            width: handleTotalWidth(),
            backgroundColor: `rgba(25, 152, 114, ${darkMode ? "0.8" : "1"})`,
          }}
        />
      </div>
      <div className={classes.textContent}>
        <div className={classes.text}>
          <Typography>
            {t(
              "devops.workitemContent.timetracking.tabBar.additionalContent.estimated"
            )}
          </Typography>
          <Typography>{estimatedHours}</Typography>
        </div>
        <div className={classes.text}>
          <Typography>
            {t(
              "devops.workitemContent.timetracking.tabBar.additionalContent.total"
            )}
          </Typography>
          <Typography>{summaryHours}</Typography>
        </div>
      </div>
    </div>
  );
};
