import { FunctionComponent, ReactNode } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { useTooltipContent } from "./tooltip-context";
import { SetupLanguages } from "@dyce/slices";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Typography } from "@mui/material";
// Jira
import JiraTooltip from "@atlaskit/tooltip";
import { TriggerProps } from "@atlaskit/tooltip/dist/types/types";
import { Placement } from "@atlaskit/popper";
import { token } from "@atlaskit/tokens";
import { colors } from "@atlaskit/theme";

const useStyle = makeStyles(() =>
  createStyles({
    title: {
      fontSize: 8,
      padding: 0,
      whiteSpace: "pre-line", // with \n in translation files line get break
    },
    link: {
      fontSize: 10,
      padding: 0,
      textDecoration: "none",
      fontStyle: "italic",
    },
  })
);

interface ITooltipProps {
  /**
   * Content for tooltip
   */
  content: string;
  /**
   * Provide url link substring from /static/doc/docuLinks.json
   */
  urlPath?: Record<SetupLanguages, string> | undefined;
  /**
   * Children to wrap with tooltip
   */
  children: (tooltipProps: TriggerProps) => ReactNode;
  /**
   * Hide tooltip on click
   */
  hideTooltipOnClick?: boolean;
  /**
   * Position of tooltip
   * @default "bottom"
   */
  position?: Placement | "mouse";
  /**
   * Show only one line of text, and truncate when too long
   * @default false
   */
  truncate?: boolean;
  /**
   * TestId for tooltip
   */
  testId?: string;
}

export const Tooltip: FunctionComponent<ITooltipProps> = ({
  content,
  urlPath,
  children,
  hideTooltipOnClick = false,
  position = "bottom",
  truncate = false,
  testId,
}) => {
  const classes = useStyle();
  const { t } = useTranslation();
  const { baseUrl, language = "en" } = useTooltipContent();

  // English has no subfolder in url path
  const urlLanguage = language === "en" ? "" : `${language}/`;
  const url = urlPath
    ? `https://docs.dyce.cloud/${urlLanguage}docs/${baseUrl}${urlPath[language]}`
    : null;

  return (
    <JiraTooltip
      testId={testId}
      position={position}
      content={
        <>
          {content}
          {url && (
            <>
              <br />
              <Typography className={classes.link} variant="caption">
                <a
                  target="_blank"
                  className={classes.link}
                  style={{
                    color: token("color.text.inverse", colors.N0),
                  }}
                  href={url}
                  rel="noreferrer"
                >
                  {t("tooltip.readMore")}
                </a>
              </Typography>
            </>
          )}
        </>
      }
      hideTooltipOnClick={hideTooltipOnClick}
      truncate={truncate}
    >
      {(tooltipProps) => children(tooltipProps)}
    </JiraTooltip>
  );
};
