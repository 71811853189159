import React, { FunctionComponent } from "react";
// Types
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import { Paper, Typography, Button } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      width: "100%",
      height: "100%",
    },
    header: {
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "20px 15px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    headerRow: {
      display: "flex",
      alignItems: "center",
    },
    adornmentBorder: {
      margin: "0 10px",
    },
  })
);

interface IAdminCardProps {
  /**
   * Card title displayed above
   */
  title: string;
  /**
   * Element display next to the title
   */
  titleAdornment?: () => React.ReactElement;
  /**
   * Icon for the right side action button
   */
  actionButtonIcon?: React.ReactNode;
  /**
   * Action button text
   */
  actionButtonText?: string;
  /**
   * Action button action
   */
  onActionButton?: () => void;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const AdminCard: FunctionComponent<IAdminCardProps> = ({
  title,
  titleAdornment,
  actionButtonIcon,
  actionButtonText,
  onActionButton,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme<DyceTheme>();

  return (
    <Paper
      elevation={theme.palette.propsDyce.paperDesign.elevation}
      variant={theme.palette.propsDyce.paperDesign.variant}
      className={classes.paper}
    >
      <div className={classes.header}>
        <div className={classes.headerRow}>
          <Typography variant="h6" data-testid="header">
            {title}
          </Typography>
          <div className={classes.adornmentBorder}>
            {titleAdornment && titleAdornment()}
          </div>
        </div>
        {onActionButton && (
          <Button
            startIcon={actionButtonIcon}
            onClick={() => onActionButton()}
            variant="contained"
          >
            {actionButtonText}
          </Button>
        )}
      </div>
      <>{children}</>
    </Paper>
  );
};
