import { FunctionComponent } from "react";
// Helper
import { handleBackgroundColor } from "./utils";
// DYCE-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Box, useTheme } from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "flex",
      borderRadius: theme.shape.borderRadius,
    },
  })
);

interface IContainerWrapperProps {
  /**
   * Is component currently active (name)
   */
  active: boolean;
  /**
   * Name from current container
   */
  name: string;
  /**
   * Callback fired on focus (including children)
   */
  handleFocus?: (name: string) => void;
  /**
   * Width for individual wrapper to calculate flex-wrapping
   * @default '100%'
   */
  width?: string;
  /**
   * Rows to calculate wrapper height and flex-wrapping
   * @default 1
   */
  rows?: 1 | 2;
  /**
   * Height for textfield
   * @default 57
   */
  textFieldHeight?: number;
  /**
   * Size for textfield
   */
  size?: "small" | "medium";
  /**
   * If the border is set, background color is set to theme.palette.background.default
   */
  borderActive?: boolean;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const FieldsWrapper: FunctionComponent<IContainerWrapperProps> = ({
  active,
  name,
  handleFocus,
  width = "100%",
  rows = 1,
  size,
  borderActive = false,
  textFieldHeight = 57,
  children,
}) => {
  const theme = useTheme<DyceTheme>();
  const classes = useStyles();

  return (
    <Box
      className={classes.container}
      onFocus={() => handleFocus && handleFocus(name)}
      sx={{
        background: handleBackgroundColor({ theme, active, borderActive }),
        height: `calc(${textFieldHeight}px * ${rows} + 32px + ${
          (rows - 1) * (size === "small" ? 12 : 16)
        }px)`,
        maxHeight: `calc(${textFieldHeight}px * ${rows} + 32px + ${
          (rows - 1) * (size === "small" ? 12 : 16)
        }px + 1px)`,
        width: width,
        minWidth: width.includes("%") ? "" : width,
        rowGap: size === "small" ? "0.75rem" : "1rem",
        columnGap: size === "small" ? "0.5rem" : "1rem",
        padding: size === "small" ? "1rem 0.5rem" : "1rem",
        flexWrap: rows > 1 ? "wrap" : "nowrap",
      }}
    >
      {children}
    </Box>
  );
};
