import { useEffect, useState, Suspense, FunctionComponent } from "react";
import Routes from "./Routes";
import { useTranslation } from "react-i18next";
// Helper
import LuxonUtils from "@date-io/luxon";
import {
  Switch,
  Route,
  BrowserRouter as Router,
  useHistory,
  useLocation,
} from "react-router-dom";
import { SnackbarProvider } from "notistack";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import moment from "moment";
// Material-UI
import { Box, CircularProgress, CssBaseline, useTheme } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import { LocalizationProvider as MuiPickerLocalizationProvider } from "@mui/x-date-pickers";
// Dyce-Lib
import { useUpdateEffect } from "@dyce/hooks";
import { DyceTheme, ThemeWrapper } from "@dyce/theme";
import {
  BottomNavigation,
  ConsentBanner,
  LoadingSpinner,
  Navbar,
  StaticProvider,
  TooltipProvider,
  useStaticContent,
} from "@dyce/ui";
import { ErrorBoundary, ErrorMessage } from "@dyce/ui";
import { useAbility } from "@dyce/auth";
import { Instance, RecordTimeRec } from "@dyce/tnt-api";
import {
  createRec,
  selectDarkMode,
  getCurrentUser,
  selectCurrentUser,
  getInstances,
  selectLanguageCode,
  selectSideDrawerLeft,
  getStopwatch,
  loadFromLocalStorage,
  selectCurrentWorkspace,
  selectInstanceError,
  selectInstances,
  selectIsEnvironmentLoaded,
  selectStopwatch,
  selectUserValidated,
  setCompany,
  setInstance,
  setLanguage,
  startStopwatch,
  stopStopwatch,
  toggleUserMenu,
  useAppDispatch,
  userMenuSelector,
  useAppSelector,
  selectLocaleHyphen,
  setAllowNetworkWatcher,
  selectAdminSettings,
  setTasksRule,
  getSettings,
  setResetCompanyMapping,
  setValidateInstance,
  selectInvalidGrantUserInfo,
  setInvalidGrantLogin,
  SetupLanguages,
} from "@dyce/slices";
// Components
import { FrontPage } from "./pages";
import * as Sentry from "@sentry/react";
import { Logout } from "./pages/logout";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      overflowY: "auto",
    },
    content: {
      flex: 1,
      width: "100%",
    },
    fallback: {
      display: "flex",
      height: "100vh",
      width: "100vw",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const App: FunctionComponent = () => {
  // Selectors
  const language = useAppSelector(selectLanguageCode);

  return (
    <StaticProvider language={language}>
      <AppStyleWrapper language={language} />
    </StaticProvider>
  );
};

const AppStyleWrapper: FunctionComponent<{ language: SetupLanguages }> = ({
  language,
}) => {
  // Selectors
  const darkMode = useAppSelector(selectDarkMode);
  const sideDrawerLeft = useAppSelector(selectSideDrawerLeft);
  const localeHyphen = useAppSelector(selectLocaleHyphen);
  const {
    isMobile: { mobile },
  } = useStaticContent();

  return (
    <ThemeWrapper
      darkMode={darkMode}
      sideDrawerLeft={sideDrawerLeft}
      locale={localeHyphen}
      isMobile={mobile}
    >
      <CssBaseline />
      <MuiPickerLocalizationProvider
        dateAdapter={LuxonUtils}
        adapterLocale={language}
      >
        <AppStaticContent language={language} localeHyphen={localeHyphen} />
      </MuiPickerLocalizationProvider>
    </ThemeWrapper>
  );
};

const AppStaticContent: FunctionComponent<{
  language: SetupLanguages;
  localeHyphen: string;
}> = ({ language, localeHyphen }) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const { updateUser } = useAbility();
  const theme = useTheme<DyceTheme>();
  const history = useHistory();
  const location = useLocation();
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const {
    docuLinks,
    routes,
    isMobile: { mobile },
  } = useStaticContent();

  const { i18n } = useTranslation("", { useSuspense: false });

  // Selectors
  const { instance: dyceInstance, company } = useAppSelector(
    selectCurrentWorkspace
  );
  const adminSettings = useAppSelector(selectAdminSettings);
  const instances = useAppSelector(selectInstances);
  const user = useAppSelector(selectCurrentUser); //user
  const isValidated = useAppSelector(selectUserValidated);
  const isEnvLoaded = useAppSelector(selectIsEnvironmentLoaded);
  const hasError = useAppSelector(selectInstanceError);
  const { visible } = useAppSelector(userMenuSelector);
  const invalidGrant = useAppSelector(selectInvalidGrantUserInfo);
  // Stopwatch
  const stopwatch = useAppSelector(selectStopwatch);

  // UseStates
  const [initLoading, setInitLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isValidated && user.id === "") {
      const filter = {
        expand: [
          "resource($expand=defaults($expand=calendar($expand=entries),workhourtemplate)), roles, stopwatch",
        ],
      };
      dispatch(getSettings());
      dispatch(getCurrentUser(filter)).then((res: any) => {
        if (
          res.type.endsWith("rejected") &&
          (res as any).payload.message &&
          (res as any).payload.message.includes("40")
        ) {
          sessionStorage.clear();
          window.location.href = "/?usernotfound=true";
        } else {
          Sentry.setUser(res.payload);
        }
      });
    }
  }, [isValidated, user, account]);

  useEffect(() => {
    dispatch(setTasksRule(adminSettings.tasks.assignment));
  }, [adminSettings.tasks.assignment]);

  useEffect(() => {
    if (hasError !== null) {
      history.push("/error");
    }
  }, [hasError]);

  useEffect(() => {
    // Put initial language once in redux
    // (might not be the best spot)
    if (!language && i18n.language) dispatch(setLanguage(i18n.language));
    dispatch(loadFromLocalStorage());
  }, []);

  useEffect(() => {
    moment.locale(localeHyphen);
  }, [localeHyphen]);

  useEffect(() => {
    // Get instances from User
    if (accounts.length > 0) {
      dispatch(getInstances()).then((res) => {
        if (
          res.type.endsWith("rejected") &&
          (res as any).payload.message &&
          (res as any).payload.message.includes("40")
        ) {
          const name = accounts[0].name ? accounts[0].name : "";
          localStorage.setItem(
            "userNotFound",
            JSON.stringify({ email: accounts[0].username, name: name })
          );
          sessionStorage.clear();
          window.location.href = "/?usernotfound=true";
        } else {
          authenticateSelectedInstance(res.payload);
        }
      });
    }
  }, [accounts]);

  useEffect(() => {
    // User-settings
    dispatch(loadFromLocalStorage());
  }, []);

  useEffect(() => {
    updateUser(user);
  }, [user]);

  useUpdateEffect(() => {
    if (isEnvLoaded) {
      setInitLoading(false);
      if (invalidGrant !== null && invalidGrant.invalidGrant === true) {
        dispatch(setInvalidGrantLogin(null));
      }
    }
  }, [isEnvLoaded]);

  // Handler
  const authenticateSelectedInstance = async (
    payload: Error | Instance[] | undefined
  ) => {
    if (payload && Array.isArray(payload)) {
      const resetStorage = () => {
        dispatch(setResetCompanyMapping());
        dispatch(setValidateInstance(false));
        setInitLoading(false);
      };

      const localInstance = await localStorage.getItem("instance");
      const localCompany = await localStorage.getItem("mandate");

      if (localInstance && localCompany) {
        const instanceFound = payload.find(
          (x: Instance) => x.name === localInstance
        );
        const companyFound = instanceFound?.companies.findIndex(
          (x) => x.name === localCompany
        );
        if (instanceFound && companyFound !== -1) {
          dispatch(
            setInstance({ instance: instanceFound, refreshUser: false })
          );
          dispatch(setCompany({ company: localCompany, refreshUser: false }));
        } else {
          resetStorage();
        }
      } else {
        resetStorage();
      }
    }
  };

  const handleStopwatchStart = (start: string, description: string) => {
    dispatch(startStopwatch({ start, description }));
  };

  const handleStopwatchStop = () => {
    dispatch(stopStopwatch());
  };

  const handleCreateStopwatchRecord = (record: RecordTimeRec) => {
    dispatch(
      createRec({
        ...record,
        start: record.start,
        end: record.end,
        duration: record.duration,
        durationBillable: record.durationBillable,
        nonBillableReason: record.nonBillableReason,
        description: record.description,
      } as RecordTimeRec)
    );
  };

  const handleFetchStopwatch = () => {
    dispatch(getStopwatch());
  };

  const handleCloseUsermenu = () => {
    dispatch(toggleUserMenu());
  };

  const handleMandantSelection = (instance: Instance, company: string) => {
    dispatch(setInstance({ instance, refreshUser: true }));
    dispatch(setCompany({ company, refreshUser: true }));
  };

  const handleFavicon = (faviconName: string): void => {
    if (document.getElementById("favicon")) {
      (document.getElementById("favicon") as any).href = faviconName;
    }
  };

  return (
    <>
      <SnackbarProvider maxSnack={3}>
        <TooltipProvider
          baseUrl={docuLinks.appAreas.timetracking.baseUrl}
          language={language}
        >
          <AuthenticatedTemplate>
            {mobile && <BottomNavigation />}
            <Suspense
              fallback={
                <div className={classes.fallback}>
                  <CircularProgress
                    style={{ marginRight: 20 }}
                    size={24}
                    color="primary"
                  />
                  Loading...
                </div>
              }
            >
              <Navbar
                isLoggedIn={true}
                location={location.pathname}
                stopwatch={{
                  start: handleStopwatchStart,
                  stop: handleStopwatchStop,
                  create: handleCreateStopwatchRecord,
                  fetch: handleFetchStopwatch,
                  data: {
                    start: stopwatch ? stopwatch.start : "",
                    description: stopwatch ? stopwatch.description : "",
                    userId: stopwatch ? stopwatch.userId : user.id,
                    running: stopwatch ? stopwatch.running : false,
                  },
                }}
                env={{
                  instance: dyceInstance ? dyceInstance : "",
                  company: company ? company : "",
                  instances: instances,
                }}
                onToggle={handleCloseUsermenu}
                toggleUsermenu={visible}
                currentUser={user}
                onSelection={handleMandantSelection}
                documentationUrl={docuLinks.appAreas.timetracking.baseUrl}
                handleChangeFavicon={handleFavicon}
                isMobile={mobile}
              />
            </Suspense>
            {/** id in Box is needed for <ScrollTop /> component! */}
            <Box
              className={classes.root}
              id="rootContainer"
              sx={
                location.pathname === "/" ||
                location.pathname.includes(routes.TNT_RECS + "/")
                  ? {
                      height: "calc(100vh - 64px)",
                      marginTop: "64px",
                      paddingBottom: mobile ? "50px" : "0px",
                      backgroundColor: theme.palette.background.default,
                      [theme.breakpoints.down("sm")]: {
                        height: "calc(100vh - 56px)",
                        marginTop: "56px",
                      },
                    }
                  : {
                      height: "calc(100vh - 64px - 6rem)",
                      marginTop: "calc(64px + 6rem)",
                      paddingBottom: mobile ? "50px" : "0px",
                      backgroundColor: theme.palette.background.default,
                      [theme.breakpoints.down("sm")]: {
                        height: "calc(100vh - 56px - 112px)",
                        marginTop: "calc(56px + 112px)",
                      },
                    }
              }
            >
              <ErrorBoundary
                user={user}
                instance={dyceInstance!}
                resource={user.resource!}
                onError={(_, id) => <ErrorMessage uuid={id || ""} />}
              >
                <main className={classes.content}>
                  <Suspense fallback="">
                    {initLoading ? (
                      <LoadingSpinner
                        allowNetworkWatcher={(value) =>
                          dispatch(setAllowNetworkWatcher(value))
                        }
                      />
                    ) : (
                      <>
                        <Routes />
                        <ConsentBanner />
                      </>
                    )}
                  </Suspense>
                </main>
              </ErrorBoundary>
            </Box>
          </AuthenticatedTemplate>
        </TooltipProvider>
      </SnackbarProvider>
      <UnauthenticatedTemplate>
        <Router>
          <Switch>
            <Route exact={true} path={routes.LOGOUT} component={Logout} />
            <Route path={routes.HOME}>
              <FrontPage invalidGrant={invalidGrant} />
            </Route>
          </Switch>
        </Router>
      </UnauthenticatedTemplate>
    </>
  );
};

export default App;
