// Helper
import _ from "lodash";
import { apiCallActivity, apiCallLookUp, handleResetPending } from "./utils";
import { UseFormSetFocus } from "react-hook-form";
import { handleFocusField } from "../utils";
import { Expand } from "odata-query";
import { InitialData } from "./initial-data";
// Dyce-Lib
import {
  Customer,
  JobTaskStatusOptions,
  PopulatedJob,
  PopulatedJobPlanningLine,
  PopulatedJobTask,
  Activity,
  NonBillableReasonOptions,
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
} from "@dyce/tnt-api";
import { AppDispatch } from "@dyce/slices";
import activityController from "../../look-up/tableDefinition/activityController";
import customerController from "../../look-up/tableDefinition/customerController";
import jobController from "../../look-up/tableDefinition/jobController";
import jobTaskController from "../../look-up/tableDefinition/jobTaskController";
import { ValidateRecordTask } from "../../types";
import {
  LookUpMapping,
  LookUpOptions,
  LookUpShortOptions,
} from "@dyce/interfaces";

/**
 * Function to react correct on customer user selection
 * (set Filter / fill out || delete Job ...)
 * => Callback function for onSelect prop
 * @param customerData IRows object from with Customer entries
 * @param onChange OnChange event from LookUp
 * @param validateObject State object for business logic
 * @param mappingObject State object from Editor
 * @param filterIsActive Boolean if filter is active
 * @param dispatch Dispatch hook from redux toolkit
 */
export async function customerSelected<T>({
  customerData,
  onChange,
  validateObject,
  setFocus,
  filterIsActive,
  allowDeleteCustomer,
  mappingObject,
  dispatch,
}: {
  customerData: Customer | null;
  onChange: (...event: any[]) => void;
  validateObject: ValidateRecordTask<T>;
  setFocus: UseFormSetFocus<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >;
  filterIsActive: boolean;
  allowDeleteCustomer: boolean;
  mappingObject?: LookUpMapping;
  dispatch?: AppDispatch;
}): Promise<ValidateRecordTask<T>> {
  if (customerData && mappingObject && dispatch) {
    let dirty = false;
    // If user select new Customer, delete Job && JobTask && JobPlanningLine
    if (validateObject.ids.customer !== customerData.id) {
      dirty = true;
      if (validateObject.ids.job.length > 0) {
        await clearDependingFields({
          deleteCustomer: false,
          validateObject,
        });
      }
    }

    validateObject.ids.customer = customerData.id;
    // Set filterForJob
    validateObject.odataFilter.filterForCustomer = {
      customer: {
        id: { eq: { type: "guid", value: customerData.id } },
      },
    };

    if (dirty) {
      // More then 1 Job || null => Focus Job
      if (
        validateObject.ids.jobTask.length === 0 &&
        !(customerData.jobs && customerData.jobs?.length === 1)
      ) {
        // Focus Job Field
        handleFocusField(setFocus, "customer", 20);
        handleFocusField(setFocus, "job", 30);
      }
      if (customerData.jobs && customerData.jobs.length === 1) {
        // Only 1 Job => fill out automatically
        handleJobFilling({
          jobData: customerData.jobs[0],
          getCustomer: false,
          validateObject,
          mappingObject,
          filterIsActive,
          dispatch,
          setFocus,
        });
        handleFocusField(setFocus, "customer", 20);
        handleFocusField(setFocus, "job", 50);
      }
    }

    validateObject.memoryIds.customer = customerData.id;
    validateObject.memoryEntries.customer = customerController.createRows<T>([
      customerData,
    ])[0];

    onChange({
      id: customerData.id,
      name: customerData.name,
      no: customerData.no,
    });
  } else {
    onChange({
      id: null,
      name: null,
      no: null,
    });
    validateObject.odataFilter.filterForCustomer = null;

    // Delete depending fields (Job/JobTask/Work) only if time-tracking-tool has
    // customer as selectable field
    if (!allowDeleteCustomer) {
      await clearDependingFields<T>({
        deleteCustomer: true,
        validateObject,
      });
    }
  }

  return validateObject;
}

/**
 * Function to clear Customer &&|| Job Field
 * @param deleteCustomer Check if Customer field needs to be cleared too
 * @param validateObject Current state object
 */
export async function clearDependingFields<T>({
  deleteCustomer,
  validateObject,
}: {
  deleteCustomer: boolean;
  validateObject: ValidateRecordTask<T>;
}): Promise<boolean> {
  // Delete depending fields (Customer/Job/JobTask/Activity)
  if (deleteCustomer && validateObject.ids.customer.length > 0) {
    validateObject.ids.customer = "";
    validateObject.entries.customer = [];
  }
  // Job
  if (
    validateObject.ids.job === undefined ||
    (validateObject.ids.job && validateObject.ids.job.length > 0)
  ) {
    validateObject.ids.job = "";
    validateObject.entries.job = [];
  }
  // JobTask
  if (
    validateObject.ids.jobTask === undefined ||
    (validateObject.ids.jobTask && validateObject.ids.jobTask.length > 0)
  ) {
    validateObject.ids.jobTask = "";
    validateObject.infos.memoryJobTaskId = "";
    validateObject.entries.jobTask = [];
    validateObject.odataFilter.filterForJob = null;
    validateObject.orderBy.jobTask = jobTaskController.getSqlColumnName(
      "jobNo",
      "ASC"
    );
  } else if (validateObject.ids.job.length === 0) {
    validateObject.odataFilter.filterForJob = null;
    validateObject.orderBy.jobTask = jobTaskController.getSqlColumnName(
      "jobNo",
      "ASC"
    );
  }
  // JobPlanningLine
  if (
    validateObject.ids.activity === undefined ||
    (validateObject.ids.activity && validateObject.ids.activity.length > 0)
  ) {
    validateObject.ids.activity = "";
    validateObject.entries.activity = [];
  }
  // Trigger deletion
  validateObject.dependency.deleteFields = true;
  return true;
}

/**
 * Function to react correct on job user selection
 * (set Filter / fill out || delete Job && JobTask && JPL ...)
 * => Callback function for onSelect prop
 * @param jobData IRows object from with Customer entries
 * @param onChange OnChange event from LookUp
 * @param validateObject State object for business logic
 * @param mappingObject State object from Editor
 * @param dispatch Dispatch hook from redux toolkit
 */
export async function jobSelected<T>({
  jobData,
  onChange,
  validateObject,
  setFocus,
  allowDeleteCustomer,
  mappingObject,
  dispatch,
}: {
  jobData: PopulatedJob | null;
  onChange: (...event: any[]) => void;
  validateObject: ValidateRecordTask<T>;
  setFocus: UseFormSetFocus<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >;
  allowDeleteCustomer: boolean;
  mappingObject?: LookUpMapping;
  dispatch?: AppDispatch;
}): Promise<ValidateRecordTask<T>> {
  if (jobData && mappingObject && dispatch) {
    validateObject.ids.job = jobData.id;
    // Set filter for jobTask
    validateObject.odataFilter.filterForJob = {
      job: { id: { eq: { type: "guid", value: jobData.id } } },
    };

    // If user select new Job, delete JobTask && JobPlanningLine
    if (mappingObject.job && mappingObject.job.id !== jobData.id) {
      // JobTask
      validateObject.ids.jobTask = "";
      validateObject.entries.jobTask = [];
      // Activity
      validateObject.ids.activity = "";
      validateObject.entries.activity = [];
      // Trigger deletion
      validateObject.dependency.deleteFields = true;
    }

    if (jobData.customer) {
      // Set Filter for Job
      validateObject.odataFilter.filterForCustomer = {
        customer: {
          id: { eq: { type: "guid", value: jobData.customer.id } },
        },
      };
      if (!mappingObject.job || jobData.id !== mappingObject.job.id) {
        handleFocusField(setFocus, "jobTask", 100);
      }
      if (jobData.id === validateObject.infos.memoryLastJobId) {
        // Same input in job field => focus jobTask field again
        validateObject.infos.memoryLastJobId = "";
        handleFocusField(setFocus, "jobTask", 100);
      }
      if (validateObject.infos.jobHadError) {
        validateObject.infos.jobHadError = false;
        // Job with same input as before error
        handleFocusField(setFocus, "jobTask", 100);
      }

      if (
        (mappingObject.customer === null && mappingObject.jobTask === null) ||
        (allowDeleteCustomer &&
          mappingObject.customer &&
          jobData.customer.id !== mappingObject.customer.id)
      ) {
        handleCustomerFilling({
          customerData: jobData.customer,
          fromJob: true,
          validateObject,
          mappingObject,
          setFocus,
        });
      }
    }

    validateObject.orderBy.jobTask = jobTaskController.getSqlColumnName(
      "description",
      "ASC"
    );

    validateObject.memoryIds.job = jobData.id;
    validateObject.memoryEntries.job = jobController.createRows<T>([
      jobData,
    ])[0];

    onChange({
      id: jobData.id,
      description: jobData.description,
      no: jobData.no,
    });

    return validateObject;
  } else {
    onChange({
      id: null,
      description: null,
      no: null,
    });
    // Safely reset pending state if still being set
    if (
      validateObject.pending.blurred !== null &&
      validateObject.pending.field === LookUpOptions.JOB
    ) {
      validateObject = handleResetPending({
        projectingInput: validateObject,
      });
    }
    await clearDependingFields({
      deleteCustomer: allowDeleteCustomer,
      validateObject,
    });
  }

  return validateObject;
}

/**
 * From Job => Function to fill out Customer automatically
 * @param customerData Customer object {@typedef Customer}
 * @param fromJob Set focus correct when user selects job first
 * @param validateObject State object for business logic
 * @param mappingObject State object from Editor
 */
function handleCustomerFilling<T>({
  customerData,
  fromJob,
  validateObject,
  mappingObject,
  setFocus,
}: {
  customerData: Customer;
  fromJob: boolean;
  validateObject: ValidateRecordTask<T>;
  mappingObject: LookUpMapping;
  setFocus: UseFormSetFocus<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >;
}) {
  if (customerData.id) {
    // Fill out Customer field
    mappingObject = {
      ...mappingObject,
      customer: {
        id: customerData.id,
        name: customerData.name,
        no: customerData.no,
      },
    };
    validateObject.odataFilter.filterForCustomer = {
      customer: {
        id: { eq: { type: "guid", value: customerData.id } },
      },
    };

    const { id, entry } = InitialData.getCustomer<T>(mappingObject);
    validateObject.ids.customer = id;
    validateObject.entries.customer = entry;

    // Focus JobTask when Customer and Job is filled automatically
    if (fromJob) {
      handleFocusField(setFocus, "jobTask", 100);
    }
    setTimeout(() => {
      validateObject.infos.customerIsConfirming = false;
    }, 500);
  }
}

/**
 * Function to fill Job Field automatically
 * @param jobData Rows object from Customer with Job information
 * @param getCustomer Boolean if customer is empty => starts API Call and fill customer
 * @param validateObject State object for business logic
 * @param mappingObject State object from Editor
 * @param filterIsActive Boolean if filter is active
 * @param dispatch Dispatch hook from redux toolkit
 */
async function handleJobFilling<T>({
  jobData,
  getCustomer,
  validateObject,
  mappingObject,
  filterIsActive,
  dispatch,
  setFocus,
}: {
  jobData: PopulatedJob;
  getCustomer: boolean;
  validateObject: ValidateRecordTask<T>;
  mappingObject: LookUpMapping;
  filterIsActive: boolean;
  dispatch: AppDispatch;
  setFocus: UseFormSetFocus<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >;
}): Promise<boolean> {
  if (jobData && jobData.id) {
    // Fill information in Textfield
    mappingObject = {
      ...mappingObject,
      job: {
        id: jobData.id,
        description: jobData.description,
        no: jobData.no,
      },
    };

    const { id, entry } = InitialData.getJob<T>(mappingObject);
    validateObject.ids.job = id;
    validateObject.entries.job = entry;
    // Set filter for JobTask
    validateObject.odataFilter.filterForJob = {
      job: { id: { eq: { type: "guid", value: jobData.id } } },
    };
    // Focus JobTask
    handleFocusField(setFocus, "jobTask", 400);
  }
  // Start get Customer information if field is empty
  if (getCustomer) {
    await getCustomerInformation<T>({
      jobData,
      validateObject,
      mappingObject,
      filterIsActive,
      dispatch,
      setFocus,
    });
  }
  return true;
}

/**
 * Function to get Customer entries and fill Customer field automatically
 * @param jobData PopulatedJob object {@typedef PopulatedJob}
 * @param validateObject State object for business logic
 * @param mappingObject State object from Editor
 * @param dispatch Dispatch hook from redux toolkit
 */
export async function getCustomerInformation<T>({
  jobData,
  validateObject,
  mappingObject,
  filterIsActive,
  dispatch,
  setFocus,
}: {
  jobData: PopulatedJob;
  validateObject: ValidateRecordTask<T>;
  mappingObject: LookUpMapping;
  filterIsActive: boolean;
  dispatch: AppDispatch;
  setFocus: UseFormSetFocus<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >;
}) {
  // Set filter for Job
  const filter = { no: jobData.no };
  const jobWithCustomerArray: PopulatedJob[] = await apiCallLookUp<
    PopulatedJob[],
    PopulatedJob
  >({
    orderBy: validateObject.orderBy.job,
    filterColumns: null,
    expand: jobController.expand as Expand<PopulatedJob>,
    top: 1,
    filterPreviousField: null,
    odataQuery: null,
    dispatch: dispatch,
    lookUpField: LookUpShortOptions.JOB,
    jobNoFilter: filter,
    getTaskedList: filterIsActive,
  });

  if (jobWithCustomerArray[0] && jobWithCustomerArray[0].customer) {
    // Check if Customer is not same as current one
    if (
      mappingObject.customer === null ||
      mappingObject.customer?.id !== jobWithCustomerArray[0].customer.id
    ) {
      validateObject.ids.customer = jobWithCustomerArray[0].customer.id;
      handleCustomerFilling({
        customerData: jobWithCustomerArray[0].customer,
        fromJob: false,
        validateObject,
        mappingObject,
        setFocus,
      });
    }
    validateObject.odataFilter.filterForCustomer = {
      customer: {
        id: {
          eq: { type: "guid", value: jobWithCustomerArray[0].customer.id },
        },
      },
    };
  }
}

/**
 * Function to react correct on jobTask user selection
 * (set Filter / fill out || delete JobPlanningLine ...)
 * => Callback function for onSelect prop
 * @param jobTaskData IRows object from with JobTask entries
 * @param onChange OnChange event from LookUp
 * @param validateObject State object for business logic
 * @param mappingObject State object from Editor
 * @param filterIsActive Boolean if filter is active
 * @param dispatch Dispatch hook from redux toolkit
 * @param validateProjectingInput Callback to set object state
 */
export async function jobTaskSelected<T>({
  jobTaskData,
  onChange,
  validateObject,
  setFocus,
  filterIsActive,
  mappingObject,
  dispatch,
  blockClosingCallback,
  validateProjectingInput,
}: {
  jobTaskData: PopulatedJobTask | null;
  onChange: (...event: any[]) => void;
  validateObject: ValidateRecordTask<T>;
  setFocus: UseFormSetFocus<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >;
  filterIsActive: boolean;
  mappingObject?: LookUpMapping;
  dispatch?: AppDispatch;
  blockClosingCallback?: (value: boolean) => void;
  validateProjectingInput?: (object: ValidateRecordTask<T>) => void;
}): Promise<ValidateRecordTask<T>> {
  if (
    jobTaskData &&
    mappingObject &&
    dispatch &&
    blockClosingCallback &&
    validateProjectingInput
  ) {
    validateObject.ids.jobTask = jobTaskData.id;

    // If user select new JobTask, delete Activity
    if (
      mappingObject.jobTask &&
      validateObject.infos.memoryJobTaskId.length > 0 &&
      validateObject.infos.memoryJobTaskId !== jobTaskData.id &&
      !validateObject.infos.deletedByUser
    ) {
      validateObject.ids.activity = "";
      validateObject.entries.activity = [];
      validateObject.infos.activityHasValue = false;
      validateObject.dependency.deleteFields = true;

      validateObject.infos.initialActivity = true;
      validateObject.infos.focusActivity = true;
    }
    if (validateObject.infos.memoryJobTaskId !== jobTaskData.id) {
      validateObject.infos.changedJobTask = true;
    }
    validateObject.infos.deletedByUser = false;

    // Here call to set Job automatically
    if (validateObject.ids.job.length === 0 && jobTaskData.job) {
      validateObject.odataFilter.filterForCustomer = null;
      validateObject.infos.customerIsConfirming = true;
      await handleJobFilling({
        jobData: jobTaskData.job,
        getCustomer: true,
        validateObject,
        mappingObject,
        filterIsActive,
        dispatch,
        setFocus,
      });
    }

    // Here call to set Activity automatically
    if (
      jobTaskData.job &&
      jobTaskData.job.id &&
      validateObject.infos.memoryJobTaskId !== jobTaskData.id
    ) {
      blockClosingCallback(true);
      await getActivityInformation({
        job: jobTaskData.job.id,
        jobTask: jobTaskData.id,
        validateObject,
        mappingObject,
        filterIsActive,
        dispatch,
        validateProjectingInput,
        blockClosingCallback,
      });
    } else if (validateObject.infos.memoryLastJobTaskId === jobTaskData.id) {
      // If user select same JobTask, set focus to Activity
      if (validateObject.infos.blockMemoryLastJobTaskId) {
        validateObject.infos.blockMemoryLastJobTaskId = false;
      } else {
        setFocus("hidden");
        setFocus("jobTask");
        handleFocusField(setFocus, "jobPlanningLine");
        validateObject.infos.memoryLastJobTaskId = "";
      }
    } else {
      validateObject.infos.initialJobTask = false;
    }
    // Set jobTaskData.id for memory
    validateObject.infos.memoryJobTaskId = jobTaskData.id;
    validateObject.memoryIds.jobTask = jobTaskData.id;
    validateObject.memoryEntries.jobTask = jobTaskController.createRows<T>([
      jobTaskData,
    ])[0];

    if (validateObject.infos.jobHadError) {
      validateObject.infos.jobHadError = false;
    }

    onChange({
      id: jobTaskData.id,
      description: jobTaskData.description,
      no: jobTaskData.no,
      job: {
        id: jobTaskData.job ? jobTaskData.job.id : null,
        description: jobTaskData.job ? jobTaskData.job.description : null,
        no: jobTaskData.job ? jobTaskData.job.no : null,
      },
      jobPlanningLine: jobTaskData.jobPlanningLine
        ? {
            id: jobTaskData.jobPlanningLine
              ? jobTaskData.jobPlanningLine.id
              : null,
            description: jobTaskData.jobPlanningLine
              ? jobTaskData.jobPlanningLine.description
              : null,
            serviceBillingType: jobTaskData.jobPlanningLine
              ? jobTaskData.jobPlanningLine.serviceBillingType
              : null,
          }
        : null,
      status: jobTaskData.status,
    });

    return validateObject;
  } else {
    onChange({
      id: null,
      description: null,
      no: null,
      job: {
        id: null,
        description: null,
        no: null,
      },
      jobPlanningLine: null,
      status: JobTaskStatusOptions.OPEN,
    });
    validateObject.ids.jobTask = "";
    validateObject.entries.jobTask = [];
    // Activity
    validateObject.ids.activity = "";
    validateObject.entries.activity = [];
    validateObject.memoryEntries.activity = null;
    validateObject.memoryIds.activity = "";
    if (!validateObject.pending.isLoading) {
      // Clear value only when not in pending state
      validateObject.infos.deletedByUser = true;
      validateObject.infos.memoryJobTaskId = "";
      validateObject.infos.checkForSameTnTModelId = "";
    }
    // Safely reset pending state if still being set
    if (
      validateObject.pending.blurred !== null &&
      validateObject.pending.field === LookUpOptions.JOBTASK &&
      !validateObject.infos.jobTaskLoading
    ) {
      validateObject = handleResetPending({
        projectingInput: validateObject,
      });
    }
    // Trigger deletion
    if (validateObject.pending.isLoading === false) {
      validateObject.dependency.deleteFields = true;
    }
  }

  return validateObject;
}

/**
 * Function to get JobPlanningLine entries and fill field automatically
 * or focus jobPlanningLine field
 * @param job ID from current selected Job
 * @param jobTask ID from current selected JobTask
 * @param validateObject State object for business logic
 * @param filterIsActive Boolean if filter is active
 * @param mappingObject State object from Editor
 * @param dispatch Dispatch hook from redux toolkit
 * @param validateProjectingInput Callback to set object state
 */
async function getActivityInformation<T>({
  job,
  jobTask,
  validateObject,
  mappingObject,
  filterIsActive,
  dispatch,
  validateProjectingInput,
  blockClosingCallback,
}: {
  job: string;
  jobTask: string;
  validateObject: ValidateRecordTask<T>;
  mappingObject: LookUpMapping;
  filterIsActive: boolean;
  dispatch: AppDispatch;
  validateProjectingInput: (object: ValidateRecordTask<T>) => void;
  blockClosingCallback: (value: boolean) => void;
}): Promise<ValidateRecordTask<T>> {
  if (!validateObject.infos.activityLoading) {
    validateObject.infos.activityLoading = true;
    const activityArray = await apiCallActivity<Activity[]>({
      doFilter: false,
      debouncedValue: "",
      orderBy: validateObject.orderBy.activity,
      jobId: job,
      getTaskedList: filterIsActive,
      jobTaskId: jobTask,
      dispatch: dispatch,
    });

    validateObject.infos.activityLoading = false;
    if (activityArray.length > 1) {
      // Find same Activity after change jobTask and fill with this
      const sameActivity = activityArray.find(
        (act) =>
          act.tntModelLine.id === validateObject.infos.checkForSameTnTModelId
      );
      // Find unique ItemNo. and fill out Activity
      const uniqueNumber = _.remove(activityArray, (x) => {
        return x.tntModelLine.itemNo !== null;
      });
      if (sameActivity) {
        validateObject = handleActivityFilling({
          activityData: sameActivity,
          validateObject,
          mappingObject,
          validateProjectingInput,
          blockClosingCallback,
        });
      } else if (uniqueNumber.length === 1) {
        validateObject = handleActivityFilling({
          activityData: uniqueNumber[0],
          validateObject,
          mappingObject,
          validateProjectingInput,
          blockClosingCallback,
        });
      } else {
        if (validateObject.entries.customer.length > 0) {
          validateObject.infos.changedJobTask = false;
          validateObject.infos.focusActivity = true;
        }
        blockClosingCallback(false);
      }
    } else if (activityArray.length === 1) {
      validateObject = handleActivityFilling({
        activityData: activityArray[0],
        validateObject,
        mappingObject,
        validateProjectingInput,
        blockClosingCallback,
      });
    } else {
      if (validateObject.entries.customer.length > 0) {
        validateObject.infos.focusActivity = true;
        validateObject.infos.changedJobTask = false;
      }
      validateObject.infos.checkForSameTnTModelId = "";
      setTimeout(() => {
        blockClosingCallback(false);
      }, 500);
    }
  }
  return validateObject;
}

/**
 * Function to fill JobPlanningLine field automatically
 * @param activityData jobPlanningLine object
 * @param validateObject State object for business logic
 * @param mappingObject State object from Editor
 * @param validateProjectingInput Callback to set object state
 */
function handleActivityFilling<T>({
  activityData,
  validateObject,
  mappingObject,
  validateProjectingInput,
  blockClosingCallback,
}: {
  activityData: Activity;
  validateObject: ValidateRecordTask<T>;
  mappingObject: LookUpMapping;
  validateProjectingInput: (object: ValidateRecordTask<T>) => void;
  blockClosingCallback: (value: boolean) => void;
}): ValidateRecordTask<T> {
  // JobPlanningLine
  mappingObject = {
    ...mappingObject,
    jobPlanningLine: {
      id: activityData.jobPlanningLine.id,
      description: activityData.tntModelLine
        ? activityData.tntModelLine.description
        : activityData.jobPlanningLine.description,
      serviceBillingType: activityData.jobPlanningLine.serviceBillingType,
      tntModelLine: {
        id: activityData.tntModelLine.id,
        description: activityData.tntModelLine.description,
        billable: activityData.tntModelLine.billable,
        nonBillableReason: activityData.tntModelLine.nonBillableReason,
        rounding: activityData.tntModelLine.rounding,
      },
      jobTask: {
        id: activityData.jobTask.id,
        description: activityData.jobTask.description,
        no: activityData.jobTask.no,
        job: {
          id: "",
          description: null,
          no: null,
        },
        jobPlanningLine: null,
        status: activityData.jobTask.status,
      },
    },
    tntModelLine: {
      id: activityData.tntModelLine.id,
      description: activityData.tntModelLine.description,
      billable: activityData.tntModelLine.billable,
      nonBillableReason: activityData.tntModelLine.nonBillableReason,
      rounding: activityData.tntModelLine.rounding,
    },
  };
  const { id, entry } = InitialData.getActivity<T>(mappingObject);
  validateObject.ids.activity = id;
  validateObject.entries.activity = entry;
  if (mappingObject.tntModelLine && mappingObject.tntModelLine.id) {
    validateObject.infos.checkForSameTnTModelId = mappingObject.tntModelLine.id;
  }
  validateObject.infos.memoryActivityId = id;
  validateObject.infos.focusActivity = true;
  validateObject.infos.filledFromJobTask = true;

  validateProjectingInput({
    ...validateObject,
    entries: {
      ...validateObject.entries,
      activity: validateObject.entries.activity,
    },
  });

  setTimeout(() => {
    blockClosingCallback(false);
  }, 300);
  return validateObject;
}

/**
 * Function to react correct on jobPlanningLine user selection
 * (set Filter / fill out || delete JobTask ...)
 * => Callback function for onSelect prop
 * @param activityData IRows object from with JobPlanningLine entries
 * @param onChange OnChange event from LookUp
 * @param validateObject State object for business logic
 * @param mappingObject State object from Editor
 * @param validateProjectingInput Callback to set object state
 */
export async function activitySelected<T>({
  activityData,
  onChange,
  validateObject,
  onActivityChanged,
  mappingObject,
}: {
  activityData: (PopulatedJobPlanningLine & { cells?: any }) | null;
  onChange: (...event: any[]) => void;
  validateObject: ValidateRecordTask<T>;
  onActivityChanged: ({
    jobPlanningLine,
    jobTask,
  }: {
    jobPlanningLine: PopulatedJobPlanningLine | null;
    jobTask: PopulatedJobTask | null;
  }) => void;
  mappingObject?: LookUpMapping;
}): Promise<ValidateRecordTask<T>> {
  if (activityData && activityData.tntModelLine && mappingObject) {
    validateObject.infos.activityHasValue = true;

    // Here information to set JobTask automatically
    if (
      activityData.jobTask &&
      validateObject.ids.jobTask !== activityData.jobTask?.id &&
      !validateObject.infos.jobTaskLoading
    ) {
      mappingObject = {
        ...mappingObject,
        jobTask: {
          id: activityData.jobTask.id,
          description: activityData.jobTask.description,
          no: activityData.jobTask.no,
          job: {
            id: activityData.jobTask.job ? activityData.jobTask.job.id : "",
            description: activityData.jobTask.job
              ? activityData.jobTask.job.description
              : "",
            no: activityData.jobTask.job ? activityData.jobTask.job.no : "",
          },
          jobPlanningLine: null,
          status: activityData.jobTask.status,
        },
      };
      const { id, entry } = InitialData.getJobTask<T>(mappingObject);
      validateObject.ids.jobTask = id;
      validateObject.entries.jobTask = entry;
      validateObject.infos.memoryJobTaskId = id;
    }

    const newActivity = { ...(activityData as any) };
    const activityModel = {
      id: newActivity.id,
      description: newActivity.tntModelLine
        ? newActivity.tntModelLine.description
        : newActivity.description,
      serviceBillingType: newActivity.serviceBillingType
        ? newActivity.serviceBillingType
        : "",
      jobPlanningLine: {
        id: newActivity.jplId ? newActivity.jplId : newActivity.id,
        description: newActivity.tntModelLine
          ? newActivity.tntModelLine.description
          : newActivity.description,
        serviceBillingType: newActivity.serviceBillingType
          ? newActivity.serviceBillingType
          : "",
      },
      tntModelLine: {
        id: newActivity.tntModelLine.tntId
          ? newActivity.tntModelLine.tntId
          : newActivity.tntModelLine.id,
        description: newActivity.tntModelLine
          ? newActivity.tntModelLine.description
          : null,
        billable: newActivity.tntModelLine
          ? newActivity.tntModelLine.billable
          : false,
        nonBillableReason: newActivity.tntModelLine
          ? newActivity.tntModelLine.nonBillableReason
          : NonBillableReasonOptions.NONE,
        rounding: newActivity.tntModelLine
          ? newActivity.tntModelLine.rounding
          : 0,
      },
      no: newActivity.cells && newActivity.cells.no ? newActivity.cells.no : 0,
      jobTask: {
        id:
          newActivity.jobTask && newActivity.jobTask.id
            ? newActivity.jobTask.id
            : null,
        description:
          newActivity.jobTask && newActivity.jobTask.description
            ? newActivity.jobTask.description
            : null,
        no:
          newActivity.jobTask && newActivity.jobTask.no
            ? newActivity.jobTask.no
            : null,
        status:
          newActivity.jobTask && newActivity.jobTask.status
            ? newActivity.jobTask.status
            : JobTaskStatusOptions.OPEN,
        job: null,
      },
    };

    // Save for checking on jobTask change to set activity again automatically
    validateObject.infos.checkForSameTnTModelId = activityModel.tntModelLine.id;
    onChange(activityModel);

    validateObject.memoryIds.activity = newActivity.id;
    validateObject.memoryEntries.activity = activityController.createRows<T>([
      {
        ...activityModel,
        jobPlanningLine: {
          ...activityModel.jobPlanningLine,
          tntModelLine: {
            ...activityModel.tntModelLine,
          },
        },
      },
    ])[0];

    // Focus Description when JobTask was changed afterwards to validate nBR correct
    const tempJobTask: PopulatedJobTask = {
      ...activityModel.jobTask,
      jobPlanningLine: null,
    };
    const tempJobPlanningLine: PopulatedJobPlanningLine = {
      ...activityModel.jobPlanningLine,
      id: activityModel.id,
      tntModelLine: activityModel.tntModelLine,
      jobTask: tempJobTask,
    };
    const mutateTimeInputJob = { jobPlanningLine: { ...tempJobPlanningLine } };
    const { id, entry } = InitialData.getActivity<T>(
      mutateTimeInputJob as RecordEntry
    );
    validateObject.ids.activity = id;
    validateObject.entries.activity = entry;
    if (validateObject.infos.initialActivity) {
      if (
        newActivity.jobTask &&
        validateObject.ids.customer.length > 0 &&
        validateObject.infos.changedJobTask
      ) {
        validateObject.infos.changedJobTask = false;
        validateObject.forceChange.description = newActivity.description;

        validateObject.infos.filledFromJobTask = true;
        // Force filling field with information
        onActivityChanged({
          jobPlanningLine: tempJobPlanningLine,
          jobTask: tempJobTask,
        });
        return validateObject;
      } else if (validateObject.infos.memoryActivityId !== newActivity.id) {
        validateObject.infos.memoryActivityId = newActivity.id;

        // Force filling field with information
        onActivityChanged({
          jobPlanningLine: tempJobPlanningLine,
          jobTask: tempJobTask,
        });
        return validateObject;
      } else if (
        validateObject.infos.activityIdBeforeError === newActivity.id
      ) {
        validateObject.infos.activityIdBeforeError = "";
        // After error and refill with same activity as before error we are here
        // Force filling field with information
        onActivityChanged({
          jobPlanningLine: tempJobPlanningLine,
          jobTask: tempJobTask,
        });
        return validateObject;
      }
    } else {
      validateObject.infos.initialActivity = true;
      return validateObject;
    }
    onActivityChanged({
      jobPlanningLine: tempJobPlanningLine,
      jobTask: tempJobTask,
    });
    return validateObject;
  } else {
    onChange({
      id: null,
      description: null,
      serviceBillingType: null,
      tntModelLine: {
        tntId: null,
        description: null,
        billable: false,
        nonBillableReason: NonBillableReasonOptions.NONE,
        rounding: 1,
      },
    });
    // Safely reset pending state if still being set
    if (
      validateObject.pending.blurred !== null &&
      validateObject.pending.field === LookUpOptions.JOBPLANNINGLINE
    ) {
      validateObject = handleResetPending({
        projectingInput: validateObject,
      });
    }
    //
    validateObject.ids.activity = "";
    validateObject.entries.activity = [];
    if (
      validateObject.pending.blurred === null &&
      validateObject.infos.initialActivity &&
      validateObject.infos.memoryActivityId.length > 0
    ) {
      onActivityChanged({
        jobPlanningLine: null,
        jobTask: null,
      });
    }
    // Trigger deletion
    validateObject.dependency.deleteFields = true;
    return validateObject;
  }
}
