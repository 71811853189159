import { useEffect, useState } from "react";
import {
  CloseReason,
  OptionsObject,
  SnackbarKey,
  useSnackbar,
} from "notistack";
import { Action } from "./helpers/notification";
import { useHotkeys } from "react-hotkeys-hook";

type Notification = {
  key: SnackbarKey;
  message: string;
  autoHideDuration: number;
  persist: boolean;
  dismissed: boolean;
  options: NotifyOptions;
};

export type CallbackOption = {
  /**
   * Provide name to show as Text in action from Snackbar.
   * Show / Undo will translated automatically, any other shold provided as "t-string"
   */
  name: string;
  /**
   * Callback function
   */
  fn: () => void;
};

interface NotifyOptions extends OptionsObject {
  /**
   * Optional Callback functions provided in an array @type { CallbackOption }
   */
  callbacks?: CallbackOption[];
  /**
   * Optional close button for Snackbars
   * @default false
   */
  canClose?: boolean;
  /**
   * Optional variant for Snackbars to show in specified variant
   * @default default
   */
  variant?: "default" | "error" | "success" | "warning" | "info";
  /**
   * Optional callback function to react on @type {CloseReason}, event ot getting SnackbarKey
   */
  handleOnClose?: (options: {
    event: React.SyntheticEvent<any, Event> | null;
    reason: CloseReason;
    key?: SnackbarKey | undefined;
  }) => void;
}

let displayed: SnackbarKey[] = [];

export const useNotification = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  // States
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const storeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: SnackbarKey) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  // UseEffetcs
  useEffect(() => {
    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          // dismiss snackbar using notistack
          closeSnackbar(key);
          return;
        }

        // do nothing if snackbar is already displayed
        if (displayed.includes(key)) return;

        // display snackbar using notistack
        enqueueSnackbar(message, {
          key,
          ...options,
          role: "alert",
          onClose: (event, reason, myKey) => {
            if (options.onClose) {
              options.onClose(event, reason, myKey);
            }
          },
          onExited: (event, myKey) => {
            // remove this snackbar from redux store
            removeDisplayed(myKey);
          },
        });

        // keep track of snackbars that we've displayed
        storeDisplayed(key);
      }
    );
  }, [notifications, closeSnackbar, enqueueSnackbar]);

  useHotkeys(
    "esc",
    (e) => {
      e.preventDefault();
      if (displayed.length > 0) {
        handleEsc();
      }
    },
    [displayed]
  );

  const handleEsc = () => {
    const lastKey = displayed.shift();
    if (lastKey) {
      removeDisplayed(lastKey);
      closeSnackbar(lastKey);
    }
  };

  // ADD COMMENTS
  const notification = (message: string, options: NotifyOptions) => {
    const { canClose, callbacks, variant, handleOnClose } = options;
    const createKey = Date.now() + Math.random();

    const handleClose = (key: SnackbarKey) => {
      removeDisplayed(key);
      closeSnackbar(key);
    };

    const handleInternalOnClose = (
      event: React.SyntheticEvent<any, Event> | null,
      reason: CloseReason,
      key?: SnackbarKey | undefined
    ) => {
      if (handleOnClose) {
        handleOnClose({ event, reason, key });
      }
    };

    const switchCloseButtonColor = () => {
      if (variant) {
        if (variant !== "default") {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    };

    const Actions = Action(
      createKey,
      handleClose,
      switchCloseButtonColor(),
      callbacks,
      canClose
    );

    const newNotification: Notification = {
      key: createKey,
      message: message,
      autoHideDuration: 20000,
      persist: false,
      dismissed: false,
      options: {
        onClose: handleInternalOnClose,
        action: Actions,
        callbacks: [],
        variant: variant,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
      },
    };
    setNotifications([newNotification]);
  };

  return notification;
};
