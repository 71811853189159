import React, { FunctionComponent, useEffect, useState } from "react";
// Helpers
import { useTranslation } from "react-i18next";
import { unwrapResult } from "@reduxjs/toolkit";
// Dyce-Lib
import {
  getAllClients,
  getAllResources,
  getAllRoles,
  getClientToken,
  removeClient,
  selectAllClients,
  selectAllResourcesAsArray,
  selectAllRolesAsArray,
  selectCurrentWorkspace,
  useAppDispatch,
  useAppSelector,
} from "@dyce/slices";
import { DyceTheme } from "@dyce/theme";
import { CopyToClipboard, Dialog, SubHeader, useStaticContent } from "@dyce/ui";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Box, CircularProgress, TextField, useTheme } from "@mui/material";
// Components
import { ClientsList, CreateClient } from "../../../components/admin/clients";
import { Client } from "@dyce/tnt-api";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: `calc(100vh - ${theme.palette.propsDyce.commonPageProps.marginTop} - 64px - 112px - 8px)`,
      marginRight: "1.75rem",
      marginLeft: "1.25rem",
      [theme.breakpoints.down("sm")]: {
        height: `calc(100vh - ${theme.palette.propsDyce.commonPageProps.marginTop} - 56px - 112px - 8px)`,
      },
    },
    loadingProgress: {
      display: "flex",
      height: "91px",
      justifyContent: "center",
      alignItems: "center",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
      alignItems: "center",
    },
    tokenBox: {
      display: "flex",
      padding: "1rem",
      borderStyle: "solid",
      borderWidth: "1px",
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.divider,
      minWidth: 500,
      width: 500,
      minHeight: 100,
      height: "100%",
    },
    tokenContent: {
      height: "100px",
    },
    copyButton: {
      marginLeft: "1rem",
      marginRight: "1rem",
    },
  })
);

export const Clients: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { docuLinks } = useStaticContent();
  const dispatch = useAppDispatch();
  const theme = useTheme<DyceTheme>();

  // Selectors
  const allRoles = useAppSelector(selectAllRolesAsArray);
  const allResources = useAppSelector(selectAllResourcesAsArray);
  const { instance, company } = useAppSelector(selectCurrentWorkspace);
  const allClients = useAppSelector(selectAllClients);

  // States
  const [openJwtDialog, setOpenJwtDialog] = useState(false);
  const [loadToken, setLoadToken] = useState(false);
  const [jwtToken, setJwtToken] = useState<string | null>(null);
  const [clientToEdit, setClientToEdit] = useState<Client | null>(null);

  // UseEffect
  useEffect(() => {
    dispatch(getAllRoles());
    dispatch(getAllResources());
  }, [instance, company]);

  useEffect(() => {
    dispatch(getAllClients());
  }, []);

  // Handler
  const handleGetToken = async (id: string) => {
    setLoadToken(true);
    setOpenJwtDialog(true);
    try {
      const token = await dispatch(getClientToken(id));
      const result = unwrapResult(token);
      setJwtToken(result);
    } catch (error) {
      console.warn(error);
    }
    setLoadToken(false);
  };

  const handleDeleteClient = (id: string) => {
    dispatch(removeClient(id));
  };

  return (
    <>
      <SubHeader
        tooltipLabel={t("admin.client.info")}
        tooltipUrlPath={docuLinks.administration.client.subheader.info}
        title={t("admin.client.title")}
        subtitle={t("admin.client.subtitle")}
      />
      <div className={classes.container}>
        <CreateClient
          roles={allRoles}
          resources={allResources}
          clientToEdit={clientToEdit}
          onClientEdited={() => setClientToEdit(null)}
        />
        <ClientsList
          clients={allClients}
          roles={allRoles}
          getToken={handleGetToken}
          deleteClient={handleDeleteClient}
          editClient={(val) => setClientToEdit(val)}
        />
      </div>
      <Dialog
        open={openJwtDialog}
        actionButtonLabels={{
          cancel: t("admin.client.tooltip.close"),
        }}
        onCancel={() => {
          setOpenJwtDialog(false);
          setJwtToken(null);
        }}
        title={t("admin.client.tooltip.jwtToken")}
      >
        <Box className={classes.dialogContent}>
          {loadToken ? (
            <Box className={classes.tokenBox}>
              <div className={classes.loadingProgress}>
                <CircularProgress size={32} />
              </div>
            </Box>
          ) : (
            <TextField
              className={classes.tokenBox}
              multiline
              fullWidth
              rows={4}
              disabled
              value={jwtToken ? jwtToken : t("admin.client.missingToken")}
              sx={{
                "& .Mui-disabled": {
                  WebkitTextFillColor: theme.palette.text.primary,
                },
                "& .MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled": {
                  WebkitTextFillColor: theme.palette.text.primary,
                },
              }}
            />
          )}
          <CopyToClipboard
            valueTopCopy={jwtToken}
            spanStyle={classes.copyButton}
          />
        </Box>
      </Dialog>
    </>
  );
};
