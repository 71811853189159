import { useEffect, useState } from "react";

/**
 * Hook to check double clicks, fires callback on single- and doubleclicks.
 * @param actionDoubleClick Callback with provided id, fired when double click was triggered
 * @param actionSimpleClick Optional callback with provided id, fired when single click was triggered
 * @param delay Time in ms when click event will be resetted @default 250
 * @returns Function that recieves selected id
 */
export const useDoubleClick = (
  actionDoubleClick: (id: string) => void,
  actionSimpleClick?: (id: string) => void,
  delay = 250
) => {
  const [click, setClick] = useState<number>(0);
  const [id, setId] = useState<string>("");
  const [memoryId, setMemoryId] = useState<string | null>(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      // Fire Callback with id for single click when timed out
      if (click === 1) {
        actionSimpleClick && actionSimpleClick(id);
      }
      setClick(0);
      setMemoryId(null);
    }, delay);

    if (click === 1) {
      // Save id to memory to check that doubleclick was on same element/object
      setMemoryId(id);
    } else if (click === 2 && memoryId === id) {
      // Fire Callback for double click with id
      actionDoubleClick(id);
    }

    return () => clearTimeout(timer);
  }, [click]);

  const doubleClick = (id: string) => {
    setId(id);
    setClick((prev) => prev + 1);
  };

  return doubleClick;
};
