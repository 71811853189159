import React, {
  createContext,
  FunctionComponent,
  useContext,
  useState,
  useEffect,
} from "react";
import {
  Ability,
  AbilityBuilder,
  AbilityClass,
  ForcedSubject,
} from "@casl/ability";
import { CurrentUser } from "@dyce/tnt-api";

type AbilityContextState = {
  can: (action: Actions, subject: Subjects) => boolean;
  updateUser: (user: CurrentUser) => void;
};

export type Actions = "create" | "read" | "update" | "delete";
export type Subjects = "admin" | "timerec" | "resplan";
type AbilityType = [Actions, Subjects | ForcedSubject<Subjects>];
type AppAbility = Ability<AbilityType>;
const AppAbility = Ability as AbilityClass<AppAbility>;

export const AbilityContext = createContext<AbilityContextState | undefined>(
  undefined
);

/**
 * defines permissions for resources per user
 * @param user user to create the ability for
 * @returns created ability
 */
export const defineAbilityFor = (user: CurrentUser) => {
  const { can, build } = new AbilityBuilder(AppAbility);

  // create default ruleset until current user is loaded
  if (user?.id === "" || user === undefined) {
    can("read", "timerec");
    can("read", "resplan");
    can("read", "admin");

    const ability = build({
      detectSubjectType: (obj) => obj.toString() as Subjects,
    });

    return ability;
  }

  user.roles.forEach((r) => {
    // don't assign timerec ability if setup for user was not complete
    if (r.type === "timerec" && user.resource == null) {
      return;
    }

    can("read", r.type as Subjects);
    can("create", r.type as Subjects);
    can("update", r.type as Subjects);
    can("delete", r.type as Subjects);
  });

  const ability = build({
    detectSubjectType: (obj) => obj.toString() as Subjects,
  });

  return ability;
};

interface IAbilityProviderProps {
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

/**
 * Creates the ability provider and holds state for the ability
 * @returns ability provider
 */
export const AbilityProvider: FunctionComponent<IAbilityProviderProps> = ({
  children,
}) => {
  const [ability, setAbility] = useState<Ability<AbilityType>>(
    new Ability<AbilityType>()
  );

  // create default ruleset until current user is loaded
  useEffect(() => {
    const { can, build } = new AbilityBuilder(AppAbility);

    can("read", "timerec");
    can("read", "resplan");
    can("read", "admin");

    const emptyAbility = build({
      detectSubjectType: (obj) => obj.toString() as Subjects,
    });

    setAbility(emptyAbility);
  }, []);

  const can = (action: Actions, subject: Subjects) => {
    return ability.can(action, subject);
  };

  const updateUser = (user: CurrentUser) => {
    setAbility(defineAbilityFor(user));
  };

  return (
    <AbilityContext.Provider value={{ can, updateUser }}>
      {children}
    </AbilityContext.Provider>
  );
};

/**
 * Custom hook for the ability context
 * @returns Ability context
 */
export const useAbility = () => {
  const context = useContext(AbilityContext);

  if (context === undefined) {
    throw new Error("Ability Context is undefined");
  }

  return context;
};
