import { FunctionComponent, MouseEvent } from "react";
// Helper
import { createRoot } from "react-dom/client";
import { DateTime } from "luxon";
import { t } from "i18next";
import { handleButtonContainerWidth } from "./utils";
// Dyce-Lib
import { RecordTimeRec, ResourceCapacity, StatusOptions } from "@dyce/tnt-api";
import { DyceTheme } from "@dyce/theme";
import {
  ItemEntriesInfo,
  ItemEntriesProgress,
  PopulatedEventModel,
  StaticProvider,
  Tooltip,
} from "@dyce/ui";
import { SetupLanguages } from "@dyce/slices";
// Bryntum
import { CSSHelper, StringHelper, LocaleManager } from "@bryntum/calendar";
// MUI
import { Box, IconButton } from "@mui/material";

// Change color from tentative Timerecordings to striped colors
CSSHelper.insertRule(
  ".tentative .b-cal-event { background: repeating-linear-gradient(45deg, gray, gray 10px, #a284f7 10px, #a284f7 20px); }"
);
CSSHelper.insertRule(".noPointer .b-cal-event { cursor: initial }");
// Change color from allDay Timerecordings
CSSHelper.insertRule(
  ".allday-record .b-cal-event { background: #acffc180 !important; }"
);
// Change text-color from allDay Timerecordings
CSSHelper.insertRule(
  ".allday-record-text .b-cal-event-desc { color: #606060 !important; }"
);
// Calendar-Timerecordings with dependency to Calendar-Events
CSSHelper.insertRule(
  ".depend-two-1 { left: 0px !important; width: 50% !important; }"
);
CSSHelper.insertRule(
  ".depend-two-2 { left: 50% !important; width: 50% !important; }"
);
CSSHelper.insertRule(
  ".depend-three-1 { left: 0px !important; width: 33.3% !important; }"
);
CSSHelper.insertRule(
  ".depend-three-2 { left: 33.3% !important; width: 33.3% !important; }"
);
CSSHelper.insertRule(
  ".depend-three-3 { left: 66.6% !important; width: 33.4% !important; }"
);
CSSHelper.insertRule(
  ".depend-four-1 { left: 0px !important; width: 25% !important; }"
);
CSSHelper.insertRule(
  ".depend-four-2 { left: 25% !important; width: 25% !important; }"
);
CSSHelper.insertRule(
  ".depend-four-3 { left: 50% !important; width: 25% !important; }"
);
CSSHelper.insertRule(
  ".depend-four-4 { left: 75% !important; width: 25% !important; }"
);
CSSHelper.insertRule(
  ".depend-five-1 { left: 0px !important; width: 20% !important; }"
);
CSSHelper.insertRule(
  ".depend-five-2 { left: 20% !important; width: 20% !important; }"
);
CSSHelper.insertRule(
  ".depend-five-3 { left: 40% !important; width: 20% !important; }"
);
CSSHelper.insertRule(
  ".depend-five-4 { left: 60% !important; width: 20% !important; }"
);
CSSHelper.insertRule(
  ".depend-five-5 { left: 80% !important; width: 20% !important; }"
);

const wait = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

export const handleInitialCalendarSettings = async ({
  calendarStyle,
  language,
}: {
  calendarStyle: string;
  language: SetupLanguages;
}): Promise<boolean> => {
  // Add or change theme in document head tag
  const oldStyleNode = document.getElementById("data-bryntum-theme");
  if (oldStyleNode) {
    oldStyleNode.setAttribute(
      "href",
      `assets/theme/calendar.${calendarStyle}.css`
    );
  } else {
    const styleNode = document.createElement("link");
    styleNode.setAttribute("rel", "stylesheet");
    styleNode.setAttribute(
      "href",
      `assets/theme/calendar.${calendarStyle}.css`
    );
    styleNode.setAttribute("id", "data-bryntum-theme");
    document.head.appendChild(styleNode);
  }

  // Define language
  LocaleManager.locale =
    language.substring(0, 1).toUpperCase() + language.substring(1);

  // Give time to set configurations and render calendar correct
  await wait(50);

  return true;
};

export const handleEventRenderer = ({
  renderAddButton,
  eventRecord,
  theme,
  updateEventId,
  onTimeRecordingForm,
}: {
  renderAddButton: boolean;
  eventRecord: PopulatedEventModel;
  theme: DyceTheme;
  updateEventId: string | null;
  onTimeRecordingForm: ({
    element,
    buttonElement,
    eventRecord,
  }: {
    element: HTMLElement | null;
    buttonElement: HTMLElement | null;
    eventRecord: PopulatedEventModel;
  }) => void;
}): HTMLDivElement => {
  // Create a wrapper div
  const wrapper = document.createElement("div");
  wrapper.id = `wrapper_${eventRecord.id}`;
  wrapper.className = "wrapper_eventButtons";
  // Add the event text to the wrapper
  const eventText = StringHelper.xss` ${eventRecord.name}`;
  const eventTextEl = document.createElement("span");
  eventTextEl.style.cssText = `
  font-size: 12px;
  `;
  eventTextEl.innerHTML = eventText;
  wrapper.appendChild(eventTextEl);
  const buttonContainerId = eventRecord.id
    ? String(eventRecord.id)
    : String(eventRecord.id).split("_")[2].toString();
  // Get already existing buttonContainer container or create a new one
  let buttonContainer = document.getElementById(buttonContainerId);

  if (
    (buttonContainer === null &&
      eventRecord.allDay === true &&
      eventRecord.timerecording !== null) ||
    (buttonContainer === null &&
      renderAddButton &&
      eventRecord.allDay === false)
  ) {
    buttonContainer = document.createElement("div");
    if (eventRecord.duration >= 30) {
      const root = createRoot(buttonContainer);
      root.render(
        <EventButton
          record={eventRecord.timerecording}
          theme={theme}
          onClick={(event) => {
            event.stopPropagation();

            onTimeRecordingForm({
              element: null,
              buttonElement: buttonContainer,
              eventRecord: eventRecord,
            });
          }}
        />
      );
    }
  }

  if (updateEventId === eventRecord.id && buttonContainer !== null) {
    buttonContainer = document.createElement("div");
    const root = createRoot(buttonContainer);
    root.render(
      <EventButton
        record={eventRecord.timerecording}
        theme={theme}
        onClick={(event) => {
          event.stopPropagation();

          onTimeRecordingForm({
            element: null,
            buttonElement: buttonContainer,
            eventRecord: eventRecord,
          });
        }}
      />
    );
  }

  // Style Container
  if (buttonContainer !== null) {
    buttonContainer.style.cssText = `
    position: absolute;
    bottom: ${eventRecord.duration <= 30 ? "0px" : "3px"};
    transition: all 300ms ease-in-out;
    pointer-events: none;
    width: 100%;
    `;
    buttonContainer.id = buttonContainerId;

    // Move Add/Edit Button to the right if event is dragged or resized
    if (buttonContainerId.includes(`dragResize-event-`)) {
      buttonContainer.style.display = "flex";
      buttonContainer.style.right = "40px";
      buttonContainer.style.opacity = "0.5";
    } else if (buttonContainerId.includes(`_generated`) || !renderAddButton) {
      buttonContainer.style.opacity = "0";
      buttonContainer.style.display = "none";
    } else {
      buttonContainer.style.display = "flex";
      buttonContainer.style.right = "3px";
      buttonContainer.style.opacity = "1";
    }

    // Add tooltip
    const tooltipEl = document.createElement("div");
    tooltipEl.classList.add("tooltip");

    // Add the button to the wrapper if event is min. 45 minutes
    if (eventRecord.duration >= 45) {
      wrapper.appendChild(buttonContainer);
    } else if (eventRecord.timerecording === null) {
      const wrapperTooltipContainer = document.createElement("div");
      wrapperTooltipContainer.style.cssText = `
      position: absolute;
      display: flex;
      justify-content: end;
      height: 30px;
      width: calc(${handleButtonContainerWidth({
        calEventId: buttonContainerId,
      })}px - 15px);
      `;

      wrapper.appendChild(wrapperTooltipContainer);

      const root = createRoot(wrapperTooltipContainer);
      root.render(
        <EventButton
          record={eventRecord.timerecording}
          theme={theme}
          calEventId={eventRecord.id.toString()}
          onClick={(event) => {
            event.stopPropagation();

            onTimeRecordingForm({
              element: null,
              buttonElement: wrapperTooltipContainer,
              eventRecord: eventRecord,
            });
          }}
        />
      );
    }
  }

  // Return the wrapper element
  return wrapper;
};

interface IEventButtonProps {
  record: RecordTimeRec | null;
  theme: DyceTheme;
  calEventId?: string;
  onClick: (event: MouseEvent) => void;
}

const EventButton: FunctionComponent<IEventButtonProps> = ({
  record,
  theme,
  calEventId,
  onClick,
}) => {
  return (
    <StaticProvider>
      <Box
        sx={{
          position: calEventId ? "fixed" : "relative",
          top: "1",
          left: "1",
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          transform: calEventId ? "translateY(-16px)" : "inherit",
          flex: 1,
          opacity: calEventId ? 0 : 1,
          width: calEventId
            ? `${handleButtonContainerWidth({ calEventId })}px`
            : "auto",
          transition: "opacity 200ms ease-in-out",
          "&:hover": {
            opacity: 1,
          },
          "& .MuiIconButton-root": {
            height: "20px",
            width: "20px",
            pointerEvents: "painted",
            backgroundColor: record
              ? record.status !== StatusOptions.OPEN
                ? theme.palette.grey[800]
                : theme.palette.common.white
              : theme.palette.primary.main,
            "&:hover": {
              backgroundColor: record
                ? record.status !== StatusOptions.OPEN
                  ? theme.palette.common.black
                  : theme.palette.grey[300]
                : theme.palette.primary.dark,
            },
          },
        }}
      >
        {record ? (
          <IconButton size="small" sx={{ padding: "3px" }} onClick={onClick}>
            <ItemEntriesInfo
              isComplete={record.complete}
              isReleased={record.status !== StatusOptions.OPEN}
              record={record}
            />
          </IconButton>
        ) : (
          <Tooltip
            label={t("calendar.menu.caption.create")}
            urlPath={{ de: "de", en: "en" }}
            position="bottom-end"
          >
            <span
              style={{
                display: "flex",
                height: "20px",
                width: "20px",
                padding: "1px",
                borderRadius: "50%",
                backgroundColor: "white",
              }}
            >
              <IconButton
                sx={{
                  height: "18px !important",
                  width: "18px !important",
                }}
                onClick={onClick}
              >
                <div
                  className="b-widget b-tool b-align-end b-contains-focus"
                  style={{
                    color: "#fff",
                    fontSize: "22px",
                    textAlign: "center",
                    lineHeight: 1,
                  }}
                >
                  <span
                    className={`b-icon b-icon-add
                    `}
                    style={{
                      fontSize: "13px",
                      pointerEvents: "none",
                    }}
                  />
                </div>
              </IconButton>
            </span>
          </Tooltip>
        )}
      </Box>
    </StaticProvider>
  );
};

/**
 * Function to render the day-progress-circle component in the day-header
 */
export const handleDayHeaderInfoProgress = ({
  date,
  events,
  resourceCapacity,
}: {
  /**
   * Date of the day
   */
  date: string;
  /**
   * Events of the week
   */
  events: PopulatedEventModel[];
  /**
   * User resource capacity
   */
  resourceCapacity: ResourceCapacity[] | null;
}): void => {
  const dayDateDocument = document.getElementsByClassName("b-cal-cell-header");
  const startOfWeek = DateTime.fromISO(date).startOf("week");
  const eventsWithRecs = events.filter((event) => event.timerecording !== null);
  const timerecordings = eventsWithRecs.map((event) => event.timerecording!);
  const recordsByDate: [string, RecordTimeRec[]][] = Object.entries(
    timerecordings.reduce(
      (timeRecs, timeRec) => {
        const date = DateTime.fromISO(timeRec.date).toISODate();
        timeRecs[date] = timeRecs[date]
          ? [...timeRecs[date], timeRec]
          : [timeRec];
        return timeRecs;
      },
      {} as { [key: string]: RecordTimeRec[] }
    )
  );
  if (dayDateDocument.length > 0) {
    for (let i = 0; i < dayDateDocument.length; i++) {
      const dayInWeek = startOfWeek.plus({ days: i }).toISODate();
      let duration = 0;
      recordsByDate.forEach(([day, record]) => {
        if (day === dayInWeek) {
          record.forEach((rec) => {
            duration += rec.duration;
          });
        }
      });
      const element = dayDateDocument.item(i);
      // Create new infoContainer
      const infoContainerProgress = document.createElement("div");
      infoContainerProgress.style.cssText = `
          position: absolute;
          pointer-events: none;
          top: 20px;
          width: calc(100% / 7);
          height: 100%;
          display: flex;
          justify-content: end;
        `;
      const dayInfoWrapperProgress = document.createElement("div");
      dayInfoWrapperProgress.id = `dayInfoWrapperProgress_${i}`;
      dayInfoWrapperProgress.style.cssText = `
        display: flex;
        width: 40%;
        height: 100%;
        margin-right: 4%;
        margin-top: 6px;
        justify-content: center;
        align-items: center;
        `;

      // Check if infoContainer already exists => remove it
      const oldInfoContainer = document.getElementById(
        `infoContainerProgress_${i}`
      );
      if (oldInfoContainer) {
        oldInfoContainer.remove();
      }
      const root = createRoot(dayInfoWrapperProgress);
      root.render(
        <ItemEntriesProgress
          date={dayInWeek}
          duration={duration}
          smallSize
          resourceCapacity={resourceCapacity}
        />
      );
      infoContainerProgress.append(dayInfoWrapperProgress);
      if (element) {
        element.id = `dayInfo_${i}`;
        infoContainerProgress.className = "dayDate_element";
        element.append(infoContainerProgress);
      }
      infoContainerProgress.id = `infoContainerProgress_${i}`;
    }
  }
};
