import React, { FunctionComponent, useEffect } from "react";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { withStyles, makeStyles, createStyles } from "@mui/styles";
import { IconButton, Menu, MenuProps } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import CloseIcon from "@mui/icons-material/Close";
// Components
import { Content } from "./application-menu-content";

const appMenuWidth = 374;

const StyledMenu = withStyles(() =>
  createStyles({
    paper: {
      width: appMenuWidth,
      boxShadow: "10px 0px 8px -8px rgba(0,0,0,0.5)",
      marginLeft: -12,
      marginTop: 0,
      height: "100vh",
    },
  })
)((props: MenuProps) => (
  <Menu
    elevation={0}
    marginThreshold={0}
    anchorOrigin={{
      vertical: -12,
      horizontal: 0,
    }}
    {...props}
  />
));

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    appMenu: {
      "& .MuiPaper-root": {
        borderRadius: 0,
        backgroundColor: "#272929",
        paddingLeft: "1.5rem",
        minHeight: "fill-available",
      },
    },
    menuButton: {
      color: "#000000",
    },
    buttonPlaceHolder: {
      height: 24,
      width: 24,
    },
    menuEnlarger: {
      minHeight: 16,
      width: appMenuWidth,
      background: "red",
    },
    title: {
      paddingLeft: 2,
      flexGrow: 1,
      letterSpacing: "0.2em",
      fontWeight: 300,
      color: "#fff",
    },
    appButtonWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
      paddingRight: "1.5rem",
    },
    buttonIcons: {
      height: 27,
      "& .MuiListItemIcon-root": {
        minWidth: 26,
        marginLeft: -6,
      },
    },
    buttons: {
      paddingBottom: "1.5rem",
      "& .MuiButtonBase-root": {
        color: theme.palette.primary.contrastText,
        height: 150,
        width: 150,
        "&:hover": {
          backgroundColor: "#5bfccf",
          boxShadow: "0px 0px 10px 3px rgba(35,99,81,1)",
        },
      },
      "& .MuiListItemIcon-root": {
        color: theme.palette.primary.contrastText,
      },
    },
    adminButton: {
      "& .MuiButtonBase-root": {
        background: "#6e8dff",
      },
    },
    timerecButton: {
      "& .MuiButtonBase-root": {
        background: "#40d3eb",
      },
    },
    placeholderButton: {
      "& .MuiButtonBase-root": {
        background: "#29ffff",
      },
    },
  })
);

export interface ApplicationMenuProps {
  /**
   * triggered when the modal state changes
   */
  onModalChange?: () => void;
  /**
   * link component
   */
  linkAs?: any;
}

export const ApplicationMenu: FunctionComponent<ApplicationMenuProps> = ({
  onModalChange,
  linkAs,
}) => {
  const classes = useStyles();

  // States
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  // UseEffects
  useEffect(() => {
    onModalChange && onModalChange();
  }, [anchorEl]);

  // Handler
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <IconButton
          aria-label="menu"
          onClick={handleClick}
          edge="start"
          className={classes.menuButton}
        >
          {anchorEl === null ? (
            <AppsIcon />
          ) : (
            <div className={classes.buttonPlaceHolder} />
          )}
        </IconButton>
        <StyledMenu
          id="long-menu"
          anchorEl={anchorEl}
          keepMounted
          open={open}
          onClose={handleClose}
          className={classes.appMenu}
        >
          <IconButton
            color="primary"
            aria-label="menu"
            onClick={handleClose}
            edge="start"
            sx={{ marginTop: 0.5 }}
          >
            <CloseIcon />
          </IconButton>
          <h2 className={classes.title}>APPS</h2>
          <div className={classes.appButtonWrapper}>
            <Content handleRef={handleClose} linkAs={linkAs} />
          </div>
        </StyledMenu>
      </div>
    </>
  );
};
