import { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { Periods } from "@dyce/interfaces";
import { PeriodTime, StatisticsPiePeriod } from "@dyce/tnt-api";
import { LoadingSpinner, PlotContainer, PlotHeader } from "@dyce/ui";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Fade } from "@mui/material";
// Components
import { TimePlot } from "./TimePlot";
import { StatisticsPlot } from "./StatisticsPlot";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    flexContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginLeft: "1rem",
      [theme.breakpoints.down(theme.breakpoints.values.dashboardBreakPoint)]: {
        flexWrap: "wrap",
      },
    },
    flexComponent: {
      width: "50%",
      minWidth: 500,
      marginRight: "1rem",
      marginBottom: "1rem",
      [theme.breakpoints.down(theme.breakpoints.values.dashboardBreakPoint)]: {
        width: "100%",
        minWidth: 800,
      },
    },
    noDataContainer: {
      display: "flex",
      justifyContent: "center",
      height: "100%",
      textAlign: "center",
    },
    noDataContent: {
      width: 480,
    },
  })
);

interface IPlotsProps {
  /**
   * If true, the plots will be rendered
   */
  doRender: boolean;
  /**
   * If false, the plots will be faded
   */
  fade: boolean;
  /**
   * Height of the plots, e.g. calculated from responsive behavior
   */
  plotHeight: number;
  /**
   * Data for the bar chart (periodTime) and pie chart (statistics)
   */
  plotsData: {
    periodTime: PeriodTime[];
    statistics: StatisticsPiePeriod;
  } | null;
  // BarChart-Plot
  /**
   * If true, days without capacity will be shown
   */
  showDaysWithoutCapacity?: boolean;
  /**
   * If true, the new date format will be used (shorter string, e.g. 01.02.)
   */
  newDateFormat: boolean;
  /**
   * Responsive behavior
   */
  responsive: {
    /**
     * If true, the device is an iPad
     */
    isIpad: boolean;
    /**
     * If true, the device is an iPad Pro ( > iPad)
     */
    isIpadPro: boolean;
    /**
     * If true, the device is a tablet (iPad or iPad Pro)
     */
    tablet: boolean;
  };
  /**
   * Locale with hyphen, e.g. "de-DE"
   */
  localeHyphen: string;
  /**
   * Language, e.g. "de"
   */
  language: string;
  /**
   * Callback for the bar click
   * @param nextState Bar-Info from the clicked bar
   * @returns void
   */
  onBarClick: (nextState: CategoricalChartState) => void;
  /**
   * Period of the plot
   */
  period?: Periods;
}

export const Plots: FunctionComponent<IPlotsProps> = ({
  doRender,
  fade,
  plotHeight,
  plotsData,
  // TimePlot
  showDaysWithoutCapacity,
  newDateFormat,
  responsive: { isIpad, isIpadPro, tablet },
  localeHyphen,
  language,
  onBarClick,
  period,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      style={{
        paddingTop: "2px",
      }}
    >
      <div className={classes.flexContainer}>
        <div
          className={classes.flexComponent}
          style={{
            height: `${plotHeight + 100}px`,
          }}
        >
          <PlotContainer>
            <PlotHeader
              title={t("timerec.dashboard.plots.title.timeTracking", {
                count: plotsData ? plotsData.periodTime.length : 0,
              })}
            />
            <>
              {doRender ? (
                !fade ? (
                  <Fade
                    in={!fade}
                    timeout={{ appear: 500, enter: 500, exit: 0 }}
                  >
                    <div>
                      {plotsData && (
                        <TimePlot
                          data={doRender ? plotsData.periodTime : []}
                          showEmpty={showDaysWithoutCapacity}
                          newDateFormat={newDateFormat}
                          onBarClick={onBarClick}
                          locale={localeHyphen}
                          language={language}
                          containerHeight={plotHeight}
                          period={period}
                          responsive={{
                            isIpad,
                            isIpadPro,
                            tablet,
                          }}
                        />
                      )}
                    </div>
                  </Fade>
                ) : (
                  <div style={{ height: plotHeight }} />
                )
              ) : (
                <AwaitData fade={fade} plotHeight={plotHeight} />
              )}
            </>
          </PlotContainer>
        </div>
        <div
          className={classes.flexComponent}
          style={{
            height: `${plotHeight + 100}px`,
          }}
        >
          <PlotContainer>
            <PlotHeader
              title={t("timerec.dashboard.plots.title.project", {
                count: plotsData ? plotsData.statistics.jobTotals.length : 0,
              })}
            />
            <>
              {doRender ? (
                !fade ? (
                  <Fade
                    in={!fade}
                    timeout={{ appear: 500, enter: 500, exit: 0 }}
                  >
                    <div>
                      {plotsData && (
                        <StatisticsPlot
                          colors={
                            plotsData.statistics.jobTotals.length > 4
                              ? [
                                  "#0c93c4",
                                  "#0fb4ef",
                                  "#39c1f3",
                                  "#64cff6",
                                  "#90dcf8",
                                  "#c5edfb",
                                ]
                              : ["#0c93c4", "#0fb4ef", "#64cff6", "#c5edfb"]
                          }
                          containerHeight={plotHeight}
                          isIpad={isIpad}
                          visualizationType="jobTotals"
                          data={plotsData.statistics}
                        />
                      )}
                    </div>
                  </Fade>
                ) : (
                  <div style={{ height: plotHeight }} />
                )
              ) : (
                <AwaitData fade={fade} plotHeight={plotHeight} />
              )}
            </>
          </PlotContainer>
        </div>
      </div>
      <div className={classes.flexContainer}>
        <div
          className={classes.flexComponent}
          style={{
            height: `${plotHeight + 100}px`,
          }}
        >
          <PlotContainer>
            <PlotHeader
              title={t("timerec.dashboard.plots.title.billable", {
                count: plotsData
                  ? plotsData.statistics.billableTotals.length
                  : 0,
              })}
            />
            <>
              {doRender ? (
                !fade ? (
                  <Fade
                    in={!fade}
                    timeout={{ appear: 500, enter: 500, exit: 0 }}
                  >
                    <div>
                      {plotsData && (
                        <StatisticsPlot
                          containerHeight={plotHeight}
                          isIpad={isIpad}
                          colors={["#12e49d", "#60f2c1", "#8af6d1", "#d2fbed"]}
                          visualizationType="billableTotals"
                          data={plotsData.statistics}
                        />
                      )}
                    </div>
                  </Fade>
                ) : (
                  <div style={{ height: plotHeight }} />
                )
              ) : (
                <AwaitData fade={fade} plotHeight={plotHeight} />
              )}
            </>
          </PlotContainer>
        </div>
        <div
          className={classes.flexComponent}
          style={{
            height: `${plotHeight + 100}px`,
          }}
        >
          <PlotContainer>
            <PlotHeader
              title={t("timerec.dashboard.plots.title.activities", {
                count: plotsData
                  ? plotsData.statistics.activityTotals.length
                  : 0,
              })}
            />
            <>
              {doRender ? (
                !fade ? (
                  <Fade
                    in={!fade}
                    timeout={{ appear: 500, enter: 500, exit: 0 }}
                  >
                    <div>
                      {plotsData && (
                        <StatisticsPlot
                          containerHeight={plotHeight}
                          isIpad={isIpad}
                          colors={
                            plotsData.statistics.activityTotals.length > 6
                              ? [
                                  "#b24767",
                                  "#bf5e7b",
                                  "#c7728b",
                                  "#cf869c",
                                  "#d79aad",
                                  "#e1b4c1",
                                  "#e6c1cb",
                                  "#ebcdd6",
                                  "#eed5dc",
                                  "#f3e1e7",
                                  "#f8eef1",
                                ]
                              : [
                                  "#b24767",
                                  "#c26581",
                                  "#cf869c",
                                  "#dca7b7",
                                  "#e9c8d2",
                                  "#f6ebee",
                                ]
                          }
                          visualizationType="activityTotals"
                          data={plotsData.statistics}
                        />
                      )}
                    </div>
                  </Fade>
                ) : (
                  <div style={{ height: plotHeight }} />
                )
              ) : (
                <AwaitData fade={fade} plotHeight={plotHeight} />
              )}
            </>
          </PlotContainer>
        </div>
      </div>
    </div>
  );
};

interface IAwaitDataProps {
  fade: boolean;
  plotHeight: number;
}

const AwaitData: FunctionComponent<IAwaitDataProps> = ({
  fade,
  plotHeight,
}) => {
  const { t } = useTranslation();

  return (
    <Fade in={!fade} timeout={{ appear: 500, enter: 500, exit: 0 }}>
      <div>
        <LoadingSpinner
          delay={0}
          withRefreshButton={false}
          text={t("timerec.dashboard.timeWorked.loading")}
          style={{ height: plotHeight }}
        />
      </div>
    </Fade>
  );
};
