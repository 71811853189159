import React, { FunctionComponent } from "react";
// MUI
import { Box } from "@mui/material";

interface IFunctionComponentProps {
  /**
   * If true, component will be shown (!none)
   */
  isOpen: boolean;
  /**
   * If true, marginBottom cut off columnGap
   */
  moreOptionsWrapper?: boolean;
  /**
   * Define size for gap between items
   * @default "medium"
   */
  size?: "small" | "medium";
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const SectionWrapper: FunctionComponent<IFunctionComponentProps> = ({
  isOpen,
  moreOptionsWrapper = false,
  size = "medium",
  children,
}) => {
  return (
    <Box
      sx={{
        display: isOpen ? "flex" : "none",
        gap: size === "small" ? "0.5rem" : "1rem",
        flexDirection: "row",
        marginBottom: moreOptionsWrapper ? "-1rem" : "",
      }}
    >
      {children}
    </Box>
  );
};
