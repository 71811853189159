import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { useHistory } from "react-router-dom";
// Dyce-Lib
import {
  DatePagination,
  Dialog,
  LoadingSpinner,
  PeriodSummary,
  SubHeader,
  useStaticContent,
} from "@dyce/ui";
import { DyceTheme } from "@dyce/theme";
import {
  getCalendarEntries,
  getRecsByWeek,
  removeEntries,
  resetCalendarEntries,
  selectCalendarEntries,
  selectCalendarIsLoading,
  selectCurrentUserResources,
  selectDarkMode,
  selectLanguageCode,
  selectLocaleHyphen,
  templatesAsTwoDimArray,
  selectTimerecsAreLoading,
  selectTimerecsAsArray,
  setAllowNetworkWatcher,
  setDateForSelector,
  setOpenEditor,
  useAppDispatch,
  useAppSelector,
  selectEditorState,
  selectHasResource,
  selectCurrentWorkspace,
} from "@dyce/slices";
import { useUpdateEffect } from "@dyce/hooks";
import { msalInstance } from "@dyce/auth";
import { Periods } from "@dyce/interfaces";
import {
  handleLoadCalendarSelection,
  handleOpenAppointments,
  handlePeriodText,
  handleSaveCalendarSelection,
  handleTotalMinutes,
} from "@dyce/utils";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
// Components
import { CalendarView } from "../../../components/timerecording";
import { ConsentDialog } from "./dialogs/ConsentDialog";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    recordsContainer: {
      ...theme.palette.propsDyce.contentVisualHeight,
    },
    pagination: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      marginTop: "-8px",
    },
    subTitleChip: {
      display: "inherit",
      marginLeft: ".5rem",
    },
    summary: {
      marginTop: "-6px",
      marginRight: "-16px",
    },
  })
);

export const Calendar: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { docuLinks, routes } = useStaticContent();
  const history = useHistory();

  // Set scope for calendar access
  const scopes = ["Calendars.Read"];

  // Selectors
  const isLoadingCalendar = useAppSelector(selectCalendarIsLoading);
  const isLoadingRecords = useAppSelector(selectTimerecsAreLoading);
  const language = useAppSelector(selectLanguageCode);
  const darkMode = useAppSelector(selectDarkMode);
  const locale = useAppSelector(selectLocaleHyphen);
  const userCapacity = useAppSelector(selectCurrentUserResources);
  const timeRecordings = useAppSelector(selectTimerecsAsArray);
  const calendarEntries = useAppSelector(selectCalendarEntries);
  const templates = useAppSelector(templatesAsTwoDimArray);
  const timerecFormOpen = useAppSelector(selectEditorState);
  const resource = useAppSelector(selectHasResource);
  const workspace = useAppSelector(selectCurrentWorkspace);

  // UseStates
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [consentGranted, setConsentGranted] = useState<
    "notChecked" | "granted" | "notGranted"
  >("notChecked");
  const [date, setDate] = useState<string>(DateTime.now().toISODate());
  const [period, setPeriod] = useState<Periods>(handleLoadCalendarSelection());
  // Tour
  const [totalMinutesForSummary, setTotalMinutesForSummary] = useState<
    number[]
  >([]);
  const [startTour, setStartTour] = useState<boolean>(false);
  const [tourTimer, setTourTimer] = useState<number>(0);

  // UseEffects
  useEffect(() => {
    // Consent check
    handleConsentCheck();
  }, []);

  useEffect(() => {
    let refetchCalEntries: NodeJS.Timer;
    if (consentGranted === "granted") {
      // Get calendar entries for the current week
      const startOfWeek = DateTime.fromISO(date).startOf("week").toISO();
      const endOfWeek = DateTime.fromISO(date).endOf("week").toISO();
      refetchCalEntries = setInterval(
        () => {
          dispatch(
            getCalendarEntries({
              from: startOfWeek,
              to: endOfWeek,
            })
          );
        },
        1000 * 60 * 5
      );
    }
    return () => {
      clearInterval(refetchCalEntries);
    };
  }, [consentGranted, date]);

  useEffect(() => {
    if (consentGranted === "notGranted") {
      const checkTourState = async () => {
        const newTour = localStorage.getItem("tour");
        if (newTour) {
          const calendarTour = JSON.parse(newTour);
          const calendarTourGiven = calendarTour.calendar.consent.tour;
          if (!calendarTourGiven) {
            setStartTour(true);
          }
        } else {
          setStartTour(true);
        }
      };
      checkTourState();
    }
  }, [consentGranted]);

  useEffect(() => {
    let countdown: NodeJS.Timer;
    if (startTour) {
      // Count till 5 then start tour
      countdown = setInterval(() => {
        if (tourTimer < 5) {
          setTourTimer((tourTimer) => tourTimer + 1);
        } else {
          clearInterval(countdown);
          setStartTour(false);
        }
      }, 1000);
    }
    return () => {
      clearInterval(countdown);
    };
  }, [startTour]);

  useUpdateEffect(() => {
    if (
      !isLoadingCalendar &&
      !isLoadingRecords &&
      consentGranted !== "notChecked"
    ) {
      setInitLoading(false);
    }
  }, [isLoadingCalendar, isLoadingRecords, consentGranted]);

  useEffect(() => {
    if (consentGranted === "granted") {
      dispatch(resetCalendarEntries());
    }
    // Get calendar entries for the current week
    const startOfWeek = DateTime.fromISO(date).startOf("week").toISO();
    const endOfWeek = DateTime.fromISO(date).endOf("week").toISO();

    if (consentGranted === "granted") {
      dispatch(
        getCalendarEntries({
          from: startOfWeek,
          to: endOfWeek,
        })
      );
    }
    dispatch(
      getRecsByWeek({
        start: new Date(startOfWeek),
      })
    );
    return () => {
      if (consentGranted === "granted") {
        dispatch(resetCalendarEntries());
      }
      dispatch(removeEntries());
      dispatch(setDateForSelector(null));
      dispatch(setOpenEditor(false));
    };
  }, [date, consentGranted, workspace, resource]);

  useEffect(() => {
    if (!isLoadingRecords) {
      if (timeRecordings.length > 0) {
        const totalMinutes = handleTotalMinutes({ recs: timeRecordings });
        setTotalMinutesForSummary(totalMinutes);
      } else {
        setTotalMinutesForSummary([]);
      }
    }
  }, [timeRecordings, isLoadingRecords]);

  // Handler
  const handleConsentCheck = async () => {
    // Check if the user has already given consent
    try {
      const res = await msalInstance.acquireTokenSilent({
        scopes,
      });
      if (res.scopes.length > 0 && scopes.includes(scopes[0])) {
        setConsentGranted("granted");
      } else {
        setInitLoading(false);
      }
    } catch (error) {
      setConsentGranted("notGranted");
      setInitLoading(false);
    }
  };

  const addWeek = () => {
    const newDate = DateTime.fromISO(date).plus({ week: 1 }).toISODate();
    setDate(newDate);
  };

  const subWeek = () => {
    const newDate = DateTime.fromISO(date).minus({ week: 1 }).toISODate();
    setDate(newDate);
  };

  const today = () => {
    const newDate = DateTime.now().toISODate();
    setDate(newDate);
  };

  const handleSaveLocalStorage = ({
    startConsent,
  }: {
    startConsent: boolean;
  }) => {
    localStorage.setItem(
      "tour",
      JSON.stringify({
        calendar: {
          consent: {
            tour: true,
          },
        },
      })
    );
    setTourTimer(0);
    setStartTour(false);
    if (startConsent) {
      history.push(`${routes.SETTINGS_TNT}?highlight=calendarsettings`);
    }
  };

  return (
    <>
      <SubHeader
        title={t("timerec.subHeader.calendar.title")}
        subtitle={
          consentGranted === "granted" ? (
            t("timerec.subHeader.calendar.subtitle.granted", {
              sum: handleOpenAppointments({
                calendarEntries: calendarEntries,
                timeRecordings: timeRecordings,
              }),
            })
          ) : consentGranted === "notGranted" ? (
            <>
              {t("timerec.subHeader.calendar.subtitle.notGranted")}
              <IconButton
                onClick={() => handleSaveLocalStorage({ startConsent: true })}
                size="small"
                color="primary"
                sx={{ marginBottom: "1px", marginLeft: ".25rem" }}
              >
                <ArrowOutwardOutlinedIcon />
              </IconButton>
            </>
          ) : (
            <>{null}</>
          )
        }
        tooltipLabel={t("timerec.subHeader.calendar.info")}
        tooltipUrlPath={docuLinks.timetracking.incompleteTimes.subheader.info}
        isLoading={isLoadingCalendar || isLoadingRecords}
      >
        <div className={classes.pagination}>
          <DatePagination
            forwardHandler={addWeek}
            backwardHandler={subWeek}
            todayHandler={today}
            date={date}
            allowWorkweek
            localeHyphen={locale}
            disabled={timerecFormOpen}
            withCalendar={{
              onSelect: (value) => value && setDate(value.toISODate()),
            }}
            periodOptions={{
              periods: ["workweek", "week"],
              initialPeriod: period,
            }}
            periodSelection
            onChangePeriod={(value) => {
              if (value !== period) {
                handleSaveCalendarSelection(value as "week" | "workweek");
                setPeriod(value);
              }
            }}
          />
        </div>
        <div className={classes.summary}>
          <PeriodSummary
            header={t("timerec.dashboard.timeWorked.total", {
              period: handlePeriodText(period),
            })}
            totalDuration={{
              period: period,
              date: date,
              capacity: userCapacity,
            }}
            totalMinutesWorked={totalMinutesForSummary}
          />
        </div>
      </SubHeader>
      {initLoading ? (
        <LoadingSpinner
          allowNetworkWatcher={(value) =>
            dispatch(setAllowNetworkWatcher(value))
          }
        />
      ) : (
        <>
          <CalendarView
            timeRecordings={timeRecordings}
            calendarEntries={
              consentGranted === "granted" ? calendarEntries : []
            }
            templates={templates}
            styles={classes}
            language={language}
            locale={locale}
            darkMode={darkMode}
            date={DateTime.fromISO(date).toJSDate()}
            period={period}
          />
          <Dialog
            open={startTour && tourTimer >= 5}
            title={t("timerec.calendar.dialog.title")}
          >
            <ConsentDialog
              onCancel={() => handleSaveLocalStorage({ startConsent: false })}
              onSubmit={() => handleSaveLocalStorage({ startConsent: true })}
            />
          </Dialog>
        </>
      )}
    </>
  );
};
