import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { useViewPortHeight } from "@dyce/hooks";
// MUI
import {
  Box,
  Breakpoint,
  Button,
  Dialog as MDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import OpenInFullOutlinedIcon from "@mui/icons-material/OpenInFullOutlined";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";

interface IDialogProps {
  /**
   * Boolean to open/close Dialog
   */
  open: boolean;
  /**
   * Header title for Dialog
   */
  title?: string;
  /**
   * Additional text shown below title
   * @default ""
   */
  text?: string;
  /**
   * Provide further text to inform users for eventually errors,
   * Text is shown in color="red"
   */
  errorText?: string;
  /**
   * Define strings for action buttons, will render if not undefined
   */
  actionButtonLabels?: {
    cancel?: string;
    discard?: string;
    confirm?: string;
  };
  /**
   * boolean to disable Submit button
   */
  isDisabled?: boolean;
  /**
   * Callback function for onSubmit, passes reference when provided @type {fromReference}
   */
  onSubmit?: (
    ref: React.MutableRefObject<HTMLInputElement | null> | null
  ) => void;
  /**
   * Callback function for onCancel, passes reference when provided @type {fromReference}
   */
  onCancel?: (
    ref: React.MutableRefObject<HTMLInputElement | null> | null
  ) => void;
  /**
   * Callback function for onDiscard, passes reference when provided @type {fromReference}
   */
  onDiscard?: (
    ref: React.MutableRefObject<HTMLInputElement | null> | null
  ) => void;
  /**
   * Provide reference e.g. from input, to get back in callback (onSubmit/onCancel)
   */
  fromReference?: React.MutableRefObject<HTMLInputElement | null>;
  /**
   * If true, content width will be 100% (width of children);
   * Does not work with expandOptions prop from @type {expandOptions}
   * @default false
   */
  adjustableWidth?: boolean;
  /**
   * Provide size from @type {Breakpoint}
   * @default "md"
   * @default "xl" if @type {expandable} is true
   */
  size?: Breakpoint;
  /**
   * Properties for expandable Dialog
   */
  expandOptions?: {
    /**
     * Minimum dialog width in not expanded state
     * @default 600
     */
    minWidth?: number;
    /**
     * Callback fired initial, onExpand event and onResize to provide information
     * about current states
     * @param param0 Props about current states
     * @returns void
     */
    onExpand?: ({
      currentWidth,
      currentHeight,
      isExpanded,
    }: {
      currentWidth: number;
      currentHeight: number;
      isExpanded: boolean;
    }) => void;
  };
  /**
   * Children as React.ReactNode
   */
  children?: React.ReactNode;
}

export const Dialog: FunctionComponent<IDialogProps> = ({
  open,
  title,
  text = "",
  errorText,
  children,
  actionButtonLabels,
  isDisabled = false,
  fromReference,
  adjustableWidth = false,
  onCancel,
  onDiscard,
  onSubmit,
  expandOptions,
  size = "md",
}) => {
  const { t } = useTranslation();
  const windowDim = useViewPortHeight();
  const { minWidth = 600, onExpand } = expandOptions || {};

  const dialogHeight = windowDim.height;
  const dialogMaxWidth = windowDim.width < 1536 ? windowDim.width - 65 : 1536;

  // States
  const [maximized, setMaximized] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    onExpand &&
      onExpand({
        currentWidth: maximized ? dialogMaxWidth : minWidth,
        currentHeight: dialogHeight - 350,
        isExpanded: maximized,
      });
  }, [windowDim.height, windowDim.width, maximized]);

  return (
    <MDialog
      open={open}
      onClose={() => onCancel && onCancel(fromReference ? fromReference : null)}
      maxWidth={expandOptions ? "xl" : size}
      disableEnforceFocus={fromReference ? true : false}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          paddingRight: "2rem",
        }}
      >
        {title && <DialogTitle>{title}</DialogTitle>}
        {expandOptions && (
          <IconButton
            size="large"
            color="secondary"
            onClick={() => setMaximized(!maximized)}
          >
            {maximized ? (
              <CloseFullscreenOutlinedIcon />
            ) : (
              <OpenInFullOutlinedIcon />
            )}
          </IconButton>
        )}
      </Box>
      <DialogContent
        sx={{
          width: maximized
            ? `${dialogMaxWidth}px`
            : adjustableWidth
            ? "100%"
            : `${minWidth}px`,
          height: "auto",
          maxHeight: dialogHeight,
          transition: "width 0.5s ease-in-out",
        }}
      >
        <Typography>{text}</Typography>
        {errorText && (
          <Typography
            id="dialog-error-text"
            color="red"
            style={{ marginTop: "1rem", marginBottom: "1rem" }}
          >
            {errorText}
          </Typography>
        )}
        {children}
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button
            onClick={() => onCancel(fromReference ? fromReference : null)}
            color="inherit"
            variant="contained"
            size="small"
          >
            {actionButtonLabels?.cancel
              ? actionButtonLabels.cancel
              : t("common.dialog.cancel")}
          </Button>
        )}
        {onDiscard && (
          <Button
            onClick={() => onDiscard(fromReference ? fromReference : null)}
            color="inherit"
            variant="contained"
            size="small"
          >
            {actionButtonLabels?.discard
              ? actionButtonLabels.discard
              : t("common.dialog.discard")}
          </Button>
        )}
        {onSubmit && (
          <Button
            onClick={() => onSubmit(fromReference ? fromReference : null)}
            color="primary"
            variant="contained"
            size="small"
            disabled={isDisabled || errorText !== undefined}
          >
            {actionButtonLabels?.confirm
              ? actionButtonLabels.confirm
              : t("common.dialog.confirm")}
          </Button>
        )}
      </DialogActions>
    </MDialog>
  );
};
