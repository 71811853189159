import React, { FunctionComponent } from "react";
import {
  selectRequestsAsArray,
  setSlowNetwork,
  resetNetworkState,
  networkSelector,
} from "../../features/networkWatcher/networkWatcherSlice";
import { selectAllowNetworkWatcher } from "./networkWatcherSelector";
// Dyce-Lib
import { useInterval } from "@dyce/hooks";
import { useAppDispatch, useAppSelector } from "../../store/store";

interface INetworkWatcherProps {
  /**
   * ReactNode component to render when conditions for slow network is true
   */
  renderAtSlowNetwork: (value: boolean) => React.ReactNode;
}

export const NetworkWatcher: FunctionComponent<INetworkWatcherProps> = ({
  renderAtSlowNetwork,
}) => {
  const dispatch = useAppDispatch();
  const requests = useAppSelector(selectRequestsAsArray);
  const allowNetworkWatcher = useAppSelector(selectAllowNetworkWatcher);
  const { slowNetwork } = useAppSelector(networkSelector);

  useInterval(() => {
    let slow = false;

    requests.forEach((request) => {
      if (Date.now() > request.start + 4000) slow = true;
    });

    if (slow && !slowNetwork && allowNetworkWatcher) dispatch(setSlowNetwork());
    if (!slow && slowNetwork) dispatch(resetNetworkState());
  }, 1000);

  return <>{renderAtSlowNetwork(slowNetwork)}</>;
};
