import {
  Customer,
  PopulatedJob,
  PopulatedJobPlanningLine,
  PopulatedJobTask,
} from "@dyce/tnt-api";
import { IDatagridRow } from "../../types";
import { LookUpMapping } from "@dyce/interfaces";

export class InitialData {
  static getCustomer<T = Customer>(
    data: LookUpMapping
  ): { id: string; entry: IDatagridRow<T>[] } {
    return {
      id: data.customer ? data.customer.id : "",
      entry: [
        {
          id: data.customer ? data.customer.id : "",
          cells: { ...(data.customer as any) },
        },
      ],
    };
  }

  static getJob<T = PopulatedJob>(
    data: LookUpMapping
  ): {
    id: string;
    entry: IDatagridRow<T>[];
  } {
    return {
      id: data.job ? data.job.id : "",
      entry: [
        {
          id: data.job ? data.job.id : "",
          cells: { ...(data.job as any) },
        },
      ],
    };
  }

  static getJobTask<T = PopulatedJobTask>(
    data: LookUpMapping
  ): {
    id: string;
    entry: IDatagridRow<T>[];
  } {
    return {
      id: data.jobTask ? data.jobTask.id : "",
      entry: [
        {
          id: data.jobTask ? data.jobTask.id : "",
          cells: {
            ...(data.jobTask as any),
            jobNo: data.jobTask && data.jobTask.job ? data.jobTask.job.no : "",
            jobDescription:
              data.jobTask && data.jobTask.job
                ? data.jobTask.job.description
                : "",
          },
        },
      ],
    };
  }

  static getActivity<T = PopulatedJobPlanningLine>(
    data: LookUpMapping
  ): {
    id: string;
    entry: IDatagridRow<T>[];
  } {
    let uniqueId = "";
    if (
      data.jobPlanningLine &&
      data.jobPlanningLine.id &&
      data.tntModelLine &&
      data.tntModelLine.id
    ) {
      uniqueId = data.jobPlanningLine.id + data.tntModelLine.id;
    } else if (data.jobPlanningLine && data.jobPlanningLine.id) {
      uniqueId = data.jobPlanningLine.id;
    }

    return {
      id: uniqueId,
      entry: [
        {
          id: uniqueId,
          cells: {
            ...(data.jobPlanningLine as any),
            description:
              data.jobPlanningLine && data.jobPlanningLine.tntModelLine
                ? data.jobPlanningLine.tntModelLine.description
                : data.jobPlanningLine && data.jobPlanningLine.description,
            jobTaskDescription:
              data.jobPlanningLine && data.jobPlanningLine.jobTask
                ? data.jobPlanningLine.jobTask.description
                : data.jobTask
                ? data.jobTask.description
                : "",
            jobPlanningLineDescription: data.jobPlanningLine
              ? data.jobPlanningLine.description
              : "",
            serviceBillingType: data.jobPlanningLine
              ? data.jobPlanningLine.serviceBillingType
              : "",
            billableInfo:
              data.jobPlanningLine && data.jobPlanningLine.tntModelLine
                ? data.jobPlanningLine.tntModelLine.billable
                : "",
            jobTask:
              data.jobPlanningLine && data.jobPlanningLine.jobTask
                ? data.jobPlanningLine.jobTask
                : data.jobTask
                ? data.jobTask
                : null,
          },
        },
      ],
    };
  }
}
