import { FunctionComponent } from "react";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Paper, Divider, useTheme } from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    itemPaper: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
      height: theme.palette.propsDyce.timeRecItemHeight.commonHeight,
      padding: "0 1.5rem",
    },
  })
);

interface IRecordListItemDummyProps {
  /**
   * If true, <Divider /> will be shown at bottom of component
   */
  showDivider: boolean;
}

export const RecordListItemDummy: FunctionComponent<
  IRecordListItemDummyProps
> = ({ showDivider }) => {
  const classes = useStyles();
  const theme = useTheme<DyceTheme>();

  return (
    <>
      <Paper
        elevation={theme.palette.propsDyce.paperDesign.elevation}
        className={classes.itemPaper}
      />
      {showDivider && <Divider />}
    </>
  );
};
