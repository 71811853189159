/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  useState,
  useEffect,
  useRef,
  FunctionComponent,
  MutableRefObject,
} from "react";
// Helpers
import { DateTime } from "luxon";
import { checkTimeInput, dateFromString, parseInput } from "./utils";
import { RefCallBack } from "react-hook-form";
import moment, { Moment } from "moment";
// MUI
import { TextField } from "@mui/material";
import { LocalizationProvider, DesktopTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface ITimeFieldProps {
  /**
   * Default and/or selected value
   */
  style?: string;
  /**
   * If true, textfield is initial selected / autoFocus={true}
   * @default false
   */
  isSelected?: boolean;
  /**
   * Textfield-label
   */
  label: string;
  /**
   * Default and/or selected value
   */
  value: DateTime | null | string;
  /**
   * Variant of textfield
   */
  variant: "standard" | "filled" | "outlined" | undefined;
  /**
   * If true, Textfield is disabled
   * @default false
   */
  isDisabled?: boolean;
  /**
   * Current selected locale with hyphen, e.g. 'en-GB'
   */
  localeHyphen?: string;
  /**
   * If true, the input will take up the full width of its container
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Add this prop if the input is required
   * @default false
   */
  required?: boolean;
  /**
   * Callback fired onChange event
   */
  onChange: (value: string | null) => void;
  /**
   * Callback fired onBlur event
   */
  onBlur: (value: any, ref: MutableRefObject<HTMLInputElement | null>) => void;
  /**
   * React-Hook-Form reference hook, e.g. to make component focusable
   */
  refHook?: RefCallBack;
  /**
   * Set tabIndex to focus correct field for index-based navigation
   */
  tabIndex?: number;
  /**
   * Unique ID for testing field
   * @default "test-cypress-id"
   */
  testId?: string;
  /**
   * Define size of textfield
   * @default "medium"
   */
  size?: "small" | "medium";
}

export const TimeFieldMoment: FunctionComponent<ITimeFieldProps> = ({
  style,
  isSelected = false,
  label,
  variant,
  value,
  isDisabled = false,
  localeHyphen = "de-DE",
  fullWidth = false,
  required = false,
  onChange,
  onBlur,
  refHook,
  tabIndex,
  size = "medium",
  testId = "test-cypress-id",
  ...rest
}) => {
  // Refs
  const inputRef = useRef<HTMLInputElement | null>(null);

  const getDateTime = (input: DateTime | null | string): Moment | null => {
    if (DateTime.isDateTime(input)) {
      return moment(input.toISO());
    }

    if (input) {
      return moment(input as string);
    }

    return null;
  };

  // States
  const [date, setDate] = useState<Moment | null>(getDateTime(value));
  const [dateString, setDateString] = useState<string>(
    getDateTime(value) ? getDateTime(value)!.format("HH:mm") : ""
  );
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [focusField, setFocusField] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    if (value) {
      if (typeof value === "string") {
        if (value !== "Error") {
          setDate(moment(value));
          setDateString(moment(value).format("HH:mm"));
          onChange(value);
        }
      } else {
        setDate(moment(value.toISO()));
        setDateString(value.toFormat("HH:mm"));
        onChange(value.toISO());
      }
    } else {
      setDate(null);
      setDateString("");
      onChange(null);
    }
  }, [value]);

  useEffect(() => {
    if (date && !isOpen && focusField) {
      onChange(date.toISOString());
      inputRef && inputRef.current && (inputRef.current as any).select();
      setFocusField(false);
    }
  }, [isOpen, focusField]);

  // Handler
  const handleInputChange = (val: null | Moment, input?: string) => {
    // @ts-ignore
    if (val && val.isValid && input !== undefined) {
      setDateString(input);
      // TODO: Check behavior after merging with main (great bugfix there) - 2023-04-12 MOR
      // Force state change, to update component also with same entry (08:00 => 8 onBlur())
      // setDate(null);
    } else {
      // @ts-ignore
      if (val && val.isValid) {
        setDate(val);
        setDateString(val.format("HH:mm"));
      } else {
        setDateString("Error");
      }
    }
  };

  const handleOnBlur = () => {
    if (dateString.length > 0 && dateString !== "Error") {
      let parsingInput: any = dateString;
      if (dateString.includes(":")) {
        if (dateString.includes("00:")) {
          parsingInput = dateString.toString().replace("00:", "00");
        } else {
          parsingInput = dateString.toString().replace(":", "");
        }
      }
      const autocomplete = parseInput(parsingInput.toString());

      if (checkTimeInput(autocomplete)) {
        const autoDate = dateFromString(autocomplete);
        if (DateTime.isDateTime(autoDate) && autoDate.isValid) {
          setDate(moment(autoDate.toISO()));
          onChange(autoDate.toISO());
        } else if (!DateTime.isDateTime(autoDate)) {
          setDate(null);
          onChange(null);
        }
      } else {
        onChange("Error");
        setDateString("Error");
      }
    } else {
      if (required) {
        onChange("Error");
        setDateString("Error");
      } else {
        setDateString("");
        onChange(null);
      }
      setDate(null);
    }
    onBlur(date, inputRef);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={localeHyphen}>
      <DesktopTimePicker
        onClose={() => setIsOpen(false)}
        onOpen={() => {
          setIsOpen(true);
          setFocusField(true);
        }}
        inputRef={(e: HTMLInputElement) => {
          refHook && refHook(e);
          inputRef.current = e;
        }}
        disabled={isDisabled}
        // disableCloseOnSelect={false}
        showToolbar={true}
        orientation="portrait"
        value={date}
        data-testid="timepicker"
        onChange={handleInputChange}
        label={
          label?.length === 0 ? (
            <ErrorOutlineIcon
              style={{
                color: "#f44336",
                transform: "scale(1.2)",
              }}
            />
          ) : (
            label
          )
        }
        OpenPickerButtonProps={{ tabIndex: -1 }}
        disableMaskedInput={true}
        InputProps={{
          ...rest,
          onBlur: handleOnBlur,
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            id={testId}
            size={size}
            inputProps={{
              ...params.inputProps,
              tabIndex: tabIndex,
            }}
            fullWidth={fullWidth}
            autoFocus={isSelected}
            onFocus={(e) => e.target.select()}
            className={style}
            variant={variant}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
