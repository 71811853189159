// Helper
import i18next from "i18next";
// Dyce-Helper
import { PopulatedJob } from "@dyce/tnt-api";
import { IDatagridColumn, IDatagridRow } from "@dyce/ui";

const expand = {
  customer: { select: ["id", "name", "no"] },
};

const createColumns = (): Record<string, IDatagridColumn> => {
  // Specify Columnheader in Objectkey: "name" and change editable to true if needed
  return {
    no: {
      key: "no",
      name: i18next.t("components.lookup.columnHeader.job.jobNo"),
      editable: true,
      width: 0.4,
      searchable: true,
      display: true,
      sortable: true,
    },
    description: {
      key: "description",
      name: i18next.t("components.lookup.columnHeader.job.description"),
      editable: true,
      searchable: true,
      display: true,
      sortable: true,
    },
  };
};

function createRows<T>(entries: PopulatedJob[]): IDatagridRow<T>[] {
  // set Columnvalue with specified DataBase Column
  const row: IDatagridRow[] = entries.map((entry) => {
    return {
      id: entry.id,
      cells: {
        no: entry.no,
        description: entry.description,
        skells: false,
        customer: {
          id: entry.customer && entry.customer.id,
          name: entry.customer && entry.customer.name,
          no: entry.customer && entry.customer.no,
        },
      },
    };
  });

  return row;
}

const createSkeletonRows = (count: number): IDatagridRow<any>[] => {
  const row: IDatagridRow<any>[] = [...Array(count)].map((_, index) => {
    return {
      id: index.toString(),
      cells: {
        no: "",
        description: "",
        skells: true,
        customer: {
          id: "",
          name: "",
          no: "",
        },
      },
    };
  });

  return row;
};

const getDbColumnSearch = (debouncedValue: string): any[] => {
  // Define DataBase Column Names for search Query (Objectkey)
  const firstDbColumn: { no: { contains: string } } = {
    no: { contains: debouncedValue },
  };
  const secondDbColumn: { description: { contains: string } } = {
    description: { contains: debouncedValue },
  };

  return [firstDbColumn, secondDbColumn];
};

const getSqlColumnName = (
  sortColumn: string,
  sortDirection: string
): string => {
  const sqlColumn = {
    no: `no ${sortDirection}`,
    description: `description ${sortDirection}`,
    default: `no ${sortDirection}`,
  };

  return `${
    sqlColumn[sortColumn as "no" | "description"] || sqlColumn["default"]
  }`;
};

export default {
  expand,
  getDbColumnSearch,
  createColumns,
  createRows,
  createSkeletonRows,
  getSqlColumnName,
};
