import { createSelector } from "@reduxjs/toolkit";
import { RootState, UserStateSlice } from "../../types/types";
import { InvalidUser } from "@dyce/interfaces";

// Different user calls
export const selectCurrentUser = (state: RootState) => state.user.currentUser;
export const selectUserById = (state: RootState) => state.user.userById;

export const selectHasResource = (state: RootState) =>
  Boolean(state.user.currentUser.resource);
export const selectCurrentInstance = (state: RootState) => state.user.instance;
export const selectCurrentCompany = (state: RootState) => state.user.company;
export const selectInstances = (state: RootState) => state.user.instances;
export const selectCurrentWorkspace = createSelector(
  [selectCurrentInstance, selectCurrentCompany],
  (instance, company) => ({ instance, company })
);
export const selectUserValidated = (state: RootState) =>
  state.user.instanceValidated;

export const selectWorkspaceLoaded = createSelector(
  [selectCurrentWorkspace, selectUserValidated],
  ({ instance, company }, validated) => {
    if (instance === null || company === null) return false;
    if (validated === null) return false;
    if (validated === false) return false;

    return true;
  }
);
export const selectIsInstanceValidating = (state: RootState) =>
  state.user.isValidatingInstance;

export const selectHasUserTimerecRole = createSelector(
  selectCurrentUser,
  (user) => {
    return Boolean(user.roles.find((r) => r.type === "timerec"));
  }
);

export const selectIsUserLoading = (state: RootState): boolean =>
  state.user.isLoading;

export const selectIsCurrentUserLoading = (state: RootState) =>
  state.user.isLoadingUser;

export const fullUserSelector = (state: RootState): UserStateSlice =>
  state.user;

export const selectInvalidGrant = (state: RootState): boolean =>
  state.user.invalidGrant;

export const selectInvalidGrantUserInfo = (
  state: RootState
): InvalidUser | null => state.user.invalidGrantUserInfo;
