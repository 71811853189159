import { FunctionComponent, useEffect, useRef, useState } from "react";
// Helper
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "../timetracking/helper/style.css";
import useVisibilitySensor from "@rooks/use-visibility-sensor";
import { DateTime } from "luxon";
// DYCE-Lib
import { DyceTheme } from "@dyce/theme";
import { RecordListHeader } from "../timetracking/record-list-header";
import {
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  TemplateCategory,
  ValidatedRecord,
  JobViewEntry,
} from "@dyce/tnt-api";
import { TaskSettings } from "@dyce/slices";
// MUI
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import { Box, Paper } from "@mui/material";
import { RecordListItemDummy } from "../../skeletons/record-item-entries/record-list-item-dummy";
// Components
import { TaskListItem } from "./task-list-item";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    itemsBox: {
      background: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      width: "100%",
      minWidth: 864,
      marginBottom: "1.625rem",
      scrollMarginTop: "200px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
    },
  })
);

interface ITaskListProps {
  /**
   * String shown in header, when no date is provided
   */
  headerText?: TemplateCategory;
  /**
   * Grouping of tasks, e.g. "Customer"
   */
  groupedBy: TaskSettings;
  /**
   * Array of tasks to be displayed
   */
  tasks: JobViewEntry[];
  /**
   * Location information, e.g. "en-US"
   */
  locale: string;
  /**
   * Props forwarded to TemplateDatePicker; Provide props when needed in ListItemEntry
   */
  saveAsTimeRecordProps: {
    onSave: (
      entry: RecordEntry & Partial<RecordTemplate & RecordTimeRec>,
      isTimeRecord?: boolean
    ) => Promise<{
      newEntry: RecordEntry | RecordTemplate | null;
      error: boolean;
    }>;
    validateTemplateObject?: (
      template: RecordTemplate
    ) => Promise<ValidatedRecord>;
    clearLatestCreatedId: () => void;
    openCreatedRecording: (value: boolean) => void;
    deleteRecord: (id: string) => Promise<boolean>;
    openInTimerecordings: boolean;
  };
}

export const TaskList: FunctionComponent<ITaskListProps> = ({
  headerText,
  groupedBy,
  locale,
  saveAsTimeRecordProps,
  tasks,
}) => {
  const classes = useStyles();
  const theme = useTheme<DyceTheme>();

  // Refs
  const renderInView = useRef(null);
  const { isVisible } = useVisibilitySensor(renderInView, {
    intervalCheck: 50,
    scrollCheck: true,
    partialVisibility: true,
    scrollDebounce: 200,
    resizeCheck: true,
    resizeDebounce: 250,
    shouldCheckOnMount: true,
  });

  // States
  const [showFading, setShowFading] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    let fade: NodeJS.Timeout;
    if (isVisible) {
      fade = setTimeout(() => {
        setShowFading(true);
      }, 100);
    } else {
      setShowFading(false);
    }
    return () => {
      clearTimeout(fade);
    };
  }, [isVisible]);

  return (
    <Box
      className={classes.itemsBox}
      sx={{
        boxShadow: "4px 8px 16px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Paper
        elevation={theme.palette.propsDyce.paperDesign.elevation}
        ref={renderInView}
      >
        <div className={classes.container}>
          <RecordListHeader
            isTemplate={false}
            date={""}
            markHeader
            headerText={headerText}
            totalHours={0}
            openEditor={() => {}}
            hasAdditions={false}
            disableButton={true}
            hasEntries={tasks.length > 0}
          />
        </div>
        <TransitionGroup>
          {tasks.map((task, index) => (
            <CSSTransition key={task.id} timeout={125} classNames="item">
              {!isVisible ? (
                <RecordListItemDummy showDivider={index + 1 !== tasks.length} />
              ) : (
                <TaskListItem
                  groupedBy={groupedBy}
                  task={task}
                  showDivider={index < tasks.length - 1}
                  locale={locale}
                  saveAsTimeRecordProps={saveAsTimeRecordProps}
                  showFading={showFading}
                />
              )}
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Paper>
    </Box>
  );
};
