// Helper
import { t } from "i18next";
import { DateTime } from "luxon";
// Dyce-Lib
import { NonBillableReasonOptions } from "@dyce/tnt-api";

/**
 * Function to calculate worktime for every single Timeentry (Item) in List
 * @param duration workTime for Item in minutes
 * @param breakTime breakTime for Item in minutes
 * @returns String in hour format to display net working time ("0:00")
 */
export const calculateHours = ({
  duration,
  allowMoreThanADay = false,
}: {
  duration: number;
  allowMoreThanADay?: boolean;
}): string => {
  let hours = "0:00";
  if (duration > 0) {
    if (duration > 1440 && !allowMoreThanADay) {
      hours = "24:++";
    } else if (duration === 1440) {
      hours = "24:00";
    } else {
      const rhours = Math.floor(duration / 60);
      const time = DateTime.local(2020, 1, 1)
        .plus({ minutes: duration })
        .toFormat("mm");
      hours = `${rhours}:${time}`;
    }
  }

  return hours;
};

/**
 * Function to calculate the percentage of working hours of the current entry
 * from the whole working day
 * @param duration workTime for Item in minutes
 * @param workHours @see {getWorkHours}
 * @returns Caculated percantage for Item as number (0-100)
 */
export const calculateValue = (duration: number, workHours: number): number => {
  let total = 0;
  if (duration > 0) {
    total = (duration / 60 / workHours) * 100;
  }

  return Math.min(total, 100);
};

/**
 * Function to provide Optionlabel for Autocomplete field in correct language
 * @param key Value from selected option at Autocomplete field
 * @param t Translation function
 * @returns String from selected option in correct language
 */
export const getTitle = (key: string, fromDashboard = false) => {
  switch (key) {
    case NonBillableReasonOptions.NONE: {
      if (fromDashboard) {
        return t("timerec.dailyRecording.add.nonbillable.noneBillable");
      } else {
        return t("timerec.dailyRecording.add.nonbillable.none");
      }
    }
    case NonBillableReasonOptions.INTERNAL:
      return t("timerec.dailyRecording.add.nonbillable.internal");
    case NonBillableReasonOptions.GOODWILL:
      return t("timerec.dailyRecording.add.nonbillable.goodwill");
    case NonBillableReasonOptions.WARRANTY:
      return t("timerec.dailyRecording.add.nonbillable.warranty");

    default:
      return "";
  }
};
