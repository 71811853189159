/**
 * Function to provide the Jira addon key based on the current host.
 * declare environment variables in jira-frontend project named:
 * NX_JIRA_ADDON_KEY
 * NX_JIRA_ADDON_KEY_DEV
 * NX_JIRA_ADDON_KEY_LOCAL
 * and set the values accordingly!
 * @returns Jira addon key
 */
export const handleAddonKey = (): string => {
  const host = window.location.host;
  switch (host) {
    case "tunnel.dyce.dev":
      return process.env["NX_JIRA_ADDON_KEY_LOCAL"] as string;
    case "jira.dyce.dev":
      return process.env["NX_JIRA_ADDON_KEY_DEV"] as string;
    case "jira.dyce.cloud":
      return process.env["NX_JIRA_ADDON_KEY"] as string;
    default:
      return process.env["NX_JIRA_ADDON_KEY"] as string;
  }
};
