import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  AsyncThunk,
} from "@reduxjs/toolkit";
import { pull } from "lodash";
import { ActionMeta } from "@dyce/tnt-api";
import { tntApiHelper } from "../../apiHelper";
import { OnboardingStateSlice, RootState } from "../../types/types";

export const createNew: AsyncThunk<
  any,
  undefined,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<any, undefined, { state: RootState; rejectValue: Error }>(
  "onboarding/createNewStatus",
  async (_, { getState, rejectWithValue }) => {
    try {
      const client = await (
        await tntApiHelper(getState)
      ).getOnboardingService();

      return await client.createNewEntry();
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getOnboardingState: AsyncThunk<
  any,
  number | null,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  any,
  number | null,
  { state: RootState; rejectValue: Error }
>(
  "onboarding/getStateStatus",
  async (arg, { getState, rejectWithValue }) => {
    try {
      const client = await (
        await tntApiHelper(getState)
      ).getOnboardingService();

      const { randomNumber } = getState().onboarding;
      if (randomNumber) {
        return await client.getOnboardingStateEntry(randomNumber);
      } else {
        return null;
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (arg, { getState }) => {
      const { requests, randomNumber } = getState().onboarding;
      // start request only if random number exists
      // start request only if no previous request is still in the queue
      if (Object.values(requests).length > 0 || randomNumber === null) {
        return false;
      } else {
        return void {};
      }
    },
  }
);

const initialState: OnboardingStateSlice = {
  entities: {},
  requests: [],
  errors: {},
  randomNumber: null,
  instanceState: null,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState: initialState,
  reducers: {
    resetState(state) {
      state.randomNumber = null;
      state.instanceState = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createNew.fulfilled, (state, action) => {
        state.randomNumber = action.payload;
      })
      .addCase(getOnboardingState.fulfilled, (state, action) => {
        state.instanceState = action.payload;
      })
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("fulfilled"),
        (state, action) => {
          pull(state.requests, action.meta.requestId);
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("pending"),
        (state, action) => {
          state.requests.push(action.meta.requestId);
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("rejected"),
        (state, action) => {
          pull(state.requests, action.meta.requestId);
          if (action.payload && action.payload.status !== 900)
            state.instanceState = 99;
        }
      ),
});

export const { resetState } = onboardingSlice.actions;

export default onboardingSlice.reducer;
