import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { UserConsent, getUserConsent, setUserConsent } from "@dyce/utils";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Button, Paper, Slide } from "@mui/material";
// Components
import { ConsentText } from "./consent-text";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      position: "absolute",
      bottom: "3rem",
      right: "2%",
      left: "2%",
      height: "auto",
      zIndex: theme.zIndex.fab + 1,
      "& .MuiPaper-root": {
        backgroundColor:
          theme.palette.mode === "light"
            ? "rgb(186, 234, 255)"
            : "rgb(0, 105, 137)",
      },
    },
    contentContainer: {
      color: theme.palette.primary.contrastText,
      padding: "1rem",
    },
    bannerFooter: {
      display: "flex",
      justifyContent: "flex-end",
      paddingTop: "1rem",
      gap: "1rem",
    },
  })
);

export const ConsentBanner: FunctionComponent = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  // States
  const [isBannerMounted, setIsBannerMounted] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    const consent = getUserConsent();
    if (consent == UserConsent.NOT_ANSWERED) setIsBannerMounted(true);
  }, []);

  return (
    <div
      className={classes.container}
      style={{
        transition: "left 0.2s ease-in-out",
        overflow: isBannerMounted ? "visible" : "hidden",
      }}
    >
      <Slide direction="up" in={isBannerMounted} mountOnEnter unmountOnExit>
        <Paper className={classes.contentContainer} elevation={6}>
          <ConsentText
            showWithSettingsInfo
            onSettingsLink={() => {
              setIsBannerMounted(false);
            }}
          />
          <div className={classes.bannerFooter}>
            <Button
              variant="contained"
              color="inherit"
              size="small"
              onClick={() => {
                setUserConsent(UserConsent.NOT_GIVEN);
                setIsBannerMounted(false);
              }}
            >
              {t("consent.cookieBanner.button.decline")}
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setUserConsent(UserConsent.GIVEN);
                setIsBannerMounted(false);
              }}
            >
              {t("consent.cookieBanner.button.accept")}
            </Button>
          </div>
        </Paper>
      </Slide>
    </div>
  );
};
