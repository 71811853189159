import { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { getMissingValuesList } from "./utils";
// Dyce-Lib
import { Tooltip, useStaticContent } from "@dyce/ui";
import { DyceTheme } from "@dyce/theme";
import { RecordTimeRec, RecordTemplate } from "@dyce/tnt-api";
import { MonetizationOffOutlinedIcon } from "@dyce/ui-assets";
// MUI
import { teal, red } from "@mui/material/colors";
import { makeStyles, createStyles } from "@mui/styles";
import { List, ListItem, ListItemText, useTheme } from "@mui/material";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";

const useStyles = makeStyles(() =>
  createStyles({
    moneyIcon: {
      color: teal["A400"],
    },
    moneyIconReleased: {
      color: teal["A100"],
    },
    errorIcon: {
      color: red[300],
    },
  })
);

interface IItemEntriesInfoProps {
  /**
   * Is the record released? (!StatusOptions.OPEN)
   */
  isReleased: boolean;
  /**
   * Is the record complete? (all values are set)
   */
  isComplete: boolean;
  /**
   * Time- or Template record
   */
  record: RecordTimeRec | RecordTemplate;
}

export const ItemEntriesInfo: FunctionComponent<IItemEntriesInfoProps> = ({
  isReleased,
  isComplete,
  record,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { docuLinks } = useStaticContent();
  const theme = useTheme<DyceTheme>();

  // Tooltip texts
  const tooltipTextMoney = t(
    "timerec.dailyRecording.item.tooltip.completeAndCalculable"
  );
  const tooltipTextMoneyOff = t(
    "timerec.dailyRecording.item.tooltip.completeAndNonCalculable"
  );

  const tooltipError = () => {
    const valueList = getMissingValuesList(record);
    return (
      <List dense sx={{ padding: "0px", marginBottom: "-1rem" }}>
        {valueList.map((value) => (
          <ListItem key={value} sx={{ padding: "0px 8px" }}>
            <ListItemText primary={value} />
          </ListItem>
        ))}
      </List>
    );
  };

  if (!isComplete) {
    return (
      <Tooltip
        header={t("tooltip.records.incompleteList")}
        label={tooltipError()}
        urlPath={docuLinks.timetracking.timerecordings.records.itemInfo.error}
      >
        <ErrorOutlineOutlinedIcon className={classes.errorIcon} />
      </Tooltip>
    );
  } else {
    if (record.durationBillable > 0) {
      return (
        <Tooltip
          label={tooltipTextMoney}
          urlPath={
            docuLinks.timetracking.timerecordings.records.itemInfo.billable
          }
        >
          <MonetizationOnOutlinedIcon
            className={
              isReleased ? classes.moneyIconReleased : classes.moneyIcon
            }
          />
        </Tooltip>
      );
    } else {
      return (
        <Tooltip
          label={tooltipTextMoneyOff}
          urlPath={
            docuLinks.timetracking.timerecordings.records.itemInfo.notBillable
          }
        >
          <span
            style={{
              transform: "translateY(2px)",
            }}
          >
            <MonetizationOffOutlinedIcon
              fill={
                isReleased ? theme.palette.grey[300] : theme.palette.grey[500]
              }
            />
          </span>
        </Tooltip>
      );
    }
  }
};
