import { FunctionComponent } from "react";
// Helper
import clsx from "clsx";
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { Tooltip } from "../tooltip/tooltip";
import { useStaticContent } from "../static-provider/static-provider";
// MUI
import { useScrollTrigger, Zoom, Box, Fab } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

interface IScrollTopProps {
  /**
   * Children as React.ReactNode
   */
  children?: React.ReactNode;
}

export const ScrollTop: FunctionComponent<IScrollTopProps> = ({ children }) => {
  const { t } = useTranslation();
  const {
    docuLinks,
    isMobile: { mobile },
  } = useStaticContent();
  const scrollContainer = document.getElementById("rootContainer") ?? undefined;
  const trigger = useScrollTrigger({
    target: scrollContainer,
    disableHysteresis: true,
    threshold: 60, // Offset from top when Button should be shown
  });

  // Handler
  const handleClick = () => {
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <Zoom in={trigger && !mobile}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        className={clsx("mui-fixed")}
      >
        {!children ? (
          <Tooltip
            label={t("tooltip.records.scrollToTop")}
            urlPath={docuLinks.timetracking.timerecordings.records.scrollToTop}
            position="left"
          >
            <Fab color="primary" size="small" aria-label="scroll back to top">
              <KeyboardArrowUpIcon />
            </Fab>
          </Tooltip>
        ) : (
          children
        )}
      </Box>
    </Zoom>
  );
};
