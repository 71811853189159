import React from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { selectSideDrawerLeft, useAppSelector } from "@dyce/slices";
import { DyceTheme } from "@dyce/theme";
import { MenuItemCreator, useStaticContent } from "@dyce/ui";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import FaceIcon from "@mui/icons-material/Face";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    content: theme.palette.propsDyce.drawerLeftContent,
  })
);

export const SettingsMenu: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { routes } = useStaticContent();

  // Selectors
  const drawerLeftToggled = useAppSelector(selectSideDrawerLeft);

  return (
    <>
      <MenuItemCreator
        cssItem={classes.content}
        url={routes.SETTINGS}
        icon={<FaceIcon />}
        label={t("settings.nav.general")}
        tooltipLabel={
          !drawerLeftToggled ? t("settings.nav.general") : undefined
        }
      />
      <MenuItemCreator
        cssItem={classes.content}
        url={routes.SETTINGS_TNT}
        icon={<AccessTimeIcon />}
        label={t("settings.nav.timerec")}
        tooltipLabel={
          !drawerLeftToggled ? t("settings.nav.timerec") : undefined
        }
      />
    </>
  );
};
