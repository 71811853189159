// Helper
import { t } from "i18next";
// Dyce-Lib
import { RecordTimeRec, RecordTemplate } from "@dyce/tnt-api";
import { Duration } from "luxon";

/**
 * Function to find missing values (empty fields) in timerecs
 * @param record Timerecording object
 * @returns Stringarray with missing values in specified language
 */
export const getMissingValuesList = (
  record: RecordTimeRec | RecordTemplate
): string[] => {
  const valueListTranslation = {
    customer: t("timerec.dailyRecording.validate.fieldName.customer"),
    duration:
      t("timerec.dailyRecording.add.duration")[0].toUpperCase() +
      t("timerec.dailyRecording.add.duration").substring(1).toLowerCase(),
    description:
      t("timerec.dailyRecording.add.description")[0].toUpperCase() +
      t("timerec.dailyRecording.add.description").substring(1).toLowerCase(),
    job: t("timerec.dailyRecording.validate.fieldName.job"),
    jobTask: t("timerec.dailyRecording.validate.fieldName.jobTask"),
  };

  const recordArray = Object.entries(record);
  const valueListTranslationArray = Object.entries(valueListTranslation);
  const valueList: string[] = [];

  for (let i = 0; i < recordArray.length; i++) {
    for (let j = 0; j < valueListTranslationArray.length; j++) {
      if (recordArray[i][0] === valueListTranslationArray[j][0]) {
        if (
          recordArray[i][1] === 0 ||
          recordArray[i][1] === null ||
          (recordArray[i][0] === "description" &&
            recordArray[i][1].length === 0)
        ) {
          valueList.push(`● ${valueListTranslationArray[j][1]}`);
        }
      }
    }
  }

  return valueList;
};

/**
 * Function to format workhours to a readable clock string
 * @param workHour Number of workhours (e.g. 8)
 * @returns String with formatted workhours (e.g. "8:00")
 */
export const formatWorkHours = (workHour: number): string => {
  const newWorkHours = Duration.fromObject({ hours: workHour }).toISOTime({
    suppressMilliseconds: true,
    suppressSeconds: true,
  });
  return newWorkHours.startsWith("0") ? newWorkHours.slice(1) : newWorkHours;
};
