import { createSlice, createAsyncThunk, AsyncThunk } from "@reduxjs/toolkit";
import { getCurrentUser } from "../user/userSlice";
import { Resource } from "@dyce/tnt-api";
import { getDateArray, handleRecurring } from "./util";
import { tntApiHelper } from "../../apiHelper";
import { DateTime } from "luxon";
import { ResourceStateSlice, RootState } from "../../types/types";

/**
 * Get all Resources
 */
export const getResources: AsyncThunk<
  Resource[],
  undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  Resource[],
  undefined,
  { state: RootState; rejectWithError: Error }
>("resource/get", async (_, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getResourceService();

    return client.getAll();
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Get all Resources
 */
export const getCurrentResources: AsyncThunk<
  Resource[],
  string,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  Resource[],
  string,
  { state: RootState; rejectWithError: Error }
>("resource/getCurrent", async (resId, { getState, rejectWithValue }) => {
  try {
    const filter = {
      id: { eq: { type: "guid", value: resId } },
    };

    const client = await (await tntApiHelper(getState)).getResourceService();

    return client.getAll({ filter });
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Update a resource
 */
export const updateResources: AsyncThunk<
  Resource,
  Resource,
  { state: RootState; rejectWithError: Error }
> = createAsyncThunk<
  Resource,
  Resource,
  { state: RootState; rejectWithError: Error }
>("resource/update", async (data, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getResourceService();

    return await client.update(data.id, data);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const initialStateResources: ResourceStateSlice = {
  entities: [],
  currentResource: null,
  currentUser: {
    "2021-01-01": {
      calendar: [],
      capacity: {
        monday: 2,
        tuesday: 2,
        wednesday: 2,
        thursday: 2,
        friday: 2,
        saturday: 0,
        sunday: 0,
      },
      recurringHolidays: [],
    },
  },
};
const resourceSlice = createSlice({
  name: "resources",
  initialState: initialStateResources,
  reducers: {
    clearEntities: (state) => {
      state.entities = [];
    },
    setResources: (state, action) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getResources.fulfilled, (state, action) => {
      state.entities = action.payload;
    });
    builder.addCase(getCurrentResources.fulfilled, (state, action) => {
      state.currentResource = action.payload[0];
    });

    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      if (action.payload.resource && action.payload.resource.defaults)
        state.currentUser = {};

      action.payload.resource &&
        action.payload.resource.defaults &&
        action.payload.resource.defaults.forEach((d) => {
          state.currentUser[DateTime.fromISO(d.validFrom).toISODate()] = {
            calendar: getDateArray(d.calendar ? d.calendar.entries : undefined),
            capacity: d.workHourTemplate,
            recurringHolidays: handleRecurring(
              d.calendar ? d.calendar.entries : undefined
            ),
          };
        });
    });

    builder.addCase(updateResources.fulfilled, (state, action) => {
      const index = state.entities.findIndex(
        (el) => el.id === action.payload.id
      );

      if (index < 0) {
        throw new Error("Item not found");
      }

      state.entities[index] = {
        id: action.payload.id,
        no: action.payload.no,
        name: action.payload.name,
      };
    });
  },
});

export const { clearEntities } = resourceSlice.actions;
export default resourceSlice.reducer;
