import { WorkItemType } from "../types";
import buildQuery, { QueryOptions } from "odata-query";
import axios, { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export class WorkItemService {
  public baseUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = combineURLs(baseUrl, "/WorkItems");

    this.axiosInstance = instance;
  }

  /**
   * Gets all WorkItems
   * @param query OData Query
   * @example
   * // Get every single Template
   * const client = new TnTClient(args).getWorkItemService()
   * await client.getAll()
   * // Only get the first 10 entries
   * const client = new TnTClient(args).getWorkItemService()
   * await client.getAll({ top: 10 })
   */
  public async getAll(
    query?: Partial<QueryOptions<WorkItemType>>
  ): Promise<WorkItemType[]> {
    const url = `${this.baseUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<WorkItemType[]>(url);

    return response.data;
  }

  /**
   * Gets only one WorkItem by foreign information
   * @param arg Object with foreign information: {source, organization, project, taskId}
   * @param query OData Query
   * @example
   * const client = new TnTClient(args).getWorkItemService()
   * await client.getOne("0000-0000-0000-0000")
   */
  public async getOne(
    arg: {
      source: string;
      organization: string;
      project: string;
      taskId: string;
    },
    query?: Partial<QueryOptions<WorkItemType>>
  ): Promise<WorkItemType> {
    const { source, organization, project, taskId } = arg;
    const url = `${
      this.baseUrl
    }(source='${source}',organization='${organization}',project='${project}',id='${taskId}')${buildQuery(
      query
    )}`;

    const response = await this.axiosInstance.get<WorkItemType>(url);

    return response.data;
  }

  /**
   * Creates a new WorkItem
   * @param payload
   * @example
   * const client = new TnTClient(args).getWorkItemService()
   * await client.create({ id: "705", source: "AzureDevOps", organization: "AzureOrganizaionName", project: "AzureProjectName", title: "WorkItemTitle", status: "WorkItemStatus" | null })
   */
  public async create(payload: WorkItemType): Promise<WorkItemType> {
    const url = `${this.baseUrl}`;

    const result = await this.axiosInstance.post<WorkItemType>(url, payload);

    return result.data;
  }

  /**
   * Update a single WorkItem
   * @param arg Object with foreign information: {source, organization, project, taskId}
   * @param payload
   * const client = new TnTClient(args).getWorkItemService()
   * await client.update({ id: "705", source: "AzureDevOps", organization: "AzureOrganizaionName", project: "AzureProjectName", title: "WorkItemTitle", status: "WorkItemStatus" | null })
   */
  public async update(
    arg: {
      source: string;
      organization: string;
      project: string;
      taskId: string;
    },
    payload: DeepPartial<WorkItemType>
  ): Promise<WorkItemType> {
    const { source, organization, project, taskId } = arg;
    const url = `${this.baseUrl}(source='${source}',organization='${organization}',project='${project}',id='${taskId}')}`;

    const result = await this.axiosInstance.put<WorkItemType>(url, payload);

    return result.data;
  }

  /**
   * Deletes a WorkItem
   * @param arg Object with foreign information: {source, organization, project, taskId}
   * const client = new TnTClient().getWorkItemService()
   * await client.remove({ id: "705", source: "AzureDevOps", organization: "AzureOrganizaionName", project: "AzureProjectName", title: "WorkItemTitle", status: "WorkItemStatus" | null })
   */
  public async remove(arg: {
    source: string;
    organization: string;
    project: string;
    taskId: string;
  }): Promise<void> {
    const { source, organization, project, taskId } = arg;
    const url = `${this.baseUrl}(source='${source}',organization='${organization}',project='${project}',id='${taskId}')}`;

    await this.axiosInstance.delete(url);

    return;
  }
}
