import { useMemo, forwardRef, FunctionComponent } from "react";
import { Link as RouterLink } from "react-router-dom";
// Dyce-Lib
import { Tooltip } from "../tooltip/tooltip";
import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    menuText: {
      "& .MuiTypography-body1": {
        fontSize: theme.typography.body2.fontSize,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: theme.typography.body2.fontWeight,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
      },
    },
  })
);

interface ISideNavItemProps {
  /**
   * link url
   */
  url: string;
  /**
   * left side icon
   */
  icon: React.ReactNode;
  /**
   * name of the menu entry
   */
  label: string;
  /**
   * @deprecated
   */
  cssItem: string;
  /**
   * tooltip text for the entry
   */
  tooltip?: string;
  /**
   * path part of location
   */
  location?: string;
}

export const SideNavItem: FunctionComponent<ISideNavItemProps> = ({
  url,
  icon,
  label,
  cssItem,
  tooltip,
  location = "",
}) => {
  const classes = useStyles();
  const renderLink = useMemo(
    () => forwardRef((itemProps) => <RouterLink to={url} {...itemProps} />),
    [url]
  );

  const content = (
    <li>
      <ListItemButton
        className={cssItem}
        component={renderLink}
        selected={location === url}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={label} className={classes.menuText} />
      </ListItemButton>
    </li>
  );

  return tooltip ? (
    <Tooltip label={tooltip} position="right" urlPath={{ en: "", de: "" }}>
      {content}
    </Tooltip>
  ) : (
    { ...content }
  );
};
