import { useState, useRef, useEffect } from "react";
// RTK
import { unwrapResult } from "@reduxjs/toolkit";
// Helpers
import { useTranslation } from "react-i18next";
import { useUserList } from "../../../components/admin/userList/context/userContext";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Fab } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import {
  AADList,
  Dialog,
  SubHeader,
  Tooltip,
  useStaticContent,
} from "@dyce/ui";
import { useNotification } from "@dyce/hooks";
import { AadUser, PopulatedRole } from "@dyce/tnt-api";
import {
  useAppDispatch,
  useAppSelector,
  selectCurrentUser,
  selectAllRolesAsArray,
  createUser,
  setUserRoles,
  setCurrentUserRoles,
  removeUser,
  getAllAADUsers,
  selectDarkMode,
  selectAadUsersNotInDyce,
} from "@dyce/slices";
// Components
import { RoleDialog } from "../../../components/admin/roleDialog/RoleDialog";
import { DYCEList } from "../../../components/admin/userList/UserList";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      height: `calc(100vh - ${theme.palette.propsDyce.commonPageProps.marginTop} - 64px - 112px - 8px)`,
      marginRight: "1.75rem",
      marginLeft: "1.25rem",
      [theme.breakpoints.down("sm")]: {
        height: `calc(100vh - ${theme.palette.propsDyce.commonPageProps.marginTop} - 56px - 112px - 8px)`,
      },
    },
  })
);

export const Users = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notify = useNotification();
  const { docuLinks } = useStaticContent();

  // UseRefs
  const containerRef = useRef<HTMLDivElement | null>(null);

  // Selectors
  const currentUser = useAppSelector(selectCurrentUser);
  const allRoles = useAppSelector(selectAllRolesAsArray);
  const darkmode = useAppSelector(selectDarkMode);
  const aadUsers = useAppSelector(selectAadUsersNotInDyce);

  // States
  const [createUserDialog, setCreateUserDialog] = useState(false);
  const [editUserDialog, setEditUserDialog] = useState(false);
  const [loadListNew, setLoadListNew] = useState(false);
  const [userCount, setUserCount] = useState<number>(0);

  const [selectedAADUsers, setSelectedAADUsers] = useState<Set<AadUser>>(
    new Set()
  );
  const {
    selectedUsers: selectedDYCEUsers,
    setSelectedUsers: setSelectedDYCEUsers,
    userRoleMap,
  } = useUserList();

  // UseEffects
  useEffect(() => {
    if (containerRef.current) {
      const userCount = Math.ceil(containerRef.current.clientHeight / 35) + 10;
      setUserCount(userCount);
    }
  }, [containerRef.current]);

  // Handler
  const handleAddClick = () => {
    dispatch(getAllAADUsers({}));
    setCreateUserDialog(true);
    setLoadListNew(false);
  };

  const handleSubmitAddClick = () => {
    let currentIndex = 0;
    selectedAADUsers?.forEach((user) =>
      dispatch(
        createUser({
          id: user.id,
          name: user.displayName,
          email: user.mail,
        })
      )
        .then(() => currentIndex++)
        .then(() => {
          if (currentIndex === selectedAADUsers.size) {
            setLoadListNew(true);
          }
        })
    );

    setCreateUserDialog(false);

    setSelectedAADUsers(new Set());
    setSelectedDYCEUsers(new Set());
  };

  const handleAadSearch = (search: string) => {
    dispatch(getAllAADUsers({ search }));
  };

  const handleSubmitEditClick = () => {
    Object.keys(userRoleMap).forEach(async (id) => {
      // prevent current user from removing admin role from him/herself
      if (
        currentUser.id === id &&
        currentUser.roles.some((r) => r.type === "admin")
      ) {
        const adminRole = allRoles.find((r) => r.type === "admin");
        if (
          adminRole &&
          !Array.from(userRoleMap[id]).some((r) => r === adminRole.id)
        ) {
          notify(t("admin.users.cantRemoveAdminFromSelf"), {
            canClose: true,
            variant: "info",
          });
          userRoleMap[id].add(adminRole.id);
        }
      }

      try {
        const action = await dispatch(
          setUserRoles({
            userId: id,
            userRoles: Array.from(userRoleMap[id]).map((r) => ({ id: r })),
          })
        );

        unwrapResult(action);

        if (currentUser.id === id) {
          dispatch(
            setCurrentUserRoles([
              ...(Array.from(userRoleMap[id]).map((r) =>
                allRoles.find((v) => v.id === r)
              ) as PopulatedRole[]),
            ])
          );
        }
      } catch (e) {
        // TODO notification
        console.error(e);
      }
    });
    setEditUserDialog(false);
    setSelectedDYCEUsers(new Set());
  };

  const handleDeleteClick = () => {
    selectedDYCEUsers?.forEach((user) => {
      if (user.id == currentUser.id) {
        notify(t("admin.users.cantRemoveSelf"), {
          canClose: true,
          variant: "warning",
        });
      } else {
        dispatch(removeUser(user.id));
        setSelectedDYCEUsers(new Set());
      }
    });
  };

  const handleEditClick = () => {
    setEditUserDialog(true);
  };

  return (
    <div ref={containerRef}>
      <SubHeader
        tooltipLabel={t("admin.users.info")}
        tooltipUrlPath={docuLinks.administration.users.subheader.info}
        title={t("admin.users.title")}
        subtitle={t("admin.users.subtitle")}
      >
        <Tooltip
          label={t("admin.users.tooltip.aad")}
          urlPath={docuLinks.administration.users.subheader.aad}
        >
          <Fab aria-label="add" onClick={handleAddClick} color="primary">
            <AddOutlinedIcon fontSize="medium" />
          </Fab>
        </Tooltip>
      </SubHeader>
      <div className={classes.container}>
        <DYCEList
          onDelete={handleDeleteClick}
          onEdit={handleEditClick}
          loadListNew={loadListNew}
          loadUserCount={userCount}
        />
      </div>
      <Dialog
        open={createUserDialog}
        onCancel={() => {
          setCreateUserDialog(false);
        }}
        onSubmit={handleSubmitAddClick}
        title={t("admin.users.modal.header")}
        adjustableWidth
      >
        <AADList
          onChange={setSelectedAADUsers}
          onSearch={handleAadSearch}
          darkmode={darkmode}
          data={aadUsers}
        />
      </Dialog>
      <Dialog
        open={editUserDialog}
        onCancel={() => {
          setEditUserDialog(false);
          setSelectedDYCEUsers(new Set());
        }}
        onSubmit={handleSubmitEditClick}
        title={t("admin.users.ui.editUserRoles")}
      >
        <RoleDialog />
      </Dialog>
    </div>
  );
};
