import { useMemo, forwardRef, FunctionComponent } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// Dyce-Lib
import { Tooltip } from "../../tooltip/tooltip";
import { DyceTheme } from "@dyce/theme";
// Material UI
import { makeStyles, createStyles } from "@mui/styles";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    menuText: {
      "& .MuiTypography-body1": {
        fontSize: theme.typography.body2.fontSize,
        fontFamily: theme.typography.body2.fontFamily,
        fontWeight: theme.typography.body2.fontWeight,
        lineHeight: theme.typography.body2.lineHeight,
        letterSpacing: theme.typography.body2.letterSpacing,
      },
    },
  })
);

interface IMenuItemCreatorProps {
  /**
   * To URL pushed to Router
   */
  url: string;
  /**
   * MUI-Icon shown in ListItem
   */
  icon?: React.ReactNode;
  /**
   * Label from Menuitem
   */
  label: string;
  /**
   * CSS Style
   */
  cssItem: string;
  /**
   * If string.length > 0, ToolTip will be shown right from MenuItem
   */
  tooltipLabel?: string;
}

export const MenuItemCreator: FunctionComponent<IMenuItemCreatorProps> = ({
  url,
  icon,
  label,
  cssItem,
  tooltipLabel,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const renderLink = useMemo(
    () =>
      // eslint-disable-next-line
      forwardRef((itemProps, ref) => <RouterLink to={url} {...itemProps} />),
    [url]
  );

  const kids = (
    <li>
      <ListItemButton
        className={cssItem}
        component={renderLink}
        selected={location.pathname + location.hash === url}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={label} className={classes.menuText} />
      </ListItemButton>
    </li>
  );

  return (
    <>
      {tooltipLabel ? (
        <Tooltip
          label={tooltipLabel}
          position="right"
          urlPath={{ en: "", de: "" }}
        >
          {kids}
        </Tooltip>
      ) : (
        { ...kids }
      )}
    </>
  );
};
