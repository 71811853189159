import { FunctionComponent, useEffect } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { useNotification } from "@dyce/hooks";
import {
  clearLatestCreatedId,
  getAllTemplates,
  selectCapacities,
  selectAdminSettingsTasks,
  selectEditorState,
  selectErrorOnUpdate,
  selectLanguageCode,
  selectLocaleHyphen,
  setClearErrorOnUpdate,
  setOpenEditor,
  templatesAsTwoDimArray,
  useAppDispatch,
  useAppSelector,
} from "@dyce/slices";
import { LoadMore, RecordList } from "@dyce/ui";
import { RecordTimeRec } from "@dyce/tnt-api";
import {
  handleDeleteRecord,
  handleRecordBudget,
  handleSaveTimerecording,
  validateTemplateObject,
} from "@dyce/utils";
// MUI
import { Container } from "@mui/material";

type IncompleteTimesStyles = {
  recordsContainer: string;
  listContainer: string;
};

interface IncompleteTimeProps {
  styles: IncompleteTimesStyles;
  dayRecords: [string, RecordTimeRec[]][];
  pagination: {
    count: number;
    loadCount: number;
    loadMore: () => void;
  };
  itemsArrayInfos: [string, number][] | undefined;
}

export const IncompleteRecordList: FunctionComponent<IncompleteTimeProps> = ({
  styles,
  dayRecords,
  pagination,
  itemsArrayInfos,
}) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useNotification();

  // Selectors
  const errorOnUpdate = useAppSelector(selectErrorOnUpdate);
  const localeHyphen = useAppSelector(selectLocaleHyphen);
  const timeTrackingToolIsOpen = useAppSelector(selectEditorState);
  const templates = useAppSelector(templatesAsTwoDimArray);
  const capacities = useAppSelector(selectCapacities);
  const languageCode = useAppSelector(selectLanguageCode);
  const tasksRule = useAppSelector(selectAdminSettingsTasks);

  // UseEffects
  useEffect(() => {
    // Logic to call recEntry by id on rejected updated and check if entry was deleted
    // or compare status after fulfilled api call.
    if (errorOnUpdate) {
      if (errorOnUpdate.deleted) {
        notification(t("timerec.notification.timerecWasDeleted"), {
          variant: "error",
          canClose: true,
        });
      } else if (
        errorOnUpdate.newStatus === errorOnUpdate.status &&
        errorOnUpdate.unknownIssue
      ) {
        // Unknown Issue, e.g. validation error => notify!
        notification(t("timerec.notification.errorOnUpdate"), {
          variant: "warning",
          canClose: true,
        });
      }
      setTimeout(() => {
        dispatch(setClearErrorOnUpdate());
      }, 500);
    }
  }, [errorOnUpdate]);

  return (
    <div className={styles.recordsContainer} id="recEntries">
      <Container maxWidth={false}>
        <div className={styles.listContainer}>
          {dayRecords.map(([day, records]) => (
            <div key={day} id={`#${day}`}>
              <RecordList
                workWithFilter={tasksRule.assignment}
                date={day}
                records={records}
                isEnglish={languageCode === "en"}
                itemsArrayInfos={itemsArrayInfos}
                resourceCapacity={capacities}
                headerAdditions={false}
                templateList={templates}
                getAllTemplates={() => dispatch(getAllTemplates())}
                validateTemplateObject={async (template) =>
                  await validateTemplateObject(template, dispatch)
                }
                templateCategories={{
                  templateCategories: [],
                  getAllCategories: () => {},
                }}
                timeTrackingToolIsOpen={timeTrackingToolIsOpen}
                menuOptions={{ template: false, copy: false }}
                deleteRecord={(id) => handleDeleteRecord(id, dispatch)}
                getRecordBudget={async ({ id, levelOfDetail }) =>
                  await handleRecordBudget({
                    dispatch,
                    id,
                    levelOfDetail,
                    inHours: true,
                  })
                }
                timeTrackingToolProps={{
                  onSave: (entry) => handleSaveTimerecording(entry, dispatch),
                  timeTrackingToolOpen: (value) =>
                    dispatch(setOpenEditor(value)),
                  clearLatestCreatedId: () => dispatch(clearLatestCreatedId()),
                  localeHyphen: localeHyphen,
                  ignoreOpenNewEditor: true,
                }}
              />
            </div>
          ))}
        </div>
      </Container>
      <LoadMore
        count={pagination.count}
        loadCount={pagination.loadCount}
        loadMore={pagination.loadMore}
        listLength={dayRecords.length}
      />
    </div>
  );
};
