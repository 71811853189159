import React, { FunctionComponent, useEffect } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { useMsal } from "@azure/msal-react";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Typography } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    rootContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      flexFlow: "row",
      width: "100%",
      height: "100vh",
    },
    messageContainer: {
      maxWidth: 540,
      verticalAlign: "center",
    },
    header: {
      marginBottom: "20px !important",
    },
  })
);

export const Logout: FunctionComponent = () => {
  const classes = useStyles();
  const { instance } = useMsal();
  const { t } = useTranslation();

  useEffect(() => {
    instance.logout({
      postLogoutRedirectUri: "/",
    });
  }, []);

  return (
    <div className={classes.rootContainer}>
      <div className={classes.messageContainer}>
        <Typography variant="h2" className={classes.header}>
          {t("logout.title")}
        </Typography>
      </div>
    </div>
  );
};
