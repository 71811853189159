import { FunctionComponent } from "react";
// MUI
import { Box, Fade } from "@mui/material";

interface IMoreOptionsWrapperProps {
  /**
   * If true, TTT is open
   */
  isOpen: boolean;
  /**
   * If true, component will be shown (!none)
   */
  openWrapper: boolean;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
  /**
   * Define size of wrapper
   * @default "medium"
   */
  size?: "small" | "medium";
}

export const MoreOptionsWrapper: FunctionComponent<
  IMoreOptionsWrapperProps
> = ({ isOpen, openWrapper, size = "medium", children }) => {
  return (
    <Box
      sx={{
        width: "100%",
        padding: openWrapper && isOpen ? "0 0 1rem 0" : "0",
        transition: "height 0.25s ease-in-out",
        height:
          openWrapper && isOpen ? (size === "small" ? "73px" : "105px") : "0px",
      }}
    >
      <Fade in={openWrapper} timeout={{ appear: 700, enter: 700, exit: 200 }}>
        <div>{children}</div>
      </Fade>
    </Box>
  );
};
