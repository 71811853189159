import { FunctionComponent, useState } from "react";
// Helper
import i18next from "i18next";
import { useMsal } from "@azure/msal-react";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Button as ButtonMui } from "@mui/material";
// Azure styles
import { Button as ButtonAzure } from "azure-devops-ui/Button";
import { InteractionStatus } from "@azure/msal-browser";
// Jira
// @ts-ignore
import ButtonJira from "@atlaskit/button";
// Dyce-Lib
import { LoadingSpinner as LoadingSpinnerMui } from "../loading-spinner/loading-spinner";
import { LoadingSpinner as LoadingSpinnerAzure } from "@dyce/ui-devops";
import { LoadingSpinner as LoadingSpinnerJira } from "@dyce/ui-jira";

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "0.5rem 1.5rem",
      height: "100%",
      width: "100%",
      gap: "1rem",
    },
  })
);

interface ILoginProps {
  componentTheme: "muiComponent" | "azureComponent" | "jiraComponent";
}

export const LoginPopupPage: FunctionComponent<ILoginProps> = ({
  componentTheme,
}) => {
  const classes = useStyles();
  const { instance, inProgress } = useMsal();

  // UseStates
  const [disableLogin, setDisableLogin] = useState<boolean>(false);

  // Handler
  const handleLogin = () => {
    setDisableLogin(true);

    instance.loginPopup().catch((e) => {
      setDisableLogin(false);
      console.error(e);
    });
  };

  return (
    <div className={classes.container}>
      {inProgress === InteractionStatus.Login ? (
        componentTheme === "muiComponent" ? (
          <LoadingSpinnerMui
            text={i18next.t("authentication.waiting.login")}
            delay={0}
            withRefreshButton={false}
          />
        ) : componentTheme === "azureComponent" ? (
          <LoadingSpinnerAzure
            text={i18next.t("authentication.waiting.login")}
          />
        ) : (
          <LoadingSpinnerJira
            text={i18next.t("authentication.waiting.login")}
          />
        )
      ) : (
        <>
          <div>{String(i18next.t("authentication.contentText"))}</div>
          <div>
            {componentTheme === "muiComponent" ? (
              <ButtonMui
                onClick={() => handleLogin()}
                disabled={disableLogin}
                variant="outlined"
              >
                {String(i18next.t("login.signup"))}
              </ButtonMui>
            ) : componentTheme === "azureComponent" ? (
              <ButtonAzure
                onClick={() => handleLogin()}
                disabled={disableLogin}
                text={i18next.t("login.signup")}
              />
            ) : (
              <ButtonJira
                onClick={() => handleLogin()}
                isDisabled={disableLogin}
                appearance="primary"
              >
                {i18next.t("login.signup")}
              </ButtonJira>
            )}
          </div>
        </>
      )}
    </div>
  );
};
