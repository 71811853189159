import { FunctionComponent } from "react";
// Dyce-Lib
import { Instance } from "@dyce/tnt-api";
// MUI
import { Box, Typography } from "@mui/material";
import { TreeItem, TreeView } from "@mui/lab";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import BusinessIcon from "@mui/icons-material/Business";
import StorageIcon from "@mui/icons-material/Storage";

export interface InstanceTreeProps {
  /**
   * all instances the user has
   */
  instances: Instance[];
  /**
   * instance name the user is currently in
   */
  instance: string;
  /**
   * company name the user is currently in
   */
  company: string;
  /**
   * callback when a new instance/company combination is selected
   */
  onSelection?: (instance: Instance, company: string) => void;
}

export const InstanceTree: FunctionComponent<InstanceTreeProps> = ({
  instance,
  instances,
  company,
  onSelection,
}) => {
  // Handler
  const handleSelection = (instance: Instance, company: string) => {
    onSelection && onSelection(instance, company);
  };

  return (
    <TreeView
      defaultExpanded={instance ? [instance] : []}
      defaultSelected={instance ? `${instance}+${company}` : ""}
      defaultExpandIcon={<ArrowDropDownIcon />}
      defaultCollapseIcon={<ArrowDropUpIcon />}
    >
      {instances.length > 0 ? (
        instances.map((i, index) => (
          <TreeElement
            key={index}
            nodeId={i.name}
            label={i.name}
            labelIcon={StorageIcon}
          >
            {i.companies.map((c, index) => (
              <TreeElement
                key={index}
                nodeId={`${i.name}+${c.name}`}
                label={c.name}
                labelIcon={BusinessIcon}
                onClick={() => handleSelection(i, c.name)}
              />
            ))}
          </TreeElement>
        ))
      ) : (
        <TreeElement
          nodeId={"Loading"}
          label={"Loading"}
          labelIcon={StorageIcon}
          disabled
        />
      )}
    </TreeView>
  );
};

interface TreeElementProps {
  /**
   * Unique id for the node.
   */
  nodeId: string;
  /**
   * The content label of the component.
   */
  label: string;
  /**
   * Callback fired when the component is clicked.
   * @returns void
   */
  onClick?: () => void;
  /**
   * If true, the node is disabled.
   */
  disabled?: boolean;
  /**
   * The icon to display next to the tree node's label.
   */
  labelIcon: any;
  /**
   * Children as React.ReactNode
   */
  children?: React.ReactNode;
}

export const TreeElement: FunctionComponent<TreeElementProps> = ({
  nodeId,
  label,
  labelIcon: LabelIcon,
  onClick,
  disabled = false,
  ...rest
}) => {
  return (
    <TreeItem
      label={
        <Box sx={{ display: "flex", alignItems: "center", p: 0.5, pr: 0 }}>
          <Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
          <Typography
            variant="body2"
            sx={{ fontWeight: "inherit", flexGrow: 1 }}
          >
            {label}
          </Typography>
          <Typography variant="caption" color="inherit"></Typography>
        </Box>
      }
      nodeId={nodeId}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    />
  );
};
