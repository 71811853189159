// Types
import { CallbackOption } from "../../useNotification";
// Helper
import i18next from "i18next";
import { SnackbarKey } from "notistack";
// MUI
import { IconButton, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

/**
 * Function to handle different action behavior for Snackbar, depending on
 * provided option props.
 * @param key Individual key for every snackbar as @type {SnackbarKey}
 * @param handleClose Callback function for close snackbar
 * @param switchColor Switch to react on snackbar variant and show closeButton in different color
 * @param callbacks Array with callbackFunctions @type {Record<string, () => void>}
 * @param canClose Boolean to show closeButton @default false
 * @returns JSX.Element
 */
export const Action = (
  key: SnackbarKey,
  handleClose: (key: SnackbarKey) => void,
  switchColor: boolean,
  callbacks?: CallbackOption[],
  canClose?: boolean
): JSX.Element => {
  return (
    <>
      {callbacks &&
        callbacks.map((c, i) => (
          <>
            <Typography
              variant="caption"
              sx={{
                textDecoration: "underline",
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
              key={i}
              onClick={() => {
                c.fn();
                handleClose(key);
              }}
            >
              {c.name.toLowerCase() === "undo"
                ? String(i18next.t("notification.action.undo"))
                : c.name.toLocaleLowerCase() === "show"
                ? String(i18next.t("notification.action.show"))
                : c.name}
            </Typography>
            {!(callbacks.length === i + 1) && (
              <div style={{ marginRight: "0.5rem" }}>{"/"}</div>
            )}
          </>
        ))}
      {canClose && (
        <IconButton
          color={switchColor ? "inherit" : "primary"}
          size="small"
          onClick={() => handleClose(key)}
        >
          <CloseOutlinedIcon fontSize="small" />
        </IconButton>
      )}
    </>
  );
};
