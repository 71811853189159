// Helper
import _ from "lodash";
import { DateTime } from "luxon";
import { useCallback } from "react";
import { UseFormSetFocus } from "react-hook-form";
import i18next from "i18next";
// Dyce-Lib
import {
  Customer,
  Job,
  JobTaskStatusOptions,
  NonBillableReasonOptions,
  PopulatedJobPlanningLine,
  PopulatedJobTask,
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  StatusOptions,
  TemplateCategory,
  TntModelLine,
} from "@dyce/tnt-api";
import { MemoryState, PrevStateObject } from "@dyce/ui";
import { CollapsibleHeader, FieldTypes } from "@dyce/interfaces";

/**
 * Function to type and validate YUP schema
 * @param validationSchema Defined schema from 'yup'
 * @returns Validated yup schema object
 */
export const useYupValidationResolver = (validationSchema: any) =>
  useCallback(
    async (data: any) => {
      try {
        const values = await validationSchema.validate(data, {
          abortEarly: false,
        });

        return {
          values,
          errors: {},
        };
      } catch (errors: any) {
        return {
          values: {},
          errors: errors.inner.reduce(
            (allErrors: any, currentError: any) => ({
              ...allErrors,
              [currentError.path]: {
                type: currentError.type ?? "validation",
                message: currentError.message,
              },
            }),
            {}
          ),
        };
      }
    },
    [validationSchema]
  );

/**
 * Function to fill entries in RecordEntry object, that differst between Template and
 * Timerecording data or just for initialization
 * @param entries Entries from Item || Template || null for empty initialization
 * @param data Data object from handleValidate <== This is only temporarly (LookUp)
 * @param recordDate Date from current selected day as string
 * @returns TimeInputs object with or without entries
 */
export const defineTimeInputs = (
  entries: (RecordEntry & Partial<RecordTemplate & RecordTimeRec>) | null,
  data: any | null,
  recordDate = ""
): RecordEntry & Partial<RecordTemplate & RecordTimeRec> => {
  let timeInput: RecordEntry & Partial<RecordTemplate & RecordTimeRec>;
  let entryId: string | undefined = "";
  let entryDate: string | undefined = undefined;
  let entryCreated: string | undefined = undefined;
  let entryStatus = StatusOptions.OPEN;
  // Template
  let entryCategory: TemplateCategory | undefined = undefined;

  // Set entries on edit
  if (entries && !data) {
    if (isRecord(entries)) {
      entryDate = entries.date ? DateTime.fromISO(entries.date).toISO() : "";
      entryCreated = entries.created;
      entryStatus = entries.status ? entries.status : StatusOptions.OPEN;
    } else {
      entryCategory = entries.category ? entries.category : undefined;
    }
    entryId = entries.id;

    // Define TimeInputs object on edit
    timeInput = {
      id: entryId,
      start: entries.start ? DateTime.fromISO(entries.start).toISO() : null,
      end: entries.end ? DateTime.fromISO(entries.end).toISO() : null,
      calendarEventId:
        entries.calendarEventId !== undefined &&
        entries.calendarEventId !== null
          ? entries.calendarEventId.length === 0
            ? null
            : entries.calendarEventId
          : null,
      duration: entries.duration,
      break: entries.break,
      description: entries.description ? entries.description : "",
      // Customer
      customer: entries.customer
        ? {
            id: entries.customer ? entries.customer.id : "",
            name: entries.customer ? entries.customer.name : null,
            no: entries.customer ? entries.customer.no : null,
          }
        : null,
      // Job
      job: entries.job
        ? {
            id: entries.job ? entries.job.id : "",
            description: entries.job ? entries.job.description : null,
            no: entries.job ? entries.job.no : null,
          }
        : null,
      // JobTask
      jobTask: entries.jobTask
        ? {
            id: entries.jobTask ? entries.jobTask.id : "",
            description: entries.jobTask ? entries.jobTask.description : null,
            no: entries.jobTask ? entries.jobTask.no : null,
            job: {
              id: entries.job ? entries.job.id : "",
              description: entries.job ? entries.job.description : null,
              no: entries.job ? entries.job.no : null,
            },
            jobPlanningLine: entries.jobPlanningLine
              ? {
                  id: entries.jobPlanningLine ? entries.jobPlanningLine.id : "",
                  description: entries.jobPlanningLine
                    ? entries.jobPlanningLine.description
                    : "",
                  serviceBillingType: entries.jobPlanningLine
                    ? entries.jobPlanningLine.serviceBillingType
                    : null,
                }
              : null,
            status: entries.jobTask
              ? entries.jobTask.status
              : JobTaskStatusOptions.OPEN,
          }
        : null,
      // Activity
      jobPlanningLine:
        // Fill only when tntModelLine is available otherwise
        // jobPlanningLine is also in jobTask
        entries.jobPlanningLine && entries.tntModelLine
          ? {
              id: entries.jobPlanningLine ? entries.jobPlanningLine.id : "",
              description: entries.tntModelLine
                ? entries.tntModelLine.description
                : null,
              serviceBillingType: entries.jobPlanningLine
                ? entries.jobPlanningLine.serviceBillingType
                : null,
              tntModelLine: entries.tntModelLine
                ? {
                    id: entries.tntModelLine ? entries.tntModelLine.id : "",
                    description: entries.tntModelLine
                      ? entries.tntModelLine.description
                      : null,
                    billable: entries.tntModelLine
                      ? entries.tntModelLine.billable
                      : false,
                    nonBillableReason: entries.tntModelLine
                      ? entries.tntModelLine.nonBillableReason
                      : NonBillableReasonOptions.NONE,
                    rounding: entries.tntModelLine
                      ? entries.tntModelLine.rounding
                      : 0,
                  }
                : undefined,
              jobTask: {
                id: entries.jobTask ? entries.jobTask.id : "",
                description: entries.jobTask
                  ? entries.jobTask.description
                  : null,
                no: entries.jobTask ? entries.jobTask.no : null,
                job: {
                  id: entries.job ? entries.job.id : "",
                  description: entries.job ? entries.job.description : null,
                  no: entries.job ? entries.job.no : null,
                },
                jobPlanningLine: {
                  id: entries.jobPlanningLine ? entries.jobPlanningLine.id : "",
                  description: entries.jobPlanningLine
                    ? entries.jobPlanningLine.description
                    : "",
                  serviceBillingType: entries.jobPlanningLine
                    ? entries.jobPlanningLine.serviceBillingType
                    : null,
                },
                status: entries.jobTask
                  ? entries.jobTask.status
                  : JobTaskStatusOptions.OPEN,
              },
            }
          : null,
      tntModelLine: entries.tntModelLine
        ? {
            id: entries.tntModelLine ? entries.tntModelLine.id : "",
            description: entries.tntModelLine
              ? entries.tntModelLine.description
              : null,
            billable: entries.tntModelLine
              ? entries.tntModelLine.billable
              : false,
            nonBillableReason: entries.tntModelLine
              ? entries.tntModelLine.nonBillableReason
              : NonBillableReasonOptions.NONE,
            rounding: entries.tntModelLine ? entries.tntModelLine.rounding : 0,
          }
        : null,
      durationBillable: entries.durationBillable,
      nonBillableReason: entries.nonBillableReason
        ? entries.nonBillableReason
        : NonBillableReasonOptions.NONE,
      workItem: entries.workItem
        ? {
            id: entries.workItem.id,
            source: entries.workItem.source,
            organization: entries.workItem.organization,
            project: entries.workItem.project,
            title: entries.workItem.title,
            status: entries.workItem.status,
          }
        : null,
    };
    if (entryCategory !== undefined) {
      timeInput = { ...timeInput, category: entryCategory, date: undefined };
    } else {
      timeInput = {
        ...timeInput,
        date: entryDate,
        created: entryCreated,
        status: entryStatus,
      };
    }
  } else if (data) {
    // Validate data from Editor
    timeInput = {
      id: data.id ? data.id : "",
      start: data.start,
      end: data.end,
      calendarEventId: data.calendarEventId,
      duration: data.duration,
      break: data.break,
      date: data.date,
      description: data.description ? data.description : "",
      category: data.category,
      customer:
        data.customer && data.customer.id
          ? {
              id: data.customer.id ? data.customer.id : null,
              name: data.customer.name ? data.customer.name : null,
              no: data.customer.no ? data.customer.no : null,
            }
          : null,
      job:
        data.job && data.job.id
          ? {
              id: data.job.id ? data.job.id : null,
              description: data.job.description ? data.job.description : null,
              no: data.job.no ? data.job.no : null,
            }
          : null,
      jobTask:
        data.jobTask && data.jobTask.id
          ? {
              id: data.jobTask.id ? data.jobTask.id : null,
              description: data.jobTask.description
                ? data.jobTask.description
                : null,
              no: data.jobTask ? data.jobTask.no : null,
              job: data.jobTask.job
                ? {
                    id: data.jobTask.job
                      ? data.jobTask.job.id
                      : data.job.id
                      ? data.job.id
                      : null,
                    description: data.jobTask.job
                      ? data.jobTask.job.description
                      : data.job.description
                      ? data.job.description
                      : null,
                    no: data.jobTask.job
                      ? data.jobTask.job.no
                      : data.job.no
                      ? data.job.no
                      : null,
                  }
                : null,
              jobPlanningLine:
                data.jobTask.jobPlanningLine && data.jobTask.jobPlanningLine.id
                  ? {
                      id: data.jobTask.jobPlanningLine.id
                        ? data.jobTask.jobPlanningLine.id
                        : null,
                      description: data.jobTask.jobPlanningLine.description
                        ? data.jobTask.jobPlanningLine.description
                        : null,
                      serviceBillingType: data.jobTask.jobPlanningLine
                        .serviceBillingType
                        ? data.jobTask.jobPlanningLine.serviceBillingType
                        : null,
                    }
                  : null,
              status: data.jobTask.status
                ? data.jobTask.status
                : JobTaskStatusOptions.OPEN,
            }
          : null,
      jobPlanningLine:
        data.jobPlanningLine && data.jobPlanningLine.id
          ? {
              id: data.jobPlanningLine.id ? data.jobPlanningLine.id : null,
              description: data.jobPlanningLine.tntModelLine
                ? data.jobPlanningLine.tntModelLine.description
                : null,
              serviceBillingType: data.jobPlanningLine.serviceBillingType
                ? data.jobPlanningLine.serviceBillingType
                : null,
              tntModelLine: {
                id:
                  data.jobPlanningLine.tntModelLine &&
                  data.jobPlanningLine.tntModelLine.id
                    ? data.jobPlanningLine.tntModelLine.id
                    : null,
                description:
                  data.jobPlanningLine.tntModelLine &&
                  data.jobPlanningLine.tntModelLine.description
                    ? data.jobPlanningLine.tntModelLine.description
                    : null,
                billable:
                  data.jobPlanningLine.tntModelLine &&
                  data.jobPlanningLine.tntModelLine.billable
                    ? data.jobPlanningLine.tntModelLine.billable
                    : false,
                nonBillableReason:
                  data.jobPlanningLine.tntModelLine &&
                  data.jobPlanningLine.tntModelLine.nonBillableReason
                    ? data.jobPlanningLine.tntModelLine.nonBillableReason
                    : NonBillableReasonOptions.NONE,
                rounding:
                  data.jobPlanningLine.tntModelLine &&
                  data.jobPlanningLine.tntModelLine.rounding
                    ? data.jobPlanningLine.tntModelLine.rounding
                    : 0,
              },
              jobTask: {
                id: data.jobTask ? data.jobTask.id : null,
                description: data.jobTask ? data.jobTask.description : null,
                no: data.jobTask ? data.jobTask.no : null,
                job:
                  data.job && data.job.id
                    ? {
                        id: data.job.id ? data.job.id : null,
                        description: data.job.description
                          ? data.job.description
                          : null,
                        no: data.job.no ? data.job.no : null,
                      }
                    : null,
                jobPlanningLine: {
                  id: data.jobPlanningLine.id ? data.jobPlanningLine.id : null,
                  description: data.jobPlanningLine.description
                    ? data.jobPlanningLine.description
                    : null,
                  serviceBillingType: data.jobPlanningLine.serviceBillingType
                    ? data.jobPlanningLine.serviceBillingType
                    : null,
                },
                status: data.jobTask
                  ? data.jobTask.status
                  : JobTaskStatusOptions.OPEN,
              },
            }
          : null,
      tntModelLine:
        data.jobPlanningLine &&
        data.jobPlanningLine.id &&
        data.jobPlanningLine.tntModelLine &&
        data.jobPlanningLine.tntModelLine.id
          ? {
              id: data.jobPlanningLine.tntModelLine.id
                ? data.jobPlanningLine.tntModelLine.id
                : null,
              description: data.jobPlanningLine.tntModelLine.description
                ? data.jobPlanningLine.tntModelLine.description
                : null,
              billable: data.jobPlanningLine.tntModelLine.billable
                ? data.jobPlanningLine.tntModelLine.billable
                : false,
              nonBillableReason: data.jobPlanningLine.tntModelLine
                .nonBillableReason
                ? data.jobPlanningLine.tntModelLine.nonBillableReason
                : NonBillableReasonOptions.NONE,
              rounding:
                data.jobPlanningLine.tntModelLine &&
                data.jobPlanningLine.tntModelLine.rounding
                  ? data.jobPlanningLine.tntModelLine.rounding
                  : 0,
            }
          : null,
      durationBillable: data.durationBillable,
      nonBillableReason: data.nonBillableReason,
      // released: data.released ? data.released : false,
      status: data.status,
      workItem: data.workItem
        ? {
            id: data.workItem.id,
            source: data.workItem.source,
            organization: data.workItem.organization,
            project: data.workItem.project,
            title: data.workItem.title,
            status: data.workItem.status,
          }
        : null,
    };
    if (entryCategory === undefined) {
      timeInput = { ...timeInput, created: entryCreated };
    }
  } else {
    // Initial object on create
    timeInput = {
      start: null,
      end: null,
      calendarEventId: null,
      duration: 0,
      break: 0,
      description: "",
      date: DateTime.fromFormat(recordDate, "yyyy-MM-dd").toISO(),
      customer: null,
      job: null,
      jobTask: null,
      jobPlanningLine: null,
      tntModelLine: null,
      durationBillable: 0,
      nonBillableReason: NonBillableReasonOptions.NONE,
      status: StatusOptions.OPEN,
      workItem: null,
    };
  }

  return timeInput;
};

export const handleFillWithTemplate = ({
  template,
  timeInput,
  recordDate,
  propagateTime,
}: {
  template: RecordTemplate;
  timeInput: RecordTimeRec;
  recordDate: string;
  propagateTime: boolean;
}): RecordTimeRec => {
  let newStart: string | null = null;
  let newEnd: string | null = null;
  let newDuration = 0;
  let newBreak = 0;
  let newCustomer: Customer | null = null;
  let newJob: Job | null = null;
  let newJobTask: PopulatedJobTask | null = null;
  let newJobPlanningLine: PopulatedJobPlanningLine | null = null;
  let newTntModelLine: TntModelLine | null = null;
  let newDurationBillable = 0;
  let newNonBillableReason: NonBillableReasonOptions =
    NonBillableReasonOptions.NONE;

  // Logic for Time fields
  if (timeInput.duration === 0 && propagateTime) {
    let blockChange = false;
    if (template.start) {
      newStart = parseTimeWithDate(template.start, recordDate);
      if (timeInput.end) {
        newEnd = null;
        blockChange = true;
      }
    } else if (template.duration === 0) {
      newStart = timeInput.start;
    }
    if (template.end) {
      newEnd = parseTimeWithDate(template.end, recordDate);
      if (timeInput.start) {
        newStart = null;
      }
    } else if (!blockChange && template.duration === 0) {
      newEnd = timeInput.end;
    }

    newDuration = template.duration;
    newBreak = template.break;
    // Billable information from Template
    newDurationBillable = template.durationBillable;
    newNonBillableReason = template.nonBillableReason;
  } else {
    newDuration = timeInput.duration;
    newBreak = timeInput.break;
    newStart = timeInput.start
      ? parseTimeWithDate(timeInput.start, recordDate)
      : null;
    newEnd = timeInput.end
      ? parseTimeWithDate(timeInput.end, recordDate)
      : null;
    // Billable information depending on Template status
    if (
      template.tntModelLine &&
      template.tntModelLine.id &&
      timeInput.tntModelLine === null
    ) {
      if (template.tntModelLine.billable === false) {
        newDurationBillable = 0;
        newNonBillableReason = template.tntModelLine.nonBillableReason;
      } else {
        newDurationBillable = timeInput.duration - timeInput.break;
        newNonBillableReason = NonBillableReasonOptions.NONE;
      }
    } else {
      newDurationBillable = timeInput.durationBillable;
      newNonBillableReason = timeInput.nonBillableReason;
    }
  }

  // Logic for projecting fields
  // Fill ProjectingFields ongoing if already filled matches with incomaing template
  if (timeInput.customer && template.customer) {
    if (timeInput.customer.id === template.customer.id) {
      newCustomer = template.customer;
      if (!timeInput.job && template.job) {
        newJob = template.job;
        newJobTask = template.jobTask;
        if (template.jobPlanningLine && template.tntModelLine) {
          newJobPlanningLine = {
            ...template.jobPlanningLine,
            tntModelLine: template.tntModelLine,
          };
          newTntModelLine = template.tntModelLine;
        }
      } else if (
        timeInput.job &&
        template.job &&
        timeInput.job.id === template.job.id
      ) {
        newJob = template.job;
        if (!timeInput.jobTask && template.jobTask) {
          newJobTask = template.jobTask;
          if (template.jobPlanningLine && template.tntModelLine) {
            newJobPlanningLine = {
              ...template.jobPlanningLine,
              tntModelLine: template.tntModelLine,
            };
            newTntModelLine = template.tntModelLine;
          }
        } else if (timeInput.jobTask && template.jobTask) {
          if (timeInput.jobTask.id === template.jobTask.id) {
            newJobTask = template.jobTask;
          }
          if (!timeInput.jobPlanningLine && template.jobPlanningLine) {
            if (template.jobPlanningLine && template.tntModelLine) {
              newJobPlanningLine = {
                ...template.jobPlanningLine,
                tntModelLine: template.tntModelLine,
              };
              newTntModelLine = template.tntModelLine;
            }
          } else if (timeInput.jobPlanningLine && template.jobPlanningLine) {
            if (timeInput.jobPlanningLine.id === template.jobPlanningLine.id) {
              newJobPlanningLine = timeInput.jobPlanningLine;
              newTntModelLine = timeInput.tntModelLine;
            }
          }
        }
      }
    }
  }
  // Else fill only when Customer is empty
  else {
    if (template.customer) {
      newCustomer = template.customer;
      if (template.job) {
        newJob = template.job;
        if (template.jobTask) {
          newJobTask = template.jobTask;
          if (template.jobPlanningLine) {
            newJobPlanningLine = template.jobPlanningLine;
            if (template.tntModelLine) {
              newTntModelLine = template.tntModelLine;
              // Fill tntModelLine in jobPlanningLine
              newJobPlanningLine = {
                ...newJobPlanningLine,
                tntModelLine: template.tntModelLine,
              };
            }
          }
        }
      }
    }
  }

  const newTimeInput: RecordTimeRec = {
    id: timeInput.id,
    start: newStart,
    end: newEnd,
    duration: newDuration,
    break: newBreak,
    calendarEventId: timeInput.calendarEventId,
    description: timeInput.description
      ? timeInput.description
      : template.description
      ? template.description
      : "",
    date: DateTime.fromISO(recordDate).toISO(),
    created: "",
    modified: "",
    status: StatusOptions.OPEN,
    complete: false,
    // Customer
    customer: newCustomer,
    // Job
    job: newJob,
    // JobTask
    jobTask: newJobTask,
    // Activity
    jobPlanningLine: newJobPlanningLine,
    tntModelLine: newTntModelLine,
    durationBillable: newDurationBillable,
    nonBillableReason: newNonBillableReason,
    workItem: null,
  };

  return newTimeInput;
};

export const definePrevStateObject = (
  entries: (RecordEntry & Partial<RecordTemplate & RecordTimeRec>) | null,
  recordDate = ""
) => {
  const prevStates: PrevStateObject = {
    timeInput: defineTimeInputs(entries, null, recordDate),
    memory: defineMemory(entries),
    errors: [],
  };
  return prevStates;
};

export const defineMemory = (
  entries: (RecordEntry & Partial<RecordTemplate & RecordTimeRec>) | null
): MemoryState => {
  let memory: MemoryState;
  if (entries) {
    memory = {
      duration: entries.duration,
      pause: entries.break,
      billableDuration: entries.durationBillable,
      lastStart: entries.start,
      lastEnd: entries.end,
      tntId: entries.tntModelLine ? entries.tntModelLine.id : "",
      nonBillableReasonChanged:
        entries.tntModelLine && entries.tntModelLine.nonBillableReason
          ? true
          : false,
      nonBillableReason: entries.nonBillableReason,
    };
  } else {
    memory = {
      duration: 0,
      pause: 0,
      billableDuration: -1,
      lastStart: null,
      lastEnd: null,
      tntId: "",
      nonBillableReasonChanged: false,
      nonBillableReason: NonBillableReasonOptions.NONE,
    };
  }
  return memory;
};

/**
 * Function to set Yup schema: Define keys likely {@link defineTimeInputs()}
 * @returns Yup Schema
 */
export const defineYupSchema = (yup: any) => {
  const yupSchema = yup.object().shape({
    id: yup.string().nullable(),
    start: yup.mixed().nullable(),
    end: yup.mixed().nullable(),
    calendarEventId: yup.string().nullable(),
    duration: yup.mixed().nullable(),
    break: yup.mixed().nullable(),
    date: yup.mixed().nullable(),
    description: yup.string().nullable(),
    hidden: yup.string().nullable(),
    customer: yup
      .object()
      .shape({
        id: yup.string().nullable(),
        name: yup.string().nullable(),
        no: yup.string().nullable(),
      })
      .nullable(),
    job: yup
      .object()
      .shape({
        id: yup.string().nullable(),
        description: yup.string().nullable(),
        no: yup.string().nullable(),
      })
      .nullable(),
    jobTask: yup
      .object()
      .shape({
        id: yup.string().nullable(),
        description: yup.string().nullable(),
        no: yup.string().nullable(),
        job: yup.object().shape({
          id: yup.string().nullable(),
          description: yup.string().nullable(),
          no: yup.string().nullable(),
        }),
        jobPlanningLine: yup
          .object()
          .shape({
            id: yup.string().nullable(),
            description: yup.string().nullable(),
          })
          .nullable(),
        status: yup.string().nullable(),
      })
      .nullable(),
    jobPlanningLine: yup
      .object()
      .shape({
        id: yup.string().nullable(),
        description: yup.string().nullable(),
        serviceBillingType: yup.string().nullable(),
        tntModelLine: yup.object().shape({
          id: yup.string().nullable(),
          description: yup.string().nullable(),
          billable: yup.boolean(),
          nonBillableReason: yup.string().nullable(),
          rounding: yup.number(),
        }),
        jobTask: yup.object().shape({
          id: yup.string().nullable(),
          description: yup.string().nullable(),
          no: yup.string().nullable(),
          status: yup.string().nullable(),
        }),
      })
      .nullable(),
    tntModelLine: yup
      .object()
      .shape({
        id: yup.string().nullable(),
        description: yup.string().nullable(),
        itemNo: yup.number(),
        billable: yup.boolean(),
        nonBillableReason: yup.string().nullable(),
        rounding: yup.number(),
      })
      .nullable(),
    durationBillable: yup.mixed().nullable(),
    nonBillableReason: yup.mixed().nullable(),
    // created: yup.string().nullable(),
    name: yup.string(),
    status: yup.string(),
    workType: yup
      .object()
      .shape({
        id: yup.string(),
        source: yup.string(),
        organization: yup.string(),
        project: yup.string(),
        title: yup.string(),
        status: yup.string().nullable(),
      })
      .nullable(),
  });

  return yupSchema;
};

export const parseTimeWithDate = (
  templateTime: string,
  currentDate: string
): string => {
  const incomingTime = DateTime.fromISO(templateTime);
  const date = DateTime.fromISO(currentDate);
  const newTime = date
    .set({
      year: date.get("year"),
      month: date.get("month"),
      day: date.get("day"),
      hour: incomingTime.get("hour"),
      minute: incomingTime.get("minute"),
      second: incomingTime.get("second"),
      millisecond: incomingTime.get("millisecond"),
    })
    .toISO();

  return newTime;
};

export const handleDurationBillableRounding = (
  duration: number,
  rounding: number
): number => {
  const modulo = duration % (rounding > 0 ? rounding : 1);
  if (rounding === 1 || modulo === 0) {
    return duration;
  } else {
    const billableDuration = duration - modulo + rounding;
    return billableDuration;
  }
};

/**
 * Function to check if entries are {@link RecordTimeRec} or not
 * Is called from {@link defineTimeInputs()}
 * @param entries @see {defineTimeInputs}
 * @returns Boolean
 */
export const isRecord = (
  entries: RecordEntry & Partial<RecordTemplate & RecordTimeRec>
): entries is RecordTimeRec => {
  return Boolean(entries.date);
};

/**
 * Function to proof if chenges were made by user or not
 * @param timeInput TimeInputs object
 * @param timeInputsInitial TimeInputs object from initial state
 * @returns Boolean
 */
export const compareChanges = (
  timeInput: RecordEntry & Partial<RecordTemplate & RecordTimeRec>,
  timeInputInitial: RecordEntry & Partial<RecordTemplate & RecordTimeRec>
): boolean => {
  const omitKeys = [
    "category",
    "created",
    "status",
    "jobTask.jobPlanningLine",
    "jobPlanningLine",
    "jobPlanningLine.jobTask",
    "jobPlanningLine.description",
    "jobPlanningLine.tntModelLine",
    "jobPlanningLine.serviceBillingType",
  ];

  // Compare initial status with current status
  const checkTimeInputs = _.omit(
    {
      ...timeInput,
      date: timeInput.date ? timeInput.date.split("T")[0] : undefined,
      id: timeInput.id && timeInput.id.length > 0 ? timeInput.id : undefined,
      durationBillable: timeInput.durationBillable
        ? timeInput.durationBillable
        : undefined,
      jobPlanningLine:
        timeInput.jobPlanningLine && timeInput.jobPlanningLine.id
          ? {
              id:
                timeInput.jobPlanningLine.id.length > 36
                  ? timeInput.jobPlanningLine.id.slice(0, -36)
                  : timeInput.jobPlanningLine.id,
            }
          : null,
    },
    omitKeys
  );
  const checkTimeInputsInitial = _.omit(
    {
      ...timeInputInitial,
      date: timeInputInitial.date
        ? timeInputInitial.date.split("T")[0]
        : undefined,
      id:
        timeInputInitial.id && timeInputInitial.id.length > 0
          ? timeInputInitial.id
          : undefined,
      durationBillable: timeInputInitial.durationBillable
        ? timeInputInitial.durationBillable
        : undefined,
    },
    omitKeys
  );

  const result = isArrayEqual(
    Object.entries(checkTimeInputs),
    Object.entries(checkTimeInputsInitial)
  );

  return result;
};

/**
 * Function to proof equality from object entries {@link compareChanges()}
 * @param x Objectentries from TimeInputs object
 * @param y Objectentries from initial TimeInputs object
 * @returns Negated boolean
 */
const isArrayEqual = (x: any, y: any): boolean => {
  return !_(x).differenceWith(y, _.isEqual).isEmpty();
};

/**
 * Function to check if location.state has a value
 * @param location Location
 * @returns State from location to spread or null
 */
export const handleLocationState = (
  location: any
): { ["x-string"]: boolean } | null => {
  const locationState = location.state;

  return locationState ? locationState : null;
};

/**
 * Function to check if user changed Projecting fields after found validation errors
 * @param validateErrorIds Array with all wrong validated id's
 * @param timeInput TimeInput object from validation
 * @returns Boolean
 */
export const foundValidationErrors = (
  validateErrorIds: string[],
  timeInput: RecordEntry
): boolean => {
  let found = false;
  validateErrorIds.forEach((id) => {
    if (
      validateErrorIds.length > 0 &&
      Object.entries(timeInput).some((key) =>
        key.some((value: any) => value && value.id === id)
      )
    ) {
      found = true;
    }
  });
  return found;
};

/**
 * Function to focus specified Field in Editor component
 * @param setFocus setFocus hook from react-hook-form
 * @param name Name of registered Field
 * @param timeOut Number for defined timeout
 */
export const handleFocusField = (
  setFocus: UseFormSetFocus<
    RecordEntry & Partial<RecordTemplate & RecordTimeRec>
  >,
  name: FieldTypes,
  timeOut = 0
) => {
  const timer = setTimeout(() => {
    try {
      setFocus(name);
    } catch {
      console.log("Registration not found!");
    }
  }, timeOut);
  return () => clearTimeout(timer);
};

/**
 * Function to slice id from jobPlanningLine object. This is needed to have unique
 * id for Activity field, so preselected value can be marked in LookUp datagrid.
 * @param timeInput TimeInput object from Editor
 * @returns TimeInput object to save
 */
export const sliceJobPlanningLineId = (
  timeInput: RecordEntry & Partial<RecordTemplate & RecordTimeRec>
): RecordEntry & Partial<RecordTemplate & RecordTimeRec> => {
  if (
    timeInput.jobPlanningLine &&
    timeInput.jobPlanningLine.id &&
    timeInput.jobPlanningLine.id.length > 36
  ) {
    const mutateTimeInput: RecordEntry &
      Partial<RecordTemplate & RecordTimeRec> = {
      ...timeInput,
      jobPlanningLine: {
        ...timeInput.jobPlanningLine,
        id: timeInput.jobPlanningLine.id.slice(0, -36),
      },
    };
    return mutateTimeInput;
  } else {
    return timeInput;
  }
};

/**
 * Function to handle CollapsibleHeader title from JSON file
 * @param title Custom or defined title for CollapsibleHeader
 * @returns Custom title or defined title translated
 */
export const handleCollapsibleHeaderTitle = (
  title: CollapsibleHeader
): string => {
  switch (title) {
    case "mapping":
      return i18next.t("timerecs.editor.collapsible.header.mapping");
    case "billable":
      return i18next.t("timerecs.editor.collapsible.header.billable");
    default:
      return title;
  }
};
