import { createSelector } from "@reduxjs/toolkit";
import {
  doRenderDay,
  foreignEntriesAsTwoDimArray,
  handleStatisticsByPeriods,
  handleSummaryByPeriods,
  recordsByDate,
} from "./util";
import { DateTime } from "luxon";
import * as _ from "lodash";
import { PeriodTime, RecordTimeRec, StatisticsPiePeriod } from "@dyce/tnt-api";
import { ResourceList } from "@dyce/interfaces";
// Dependencies to resources & settings
import { selectCurrentUserResources } from "../resources/resourceSelector";
import { selectTimeRecordingSettings } from "../settings/settingsSelector";
import { selectCurrentUser } from "../user/userSelector";
import { RootState } from "../../types/types";

const dateFormat = "yyyy-MM-dd";

export const selectAddDateToSelector = (state: RootState) =>
  state.timerec.addDateToSelector;
export const selectEditorState = (state: RootState) => state.timerec.openEditor;
export const selectTimerecs = (state: RootState) => state.timerec.entries;
export const selectLoaded = (state: RootState) => state.timerec.loaded;
export const selectTimerecsIsLoading = (state: RootState) =>
  state.timerec.isLoadingEntries;
export const selectTimerecsAreLoading = (state: RootState) =>
  state.timerec.requests.length > 0;
export const selectTimerecsAsArray = (state: RootState) =>
  Object.values(state.timerec.entries);
export const selectRequestsTimerecs = (state: RootState) =>
  state.timerec.requests;
export const incompleteCount = (state: RootState) =>
  state.timerec.incompleteEntries.count;
export const incompleteTimerecs = (state: RootState) =>
  state.timerec.incompleteEntries.entries;
export const isStopwatchRunning = (state: RootState) =>
  Boolean(state.timerec.stopwatch?.running);

export const selectStopwatch = (state: RootState) => state.timerec.stopwatch;
export const selectIsStopwatchLoading = (state: RootState) =>
  state.timerec.stopwatchLoading;
// export const selectSettings = (state: RootState) => state.settings.timerec;

export const selectLatestRecId = (state: RootState) =>
  state.timerec.latestCreatedId;
export const selectLatestEndTime = (state: RootState) =>
  state.timerec.latestCreatedEndTime;
export const selectErrorOnUpdate = (state: RootState) =>
  state.timerec.errorOnUpdate;

export const isLoadingEntriesTimerecs = createSelector(
  (state: RootState) => state.timerec,
  (recs) => recs.isLoadingEntries
);

export const selectRecById = createSelector(
  selectTimerecs,
  (_: any, id: string) => id,
  (recs, id) => recs[id]
);

export const selectAnyTimerecRequestPending = createSelector(
  selectRequestsTimerecs,
  (requests) => requests.length > 0
);

/**
 * Gets the weekly time overview for the dashboard
 */
export const periodTime = (state: RootState) => state.timerec.periodTimes;
/**
 * Gets the weekly statistics overview for the dashboard
 */
export const periodStatistics = (state: RootState) =>
  state.timerec.periodStatistics;

/**
 * Get all entries in the current week
 */
export const selectRecsByWeek = createSelector(
  [
    selectTimerecs,
    selectCurrentUserResources,
    selectLoaded,
    selectTimeRecordingSettings,
    selectAddDateToSelector,
  ],
  (recs, defaults, loaded, settings, addToSelector) => {
    const start = loaded;
    const {
      dashboard: { showDaysWithoutCapacity },
    } = settings;

    let recArray = Object.values(recs);

    // filter all entries that are the week of the given date
    recArray = recArray.filter((r) =>
      DateTime.fromISO(r.date)
        .toLocal()
        .hasSame(DateTime.fromISO(start).toLocal(), "week")
    );

    // reduce array to 2-dimensions with date as first key
    const sortedRecs =
      recArray.length > 0
        ? _.groupBy(
            recArray,
            (val) => DateTime.fromISO(val.date).toISO().split("T")[0]
          ) // group by date
        : ([] as unknown as _.Dictionary<
            // or return empty array if recs empty
            RecordTimeRec[]
          >);

    const currentDate = DateTime.fromISO(start);
    const weekStart = currentDate.startOf("week");

    const entries: RecordTimeRec[] = [];
    const days = [];

    // create a weekday array
    const weekdays: string[] = [];
    for (let i = 0; i < 7; i++) {
      weekdays.push(weekStart.plus({ days: i }).toFormat(dateFormat));
    }

    // merge entries with weekdays
    for (let index = 0; index < weekdays.length; index++) {
      const el = weekdays[index];
      if (sortedRecs[el] && sortedRecs[el].length > 0) {
        sortedRecs[el].forEach((e) => entries.push(e));
      } else {
        const render = doRenderDay(
          DateTime.fromFormat(el, dateFormat).toJSDate(),
          defaults
        );
        if (render || showDaysWithoutCapacity) {
          days.push(DateTime.fromFormat(el, dateFormat).toJSDate());
        }

        // add empty day if flag is set
        // e.g. 'today' is not a working day and user press altModifier+n || plus-button
        if (
          addToSelector &&
          DateTime.fromISO(addToSelector)
            .toLocal()
            .hasSame(DateTime.fromISO(start).toLocal(), "week")
        ) {
          days.push(DateTime.fromFormat(addToSelector, dateFormat).toJSDate());
        }
      }
    }

    return recordsByDate(entries, days);
  }
);

/**
 * Get all imcomplete entries
 */
export const selectIncompleteEntries = createSelector(
  incompleteTimerecs,
  (recs) => {
    const recArray = Object.values(recs);

    const grouped = Object.entries(
      recArray.reduce(
        (timeRecs, timeRec) => {
          const date = DateTime.fromISO(timeRec.date).toFormat(dateFormat);
          timeRecs[date] = timeRecs[date]
            ? [...timeRecs[date], timeRec]
            : [timeRec];
          return timeRecs;
        },
        {} as { [key: string]: RecordTimeRec[] }
      )
    );

    const sorted = grouped.sort(
      (a, b) =>
        DateTime.fromFormat(b[0], dateFormat).toMillis() -
        DateTime.fromFormat(a[0], dateFormat).toMillis()
    );

    return sorted;
  }
);

/**
 * Get all incomplete entries for foreign extension
 */
export const selectIncompleteForeignEntries = createSelector(
  [incompleteTimerecs, selectCurrentUser],
  (incompleteRecs, currentUser) => {
    return foreignEntriesAsTwoDimArray(incompleteRecs, currentUser.resource);
  }
);

/**
 * Get all entries for foreign extension
 */
export const selectForeignEntries = createSelector(
  [selectTimerecs, selectCurrentUser],
  (recs, currentUser): [ResourceList, RecordTimeRec[]][] => {
    return foreignEntriesAsTwoDimArray(recs, currentUser.resource);
  }
);

/**
 * Get summary grouped by period (week, month, year)
 * and correct capacity for each period
 */
export const selectSummaryAsPeriod = createSelector(
  [periodTime, selectCurrentUserResources],
  (summary, currentUser): PeriodTime[] => {
    return handleSummaryByPeriods({
      payload: summary,
      userCapacity: currentUser,
    });
  }
);

/**
 * Get statistics grouped by period (week, month, year)
 * with Jobs information for 'others' (> 5)
 */
export const selectStatisticAsPeriod = createSelector(
  [periodStatistics],
  (statistics): StatisticsPiePeriod | null => {
    if (!statistics) {
      return null;
    } else {
      return handleStatisticsByPeriods({ payload: statistics });
    }
  }
);

/**
 * Get all entries for the current period in one object
 * to have best rerender performance
 */
export const selectPeriodTimesAndStatistics = createSelector(
  [selectSummaryAsPeriod, selectStatisticAsPeriod, selectRequestsTimerecs],
  (summary, statistics, requests) => {
    if (requests.length > 0 || summary.length === 0 || !statistics) {
      return null;
    } else {
      return {
        periodTime: summary,
        statistics: statistics,
      };
    }
  }
);
