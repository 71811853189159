import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { formatIntl } from "./format";

const languages = {
    de: "deutsch",
    en: "english",
};

const whiteList = [
    "en",
    "en-US",
    "en-CA",
    "en-GB",
    "de",
    "de-DE",
    "de-AT",
    "de-CH",
    "de-GB",
    "de-US",
];

i18n
    // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-xhr-backend
    .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: "en",
        supportedLngs: whiteList,
        debug: false,
        backend: {
            loadPath: "../../assets/locales/{{lng}}/{{ns}}.json",
        },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default

            format: function (value, format) {
                return formatIntl(value, format, i18n.language);
            },
        },
    });

i18n.on("languageChanged", function (lng) {
    console.log("i18n languageChanged", lng);
});

export { languages };
export default i18n;
