import { FunctionComponent, Suspense } from "react";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { IconButton, List, Paper, Collapse } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const marginTop = 30;

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    root: {
      position: "fixed",
      display: "flex",
      height: "calc(100vh - 56px)",
      left: 0,
      top: 56,
      whiteSpace: "nowrap",
      zIndex: theme.zIndex.speedDial,
    },
    paper: {
      margin: theme.spacing(1),
    },
    paperStyle: {
      stroke: theme.palette.divider,
      strokeWidth: 1,
    },
    toolbarOpen: {
      transition: theme.palette.propsDyce.drawerTransitions.transCommon,
      padding: theme.spacing(0, 0.5),
      marginLeft: theme.palette.propsDyce.drawerWidthOpen.width,
      marginTop: marginTop,
    },
    toolbarClose: {
      transition: "all ease 300ms",
      padding: theme.spacing(0, 0.5),
      marginLeft: 3,
      marginTop: marginTop,
    },
  })
);

interface IDrawerLeftProps {
  /**
   * Callback fired when expand/collapse button clicked;
   * If undefined, button is not visible;
   * @value = !open
   */
  openClose?: (value: boolean) => void;
  /**
   * If true, drawer will be expanded
   * @default true
   */
  open?: boolean;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const DrawerLeft: FunctionComponent<IDrawerLeftProps> = ({
  openClose,
  open = true,
  children,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper
        square
        className={classes.paperStyle}
        elevation={2}
        sx={{
          width: open ? 250 : 60,
          transition: "all ease 300ms",
        }}
      >
        <Collapse
          in={open}
          collapsedSize={60}
          orientation={"horizontal"}
          timeout={{ enter: 200, exit: 300 }}
          sx={{
            "& .MuiCollapse-wrapperInner": {
              width: !open ? 60 : "100%",
              transition: "all ease 300ms",
            },
          }}
        >
          {openClose && (
            <div className={open ? classes.toolbarOpen : classes.toolbarClose}>
              <IconButton
                onClick={() => openClose(!open)}
                aria-label="open drawer"
                id="drawerButton"
              >
                {open ? (
                  <ChevronLeftIcon id="drawerButtonLeft" />
                ) : (
                  <ChevronRightIcon id="drawerButtonRight" />
                )}
              </IconButton>
            </div>
          )}
          <Suspense fallback="">
            <List style={{ marginTop: 5 }}>{children}</List>
          </Suspense>
        </Collapse>
      </Paper>
    </div>
  );
};
