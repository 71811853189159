import { MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
// Helper
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import {
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  StatusOptions,
} from "@dyce/tnt-api";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
// Components
import { DateFieldMoment } from "../../inputs/dateField/moment/dateField";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    datePicker: {
      "& .MuiIconButton-edgeEnd": {
        color: theme.palette.primary.main,
      },
    },
    textFieldDate: {
      width: "10rem",
      "& .MuiFilledInput-input": {
        padding: "22px 10px 6px",
      },
      "& .MuiFormLabel-root": {
        color: theme.palette.primary.main,
        fontSize: 12,
      },
      "& .MuiFilledInput-underline:before": {
        borderBottomColor: theme.palette.primary.main,
      },
      "& .MuiInputBase-input": {
        fontSize: 14,
        height: "1.5rem",
      },
      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor:
          theme.palette.mode === "light"
            ? "rgba(0, 0, 0, 0.23)"
            : "rgba(255, 255, 255, 0.23)",
      },
    },
  })
);

interface IWorkDateFieldProps {
  /**
   * Timerecording object
   */
  timeInput: RecordEntry & Partial<RecordTemplate & RecordTimeRec>;
  /**
   * Common CSS style
   */
  commonTextFieldStyles: string;
  /**
   * Current selected language is 'en'?
   */
  isEnglish: boolean;
  /**
   * If true, components will be disabled;
   */
  pending: boolean;
  /**
   * Define tabIndex to focus correct field
   */
  tabIndex?: number;
  /**
   * If true, the input element will be focused during the first mount
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Unique ID for testing field
   * @default "test-cypress-id"
   */
  testId?: string;
  /**
   * Define size of textfield
   */
  size?: "small" | "medium";
  /**
   * Callback fired onBlur event, to save reference to state
   * @param ref MutableRefObject<HTMLInputElement | null>
   * @returns void
   */
  onInputBlur?: (ref: MutableRefObject<HTMLInputElement | null>) => void;
}

export const WorkDateField: React.FC<IWorkDateFieldProps> = ({
  timeInput,
  tabIndex,
  isEnglish,
  commonTextFieldStyles,
  pending,
  testId = "test-cypress-id",
  autoFocus = false,
  size,
  onInputBlur,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  return (
    <div
      className={classes.datePicker}
      style={{
        marginRight: size === "small" ? "0" : "2rem",
      }}
    >
      <Controller
        name="date"
        control={control}
        render={({ field: { onChange, onBlur, ref } }) => (
          <DateFieldMoment
            refHook={ref}
            data-testid="date-field"
            testId={testId}
            onChange={onChange}
            onBlur={(_, ref) => {
              onInputBlur && onInputBlur(ref);
              onBlur;
            }}
            size={size}
            autoFocus={autoFocus}
            // Temporarily needed, till MUI fixes 'LocalizationProvider' for DateTime lib.
            localeHyphen={moment.locale()}
            tabIndex={tabIndex}
            isDisabled={
              (timeInput.status && timeInput.status !== StatusOptions.OPEN) ||
              timeInput.status === undefined ||
              pending
            }
            value={timeInput.date ? timeInput.date : ""}
            label={errors["date"] ? "" : t("timerec.dailyRecording.add.date")}
            style={classNames(classes.textFieldDate, commonTextFieldStyles)}
            variant={"outlined"}
            isEnglish={isEnglish}
          />
        )}
        defaultValue={timeInput.date}
      />
    </div>
  );
};
