import { CalendarEntries } from "../types";
import { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

export class CalendarService {
  public baseUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = combineURLs(baseUrl, "/calendar");

    this.axiosInstance = instance;
  }

  /**
   * Generic Function to batch requests
   * @param requests Requests to be batched @type {ODataBatchRequest[]}
   * @returns ODataBatchResponse<T>
   */
  public async getEntries({ from, to }: { from: string; to: string }) {
    const url = `${this.baseUrl}?from=${from}&to=${to}`;

    const response = await this.axiosInstance.get<CalendarEntries[]>(url);

    return response.data;
  }
}
