import { DateTime } from "luxon";

/**
 * Normalizes an ISO Date by rounding the seconds to the nearest minute
 * @param time ISO Date string
 * @returns ISO Date string
 */
export const normalizeTime = (
  start: string,
  end: string
): { start: string; end: string } => {
  let startDT = DateTime.fromISO(start);
  let endDT = DateTime.fromISO(end);

  let diff = startDT.diff(endDT, ["seconds", "minutes"]);

  // if duration < 60s set start to 0 and end to full minute
  if (
    diff.minutes === 0 &&
    diff.seconds < 60 &&
    startDT.minute === endDT.minute
  ) {
    startDT = startDT.set({
      second: 0,
      millisecond: 0,
    });

    endDT = endDT.set({
      minute: startDT.minute + 1,
      second: 0,
      millisecond: 0,
    });
  } else {
    // round to next full minute
    if (startDT.second !== 0) {
      startDT = startDT.set({
        minute: startDT.minute + 1,
        second: 0,
        millisecond: 0,
      });
    }

    // round to next full minute
    if (endDT.second !== 0) {
      endDT = endDT.set({
        minute: endDT.minute + 1,
        second: 0,
        millisecond: 0,
      });
    }

    // maintain 1 minute minimal duration
    diff = startDT.diff(endDT, ["minutes"]);
    if (diff.minutes === 0) {
      endDT = endDT.set({
        minute: endDT.minute + 1,
        second: 0,
        millisecond: 0,
      });
    }
  }

  return { start: startDT.toISO(), end: endDT.toISO() };
};

export const getDuration = (start: string, end: string): number => {
  const dif = DateTime.fromISO(end).diff(DateTime.fromISO(start), "minute");
  return dif.minutes;
};
