import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./app/serviceWorker";
import "@dyce/translations";
import { AbilityProvider, AuthProvider } from "@dyce/auth";
import App from "./app/App";
import { NetworkWatcher, StoreProvider } from "@dyce/slices";
import { Backdrop } from "@dyce/ui";
import { t } from "i18next";
import { createRoot } from "react-dom/client";
import { UserConsent, getUserConsent, startSentry } from "@dyce/utils";

if (getUserConsent() === UserConsent.GIVEN) {
  startSentry();
}

const root = createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StoreProvider
    selectSlices={{
      admin: true,
      network: true,
      resources: true,
      settings: true,
      task: true,
      calendar: true,
      templates: true,
      timerec: true,
      tnt: true,
      ui: true,
      user: true,
    }}
  >
    <AuthProvider
      authority={process.env["NX_AUTH_URL"] as string}
      clientId={process.env["NX_AUTH_CLIENT_ID"] as string}
      scope={process.env["NX_API_SCOPE"] as string}
      redirectUri="/"
    >
      <AbilityProvider>
        <Router>
          <App />
          <NetworkWatcher
            renderAtSlowNetwork={(slowNetwork) => (
              <Backdrop
                value={t("networkWatcher.slowNetwork")}
                open={slowNetwork}
              />
            )}
          />
        </Router>
      </AbilityProvider>
    </AuthProvider>
  </StoreProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
