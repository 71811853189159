import React, { FunctionComponent } from "react";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

const useStyle = makeStyles(() =>
  createStyles({
    headerContainer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      marginTop: ".5rem",
      paddingBottom: "1.5rem",
    },
    captionColor: {
      color: "grey",
      fontSize: "0.8rem",
    },
  })
);

interface IPlotHeaderProps {
  /**
   * Title to be shown in h6
   */
  title: string;
  /**
   * Description to be shown in 'caption' and grey
   */
  description?: string;
  /**
   * Optional JSX.Element callback to render component on left side of header
   */
  headerItemLeft?: () => React.ReactElement;
  /**
   * Optional JSX.Element callback to render component on the middle of header
   */
  headerItem?: () => React.ReactElement;
  /**
   * Optional JSX.Element callback to render component on right side of header
   */
  headerItemRight?: () => React.ReactElement;
}

export const PlotHeader: FunctionComponent<IPlotHeaderProps> = ({
  title,
  description,
  headerItemLeft,
  headerItem,
  headerItemRight,
}) => {
  const classes = useStyle();

  return (
    <div className={classes.headerContainer}>
      <Grid container direction="row" style={{ display: "contents" }}>
        {headerItemLeft && <Grid item>{headerItemLeft()}</Grid>}
        <Grid item>
          <Grid item>
            <Typography variant="h6">{title}</Typography>
          </Grid>
          {description && (
            <Grid item>
              <Typography variant="caption" className={classes.captionColor}>
                {description}
              </Typography>
            </Grid>
          )}
          {headerItem && <Grid item>{headerItem()}</Grid>}
        </Grid>
        {headerItemRight && <Grid item>{headerItemRight()}</Grid>}
      </Grid>
    </div>
  );
};
