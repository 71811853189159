import { Periods } from "@dyce/interfaces";
import { Days, ResourceCapacity } from "@dyce/tnt-api";
import { DateTime } from "luxon";

/**
 * Function to get the capacity for each day of the provided date
 * in correct capacity period from user
 * @param param0 Current date in ISO format and array of capacities
 * @returns Capacity for the current day as Number
 */
export const handleCapaPerDay = ({
  currentDate,
  capacities,
}: {
  /**
   * Current date in ISO format
   */
  currentDate: string;
  /**
   * Array of capacities sorted by date
   */
  capacities: ResourceCapacity[];
}): number => {
  const date = DateTime.fromISO(currentDate);
  const weekDay = date.toFormat("cccc").toLowerCase() as Days;
  let workDayCapa = 0;

  capacities.forEach((capa) => {
    const capaDate = DateTime.fromISO(capa.periodStart);
    if (date >= capaDate) {
      workDayCapa = capa.capacity[weekDay];
    }
  });

  return workDayCapa;
};

/**
 * Function to set the proper format for the selected period to set shortest string length
 * for Bar-Chart to show the date in the x-axis
 * Also possible to get the date in ISO format back; Everything controlled by the @property {period}
 * @param param0 See every single property
 * @returns Date as string in ISO format or empry string
 */
export const handleBarChartDateFormat = ({
  period = "week",
  date,
  options,
}: {
  /**
   * Period to get the proper format
   * @default "week"
   */
  period: Periods;
  /**
   * @property {setFormat = true} -> Date in ISO format
   * @property {getDate = true} -> Date in proper format for the selected period
   */
  date: string;
  /**
   * Options to retrieve the date in ISO format or to set the proper format for the selected period
   * @param getDate Boolean to get the date in ISO format
   * @param setFormat Boolean to set the proper format for the selected period
   * if setFormat is true, getDate is ignored;
   * if getDate is true, setFormat is ignored;
   * if both are false or true, an ISODate string is returned (2020-01-01)
   * @default { getDate: false, setFormat: false }
   */
  options: {
    getDate?: boolean;
    setFormat?: boolean;
  };
}): string => {
  const { getDate = false, setFormat = false } = options;
  const errorDate = "2000-01-01";

  if ((setFormat && getDate) || (!setFormat && !getDate)) {
    return errorDate;
  }
  if (setFormat) {
    if (DateTime.fromISO(date).isValid) {
      switch (period) {
        case "week":
          // ooo = ordinal (day of year), padded to 3 (218)
          return DateTime.fromISO(date).toFormat("ooo kk");
        case "month":
          // WW = week of year as a padded number (31)
          return DateTime.fromISO(date).toFormat("WW kk");
        case "year":
          // MM = month as a padded number (08)
          return DateTime.fromISO(date).toFormat("MMkk");
      }
    } else {
      console.warn("Date is not valid");
      return errorDate;
    }
  }
  if (getDate) {
    let dateFormat: string | null = null;
    switch (date.length) {
      case 6:
        dateFormat = "ooo kk";
        break;
      case 5:
        dateFormat = "WW kk";
        break;
      case 4:
        dateFormat = "MMkk";
        break;
      default:
        dateFormat = "ooo kk";
        break;
    }
    if (dateFormat) {
      switch (dateFormat) {
        case "ooo kk": {
          if (period === "week") {
            const day = DateTime.fromFormat(date.split(" ")[0], "ooo").toFormat(
              "MM-dd"
            );
            const year = DateTime.fromFormat(date.split(" ")[1], "kk").toFormat(
              "yyyy"
            );
            return `${year}-${day}`; // 2021-03-15
          } else {
            console.warn("Period should be 'week' for this format");
            return errorDate;
          }
        }
        case "WW kk": {
          if (period === "month") {
            return DateTime.fromFormat(date, "WW kk")
              .startOf("week")
              .toISODate();
          } else {
            console.warn("Period should be 'month' for this format");
            return errorDate;
          }
        }
        case "MMkk": {
          if (period === "year") {
            const month = DateTime.fromFormat(date.slice(0, -2), "MM")
              .startOf("month")
              .toFormat("MM-dd");
            const dateYear = `20${date.slice(2)}`;
            return `${dateYear}-${month}`; // 2021-01-01
          } else {
            console.warn("Period should be 'year' for this format");
            return errorDate;
          }
        }
      }
    } else {
      return errorDate;
    }
  }
  return errorDate;
};
