import React, { useState, useEffect } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  getCalendarWeek,
  getMonthInfoString,
  goToLastWeek,
  goToNextWeek,
  goToToday,
  handleCreateNewTimerecording,
} from "./utils";
import { DateTime } from "luxon";
import { useHotkeys } from "react-hotkeys-hook";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { DatePagination, SubHeader, Tooltip, useStaticContent } from "@dyce/ui";
import { useUpdateEffect } from "@dyce/hooks";
import {
  getRecsByWeek,
  removeEntries,
  selectCurrentWorkspace,
  selectCurrentUserResources,
  selectEditorState,
  selectHasResource,
  selectLanguageCode,
  selectLocale,
  selectLocaleHyphen,
  selectRecsByWeek,
  setDateForSelector,
  setOpenEditor,
  useAppDispatch,
  useAppSelector,
  RootState,
} from "@dyce/slices";
import { getItemsArrayInfos, validateLocationHashDate } from "@dyce/utils";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import { Chip, Container, Fab, Typography } from "@mui/material";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// Components
import { TimerecordingList } from "../../../components/timerecording";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    recordsContainer: { ...theme.palette.propsTimeTracking.recordsContainer },
    subTitle: {
      display: "flex",
      alignItems: "center",
    },
    subTitleChip: {
      display: "inherit",
      marginLeft: "1rem",
    },
    children: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      flexGrow: 1,
      height: "3.5rem",
      alignItems: "center",
    },
    weekContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      paddingTop: "12px",
    },
    buttonsRight: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      width: "220px",
    },
  })
);

export const Timerecordings: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const location = useLocation<{
    fromDashboard: boolean;
    fromTemplates: {
      scrollPosition: number;
    };
    fromTasks: {
      scrollPosition: number;
    };
  }>();
  const history = useHistory();
  const {
    platform,
    docuLinks,
    routes,
    isMobile: { mobile },
  } = useStaticContent();
  const scrollContainer = document.getElementById("rootContainer") ?? undefined;

  const dateFormat = "yyyy-MM-dd";
  const goBack = Boolean(
    (location.state && location.state.fromDashboard) ||
      (location.state && location.state.fromTemplates) ||
      (location.state && location.state.fromTasks)
  );

  // Selectors
  const userEntryDate = useAppSelector(selectCurrentUserResources);
  const resource = useAppSelector(selectHasResource);
  const workspace = useAppSelector(selectCurrentWorkspace);
  const dayRecords = useAppSelector(selectRecsByWeek);
  const isLoading = useAppSelector(
    (state: RootState): unknown[] => state.timerec.requests
  );
  const language = useAppSelector(selectLanguageCode);
  const locale = useAppSelector(selectLocale);
  const localeHyphen = useAppSelector(selectLocaleHyphen);
  const editorOpen = useAppSelector(selectEditorState);

  // States
  const [locationStartDate] = useState<string>(
    validateLocationHashDate(location, localeHyphen, userEntryDate)
  );
  const [initLoading, setInitLoading] = useState<boolean>(true);
  const [itemsArrayInfos, setItemsArrayInfos] = useState<[string, number][]>(
    []
  );
  const [additionHeight, setAdditionHeight] = useState<number>(0);
  const [allowScroll, setAllowScroll] = useState<boolean>(true);

  // UseEffects
  useEffect(() => {
    return () => {
      dispatch(removeEntries());
      dispatch(setDateForSelector(null));
      dispatch(setOpenEditor(false));
      if (scrollContainer) {
        scrollContainer.scrollTo({
          top: 0,
          left: 0,
        });
      }
    };
  }, []);

  useEffect(() => {
    //Initial ApiCall
    dispatch(
      getRecsByWeek({
        start: new Date(locationStartDate),
      })
    );
  }, [workspace, resource]);

  useUpdateEffect(() => {
    if (isLoading.length === 0) {
      setInitLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    // Give single array.length to child component
    const itemsInfo = getItemsArrayInfos(dayRecords);
    setItemsArrayInfos(itemsInfo);

    let count = 0;
    for (let i = 0; i < itemsInfo.length - 1; i++) {
      count += 1 + itemsInfo[i][1];
    }
    setAdditionHeight(count * 58);
  }, [dayRecords]);

  useEffect(() => {
    if (editorOpen) {
      setAllowScroll(false);
    }
  }, [editorOpen]);

  // UseHotKeys
  useHotkeys(
    // prevent refresh page && callApi again
    platform.modifiers.refreshPage,
    (e) => {
      if (!initLoading) {
        e.preventDefault();
        setInitLoading(true);
        let getTopDate = DateTime.now().toISODate();
        if (dayRecords.length > 0) {
          getTopDate = dayRecords[0][0];
        }
        dispatch(removeEntries());
        // API Call
        dispatch(
          getRecsByWeek({
            start: new Date(getTopDate),
          })
        );
      }
    },
    [dayRecords, initLoading]
  );

  useHotkeys(
    // Create new timerecording for today
    `${platform.modifiers.modifierAlt}+n`,
    (e) => {
      e.preventDefault();
      setTimeout(
        () => {
          dispatch(setDateForSelector(DateTime.now().toISODate()));
          handleCreateNewTimerecording(history as any, false);
        },
        editorOpen ? 800 : 0
      );
    },
    [dayRecords]
  );

  // Handler
  const today = () => {
    const todayIncurrentWeek = dayRecords.some(
      (x) => DateTime.fromFormat(x[0], dateFormat).day === DateTime.now().day
    );
    if (!todayIncurrentWeek) {
      setInitLoading(true);
    }
    dispatch(setOpenEditor(false));
    setAllowScroll(true);
    history.push(goToToday(location as unknown as Location));
  };

  const subWeek = () => {
    history.push(goToLastWeek(location as unknown as Location));
    setInitLoading(true);
    setAllowScroll(false);
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
        left: 0,
      });
    }
  };

  const addWeek = () => {
    history.push(goToNextWeek(location as unknown as Location));
    setInitLoading(true);
    setAllowScroll(false);
    if (scrollContainer) {
      scrollContainer.scrollTo({
        top: 0,
        left: 0,
      });
    }
  };

  const handleCalendar = (date: DateTime | null) => {
    setAllowScroll(true);
    const urlDate: string = date
      ? "#".concat(date.toString().split("T")[0])
      : "";
    history.push(urlDate);
  };

  const showMonth = (): string => {
    let monthInfo = "";
    if (location.hash.length > 0) {
      const hashDate: string = location.hash.split("#")[1];

      monthInfo = getMonthInfoString(hashDate, dateFormat, language);
    } else {
      monthInfo = "";
    }
    return monthInfo;
  };

  const handleWeekDate = (): string => {
    if (location.hash.length > 0) {
      return location.hash.split("#")[1];
    } else {
      return "";
    }
  };

  const showCalendarWeek = (): string => {
    let calendarWeek = "";
    if (location.hash.length > 0) {
      const hashDate: string = location.hash.split("#")[1];

      calendarWeek = getCalendarWeek(
        hashDate,
        dateFormat,
        locale,
        localeHyphen
      );
    } else {
      calendarWeek = "";
    }
    return calendarWeek;
  };

  const handleReturnURI = () => {
    if (location.state.fromDashboard) {
      history.push(`${routes.TNT}`);
    } else if (location.state.fromTemplates) {
      history.push(`${routes.TNT_TEMPLATES}`, { ...location.state });
    } else if (location.state.fromTasks) {
      history.push(`${routes.TNT_TASKS}`, { ...location.state });
    }
  };

  return (
    <>
      <SubHeader
        tooltipLabel={t("timerec.subHeader.myTime.dailyRecords.info")}
        tooltipUrlPath={docuLinks.timetracking.timerecordings.subheader.info}
        childrenMobile={mobile}
        textMobile={mobile}
        title={
          <Typography
            fontSize="1.25rem"
            fontWeight={500}
            lineHeight={1.6}
            letterSpacing="0.00075em"
            marginBottom="0.0035em"
          >
            {t("timerec.subHeader.myTime.dailyRecords.title")}
          </Typography>
        }
        subtitle={
          <div className={classes.subTitle}>
            {showCalendarWeek().length > 0 && (
              <>
                <Typography variant="h4" sx={{ marginLeft: "-2px" }}>
                  {showMonth()}
                </Typography>
                <Chip
                  className={classes.subTitleChip}
                  size="small"
                  label={showCalendarWeek()}
                />
              </>
            )}
          </div>
        }
        isLoading={isLoading.length > 0}
      >
        <div className={classes.children}>
          <div className={classes.weekContainer}>
            <DatePagination
              forwardHandler={addWeek}
              backwardHandler={subWeek}
              todayHandler={today}
              date={handleWeekDate()}
              localeHyphen={localeHyphen}
              withCalendar={{
                onSelect: handleCalendar,
              }}
            />
          </div>
          <div className={classes.buttonsRight}>
            {goBack && !mobile && (
              <Tooltip
                label={t("timerec.subHeader.myTime.dailyRecords.back")}
                urlPath={
                  docuLinks.timetracking.timerecordings.subheader.goBackButton
                }
                position="bottom"
              >
                <Fab color="primary" onClick={() => handleReturnURI()}>
                  <ArrowBackIcon fontSize="medium" />
                </Fab>
              </Tooltip>
            )}
            <Tooltip
              label={t("timerec.subHeader.myTime.dailyRecords.newTimeRec", {
                modifier: platform.description.modifierAlt,
              })}
              position={"bottom"}
              urlPath={
                docuLinks.timetracking.timerecordings.subheader
                  .createTimerecordingButton
              }
            >
              <Fab
                aria-label="day-picker"
                color="primary"
                onClick={() => {
                  dispatch(setDateForSelector(DateTime.now().toISODate()));
                  handleCreateNewTimerecording(history as any, editorOpen);
                }}
                style={{ marginLeft: 20 }}
              >
                <AddOutlinedIcon fontSize="medium" />
              </Fab>
            </Tooltip>
          </div>
        </div>
      </SubHeader>
      <div className={classes.recordsContainer}>
        <Container maxWidth={false}>
          <TimerecordingList
            dayRecords={dayRecords}
            itemsArrayInfos={itemsArrayInfos}
            setInitLoading={setInitLoading}
            initLoading={initLoading}
            additionHeight={additionHeight}
            allowScroll={allowScroll}
            openNewAtToday={() =>
              handleCreateNewTimerecording(history as any, true)
            }
            timeTrackingToolOpen={(value) => dispatch(setOpenEditor(value))}
            timeTrackingToolIsOpen={editorOpen}
            clearTodayDate={() => dispatch(setDateForSelector(null))}
          />
        </Container>
      </div>
    </>
  );
};
