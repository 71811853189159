import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Button } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    loadMore: {
      textAlign: "center",
      alignSelf: "center",
      width: 150,
      marginBottom: "20px !important",
    },
  })
);

interface ILoadMoreProps {
  count: number;
  loadCount: number;
  listLength: number;
  loadMore: () => void;
}

export const LoadMore: FunctionComponent<ILoadMoreProps> = ({
  count,
  loadCount,
  listLength,
  loadMore,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  // UseStates
  const [loading, setLoading] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    if (loading) {
      if (count < loadCount) {
        setLoading(false);
      }
    }
  }, [count]);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      {count > 0 && listLength > 0 && loadCount < count && (
        <Button
          variant="contained"
          onClick={() => {
            setLoading(true);
            loadMore();
          }}
          className={classes.loadMore}
        >
          {t("lib.pagination.button.loadMore")}
        </Button>
      )}
      {loading && <div style={{ height: 56.5 }}></div>}
    </div>
  );
};
