import {
  FunctionComponent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
// Helper
import {
  handleDayHeaderInfoProgress,
  handleEventRenderer,
  handleInitialCalendarSettings,
} from "./renderComponents";
import {
  handleEventDurationCheck,
  handleEventSorting,
  handleEventToTimerecording,
  handleGUIDCheck,
  handleMutateEvents,
  handleRecordEventDependency,
  handleTimeFormat,
} from "./utils";
import { Calendar, EventModelConfig } from "@bryntum/calendar";
import { useTranslation } from "react-i18next";
import { DateTime } from "luxon";
import { default as dynamicCalendar } from "./formDesign/dynamicCalendar.json";
import { calendarConfig } from "./calendarConfig";
import _ from "lodash";
// Dyce-Lib
import { useNotification, useUpdateEffect } from "@dyce/hooks";
import {
  CalendarEntries,
  RecordTemplate,
  RecordTimeRec,
  TasksFilterStatus,
} from "@dyce/tnt-api";
import { Periods, ToolStyle } from "@dyce/interfaces";
import { EventEditor, PopulatedEventModel, TimeTrackingTool } from "@dyce/ui";
import { DyceTheme } from "@dyce/theme";
import {
  handleRecordBudget,
  handleSaveTimerecording,
  validateTemplateObject,
} from "@dyce/utils";
import {
  SetupLanguages,
  getAllTemplates,
  handleCapaWithHolidays,
  removeRec,
  selectCapacities,
  selectCurrentUserResources,
  setOpenEditor,
  useAppDispatch,
  useAppSelector,
} from "@dyce/slices";
// MUI
import { Container, useTheme } from "@mui/material";
// Components
import { BryntumCalendar } from "@bryntum/calendar-react";
// Languages
import "@bryntum/calendar/locales/calendar.locale.De";
import "@bryntum/calendar/locales/calendar.locale.En";

type IncompleteTimesStyles = {
  recordsContainer: string;
};

interface ICalendarViewProps {
  /**
   * The time recordings to display
   */
  timeRecordings: RecordTimeRec[];
  /**
   * The calendar entries to display
   */
  calendarEntries: CalendarEntries[] | null;
  /**
   * The templates to display
   */
  templates: [string, RecordTemplate[]][];
  /**
   * The styles to apply for the component fit to DYCE
   */
  styles: IncompleteTimesStyles;
  /**
   * The language to use
   * @default "de"
   */
  language: SetupLanguages;
  /**
   * The locale to use
   * @default "en-GB"
   */
  locale?: string;
  /**
   * Theme to use
   * @default false
   */
  darkMode?: boolean;
  /**
   * The date to display
   */
  date: Date;
  /**
   * The period to display
   */
  period: Periods;
}

export const CalendarView: FunctionComponent<ICalendarViewProps> = ({
  styles,
  timeRecordings,
  templates,
  calendarEntries,
  language = "de",
  locale = "en-GB",
  darkMode = false,
  date,
  period,
}) => {
  const theme = useTheme<DyceTheme>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const calendarStyle = darkMode ? "classic-dark" : "classic-light";
  let isDeleting = false;
  const copyHandler = new Map();

  // useRefs
  const calendarRef = useRef<BryntumCalendar>(null);
  const calendarInstance = () => calendarRef.current?.instance as Calendar;
  const elementRef = useRef<Element | null>(null);
  const elementHoverRef = useRef<Element | null>(null);

  // Selectors
  const resourceCapacity = useAppSelector(selectCapacities);
  const userCapacity = useAppSelector(selectCurrentUserResources);

  // UseStates
  const [initConfig, setInitConfig] = useState<boolean>(false);
  const [internalDate, setInternalDate] = useState<Date>(date);
  // Events | (Calendar or Timerecordings)
  const [calendarEvents, setCalendarEvents] = useState<
    Partial<EventModelConfig>[]
  >([]);
  const [headerEvents, setHeaderEvents] = useState<Partial<EventModelConfig>[]>(
    []
  );
  const [recordEntries, setRecordEntries] = useState<RecordTimeRec[] | null>(
    null
  );
  const [timerecordingId, setTimerecordingId] = useState<string | null>(null);
  const [updateEvents, setUpdateEvents] = useState<boolean>(false);
  const [updateEventWithId, setUpdateEventWithId] = useState<string | null>(
    null
  );
  // Header
  const [headerRendered, setHeaderRendered] = useState<boolean>(false);
  // TimeRecordings
  const [recordPre, setRecordPre] = useState<RecordTimeRec | null>(null);
  const [record, setRecord] = useState<RecordTimeRec | null>(null);
  const [editorHeight, setEditorHeight] = useState<number>(0);
  // Anchors
  const [eventTimerecordingAnchorPre, setEventTimerecordingAnchorPre] =
    useState<HTMLElement | null>(null);
  const [eventTimerecordingAnchor, setEventTimerecordingAnchor] =
    useState<HTMLElement | null>(null);

  // EventListener
  const createEventListener = (type: "keydown" | "keyup") => {
    const handler = (e: KeyboardEvent) => {
      handleHotkey(e);
    };
    window.addEventListener(type, handler);
    return handler;
  };

  // UseEffects
  useUpdateEffect(() => {
    setRecordEntries(timeRecordings);
  }, [timeRecordings]);

  useEffect(() => {
    if (initConfig) {
      const shiftKeyDown = createEventListener("keydown");
      const shiftKeyUp = createEventListener("keyup");
      copyHandler.set("keydown", shiftKeyDown);
      copyHandler.set("keyup", shiftKeyUp);
    }
    // Cleanup function
    return () => {
      copyHandler.forEach((handler, type) => {
        window.removeEventListener(type, handler);
      });
      copyHandler.clear(); // clear the map
    };
  }, [initConfig]);

  useLayoutEffect(() => {
    startInitialSetup();
    if (process.env.NODE_ENV === "development") {
      // TODO: delete this when license is available
      localStorage.setItem("b-calendar-trial-start", "1687219200000");
      localStorage.setItem("b-calendar-verify-date", "1689811200000");
    }
  }, []);

  useUpdateEffect(() => {
    if (initConfig) {
      if (!headerRendered) {
        setHeaderRendered(true);
      }
      if (internalDate !== date) {
        setInternalDate(date);
      }
    }
  }, [date, initConfig]);

  useEffect(() => {
    if (
      initConfig &&
      eventTimerecordingAnchor === null &&
      calendarEntries !== null &&
      recordEntries !== null
    ) {
      const combinedEntries = [...calendarEntries, ...recordEntries];
      const newEvents = handleMutateEvents(
        combinedEntries
      ) as PopulatedEventModel[];
      setUpdateEvents(false);
      setHeaderEvents(newEvents);
      const { sortedEvents } = handleEventSorting({ events: newEvents });
      const isEqual = _.isEqual(sortedEvents, calendarEvents);
      if (!isEqual) {
        setCalendarEvents(sortedEvents);
      }
    }
  }, [
    initConfig,
    recordEntries,
    calendarEntries,
    updateEvents,
    eventTimerecordingAnchor,
  ]);

  // Add component to header
  useUpdateEffect(() => {
    if (headerRendered) {
      handleDayHeaderInfoProgress({
        date: DateTime.fromJSDate(internalDate).toISO(),
        events: headerEvents as PopulatedEventModel[],
        resourceCapacity: resourceCapacity,
      });
    }
  }, [headerRendered, headerEvents]);

  // Handler
  const startInitialSetup = async () => {
    const done = await handleInitialCalendarSettings({
      calendarStyle,
      language,
    });
    if (done) {
      setInitConfig(true);
    }
  };

  /**
   * Function to handle copy by dragging
   * @param e KeyboardEvent
   */
  const handleHotkey = (e: KeyboardEvent) => {
    if (e.repeat) {
      return;
    }
    if (e.key === "Shift" && e.type === "keydown" && elementRef.current) {
      elementRef.current.classList.remove("b-dragging-item");
      elementRef.current.classList.add("b-selected");
      elementRef.current.classList.add("b-hover-anim");
      elementRef.current.classList.add("b-active");
      elementRef.current.classList.add("copy-element");
    }
    if (e.key === "Shift" && e.type === "keyup" && elementRef.current) {
      elementRef.current.classList.add("b-dragging-item");
      elementRef.current.classList.remove("b-selected");
      elementRef.current.classList.remove("b-hover-anim");
      elementRef.current.classList.remove("b-active");
      elementRef.current.classList.remove("copy-element");
    }
    if (e.key === "Escape" && e.type === "keydown" && elementRef.current) {
      elementRef.current.classList.remove("copy-element");
      elementRef.current.classList.remove("b-selected");
      elementRef.current = null;
    }
  };

  const handleTimeRecordingForm = useCallback(
    ({
      element,
      buttonElement,
      eventRecord,
    }: {
      element: HTMLElement | null;
      buttonElement: HTMLElement | null;
      eventRecord: PopulatedEventModel;
    }) => {
      const rec = handleEventToTimerecording({
        event: eventRecord,
        timerecordingId: eventRecord.timerecordingId,
      });
      setRecordPre(rec);
      setUpdateEventWithId(null);

      // Anchor for Timerecording-Form
      const parentElementFromButton =
        buttonElement?.parentNode?.parentElement?.parentElement?.parentElement
          ?.parentElement;
      setEventTimerecordingAnchorPre(
        element
          ? element
          : parentElementFromButton === undefined
          ? buttonElement
          : parentElementFromButton
      );
    },
    []
  );

  useEffect(() => {
    if (eventTimerecordingAnchor === null && recordPre !== null) {
      setEventTimerecordingAnchor(eventTimerecordingAnchorPre);

      const recId = recordPre && recordPre.id.length > 0 ? recordPre.id : null;
      setRecord(recordPre);
      setTimerecordingId(recId);
      setUpdateEventWithId(null);
    }
  }, [eventTimerecordingAnchorPre, recordPre]);

  /**
   * Function to handle the event before edit (open the time record form)
   * @param param0 EventRecord and EventElement from Calendar
   * @returns False to prevent default behavior
   */
  const handleOnBeforeEventEdit = ({
    eventRecord,
    eventElement,
  }: {
    eventRecord: any;
    eventElement: HTMLElement;
  }) => {
    if (eventRecord.data.editable === false) {
      return false;
    }
    const rec = handleEventToTimerecording({
      event: eventRecord.data,
      timerecordingId: eventRecord.data.timerecordingId,
    });
    setRecord(rec);
    if (!eventRecord.data.id.includes("_generated")) {
      setTimerecordingId(rec.id);
    }
    setUpdateEventWithId(null);
    // Anchor for Timerecording-Form
    setEventTimerecordingAnchor(eventElement);

    return false;
  };

  /**
   * Function to proof if duration is valid (end is not in next day)
   * @param param0 EventRecord from Calendar
   * @returns true if duration is valid else false
   */
  const handleValidateDragFn = ({ eventRecord }: { eventRecord: any }) => {
    if (isDeleting) {
      // Pending delete in progress
      return false;
    }
    const { error } = handleEventDurationCheck({
      event: eventRecord.data,
    });

    if (error) {
      notification(
        t("calendar.eventEditor.createTimerecording.error.duration"),
        {
          variant: "error",
          canClose: true,
        }
      );

      return false;
    } else {
      if (eventRecord.data.timerecording) {
        // Resizing an existing timerecording (event) => update
        handleSaveByInteraction({
          event: eventRecord.data,
          copyElement: false,
        });
      }
      setUpdateEvents(true);
      if (elementRef.current) {
        // Reset elementRef (old element)
        elementRef.current = null;
      }
      return true;
    }
  };

  /**
   * Function to proof if duration is valid (end is not in next day)
   * @param param0 EventRecord from Calendar
   * @returns true if duration is valid else false
   */
  const handleDragMoveFn = async ({ eventRecord }: { eventRecord: any }) => {
    if (isDeleting) {
      return false;
    }
    setUpdateEventWithId(null);

    if (
      elementRef.current &&
      elementRef.current.classList.contains("copy-element")
    ) {
      if (eventRecord.data.timerecording) {
        // Copy an existing timerecording (event) => update
        handleSaveByInteraction({
          event: eventRecord.data,
          copyElement: true,
        });
      }
      // Reset elementRef (element to copy)
      if (elementRef.current) {
        elementRef.current.classList.remove("b-selected");
        elementRef.current.classList.remove("copy-element");
        elementRef.current = null;
      }
      return false;
    } else {
      // Update Event
      if (eventRecord.data.timerecording) {
        // Moving an existing timerecording (event) => update
        handleSaveByInteraction({
          event: eventRecord.data,
          copyElement: false,
        });
      }
      setUpdateEvents(true);
      if (elementRef.current) {
        // Reset elementRef (old element)
        elementRef.current = null;
      }
      return true;
    }
  };

  const handleSaveByInteraction = async ({
    event,
    copyElement,
  }: {
    event: PopulatedEventModel;
    copyElement: boolean;
  }) => {
    const rec = handleEventToTimerecording({
      event: event,
      timerecordingId: event.timerecordingId,
      updatePeriods: event.allDay ? false : true,
      updateDate: event.allDay ? true : false,
    });
    const newRec = copyElement
      ? { ...rec, id: undefined, calendarEventId: null }
      : rec;
    const { error } = await handleSaveTimerecording(newRec, dispatch);
    setUpdateEvents(error);
  };

  /**
   * Function to control the tools for the tooltip depending on
   * create or edit timerecording
   * @param param0 source from Calendar
   */
  const handleBeforeShowTooltip = ({ source }: { source: any }) => {
    // Do not show tools for calendar events
    const eventId = source.title.eventInfo.id;
    if (handleGUIDCheck(eventId) === false) {
      if (source.title.eventInfo.timerecordingId === undefined) {
        const renderAddButton = handleRecordEventDependency({
          currentEvent: source.title.eventInfo.event,
          events: calendarEvents as PopulatedEventModel[],
        });
        source.tools.createTimeRecord = renderAddButton;
        source.tools.editTimerecording = false;
        source.tools.deleteTimerecording = false;
      } else {
        source.tools.createTimeRecord = false;
        source.tools.editTimerecording = true;
        source.tools.deleteTimerecording = true;
      }
    } else {
      source.tools.createTimeRecord = false;
      source.tools.editTimerecording = true;
      source.tools.deleteTimerecording = true;
    }
  };

  const handleDeleteTimerecording = ({
    id,
  }: {
    id: string;
  }): Promise<boolean> => {
    if (!isDeleting) {
      isDeleting = true;
      return new Promise<boolean>((resolve) => {
        dispatch(removeRec(id)).then((result) => {
          if (result.meta.requestStatus === "fulfilled") {
            notification(t("calendar.timerecording.delete.success"), {
              variant: "success",
              canClose: true,
            });
            isDeleting = false;
            setUpdateEvents(true);
            resolve(true);
          } else {
            notification(t("calendar.timerecording.delete.error"), {
              variant: "error",
              canClose: true,
            });
            isDeleting = false;
            resolve(false);
          }
        });
      });
    } else {
      notification(t("calendar.timerecording.delete.inProgress"), {
        variant: "error",
        canClose: true,
      });
      return Promise.resolve(false);
    }
  };

  const eventRenderer = useCallback(
    ({ eventRecord }: { eventRecord: any }) => {
      const renderAddButton = handleRecordEventDependency({
        currentEvent: eventRecord.data,
        events: calendarEvents as PopulatedEventModel[],
      });

      return handleEventRenderer({
        renderAddButton: renderAddButton,
        eventRecord: eventRecord.data,
        theme,
        updateEventId: updateEventWithId,
        onTimeRecordingForm: handleTimeRecordingForm,
      });
    },
    [calendarEvents, updateEventWithId]
  );

  const handleHoverLinkedItem = ({
    eventRecord,
    itemIsRecord,
  }: {
    eventRecord: any;
    itemIsRecord: boolean;
  }) => {
    if (itemIsRecord) {
      // If new created => event.timeRecord is undefined
      if (eventRecord.timerecording) {
        const linkedCalEvent = calendarEvents.find(
          (event) => event.id === eventRecord.timerecording.calendarEventId
        );
        if (linkedCalEvent) {
          const isLinked = !handleRecordEventDependency({
            currentEvent: linkedCalEvent as PopulatedEventModel,
            events: calendarEvents as PopulatedEventModel[],
          });

          if (isLinked) {
            const el = document.querySelector(
              `[data-event-id="${eventRecord.timerecording.calendarEventId}"]`
            );
            if (el) {
              elementHoverRef.current = el;
              elementHoverRef.current.classList.add("b-hover-anim");
              elementHoverRef.current.classList.add("b-selected");
              elementHoverRef.current.classList.add("b-hover-anim");
              elementHoverRef.current.classList.add("b-active");
            }
          }
        }
      }
    } else {
      const isLinked = !handleRecordEventDependency({
        currentEvent: eventRecord as PopulatedEventModel,
        events: calendarEvents as PopulatedEventModel[],
      });
      if (isLinked) {
        const linkedRecord = calendarEvents.find((event) => {
          if ((event as PopulatedEventModel).timerecording) {
            return (
              (event as PopulatedEventModel).timerecording!.calendarEventId ===
              eventRecord.id
            );
          } else {
            return undefined;
          }
        });
        if (linkedRecord) {
          const el = document.querySelector(
            `[data-event-id="${linkedRecord.id}"]`
          );
          if (el) {
            elementHoverRef.current = el;
            elementHoverRef.current.classList.add("b-hover-anim");
            elementHoverRef.current.classList.add("b-selected");
            elementHoverRef.current.classList.add("b-hover-anim");
            elementHoverRef.current.classList.add("b-active");
          }
        }
      }
    }
  };

  return (
    <div className={styles.recordsContainer} id="calendarEntries">
      <Container maxWidth={false}>
        {initConfig && (
          <>
            <BryntumCalendar
              onEventMouseOver={({ eventRecord }: { eventRecord: any }) => {
                const event = eventRecord.data;
                handleHoverLinkedItem({
                  eventRecord: eventRecord.data,
                  itemIsRecord: event.timerecording !== null,
                });
              }}
              onEventMouseOut={() => {
                if (elementHoverRef.current) {
                  elementHoverRef.current.classList.remove("b-hover-anim");
                  elementHoverRef.current.classList.remove("b-selected");
                  elementHoverRef.current.classList.remove("b-hover-anim");
                  elementHoverRef.current.classList.remove("b-active");
                  elementHoverRef.current = null;
                }
              }}
              ref={calendarRef}
              events={calendarEvents}
              date={internalDate} // Set date to current date
              readOnly={eventTimerecordingAnchor !== null}
              sidebar={false} // Hide Sidebar
              hideNonWorkingDays={period === "workweek"}
              timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
              weekStartDay={1} // Start on monday
              mode={period === "workweek" ? "week" : period} // Set mode to week
              height={`calc(${theme.palette.propsDyce.contentVisualHeight.height} - 16px)`}
              preventTooltipOnTouch={true}
              modes={{
                day: false,
                month: false,
                year: false,
                agenda: false,
                week: {
                  shortEventDuration: "45 minutes",
                  eventLayout: {
                    clearanceMinutes: 75, // Define minutes at which startDate diff. events overlapping
                  },
                  timeFormat: handleTimeFormat({
                    date: internalDate,
                    locale: locale,
                  }),
                  eventRenderer: eventRenderer,
                  dayHeaderRenderer: (domConfig: any) => {
                    const workHours = handleCapaWithHolidays({
                      dateToCheck: domConfig.dataset.headerDate,
                      capacity:
                        Object.entries(userCapacity).length > 0
                          ? userCapacity
                          : undefined,
                      period: "day",
                    });
                    if (workHours === 0) {
                      domConfig.className["b-nonworking-day"] = true;
                      domConfig.className["b-weekend"] = true;
                    } else {
                      domConfig.className["b-nonworking-day"] = false;
                      domConfig.className["b-weekend"] = false;
                    }
                    return domConfig;
                  },
                  dayCellRenderer: (domConfig: any) => {
                    const workHours = handleCapaWithHolidays({
                      dateToCheck: domConfig.dataset.date,
                      capacity:
                        Object.entries(userCapacity).length > 0
                          ? userCapacity
                          : undefined,
                      period: "day",
                    });
                    if (workHours === 0) {
                      domConfig.className["b-nonworking-day"] = true;
                      domConfig.className["b-weekend"] = true;
                    } else {
                      domConfig.className["b-nonworking-day"] = false;
                      domConfig.className["b-weekend"] = false;
                    }
                    return domConfig;
                  },
                },
              }}
              dragFeature={{
                newName: t("calendar.event.description.new"),
                recurrenceTip: "",
                durationUnit: "minute",
                onDragStart: () => {
                  let collection =
                    document.getElementsByClassName("b-selected");
                  if (collection.length === 0) {
                    collection = document.getElementsByClassName(
                      "b-sch-event-selected"
                    );
                  }

                  if (
                    collection.length > 0 &&
                    (collection.item(0) as any).dataset &&
                    collection &&
                    (collection.item(0) as any).dataset.eventId
                  ) {
                    const elementToCopy = collection.item(0);
                    elementRef.current = elementToCopy;
                  }
                },
              }}
              onBeforeDragMoveEnd={handleDragMoveFn}
              onBeforeDragResizeEnd={handleValidateDragFn}
              onBeforeDragCreateEnd={handleValidateDragFn}
              eventMenuFeature={{
                items: {
                  editEvent: {
                    weight: 100,
                    text: t("calendar.menu.caption.edit"),
                    onItem: ({
                      eventElement,
                      eventRecord,
                    }: {
                      eventElement: HTMLElement;
                      eventRecord: any;
                    }) => {
                      handleTimeRecordingForm({
                        element: eventElement,
                        buttonElement: null,
                        eventRecord: eventRecord,
                      });
                    },
                  },
                  duplicate: {
                    weight: 200,
                    icon: "b-fa b-fa-clone",
                    text: t("calendar.menu.caption.duplicate"),
                    // Will look up ownership chain and find the Calendar
                    onItem: ({ eventRecord }: { eventRecord: any }) => {
                      handleSaveByInteraction({
                        event: eventRecord.data,
                        copyElement: true,
                      });
                    },
                  },
                  deleteEvent: {
                    weight: 300,
                    text: t("calendar.menu.caption.delete"),
                    onItem: ({ eventRecord }: { eventRecord: any }) => {
                      handleDeleteTimerecording({
                        id: eventRecord.data.id,
                      });
                    },
                  },
                },
                // Process items before context-menu is shown
                processItems({
                  items,
                  eventRecord,
                }: {
                  items: any;
                  eventRecord: any;
                }) {
                  // Do not show menu for events without timerecordings
                  if (eventRecord.data.editable === false) {
                    const isLinked = !handleRecordEventDependency({
                      currentEvent: eventRecord.data as PopulatedEventModel,
                      events: calendarEvents as PopulatedEventModel[],
                    });
                    items.editEvent.disabled = isLinked;
                    items.editEvent.text = t("calendar.menu.caption.new");
                    items.duplicate.hidden = true;
                    items.deleteEvent.hidden = true;
                    return true;
                  } else {
                    return true;
                  }
                },
              }}
              onBeforeEventDelete={({
                eventRecords,
              }: {
                eventRecords: any[];
              }) => {
                const id = eventRecords[0].data.id;
                if (handleGUIDCheck(id)) {
                  handleDeleteTimerecording({
                    id: id,
                  });
                  return true;
                } else {
                  return false;
                }
              }}
              eventTooltipFeature={{
                align: "l-r", // Position where tooltip of events is shown
                revealEventsInCluster: false, // Size Events to maximum width when selected
                listeners: {
                  beforeShow: handleBeforeShowTooltip,
                },
                timeFormat: handleTimeFormat({
                  date: internalDate,
                  locale: locale,
                }),
                tools: {
                  delete: false,
                  edit: false,
                  // Add a new tool for our own operation
                  createTimeRecord: {
                    cls: "b-icon-add",
                    tooltip: t("calendar.menu.caption.new"),
                    handler: () => {
                      const elTooltip = document.getElementById(
                        "b-calendar-1-event-tip"
                      );
                      if (elTooltip) {
                        const eventInfo = (elTooltip?.children[1] as any)
                          .lastDomConfig.children[0].eventInfo;

                        const id = eventInfo.id;
                        const event = eventInfo.event;
                        const el = document.querySelector(
                          `[data-event-id="${id}"]`
                        );
                        if (el) {
                          handleTimeRecordingForm({
                            element: el as HTMLElement,
                            buttonElement: null,
                            eventRecord: event,
                          });
                        } else {
                          console.log(
                            "event info found but element not found!"
                          );
                        }
                      } else {
                        console.log("Element not found!");
                      }
                    },
                  },
                  editTimerecording: {
                    cls: "b-icon-clock",
                    tooltip: t("calendar.menu.caption.edit"),
                    handler: () => {
                      const elTooltip = document.getElementById(
                        "b-calendar-1-event-tip"
                      );
                      if (elTooltip) {
                        const eventInfo = (elTooltip?.children[1] as any)
                          .lastDomConfig.children[0].eventInfo;

                        const id = eventInfo.id;
                        const event = eventInfo.event;
                        const el = document.querySelector(
                          `[data-event-id="${id}"]`
                        );
                        if (el) {
                          handleTimeRecordingForm({
                            element: el as HTMLElement,
                            buttonElement: null,
                            eventRecord: event,
                          });
                        } else {
                          console.log(
                            "event info found but element not found!"
                          );
                        }
                      } else {
                        console.log("Element not found!");
                      }
                    },
                  },
                  deleteTimerecording: {
                    cls: "b-icon-trash",
                    tooltip: t("calendar.menu.caption.delete"),
                    handler: async (e: any) => {
                      const elTooltip = document.getElementById(
                        "b-calendar-1-event-tip"
                      );
                      if (elTooltip) {
                        const eventInfo = (elTooltip?.children[1] as any)
                          .lastDomConfig.children[0].eventInfo;

                        const id = eventInfo.timerecordingId;

                        const deleted = await handleDeleteTimerecording({
                          id: id,
                        });
                        if (deleted) {
                          e.path[2].remove();
                        }
                      } else {
                        console.log("Element not found!");
                      }
                    },
                  },
                },
                titleRenderer(eventRecord: any) {
                  return {
                    text: eventRecord.data.name,
                    eventInfo: {
                      id: eventRecord.data.id,
                      timerecordingId: eventRecord.data.timerecordingId,
                      event: eventRecord.data,
                    },
                  };
                },
              }}
              scheduleMenuFeature={{
                newName: t("calendar.event.description.new"),
                recurrenceTip: "",
                items: {
                  addEvent: {
                    disabled: false,
                    text: t("calendar.menu.caption.new"),
                  },
                },
              }}
              scrollAction={"realign"} // Scroll to event when selected
              onBeforeEventEdit={handleOnBeforeEventEdit}
              {...calendarConfig}
            />
            <EventEditor
              anchorEl={eventTimerecordingAnchor}
              onClose={() => {}}
              withCloseButton={false}
              darkMode={darkMode}
              maxWidth={800}
              minHeight={494 - 56}
              forceMinHeightAsHeight={editorHeight + 56}
              altBoundaryPreventOverflow={false}
              overwriteZIndex={theme.zIndex.appBar + 1}
              headerCaption={
                !timerecordingId || timerecordingId === "creating"
                  ? t(
                      "calendar.eventEditor.headerCaption.timerecordingEvent.new"
                    )
                  : t(
                      "calendar.eventEditor.headerCaption.timerecordingEvent.update"
                    )
              }
            >
              <TimeTrackingTool
                forceSave
                allowClickAway={false}
                record={record}
                onCloseEditor={() => {
                  setEventTimerecordingAnchorPre(null);
                  setEventTimerecordingAnchor(null);
                  setRecordPre(null);
                  setRecord(null);
                  setEditorHeight(0);
                  const preRec = calendarInstance().eventStore.find((e: any) =>
                    e.id.includes("_generated")
                  );
                  if (preRec) {
                    calendarInstance().eventStore.remove(preRec);
                  }
                }}
                onSave={async (record) => {
                  setUpdateEventWithId(record.id || null);
                  setEventTimerecordingAnchorPre(null);
                  setEventTimerecordingAnchor(null);
                  setRecordPre(null);
                  setRecord(null);
                  const { error, newEntry } = await handleSaveTimerecording(
                    record,
                    dispatch
                  );
                  setUpdateEvents(true);
                  return { newEntry, error };
                }}
                getRecordBudget={async ({ id, levelOfDetail }) =>
                  await handleRecordBudget({
                    dispatch,
                    id,
                    levelOfDetail,
                    inHours: true,
                  })
                }
                allowUriMutation={false}
                currentHeight={(height) => setEditorHeight(height)}
                open={eventTimerecordingAnchor !== null}
                recordDate={(record && record.date) || ""}
                ignoreOpenNewEditor
                overwriteDesign={dynamicCalendar as ToolStyle}
                isEnglish={locale ? locale.includes("en") : false}
                workWithFilter={TasksFilterStatus.OPTIONAL}
                validateTemplateObject={async (template) =>
                  await validateTemplateObject(template, dispatch)
                }
                states={{
                  setter: {
                    timeTrackingToolOpen: (value) =>
                      dispatch(setOpenEditor(value)),
                    templateList: {
                      getAllTemplates: () => dispatch(getAllTemplates()),
                    },
                  },
                  getter: {
                    localeHyphen: locale ? locale : "en-GB",
                    darkMode: darkMode,
                    templates: templates,
                  },
                }}
              />
            </EventEditor>
          </>
        )}
      </Container>
    </div>
  );
};
