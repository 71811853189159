import {
  Activity,
  Customer,
  PopulatedJob,
  PopulatedJobPlanningLine,
  PopulatedJobTask,
} from "../types";
import buildQuery, { QueryOptions } from "odata-query";
import { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

export class TnTService {
  public baseUrl: string;
  private customerUrl: string;
  private jplUrl: string;
  private jobTaskUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = combineURLs(baseUrl, "/Jobs");
    this.customerUrl = combineURLs(baseUrl, "/Customers");
    this.jplUrl = combineURLs(baseUrl, "/jobplanninglines");
    this.jobTaskUrl = combineURLs(baseUrl, "/jobtasks");

    this.axiosInstance = instance;
  }

  /**
   * Gets all Customers
   * @param query OData Query
   * @example
   * const client = new TnTClient(args).getCustomerService()
   * await client.getAll()
   */
  public async getCustomers(
    query?: Partial<QueryOptions<Customer>>
  ): Promise<Customer[]> {
    const url = `${this.customerUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Customer[]>(url);

    return response.data;
  }

  /**
   * Gets all Jobs
   * @param query OData query
   * @example
   * const client = new TnTClient(args).getTnTService()
   * await client.getJobs()
   */
  public async getJobs(
    query?: Partial<QueryOptions<PopulatedJob>>
  ): Promise<Partial<PopulatedJob>[]> {
    const url = `${this.baseUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Partial<PopulatedJob>[]>(url);

    return response.data;
  }

  /**
   * Gets all Job Planning Lines
   * @param query OData query
   * @example
   * const client = new TnTClient(args).getTnTService()
   * await client.getJobPlanningLines()
   */
  public async getJobPlanningLines(
    query?: Partial<QueryOptions<PopulatedJobPlanningLine>>
  ): Promise<Partial<PopulatedJobPlanningLine>[]> {
    const url = `${this.jplUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<
      Partial<PopulatedJobPlanningLine>[]
    >(url);

    return response.data;
  }

  /**
   * Gets all Job Tasks
   * @param query OData query
   * @example
   * const client = new TnTClient(args).getTnTService()
   * await client.getJobTasks()
   */
  public async getJobTasks(
    query?: Partial<QueryOptions<PopulatedJobTask>>
  ): Promise<Partial<PopulatedJobTask>[]> {
    const url = `${this.jobTaskUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Partial<PopulatedJobTask>[]>(
      url
    );

    return response.data;
  }

  /**
   * Gets all Jobs that are attached to a specific resource
   * @param resourceId The ID of the resource
   * @param query OData query
   * @example
   * const client = new TnTClient(args).getTnTService()
   * await client.getJobPlanningLines()
   */
  public async getJobsFromResource(
    resourceId: string,
    query?: Partial<QueryOptions<PopulatedJob>>
  ): Promise<Partial<PopulatedJob>[]> {
    const url = `${this.baseUrl}(resourceId=${resourceId})${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Partial<PopulatedJob>[]>(url);

    return response.data;
  }

  /**
   * Gets all Activites for only Job or Job && JobTask
   * @param jobId The ID of selected Job
   * @param jobTaskId The ID of selected JobTask if selected, else null
   * @param filter String for final filtering
   * @param orderBy String in which order entries should be result
   * @example
   * const client = new TnTClient(args).getTnTService()
   * await client.getActivities()
   */
  public async getActivities(
    jobId: string,
    jobTaskId: string | null,
    filter: string,
    orderBy: string
  ): Promise<Activity[]> {
    const setFilter: string = filter.length > 0 ? filter : "?";
    const url = `${this.baseUrl}(${jobId})/GetActivities${
      "(jobTaskId=" + jobTaskId + ")"
    }${setFilter}$expand=jobTask,jobPlanningLine,tntModelLine&$orderBy=${orderBy}`;

    const response = await this.axiosInstance.get<Activity[]>(url);

    return response.data;
  }
}
