import { DyceTheme } from "@dyce/theme";
// MUI
import { makeStyles, createStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    textFieldCommonStyle: {
      "& .MuiFilledInput-input": {
        padding: "22px 10px 6px",
      },
      "& .MuiFormLabel-root": {
        color: theme.palette.primary.main,
        fontSize: 12,
        display: "flex",
      },
      "& .MuiInputBase-input": {
        fontSize: 14,
        height: "1.5rem",
      },
      "& .MuiFormHelperText-root.Mui-error": {
        whiteSpace: "nowrap",
      },
    },
  })
);
