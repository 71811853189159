import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
// Helper
import { useHotkeys } from "react-hotkeys-hook";
// Dyce-Lib
import { Stopwatch } from "@dyce/tnt-api";
import { DyceTheme } from "@dyce/theme";
import { Tooltip } from "../tooltip/tooltip";
import { useStaticContent } from "../static-provider/static-provider";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Button,
  Card,
  CardContent,
  ClickAwayListener,
  Fade,
  Popper,
  TextField,
  useTheme,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import SaveIcon from "@mui/icons-material/Save";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    paper: {
      display: "flex",
      minWidth: 300,
      margin: "0 20px",
    },
    desc: {
      marginTop: theme.spacing(1),
    },
    controls: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      paddingTop: theme.spacing(2),
    },
    input: {
      width: "100%",
    },
    icon: {
      height: 32,
      width: 32,
    },
    details: {
      display: "flex",
      flexDirection: "column",
      flex: "1 0 auto",
    },
    startButton: {
      width: "6.5rem",
    },
    saveButton: {
      width: "8rem",
    },
    trashButton: {
      width: "8rem",
    },
  })
);

interface IWatchModalProps {
  anchor: null | HTMLElement;
  stopwatch: Stopwatch | null;
  open: boolean;
  startRecording: (desc: string, start: string) => void;
  stopRecording: (forceEnd?: boolean, desc?: string) => void;
  trashRecording: () => void;
  close: () => void;
}

export const WatchModal: FunctionComponent<IWatchModalProps> = ({
  anchor,
  stopwatch,
  open,
  startRecording,
  stopRecording,
  trashRecording,
  close,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const { docuLinks } = useStaticContent();

  const [description, setDescription] = useState(
    stopwatch ? stopwatch.description : ""
  );
  const [time, setTime] = useState<string | null>(null);
  const [disableSave, setDisableSave] = useState<boolean>(false);

  const isLoading = !stopwatch;

  useHotkeys(
    "esc",
    close,
    {
      enableOnTags: ["INPUT", "TEXTAREA"],
      enabled: open,
    },
    [close]
  );

  const handleTrash = () => {
    setDescription("");
    trashRecording();

    setTime(null);
    close();
  };

  const handleStart = () => {
    const _time = new Date().toISOString();

    setTime(_time);
    startRecording(description, _time);
  };

  const handleStop = () => {
    setDisableSave(true);
    stopRecording(false, description);
    setDescription("");

    setTime(null);
    close();
  };

  useEffect(() => {
    if (stopwatch && stopwatch.running) {
      setTime(stopwatch.start);
      setDescription(stopwatch.description);
    }
  }, [stopwatch]);

  useEffect(() => {
    if (open) {
      setDisableSave(false);
    }
  }, [open]);

  return (
    <ClickAwayListener
      onClickAway={close}
      mouseEvent="onMouseUp"
      touchEvent={false}
    >
      <Popper
        id="stopwatch-popper"
        data-testid="stopwatch-popper"
        open={open}
        anchorEl={anchor}
        style={{
          zIndex: theme.zIndex.appBar + 1,
        }}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Card className={classes.paper} data-testid="stopwatch-modal">
              <div className={classes.details}>
                <CardContent>
                  <div className={classes.desc}>
                    <TextField
                      data-testid="stopwatch-text"
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 100 }}
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      onBlur={() =>
                        time &&
                        stopwatch &&
                        stopwatch.description !== null &&
                        description !== stopwatch.description &&
                        startRecording(description, time)
                      }
                      className={classes.input}
                      label={t("timerec.dailyRecording.add.description")}
                      InputLabelProps={{ shrink: true }}
                      autoFocus
                    />
                  </div>
                  <div className={classes.controls}>
                    <Tooltip
                      label={
                        stopwatch?.running
                          ? t("nav.stopwatch.tooltip.save")
                          : t("nav.stopwatch.tooltip.start")
                      }
                      urlPath={
                        stopwatch?.running
                          ? docuLinks.navbar.stopwatch.startButton
                          : docuLinks.navbar.stopwatch.saveButton
                      }
                      disablePortal
                    >
                      <LoadingButton
                        variant="contained"
                        loadingPosition="start"
                        loading={isLoading}
                        disabled={disableSave || isLoading}
                        className={
                          stopwatch?.running
                            ? classes.saveButton
                            : classes.startButton
                        }
                        data-testid="stopwatch-start"
                        startIcon={
                          stopwatch?.running ? <SaveIcon /> : <PlayArrowIcon />
                        }
                        onClick={stopwatch?.running ? handleStop : handleStart}
                      >
                        {stopwatch?.running
                          ? t("nav.stopwatch.save")
                          : t("nav.stopwatch.start")}
                      </LoadingButton>
                    </Tooltip>
                    <Tooltip
                      label={t("nav.stopwatch.tooltip.trash")}
                      urlPath={docuLinks.navbar.stopwatch.trashButton}
                      disablePortal
                    >
                      <span>
                        <Button
                          color={"error"}
                          variant="contained"
                          disabled={!stopwatch?.running}
                          data-testid="stopwatch-trash"
                          startIcon={<DeleteForeverIcon />}
                          onClick={handleTrash}
                          className={
                            stopwatch?.running ? classes.trashButton : ""
                          }
                        >
                          {t("nav.stopwatch.trash")}
                        </Button>
                      </span>
                    </Tooltip>
                  </div>
                </CardContent>
              </div>
            </Card>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>
  );
};
