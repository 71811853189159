export const getLocales = (language: string): string[] => {
  if (!language) {
    return [];
  }

  return Object.keys(locales[language as "de" | "en"]);
};

export const getLocaleName = (val: string, language: string): string => {
  if (!language) {
    return "";
  }

  if (val === "") {
    return "";
  }

  return locales[language as "de" | "en"][val];
};

export const locales: Record<"de" | "en", Record<string, string>> = {
  de: {
    af: "Afrikaans",
    af_NA: "Afrikaans (Namibia)",
    af_ZA: "Afrikaans (S\u00fcdafrika)",
    ak: "Akan",
    ak_GH: "Akan (Ghana)",
    sq: "Albanisch",
    sq_AL: "Albanisch (Albanien)",
    sq_XK: "Albanisch (Kosovo)",
    sq_MK: "Albanisch (Mazedonien)",
    am: "Amharisch",
    am_ET: "Amharisch (\u00c4thiopien)",
    ar: "Arabisch",
    ar_EG: "Arabisch (\u00c4gypten)",
    ar_DZ: "Arabisch (Algerien)",
    ar_BH: "Arabisch (Bahrain)",
    ar_DJ: "Arabisch (Dschibuti)",
    ar_ER: "Arabisch (Eritrea)",
    ar_IQ: "Arabisch (Irak)",
    ar_IL: "Arabisch (Israel)",
    ar_YE: "Arabisch (Jemen)",
    ar_JO: "Arabisch (Jordanien)",
    ar_QA: "Arabisch (Katar)",
    ar_KM: "Arabisch (Komoren)",
    ar_KW: "Arabisch (Kuwait)",
    ar_LB: "Arabisch (Libanon)",
    ar_LY: "Arabisch (Libyen)",
    ar_MA: "Arabisch (Marokko)",
    ar_MR: "Arabisch (Mauretanien)",
    ar_OM: "Arabisch (Oman)",
    ar_PS: "Arabisch (Pal\u00e4stinensische Autonomiegebiete)",
    ar_SA: "Arabisch (Saudi-Arabien)",
    ar_SO: "Arabisch (Somalia)",
    ar_SD: "Arabisch (Sudan)",
    ar_SS: "Arabisch (S\u00fcdsudan)",
    ar_SY: "Arabisch (Syrien)",
    ar_TD: "Arabisch (Tschad)",
    ar_TN: "Arabisch (Tunesien)",
    ar_AE: "Arabisch (Vereinigte Arabische Emirate)",
    ar_EH: "Arabisch (Westsahara)",
    hy: "Armenisch",
    hy_AM: "Armenisch (Armenien)",
    az: "Aserbaidschanisch",
    az_AZ: "Aserbaidschanisch (Aserbaidschan)",
    az_Cyrl_AZ: "Aserbaidschanisch (Kyrillisch, Aserbaidschan)",
    az_Cyrl: "Aserbaidschanisch (Kyrillisch)",
    az_Latn_AZ: "Aserbaidschanisch (Lateinisch, Aserbaidschan)",
    az_Latn: "Aserbaidschanisch (Lateinisch)",
    as: "Assamesisch",
    as_IN: "Assamesisch (Indien)",
    bm: "Bambara-Sprache",
    bm_Latn_ML: "Bambara-Sprache (Lateinisch, Mali)",
    bm_Latn: "Bambara-Sprache (Lateinisch)",
    eu: "Baskisch",
    eu_ES: "Baskisch (Spanien)",
    bn: "Bengalisch",
    bn_BD: "Bengalisch (Bangladesch)",
    bn_IN: "Bengalisch (Indien)",
    dz: "Bhutanisch",
    dz_BT: "Bhutanisch (Bhutan)",
    my: "Birmanisch",
    my_MM: "Birmanisch (Myanmar)",
    bs: "Bosnisch",
    bs_BA: "Bosnisch (Bosnien und Herzegowina)",
    bs_Cyrl_BA: "Bosnisch (Kyrillisch, Bosnien und Herzegowina)",
    bs_Cyrl: "Bosnisch (Kyrillisch)",
    bs_Latn_BA: "Bosnisch (Lateinisch, Bosnien und Herzegowina)",
    bs_Latn: "Bosnisch (Lateinisch)",
    br: "Bretonisch",
    br_FR: "Bretonisch (Frankreich)",
    bg: "Bulgarisch",
    bg_BG: "Bulgarisch (Bulgarien)",
    zh: "Chinesisch",
    zh_CN: "Chinesisch (China)",
    zh_SG: "Chinesisch (Singapur)",
    zh_MO: "Chinesisch (Sonderverwaltungsregion Macau)",
    zh_HK: "Chinesisch (Sonderverwaltungszone Hongkong)",
    zh_TW: "Chinesisch (Taiwan)",
    zh_Hant_MO: "Chinesisch (Traditionell, Sonderverwaltungsregion Macau)",
    zh_Hant_HK: "Chinesisch (Traditionell, Sonderverwaltungszone Hongkong)",
    zh_Hant_TW: "Chinesisch (Traditionell, Taiwan)",
    zh_Hant: "Chinesisch (Traditionell)",
    zh_Hans_CN: "Chinesisch (Vereinfacht, China)",
    zh_Hans_SG: "Chinesisch (Vereinfacht, Singapur)",
    zh_Hans_MO: "Chinesisch (Vereinfacht, Sonderverwaltungsregion Macau)",
    zh_Hans_HK: "Chinesisch (Vereinfacht, Sonderverwaltungszone Hongkong)",
    zh_Hans: "Chinesisch (Vereinfacht)",
    da: "D\u00e4nisch",
    da_DK: "D\u00e4nisch (D\u00e4nemark)",
    da_GL: "D\u00e4nisch (Gr\u00f6nland)",
    de: "Deutsch",
    de_BE: "Deutsch (Belgien)",
    de_DE: "Deutsch (Deutschland)",
    de_LI: "Deutsch (Liechtenstein)",
    de_LU: "Deutsch (Luxemburg)",
    de_AT: "Deutsch (\u00d6sterreich)",
    de_CH: "Deutsch (Schweiz)",
    en: "Englisch",
    en_UM: "Englisch (Amerikanisch-Ozeanien)",
    en_AS: "Englisch (Amerikanisch-Samoa)",
    en_VI: "Englisch (Amerikanische Jungferninseln)",
    en_AI: "Englisch (Anguilla)",
    en_AG: "Englisch (Antigua und Barbuda)",
    en_AU: "Englisch (Australien)",
    en_BS: "Englisch (Bahamas)",
    en_BB: "Englisch (Barbados)",
    en_BE: "Englisch (Belgien)",
    en_BZ: "Englisch (Belize)",
    en_BM: "Englisch (Bermuda)",
    en_BW: "Englisch (Botsuana)",
    en_VG: "Englisch (Britische Jungferninseln)",
    en_IO: "Englisch (Britisches Territorium im Indischen Ozean)",
    en_CK: "Englisch (Cookinseln)",
    en_DG: "Englisch (Diego Garcia)",
    en_DM: "Englisch (Dominica)",
    en_ER: "Englisch (Eritrea)",
    en_FK: "Englisch (Falklandinseln)",
    en_FJ: "Englisch (Fidschi)",
    en_GM: "Englisch (Gambia)",
    en_GH: "Englisch (Ghana)",
    en_GI: "Englisch (Gibraltar)",
    en_GD: "Englisch (Grenada)",
    en_GU: "Englisch (Guam)",
    en_GG: "Englisch (Guernsey)",
    en_GY: "Englisch (Guyana)",
    en_IN: "Englisch (Indien)",
    en_IE: "Englisch (Irland)",
    en_IM: "Englisch (Isle of Man)",
    en_JM: "Englisch (Jamaika)",
    en_JE: "Englisch (Jersey)",
    en_KY: "Englisch (Kaimaninseln)",
    en_CM: "Englisch (Kamerun)",
    en_CA: "Englisch (Kanada)",
    en_KE: "Englisch (Kenia)",
    en_KI: "Englisch (Kiribati)",
    en_CC: "Englisch (Kokosinseln)",
    en_LS: "Englisch (Lesotho)",
    en_LR: "Englisch (Liberia)",
    en_MG: "Englisch (Madagaskar)",
    en_MW: "Englisch (Malawi)",
    en_MY: "Englisch (Malaysia)",
    en_MT: "Englisch (Malta)",
    en_MH: "Englisch (Marshallinseln)",
    en_MU: "Englisch (Mauritius)",
    en_FM: "Englisch (Mikronesien)",
    en_MS: "Englisch (Montserrat)",
    en_NA: "Englisch (Namibia)",
    en_NR: "Englisch (Nauru)",
    en_NZ: "Englisch (Neuseeland)",
    en_NG: "Englisch (Nigeria)",
    en_NU: "Englisch (Niue)",
    en_MP: "Englisch (N\u00f6rdliche Marianen)",
    en_NF: "Englisch (Norfolkinsel)",
    en_PK: "Englisch (Pakistan)",
    en_PW: "Englisch (Palau)",
    en_PG: "Englisch (Papua-Neuguinea)",
    en_PH: "Englisch (Philippinen)",
    en_PN: "Englisch (Pitcairninseln)",
    en_PR: "Englisch (Puerto Rico)",
    en_RW: "Englisch (Ruanda)",
    en_SB: "Englisch (Salomonen)",
    en_ZM: "Englisch (Sambia)",
    en_WS: "Englisch (Samoa)",
    en_SC: "Englisch (Seychellen)",
    en_SL: "Englisch (Sierra Leone)",
    en_ZW: "Englisch (Simbabwe)",
    en_SG: "Englisch (Singapur)",
    en_SX: "Englisch (Sint Maarten)",
    en_MO: "Englisch (Sonderverwaltungsregion Macau)",
    en_HK: "Englisch (Sonderverwaltungszone Hongkong)",
    en_SH: "Englisch (St. Helena)",
    en_KN: "Englisch (St. Kitts und Nevis)",
    en_LC: "Englisch (St. Lucia)",
    en_VC: "Englisch (St. Vincent und die Grenadinen)",
    en_ZA: "Englisch (S\u00fcdafrika)",
    en_SD: "Englisch (Sudan)",
    en_SS: "Englisch (S\u00fcdsudan)",
    en_SZ: "Englisch (Swasiland)",
    en_TZ: "Englisch (Tansania)",
    en_TK: "Englisch (Tokelau)",
    en_TO: "Englisch (Tonga)",
    en_TT: "Englisch (Trinidad und Tobago)",
    en_TC: "Englisch (Turks- und Caicosinseln)",
    en_TV: "Englisch (Tuvalu)",
    en_UG: "Englisch (Uganda)",
    en_VU: "Englisch (Vanuatu)",
    en_US: "Englisch (Vereinigte Staaten)",
    en_GB: "Englisch (Vereinigtes K\u00f6nigreich)",
    en_CX: "Englisch (Weihnachtsinsel)",
    eo: "Esperanto",
    et: "Estnisch",
    et_EE: "Estnisch (Estland)",
    ee: "Ewe",
    ee_GH: "Ewe (Ghana)",
    ee_TG: "Ewe (Togo)",
    fo: "F\u00e4r\u00f6isch",
    fo_FO: "F\u00e4r\u00f6isch (F\u00e4r\u00f6er)",
    fi: "Finnisch",
    fi_FI: "Finnisch (Finnland)",
    fr: "Franz\u00f6sisch",
    fr_DZ: "Franz\u00f6sisch (Algerien)",
    fr_GQ: "Franz\u00f6sisch (\u00c4quatorialguinea)",
    fr_BE: "Franz\u00f6sisch (Belgien)",
    fr_BJ: "Franz\u00f6sisch (Benin)",
    fr_BF: "Franz\u00f6sisch (Burkina Faso)",
    fr_BI: "Franz\u00f6sisch (Burundi)",
    fr_CI: "Franz\u00f6sisch (C\u00f4te d\u2019Ivoire)",
    fr_DJ: "Franz\u00f6sisch (Dschibuti)",
    fr_FR: "Franz\u00f6sisch (Frankreich)",
    fr_GF: "Franz\u00f6sisch (Franz\u00f6sisch-Guayana)",
    fr_PF: "Franz\u00f6sisch (Franz\u00f6sisch-Polynesien)",
    fr_GA: "Franz\u00f6sisch (Gabun)",
    fr_GP: "Franz\u00f6sisch (Guadeloupe)",
    fr_GN: "Franz\u00f6sisch (Guinea)",
    fr_HT: "Franz\u00f6sisch (Haiti)",
    fr_CM: "Franz\u00f6sisch (Kamerun)",
    fr_CA: "Franz\u00f6sisch (Kanada)",
    fr_KM: "Franz\u00f6sisch (Komoren)",
    fr_CG: "Franz\u00f6sisch (Kongo-Brazzaville)",
    fr_CD: "Franz\u00f6sisch (Kongo-Kinshasa)",
    fr_LU: "Franz\u00f6sisch (Luxemburg)",
    fr_MG: "Franz\u00f6sisch (Madagaskar)",
    fr_ML: "Franz\u00f6sisch (Mali)",
    fr_MA: "Franz\u00f6sisch (Marokko)",
    fr_MQ: "Franz\u00f6sisch (Martinique)",
    fr_MR: "Franz\u00f6sisch (Mauretanien)",
    fr_MU: "Franz\u00f6sisch (Mauritius)",
    fr_YT: "Franz\u00f6sisch (Mayotte)",
    fr_MC: "Franz\u00f6sisch (Monaco)",
    fr_NC: "Franz\u00f6sisch (Neukaledonien)",
    fr_NE: "Franz\u00f6sisch (Niger)",
    fr_RE: "Franz\u00f6sisch (R\u00e9union)",
    fr_RW: "Franz\u00f6sisch (Ruanda)",
    fr_CH: "Franz\u00f6sisch (Schweiz)",
    fr_SN: "Franz\u00f6sisch (Senegal)",
    fr_SC: "Franz\u00f6sisch (Seychellen)",
    fr_BL: "Franz\u00f6sisch (St. Barth\u00e9lemy)",
    fr_MF: "Franz\u00f6sisch (St. Martin)",
    fr_PM: "Franz\u00f6sisch (St. Pierre und Miquelon)",
    fr_SY: "Franz\u00f6sisch (Syrien)",
    fr_TG: "Franz\u00f6sisch (Togo)",
    fr_TD: "Franz\u00f6sisch (Tschad)",
    fr_TN: "Franz\u00f6sisch (Tunesien)",
    fr_VU: "Franz\u00f6sisch (Vanuatu)",
    fr_WF: "Franz\u00f6sisch (Wallis und Futuna)",
    fr_CF: "Franz\u00f6sisch (Zentralafrikanische Republik)",
    ff: "Ful",
    ff_GN: "Ful (Guinea)",
    ff_CM: "Ful (Kamerun)",
    ff_MR: "Ful (Mauretanien)",
    ff_SN: "Ful (Senegal)",
    gl: "Galizisch",
    gl_ES: "Galizisch (Spanien)",
    lg: "Ganda",
    lg_UG: "Ganda (Uganda)",
    ka: "Georgisch",
    ka_GE: "Georgisch (Georgien)",
    el: "Griechisch",
    el_GR: "Griechisch (Griechenland)",
    el_CY: "Griechisch (Zypern)",
    kl: "Gr\u00f6nl\u00e4ndisch",
    kl_GL: "Gr\u00f6nl\u00e4ndisch (Gr\u00f6nland)",
    gu: "Gujarati",
    gu_IN: "Gujarati (Indien)",
    ha: "Hausa",
    ha_GH: "Hausa (Ghana)",
    ha_Latn_GH: "Hausa (Lateinisch, Ghana)",
    ha_Latn_NE: "Hausa (Lateinisch, Niger)",
    ha_Latn_NG: "Hausa (Lateinisch, Nigeria)",
    ha_Latn: "Hausa (Lateinisch)",
    ha_NE: "Hausa (Niger)",
    ha_NG: "Hausa (Nigeria)",
    he: "Hebr\u00e4isch",
    he_IL: "Hebr\u00e4isch (Israel)",
    hi: "Hindi",
    hi_IN: "Hindi (Indien)",
    ig: "Igbo",
    ig_NG: "Igbo (Nigeria)",
    id: "Indonesisch",
    id_ID: "Indonesisch (Indonesien)",
    ga: "Irisch",
    ga_IE: "Irisch (Irland)",
    is: "Isl\u00e4ndisch",
    is_IS: "Isl\u00e4ndisch (Island)",
    it: "Italienisch",
    it_IT: "Italienisch (Italien)",
    it_SM: "Italienisch (San Marino)",
    it_CH: "Italienisch (Schweiz)",
    ja: "Japanisch",
    ja_JP: "Japanisch (Japan)",
    yi: "Jiddisch",
    km: "Kambodschanisch",
    km_KH: "Kambodschanisch (Kambodscha)",
    kn: "Kannada",
    kn_IN: "Kannada (Indien)",
    kk: "Kasachisch",
    kk_KZ: "Kasachisch (Kasachstan)",
    kk_Cyrl_KZ: "Kasachisch (Kyrillisch, Kasachstan)",
    kk_Cyrl: "Kasachisch (Kyrillisch)",
    ks: "Kaschmirisch",
    ks_Arab_IN: "Kaschmirisch (Arabisch, Indien)",
    ks_Arab: "Kaschmirisch (Arabisch)",
    ks_IN: "Kaschmirisch (Indien)",
    ca: "Katalanisch",
    ca_AD: "Katalanisch (Andorra)",
    ca_FR: "Katalanisch (Frankreich)",
    ca_IT: "Katalanisch (Italien)",
    ca_ES: "Katalanisch (Spanien)",
    ki: "Kikuyu",
    ki_KE: "Kikuyu (Kenia)",
    ky: "Kirgisisch",
    ky_KG: "Kirgisisch (Kirgisistan)",
    ky_Cyrl_KG: "Kirgisisch (Kyrillisch, Kirgisistan)",
    ky_Cyrl: "Kirgisisch (Kyrillisch)",
    ko: "Koreanisch",
    ko_KP: "Koreanisch (Demokratische Volksrepublik Korea)",
    ko_KR: "Koreanisch (Republik Korea)",
    kw: "Kornisch",
    kw_GB: "Kornisch (Vereinigtes K\u00f6nigreich)",
    hr: "Kroatisch",
    hr_BA: "Kroatisch (Bosnien und Herzegowina)",
    hr_HR: "Kroatisch (Kroatien)",
    lo: "Laotisch",
    lo_LA: "Laotisch (Laos)",
    lv: "Lettisch",
    lv_LV: "Lettisch (Lettland)",
    ln: "Lingala",
    ln_AO: "Lingala (Angola)",
    ln_CG: "Lingala (Kongo-Brazzaville)",
    ln_CD: "Lingala (Kongo-Kinshasa)",
    ln_CF: "Lingala (Zentralafrikanische Republik)",
    lt: "Litauisch",
    lt_LT: "Litauisch (Litauen)",
    lu: "Luba-Katanga",
    lu_CD: "Luba-Katanga (Kongo-Kinshasa)",
    lb: "Luxemburgisch",
    lb_LU: "Luxemburgisch (Luxemburg)",
    mg: "Madagassisch",
    mg_MG: "Madagassisch (Madagaskar)",
    ms: "Malaiisch",
    ms_BN: "Malaiisch (Brunei Darussalam)",
    ms_Latn_BN: "Malaiisch (Lateinisch, Brunei Darussalam)",
    ms_Latn_MY: "Malaiisch (Lateinisch, Malaysia)",
    ms_Latn_SG: "Malaiisch (Lateinisch, Singapur)",
    ms_Latn: "Malaiisch (Lateinisch)",
    ms_MY: "Malaiisch (Malaysia)",
    ms_SG: "Malaiisch (Singapur)",
    ml: "Malayalam",
    ml_IN: "Malayalam (Indien)",
    mt: "Maltesisch",
    mt_MT: "Maltesisch (Malta)",
    gv: "Manx",
    gv_IM: "Manx (Isle of Man)",
    mr: "Marathi",
    mr_IN: "Marathi (Indien)",
    mk: "Mazedonisch",
    mk_MK: "Mazedonisch (Mazedonien)",
    mn: "Mongolisch",
    mn_Cyrl_MN: "Mongolisch (Kyrillisch, Mongolei)",
    mn_Cyrl: "Mongolisch (Kyrillisch)",
    mn_MN: "Mongolisch (Mongolei)",
    ne: "Nepalesisch",
    ne_IN: "Nepalesisch (Indien)",
    ne_NP: "Nepalesisch (Nepal)",
    nl: "Niederl\u00e4ndisch",
    nl_AW: "Niederl\u00e4ndisch (Aruba)",
    nl_BE: "Niederl\u00e4ndisch (Belgien)",
    nl_CW: "Niederl\u00e4ndisch (Cura\u00e7ao)",
    nl_BQ: "Niederl\u00e4ndisch (Karibische Niederlande)",
    nl_NL: "Niederl\u00e4ndisch (Niederlande)",
    nl_SX: "Niederl\u00e4ndisch (Sint Maarten)",
    nl_SR: "Niederl\u00e4ndisch (Suriname)",
    nd: "Nord-Ndebele-Sprache",
    nd_ZW: "Nord-Ndebele-Sprache (Simbabwe)",
    se: "Nord-Samisch",
    se_FI: "Nord-Samisch (Finnland)",
    se_NO: "Nord-Samisch (Norwegen)",
    se_SE: "Nord-Samisch (Schweden)",
    no: "Norwegisch",
    no_NO: "Norwegisch (Norwegen)",
    nb: "Norwegisch Bokm\u00e5l",
    nb_NO: "Norwegisch Bokm\u00e5l (Norwegen)",
    nb_SJ: "Norwegisch Bokm\u00e5l (Svalbard und Jan Mayen)",
    nn: "Norwegisch Nynorsk",
    nn_NO: "Norwegisch Nynorsk (Norwegen)",
    or: "Oriya",
    or_IN: "Oriya (Indien)",
    om: "Oromo",
    om_ET: "Oromo (\u00c4thiopien)",
    om_KE: "Oromo (Kenia)",
    os: "Ossetisch",
    os_GE: "Ossetisch (Georgien)",
    os_RU: "Ossetisch (Russische F\u00f6deration)",
    pa: "Panjabi",
    pa_Arab_PK: "Panjabi (Arabisch, Pakistan)",
    pa_Arab: "Panjabi (Arabisch)",
    pa_Guru_IN: "Panjabi (Gurmukhi, Indien)",
    pa_Guru: "Panjabi (Gurmukhi)",
    pa_IN: "Panjabi (Indien)",
    pa_PK: "Panjabi (Pakistan)",
    ps: "Paschtu",
    ps_AF: "Paschtu (Afghanistan)",
    fa: "Persisch",
    fa_AF: "Persisch (Afghanistan)",
    fa_IR: "Persisch (Iran)",
    pl: "Polnisch",
    pl_PL: "Polnisch (Polen)",
    pt: "Portugiesisch",
    pt_AO: "Portugiesisch (Angola)",
    pt_BR: "Portugiesisch (Brasilien)",
    pt_GW: "Portugiesisch (Guinea-Bissau)",
    pt_CV: "Portugiesisch (Kap Verde)",
    pt_MZ: "Portugiesisch (Mosambik)",
    pt_PT: "Portugiesisch (Portugal)",
    pt_ST: "Portugiesisch (S\u00e3o Tom\u00e9 und Pr\u00edncipe)",
    pt_MO: "Portugiesisch (Sonderverwaltungsregion Macau)",
    pt_TL: "Portugiesisch (Timor-Leste)",
    qu: "Quechua",
    qu_BO: "Quechua (Bolivien)",
    qu_EC: "Quechua (Ecuador)",
    qu_PE: "Quechua (Peru)",
    rm: "R\u00e4toromanisch",
    rm_CH: "R\u00e4toromanisch (Schweiz)",
    rw: "Ruandisch",
    rw_RW: "Ruandisch (Ruanda)",
    ro: "Rum\u00e4nisch",
    ro_MD: "Rum\u00e4nisch (Republik Moldau)",
    ro_RO: "Rum\u00e4nisch (Rum\u00e4nien)",
    rn: "Rundi-Sprache",
    rn_BI: "Rundi-Sprache (Burundi)",
    ru: "Russisch",
    ru_BY: "Russisch (Belarus)",
    ru_KZ: "Russisch (Kasachstan)",
    ru_KG: "Russisch (Kirgisistan)",
    ru_MD: "Russisch (Republik Moldau)",
    ru_RU: "Russisch (Russische F\u00f6deration)",
    ru_UA: "Russisch (Ukraine)",
    sg: "Sango",
    sg_CF: "Sango (Zentralafrikanische Republik)",
    gd: "Schottisches G\u00e4lisch",
    gd_GB: "Schottisches G\u00e4lisch (Vereinigtes K\u00f6nigreich)",
    sv: "Schwedisch",
    sv_AX: "Schwedisch (\u00c5land-Inseln)",
    sv_FI: "Schwedisch (Finnland)",
    sv_SE: "Schwedisch (Schweden)",
    sr: "Serbisch",
    sr_BA: "Serbisch (Bosnien und Herzegowina)",
    sr_XK: "Serbisch (Kosovo)",
    sr_Cyrl_BA: "Serbisch (Kyrillisch, Bosnien und Herzegowina)",
    sr_Cyrl_XK: "Serbisch (Kyrillisch, Kosovo)",
    sr_Cyrl_ME: "Serbisch (Kyrillisch, Montenegro)",
    sr_Cyrl_RS: "Serbisch (Kyrillisch, Serbien)",
    sr_Cyrl: "Serbisch (Kyrillisch)",
    sr_Latn_BA: "Serbisch (Lateinisch, Bosnien und Herzegowina)",
    sr_Latn_XK: "Serbisch (Lateinisch, Kosovo)",
    sr_Latn_ME: "Serbisch (Lateinisch, Montenegro)",
    sr_Latn_RS: "Serbisch (Lateinisch, Serbien)",
    sr_Latn: "Serbisch (Lateinisch)",
    sr_ME: "Serbisch (Montenegro)",
    sr_RS: "Serbisch (Serbien)",
    sh: "Serbo-Kroatisch",
    sh_BA: "Serbo-Kroatisch (Bosnien und Herzegowina)",
    sn: "Shona",
    sn_ZW: "Shona (Simbabwe)",
    si: "Singhalesisch",
    si_LK: "Singhalesisch (Sri Lanka)",
    sk: "Slowakisch",
    sk_SK: "Slowakisch (Slowakei)",
    sl: "Slowenisch",
    sl_SI: "Slowenisch (Slowenien)",
    so: "Somali",
    so_ET: "Somali (\u00c4thiopien)",
    so_DJ: "Somali (Dschibuti)",
    so_KE: "Somali (Kenia)",
    so_SO: "Somali (Somalia)",
    es: "Spanisch",
    es_GQ: "Spanisch (\u00c4quatorialguinea)",
    es_AR: "Spanisch (Argentinien)",
    es_BO: "Spanisch (Bolivien)",
    es_EA: "Spanisch (Ceuta und Melilla)",
    es_CL: "Spanisch (Chile)",
    es_CR: "Spanisch (Costa Rica)",
    es_DO: "Spanisch (Dominikanische Republik)",
    es_EC: "Spanisch (Ecuador)",
    es_SV: "Spanisch (El Salvador)",
    es_GT: "Spanisch (Guatemala)",
    es_HN: "Spanisch (Honduras)",
    es_IC: "Spanisch (Kanarische Inseln)",
    es_CO: "Spanisch (Kolumbien)",
    es_CU: "Spanisch (Kuba)",
    es_MX: "Spanisch (Mexiko)",
    es_NI: "Spanisch (Nicaragua)",
    es_PA: "Spanisch (Panama)",
    es_PY: "Spanisch (Paraguay)",
    es_PE: "Spanisch (Peru)",
    es_PH: "Spanisch (Philippinen)",
    es_PR: "Spanisch (Puerto Rico)",
    es_ES: "Spanisch (Spanien)",
    es_UY: "Spanisch (Uruguay)",
    es_VE: "Spanisch (Venezuela)",
    es_US: "Spanisch (Vereinigte Staaten)",
    sw: "Suaheli",
    sw_KE: "Suaheli (Kenia)",
    sw_TZ: "Suaheli (Tansania)",
    sw_UG: "Suaheli (Uganda)",
    tl: "Tagalog",
    tl_PH: "Tagalog (Philippinen)",
    ta: "Tamilisch",
    ta_IN: "Tamilisch (Indien)",
    ta_MY: "Tamilisch (Malaysia)",
    ta_SG: "Tamilisch (Singapur)",
    ta_LK: "Tamilisch (Sri Lanka)",
    te: "Telugu",
    te_IN: "Telugu (Indien)",
    th: "Thail\u00e4ndisch",
    th_TH: "Thail\u00e4ndisch (Thailand)",
    bo: "Tibetisch",
    bo_CN: "Tibetisch (China)",
    bo_IN: "Tibetisch (Indien)",
    ti: "Tigrinja",
    ti_ET: "Tigrinja (\u00c4thiopien)",
    ti_ER: "Tigrinja (Eritrea)",
    to: "Tongaisch",
    to_TO: "Tongaisch (Tonga)",
    cs: "Tschechisch",
    cs_CZ: "Tschechisch (Tschechische Republik)",
    tr: "T\u00fcrkisch",
    tr_TR: "T\u00fcrkisch (T\u00fcrkei)",
    tr_CY: "T\u00fcrkisch (Zypern)",
    ug: "Uigurisch",
    ug_Arab_CN: "Uigurisch (Arabisch, China)",
    ug_Arab: "Uigurisch (Arabisch)",
    ug_CN: "Uigurisch (China)",
    uk: "Ukrainisch",
    uk_UA: "Ukrainisch (Ukraine)",
    hu: "Ungarisch",
    hu_HU: "Ungarisch (Ungarn)",
    ur: "Urdu",
    ur_IN: "Urdu (Indien)",
    ur_PK: "Urdu (Pakistan)",
    uz: "Usbekisch",
    uz_AF: "Usbekisch (Afghanistan)",
    uz_Arab_AF: "Usbekisch (Arabisch, Afghanistan)",
    uz_Arab: "Usbekisch (Arabisch)",
    uz_Cyrl_UZ: "Usbekisch (Kyrillisch, Usbekistan)",
    uz_Cyrl: "Usbekisch (Kyrillisch)",
    uz_Latn_UZ: "Usbekisch (Lateinisch, Usbekistan)",
    uz_Latn: "Usbekisch (Lateinisch)",
    uz_UZ: "Usbekisch (Usbekistan)",
    vi: "Vietnamesisch",
    vi_VN: "Vietnamesisch (Vietnam)",
    cy: "Walisisch",
    cy_GB: "Walisisch (Vereinigtes K\u00f6nigreich)",
    be: "Wei\u00dfrussisch",
    be_BY: "Wei\u00dfrussisch (Belarus)",
    fy: "Westfriesisch",
    fy_NL: "Westfriesisch (Niederlande)",
    ii: "Yi",
    ii_CN: "Yi (China)",
    yo: "Yoruba",
    yo_BJ: "Yoruba (Benin)",
    yo_NG: "Yoruba (Nigeria)",
    zu: "Zulu",
    zu_ZA: "Zulu (S\u00fcdafrika)",
  },
  en: {
    af: "Afrikaans",
    af_NA: "Afrikaans (Namibia)",
    af_ZA: "Afrikaans (South Africa)",
    ak: "Akan",
    ak_GH: "Akan (Ghana)",
    sq: "Albanian",
    sq_AL: "Albanian (Albania)",
    sq_XK: "Albanian (Kosovo)",
    sq_MK: "Albanian (Macedonia)",
    am: "Amharic",
    am_ET: "Amharic (Ethiopia)",
    ar: "Arabic",
    ar_DZ: "Arabic (Algeria)",
    ar_BH: "Arabic (Bahrain)",
    ar_TD: "Arabic (Chad)",
    ar_KM: "Arabic (Comoros)",
    ar_DJ: "Arabic (Djibouti)",
    ar_EG: "Arabic (Egypt)",
    ar_ER: "Arabic (Eritrea)",
    ar_IQ: "Arabic (Iraq)",
    ar_IL: "Arabic (Israel)",
    ar_JO: "Arabic (Jordan)",
    ar_KW: "Arabic (Kuwait)",
    ar_LB: "Arabic (Lebanon)",
    ar_LY: "Arabic (Libya)",
    ar_MR: "Arabic (Mauritania)",
    ar_MA: "Arabic (Morocco)",
    ar_OM: "Arabic (Oman)",
    ar_PS: "Arabic (Palestinian Territories)",
    ar_QA: "Arabic (Qatar)",
    ar_SA: "Arabic (Saudi Arabia)",
    ar_SO: "Arabic (Somalia)",
    ar_SS: "Arabic (South Sudan)",
    ar_SD: "Arabic (Sudan)",
    ar_SY: "Arabic (Syria)",
    ar_TN: "Arabic (Tunisia)",
    ar_AE: "Arabic (United Arab Emirates)",
    ar_EH: "Arabic (Western Sahara)",
    ar_YE: "Arabic (Yemen)",
    hy: "Armenian",
    hy_AM: "Armenian (Armenia)",
    as: "Assamese",
    as_IN: "Assamese (India)",
    az: "Azerbaijani",
    az_AZ: "Azerbaijani (Azerbaijan)",
    az_Cyrl_AZ: "Azerbaijani (Cyrillic, Azerbaijan)",
    az_Cyrl: "Azerbaijani (Cyrillic)",
    az_Latn_AZ: "Azerbaijani (Latin, Azerbaijan)",
    az_Latn: "Azerbaijani (Latin)",
    bm: "Bambara",
    bm_Latn_ML: "Bambara (Latin, Mali)",
    bm_Latn: "Bambara (Latin)",
    eu: "Basque",
    eu_ES: "Basque (Spain)",
    be: "Belarusian",
    be_BY: "Belarusian (Belarus)",
    bn: "Bengali",
    bn_BD: "Bengali (Bangladesh)",
    bn_IN: "Bengali (India)",
    bs: "Bosnian",
    bs_BA: "Bosnian (Bosnia and Herzegovina)",
    bs_Cyrl_BA: "Bosnian (Cyrillic, Bosnia and Herzegovina)",
    bs_Cyrl: "Bosnian (Cyrillic)",
    bs_Latn_BA: "Bosnian (Latin, Bosnia and Herzegovina)",
    bs_Latn: "Bosnian (Latin)",
    br: "Breton",
    br_FR: "Breton (France)",
    bg: "Bulgarian",
    bg_BG: "Bulgarian (Bulgaria)",
    my: "Burmese",
    my_MM: "Burmese (Myanmar (Burma))",
    ca: "Catalan",
    ca_AD: "Catalan (Andorra)",
    ca_FR: "Catalan (France)",
    ca_IT: "Catalan (Italy)",
    ca_ES: "Catalan (Spain)",
    zh: "Chinese",
    zh_CN: "Chinese (China)",
    zh_HK: "Chinese (Hong Kong SAR China)",
    zh_MO: "Chinese (Macau SAR China)",
    zh_Hans_CN: "Chinese (Simplified, China)",
    zh_Hans_HK: "Chinese (Simplified, Hong Kong SAR China)",
    zh_Hans_MO: "Chinese (Simplified, Macau SAR China)",
    zh_Hans_SG: "Chinese (Simplified, Singapore)",
    zh_Hans: "Chinese (Simplified)",
    zh_SG: "Chinese (Singapore)",
    zh_TW: "Chinese (Taiwan)",
    zh_Hant_HK: "Chinese (Traditional, Hong Kong SAR China)",
    zh_Hant_MO: "Chinese (Traditional, Macau SAR China)",
    zh_Hant_TW: "Chinese (Traditional, Taiwan)",
    zh_Hant: "Chinese (Traditional)",
    kw: "Cornish",
    kw_GB: "Cornish (United Kingdom)",
    hr: "Croatian",
    hr_BA: "Croatian (Bosnia and Herzegovina)",
    hr_HR: "Croatian (Croatia)",
    cs: "Czech",
    cs_CZ: "Czech (Czech Republic)",
    da: "Danish",
    da_DK: "Danish (Denmark)",
    da_GL: "Danish (Greenland)",
    nl: "Dutch",
    nl_AW: "Dutch (Aruba)",
    nl_BE: "Dutch (Belgium)",
    nl_BQ: "Dutch (Caribbean Netherlands)",
    nl_CW: "Dutch (Cura\u00e7ao)",
    nl_NL: "Dutch (Netherlands)",
    nl_SX: "Dutch (Sint Maarten)",
    nl_SR: "Dutch (Suriname)",
    dz: "Dzongkha",
    dz_BT: "Dzongkha (Bhutan)",
    en: "English",
    en_AS: "English (American Samoa)",
    en_AI: "English (Anguilla)",
    en_AG: "English (Antigua and Barbuda)",
    en_AU: "English (Australia)",
    en_BS: "English (Bahamas)",
    en_BB: "English (Barbados)",
    en_BE: "English (Belgium)",
    en_BZ: "English (Belize)",
    en_BM: "English (Bermuda)",
    en_BW: "English (Botswana)",
    en_IO: "English (British Indian Ocean Territory)",
    en_VG: "English (British Virgin Islands)",
    en_CM: "English (Cameroon)",
    en_CA: "English (Canada)",
    en_KY: "English (Cayman Islands)",
    en_CX: "English (Christmas Island)",
    en_CC: "English (Cocos (Keeling) Islands)",
    en_CK: "English (Cook Islands)",
    en_DG: "English (Diego Garcia)",
    en_DM: "English (Dominica)",
    en_ER: "English (Eritrea)",
    en_FK: "English (Falkland Islands)",
    en_FJ: "English (Fiji)",
    en_GM: "English (Gambia)",
    en_GH: "English (Ghana)",
    en_GI: "English (Gibraltar)",
    en_GD: "English (Grenada)",
    en_GU: "English (Guam)",
    en_GG: "English (Guernsey)",
    en_GY: "English (Guyana)",
    en_HK: "English (Hong Kong SAR China)",
    en_IN: "English (India)",
    en_IE: "English (Ireland)",
    en_IM: "English (Isle of Man)",
    en_JM: "English (Jamaica)",
    en_JE: "English (Jersey)",
    en_KE: "English (Kenya)",
    en_KI: "English (Kiribati)",
    en_LS: "English (Lesotho)",
    en_LR: "English (Liberia)",
    en_MO: "English (Macau SAR China)",
    en_MG: "English (Madagascar)",
    en_MW: "English (Malawi)",
    en_MY: "English (Malaysia)",
    en_MT: "English (Malta)",
    en_MH: "English (Marshall Islands)",
    en_MU: "English (Mauritius)",
    en_FM: "English (Micronesia)",
    en_MS: "English (Montserrat)",
    en_NA: "English (Namibia)",
    en_NR: "English (Nauru)",
    en_NZ: "English (New Zealand)",
    en_NG: "English (Nigeria)",
    en_NU: "English (Niue)",
    en_NF: "English (Norfolk Island)",
    en_MP: "English (Northern Mariana Islands)",
    en_PK: "English (Pakistan)",
    en_PW: "English (Palau)",
    en_PG: "English (Papua New Guinea)",
    en_PH: "English (Philippines)",
    en_PN: "English (Pitcairn Islands)",
    en_PR: "English (Puerto Rico)",
    en_RW: "English (Rwanda)",
    en_SH: "English (Saint Helena)",
    en_KN: "English (Saint Kitts and Nevis)",
    en_LC: "English (Saint Lucia)",
    en_WS: "English (Samoa)",
    en_SC: "English (Seychelles)",
    en_SL: "English (Sierra Leone)",
    en_SG: "English (Singapore)",
    en_SX: "English (Sint Maarten)",
    en_SB: "English (Solomon Islands)",
    en_ZA: "English (South Africa)",
    en_SS: "English (South Sudan)",
    en_VC: "English (St. Vincent & Grenadines)",
    en_SD: "English (Sudan)",
    en_SZ: "English (Swaziland)",
    en_TZ: "English (Tanzania)",
    en_TK: "English (Tokelau)",
    en_TO: "English (Tonga)",
    en_TT: "English (Trinidad and Tobago)",
    en_TC: "English (Turks and Caicos Islands)",
    en_TV: "English (Tuvalu)",
    en_UM: "English (U.S. Outlying Islands)",
    en_VI: "English (U.S. Virgin Islands)",
    en_UG: "English (Uganda)",
    en_GB: "English (United Kingdom)",
    en_US: "English (United States)",
    en_VU: "English (Vanuatu)",
    en_ZM: "English (Zambia)",
    en_ZW: "English (Zimbabwe)",
    eo: "Esperanto",
    et: "Estonian",
    et_EE: "Estonian (Estonia)",
    ee: "Ewe",
    ee_GH: "Ewe (Ghana)",
    ee_TG: "Ewe (Togo)",
    fo: "Faroese",
    fo_FO: "Faroese (Faroe Islands)",
    fi: "Finnish",
    fi_FI: "Finnish (Finland)",
    fr: "French",
    fr_DZ: "French (Algeria)",
    fr_BE: "French (Belgium)",
    fr_BJ: "French (Benin)",
    fr_BF: "French (Burkina Faso)",
    fr_BI: "French (Burundi)",
    fr_CM: "French (Cameroon)",
    fr_CA: "French (Canada)",
    fr_CF: "French (Central African Republic)",
    fr_TD: "French (Chad)",
    fr_KM: "French (Comoros)",
    fr_CG: "French (Congo - Brazzaville)",
    fr_CD: "French (Congo - Kinshasa)",
    fr_CI: "French (C\u00f4te d\u2019Ivoire)",
    fr_DJ: "French (Djibouti)",
    fr_GQ: "French (Equatorial Guinea)",
    fr_FR: "French (France)",
    fr_GF: "French (French Guiana)",
    fr_PF: "French (French Polynesia)",
    fr_GA: "French (Gabon)",
    fr_GP: "French (Guadeloupe)",
    fr_GN: "French (Guinea)",
    fr_HT: "French (Haiti)",
    fr_LU: "French (Luxembourg)",
    fr_MG: "French (Madagascar)",
    fr_ML: "French (Mali)",
    fr_MQ: "French (Martinique)",
    fr_MR: "French (Mauritania)",
    fr_MU: "French (Mauritius)",
    fr_YT: "French (Mayotte)",
    fr_MC: "French (Monaco)",
    fr_MA: "French (Morocco)",
    fr_NC: "French (New Caledonia)",
    fr_NE: "French (Niger)",
    fr_RE: "French (R\u00e9union)",
    fr_RW: "French (Rwanda)",
    fr_BL: "French (Saint Barth\u00e9lemy)",
    fr_MF: "French (Saint Martin)",
    fr_PM: "French (Saint Pierre and Miquelon)",
    fr_SN: "French (Senegal)",
    fr_SC: "French (Seychelles)",
    fr_CH: "French (Switzerland)",
    fr_SY: "French (Syria)",
    fr_TG: "French (Togo)",
    fr_TN: "French (Tunisia)",
    fr_VU: "French (Vanuatu)",
    fr_WF: "French (Wallis and Futuna)",
    ff: "Fulah",
    ff_CM: "Fulah (Cameroon)",
    ff_GN: "Fulah (Guinea)",
    ff_MR: "Fulah (Mauritania)",
    ff_SN: "Fulah (Senegal)",
    gl: "Galician",
    gl_ES: "Galician (Spain)",
    lg: "Ganda",
    lg_UG: "Ganda (Uganda)",
    ka: "Georgian",
    ka_GE: "Georgian (Georgia)",
    de: "German",
    de_AT: "German (Austria)",
    de_BE: "German (Belgium)",
    de_DE: "German (Germany)",
    de_LI: "German (Liechtenstein)",
    de_LU: "German (Luxembourg)",
    de_CH: "German (Switzerland)",
    el: "Greek",
    el_CY: "Greek (Cyprus)",
    el_GR: "Greek (Greece)",
    gu: "Gujarati",
    gu_IN: "Gujarati (India)",
    ha: "Hausa",
    ha_GH: "Hausa (Ghana)",
    ha_Latn_GH: "Hausa (Latin, Ghana)",
    ha_Latn_NE: "Hausa (Latin, Niger)",
    ha_Latn_NG: "Hausa (Latin, Nigeria)",
    ha_Latn: "Hausa (Latin)",
    ha_NE: "Hausa (Niger)",
    ha_NG: "Hausa (Nigeria)",
    he: "Hebrew",
    he_IL: "Hebrew (Israel)",
    hi: "Hindi",
    hi_IN: "Hindi (India)",
    hu: "Hungarian",
    hu_HU: "Hungarian (Hungary)",
    is: "Icelandic",
    is_IS: "Icelandic (Iceland)",
    ig: "Igbo",
    ig_NG: "Igbo (Nigeria)",
    id: "Indonesian",
    id_ID: "Indonesian (Indonesia)",
    ga: "Irish",
    ga_IE: "Irish (Ireland)",
    it: "Italian",
    it_IT: "Italian (Italy)",
    it_SM: "Italian (San Marino)",
    it_CH: "Italian (Switzerland)",
    ja: "Japanese",
    ja_JP: "Japanese (Japan)",
    kl: "Kalaallisut",
    kl_GL: "Kalaallisut (Greenland)",
    kn: "Kannada",
    kn_IN: "Kannada (India)",
    ks: "Kashmiri",
    ks_Arab_IN: "Kashmiri (Arabic, India)",
    ks_Arab: "Kashmiri (Arabic)",
    ks_IN: "Kashmiri (India)",
    kk: "Kazakh",
    kk_Cyrl_KZ: "Kazakh (Cyrillic, Kazakhstan)",
    kk_Cyrl: "Kazakh (Cyrillic)",
    kk_KZ: "Kazakh (Kazakhstan)",
    km: "Khmer",
    km_KH: "Khmer (Cambodia)",
    ki: "Kikuyu",
    ki_KE: "Kikuyu (Kenya)",
    rw: "Kinyarwanda",
    rw_RW: "Kinyarwanda (Rwanda)",
    ko: "Korean",
    ko_KP: "Korean (North Korea)",
    ko_KR: "Korean (South Korea)",
    ky: "Kyrgyz",
    ky_Cyrl_KG: "Kyrgyz (Cyrillic, Kyrgyzstan)",
    ky_Cyrl: "Kyrgyz (Cyrillic)",
    ky_KG: "Kyrgyz (Kyrgyzstan)",
    lo: "Lao",
    lo_LA: "Lao (Laos)",
    lv: "Latvian",
    lv_LV: "Latvian (Latvia)",
    ln: "Lingala",
    ln_AO: "Lingala (Angola)",
    ln_CF: "Lingala (Central African Republic)",
    ln_CG: "Lingala (Congo - Brazzaville)",
    ln_CD: "Lingala (Congo - Kinshasa)",
    lt: "Lithuanian",
    lt_LT: "Lithuanian (Lithuania)",
    lu: "Luba-Katanga",
    lu_CD: "Luba-Katanga (Congo - Kinshasa)",
    lb: "Luxembourgish",
    lb_LU: "Luxembourgish (Luxembourg)",
    mk: "Macedonian",
    mk_MK: "Macedonian (Macedonia)",
    mg: "Malagasy",
    mg_MG: "Malagasy (Madagascar)",
    ms: "Malay",
    ms_BN: "Malay (Brunei)",
    ms_Latn_BN: "Malay (Latin, Brunei)",
    ms_Latn_MY: "Malay (Latin, Malaysia)",
    ms_Latn_SG: "Malay (Latin, Singapore)",
    ms_Latn: "Malay (Latin)",
    ms_MY: "Malay (Malaysia)",
    ms_SG: "Malay (Singapore)",
    ml: "Malayalam",
    ml_IN: "Malayalam (India)",
    mt: "Maltese",
    mt_MT: "Maltese (Malta)",
    gv: "Manx",
    gv_IM: "Manx (Isle of Man)",
    mr: "Marathi",
    mr_IN: "Marathi (India)",
    mn: "Mongolian",
    mn_Cyrl_MN: "Mongolian (Cyrillic, Mongolia)",
    mn_Cyrl: "Mongolian (Cyrillic)",
    mn_MN: "Mongolian (Mongolia)",
    ne: "Nepali",
    ne_IN: "Nepali (India)",
    ne_NP: "Nepali (Nepal)",
    nd: "North Ndebele",
    nd_ZW: "North Ndebele (Zimbabwe)",
    se: "Northern Sami",
    se_FI: "Northern Sami (Finland)",
    se_NO: "Northern Sami (Norway)",
    se_SE: "Northern Sami (Sweden)",
    no: "Norwegian",
    no_NO: "Norwegian (Norway)",
    nb: "Norwegian Bokm\u00e5l",
    nb_NO: "Norwegian Bokm\u00e5l (Norway)",
    nb_SJ: "Norwegian Bokm\u00e5l (Svalbard and Jan Mayen)",
    nn: "Norwegian Nynorsk",
    nn_NO: "Norwegian Nynorsk (Norway)",
    or: "Oriya",
    or_IN: "Oriya (India)",
    om: "Oromo",
    om_ET: "Oromo (Ethiopia)",
    om_KE: "Oromo (Kenya)",
    os: "Ossetic",
    os_GE: "Ossetic (Georgia)",
    os_RU: "Ossetic (Russia)",
    ps: "Pashto",
    ps_AF: "Pashto (Afghanistan)",
    fa: "Persian",
    fa_AF: "Persian (Afghanistan)",
    fa_IR: "Persian (Iran)",
    pl: "Polish",
    pl_PL: "Polish (Poland)",
    pt: "Portuguese",
    pt_AO: "Portuguese (Angola)",
    pt_BR: "Portuguese (Brazil)",
    pt_CV: "Portuguese (Cape Verde)",
    pt_GW: "Portuguese (Guinea-Bissau)",
    pt_MO: "Portuguese (Macau SAR China)",
    pt_MZ: "Portuguese (Mozambique)",
    pt_PT: "Portuguese (Portugal)",
    pt_ST: "Portuguese (S\u00e3o Tom\u00e9 and Pr\u00edncipe)",
    pt_TL: "Portuguese (Timor-Leste)",
    pa: "Punjabi",
    pa_Arab_PK: "Punjabi (Arabic, Pakistan)",
    pa_Arab: "Punjabi (Arabic)",
    pa_Guru_IN: "Punjabi (Gurmukhi, India)",
    pa_Guru: "Punjabi (Gurmukhi)",
    pa_IN: "Punjabi (India)",
    pa_PK: "Punjabi (Pakistan)",
    qu: "Quechua",
    qu_BO: "Quechua (Bolivia)",
    qu_EC: "Quechua (Ecuador)",
    qu_PE: "Quechua (Peru)",
    ro: "Romanian",
    ro_MD: "Romanian (Moldova)",
    ro_RO: "Romanian (Romania)",
    rm: "Romansh",
    rm_CH: "Romansh (Switzerland)",
    rn: "Rundi",
    rn_BI: "Rundi (Burundi)",
    ru: "Russian",
    ru_BY: "Russian (Belarus)",
    ru_KZ: "Russian (Kazakhstan)",
    ru_KG: "Russian (Kyrgyzstan)",
    ru_MD: "Russian (Moldova)",
    ru_RU: "Russian (Russia)",
    ru_UA: "Russian (Ukraine)",
    sg: "Sango",
    sg_CF: "Sango (Central African Republic)",
    gd: "Scottish Gaelic",
    gd_GB: "Scottish Gaelic (United Kingdom)",
    sr: "Serbian",
    sr_BA: "Serbian (Bosnia and Herzegovina)",
    sr_Cyrl_BA: "Serbian (Cyrillic, Bosnia and Herzegovina)",
    sr_Cyrl_XK: "Serbian (Cyrillic, Kosovo)",
    sr_Cyrl_ME: "Serbian (Cyrillic, Montenegro)",
    sr_Cyrl_RS: "Serbian (Cyrillic, Serbia)",
    sr_Cyrl: "Serbian (Cyrillic)",
    sr_XK: "Serbian (Kosovo)",
    sr_Latn_BA: "Serbian (Latin, Bosnia and Herzegovina)",
    sr_Latn_XK: "Serbian (Latin, Kosovo)",
    sr_Latn_ME: "Serbian (Latin, Montenegro)",
    sr_Latn_RS: "Serbian (Latin, Serbia)",
    sr_Latn: "Serbian (Latin)",
    sr_ME: "Serbian (Montenegro)",
    sr_RS: "Serbian (Serbia)",
    sh: "Serbo-Croatian",
    sh_BA: "Serbo-Croatian (Bosnia and Herzegovina)",
    sn: "Shona",
    sn_ZW: "Shona (Zimbabwe)",
    ii: "Sichuan Yi",
    ii_CN: "Sichuan Yi (China)",
    si: "Sinhala",
    si_LK: "Sinhala (Sri Lanka)",
    sk: "Slovak",
    sk_SK: "Slovak (Slovakia)",
    sl: "Slovenian",
    sl_SI: "Slovenian (Slovenia)",
    so: "Somali",
    so_DJ: "Somali (Djibouti)",
    so_ET: "Somali (Ethiopia)",
    so_KE: "Somali (Kenya)",
    so_SO: "Somali (Somalia)",
    es: "Spanish",
    es_AR: "Spanish (Argentina)",
    es_BO: "Spanish (Bolivia)",
    es_IC: "Spanish (Canary Islands)",
    es_EA: "Spanish (Ceuta and Melilla)",
    es_CL: "Spanish (Chile)",
    es_CO: "Spanish (Colombia)",
    es_CR: "Spanish (Costa Rica)",
    es_CU: "Spanish (Cuba)",
    es_DO: "Spanish (Dominican Republic)",
    es_EC: "Spanish (Ecuador)",
    es_SV: "Spanish (El Salvador)",
    es_GQ: "Spanish (Equatorial Guinea)",
    es_GT: "Spanish (Guatemala)",
    es_HN: "Spanish (Honduras)",
    es_MX: "Spanish (Mexico)",
    es_NI: "Spanish (Nicaragua)",
    es_PA: "Spanish (Panama)",
    es_PY: "Spanish (Paraguay)",
    es_PE: "Spanish (Peru)",
    es_PH: "Spanish (Philippines)",
    es_PR: "Spanish (Puerto Rico)",
    es_ES: "Spanish (Spain)",
    es_US: "Spanish (United States)",
    es_UY: "Spanish (Uruguay)",
    es_VE: "Spanish (Venezuela)",
    sw: "Swahili",
    sw_KE: "Swahili (Kenya)",
    sw_TZ: "Swahili (Tanzania)",
    sw_UG: "Swahili (Uganda)",
    sv: "Swedish",
    sv_AX: "Swedish (\u00c5land Islands)",
    sv_FI: "Swedish (Finland)",
    sv_SE: "Swedish (Sweden)",
    tl: "Tagalog",
    tl_PH: "Tagalog (Philippines)",
    ta: "Tamil",
    ta_IN: "Tamil (India)",
    ta_MY: "Tamil (Malaysia)",
    ta_SG: "Tamil (Singapore)",
    ta_LK: "Tamil (Sri Lanka)",
    te: "Telugu",
    te_IN: "Telugu (India)",
    th: "Thai",
    th_TH: "Thai (Thailand)",
    bo: "Tibetan",
    bo_CN: "Tibetan (China)",
    bo_IN: "Tibetan (India)",
    ti: "Tigrinya",
    ti_ER: "Tigrinya (Eritrea)",
    ti_ET: "Tigrinya (Ethiopia)",
    to: "Tongan",
    to_TO: "Tongan (Tonga)",
    tr: "Turkish",
    tr_CY: "Turkish (Cyprus)",
    tr_TR: "Turkish (Turkey)",
    uk: "Ukrainian",
    uk_UA: "Ukrainian (Ukraine)",
    ur: "Urdu",
    ur_IN: "Urdu (India)",
    ur_PK: "Urdu (Pakistan)",
    ug: "Uyghur",
    ug_Arab_CN: "Uyghur (Arabic, China)",
    ug_Arab: "Uyghur (Arabic)",
    ug_CN: "Uyghur (China)",
    uz: "Uzbek",
    uz_AF: "Uzbek (Afghanistan)",
    uz_Arab_AF: "Uzbek (Arabic, Afghanistan)",
    uz_Arab: "Uzbek (Arabic)",
    uz_Cyrl_UZ: "Uzbek (Cyrillic, Uzbekistan)",
    uz_Cyrl: "Uzbek (Cyrillic)",
    uz_Latn_UZ: "Uzbek (Latin, Uzbekistan)",
    uz_Latn: "Uzbek (Latin)",
    uz_UZ: "Uzbek (Uzbekistan)",
    vi: "Vietnamese",
    vi_VN: "Vietnamese (Vietnam)",
    cy: "Welsh",
    cy_GB: "Welsh (United Kingdom)",
    fy: "Western Frisian",
    fy_NL: "Western Frisian (Netherlands)",
    yi: "Yiddish",
    yo: "Yoruba",
    yo_BJ: "Yoruba (Benin)",
    yo_NG: "Yoruba (Nigeria)",
    zu: "Zulu",
    zu_ZA: "Zulu (South Africa)",
  },
};
