import { FunctionComponent, useState, useRef, useEffect } from "react";
// Helper
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useHotkeys } from "react-hotkeys-hook";
import { useHistory } from "react-router";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { Dialog, FormError, RecordList, useStaticContent } from "@dyce/ui";
import {
  useAppDispatch,
  createCategory,
  updateCategory,
  removeCategory,
  useAppSelector,
  selectLocaleHyphen,
  clearLatestCreatedId,
  selectEditorState,
  setOpenEditor,
  selectOpenCreatedRecording,
  setOpenCreatedRecording,
  selectLanguageCode,
  selectAdminSettingsTasks,
} from "@dyce/slices";
import { CategoriesWithTemplates, TemplateCategory } from "@dyce/tnt-api";
import {
  handleDeleteRecord,
  handleRecordBudget,
  handleSaveTimerecording,
  handleUpdateTemplate,
  mutateRecordForTemplate,
  validateTemplateObject,
} from "@dyce/utils";
// MUI
import { makeStyles, createStyles } from "@mui/styles";
import {
  Typography,
  TextField,
  ListItemIcon,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    listContainer: {
      flexDirection: "column",
      display: "flex",
      justifyContent: "stretch",
    },
    emptyPageContainer: {
      marginTop: 15,
    },
    templateIcon: {
      marginRight: ".75rem",
      color: theme.palette.primary.main,
    },
    buttonContainer: {
      marginTop: "2rem",
      marginBottom: "2rem",
      marginLeft: "1rem",
      display: "flex",
      justifyContent: "left",
      width: "100%",
      justifyItems: "center",
    },
    addButton: {
      width: "200px",
    },
    dialogContainer: {
      width: "100%",
      marginTop: 10,
    },
  })
);

interface IProps {
  templates: CategoriesWithTemplates;
  templateCategories: TemplateCategory[];
  addCategory: boolean;
  addEntry: boolean;
  onClose: () => void;
}

enum ACTION {
  delete = "DELETE",
  update = "UPDATE",
  create = "CREATE",
  new_entry = "NEW_ENTRY",
}

export const TemplateList: FunctionComponent<IProps> = ({
  templates,
  templateCategories,
  addCategory,
  addEntry,
  onClose,
}) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation<{
    fromTemplates: {
      scrollPosition: number;
    };
  }>();
  const { routes } = useStaticContent();
  const scrollContainer = document.getElementById("rootContainer") ?? undefined;

  // UseRefs
  const textInput = useRef(null);

  // Selectors
  const localeHyphen = useAppSelector(selectLocaleHyphen);
  const timeTrackingToolIsOpen = useAppSelector(selectEditorState);
  const openInTimerecordings = useAppSelector(selectOpenCreatedRecording);
  const languageCode = useAppSelector(selectLanguageCode);
  const tasksRule = useAppSelector(selectAdminSettingsTasks);

  // States
  const [dialogOpen, setDialogOpen] = useState(false);
  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [entryDialogOpen, setEntryDialogOpen] = useState(false);
  const [categoryName, setCategoryName] = useState("");
  const [selectedCategory, setSelectedCategory] =
    useState<TemplateCategory | null>(null);
  const [action, setAction] = useState(ACTION.create);

  const [error, setError] = useState<[{ message: string }] | null>(null);

  /**
   * Hotkey handler: 'Enter' for saving category
   */
  useHotkeys(
    "enter",
    () => {
      if (document.activeElement === textInput.current) {
        handleConfirm();
      }
    },
    {
      enableOnTags: ["INPUT", "TEXTAREA"],
    }
  );

  useEffect(() => {
    if (addCategory) {
      handleButtonCat();
    }
  }, [addCategory]);

  useEffect(() => {
    if (location.state && scrollContainer) {
      scrollContainer.scrollTo({
        top: location.state.fromTemplates.scrollPosition,
        left: 0,
        behavior: "auto",
      });
      history.push(location.pathname, undefined);
    }
  }, [location.state]);

  useEffect(() => {
    if (addEntry) {
      handleButtonEntry();
    }
  }, [addEntry]);

  // preselect selected category if no category available yet
  useEffect(() => {
    if (templateCategories.length === 0) {
      setSelectedCategory({
        id: "0",
        name: t("timerec.myTime.templates.category") + " 1",
        resource: "0",
      });
    }
  }, [templateCategories]);

  // preselect only category
  useEffect(() => {
    if (templateCategories.length === 1) {
      setSelectedCategory(templateCategories[0]);
    }
  }, [templateCategories]);

  /**
   * Handle button click for new category
   */
  const handleButtonCat = () => {
    setAction(ACTION.create);
    setCategoryName("");
    setDialogOpen(true);
  };

  /**
   * Handle button click for new entry
   */
  const handleButtonEntry = () => {
    setAction(ACTION.new_entry);
    setEntryDialogOpen(true);
  };

  /**
   * Handle cancel in all dialogs
   */
  const handleCancel = () => {
    setDialogOpen(false);
    setRemoveDialogOpen(false);
    setEntryDialogOpen(false);
    setError(null);
    onClose();
  };

  /**
   * Handle confirm in all dialogs
   */
  const handleConfirm = () => {
    // don't confirm if there are errors
    if (error) return;
    if (categoryName.length > 0) {
      if (action === ACTION.create) {
        dispatch(createCategory({ name: categoryName }));
      }
      if (action === ACTION.update && selectedCategory) {
        dispatch(updateCategory({ ...selectedCategory, name: categoryName }));
      }
      setDialogOpen(false);
      onClose();
    }

    if (action === ACTION.delete && selectedCategory) {
      dispatch(removeCategory(selectedCategory?.id));
      setRemoveDialogOpen(false);
    }

    if (action === ACTION.new_entry) {
      // if dummy category detected, create a new one
      if (selectedCategory?.id == "0") {
        // create the new category and wait for result so we have the ID
        dispatch(createCategory({ name: selectedCategory.name })).then(
          (res: any) => {
            setSelectedCategory(res);
            // open a new template in the category; need any bc of bad typing
            history.push(
              `${routes.TNT}/templates?newRecord=true#${selectedCategory?.name}`
            );
          }
        );
      } else {
        history.push(
          `${routes.TNT}/templates?newRecord=true#${selectedCategory?.name}`
        );
      }

      setEntryDialogOpen(false);
    }
  };

  /**
   * Handle Editor behavior, set category for save template
   */
  const handleCreateTemplate = (cat: TemplateCategory | null) => {
    if (cat) {
      setSelectedCategory(cat);
    }
  };

  /**
   * Handle category update action from context menu
   */
  const handleCategoryUpdate = (cat: TemplateCategory | null) => {
    setAction(ACTION.update);
    if (cat) {
      setSelectedCategory(cat);
      setCategoryName(cat.name);
      setDialogOpen(true);
    }
  };

  /**
   * Handle category remove action from context menu
   */
  const handleCategoryDelete = (cat: TemplateCategory | null) => {
    if (cat) {
      setAction(ACTION.delete);
      setSelectedCategory(cat);
      setRemoveDialogOpen(true);
    }
  };

  /**
   * Function to handle different information options for same Dialog
   * @param key Provide key for value that should be returned ("title" | "text")
   * @returns Translation string
   */
  const handleDialogInformation = (key: "title" | "text") => {
    const textOptions = {
      title: "",
      text: "",
    };
    if (action === ACTION.create) {
      textOptions.title = t("timerec.myTime.templates.dialog.titleCreate");
      textOptions.text = t("timerec.myTime.templates.dialog.textCreate");
    } else if (action === ACTION.update) {
      textOptions.title = t("timerec.myTime.templates.dialog.titleUpdate");
      textOptions.text = t("timerec.myTime.templates.dialog.textUpdate");
    }

    return textOptions[key];
  };

  return (
    <>
      {dialogOpen && (
        <Dialog
          open={dialogOpen}
          title={handleDialogInformation("title")}
          text={handleDialogInformation("text")}
          onSubmit={handleConfirm}
          onCancel={handleCancel}
          actionButtonLabels={{
            confirm: t("timerec.myTime.templates.dialog.confirm"),
          }}
          isDisabled={Boolean(error) || categoryName.length === 0}
        >
          <div className={classes.dialogContainer}>
            {error && <FormError errors={error as any} />}
            <TextField
              inputRef={textInput}
              style={{ width: "100%" }}
              onChange={(e) => {
                setCategoryName(e.target.value);
                if (e.target.value.length > 100) {
                  setError([
                    {
                      message: t("timerec.myTime.templates.dialog.lengthError"),
                    },
                  ]);
                } else {
                  setError(null);
                }
              }}
              value={categoryName}
              label={t("timerec.myTime.templates.dialog.placeholder")}
              InputLabelProps={{
                shrink: true,
              }}
              autoFocus={true}
            />
          </div>
        </Dialog>
      )}
      {removeDialogOpen && (
        <Dialog
          open={removeDialogOpen}
          title={t("timerec.myTime.templates.dialog.removeTitle")}
          text={t("timerec.myTime.templates.dialog.removeText", {
            category: selectedCategory?.name,
          })}
          onSubmit={handleConfirm}
          onCancel={handleCancel}
          actionButtonLabels={{
            confirm: t("timerec.myTime.templates.dialog.delete"),
          }}
        />
      )}
      {entryDialogOpen && (
        <Dialog
          isDisabled={
            templateCategories.length === 0
              ? false
              : Boolean(selectedCategory === null)
          }
          open={entryDialogOpen}
          title={t("timerec.myTime.templates.dialog.addEntryTitle")}
          text={t("timerec.myTime.templates.dialog.addEntryText", {
            category: selectedCategory?.name,
          })}
          onSubmit={handleConfirm}
          onCancel={handleCancel}
          actionButtonLabels={{
            confirm: t("timerec.myTime.templates.dialog.add"),
          }}
        >
          <FormControl fullWidth style={{ marginTop: "1rem" }}>
            <InputLabel id="create-entry-select-label">
              {t("timerec.myTime.templates.category")}
            </InputLabel>
            <Select
              labelId="create-entry-select-label"
              value={selectedCategory?.id ? selectedCategory.id : "0"}
              autoFocus={true}
              label={t("timerec.myTime.templates.category")}
              onChange={(_, e) => {
                // create a dummy category if no category exists yet
                if ((e as any).props.value == "0") {
                  setSelectedCategory({
                    id: "0",
                    name: t("timerec.myTime.templates.category") + " 1",
                    resource: "0",
                  });

                  return;
                }

                // search category by the id we received from the select component
                const cat = templateCategories.find(
                  (c) => c.id === (e as any).props.value
                );
                if (cat) setSelectedCategory(cat);
              }}
            >
              {/* If not category exists show a placeholder entry */}
              {templateCategories.length === 0 ? (
                <MenuItem value={"0"}>{`${t(
                  "timerec.myTime.templates.category"
                )} 1`}</MenuItem>
              ) : (
                templateCategories.map((c, i) => (
                  <MenuItem key={i} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </Dialog>
      )}

      <div className={classes.listContainer} id="tempEntries">
        {templateCategories.length === 0 ? (
          <NoTemplates label={t("templates.emptyPage.title")} />
        ) : (
          templateCategories.map((cat, index) => (
            <div key={cat.name + index} id={`#${cat.name.replace(/\s+/g, "")}`}>
              <RecordList
                markHeader
                workWithFilter={tasksRule.assignment}
                headerText={cat}
                records={templates[cat.id]}
                name={cat.name}
                headerAdditions={true}
                isEnglish={languageCode === "en"}
                templateList={[]}
                getRecordBudget={async ({ id, levelOfDetail }) =>
                  await handleRecordBudget({
                    dispatch,
                    id,
                    levelOfDetail,
                    inHours: true,
                  })
                }
                openInTimerecordings={openInTimerecordings.templates}
                menuOptions={{
                  template: false,
                  copy: false,
                  extensionLink: false,
                }}
                createTemplate={handleCreateTemplate}
                validateTemplateObject={async (template) =>
                  await validateTemplateObject(template, dispatch)
                }
                mutateRecordForTemplate={mutateRecordForTemplate}
                templateCategories={{
                  templateCategories: templateCategories,
                  getAllCategories: () => {},
                }}
                headerContextMenu={[
                  {
                    name: t("timerec.myTime.templates.context.update"),
                    icon: (
                      <ListItemIcon style={{ minWidth: 36 }}>
                        <CreateIcon fontSize="small" />
                      </ListItemIcon>
                    ),
                    action: handleCategoryUpdate,
                  },
                  {
                    name: t("timerec.myTime.templates.context.delete"),
                    icon: (
                      <ListItemIcon style={{ minWidth: 36 }}>
                        <DeleteForeverIcon fontSize="small" />
                      </ListItemIcon>
                    ),
                    action: handleCategoryDelete,
                  },
                ]}
                timeTrackingToolIsOpen={timeTrackingToolIsOpen}
                deleteRecord={(id, template) =>
                  handleDeleteRecord(id, dispatch, template)
                }
                timeTrackingToolProps={{
                  onSave: (entry, isTimeRecord) => {
                    if (isTimeRecord) {
                      return handleSaveTimerecording(entry, dispatch);
                    } else {
                      return handleUpdateTemplate(
                        entry,
                        selectedCategory,
                        dispatch
                      );
                    }
                  },
                  timeTrackingToolOpen: (value) =>
                    dispatch(setOpenEditor(value)),
                  clearLatestCreatedId: () => dispatch(clearLatestCreatedId()),
                  localeHyphen: localeHyphen,
                  templateCategory: cat,
                  openCreatedRecording: (value) =>
                    dispatch(
                      setOpenCreatedRecording({
                        tasks: openInTimerecordings.tasks,
                        templates: value,
                      })
                    ),
                }}
              />
            </div>
          ))
        )}
      </div>
    </>
  );
};

const NoTemplates: FunctionComponent<{ label: string }> = ({ label }) => {
  const classes = useStyles();

  return (
    <div className={classes.emptyPageContainer}>
      <Typography>{label}</Typography>
    </div>
  );
};
