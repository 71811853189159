import { RecordTemplate } from "../types";
import buildQuery, { QueryOptions } from "odata-query";
import axios, { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export class TemplateService {
  public baseUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = combineURLs(baseUrl, "/TimeRecordingTemplates");

    this.axiosInstance = instance;
  }

  /**
   * Gets all Templates
   * @param query OData Query
   * @example
   * // Get every single Template
   * const client = new TnTClient(args).getTemplateService()
   * await client.getAll()
   * // Only get the first 10 entries
   * const client = new TnTClient(args).getTemplateService()
   * await client.getAll({ top: 10 })
   */
  public async getAll(
    query?: Partial<QueryOptions<RecordTemplate>>
  ): Promise<RecordTemplate[]> {
    const url = `${this.baseUrl}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<RecordTemplate[]>(url);

    return response.data;
  }

  /**
   * Gets only one Template by ID
   * @param id The ID of the category
   * @param query OData Query
   * @example
   * const client = new TnTClient(args).getTemplateService()
   * await client.getOne("0000-0000-0000-0000")
   */
  public async getOne(
    id: string,
    query?: Partial<QueryOptions<RecordTemplate>>
  ): Promise<RecordTemplate> {
    const url = `${this.baseUrl}/${id}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<RecordTemplate>(url);

    return response.data;
  }

  /**
   * Creates a new Template
   * @param payload
   * @example
   * const client = new TnTClient(args).getTemplateService()
   * await client.create({ name: "Example", resource: { id: "0000-0000-0000-0000" } })
   */
  public async create(payload: RecordTemplate): Promise<RecordTemplate> {
    const url = `${this.baseUrl}`;

    const result = await this.axiosInstance.post<RecordTemplate>(url, payload);

    return result.data;
  }

  /**
   * Update a single Template
   * @param id
   * @param payload
   * const client = new TnTClient(args).getTemplateService()
   * await client.update("0000-0000-0000-0000", { name: "New Example" })
   */
  public async update(
    id: string,
    payload: DeepPartial<RecordTemplate>
  ): Promise<RecordTemplate> {
    const url = `${this.baseUrl}/${id}`;

    const result = await this.axiosInstance.put<RecordTemplate>(url, payload);

    return result.data;
  }

  /**
   * Deletes a Template
   * @param id
   * const client = new TnTClient().getTemplateService()
   * await client.remove("0000-0000-0000-0000")
   */
  public async remove(id: string): Promise<void> {
    const url = `${this.baseUrl}/${id}`;

    await this.axiosInstance.delete(url);

    return;
  }
}
