// Helper
import { DateTime } from "luxon";
// Dyce-Lib
import { WindowDimensions } from "@dyce/hooks";
import { RecordTimeRec } from "@dyce/tnt-api";
import { DyceTheme } from "@dyce/theme";

/**
 * Function to find next valid workday compared with timerecordings; Means if entries
 * exists on holiday/weekend/vacation they will also returned
 * @param recs Timerecordings as [string, TimeRecording[]][]
 * @param validDate @see {validateLocationHashDate}
 * @param dateFormat String in which format Dates come in ("yyyy-MM-dd")
 * @param urlSearch Location.search query or null
 * @returns String with | without location search query and next found workday (#2021-06-04)
 */
export const loopToNextWorkday = (
  recs: [string, RecordTimeRec[]][],
  validDate: string,
  urlSearch: string | null
): string => {
  let hashDate = "";
  // Loop to days after validDate
  if (recs.length > 0) {
    for (let i = 0; i < 7; i++) {
      if (
        recs.some(
          (x) =>
            x[0] === DateTime.fromISO(validDate).plus({ days: i }).toISODate()
        )
      ) {
        hashDate = `${urlSearch ? urlSearch : ""}#${DateTime.fromISO(validDate)
          .plus({ days: i })
          .toISODate()}`;

        break;
      }
    }
  }
  // Loop to days before validDate
  if (recs.length > 0) {
    for (let i = 0; i < 7; i++) {
      if (
        recs.some(
          (x) =>
            x[0] === DateTime.fromISO(validDate).minus({ days: i }).toISODate()
        )
      ) {
        hashDate = `${urlSearch ? urlSearch : ""}#${DateTime.fromISO(validDate)
          .minus({ days: i })
          .toISODate()}`;

        break;
      }
    }
  }
  return hashDate;
};

/**
 * Function to calculate height below last Day/Item to be able to sroll, that it is
 * possible to see first Workday on top (ScrollToDate etc.)
 * @param recs Timerecordings as [string, TimeRecording[]][]
 * @param windowDim Lifecycle hook for window dimensions @see {WindowDimensions}
 * @returns Height for Divcontainer below last Day/Item
 */
export const calcDivContainerHeight = (
  recs: [string, RecordTimeRec[]][],
  windowDim: WindowDimensions
): number => {
  const baseHeight = 100; // Header + MarginBottom
  const itemHeight = 59; // Item (58px) + Divider (1px)
  const height: number =
    recs[recs.length - 1].map((x) => x.length)[1] * itemHeight <=
    windowDim.height - 193
      ? windowDim.height -
        193 -
        baseHeight -
        recs[recs.length - 1].map((x) => x.length)[1] * itemHeight
      : 0;

  return height;
};

/**
 * Function to calculate scrollposition in Listentries for Timerecordings/Templates/Tasks
 * @param param0 See documentation at props directly
 * @returns Scrollheight calculated from ListEntries with Header and Margin
 */
export const handleScrollHeight = ({
  startHeight,
  hashId,
  headerCaption,
  arrayLength,
  secondArray,
  theme,
}: {
  /**
   * Initial start height
   * @default 0
   */
  startHeight?: number;
  /**
   * Id from location.hash
   */
  hashId: string;
  /**
   * Callback to get caption from header out of an array
   */
  headerCaption: (n: number) => string;
  /**
   * Number for loop count (array length)
   */
  arrayLength: number;
  /**
   * Count or length from second array (e.g. array is 2 dimensional, second info)
   */
  secondArray: (n: number) => number;
  /**
   * Theme from Dyce-Lib
   */
  theme: DyceTheme;
}) => {
  let height = startHeight ? startHeight : 0;

  for (let i = 0; i < arrayLength; i++) {
    if (headerCaption(i) === hashId) {
      break;
    } else {
      height +=
        theme.palette.propsDyce.timeRecItemHeight.commonHeaderHeight +
        26 + // Margin between containers
        (theme.palette.propsDyce.timeRecItemHeight.commonHeight + 1) *
          secondArray(i);
    }
  }

  return height;
};
