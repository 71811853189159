// Slices named as Reducer
import adminReducer from "../features/admin/adminSlice";
import calendarReducer from "../features/calendar/calendarSlice";
import devOpsReducer from "../features/devOps/devOpsSlice";
import jiraReducer from "../features/jira/jiraSlice";
import networkWatcherReducer from "../features/networkWatcher/networkWatcherSlice";
import notificationReducer from "../features/notification/notificationSlice";
import onboardingReducer from "../features/onboarding/onboardingSlice";
import resourceReducer from "../features/resources/resourceSlice";
import settingsReducer from "../features/settings/settingsSlice";
import taskReducer from "../features/task/taskSlice";
import templatesReducer from "../features/templates/templatesSlice";
import timerecsReducer from "../features/timerec/timerecsSlice";
import tntReducer from "../features/tnt/tntSlice";
import uiReducer from "../features/ui/uiSlice";
import userReducer from "../features/user/userSlice";
// RTK
import {
  AnyAction,
  CombinedState,
  combineReducers,
  Reducer,
} from "@reduxjs/toolkit";
// State sync
import { withReduxStateSync } from "redux-state-sync";
// Dyce-Lib
import {
  AdminStateSlice,
  CalendarStateSlice,
  DevOpsStateSlice,
  NetworkStateSlice,
  NotificationStateSlice,
  OnboardingStateSlice,
  SettingsStateSlice,
  TaskStateSlice,
  JiraStateSlice,
  TemplateStateSlice,
  TimerecStateSlice,
  TntStateSlice,
  ResourceStateSlice,
  UIStateSlice,
  UserStateSlice,
} from "../types/types";

/**
 * Declare rootReducer with [key]: namedReducer
 * Expand @type {SliceProps} with same [key]
 */
export const rootReducers = {
  admin: adminReducer,
  calendar: calendarReducer,
  devOps: devOpsReducer,
  jira: jiraReducer,
  network: networkWatcherReducer,
  notification: notificationReducer,
  onboarding: onboardingReducer,
  resources: resourceReducer,
  task: taskReducer,
  templates: templatesReducer,
  timerec: timerecsReducer,
  tnt: tntReducer,
  ui: uiReducer,
  user: userReducer,
  settings: settingsReducer,
};

/**
 * Expand this type at [key] same as @const {rootReducers} with boolean switch as type (value);
 * If expanded, update './context.ts' and check for testprovider files:
 * [test-utils.tsx, cypress-utils.tsx], if expandation there is also needed;
 */
export type SliceProps = {
  /**
   * Switch for adminSlice
   * @default false
   */
  admin?: boolean;
  /**
   * Switch for calendarSlice
   * @default false
   */
  calendar?: boolean;
  /**
   * Switch for networkWatcherSlice
   * @default false
   */
  network?: boolean;
  /**
   * Switch for notificationWatcherSlice
   * @default false
   */
  notification?: boolean;
  /**
   * Switch for onboardingWatcherSlice
   * @default false
   */
  onboarding?: boolean;
  /**
   * Switch for resourceSlice
   * Turns on userSlice because of dependency
   * @default false
   */
  resources?: boolean;
  /**
   * Switch for settingsSlice
   * @default false
   */
  settings?: boolean;
  /**
   * Switch for taskSlice
   * @default false
   */
  task?: boolean;
  /**
   * Switch for templatesSlice
   * @default false
   */
  templates?: boolean;
  /**
   * Switch for timerecSlice;
   * Turns on resourceSlice & settingsSlice because of dependency;
   * ResourceSlice turns on userSlice because of dependency;
   * @default false
   */
  timerec?: boolean;
  /**
   * Switch for tntSlice
   * @default false
   */
  tnt?: boolean;
  /**
   * Switch for uiSlice
   * @default false
   */
  ui?: boolean;
  /**
   * Switch for userSlice
   * @default false
   */
  user?: boolean;
  /**
   * Switch for devOpsSlice
   * @default false
   */
  devOps?: boolean;
  /**
   * Switch for jiraSlice
   * @default false
   */
  jira?: boolean;
};

export type ReducerType = Partial<
  Reducer<
    CombinedState<{
      admin: AdminStateSlice;
      calendar: CalendarStateSlice;
      network: NetworkStateSlice;
      notification: NotificationStateSlice;
      onboarding: OnboardingStateSlice;
      resources: ResourceStateSlice;
      settings: SettingsStateSlice;
      task: TaskStateSlice;
      templates: TemplateStateSlice;
      timerec: TimerecStateSlice;
      tnt: TntStateSlice;
      ui: UIStateSlice;
      user: UserStateSlice;
      devOps: DevOpsStateSlice;
      jira: JiraStateSlice;
    }>,
    AnyAction
  >
>;

/**
 * Function to select defined slices for final store, by provided information
 * @param selectSlices SliceProps object to define slices for store
 * @returns Reducer<CombinedState<{...}>> as Partial typed for combineReducers() function
 */
export const handleReducers = (
  selectSlices: SliceProps,
  syncAfterRefresh: boolean
): ReducerType => {
  // Create Arrays from Props and rootReducer
  const selectSlicesArray: [string, boolean][] = Object.entries(selectSlices);
  const rootReducersArray = Object.entries(rootReducers);

  // Declare new reducerObject
  let dyceReducers: ReducerType = {};

  // Avoid initial empty array issues
  if (selectSlicesArray.length > 0) {
    // Loop to check which slices 'turned on'
    for (let i = 0; i < selectSlicesArray.length; i++) {
      for (let j = 0; j < rootReducersArray.length; j++) {
        if (
          rootReducersArray[j][0] === selectSlicesArray[i][0] &&
          selectSlicesArray[i][1] === true
        ) {
          dyceReducers = {
            ...dyceReducers,
            [rootReducersArray[j][0]]: rootReducersArray[j][1],
          };
        }
      }
    }
  }

  if (syncAfterRefresh) {
    return withReduxStateSync(combineReducers(dyceReducers));
  } else {
    return dyceReducers;
  }
};
