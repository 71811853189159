import { MutableRefObject } from "react";
// Helper
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
// Dyce-Lib
import {
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  StatusOptions,
} from "@dyce/tnt-api";
import { DurationField as DurField } from "../../inputs/durationField/durationField";
// MUI
import { makeStyles, createStyles } from "@mui/styles";

const useStyles = (value: string) =>
  makeStyles(() =>
    createStyles({
      textFieldSmall: {
        width: value === "durationBillable" ? "15rem" : "5rem",
      },
    })
  );

interface IDurationFieldProps {
  /**
   * Define which component should be rendered
   */
  fieldName: "duration" | "break" | "durationBillable";
  /**
   * Define tabIndex to focus correct field
   */
  tabIndex?: number;
  /**
   * Timerecording object
   */
  timeInput: RecordEntry & Partial<RecordTemplate & RecordTimeRec>;
  /**
   * Common CSS style for text field
   */
  commonTextFieldStyles: string;
  /**
   * If true, components will be disabled;
   */
  pending: boolean;
  /**
   * Callback fired onBlur event, to save reference to state
   * @param ref MutableRefObject<HTMLInputElement | null>
   * @returns void
   */
  onInputBlur?: (ref: MutableRefObject<HTMLInputElement | null>) => void;
  /**
   * If true, the input element will be focused during the first mount
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Define size of textfield
   */
  size?: "small" | "medium";
}

export const DurationField: React.FC<IDurationFieldProps> = ({
  fieldName,
  tabIndex,
  timeInput,
  commonTextFieldStyles,
  pending,
  autoFocus = false,
  size,
  onInputBlur,
}) => {
  const classes = useStyles(fieldName)();
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const translationObj = {
    duration: t("timerec.dailyRecording.add.duration"),
    break: t("timerec.dailyRecording.add.break"),
    durationBillable: t("timerec.dailyRecording.add.durationBillable"),
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, ref } }) => (
        <DurField
          refHook={ref}
          testId={fieldName}
          size={size}
          label={errors[fieldName] ? "" : translationObj[fieldName]}
          isRequired={fieldName === "duration"}
          disabled={
            // Field 'break' has different disable switches then duration && durationBillable
            fieldName.includes("duration")
              ? (timeInput.status && timeInput.status !== StatusOptions.OPEN) ||
                pending
              : (timeInput.status && timeInput.status !== StatusOptions.OPEN) ||
                !timeInput.start ||
                !timeInput.end ||
                pending
          }
          onBlur={(_, ref) => onInputBlur && onInputBlur(ref)}
          data-testid={`${fieldName}-field`}
          value={timeInput[fieldName]}
          onChange={onChange}
          style={classNames(classes.textFieldSmall, commonTextFieldStyles)}
          tabIndex={tabIndex}
          autoFocus={autoFocus}
        />
      )}
      defaultValue={timeInput[fieldName]}
    />
  );
};
