import { MutableRefObject } from "react";
// Helper
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Controller, useFormContext } from "react-hook-form";
import moment from "moment";
// Dyce-Lib
import {
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  StatusOptions,
} from "@dyce/tnt-api";
import { DyceTheme } from "@dyce/theme";
// Material-UI
import { makeStyles, createStyles } from "@mui/styles";
// Components
import { TimeFieldMoment } from "../../inputs/timeField/moment/timeField";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    textFieldTime: {
      maxWidth: "8rem",
      "& .MuiIconButton-root": {
        color: theme.palette.primary.main,
      },
      "& .MuiFilledInput-input": {
        padding: "22px 10px 6px",
      },
      "& .MuiFormLabel-root": {
        color: theme.palette.primary.main,
        fontSize: 12,
      },
      "& .MuiFilledInput-underline:before": {
        borderBottomColor: theme.palette.primary.main,
      },
      "& .MuiInputBase-input": {
        fontSize: 14,
        height: "1.5rem",
      },
      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor:
          theme.palette.mode === "light"
            ? "rgba(0, 0, 0, 0.23)"
            : "rgba(255, 255, 255, 0.23)",
      },
    },
  })
);

interface IWorkTimeContainerProps {
  /**
   * Define which component should be rendered
   */
  fieldName: "start" | "end";
  /**
   * Define tabIndex to focus correct field
   */
  tabIndex?: number;
  /**
   * Timerecording object
   */
  timeInput: RecordEntry & Partial<RecordTemplate & RecordTimeRec>;
  /**
   * Common CSS style for text field
   */
  commonTextFieldStyles: string;
  /**
   * If true, components will be disabled;
   */
  pending: boolean;
  /**
   * Callback fired onBlur event, to save reference to state
   * @param ref MutableRefObject<HTMLInputElement | null>
   * @returns void
   */
  onInputBlur?: (ref: MutableRefObject<HTMLInputElement | null>) => void;
  /**
   * If true, the input element will be focused during the first mount
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Define size of textfield
   */
  size?: "small" | "medium";
}

export const WorkTimeField: React.FC<IWorkTimeContainerProps> = ({
  fieldName,
  tabIndex,
  timeInput,
  commonTextFieldStyles,
  pending,
  autoFocus = false,
  size,
  onInputBlur,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const translationObj = {
    start: t("timerec.dailyRecording.add.start"),
    end: t("timerec.dailyRecording.add.end"),
  };

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { onChange, onBlur, ref } }) => (
        <TimeFieldMoment
          data-testid={`${fieldName}-field`}
          refHook={ref}
          testId={fieldName}
          isSelected={autoFocus}
          onChange={onChange}
          onBlur={(_, ref) => {
            onInputBlur && onInputBlur(ref);
            onBlur;
          }}
          size={size}
          // Temporarily needed, till MUI fixes 'LocalizationProvider' for DateTime lib.
          localeHyphen={moment.locale()}
          isDisabled={
            (timeInput.status && timeInput.status !== StatusOptions.OPEN) ||
            pending
          }
          value={timeInput[fieldName]}
          label={errors[fieldName] ? "" : translationObj[fieldName]}
          style={classNames(classes.textFieldTime, commonTextFieldStyles)}
          variant={"outlined"}
          tabIndex={tabIndex}
        />
      )}
      defaultValue={timeInput[fieldName]}
    />
  );
};
