import React, { FunctionComponent } from "react";
// MUI
import { ButtonGroup, SxProps, Theme } from "@mui/material";

interface IButtonGroupSwitcherProps {
  /**
   * If true, Buttons will shown as button group
   */
  asButtonGroup: boolean;
  /**
   * Reference for button to set anchor correct (used for Popper)
   */
  anchorRef: React.RefObject<HTMLDivElement>;
  /**
   * Defines a string value that labels the current element.
   * @default 'split-button'
   */
  ariaLabel?: string | undefined;
  /**
   * The variant to use.
   * @default 'contained'
   */
  variant?: "text" | "contained" | "outlined" | undefined;
  /**
   * The system prop that allows defining system overrides as well as additional CSS styles.
   */
  sx?: SxProps<Theme> | undefined;
  /**
   * The size of ButtonGroup.
   * @default 'medium'
   */
  size?: "small" | "medium";
  /**
   * The color of the component. It supports those theme colors that make sense for this component.
   * @default 'primary'
   */
  color?:
    | "inherit"
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const ButtonGroupSwitcher: FunctionComponent<
  IButtonGroupSwitcherProps
> = ({
  asButtonGroup,
  anchorRef,
  variant = "contained",
  ariaLabel = "split-button",
  sx,
  size = "medium",
  color = "primary",
  children,
}) => {
  if (asButtonGroup) {
    return (
      <ButtonGroup
        size={size}
        variant={variant}
        ref={anchorRef}
        aria-label={ariaLabel}
        color={color}
        sx={sx}
      >
        {children}
      </ButtonGroup>
    );
  } else {
    return <>{children}</>;
  }
};
