import { useState, useEffect, useRef, FunctionComponent } from "react";
// Helpers
import { useHistory, useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./helper/style.css";
import useVisibilitySensor from "@rooks/use-visibility-sensor";
import { handleUrlSearchQuery } from "./helper/utils";
// Dyce-Lib
import { useUpdateEffect } from "@dyce/hooks";
import { DyceTheme } from "@dyce/theme";
import {
  RecordTimeRec,
  RecordTemplate,
  RecordEntry,
  TemplateCategory,
  ValidatedRecord,
  ResourceCapacity,
  BudgetValues,
  TasksFilterStatus,
} from "@dyce/tnt-api";
import { RecordListItemDummy } from "../../skeletons/record-item-entries/record-list-item-dummy";
import { TimeTrackingTool } from "../../time-tracking-tool/time-tracking-tool";
// MUI
import { makeStyles, createStyles, useTheme } from "@mui/styles";
import { Box, Paper } from "@mui/material";
// Components
import { RecordListHeader } from "./record-list-header";
import { RecordListItem } from "./record-list-item";
import { useStaticContent } from "../../static-provider/static-provider";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    itemsBox: {
      background: theme.palette.background.paper,
      borderRadius: theme.shape.borderRadius,
      width: "100%",
      minWidth: 864,
      marginBottom: "1.625rem",
      scrollMarginTop: "200px",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100%",
    },
  })
);

export type MenuOptions = {
  copy?: boolean;
  delete?: boolean;
  template?: boolean;
  bcLink?: boolean;
  extensionLink?: boolean;
};

interface IRecordListProps {
  /**
   * Record entries to list
   */
  records: RecordTimeRec[] | RecordTemplate[];
  /**
   * Date from current day as 'yyyy-MM-dd' format
   * @default ''
   */
  date?: string;
  /**
   * E.g. Category; Used to open Editor in correct list at user interaction
   */
  name?: string;
  /**
   * String shown in header, when no date is provided
   */
  headerText?: TemplateCategory;
  /**
   * If true, Button 'Add' and totalHours shown in header
   */
  headerAdditions?: boolean;
  /**
   * If true, header will be shown in primary color gradient
   * @default false
   */
  markHeader?: boolean;
  /**
   * Show individual icon in header
   */
  individualHeaderIcon?: JSX.Element;
  /**
   * Show individual element in header at right side
   */
  individualAddition?: (value: number) => JSX.Element;
  /**
   * Capacity from current user
   */
  resourceCapacity?: ResourceCapacity[];
  /**
   * Show JSX.Element left besides Info-icon || Template-Button
   */
  graphicalAddition?: ({
    isReleased,
    duration,
  }: {
    isReleased: boolean;
    duration: number;
  }) => JSX.Element;
  /**
   * Array infos with [date, array.length][], used to jump in list with hotkeys
   */
  itemsArrayInfos?: [string, number][];
  /**
   * Define which options shown to user
   * @default true to all options
   */
  menuOptions?: MenuOptions;
  /**
   * Callback fired to open new Time-Tracking-Tool when already one is open
   */
  openNewAtToday?: () => void;
  /**
   * Callback fired when a new template gets created and send needed TemplateCategory
   */
  createTemplate?: (name: TemplateCategory | null) => void;
  /**
   * Array with infos on Header context (option) menu
   */
  headerContextMenu?: {
    /**
     * Name for context option
     */
    name: string;
    /**
     * Provide mui-icon to make UI more UX conform
     */
    icon?: JSX.Element;
    /**
     * If true, button will be disabled
     */
    onClick?: boolean;
    /**
     * Callback fired on onClick event, send info from TemplateCategory
     */
    action: (name: TemplateCategory | null) => void;
  }[];
  /**
   * If true, menu-options will be shown with delay of 500ms
   */
  timeTrackingToolIsOpen: boolean;
  /**
   * Templates as 2-dim array used in list to select from templates
   */
  templateList: [string, RecordTemplate[]][];
  /**
   * If provided a last created endTime, startTime will be prefilled with last endTime
   */
  latestCreatedEndTime?: string | null;
  /**
   * If true, record will instantly shown in edit mode after creating/saving with
   * correct url.search query
   */
  openInTimerecordings?: boolean;
  /**
   * Current selected language is 'en'?
   * @default true
   */
  isEnglish?: boolean;
  /**
   * Callback fired on 'Abort' from Time-Tracking-Tool; Used to clear date from state
   */
  clearTodayDate?: () => void;
  /**
   * Callback fired to get list of templates from backend
   */
  getAllTemplates?: (() => void) | undefined;
  /**
   * Callback fired when user deletes record
   */
  deleteRecord: (id: string, template?: boolean) => Promise<boolean>;
  /**
   * Callbacks to get data for budget of selected jobTask
   * @param id Id from jobTask
   * @returns BudgetValues
   */
  getRecordBudget: ({
    id,
    levelOfDetail,
  }: {
    id: string;
    levelOfDetail: "jobTask" | "jobPlanningLine";
  }) => Promise<BudgetValues>;
  /**
   * ID from last saved timerecording, used to open ListItem in editMode
   * @default null
   */
  latestCreatedRecordId?: string | null;
  /**
   * Callback to validate current timerecording in backend
   */
  validateTemplateObject?: (
    template: RecordTemplate
  ) => Promise<ValidatedRecord>;
  /**
   * Callback to mutate Timerecording entry to Template entry
   */
  mutateRecordForTemplate?: (
    copyRecordForTemplate: RecordTemplate,
    category: TemplateCategory | null,
    update?: boolean
  ) => RecordTemplate;
  /**
   * Props for template categories, used to save timerecording as template
   */
  templateCategories: {
    /**
     * Categories from templates as array
     */
    templateCategories: TemplateCategory[];
    /**
     * Callback to get all Categories for selection in Dialog
     */
    getAllCategories: () => void;
  };
  /**
   * Props to create template from current timerecording
   */
  createTemplateFromRecord?: {
    /**
     * Callback to validate current timerecording in backend
     */
    validateTemplate: (value: RecordTemplate) => Promise<ValidatedRecord>;
    /**
     * Callback to create a new category when no exists
     */
    createCategory: (value: string) => Promise<TemplateCategory>;
    /**
     * Callback to save validated timerecording as template with selected category
     */
    createTemplate: (value: RecordTemplate) => void;
  };
  /**
   * Props forwarded to Time-Tracking-Tool
   */
  timeTrackingToolProps: {
    /**
     * Callback fired to save record as Timerecording or Template;
     * returns a Promise
     */
    onSave: (
      entry: RecordEntry & Partial<RecordTemplate & RecordTimeRec>,
      isTimeRecord?: boolean
    ) => Promise<{
      newEntry: RecordEntry | RecordTemplate | null;
      error: boolean;
    }>;
    /**
     * Callback fired to delete latest saved record.id
     */
    clearLatestCreatedId: () => void;
    /**
     * Callback fired on menu options click event, to prevent scrollToHash event.
     * Can be used to setState in redux store.
     */
    timeTrackingToolOpen: (value: boolean) => void;
    /**
     * Callback to set hook for checkbox and react on selection after submit
     * @default void
     */
    openCreatedRecording?: (value: boolean) => void;
    /**
     * Current locale with hyphen, e.g. 'en-GB'
     */
    localeHyphen?: string;
    /**
     * If provided, Time-Tracking-Tool opens when also record.id matches query id
     * @default null
     */
    templateCategory?: TemplateCategory | null;
    /**
     * If true, new Time-Tracking-Tool can't be opened per hotkeys or otherwise
     */
    ignoreOpenNewEditor?: boolean;
  };
  /**
   * If true, filter-button will be shown on top of datagrid;
   * This will cause different api endpoints;
   * @default 'Never'
   */
  workWithFilter?: TasksFilterStatus;
}

export const RecordList: FunctionComponent<IRecordListProps> = ({
  records,
  date = "",
  name,
  headerText,
  itemsArrayInfos,
  graphicalAddition,
  headerAdditions = true,
  markHeader = false,
  headerContextMenu,
  individualHeaderIcon,
  individualAddition,
  getRecordBudget,
  resourceCapacity,
  templateList,
  createTemplate,
  isEnglish = true,
  templateCategories,
  validateTemplateObject,
  latestCreatedEndTime = null,
  openInTimerecordings = false,
  getAllTemplates,
  openNewAtToday,
  menuOptions,
  timeTrackingToolIsOpen,
  clearTodayDate,
  deleteRecord,
  createTemplateFromRecord,
  mutateRecordForTemplate,
  latestCreatedRecordId = null,
  workWithFilter = TasksFilterStatus.NONE,
  timeTrackingToolProps: {
    onSave,
    clearLatestCreatedId,
    timeTrackingToolOpen,
    localeHyphen = "en-GB",
    templateCategory = null,
    ignoreOpenNewEditor = false,
    openCreatedRecording = () => {},
  },
}) => {
  const classes = useStyles();
  const theme = useTheme<DyceTheme>();
  const location = useLocation<{
    fromDashboard: boolean;
    fromTemplates: boolean;
    fromTasks: boolean;
  }>();
  const history = useHistory();
  const {
    routes,
    isMobile: { mobile },
  } = useStaticContent();

  // Refs
  const renderInView = useRef(null);
  const { isVisible } = useVisibilitySensor(renderInView, {
    intervalCheck: 50,
    scrollCheck: true,
    partialVisibility: true,
    scrollDebounce: 200,
    resizeCheck: true,
    resizeDebounce: 250,
    shouldCheckOnMount: true,
  });

  // States
  const [openNewTimeTrackingTool, setOpenNewTimeTrackingTool] =
    useState<boolean>(false);
  const [showNewTimeTrackingTool, setShowNewTimeTrackingTool] =
    useState<boolean>(false);
  const [openEditTimeTrackingTool, setOpenEditTimeTrackingTool] =
    useState<boolean>(false);
  const [showEditTimeTrackingTool, setShowEditTimeTrackingTool] =
    useState<boolean>(false);
  const [showFading, setShowFading] = useState<boolean>(false);
  const [totalHours, setTotalHours] = useState<number>(0);
  const [lastEndTime, setLastEndTime] = useState<DateTime | null>(null);
  const [blockSaveRecord, setBlockSaveRecord] = useState<boolean>(false);
  const [editorHeight, setEditorHeight] = useState<number>(58);
  const [isDelayOpen, setIsDelayOpen] = useState<{
    date: string;
    index: number;
  } | null>(null);

  // UseEffects
  useEffect(() => {
    setTotalHours(calculateQuantity(records));
  }, [records]);

  useEffect(() => {
    if (openEditTimeTrackingTool) {
      setShowEditTimeTrackingTool(true);
    } else {
      setShowEditTimeTrackingTool(false);
    }
  }, [openEditTimeTrackingTool]);

  // Open new Editor(Create) when newRecord=true in URL query
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const openRecord = Boolean(query.get("newRecord"));
    const hash = location.hash.substring(1);

    // opens editor if either dates match (record) or hash matches the name (category)
    if (
      openRecord &&
      hash &&
      (DateTime.fromISO(date).day === DateTime.fromISO(hash).day ||
        (templateCategory !== null && hash === name))
    ) {
      setOpenNewTimeTrackingTool(true);
    }
  }, [location, date]);

  // Update last endTime
  useEffect(() => {
    if (templateCategory === null) {
      // eslint-disable-next-line prefer-spread
      const maxDate = Math.max.apply(
        Math,
        records.map((r: any) => {
          if (r.end) {
            return DateTime.fromISO(r.end).valueOf();
          } else {
            return 0;
          }
        })
      );
      if (
        latestCreatedEndTime &&
        DateTime.fromISO(latestCreatedEndTime).day ===
          DateTime.fromISO(date).day
      ) {
        const storeLastEndTime = DateTime.fromISO(latestCreatedEndTime);
        const entriesLastEndTime = DateTime.fromMillis(maxDate);

        if (entriesLastEndTime > storeLastEndTime) {
          setLastEndTime(entriesLastEndTime);
        } else {
          setLastEndTime(storeLastEndTime);
        }
      } else {
        if (maxDate > 0) {
          setLastEndTime(DateTime.fromMillis(maxDate));
        } else {
          setLastEndTime(null);
        }
      }
    }
  }, [records, latestCreatedEndTime, timeTrackingToolIsOpen]);

  useUpdateEffect(() => {
    if (openNewTimeTrackingTool) {
      setShowNewTimeTrackingTool(true);
    }
  }, [openNewTimeTrackingTool]);

  useEffect(() => {
    let fade: NodeJS.Timeout;
    // If openEditor === true and scroll out of ViewPort then set it false
    if (isVisible) {
      fade = setTimeout(() => {
        setShowFading(true);
      }, 100);
    } else {
      setShowFading(false);
    }
    return () => {
      clearTimeout(fade);
    };
  }, [isVisible]);

  const calculateQuantity = (recs: RecordTimeRec[] | RecordTemplate[]) => {
    let total = 0;
    recs.forEach((x: RecordTimeRec | RecordTemplate) => (total += x.duration));
    return total;
  };

  useEffect(() => {
    if (isDelayOpen && !openEditTimeTrackingTool) {
      setTimeout(() => {
        history.push(
          `?editRecord=true&index=${isDelayOpen.index}#${isDelayOpen.date}`
        );
        setIsDelayOpen(null);
      }, 20);
    }
  }, [isDelayOpen, openEditTimeTrackingTool]);

  // Handler
  const handleRecordMenuClose = () => {
    setTimeout(() => {
      setOpenNewTimeTrackingTool(false);
      setOpenEditTimeTrackingTool(false);
    }, 500);
    setShowNewTimeTrackingTool(false);
    setShowEditTimeTrackingTool(false);
  };

  const handleOpenEditor = () => {
    if (mobile) {
      if (templateCategory) {
        const mutateName = templateCategory.name.replace("&", "%26");
        history.push(
          `${routes.TNT_RECS}/newTemplate?name=${mutateName}&id=${templateCategory.id}#category`,
          {
            state: { fromLocation: location.pathname },
          }
        );
      } else {
        history.push(
          `${routes.TNT_RECS}/newRecord?lastEndTime=${
            lastEndTime ? lastEndTime.toISO() : null
          }#${date}`,
          {
            state: { fromLocation: location.pathname },
          }
        );
      }
    } else if (!openNewTimeTrackingTool) {
      setOpenNewTimeTrackingTool(true);
      if (templateCategory) {
        createTemplate &&
          createTemplate(headerText !== undefined ? headerText : null);
      }
    } else {
      setTimeout(() => {
        setOpenNewTimeTrackingTool(true);
        if (templateCategory) {
          createTemplate &&
            createTemplate(headerText !== undefined ? headerText : null);
        }
      }, 500);
    }
  };

  const handleDelayListItem = ({
    date,
    index,
  }: {
    date: string;
    index: number;
  }) => {
    setIsDelayOpen({
      date: date,
      index: index,
    });
  };

  const handleBlockClosing = (value: boolean) => {
    setBlockSaveRecord(value);
  };

  // Hotkey Alt + PageUp/PageDown Handler
  const handlePageUpDownKey = (direction: "up" | "down", id: string) => {
    const locationState = location.state;
    const searchQuery: string | null = handleUrlSearchQuery(
      direction,
      id,
      itemsArrayInfos,
      date,
      records
    );

    if (searchQuery !== null) {
      setTimeout(() => {
        history.push(searchQuery, { ...locationState });
      }, 20);
    }
  };

  const isRecord = (
    entry: RecordTimeRec | RecordTemplate
  ): entry is RecordTimeRec => {
    const check: boolean = (entry as RecordTimeRec).date !== undefined;
    return check;
  };

  const populatedMenuOptions: MenuOptions = {
    copy: true,
    delete: true,
    template: true,
    bcLink: true,
    extensionLink: true,
    ...menuOptions,
  };

  return (
    <Box
      className={classes.itemsBox}
      sx={{
        boxShadow: "4px 8px 16px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Paper
        elevation={theme.palette.propsDyce.paperDesign.elevation}
        ref={renderInView}
      >
        <div className={classes.container}>
          <RecordListHeader
            isTemplate={templateCategory !== null}
            date={date}
            headerText={headerText}
            totalHours={totalHours}
            openEditor={handleOpenEditor}
            hasAdditions={headerAdditions}
            individualIcon={individualHeaderIcon}
            contextMenu={headerContextMenu}
            disableButton={blockSaveRecord}
            hasEntries={records.length > 0}
            markHeader={markHeader}
            individualAddition={individualAddition}
            resourceCapacity={resourceCapacity}
          />
          {openNewTimeTrackingTool ? (
            <TimeTrackingTool
              record={null}
              workWithFilter={workWithFilter}
              open={showNewTimeTrackingTool}
              onCloseEditor={handleRecordMenuClose}
              openNewEditor={handleOpenEditor}
              openNewAtToday={openNewAtToday}
              onSave={onSave}
              getRecordBudget={getRecordBudget}
              isEnglish={isEnglish}
              templateCategory={templateCategory}
              ignoreOpenNewEditor={ignoreOpenNewEditor}
              lastEndTime={lastEndTime}
              recordDate={date}
              handleEditPerHotKey={handlePageUpDownKey}
              blockClosingCallback={handleBlockClosing}
              blockSaveRecord={blockSaveRecord}
              validateTemplateObject={validateTemplateObject}
              states={{
                setter: {
                  timeTrackingToolOpen: timeTrackingToolOpen,
                  clearLatestCreatedId: clearLatestCreatedId,
                  clearTodayDate: clearTodayDate,
                  templates: {
                    deleteRecord: deleteRecord,
                    openCreatedRecording: openCreatedRecording,
                  },
                  templateList: {
                    getAllTemplates: getAllTemplates,
                  },
                },
                getter: {
                  localeHyphen: localeHyphen,
                  darkMode: theme.palette.mode === "dark",
                  openCreatedRecording: openInTimerecordings,
                  templates: templateList,
                },
              }}
            />
          ) : (
            <div data-testid="editor-not-rendered" />
          )}
        </div>
        <TransitionGroup>
          {records.map((record, index) => (
            <CSSTransition key={record.id} timeout={125} classNames="item">
              {!isVisible && !showEditTimeTrackingTool ? (
                <RecordListItemDummy
                  showDivider={index + 1 !== records.length}
                />
              ) : (
                <RecordListItem
                  key={record.id}
                  record={record}
                  showDivider={index + 1 !== records.length}
                  showFading={showFading}
                  graphicalAddition={graphicalAddition}
                  delayListItem={handleDelayListItem}
                  index={index}
                  resourceCapacity={
                    resourceCapacity ? resourceCapacity : undefined
                  }
                  templateCategories={templateCategories}
                  menuOptions={populatedMenuOptions}
                  createTemplateFromRecord={createTemplateFromRecord}
                  mutateRecordForTemplate={mutateRecordForTemplate}
                  latestCreatedRecordId={latestCreatedRecordId}
                  timeTrackingToolIsOpen={
                    timeTrackingToolIsOpen || openEditTimeTrackingTool
                  }
                  editorHeight={editorHeight}
                  isRecord={isRecord}
                  onListItemClicked={(value) =>
                    setOpenEditTimeTrackingTool(value)
                  }
                  editListItem={openEditTimeTrackingTool}
                  timeTrackingToolOpen={timeTrackingToolOpen}
                  fromTemplate={templateCategory !== null}
                  blockSaveRecord={blockSaveRecord}
                  clearLatestCreatedId={clearLatestCreatedId}
                  onSave={onSave}
                  deleteRecord={deleteRecord}
                  templateDatePickerProps={{
                    validateTemplateObject: validateTemplateObject,
                    openCreatedRecording: openCreatedRecording,
                    openInTimerecordings: openInTimerecordings,
                  }}
                >
                  <TimeTrackingTool
                    record={record}
                    workWithFilter={workWithFilter}
                    onCloseEditor={() => setOpenEditTimeTrackingTool(false)}
                    onSave={onSave}
                    getRecordBudget={getRecordBudget}
                    isEnglish={isEnglish}
                    open={showEditTimeTrackingTool}
                    recordDate={
                      isRecord(record)
                        ? DateTime.fromISO(record.date).toLocal().toISODate()
                        : ""
                    }
                    openNewEditor={handleOpenEditor}
                    openNewAtToday={openNewAtToday}
                    currentHeight={(value) => setEditorHeight(value)}
                    handleEditPerHotKey={handlePageUpDownKey}
                    templateCategory={templateCategory}
                    ignoreOpenNewEditor={ignoreOpenNewEditor}
                    blockClosingCallback={handleBlockClosing}
                    blockSaveRecord={blockSaveRecord}
                    validateTemplateObject={validateTemplateObject}
                    states={{
                      setter: {
                        timeTrackingToolOpen: timeTrackingToolOpen,
                        clearLatestCreatedId: clearLatestCreatedId,
                        clearTodayDate: clearTodayDate,
                        templates: {
                          deleteRecord: deleteRecord,
                          openCreatedRecording: openCreatedRecording,
                        },
                        templateList: {
                          getAllTemplates: getAllTemplates,
                        },
                      },
                      getter: {
                        localeHyphen: localeHyphen,
                        darkMode: theme.palette.mode === "dark",
                        openCreatedRecording: openInTimerecordings,
                        templates: templateList,
                      },
                    }}
                  />
                </RecordListItem>
              )}
            </CSSTransition>
          ))}
        </TransitionGroup>
      </Paper>
    </Box>
  );
};
