import { msalInstance } from "@dyce/auth";
import { TnTClient } from "@dyce/tnt-api";
import { RootState } from "../types/types";

export const tntApiHelper = async (
  getState: () => RootState,
  options?: {
    /**
     * If true, the client will return the odata info of the response,
     * e.g. @odata.context: "https://api.dyce.dev/...
     */
    withOdataContext?: boolean;
    /**
     * Provide a preInstance to the instance call to receive e.g. userRoles;
     * For this special call the instance is needed
     */
    preInstance?: string;
    /**
     * Provide a preCompany to the instance call to receive e.g. userRoles;
     * For this special call the company is needed
     */
    preCompany?: string;
  }
): Promise<TnTClient> => {
  const { instance, company, currentUser } = getState().user;
  const {
    preCompany = undefined,
    preInstance = undefined,
    withOdataContext = false,
  } = options ? options : {};

  const finalInstance =
    preInstance !== undefined
      ? preInstance
      : instance !== null
      ? instance
      : undefined;
  const finalCompany =
    preCompany !== undefined
      ? preCompany
      : company !== null
      ? company
      : undefined;

  const baseUrl = process.env["NX_API_URL"] as string;

  // Receive token from msal Hook
  const token = await msalInstance
    .refreshToken()
    .then((res) => res.accessToken);

  // Initial TnT API Client
  const client = new TnTClient({
    url: baseUrl,
    accessToken: () => token,
    populateResource:
      currentUser.resource !== null ? currentUser.resource : undefined,
    instance: finalInstance,
    company: finalCompany,
    odataInfo: withOdataContext ? withOdataContext : false,
  });

  return client;
};
