import { FunctionComponent } from "react";
// Helper
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
// Dyce-Lib
import { AllDone } from "../all-done/all-done";
import { msalInstance } from "@dyce/auth";
import { useStaticContent } from "../static-provider/static-provider";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import HowToRegOutlinedIcon from "@mui/icons-material/HowToRegOutlined";
import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";

const useStyles = makeStyles(() =>
  createStyles({
    consentContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "start",
      flexDirection: "column",
      height: "116px",
    },
    grantedContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row",
      width: "100%",
    },
    grantedButton: {
      display: "flex",
      justifyContent: "end",
    },
  })
);

interface IConsentInfoProps {
  /**
   * The consent button text
   */
  consentButton: string;
  /**
   * The scope to request
   */
  scopes: string[];
  /**
   * The custom width of the component
   */
  customWidth?: string | number;
  /**
   * The consent state
   */
  consentGranted: boolean | undefined;
  /**
   * The current app language
   * @default "en"
   */
  language?: "en" | "de";
}

export const ConsentInfo: FunctionComponent<IConsentInfoProps> = ({
  consentButton,
  scopes,
  consentGranted,
  customWidth,
  language = "en",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { routes } = useStaticContent();

  // Start consent flow
  const requestNewScope = async () => {
    try {
      await msalInstance.acquireTokenRedirect({
        scopes,
      });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={classes.consentContainer}
      style={{
        width: customWidth
          ? customWidth
          : language === "en"
          ? "22rem"
          : "20rem",
      }}
    >
      {consentGranted ? (
        <div className={classes.grantedContainer}>
          <AllDone
            label={t("settings.calendar.consent.given")}
            IndividualIcon={() => (
              <HowToRegOutlinedIcon style={{ fontSize: 64 }} color={"info"} />
            )}
          />
          <div className={classes.grantedButton}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(routes.TNT_CALENDAR)}
              endIcon={<ArrowOutwardOutlinedIcon />}
            >
              {t("settings.calendar.consent.goToCalendar")}
            </Button>
          </div>
        </div>
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={() => requestNewScope()}
          disabled={consentGranted}
        >
          {consentButton}
        </Button>
      )}
    </div>
  );
};
