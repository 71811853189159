import React from "react";
// Helper
import { FormatterProps } from "react-data-grid";
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { PopulatedUser, PopulatedRole } from "@dyce/tnt-api";
// MUI
import { Chip } from "@mui/material";

/**
 * Filters the user list for IDs
 * @param ids Selected IDs
 * @param userList Available AAD user
 * @returns Unique Set of selected user
 */
export const filterUser = <T extends { id: string }>(
  ids: Set<React.Key>,
  userList: T[]
): Set<T> => {
  const result = new Set<T>();

  userList.map((user) => {
    if (ids.has(user.id)) result.add(user);
  });

  return result;
};

/**
 * Builder function for roles as chips
 * @param props DataGrid Props
 * @param availableRoles All roles that can be assigned
 */
export const createRolesView = (
  props: React.PropsWithChildren<FormatterProps<PopulatedUser, unknown>>,
  availableRoles: PopulatedRole[]
): JSX.Element => {
  return (
    <>
      {availableRoles.map((role, i) => (
        <Chip
          style={{ marginRight: 10 }}
          key={i}
          label={role.description}
          disabled={!props.row.roles.some((r) => r.id == role.id)}
          size="small"
        />
      ))}
    </>
  );
};

/**
 * JSX Element for an empty grid
 */
export const EmptyRow = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "80%",
      }}
    >
      <div>{t("admin.users.modal.noContent")}</div>
    </div>
  );
};
