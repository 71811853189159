import {
  AdminSettings,
  AadUser,
  ActionMeta,
  Client,
  CurrentUser,
  PopulatedRole,
  PopulatedUser,
  Resource,
  ResourceRequest,
  Role,
  ServerStatusResponse,
  TasksFilterStatus,
  User,
} from "@dyce/tnt-api";
import { AdminStateSlice, RootState } from "../../types/types";
import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  AnyAction,
  AsyncThunk,
} from "@reduxjs/toolkit";
import { pull } from "lodash";
import { tntApiHelper } from "../../apiHelper";
import { handleOldSettings } from "./utils";

export const getAllUsers: AsyncThunk<
  PopulatedUser[],
  { count?: number; sort?: string; page?: number; search?: string },
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  PopulatedUser[],
  { count?: number; sort?: string; page?: number; search?: string },
  { state: RootState; rejectValue: Error }
>(
  "admin/getAllUsers",
  async ({ count, sort, page, search }, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getAdminService();
      const expand = ["roles", "resource"];
      const filter = search
        ? {
            or: [
              { email: { contains: search } },
              { name: { contains: search } },
            ],
          }
        : undefined;
      const top = page && page > 1 ? 20 : count ? count : 40;
      const skip = page ? page * top : undefined;
      const orderBy = sort ? sort : `name ASC`;

      return (await client.users.getAll({
        expand,
        filter,
        top,
        orderBy,
        skip,
      })) as PopulatedUser[];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getAllAADUsers: AsyncThunk<
  AadUser[],
  { page?: number; search?: string },
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  AadUser[],
  { page?: number; search?: string },
  { state: RootState; rejectValue: Error }
>(
  "admin/getAadUsers",
  async ({ page, search }, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getAdminService();
      const expand = ["roles", "resource"];
      const filter = search
        ? { displayName: { startswith: search } }
        : undefined;

      return (await client.users.getAllAAD({
        expand,
        filter,
      })) as AadUser[];
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

// TODO: assure type (just )
export const getOneUser: AsyncThunk<
  PopulatedUser,
  string,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  PopulatedUser,
  string,
  { state: RootState; rejectValue: Error }
>("admin/getOneUser", async (id, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getAdminService();

    return (await client.users.getOne(id)) as PopulatedUser;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getCurrentUserAdmin: AsyncThunk<
  CurrentUser & { defaults: ResourceRequest[] },
  Record<string, unknown> | undefined,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  CurrentUser & { defaults: ResourceRequest[] },
  Record<string, unknown> | undefined,
  { state: RootState; rejectValue: Error }
>("admin/getCurrentUser", async (query, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getAdminService();

    return (await client.users.getCurrent(query)) as CurrentUser & {
      defaults: ResourceRequest[];
    };
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

// Creat User (From AAD to DYCE)
export const createUser: AsyncThunk<
  PopulatedUser,
  User,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  PopulatedUser,
  User,
  { state: RootState; rejectValue: Error }
>("admin/createDyceUser", async (user, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getAdminService();
    const expand = ["roles", "resource"];

    return (await client.users.create(user, { expand })) as PopulatedUser;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const setUserRoles: AsyncThunk<
  void,
  { userId: string; userRoles: Role[] },
  { state: RootState; rejectError: Error }
> = createAsyncThunk<
  void,
  { userId: string; userRoles: Role[] },
  { state: RootState; rejectError: Error }
>(
  "admin/setUserRoles",
  async ({ userId, userRoles }, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getAdminService();

      await client.users.setRoles(userId, userRoles);
      return void {};
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const updateUser: AsyncThunk<
  void,
  Partial<PopulatedUser> & Pick<User, "id">,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  void,
  Partial<PopulatedUser> & Pick<User, "id">,
  { state: RootState; rejectWithValue: Error }
>("admin/updateUser", async (data, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getAdminService();

    await client.users.update(data.id, data);
    return void {};
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const removeUser: AsyncThunk<
  void,
  string,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<void, string, { state: RootState; rejectValue: Error }>(
  "admin/removeUser",
  async (userId, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getAdminService();

      await client.users.remove(userId);
      return void {};
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getAllRoles: AsyncThunk<
  PopulatedRole[],
  Record<string, unknown> | undefined,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  PopulatedRole[],
  Record<string, unknown> | undefined,
  { state: RootState; rejectValue: Error }
>("admin/getUserRoles", async (query, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getAdminService();

    return (await client.roles.getAll()) as PopulatedRole[];
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getAllResources: AsyncThunk<
  Resource[],
  undefined,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  Resource[],
  undefined,
  { state: RootState; rejectValue: Error }
>("admin/getResources", async (_, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getResourceService();

    return await client.getAll();
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const getServerStatus: AsyncThunk<
  ServerStatusResponse,
  undefined,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  ServerStatusResponse,
  undefined,
  { state: RootState; rejectValue: Error }
>("admin/getServerStatus", async (_, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getAdminService();

    return await client.sync.getStatus();
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Clients
 */
export const getAllClients: AsyncThunk<
  Client[],
  undefined,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  Client[],
  undefined,
  { state: RootState; rejectValue: Error }
>("admin/getAllClients", async (_, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getAdminService();
    const expand = ["roles", "resource"];

    return await client.clients.getAll({ expand });
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const createClient: AsyncThunk<
  Client,
  Partial<Client>,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  Client,
  Partial<Client>,
  { state: RootState; rejectValue: Error }
>("admin/createClient", async (payload, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getAdminService();

    return await client.clients.create(payload);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateClient: AsyncThunk<
  Client,
  Client,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<Client, Client, { state: RootState; rejectValue: Error }>(
  "admin/updateClient",
  async (payload, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getAdminService();

      return await client.clients.update(payload, payload.id);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const removeClient: AsyncThunk<
  void,
  string,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<void, string, { state: RootState; rejectValue: Error }>(
  "admin/removeClient",
  async (id, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getAdminService();

      return await client.clients.remove(id);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getClientToken: AsyncThunk<
  string,
  string,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<string, string, { state: RootState; rejectValue: Error }>(
  "admin/getClientToken",
  async (id, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getAdminService();

      return await client.clients.getToken(id);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Settings
 */
export const getSettings: AsyncThunk<
  AdminSettings | undefined,
  void,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  AdminSettings | undefined,
  void,
  { state: RootState; rejectValue: Error }
>("admin/getSettings", async (_, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getAdminService();

    return await client.settings.get();
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const updateSettings: AsyncThunk<
  string,
  AdminSettings,
  { state: RootState; rejectValue: Error }
> = createAsyncThunk<
  string,
  AdminSettings,
  { state: RootState; rejectValue: Error }
>("admin/updateSettings", async (value, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getAdminService();

    return await client.settings.create(value);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

export const initialStateAdmin: AdminStateSlice = {
  requests: [],
  response: {},
  sync: {
    serverStatus: {
      status: {
        health: -1,
        lastHeartbeat: new Date(null as any).toISOString(),
      },
    },
  },
  users: {
    aadUsers: {},
    dyceUsers: {},
    currentUser: {},
    userById: {},
    userRoles: {},
    resources: [],
  },
  clients: {
    allClients: {},
    loadingClients: true,
  },
  settings: {
    tasks: {
      assignment: TasksFilterStatus.OPTIONAL,
    },
  },
};

const adminSlice = createSlice({
  name: "admin",
  initialState: initialStateAdmin as AdminStateSlice,
  reducers: {
    setResetAdminSlice(state) {
      // Sync
      state.sync = {
        serverStatus: {
          status: {
            health: -1,
            lastHeartbeat: new Date(null as any).toISOString(),
          },
        },
      };
      // Users
      state.users.aadUsers = {};
      state.users.dyceUsers = {};
      state.users.userRoles = {};
      state.users.resources = [];
      // Clients
      state.clients.allClients = {};
      state.clients.loadingClients = true;
      // Settings
      state.settings = {
        tasks: {
          assignment: TasksFilterStatus.OPTIONAL,
        },
      };
    },
    setClearAllUsers(state) {
      state.users.aadUsers = {};
      state.users.dyceUsers = {};
    },
    clearServerStatus(state) {
      state.sync.serverStatus.status = {};
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(updateUser.fulfilled, (state, action) => {
        state.users.dyceUsers[action.meta.arg.id] = {
          ...state.users.dyceUsers[action.meta.arg.id],
          ...action.meta.arg,
        } as any;
      })
      .addCase(setUserRoles.fulfilled, (state, action) => {
        state.users.dyceUsers[action.meta.arg.userId].roles =
          action.meta.arg.userRoles;
      })
      .addCase(removeUser.fulfilled, (state, action) => {
        delete state.users.dyceUsers[action.meta.arg];
      })
      .addCase(getAllAADUsers.fulfilled, (state, action) => {
        state.users.aadUsers = {};
        action.payload.forEach((rec) => (state.users.aadUsers[rec.id] = rec));
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        action.payload.forEach(
          (user) => (state.users.dyceUsers[user.id] = user)
        );
      })
      .addCase(getCurrentUserAdmin.fulfilled, (state, action) => {
        state.users.currentUser = action.payload;
      })
      .addCase(getAllResources.fulfilled, (state, action) => {
        state.users.resources = action.payload;
      })
      .addCase(getOneUser.fulfilled, (state, action) => {
        state.users.userById = action.payload;
      })
      .addCase(getAllRoles.fulfilled, (state, action) => {
        state.users.userRoles = {};
        action.payload.forEach((rec) => (state.users.userRoles[rec.id] = rec));
      })
      .addCase(getServerStatus.fulfilled, (state, action) => {
        state.sync.serverStatus = action.payload;
      })
      .addCase(getAllClients.fulfilled, (state, action) => {
        action.payload.forEach(
          (client) => (state.clients.allClients[client.id] = client)
        );
        state.clients.loadingClients = false;
      })
      .addCase(getAllClients.pending, (state) => {
        state.clients.loadingClients = true;
      })
      .addCase(getAllClients.rejected, (state) => {
        state.clients.loadingClients = false;
      })
      .addCase(removeClient.fulfilled, (state, action) => {
        delete state.clients.allClients[action.meta.arg];
      })
      .addCase(getSettings.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.settings = handleOldSettings(action.payload);
        }
      })
      .addCase(updateSettings.fulfilled, (state, action) => {
        if (action.payload !== undefined) {
          state.settings = action.meta.arg;
        }
      })
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("pending"),
        (state, action) => {
          state.requests.push(action.meta.requestId);
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.endsWith("fulfilled") || action.type.endsWith("rejected"),
        (state, action) => {
          pull(state.requests, action.meta.requestId);
        }
      ),
});

export const { clearServerStatus, setClearAllUsers, setResetAdminSlice } =
  adminSlice.actions;

export default adminSlice.reducer;
