import React, { FunctionComponent, useEffect, useState } from "react";
// Helper
import { DateTime } from "luxon";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { handleResourceMapping, handleRoleMapping } from "./utils";
// DYCE-Lib
import {
  createClient,
  getAllClients,
  selectLanguageCode,
  updateClient,
  useAppDispatch,
  useAppSelector,
} from "@dyce/slices";
import { AutoComplete, ChipSelect, DateFieldMoment, Dialog } from "@dyce/ui";
import { Client, PopulatedRole, Resource } from "@dyce/tnt-api";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Button, TextField } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      marginTop: "1rem",
      marginBottom: "1rem",
      width: 200,
      gap: "1rem",
    },
    clientsList: {
      marginTop: "2rem",
    },
    dialogSections: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      marginTop: "1rem",
      justifyContent: "center",
      alignItems: "center",
      gap: "1rem",
    },
    dateField: {
      width: "300px",
    },
  })
);

export type MappedRoles = { id: string; value: string };

interface ICreateClientProps {
  roles: PopulatedRole[];
  resources: Resource[];
  clientToEdit: Client | null;
  onClientEdited: () => void;
}

export const CreateClient: FunctionComponent<ICreateClientProps> = ({
  roles,
  resources,
  clientToEdit,
  onClientEdited,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // Selectors
  const languageCode = useAppSelector(selectLanguageCode);

  // States
  const [openAddClientDialog, setOpenAddClientDialog] =
    useState<boolean>(false);
  const [clientName, setClientName] = useState<string>("");
  const [expireDate, setExpireDate] = useState<string>("");
  const [allowSubmit, setAllowSubmit] = useState<boolean>(false);
  const [mappedResources, setMappedResources] = useState<string[] | null>(null);
  const [mappedRoles, setMappedRoles] = useState<MappedRoles[] | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<PopulatedRole[] | null>(
    null
  );
  const [selectedResource, setSelectedResource] = useState<Resource | null>(
    null
  );

  useEffect(() => {
    if (clientName.length > 0) {
      setAllowSubmit(true);
    } else {
      setAllowSubmit(false);
    }
  }, [clientName]);

  useEffect(() => {
    setMappedRoles(handleRoleMapping(roles));
  }, [roles]);

  useEffect(() => {
    setMappedResources(handleResourceMapping(resources));
  }, [resources]);

  useEffect(() => {
    // On edit, fill fields with information
    if (clientToEdit) {
      setOpenAddClientDialog(true);
      setClientName(clientToEdit.name);
      // Roles
      setSelectedRoles(clientToEdit.roles);
      setMappedRoles(handleRoleMapping(roles));
      // Resources
      setSelectedResource(clientToEdit.resource);
      setMappedResources(handleResourceMapping(resources));
      // ValidUntil
      setExpireDate(clientToEdit.validUntil ? clientToEdit.validUntil : "");
    }
  }, [clientToEdit]);

  // Handler
  const handleBlurClientName = (value: string) => {
    setClientName(value);
  };

  const handleChangeResource = (val: string) => {
    const findRes = resources.find((x) => x.name === val);
    if (findRes) {
      setSelectedResource(findRes);
    } else {
      setSelectedResource(null);
    }
  };

  const handleChangeRole = (val: MappedRoles[]) => {
    const foundRoles: PopulatedRole[] = [];

    roles.forEach((role) => {
      val.forEach((x) => {
        if (role.id === x.id) {
          foundRoles.push({
            id: x.id,
            description: role.description,
            type: role.type,
          });
        }
      });
    });
    setSelectedRoles(foundRoles.length > 0 ? foundRoles : null);
  };

  const handleCloseDialog = () => {
    setAllowSubmit(false);
    setClientName("");
    setExpireDate("");
    setOpenAddClientDialog(false);
    setSelectedResource(null);
    setSelectedRoles(null);
    onClientEdited();
  };

  const handleSubmitClient = () => {
    if (selectedResource) {
      const newClient: Partial<Client> = {
        name: clientName,
        roles: selectedRoles ? selectedRoles : [],
        resource: selectedResource,
        validUntil: expireDate.length > 0 ? expireDate : undefined,
      };
      if (clientToEdit) {
        // Edit existing client
        const existingClient: Client = {
          ...(newClient as Client),
          id: clientToEdit.id,
        };
        dispatch(updateClient(existingClient))
          .then(() => dispatch(getAllClients()))
          .catch((e: any) => console.warn(e));
      } else {
        // Create new client
        dispatch(createClient(newClient))
          .then(() => dispatch(getAllClients()))
          .catch((e: any) => console.warn(e));
      }
    }
    setOpenAddClientDialog(false);
    onClientEdited();
  };

  return (
    <>
      <div className={classes.content}>
        <Button
          variant="contained"
          onClick={() => setOpenAddClientDialog(true)}
        >
          {t("admin.clients.add")}
        </Button>
      </div>

      <Dialog
        open={openAddClientDialog}
        size={"sm"}
        actionButtonLabels={{
          confirm: clientToEdit
            ? t("admin.clients.dialog.save")
            : t("admin.clients.dialog.create"),
        }}
        isDisabled={!allowSubmit}
        onCancel={handleCloseDialog}
        onSubmit={handleSubmitClient}
        title={t("admin.clients.dialog.addClient")}
      >
        <div className={classes.dialogSections}>
          <TextField
            label={t("admin.clients.dialog.form.clientName")}
            InputLabelProps={{
              shrink: true,
            }}
            required
            value={clientName}
            autoFocus
            sx={{ width: 300 }}
            onChange={(e) => setClientName(e.target.value)}
            onBlur={(e) => handleBlurClientName(e.target.value)}
          />
          <AutoComplete
            value={selectedResource ? selectedResource.name : ""}
            values={mappedResources ? mappedResources : []}
            label={t("admin.clients.dialog.form.clientResource")}
            getTitle={(val) => val}
            onChange={handleChangeResource}
            onBlur={(val) => {
              console.log(val);
            }}
          />
          <ChipSelect
            value={handleRoleMapping(selectedRoles ? selectedRoles : [])}
            values={mappedRoles ? mappedRoles : []}
            label={t("admin.clients.dialog.form.clientRole")}
            onBlur={() => {}}
            onChange={handleChangeRole}
          />
          <DateFieldMoment
            style={classes.dateField}
            data-testid="date-field"
            onChange={(val) => {
              if (DateTime.fromISO(val).isValid) {
                setExpireDate(val);
              } else {
                if (val.length > 0) {
                  setExpireDate("Error");
                } else {
                  setExpireDate("");
                }
              }
            }}
            onBlur={() => {}}
            isEnglish={languageCode === "en"}
            // Temporarily needed, till MUI fixes 'LocalizationProvider' for DateTime lib.
            localeHyphen={moment.locale()}
            value={expireDate}
            label={
              expireDate === "Error"
                ? expireDate
                : t("admin.clients.dialog.form.expireDate")
            }
            variant={"outlined"}
            allowEmptyField={true}
          />
        </div>
      </Dialog>
    </>
  );
};
