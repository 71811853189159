export enum InstanceStatus {
  UPGRADING = 426,
  UNAVAILABLE = 503,
}

export type InstanceStateType = {
  Initial: number;
  UpdatedFromBC: number;
  Enqueued: number;
  DbStart: number;
  InstanceCreated: number;
  CompanyStart: number;
  CompanyCreated: number;
  AsbStart: number;
  AsbFinished: number;
  CompanyDone: number;
  Done: number;
  Conflict: number;
  Error: number;
};

// Single AAD user. Don't confuse with User
export type AadUser = {
  displayName: string;
  id: string;
  isDyceUser: boolean;
  mail: string;
};

export class ResponseError extends Error {
  override name: string;
  override message: string;
  response?: Response;
  code?: number;
  constructor(message: string) {
    super();
    this.name = "Response Error";
    this.message = message;
  }
}

export class CreateReturnTimerec {
  id: string | null | undefined;
  date?: string = "";
  description?: string | null | undefined = null;
  duration?: number | null = null;
  start = "";
  end = "";
  break?: number = 0;
  workType?: Record<string, any> | null = null;
  customer?: Record<string, any> | null = null;
  job?: Record<string, any> | null = null;
  jobTask?: Record<string, any> | null = null;
  jobPlanningLine?: Record<string, any> | null = null;
  status?: StatusOptions;
  constructor(args: RecordTimeRec) {
    Object.assign(this, args);
  }
}

export class CreateReturnTemplate {
  id: string | null | undefined;
  description?: string | null | undefined = null;
  duration?: number | null = null;
  start = "";
  end = "";
  break?: number = 0;
  customer?: Record<string, any> | null = null;
  job?: Record<string, any> | null = null;
  jobTask?: Record<string, any> | null = null;
  jobPlanningLine?: Record<string, any> | null = null;
  constructor(args: RecordTemplate) {
    Object.assign(this, args);
  }
}

export type ActionMeta = {
  requestId: string;
};

export type ODataArrayResponse<T> = {
  "@odata.context": string;
  "@odata.count"?: number;
  value: T;
};

export type ODataResponse<T> = T & {
  "@odata.context": string;
};

export type ODataBatchRequest = {
  /**
   * The id of the request. This is used to match the response to the request.
   * @default index (e.g. "0")
   */
  id?: string;
  /**
   * The HTTP method of the request.
   * @default "GET"
   */
  method?: "GET" | "POST" | "PATCH" | "DELETE";
  /**
   * The URL of the request, e.g. "/Customers".
   */
  url: string;
  /**
   * The body of the request. (Patch and Post only)
   */
  body?: Record<string, any>;
};

export type ODataBatchResponses<T> = {
  responses: ODataBatchResponse<T>[];
};

export type ODataBatchResponse<T> = PopulatedBatchResponse & {
  body:
    | (T & { "@odata.context": string })
    | {
        "@odata.context": string;
        value: T;
      };
};

export type BatchResponses<T> = {
  responses: BatchResponse<T>[];
};

export type BatchResponse<T> = PopulatedBatchResponse & {
  body: T;
};

type PopulatedBatchResponse = {
  headers: {
    "content-type": string;
    "odata-version": string;
  };
  id: string;
  status: number;
};

export type RecordEntry = {
  id?: string;
  start: string | null;
  end: string | null;
  duration: number;
  break: number;
  description: string;
  customer: Customer | null;
  job: PopulatedJob | null;
  jobTask: PopulatedJobTask | null;
  jobPlanningLine: PopulatedJobPlanningLine | null;
  tntModelLine: TntModelLine | null;
  activity?: Activity;
  durationBillable: number;
  nonBillableReason: NonBillableReasonOptions;
  resource?: Resource;
  hidden?: string | null;
};

export enum NonBillableReasonOptions {
  NONE = "None",
  INTERNAL = "Internal",
  WARRANTY = "Warranty",
  GOODWILL = "GoodWill",
}

// Single User Without Resources and Roles
export type User = {
  email: string;
  id: string;
  name: string;
};

// Extend User with resources and roles
export type PopulatedUser = User & {
  resource: Resource | null;
  roles: Role[] | PopulatedRole[];
};

// Single role
export type Role = {
  id: string;
};

// Extended Role with metadata
export type PopulatedRole = Role & {
  description: string;
  type: string;
};

// Clients
export type Client = {
  id: string;
  name: string;
  roles: PopulatedRole[];
  resource: Resource;
  validUntil?: string;
  lastSeen?: string;
};

// Single part of Health Response
export interface IDataElement {
  timeStamp: string;
  value: number;
}

// Server response for Status Requests
export interface ServerStatusResponse {
  status: {
    health: number;
    latency: number;
    lastHeartbeat: string;
    processingErrorCount: number;
  };
  receiveStats: {
    total: number;
    from: string;
    to: string;
    details: IDataElement[];
  };
  sendStats: {
    total: number;
    from: string;
    to: string;
    details: IDataElement[];
  };
}

// Get Server Response
export type GetResponse<T> = {
  data: ODataArrayResponse<T>;
  pagination: string;
  requestUrl: string;
};

export type Customer = {
  id: string;
  no: string | null;
  name: string | null;
  address?: {
    id: string;
    address1: string | null;
    address2: string | null;
    city: string | null;
    state: string | null;
    postCode: string | null;
    country: string | null;
  };
  jobs?: any[] | null;
};

export type Job = {
  id: string;
  description: string | null;
  no: string | null;
};

export type PopulatedJob = Job & {
  customer?: Customer | null;
};

export type JobWithCustomer = Job & {
  customer: Customer;
};

export type JobTask = {
  id: string;
  description: string | null;
  no: string | null;
  status: JobTaskStatusOptions;
};

export enum JobTaskStatusOptions {
  OPEN = "Open",
  LOCKED = "Time recording locked",
  CLOSED = "Closed",
}

export type PopulatedJobTask = JobTask & {
  jobId?: string | null;
  job: Job | null;
  jobs?: any[] | null;
  jobPlanningLine: JobPlanningLine | null;
};

export type JobPlanningLine = {
  id: string;
  description: string | null;
  serviceBillingType: string | null;
  lineNo?: number;
  jobTaskId?: string;
  jobNo?: string | null;
  taskNo?: string | null;
};

export type PopulatedJobPlanningLine = JobPlanningLine & {
  tntModelLine?: TntModelLine;
  jobTask?: PopulatedJobTask;
};

export type Resource = {
  id: string;
  no: string;
  name: string;
  defaults?: ResourceRequest[];
};

export type WeekLoad = {
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
};

export type ResourceCapacity = {
  periodStart: string;
  capacity: WeekLoad;
};

export type ResourceRequest = {
  id: string;
  validFrom: string;
  calendar?: {
    code: string;
    description: string;
    entries: CalendarEntry[];
  };
  workHourTemplate?: WorkTimes & {
    code: string;
    description: string;
  };
};

export type WorkTimes = Record<Days, number>;

export type Days =
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday"
  | "sunday";

export type CalendarEntry = {
  id: string;
  date: string;
  description: string;
  recurrent: boolean;
  isWorking: boolean;
};

export type TempResourceOutput = {
  id: string;
  externalId: string;
  name: string;
  email: string;
  resource: {
    id: string;
    no: string;
    name: string;
    defaults: ResourceRequest[];
  };
};

/**
 * Calendar Entries
 */
export type CalendarEntries = {
  id: string | null;
  description: string;
  start: string;
  end: string;
  isAllDay: boolean;
  status: CalendarStatus;
  sensitivity: CalendarSensitivity;
  type: CalendarType;
  timerecordingId: string | null;
};

export enum CalendarStatus {
  FREE = 0, // orange
  TENTATIVE = 1, // gray-violet
  ABSENT = 2, // gray
  BUSY = 3, // violet
  OUT_OF_OFFICE = 4, // cyan
}

export enum CalendarSensitivity {
  NORMAL = 0,
  PERSONAL = 1,
  PRIVATE = 2,
  CONFIDENTIAL = 3,
}

export enum CalendarType {
  NORMAL = 0,
  RECURRING = 1,
}

export type TemplateCategory = {
  name: string;
  id: string;
  resource: string;
};

export type CategoriesWithTemplates = { [key: string]: RecordTemplate[] };

export type RecordTemplate = RecordEntry & {
  id: string;
  category: TemplateCategory;
};

export type PeriodTime = {
  date: string;
  duration: number;
  durationBillable: number;
  break: number;
  capacity: number;
};

export type Statistics = {
  billableTotals: BillableTotal[];
  from: string;
  activityTotals: JobTotal[];
  jobTotals: JobTotal[];
  to: string;
};

export type BillableTotal = {
  nonBillableReason: string;
  totalDuration: number;
  totalDurationBillable: number;
};

export type ActivityTotal = {
  description: string;
  totalDuration: number;
};

export type JobTotal = ActivityTotal & {
  id: string;
  no: string;
};

export type StatisticsPiePeriod = {
  billableTotals: PopulatedBillableTotal[];
  from: string;
  activityTotals: ActivityTotal[];
  jobTotals: JobTotal[];
  to: string;
};

export type PopulatedBillableTotal = BillableTotal & {
  description: string;
};

export type PopulatedJobTotal = JobTotal & {
  percentage: string;
};

export type RecordTimeRec = RecordEntry & {
  id: string;
  date: string;
  complete: boolean;
  created: string;
  modified: string;
  status: StatusOptions;
  calendarEventId: string | null;
  workItem?: WorkItemType | null;
};

export type WorkItemType = {
  id: string;
  source: ForeignSource;
  organization: string;
  project: string;
  title: string;
  status: string | null;
};

export enum ForeignSource {
  AZUREDEVOPS = "AzureDevOps",
  JIRA = "JIRA",
}

export enum StatusOptions {
  OPEN = "Open",
  RELEASED = "Released",
  POSTED = "Posted",
}

export enum ServiceBillingTypeOptions {
  NONE = "None",
  TIME_MATERIAL = "TimeMaterial",
  BUDGET = "Budget",
  FIXED_PRICE = "FixedPrice",
  MULTIPLE = "Multiple",
}

export type BudgetValues = {
  total: number;
  used: number;
};

export type StopwatchStart = {
  start: string;
  description: string;
};

export type Stopwatch = StopwatchStart & {
  userId: string;
  running: boolean;
};

export type ValidatedRecord = {
  ruleSetsExecuted: any[];
  errors: ValidatedErrorKeys[];
};

type ValidatedErrorKeys = {
  propertyName: string;
  errorMessage: string;
  severity: string;
  errorCode: string;
  PropertyName: string;
  PropertyValue: string;
};

export type TntModelLine = {
  id: string;
  description: string | null;
  billable: boolean;
  itemNo?: string | null;
  nonBillableReason: NonBillableReasonOptions;
  rounding: number;
};

export type Activity = {
  jobPlanningLine: PopulatedJobPlanningLine;
  jobTask: JobTask;
  tntModelLine: TntModelLine;
  no: number;
};

export type CurrentUser = {
  id: string;
  name: string;
  email: string;
  resource: Resource | null;
  roles: PopulatedRole[];
  stopwatch: Stopwatch | null;
};

export type Instance = {
  id: string;
  tenantId: string;
  name: string;
  companies: Company[];
};

export type Company = {
  id: string;
  name: string;
};

export interface JobViewEntry {
  assigned?: boolean;
  customer?: Customer;
  job?: Job;
  jobTask?: JobTask;
  jobPlanningLine?: JobPlanningLine;
  id: string;
  plannedStartDate?: string | null;
  plannedEndDate?: string | null;
  resource?: Resource;
}

export type AdminSettings = {
  tasks: {
    assignment: TasksFilterStatus;
  };
};

export enum TasksFilterStatus {
  NONE = "None",
  OPTIONAL = "Optional",
  REQUIRED = "Required",
}
