import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  AsyncThunk,
} from "@reduxjs/toolkit";
import { Expand } from "odata-query";
import { pull } from "lodash";
import { DateTime } from "luxon";
import { getCurrentUser } from "../user/userSlice";
import { isComplete } from "./util";
import {
  ActionMeta,
  ODataArrayResponse,
  RecordTemplate,
  RecordTimeRec,
  StatusOptions,
  Stopwatch,
  ValidatedRecord,
  PeriodTime,
  CreateReturnTimerec,
  Statistics,
  BudgetValues,
} from "@dyce/tnt-api";
import { tntApiHelper } from "../../apiHelper";
import { RootState, TimerecStateSlice } from "../../types/types";

const expand: Expand<Record<string, any>> = {
  customer: { select: ["id", "no", "name"] },
  job: { select: ["id", "no", "description"] },
  jobTask: { select: ["id", "description", "no", "status"] },
  jobPlanningLine: { select: ["id", "description", "serviceBillingType"] },
  tntModelLine: {
    select: ["id", "description", "billable", "nonBillableReason", "rounding"],
  },
  resource: { select: ["id", "name"] },
  workItem: {
    select: ["id", "source", "organization", "project", "title", "status"],
  },
};

/**
 * Create a new timerecording
 */
export const createRec: AsyncThunk<
  RecordTimeRec,
  RecordTimeRec,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  RecordTimeRec,
  RecordTimeRec,
  { state: RootState; rejectWithValue: Error }
>("recs/createRecStatus", async (rec, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getRecService();

    return await client.create(rec);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Fetch one time recording by ID
 */
export const getRecord: AsyncThunk<
  RecordTimeRec,
  string,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  RecordTimeRec,
  string,
  { state: RootState; rejectWithValue: Error }
>("recs/fetchRecStatus", async (id, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getRecService();

    const query = { expand };
    return (await client.getOne(id, query)) as RecordTimeRec;
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Update a time recording
 */
export const updateRec: AsyncThunk<
  Partial<RecordTimeRec>,
  RecordTimeRec,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  Partial<RecordTimeRec>,
  RecordTimeRec,
  { state: RootState; rejectWithValue: Error }
>("recs/updateRecStatus", async (rec, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getRecService();

    return await client.update(rec.id, rec);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Deletes a time recording
 */
export const removeRec: AsyncThunk<
  void,
  string,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  void,
  string,
  { state: RootState; rejectWithValue: Error }
>("recs/deleteRecStatus", async (id, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getRecService();

    return await client.remove(id);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Gets items for foreign app, e.g. DevOps or JIRA
 */
export const getRecsByWorkItem: AsyncThunk<
  ODataArrayResponse<RecordTimeRec[]>,
  [
    {
      /**
       * Provide source, e.g.: 'DevOps' or 'JIRA'
       */
      source: string;
      /**
       * Current organization of foreign app
       */
      organization: string;
      /**
       * Current project of foreign app
       */
      project: string;
      /**
       * Current task-/bugId of foreign app
       */
      taskId: string;
    },
    { filterIncomplete: boolean; topCount?: number },
  ],
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  ODataArrayResponse<RecordTimeRec[]>,
  [
    {
      source: string;
      organization: string;
      project: string;
      taskId: string;
    },
    { filterIncomplete: boolean; topCount?: number },
  ],
  { state: RootState; rejectWithValue: Error }
>(
  "recs/getDevOpsRecs",
  async ([foreignProps, filterProps], { getState, rejectWithValue }) => {
    try {
      const client = await (
        await tntApiHelper(getState, { withOdataContext: true })
      ).getRecService();
      const expand = [
        "resource",
        "customer",
        "job",
        "jobTask",
        "jobPlanningLine",
        "tntModelLine",
        "workItem",
      ];
      let count = false;
      let filter = {};
      let top: number | undefined = undefined;

      if (filterProps.filterIncomplete) {
        filter = { complete: false };
        count = true;
        if (filterProps.topCount) {
          top = filterProps.topCount;
        }
      }

      return await client.getForeignTimeRecs(foreignProps, {
        expand,
        count,
        filter,
        top,
      });
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Fetches all incomplete time records
 */
export const getIncompleteRecs: AsyncThunk<
  ODataArrayResponse<RecordTimeRec[]>,
  { top: number; skip: number },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  ODataArrayResponse<RecordTimeRec[]>,
  { top: number; skip: number },
  { state: RootState; rejectWithValue: Error }
>(
  "recs/getIncomplete",
  async ({ top, skip }, { getState, rejectWithValue }) => {
    try {
      const client = await (
        await tntApiHelper(getState, { withOdataContext: true })
      ).getRecService();

      const filter = { complete: false };

      const count = true;

      const orderBy: any = ["date desc"];

      return await client.getAll({
        filter,
        expand,
        count,
        top,
        skip,
        orderBy,
      });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        user: {
          currentUser: { resource },
        },
      } = getState();

      if (resource === undefined || resource === null) {
        return false;
      } else {
        return undefined;
      }
    },
  }
);

/**
 * Gets all entries in a week
 */
export const getRecsByWeek: AsyncThunk<
  ODataArrayResponse<RecordTimeRec[]>,
  {
    start: Date;
  },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  ODataArrayResponse<RecordTimeRec[]>,
  {
    start: Date;
  },
  { state: RootState; rejectWithValue: Error }
>(
  "recs/getByDate",
  async ({ start }, { getState, rejectWithValue }) => {
    try {
      const client = await (
        await tntApiHelper(getState, { withOdataContext: true })
      ).getRecService();

      const lastDay = new Date(
        DateTime.fromJSDate(start).endOf("week").toJSDate()
      );
      const firstDay = new Date(
        DateTime.fromJSDate(start).startOf("week").toJSDate()
      );

      const filter = { date: { le: lastDay, ge: firstDay } };

      const orderBy: any = ["date desc"];

      return await client.getAll({ expand, filter, orderBy });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        user: {
          currentUser: { resource },
        },
      } = getState();

      if (resource === undefined || resource === null) {
        return false;
      } else {
        return undefined;
      }
    },
  }
);

export const getRecsByRange: AsyncThunk<
  ODataArrayResponse<RecordTimeRec[]>,
  {
    start: string;
    end: string;
  },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  ODataArrayResponse<RecordTimeRec[]>,
  {
    start: string;
    end: string;
  },
  { state: RootState; rejectWithValue: Error }
>(
  "recs/getByRange",
  async ({ start, end }, { getState, rejectWithValue }) => {
    try {
      const client = await (
        await tntApiHelper(getState, { withOdataContext: true })
      ).getRecService();

      const filter = {
        date: {
          le: DateTime.fromISO(end).endOf("day").toUTC().toJSDate(),
          ge: DateTime.fromISO(start).startOf("day").toUTC().toJSDate(),
        },
      };

      const orderBy: any = ["date desc"];

      return await client.getAll({ expand, filter, orderBy });
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        user: {
          currentUser: { resource },
        },
      } = getState();

      if (resource === undefined || resource === null) {
        return false;
      } else {
        return undefined;
      }
    },
  }
);

/********
 * BUDGET
 ********/

export const getBudget: AsyncThunk<
  BudgetValues,
  {
    budgetOf?: "jobTaskId" | "jobPlanningLineId";
    id: string;
  },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  BudgetValues,
  {
    budgetOf?: "jobTaskId" | "jobPlanningLineId";
    id: string;
  },
  { state: RootState; rejectWithValue: Error }
>("recs/getBudget", async ({ budgetOf, id }, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getRecService();

    return await client.getBudget(budgetOf ? budgetOf : "jobTaskId", id);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/********
 * STOPWATCH
 ********/

/**
 * Get current stopwatch
 */
export const getStopwatch: AsyncThunk<
  Stopwatch,
  undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  Stopwatch,
  undefined,
  { state: RootState; rejectWithValue: Error }
>(
  "stopwatch/get",
  async (_, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getRecService();

      return await client.stopwatch.get();
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        user: {
          currentUser: { resource },
        },
      } = getState();

      if (resource === undefined || resource === null) {
        return false;
      } else {
        return undefined;
      }
    },
  }
);

/**
 * Start a new stopwatch
 */
export const startStopwatch: AsyncThunk<
  void,
  {
    start: string;
    description: string;
  },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  void,
  {
    start: string;
    description: string;
  },
  { state: RootState; rejectWithValue: Error }
>(
  "stopwatch/start",
  async ({ start, description }, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getRecService();

      return await client.stopwatch.start(start, description);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Stop a running stopwatch
 */
export const stopStopwatch: AsyncThunk<
  void,
  void,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<void, void, { state: RootState; rejectWithValue: Error }>(
  "stopwatch/stop",
  async (_, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getRecService();

      return await client.stopwatch.stop(getState().user.currentUser.id);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Fetch summary of dashboard
 */
export const getDashboardSummary: AsyncThunk<
  {
    periodTime: PeriodTime[];
    statistics: Statistics | null;
  },
  {
    to: string;
    from: string;
    unit: "day" | "week" | "month";
  },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  {
    periodTime: PeriodTime[];
    statistics: Statistics | null;
  },
  {
    to: string;
    from: string;
    unit: "day" | "week" | "month";
  },
  { state: RootState; rejectWithValue: Error }
>(
  "recs/getSummary",
  async ({ from, to, unit }, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getBatchService();
      const statistics: {
        from: string;
        to: string;
      } = {
        from: from,
        to: to,
      };

      // If unit is week (month-view), we need to adjust the 'from'-date
      // and the 'to'-date to borders of the month for statistics (first and last)
      if (unit === "week") {
        if (DateTime.fromISO(from).day !== 1) {
          statistics.from = DateTime.fromISO(from)
            .endOf("month")
            .plus({ millisecond: 1 })
            .toISO();
        }
        statistics.to = DateTime.fromISO(to).startOf("month").toISO();
      }

      const response = await client.batch<PeriodTime[] | Statistics>([
        {
          url: `/timerecordings/GetSummary(from=${from},to=${to},unit='${unit}')`,
        },
        {
          url: `/timerecordings/GetStatistics(from=${statistics.from},to=${statistics.to})`,
        },
      ]);

      const periodTimeData = Array.isArray(response.responses[0].body)
        ? response.responses[0].body
        : [];
      const statisticsData = Array.isArray(response.responses[1].body)
        ? null
        : response.responses[1].body;

      const result = {
        periodTime: periodTimeData,
        statistics: statisticsData,
      };

      return result;
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        user: {
          currentUser: { resource },
        },
      } = getState();

      if (resource === undefined || resource === null) {
        return false;
      } else {
        return undefined;
      }
    },
  }
);

export const getTimeStatistics: AsyncThunk<
  Statistics,
  {
    startTime: string;
    endTime: string;
  },
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  Statistics,
  {
    startTime: string;
    endTime: string;
  },
  { state: RootState; rejectWithValue: Error }
>(
  "recs/getStatistics",
  async ({ startTime, endTime }, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getRecService();

      return await client.statistics.get(startTime, endTime);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

/**
 * Validates a time recording
 */
export const validateRec: AsyncThunk<
  ValidatedRecord,
  RecordTemplate,
  { state: RootState; rejectWithError: Error }
> = createAsyncThunk<
  ValidatedRecord,
  RecordTemplate,
  { state: RootState; rejectWithError: Error }
>(
  "recs/validateRecStatus",
  async (rec, { getState, rejectWithValue }) => {
    try {
      const client = await (await tntApiHelper(getState)).getRecService();

      return await client.validate(rec);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
  {
    condition: (_, { getState }) => {
      const {
        user: {
          currentUser: { resource },
        },
      } = getState();

      if (resource === undefined || resource === null) {
        return false;
      } else {
        return undefined;
      }
    },
  }
);

const initialState: TimerecStateSlice = {
  entries: {},
  requests: [],
  periodTimes: [],
  periodStatistics: null,
  loaded: "",
  incompleteEntries: {
    count: 0,
    entries: {},
  },
  stopwatch: null,
  stopwatchLoading: false,
  openEditor: false,
  errors: {},
  entriesLookUp: {},
  sorting: null,
  errorOnUpdate: null,
  isLoadingEntries: true,
  latestCreatedId: null,
  latestCreatedEndTime: null,
  addDateToSelector: null,
};

const recsSlice = createSlice({
  name: "recs",
  initialState: initialState,
  reducers: {
    removeEntries(state) {
      state.entries = {};
    },
    removeIncompleteEntries(
      state,
      action: PayloadAction<{ removeAll: boolean }>
    ) {
      if (action.payload.removeAll) {
        state.incompleteEntries = {
          count: 0,
          entries: {},
        };
      } else {
        state.incompleteEntries = {
          count: state.incompleteEntries.count,
          entries: {},
        };
      }
    },
    setDateForSelector(state, action: PayloadAction<string | null>) {
      state.addDateToSelector = action.payload;
    },
    setOpenEditor(state, action) {
      state.openEditor = action.payload;
    },
    clearLatestCreatedId(state) {
      state.latestCreatedId = null;
    },
    setLatestEndTime(state, action: PayloadAction<string>) {
      state.latestCreatedEndTime = action.payload;
    },
    setClearErrorOnUpdate(state, action: PayloadAction<string | undefined>) {
      if (action.payload) {
        delete state.entries[action.payload];
        if (state.incompleteEntries.entries[action.payload]) {
          delete state.incompleteEntries.entries[action.payload];
          state.incompleteEntries.count -= 1;
        }
      }
      state.errorOnUpdate = null;
    },
    setClearPeriodTimes(state) {
      state.periodTimes = [];
    },
    setClearPeriodStatistics(state) {
      state.periodStatistics = null;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(createRec.fulfilled, (state, action) => {
        const entry = {
          ...new CreateReturnTimerec({
            ...action.meta.arg,
            ...action.payload,
          }),
          created: DateTime.now().toISO(),
        } as unknown as RecordTimeRec;
        delete (entry as any)["@odata.context"];

        state.entries[action.payload.id] = entry;
        if (!action.payload.complete) {
          state.incompleteEntries.entries[action.payload.id] = entry;
          state.incompleteEntries.count += 1;
        }

        state.latestCreatedId = entry.id;
        state.addDateToSelector = null;
      })
      .addCase(removeRec.fulfilled, (state, action) => {
        const entryToDelete = state.entries[action.meta.arg];

        // If latestCreatedEndTime is from deleted item, clear value
        if (state.latestCreatedEndTime && entryToDelete && entryToDelete.end) {
          if (
            DateTime.fromISO(state.latestCreatedEndTime).toMillis() ===
            DateTime.fromISO(entryToDelete.end).toMillis()
          ) {
            state.latestCreatedEndTime = null;
          }
        }

        // If latestCreatedId is from deleted item, clear value
        if (state.latestCreatedId && entryToDelete && entryToDelete.id) {
          if (state.latestCreatedId === entryToDelete.id) {
            state.latestCreatedId = null;
          }
        }

        if (state.incompleteEntries.entries[action.meta.arg]) {
          delete state.incompleteEntries.entries[action.meta.arg];
          state.incompleteEntries.count -= 1;
        } else if (!isComplete(state.entries[action.meta.arg])) {
          state.incompleteEntries.count -= 1;
        }

        delete state.entries[action.meta.arg];
      })
      .addCase(getRecsByWeek.pending, (state) => {
        state.isLoadingEntries = true;
      })
      .addCase(getRecsByWeek.fulfilled, (state, action) => {
        state.isLoadingEntries = false;
        state.entries = {};
        action.payload.value.forEach((e) => (state.entries[e.id] = e));
        state.loaded = DateTime.fromJSDate(action.meta.arg.start).toISO();
      })
      .addCase(getRecsByWorkItem.pending, (state) => {
        state.isLoadingEntries = true;
      })
      .addCase(getRecsByWorkItem.fulfilled, (state, action) => {
        state.isLoadingEntries = false;
        // If call is for incomplete recs save to incomplete with count
        if (action.meta.arg[1].filterIncomplete === true) {
          state.incompleteEntries.entries = {};
          action.payload.value.forEach(
            (e) => (state.incompleteEntries.entries[e.id] = e)
          );
          if (action.payload["@odata.count"] !== undefined) {
            state.incompleteEntries.count = action.payload["@odata.count"];
          }
        } else {
          // Else save to entries
          state.entries = {};
          action.payload.value.forEach((e) => (state.entries[e.id] = e));
        }
      })
      .addCase(getRecord.fulfilled, (state, action) => {
        state.entries[action.payload.id] = action.payload;
        // Safe the new status from entry to can compare with old one
        state.errorOnUpdate = state.errorOnUpdate
          ? {
              ...state.errorOnUpdate,
              newStatus: action.payload.status,
            }
          : null;
      })
      .addCase(updateRec.fulfilled, (state, action) => {
        state.entries[action.meta.arg.id as string] = {
          ...new CreateReturnTimerec(action.meta.arg),
        } as RecordTimeRec;
        if (state.entries[action.meta.arg.id as string].status === undefined) {
          state.entries[action.meta.arg.id as string].status =
            StatusOptions.OPEN;
        }
        const entry = Object.keys(state.incompleteEntries.entries).find(
          (x) => x === action.meta.arg.id
        );

        if (
          action.meta.arg.id &&
          isComplete(action.meta.arg as RecordTimeRec)
        ) {
          // Timerecording was filled with all neccessary credentials
          if (entry !== undefined) {
            delete state.incompleteEntries.entries[action.meta.arg.id];
            state.incompleteEntries.count -= 1;
          }

          state.entries[action.meta.arg.id].complete = true;
        } else if (
          action.meta.arg.id &&
          !isComplete(action.meta.arg as RecordTimeRec)
        ) {
          // Timerecording has still missing neccessary credentials
          if (entry !== undefined) {
            state.incompleteEntries.entries[action.meta.arg.id] = {
              ...action.meta.arg,
              status: StatusOptions.OPEN,
            };
          }
        }
      })
      .addCase(updateRec.rejected, (state, action) => {
        // If update fails with "Rejected", save status of payload
        if ((action as any).payload.code !== 403) {
          state.errorOnUpdate = {
            id: action.meta.arg.id,
            status: action.meta.arg.status,
            newStatus: null,
            unknownIssue: true,
            deleted: false,
          };
        }
      })
      .addCase(getRecord.rejected, (state, action) => {
        // If update fails with "404 Not Found", save status of payload
        if (
          (action as any).payload.code === 404 &&
          state.errorOnUpdate !== null
        ) {
          state.errorOnUpdate = {
            ...state.errorOnUpdate,
            deleted: true,
          };
          delete state.entries[action.meta.arg];
        } else {
          state.errorOnUpdate = null;
        }
      })
      .addCase(removeRec.rejected, (state, action) => {
        if ((action as any).payload.code === 400) {
          state.errorOnUpdate = {
            id: action.meta.arg,
            status: StatusOptions.OPEN,
            newStatus: null,
            unknownIssue: false,
            deleted: true,
          };
          // delete state.entries[action.meta.arg];
        } else {
          state.errorOnUpdate = null;
        }
      })
      .addCase(getIncompleteRecs.fulfilled, (state, action) => {
        // state.incompleteEntries.entries = {};

        action.payload.value.forEach(
          (r) => (state.incompleteEntries.entries[r.id] = r)
        );
        if (action.payload["@odata.count"] !== undefined) {
          state.incompleteEntries.count = action.payload["@odata.count"];
        }
      })
      .addCase(getStopwatch.fulfilled, (state, action) => {
        state.stopwatch = action.payload;
      })
      .addCase(startStopwatch.pending, (state) => {
        state.stopwatchLoading = true;
      })
      .addCase(startStopwatch.fulfilled, (state, action) => {
        state.stopwatch = {
          start: action.meta.arg.start,
          description: action.meta.arg.description,
          running: true,
          userId: "",
        };

        state.stopwatchLoading = false;
      })
      .addCase(startStopwatch.rejected, (state) => {
        state.stopwatchLoading = false;
      })
      .addCase(stopStopwatch.fulfilled, (state) => {
        state.stopwatch = null;
        state.stopwatchLoading = false;
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.stopwatch = action.payload.stopwatch;
      })
      .addCase(getDashboardSummary.fulfilled, (state, action) => {
        state.periodTimes = action.payload.periodTime;
        state.periodStatistics = action.payload.statistics;
      })
      .addCase(getTimeStatistics.fulfilled, (state, action) => {
        state.periodStatistics = action.payload;
      })
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.startsWith("recs") &&
          !action.type.includes("getBudget") &&
          action.type.endsWith("pending"),
        (state, action) => {
          state.requests.push(action.meta.requestId);
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          (action.type.startsWith("recs") &&
            action.type.endsWith("fulfilled")) ||
          (action.type.startsWith("recs") && action.type.endsWith("rejected")),
        (state, action) => {
          pull(state.requests, action.meta.requestId);
        }
      ),
});

export const {
  removeEntries,
  removeIncompleteEntries,
  setOpenEditor,
  clearLatestCreatedId,
  setLatestEndTime,
  setClearErrorOnUpdate,
  setClearPeriodTimes,
  setClearPeriodStatistics,
  setDateForSelector,
} = recsSlice.actions;

export default recsSlice.reducer;
