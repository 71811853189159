// Dyce-Lib
import { JobTaskStatusOptions } from "@dyce/tnt-api";
import { IDatagridRow } from "@dyce/ui";

export const findById = (
  id: string,
  rows: IDatagridRow<any>[]
): IDatagridRow | undefined => {
  return rows.find((e) => e.id === id);
};

export const calculateHeight = (
  rows: IDatagridRow[],
  visibleRows = 6,
  rowHeight = 35
): number => {
  let rowLength = rows.length;
  // Calculate with fitered jobTask.status to show exact height
  if (
    rows.length > 0 &&
    rows[0].cells !== undefined &&
    (rows[0].cells.status !== undefined ||
      (rows[0].cells.jobTask && rows[0].cells.jobTask.status !== undefined))
  ) {
    // Reset height
    rowLength = 0;
    for (let i = 0; i < rows.length; i++) {
      if (rows[0].cells.status !== undefined) {
        if (rows[i].cells.status === JobTaskStatusOptions.OPEN) {
          rowLength += 1;
        }
      } else if (
        rows[0].cells.jobTask &&
        rows[0].cells.jobTask.status !== undefined
      ) {
        if (rows[i].cells.jobTask.status === JobTaskStatusOptions.OPEN) {
          rowLength += 1;
        }
      }
    }
  }
  if (rowLength <= visibleRows) {
    if (rowLength === 0) {
      rowLength = rowLength * rowHeight + 79;
    } else {
      rowLength = rowLength * rowHeight + 37;
    }
  } else {
    rowLength = rowHeight * visibleRows + 37;
  }

  return rowLength;
};
