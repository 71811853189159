import React, { FC } from "react";
import { Link } from "react-router-dom";
// MUI
import { ListItemButton, ListItemIcon } from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
// Helper
import { useAbility } from "@dyce/auth";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useStaticContent } from "../static-provider/static-provider";

const appMenuWidth = 374;

const useStyles = makeStyles(() =>
  createStyles({
    appMenu: {
      "& .MuiPaper-root": {
        borderRadius: 0,
        backgroundColor: "#272929",
        paddingLeft: "1.5rem",
        minHeight: "-webkit-fill-available",
      },
    },
    menuButton: {
      color: "#000000",
    },
    buttonPlaceHolder: {
      height: 24,
      width: 24,
    },
    menuEnlarger: {
      minHeight: 16,
      width: appMenuWidth,
      background: "red",
    },
    title: {
      paddingLeft: 2,
      flexGrow: 1,
      letterSpacing: "0.2em",
      fontWeight: 300,
      color: "#fff",
    },
    appButtonWrapper: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
      paddingRight: "1.5rem",
    },
    listItem: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center !important",
    },
    buttonIcons: {
      height: 27,
      "& .MuiListItemIcon-root": {
        minWidth: 26,
        marginLeft: -6,
      },
    },
    buttons: {
      paddingBottom: "1.5rem",
      "& .MuiButtonBase-root": {
        color: "#1b1b1b",
        borderRadius: 4,
        height: 150,
        width: 150,
        transition: "all 0.3s ease",
        "&:hover": {
          backgroundColor: "#b1dbee",
          boxShadow: "-1px 2px 8px 2px rgb(63, 142, 158)",
        },
      },
      "& .MuiListItemIcon-root": {
        color: "#1b1b1b",
      },
    },
    adminButton: {
      "& .MuiButtonBase-root": {
        background: "#23779d",
      },
    },
    timerecButton: {
      "& .MuiButtonBase-root": {
        background: "#37a2d3",
      },
    },
    placeholderButton: {
      "& .MuiButtonBase-root": {
        background: "#29ffff",
      },
    },
  })
);

interface AppMenuContent {
  /**
   * Callback fired at onClick event
   */
  handleRef?: (...args: any) => void;
  /**
   * If true, padding-right will be 1.5rem
   * @default false
   */
  paddingRight?: boolean;
  /**
   * Component link from react-router-dom
   * @default Link
   */
  linkAs?: any;
}

export const Content: FC<AppMenuContent> = ({
  handleRef,
  paddingRight = false,
  linkAs = Link,
}) => {
  const classes = useStyles();
  const { can } = useAbility();
  const { t } = useTranslation();
  const { routes } = useStaticContent();

  return (
    <>
      {can("read", "admin") && (
        <div
          className={classNames(classes.adminButton, classes.buttons)}
          style={paddingRight ? { paddingRight: "1.5rem" } : undefined}
        >
          <ListItemButton
            disabled={!can("read", "admin")}
            component={linkAs}
            to={routes.ADMIN}
            className={classes.listItem}
            onClick={() => handleRef && handleRef()}
          >
            <div className={classes.buttonIcons}>
              <ListItemIcon>
                <PeopleOutlineIcon />
              </ListItemIcon>
            </div>
            {t("app.nav.admin")}
          </ListItemButton>
        </div>
      )}
      {can("read", "timerec") && (
        <div
          className={classNames(classes.timerecButton, classes.buttons)}
          style={paddingRight ? { paddingRight: "1.5rem" } : undefined}
        >
          <ListItemButton
            disabled={!can("read", "timerec")}
            component={linkAs}
            to={routes.TNT}
            className={classes.listItem}
            onClick={() => handleRef && handleRef()}
          >
            <div className={classes.buttonIcons}>
              <ListItemIcon>
                <AccessTimeIcon />
              </ListItemIcon>
            </div>
            {t("app.nav.timerecordings")}
          </ListItemButton>
        </div>
      )}
    </>
  );
};
