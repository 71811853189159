import React, { FunctionComponent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
// Helper
import { DateTime } from "luxon";
import { useHotkeys } from "react-hotkeys-hook";
import { prepareTemplateForTimeRec } from "./utils";
import { useHistory } from "react-router-dom";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
import { useNotification } from "@dyce/hooks";
import { CalendarPicker, Popper, Tooltip, useStaticContent } from "@dyce/ui";
import {
  RecordTemplate,
  RecordEntry,
  RecordTimeRec,
  ValidatedRecord,
  TemplateCategory,
} from "@dyce/tnt-api";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Button, Checkbox, Typography } from "@mui/material";
import { sliceJobPlanningLineId } from "../utils";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    buttonContainer: {
      display: "flex",
      justifyContent: "end",
      paddingRight: "1rem",
      gap: "1rem",
      paddingBottom: "0.5rem",
      backgroundColor: theme.palette.mode === "dark" ? "#2f2f2f" : "#fff",
    },
    checkBoxContainer: {
      display: "flex",
      justifyContent: "end",
      alignItems: "center",
      paddingRight: "1rem",
      background: theme.palette.mode === "light" ? "#f7f7f7" : "#1e1e1e",
    },
  })
);

interface ITemplateDatePickerProps {
  /**
   * If true, Template-Date-Picker will be shown
   */
  open: boolean;
  /**
   * HTML Element (reference)
   */
  anchorEl: any;
  /**
   * Callback fired onClose
   */
  onClose: (options: {
    openCalendar: boolean;
    renderCalendar: boolean;
  }) => void;
  /**
   * Entry from template selection
   */
  templateEntry: RecordTemplate;
  /**
   * Current selected template-category from list-item
   */
  templateCategory: TemplateCategory | null;
  /**
   * Callback fired onSave
   */
  onSave?: (
    entry: RecordEntry & Partial<RecordTemplate & RecordTimeRec>,
    isTimeRecord?: boolean
  ) => Promise<{
    newEntry: RecordEntry | RecordTemplate | null;
    error: boolean;
  }>;
  /**
   * current selected locale with hyphen, e.g. 'en-GB'
   */
  localeHyphen: string;
  /**
   * Define from which location the template-date-picker is called and from
   * which #id for return path and scroll position
   */
  returnProps: {
    fromLocation: "Templates" | "Tasks";
    scrollPosition: number;
  };
  /**
   * Callback fired when template is submitted to validate object;
   */
  validateTemplateObject?: (
    template: RecordTemplate
  ) => Promise<ValidatedRecord>;
  /**
   * States for template list with callbacks
   */
  states: {
    openCreatedRecording: (value: boolean) => void;
    /**
     * Callback fired onChange, to clear latest created id
     */
    clearLatestCreatedId: () => void;
    /**
     * If true, checkbox is set to true (hook is visible)
     */
    isChecked: boolean;
    /**
     * Callback fired from notification, if 'undo' was selected
     */
    deleteRecord: (id: string) => Promise<boolean>;
  };
  /**
   * Children as React.ReactNode
   */
  children?: React.ReactNode;
}

export const RecordDatePicker: FunctionComponent<ITemplateDatePickerProps> = ({
  open,
  anchorEl,
  onClose,
  onSave,
  templateEntry,
  templateCategory,
  localeHyphen,
  returnProps,
  validateTemplateObject,
  states,
  children,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const notification = useNotification();
  const { docuLinks, routes } = useStaticContent();
  const { fromLocation, scrollPosition } = returnProps;

  // States
  const [checked, setChecked] = useState<boolean>(true);
  const [disableCalendar, setDisableCalendar] = useState<boolean>(false);
  const [notifier, setNotifier] = useState<boolean>(false);
  const [date, setDate] = useState<DateTime | null>(
    DateTime.now().set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    })
  );

  // Hotkeys
  useHotkeys("escape", () => {
    onClose({ openCalendar: false, renderCalendar: false });
  });

  // UseEffect
  useEffect(() => {
    setChecked(states.isChecked);
  }, [states.isChecked]);

  // Handler
  const handleChange = (
    _: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    states.openCreatedRecording(checked);
    states.clearLatestCreatedId();
  };

  const handleDateSelect = (selectedDate: DateTime | null) => {
    setDate(selectedDate);
  };

  const handleShowRecord = (date: DateTime) => {
    // Navigate to new entry
    onClose({ openCalendar: false, renderCalendar: false });
    history.push(
      `${
        routes.TNT_RECS
      }?editRecord=true&from${fromLocation}#${date.toISODate()}`,
      { [`from${fromLocation}`]: { scrollPosition: scrollPosition } }
    );
  };

  const handleUndoFromNotification = async (
    id: string | undefined,
    date: DateTime
  ) => {
    setNotifier(false);
    const somethingWentWrong = () => {
      notification(t("templates.notification.deleteFailed"), {
        variant: "error",
        canClose: true,
        callbacks: [{ name: "Show", fn: () => handleShowRecord(date) }],
      });
      onClose({ openCalendar: false, renderCalendar: false });
    };

    if (id) {
      try {
        const deleteDispatched = await states.deleteRecord(id);

        if (deleteDispatched) {
          notification(t("templates.notification.deleteTimerec"), {
            variant: "info",
            canClose: true,
          });
        }
        onClose({ openCalendar: false, renderCalendar: false });
      } catch {
        somethingWentWrong();
      }
    } else {
      somethingWentWrong();
    }
  };

  /**
   * Function to validate object and save as Timerecording
   * @param selectedDate DateTime object from DatePicker
   */
  const handleSubmit = async (selectedDate: DateTime | null) => {
    if (selectedDate && validateTemplateObject && onSave) {
      setNotifier(true);
      setDisableCalendar(true);
      let templateEntrySliced: RecordEntry | null | RecordTemplate =
        sliceJobPlanningLineId(templateEntry);

      // If user creates new template and directly wants to save as time-record,
      // then first save current state as template
      if (templateEntry.id.length === 0 && templateCategory && checked) {
        const { newEntry } = await onSave({
          ...templateEntrySliced,
          category: { ...templateCategory },
        });
        templateEntrySliced = newEntry;
      }

      // Prepare for Timerecording
      const timeRec: RecordEntry = prepareTemplateForTimeRec(
        selectedDate,
        templateEntrySliced as RecordTemplate
      );
      // Validate object
      const validateTemplate = await validateTemplateObject(
        timeRec as RecordTemplate
      );

      if (validateTemplate.errors.length === 0) {
        // Save Template as Timerecording
        const { newEntry } = await onSave(timeRec, true);
        setDisableCalendar(false);

        if (newEntry) {
          if (checked) {
            // Navigate to new entry
            handleShowRecord(selectedDate);
            onClose({ openCalendar: false, renderCalendar: false });
          } else {
            onClose({ openCalendar: false, renderCalendar: true });
            notification(
              t("templates.notification.createTimerec", {
                date: selectedDate.toFormat("D", { locale: localeHyphen }),
              }),
              {
                callbacks: [
                  {
                    name: "Show",
                    fn: async () => {
                      if (templateCategory !== null) {
                        await onSave({
                          ...(templateEntrySliced as RecordTemplate),
                          category: { ...templateCategory },
                        });
                      }
                      handleShowRecord(selectedDate);
                    },
                  },
                  {
                    name: "Undo",
                    fn: () =>
                      handleUndoFromNotification(newEntry.id, selectedDate),
                  },
                ],
                variant: "success",
                canClose: true,
                handleOnClose: ({ reason }) => {
                  if (reason !== "instructed") {
                    onClose({ openCalendar: false, renderCalendar: false });
                  }
                },
              }
            );
          }
        } else {
          onClose({ openCalendar: false, renderCalendar: false });
        }
      } else {
        const handleErrorMessage = (errorCode: string) => {
          const startMessage =
            t("templates.notification.validateFailed.validationFailed") + " ";
          switch (errorCode) {
            case "NoAssignment": {
              return (
                startMessage +
                t("templates.notification.validateFailed.noAssignment")
              );
            }
            default: {
              return (
                startMessage +
                t("templates.notification.validateFailed.noTemplate")
              );
            }
          }
        };

        notification(handleErrorMessage(validateTemplate.errors[0].errorCode), {
          variant: "error",
        });
        setDisableCalendar(false);
        setNotifier(false);
      }
    }
  };

  return (
    <>
      <Popper
        open={open}
        anchorEl={anchorEl}
        popperWidth={320}
        placement="bottom-start"
        onClose={() =>
          onClose({ openCalendar: false, renderCalendar: notifier })
        }
        dropShadow={true}
        headerButtons={{
          individual: {},
          expand: {},
          filter: {},
        }}
      >
        <CalendarPicker
          date={DateTime.now().set({
            hour: 0,
            minute: 0,
            second: 0,
            millisecond: 0,
          })}
          renderCurrentWeek={false}
          onSelect={(selectedDate) => handleDateSelect(selectedDate)}
          disable={disableCalendar}
          localeHyphen={localeHyphen}
        />
        {children}
        <div className={classes.buttonContainer}>
          <Button
            variant="text"
            onClick={() =>
              onClose({ openCalendar: false, renderCalendar: false })
            }
            color="inherit"
          >
            {t("templates.openRecord.close")}
          </Button>
          <Button
            variant="text"
            onClick={() => handleSubmit(date)}
            color="primary"
            disabled={notifier}
          >
            {t("templates.openRecord.save")}
          </Button>
        </div>
        <div className={classes.checkBoxContainer}>
          <Tooltip
            label={t("templates.openRecord.close")}
            urlPath={
              docuLinks.timetracking.reusableComponents.editor.showTimerecording
            }
          >
            <>
              <Typography paddingRight="0.5rem">
                {t("templates.checkBox")}
              </Typography>
              <Checkbox
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            </>
          </Tooltip>
        </div>
      </Popper>
    </>
  );
};
