import { PropsWithChildren, useEffect, useState } from "react";
// Dyce-Lib
import { Tooltip } from "../../tooltip/tooltip";
import { SetupLanguages } from "@dyce/slices";
// MUI
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  ClickAwayListener,
} from "@mui/material";

interface IRadioButtonsProps<T> {
  /**
   * Title of the RadioButtons
   */
  headerTitle?: string;
  /**
   * Array of Buttons
   */
  buttonLabels: {
    id: T;
    label: string;
  }[];
  /**
   * Optional Tooltip
   */
  tooltip?: {
    /**
     * Array of Tooltip-labels;
     * It's important that the order of the tooltip-labels matches the order of the @prop {buttonLabels}
     * Also both need to have same length! Otherwise it won't be rendered.
     */
    tooltipLabels: string[];
    /**
     * URL-Path to documentation
     */
    urlPath?: Record<SetupLanguages, string>;
  };

  /**
   * Value of the selected Button
   */
  value: T;
  /**
   * Callback fired when the value changes.
   * @param value Value of the selected Button
   * @returns void
   */
  onChange: (value: T) => void;
  /**
   * Orientation of the Buttons
   */
  orientation?: "row" | "column";
  /**
   * If true, the component will take up the full width of its container.
   */
  fullWidth?: boolean;
  /**
   * If true, the headerTitle will be focused during radio-button selection.
   * @default false
   */
  shouldFocus?: boolean;
}

export function RadioButtons<T>({
  headerTitle,
  buttonLabels,
  tooltip,
  value,
  orientation = "row",
  fullWidth = true,
  shouldFocus = false,
  onChange,
}: PropsWithChildren<IRadioButtonsProps<T>>) {
  const { tooltipLabels = [], urlPath = { en: "", de: "" } } = tooltip
    ? tooltip
    : {};
  // States
  const [internalValue, setInternalValue] = useState<T>(
    value ? value : buttonLabels[0].id
  );
  const [focused, setFocused] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    setInternalValue(value ? value : buttonLabels[0].id);
  }, [value]);

  useEffect(() => {
    onChange && onChange(internalValue);
  }, [internalValue]);

  // Handler
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInternalValue((event.target as HTMLInputElement).value as T & string);
  };

  return (
    <ClickAwayListener onClickAway={() => setFocused(false)}>
      <FormControl sx={{ width: fullWidth ? "100%" : "auto" }}>
        {headerTitle && (
          <FormLabel
            id="demo-row-radio-buttons-group-label"
            sx={{ mb: 2 }}
            focused={focused}
          >
            {headerTitle}
          </FormLabel>
        )}
        <RadioGroup
          onFocus={() => shouldFocus && setFocused(true)}
          row={orientation && orientation === "row" ? true : false}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={internalValue}
          onChange={handleChange}
          sx={{
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          {tooltipLabels && tooltipLabels.length === buttonLabels.length ? (
            tooltipLabels.map((label, index) => (
              <Tooltip label={label} urlPath={urlPath} key={index}>
                <FormControlLabel
                  value={buttonLabels[index].id}
                  control={<Radio />}
                  label={buttonLabels[index].label}
                />
              </Tooltip>
            ))
          ) : (
            <>
              {buttonLabels.map((value, index) => (
                <FormControlLabel
                  key={index}
                  value={value.id}
                  control={<Radio />}
                  label={value.label}
                />
              ))}
            </>
          )}
        </RadioGroup>
      </FormControl>
    </ClickAwayListener>
  );
}
