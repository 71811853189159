import { createSlice, createAsyncThunk, isAnyOf } from "@reduxjs/toolkit";
import { getDarkMode, getLang, getLocation } from "./util";
import { Settings } from "luxon";
import {
  RootState,
  SettingsStateSlice,
  SetupLanguages,
  TaskSettings,
} from "../../types/types";
import { TasksFilterStatus } from "@dyce/tnt-api";
import { DesignOptions } from "@dyce/interfaces";

export const setLanguage = createAsyncThunk<
  string,
  string,
  { state: RootState; rejectWithError: Error }
>("settings/language", (value) => {
  return value;
});

export const setLocale = createAsyncThunk<
  string,
  string,
  { state: RootState; rejectWithError: Error }
>("settings/locale", (value) => {
  return value;
});

export const setSideDrawerLeft = createAsyncThunk<
  boolean,
  boolean,
  { state: RootState; rejectWithError: Error }
>("settings/sideDrawerLeft", (value) => {
  return value;
});

export const setTimerecDashboardWOCapacity = createAsyncThunk<
  boolean,
  boolean,
  { state: RootState; rejectWithError: Error }
>("settings/timerec_dash_wocapacity", (value) => {
  return value;
});

export const setOpenCreatedRecording = createAsyncThunk<
  {
    tasks: boolean;
    templates: boolean;
  },
  {
    tasks: boolean;
    templates: boolean;
  },
  { state: RootState; rejectWithError: Error }
>("settings/open_created_recording", (value) => {
  return value;
});

export const toggleDarkMode = createAsyncThunk<
  boolean,
  boolean,
  { state: RootState; rejectWithValue: Error }
>("ui/darkmode", (v) => {
  return v;
});

export const setTaskGrouping = createAsyncThunk<
  TaskSettings,
  TaskSettings,
  { state: RootState; rejectWithValue: Error }
>("ui/taskGrouping", (v) => {
  return v;
});

export const setTasksWODate = createAsyncThunk<
  boolean,
  boolean,
  { state: RootState; rejectWithValue: Error }
>("ui/taskWODate", (v) => {
  return v;
});

export const setTasksRule = createAsyncThunk<
  TasksFilterStatus,
  TasksFilterStatus,
  { state: RootState; rejectWithValue: Error }
>("ui/taskRule", (v) => {
  return v;
});

export const setTasksFilter = createAsyncThunk<
  {
    searchKeyWord: string;
    startDate: string;
    paginationSkip: number;
  },
  {
    searchKeyWord: string;
    startDate: string;
    paginationSkip: number;
  },
  { state: RootState; rejectWithValue: Error }
>("ui/taskFilter", (v) => {
  return v;
});

export const setTimeTrackingDesign = createAsyncThunk<
  DesignOptions,
  DesignOptions,
  { state: RootState; rejectWithValue: Error }
>("ui/timeTrackingTool", (value) => {
  return value;
});

export const initialStateSettings: SettingsStateSlice = {
  isLoading: true,
  timerec: {
    dashboard: {
      showDaysWithoutCapacity: false,
    },
    templates: {
      openCreatedRecording: {
        tasks: false,
        templates: false,
      },
    },
    tasks: {
      groupedBy: TaskSettings.CUSTOMER,
      showWithoutDate: true,
      rule: TasksFilterStatus.OPTIONAL,
      filter: {
        searchKeyWord: "",
        startDate: "",
        paginationSkip: 0,
      },
    },
  },
  ui: {
    darkMode: getDarkMode(),
    sideDrawerLeft: true,
    timeTrackingToolDesign: DesignOptions.ALL,
  },
  general: {
    language: getLang(),
    locale: getLocation(),
  },
};

const settingsSlice = createSlice({
  name: "settings",
  initialState: initialStateSettings,
  reducers: {
    loadFromLocalStorage() {
      const settings = localStorage.getItem("settings");
      let state = { ...initialStateSettings };

      if (settings) {
        state = { ...JSON.parse(settings) };
      }

      if (
        state.timerec.tasks === undefined ||
        state.timerec.tasks.filter === undefined
      ) {
        // Safty statement if user has old settings
        state.timerec.tasks = {
          groupedBy: TaskSettings.CUSTOMER,
          showWithoutDate: true,
          rule: TasksFilterStatus.OPTIONAL,
          filter: {
            searchKeyWord: "",
            startDate: "",
            paginationSkip: 0,
          },
        };
      }

      if (
        state.ui.timeTrackingToolDesign === undefined ||
        (state.ui.timeTrackingToolDesign &&
          (state.ui.timeTrackingToolDesign as string) === "naos") ||
        (state.ui.timeTrackingToolDesign as string) === "fulu" ||
        (state.ui.timeTrackingToolDesign as string) === "nunki" ||
        (state.ui.timeTrackingToolDesign as string) === "chara"
      ) {
        state.ui.timeTrackingToolDesign = DesignOptions.ALL;
      }

      state.isLoading = false;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setLanguage.fulfilled, (state, action) => {
        state.general.language = action.payload as SetupLanguages;
      })
      .addCase(setTimerecDashboardWOCapacity.fulfilled, (state, action) => {
        state.timerec.dashboard.showDaysWithoutCapacity = action.payload;
      })
      .addCase(setSideDrawerLeft.fulfilled, (state, action) => {
        state.ui.sideDrawerLeft = action.payload;
      })
      .addCase(setLocale.fulfilled, (state, action) => {
        state.general.locale = action.payload;
        Settings.defaultLocale = action.payload.replace("_", "-");
      })
      .addCase(toggleDarkMode.fulfilled, (state, action) => {
        state.ui.darkMode = action.payload;
      })
      .addCase(setOpenCreatedRecording.fulfilled, (state, action) => {
        state.timerec.templates.openCreatedRecording = action.payload;
      })
      .addCase(setTimeTrackingDesign.fulfilled, (state, action) => {
        state.ui.timeTrackingToolDesign = action.payload;
      })
      .addCase(setTaskGrouping.fulfilled, (state, action) => {
        state.timerec.tasks.groupedBy = action.payload;
      })
      .addCase(setTasksWODate.fulfilled, (state, action) => {
        state.timerec.tasks.showWithoutDate = action.payload;
      })
      .addCase(setTasksFilter.fulfilled, (state, action) => {
        state.timerec.tasks.filter = action.payload;
      })
      .addCase(setTasksRule.fulfilled, (state, action) => {
        state.timerec.tasks.rule = action.payload;
      })
      .addMatcher(
        isAnyOf(
          setLanguage.fulfilled,
          setTimerecDashboardWOCapacity.fulfilled,
          setSideDrawerLeft.fulfilled,
          setLocale.fulfilled,
          toggleDarkMode.fulfilled,
          setOpenCreatedRecording.fulfilled,
          setTimeTrackingDesign.fulfilled,
          setTaskGrouping.fulfilled,
          setTasksWODate.fulfilled,
          setTasksFilter.fulfilled,
          setTasksRule.fulfilled
        ),
        (state) => {
          localStorage.setItem("settings", JSON.stringify(state));
        }
      );
  },
});

export const { loadFromLocalStorage } = settingsSlice.actions;
export default settingsSlice.reducer;
