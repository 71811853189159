import { IProjectInfo } from "azure-devops-extension-api";
import { SDK } from "@dyce/azure-sdk";
import axios, { Axios } from "axios";
import {
  DevOpsWorkItem,
  ProjectConfigurationResult,
  SubscriptionResponse,
  SubscriptionType,
  UpdateWorkItemType,
} from "../types";
import {
  handleCollection,
  handleDevOpsBackend,
  handleDevOpsInformation,
  handleDevOpsOAuth,
} from "../helper/devOpsHelper";

export class DevOpsService {
  public baseUrl: string;
  public collection: string | undefined;

  private axiosInstance: Axios;

  constructor(collection?: string) {
    this.baseUrl = "https://dev.azure.com";

    this.collection = collection;
    this.axiosInstance = axios.create({
      baseURL: this.baseUrl,
    });

    this.axiosInstance.interceptors.request.use(async (config) => {
      const { authToken } = await handleDevOpsOAuth({
        withProject: false,
      });
      // add access token from DevOps-User
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${authToken}`,
        crossOrigin: true,
      };

      return config;
    });
  }

  /**
   *
   * @param id
   * @param value
   * @returns
   */
  public async saveToBackEnd(id: string, value: Record<string, any>) {
    const response = await handleDevOpsBackend();

    return response.setDocument(await handleCollection(this.collection), {
      id: id,
      value: value,
      __etag: -1,
    });
  }

  /**
   *
   * @param id
   * @returns
   */
  public async getDataFromBackEnd(id: string): Promise<any> {
    const response = await handleDevOpsBackend();

    return response.getDocument(await handleCollection(this.collection), id);
  }

  /**
   *
   * @returns
   */
  public async getDevOpsInformation(): Promise<{
    currentUser: SDK.IUserContext;
    organization: SDK.IHostContext;
    project: IProjectInfo | null;
  }> {
    const result = await handleDevOpsInformation();

    const { organization } = await handleDevOpsOAuth({
      withProject: false,
    });

    const currentDevOpsUser: SDK.IUserContext = await SDK.getUser();

    return {
      currentUser: currentDevOpsUser,
      organization: organization,
      project: result ? result : null,
    };
  }

  /**
   * Extra API call to foreign uri to get WorkItem information from DevOps
   * @returns WorkItem information e.g. Title, id, WorkItemType, Descrpition, Completed Work, ...
   */
  public async getWorkItemData(id: number): Promise<DevOpsWorkItem> {
    const { organization, project } = await handleDevOpsOAuth({
      withProject: true,
    });

    const url = `${organization.name}/${project?.name}/_apis/wit/workitems/${id}?$expand=fields&api-version=7.0`;

    const response = await this.axiosInstance.get<DevOpsWorkItem>(url);

    return response.data;
  }

  /**
   * Extra API call to foreign uri to get WorkItem information from DevOps
   * @returns WorkItem information e.g. Title, id, WorkItemType, Descrpition, Completed Work, ...
   */
  public async updateWorkItemData(
    id: number,
    payload: UpdateWorkItemType[]
  ): Promise<DevOpsWorkItem> {
    const { organization } = await handleDevOpsOAuth({
      withProject: false,
    });

    this.axiosInstance.interceptors.request.use((config) => {
      // add access token from DevOps-User
      config.headers = {
        ...config.headers,
        "Content-Type": "application/json-patch+json",
      };

      return config;
    });

    const url = `${organization.name}/_apis/wit/workitems/${id}?api-version=7.0`;

    const response = await this.axiosInstance.patch<DevOpsWorkItem>(
      url,
      payload
    );

    return response.data;
  }

  /**
   * Extra API call create web-hook subscriptions
   * @returns WorkItem information e.g. Title, id, WorkItemType, Descrpition, Completed Work, ...
   */
  public async createWebHookSubscription(
    payload: SubscriptionType
  ): Promise<SubscriptionResponse> {
    const { organization } = await handleDevOpsOAuth({
      withProject: false,
    });

    const url = `${organization.name}/_apis/hooks/subscriptions?api-version=6.0`;

    const response = await this.axiosInstance.post<SubscriptionResponse>(
      url,
      payload
    );

    return response.data;
  }

  /**
   * Extra API call to foreign uri to get WorkItem information from DevOps
   */
  public async deleteWebHookSubscription(id: string): Promise<number> {
    const { organization } = await handleDevOpsOAuth({
      withProject: false,
    });

    const url = `${organization.name}/_apis/hooks/subscriptions/${id}?api-version=6.0`;

    const response = await this.axiosInstance.delete(url);

    return response.status;
  }

  /**
   * Extra API call to foreign uri to get WorkItem information from DevOps
   */
  public async getWebHookSubscriptions(): Promise<{
    count: number;
    value: SubscriptionResponse[];
  }> {
    const { organization } = await handleDevOpsOAuth({
      withProject: false,
    });

    const url = `${organization.name}/_apis/hooks/subscriptions?api-version=6.0`;

    const response = await this.axiosInstance.get(url);

    return response.data;
  }

  /**
   * Extra API call to foreign uri to get WorkItem information from DevOps
   * @returns WorkItem information e.g. Title, id, WorkItemType, Descrpition, Completed Work, ...
   */
  public async getAreaTree(
    query = "$depth=2"
  ): Promise<ProjectConfigurationResult> {
    const { organization, project } = await handleDevOpsOAuth({
      withProject: true,
    });

    const url = `${organization.name}/${project?.name}/_apis/wit/classificationnodes?${query}&api-version=6.0`;

    const response =
      await this.axiosInstance.get<ProjectConfigurationResult>(url);

    return response.data;
  }

  public async getDevOpsSettings(): Promise<any> {
    const { organization } = await handleDevOpsOAuth({
      withProject: false,
    });

    const url = `${organization.name}/_apis/Settings/Entries/me/WebPlatform`;

    const response = await this.axiosInstance.get<any>(url);

    return response.data;
  }
}
