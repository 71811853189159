// Helper
import { DateTime } from "luxon";
import {
  calculateNewEndTime,
  checkEndBeforeStartTime,
  createEndTime,
  createStartTime,
} from "./helper/calculateTimeFields";
import {
  defineTimeInputs,
  parseTimeWithDate,
  handleDurationBillableRounding,
} from "./utils";
import { t } from "i18next";
// Dyce-Lib
import {
  RecordEntry,
  RecordTemplate,
  RecordTimeRec,
  NonBillableReasonOptions,
} from "@dyce/tnt-api";
import { PrevStateObject } from "@dyce/ui";

export const handleValidate = (
  data: any,
  prevData: PrevStateObject,
  record: (RecordEntry & Partial<RecordTemplate & RecordTimeRec>) | null,
  recordDate: string
): PrevStateObject => {
  const dateFormat = "yyyy-MM-dd";
  const newPrevData: PrevStateObject = { ...prevData };

  if (newPrevData.errors.length > 0) {
    newPrevData.errors = [];
  }

  // Set Date for create Timerecording to selected day
  if (!record || (record && record.category === undefined)) {
    if (
      !data.date ||
      (DateTime.fromISO(data.date).toFormat("HH:mm") === "00:00" &&
        DateTime.fromISO(data.date).toFormat(dateFormat) === recordDate)
    ) {
      data.date = DateTime.fromFormat(recordDate, dateFormat).toISO();
    }
  }

  // BackUp endTime as ISO
  let backUpEnd: string = data.end;
  if (
    data.date !== undefined &&
    data.date !== null &&
    data.end !== null &&
    String(data.end).length > 0
  ) {
    backUpEnd = parseTimeWithDate(data.end, data.date);
  }

  // clear end or start time if the other time field is cleared
  if (
    data.start !== prevData.memory.lastStart &&
    data.start === null &&
    data.duration !== 0
  ) {
    data.end = null;
    newPrevData.timeInput.end = null;
  }

  if (
    data.end !== prevData.memory.lastEnd &&
    data.end === null &&
    data.duration !== 0
  ) {
    data.start = null;
    newPrevData.timeInput.start = null;
  }

  // Set startTime to correct Date (needed if user changes dateField)
  if (
    data.start !== null &&
    data.start !== "Error" &&
    String(data.start).length > 0 &&
    data.date &&
    DateTime.fromISO(data.start).day !== DateTime.fromISO(data.date).day
  ) {
    data.start = parseTimeWithDate(data.start, data.date);
  }
  // Set endTime to correct Date (needed if user changes dateField)
  if (
    data.end !== null &&
    data.end !== "Error" &&
    String(data.end).length > 0 &&
    data.date &&
    DateTime.fromISO(data.end).day !== DateTime.fromISO(data.date).day
  ) {
    data.end = parseTimeWithDate(data.end, data.date);
  }

  // Check Duration is equal to Start- / EndTime
  // Else set EndTime and/or Duration correct
  if (
    data.start &&
    data.end &&
    (data.duration || data.duration === 0) &&
    (prevData.timeInput.duration || prevData.timeInput.duration === 0)
  ) {
    const newTime: {
      end: string;
      duration: number;
    } = calculateNewEndTime(
      DateTime.fromISO(data.start),
      DateTime.fromISO(data.end),
      data.duration,
      data.break
    );
    if (newTime.end !== data.end || newTime.duration !== data.duration) {
      data.end = newTime.end;
      data.duration = newTime.duration;
    }
  }

  // Change EndTime if Duration is changed
  if (
    data.end &&
    data.end !== backUpEnd &&
    data.duration === prevData.timeInput.duration
  ) {
    data.end = DateTime.fromISO(backUpEnd).toISO();
  }

  // When only StartTime and change Duration => Create EndTime
  if (data.start && !data.end && data.duration > 0) {
    const endTime: string = createEndTime(
      DateTime.fromISO(data.start),
      data.duration
    );
    data.end = endTime;
    newPrevData.timeInput.end = endTime;
  }

  // When only EndTime and change Duration => Create StartTime
  if (!data.start && data.end && data.duration > 0) {
    const startTime: string = createStartTime(
      DateTime.fromISO(data.end),
      data.duration
    );
    data.start = startTime;
    newPrevData.timeInput.start = startTime;
  }

  // When Start || End is deleted afterwards and break is greater 0 => Break is 0,
  // while DureationField for break is getting disabled.
  if (
    data.start === null &&
    data.end === null &&
    data.break > 0 &&
    data.duration > 0
  ) {
    data.break = 0;
    newPrevData.timeInput.break = data.break;
    newPrevData.memory.pause = data.break;
    if (
      data.jobPlanningLine &&
      data.jobPlanningLine.id &&
      data.jobPlanningLine.tntModelLine &&
      data.jobPlanningLine.tntModelLine.id !== undefined &&
      data.jobPlanningLine.tntModelLine.id !== null &&
      data.jobPlanningLine.tntModelLine.id.length > 0 &&
      data.jobPlanningLine.tntModelLine.billable !== undefined &&
      data.jobPlanningLine.tntModelLine.billable === true &&
      data.nonBillableReason === NonBillableReasonOptions.NONE
    ) {
      data.durationBillable = handleDurationBillableRounding(
        data.duration,
        data.jobPlanningLine.tntModelLine.rounding
      );
      newPrevData.timeInput.durationBillable = data.durationBillable;
      newPrevData.memory.billableDuration = data.durationBillable;
    } else {
      data.durationBillable = 0;
      newPrevData.timeInput.durationBillable = data.durationBillable;
      newPrevData.memory.billableDuration = data.durationBillable;
    }
  }

  // Check if break is greater then (end - start) => Error
  if (data.start && data.end && data.break > 0) {
    const startEndDiff = DateTime.fromISO(data.end).diff(
      DateTime.fromISO(data.start)
    );
    const startEndSeconds = startEndDiff.milliseconds / 1000 / 60;

    if (data.break > startEndSeconds) {
      newPrevData.errors.push({
        ref: "break",
        errorOption: {
          type: "manual",
          message: t("timerec.dailyRecording.validate.positiveQuantity"),
        },
      });
      return newPrevData;
    }
  }

  // Check if EndTime is before StartTime and set Duration correct to endTime
  // Also check for 0:00h as endTime
  if (data.start && data.end && data.end !== "Error") {
    let newDuration = 0;
    let newEndTime = "";
    [newDuration, newEndTime] = checkEndBeforeStartTime(
      data.start,
      data.end,
      data.break,
      data.date
    );
    if (newDuration < 0) {
      if (data.duration > 0 && data.end !== null) {
        const newDurationAfterError =
          DateTime.fromISO(backUpEnd).diff(DateTime.fromISO(data.start))
            .milliseconds /
          1000 /
          60;
        if (newDurationAfterError >= 0) {
          // After error thrown, check if endTime was changed by user and is valid (after start)
          newPrevData.timeInput.duration = newDurationAfterError;
          data.duration = newDurationAfterError;
        } else {
          // Duration was set after start (no end) && duration + start > 24:00
          newPrevData.errors.push({
            ref: "duration",
            errorOption: {
              type: "manual",
              message: t("timerec.dailyRecording.validate.startendtime"),
            },
          });
        }
      } else {
        newPrevData.errors.push({
          ref: "end",
          errorOption: {
            type: "manual",
            message: t("timerec.dailyRecording.validate.startendtime"),
          },
        });
      }
      return newPrevData;
    } else {
      data.duration = newDuration;
      data.end = newEndTime;
    }
  }

  // User selected Activity
  if (
    data.jobPlanningLine &&
    data.jobPlanningLine.tntModelLine &&
    data.jobPlanningLine.tntModelLine.id !== undefined &&
    data.jobPlanningLine.tntModelLine.id !== null &&
    data.jobPlanningLine.tntModelLine.id.length > 0
  ) {
    // Update the nonBillableReason if TnTModel says its not billable
    if (
      data.jobPlanningLine.tntModelLine.billable === false &&
      !newPrevData.memory.nonBillableReasonChanged
    ) {
      if (
        data.jobPlanningLine.tntModelLine.nonBillableReason ===
        NonBillableReasonOptions.NONE
      ) {
        data.nonBillableReason = NonBillableReasonOptions.INTERNAL;
        newPrevData.memory.nonBillableReason =
          NonBillableReasonOptions.INTERNAL;
        newPrevData.timeInput.nonBillableReason =
          NonBillableReasonOptions.INTERNAL;
        newPrevData.memory.nonBillableReasonChanged = true;
      } else {
        data.nonBillableReason =
          data.jobPlanningLine.tntModelLine.nonBillableReason;
        newPrevData.timeInput.nonBillableReason =
          data.jobPlanningLine.tntModelLine.nonBillableReason;
        newPrevData.memory.nonBillableReason =
          data.jobPlanningLine.tntModelLine.nonBillableReason;
        newPrevData.memory.nonBillableReasonChanged = true;
      }
    }
  }

  // Update reason for non billing; only if the durationBillable has changed manually
  if (
    data.durationBillable !== undefined &&
    prevData.memory.billableDuration !== data.durationBillable
  ) {
    if (
      data.jobPlanningLine &&
      data.jobPlanningLine.id &&
      data.jobPlanningLine.tntModelLine &&
      data.durationBillable < data.duration
    ) {
      data.nonBillableReason =
        data.jobPlanningLine.tntModelLine.nonBillableReason;
      newPrevData.timeInput.nonBillableReason = data.nonBillableReason;
    } else if (data.duration > data.durationBillable) {
      data.nonBillableReason = NonBillableReasonOptions.INTERNAL;
      newPrevData.memory.nonBillableReason = NonBillableReasonOptions.INTERNAL;
      newPrevData.timeInput.nonBillableReason =
        NonBillableReasonOptions.INTERNAL;
    } else if (data.duration <= data.durationBillable) {
      data.nonBillableReason = NonBillableReasonOptions.NONE;
      newPrevData.memory.nonBillableReason = NonBillableReasonOptions.NONE;
      newPrevData.timeInput.nonBillableReason = NonBillableReasonOptions.NONE;
    }
    newPrevData.memory.billableDuration = data.durationBillable;
  }

  // Update reason if tnt model changes
  if (
    data.jobPlanningLine &&
    data.jobPlanningLine.tntModelLine &&
    data.jobPlanningLine.tntModelLine.id &&
    data.jobPlanningLine.tntModelLine.id.length > 0 &&
    data.jobPlanningLine.tntModelLine.id !== prevData.memory.tntId
  ) {
    // Reset NBR if billable reason is given from model
    if (
      data.jobPlanningLine.tntModelLine.nonBillableReason !== undefined &&
      prevData.memory.nonBillableReasonChanged
    ) {
      data.nonBillableReason = NonBillableReasonOptions.NONE;
      newPrevData.memory.nonBillableReason = data.nonBillableReason;
      newPrevData.timeInput.nonBillableReason = data.nonBillableReason;
      newPrevData.memory.nonBillableReasonChanged = false;
      data.durationBillable = handleDurationBillableRounding(
        data.duration,
        data.jobPlanningLine.tntModelLine.rounding
      );
      newPrevData.timeInput.durationBillable = data.durationBillable;
      newPrevData.memory.billableDuration = data.durationBillable;
      if (
        data.jobPlanningLine.tntModelLine.nonBillableReason !==
          NonBillableReasonOptions.NONE &&
        data.jobPlanningLine.tntModelLine.billable === false
      ) {
        data.durationBillable = 0;
        newPrevData.timeInput.durationBillable = data.durationBillable;
        newPrevData.memory.billableDuration = data.durationBillable;
        data.nonBillableReason =
          data.jobPlanningLine.tntModelLine.nonBillableReason;
        newPrevData.timeInput.nonBillableReason = data.nonBillableReason;
        newPrevData.memory.nonBillableReason = data.nonBillableReason;
        newPrevData.memory.nonBillableReasonChanged = false;
      }
    } else {
      if (data.duration > data.durationBillable + data.break) {
        if (data.jobPlanningLine.tntModelLine.billable === true) {
          data.nonBillableReason = NonBillableReasonOptions.NONE;
          newPrevData.memory.nonBillableReason = data.nonBillableReason;
          newPrevData.timeInput.nonBillableReason = data.nonBillableReason;
          data.durationBillable = handleDurationBillableRounding(
            data.duration,
            data.jobPlanningLine.tntModelLine.rounding
          );
        } else {
          data.nonBillableReason = NonBillableReasonOptions.INTERNAL;
          newPrevData.memory.nonBillableReason =
            NonBillableReasonOptions.INTERNAL;
          newPrevData.timeInput.nonBillableReason =
            NonBillableReasonOptions.INTERNAL;
        }
      }
      if (data.duration <= data.durationBillable + data.break) {
        // Calculate durationBillable new with rounding
        data.durationBillable = handleDurationBillableRounding(
          data.duration,
          data.jobPlanningLine.tntModelLine.rounding
        );
        newPrevData.timeInput.durationBillable = data.durationBillable;
        newPrevData.memory.billableDuration = data.durationBillable;
        data.nonBillableReason = NonBillableReasonOptions.NONE;
        newPrevData.memory.nonBillableReason = NonBillableReasonOptions.NONE;
        newPrevData.timeInput.nonBillableReason = NonBillableReasonOptions.NONE;
      }
    }
    newPrevData.memory.tntId = data.jobPlanningLine.tntModelLine.id;
  }

  // Reset durationBillable if tntModel is not billable
  // Else calculate durationBillable (also if no tntModel is given)
  const defineDurationBillable = () => {
    if (
      data.jobPlanningLine &&
      data.jobPlanningLine.id &&
      data.jobPlanningLine.tntModelLine &&
      data.jobPlanningLine.tntModelLine.id !== undefined &&
      data.jobPlanningLine.tntModelLine.id !== null &&
      data.jobPlanningLine.tntModelLine.id.length > 0 &&
      data.jobPlanningLine.tntModelLine.billable !== undefined
    ) {
      // Only on first selection of JPL and TnTModelLine
      if (
        prevData.memory.duration !== data.duration ||
        prevData.memory.pause !== data.break ||
        (prevData.timeInput.jobPlanningLine === null &&
          data.jobPlanningLine.tntModelLine.id.length > 0)
      ) {
        if (data.jobPlanningLine.tntModelLine.billable === false) {
          data.durationBillable = 0;
          newPrevData.timeInput.durationBillable = data.durationBillable;
          newPrevData.memory.billableDuration = data.durationBillable;
          // Set nonBillableReason to Activity model
          if (
            data.jobPlanningLine.tntModelLine.nonBillableReason ===
            NonBillableReasonOptions.NONE
          ) {
            data.nonBillableReason = NonBillableReasonOptions.INTERNAL;
            newPrevData.memory.nonBillableReason =
              NonBillableReasonOptions.INTERNAL;
            newPrevData.timeInput.nonBillableReason =
              NonBillableReasonOptions.INTERNAL;
            newPrevData.memory.nonBillableReasonChanged = false;
          } else {
            data.nonBillableReason =
              data.jobPlanningLine.tntModelLine.nonBillableReason;
            newPrevData.timeInput.nonBillableReason = data.nonBillableReason;
            newPrevData.memory.nonBillableReason = data.nonBillableReason;
            newPrevData.memory.nonBillableReasonChanged = false;
          }
        } else if (data.jobPlanningLine.tntModelLine.billable === true) {
          data.durationBillable = handleDurationBillableRounding(
            data.duration,
            data.jobPlanningLine.tntModelLine.rounding
          );
          newPrevData.timeInput.durationBillable = data.durationBillable;
          newPrevData.memory.billableDuration = data.durationBillable;
          // Set nonBillableReason to none
          data.nonBillableReason = NonBillableReasonOptions.NONE;
          newPrevData.memory.nonBillableReason = NonBillableReasonOptions.NONE;
          newPrevData.timeInput.nonBillableReason =
            NonBillableReasonOptions.NONE;
        }
        newPrevData.memory.duration = data.duration;
        newPrevData.memory.pause = data.break;
      }
      if (
        data.durationBillable === undefined &&
        prevData.memory.billableDuration !== data.durationBillable
      ) {
        if (data.jobPlanningLine.tntModelLine.billable === true) {
          data.durationBillable = handleDurationBillableRounding(
            data.duration,
            data.jobPlanningLine.tntModelLine.rounding
          );
          prevData.memory.billableDuration = data.durationBillable;
        } else {
          data.durationBillable = prevData.memory.billableDuration;
        }
        newPrevData.timeInput.durationBillable = data.durationBillable;
        newPrevData.memory.billableDuration = data.durationBillable;
      }
    } else {
      // Check if user changed durationBillable and confirm, else
      // calculate from duration and set nBR to NONE if it was INTERNAL
      if (data.durationBillable === undefined && !record) {
        data.durationBillable = 0;
      }
      if (prevData.timeInput.durationBillable !== data.durationBillable) {
        newPrevData.timeInput.durationBillable = data.durationBillable;
      } else if (
        prevData.memory.duration !== data.duration ||
        prevData.memory.pause !== data.break
      ) {
        // No rounding here, because no tntModel is set
        data.durationBillable = data.duration;
        newPrevData.timeInput.durationBillable = data.durationBillable;
        newPrevData.memory.duration = data.duration;
        newPrevData.memory.pause = data.break;
        if (
          prevData.timeInput.nonBillableReason ===
          NonBillableReasonOptions.INTERNAL
        ) {
          data.nonBillableReason = NonBillableReasonOptions.NONE;
          newPrevData.timeInput.nonBillableReason =
            NonBillableReasonOptions.NONE;
          newPrevData.memory.nonBillableReason = NonBillableReasonOptions.NONE;
          newPrevData.memory.nonBillableReasonChanged = false;
        }
      }
      // newPrevData.memory.billableDuration = data.durationBillable;
    }
  };
  defineDurationBillable();

  // Set new tntModelLine ID finally for memory
  if (
    data.jobPlanningLine &&
    data.jobPlanningLine.tntModelLine &&
    data.jobPlanningLine.tntModelLine.id &&
    data.jobPlanningLine.tntModelLine.id.length > 0 &&
    data.jobPlanningLine.tntModelLine.id !== prevData.memory.tntId
  ) {
    newPrevData.memory.tntId =
      data.jobPlanningLine.tntModelLine.id !== undefined
        ? data.jobPlanningLine.tntModelLine.id
        : "";
  }

  // Only Duration as Input? Set it correct
  if (
    (!data.start && !data.end && data.duration > 0) ||
    (!data.start && !data.end && record && record.duration > 0)
  ) {
    newPrevData.timeInput.duration = data.duration;
  }

  // Set start & end memory
  newPrevData.memory.lastStart = data.start;
  newPrevData.memory.lastEnd = data.end;

  // If nonBillableReason manually set by user to a reason (coming from NONE),
  // allways set dureationBillable to 0 to prevent BC errors!
  if (
    prevData.memory.nonBillableReason !== data.nonBillableReason &&
    data.durationBillable >= data.duration
  ) {
    data.durationBillable = 0;
    newPrevData.timeInput.durationBillable = data.durationBillable;
    newPrevData.memory.billableDuration = data.durationBillable;
    newPrevData.memory.nonBillableReason = data.nonBillableReason;
  } else if (
    prevData.memory.nonBillableReason !== data.nonBillableReason &&
    data.nonBillableReason === NonBillableReasonOptions.NONE &&
    data.durationBillable <= data.duration
  ) {
    if (
      data.jobPlanningLine &&
      data.jobPlanningLine.tntModelLine &&
      data.jobPlanningLine.tntModelLine.id !== undefined &&
      data.jobPlanningLine.tntModelLine.id !== null &&
      data.jobPlanningLine.tntModelLine.id.length > 0 &&
      data.jobPlanningLine.tntModelLine.billable === true
    ) {
      data.durationBillable = handleDurationBillableRounding(
        data.duration,
        data.jobPlanningLine.tntModelLine.rounding
      );
      newPrevData.timeInput.durationBillable = data.durationBillable;
      newPrevData.memory.billableDuration = data.durationBillable;
      newPrevData.memory.nonBillableReason = data.nonBillableReason;
    } else if (
      data.jobPlanningLine === null ||
      (data.jobPlanningLine &&
        data.jobPlanningLine.tntModelLine &&
        data.jobPlanningLine.tntModelLine.id === null) ||
      (data.jobPlanningLine &&
        data.jobPlanningLine.tntModelLine &&
        data.jobPlanningLine.tntModelLine.id !== undefined &&
        data.jobPlanningLine.tntModelLine.id !== null &&
        data.jobPlanningLine.tntModelLine.id.length === 0)
    ) {
      data.durationBillable = handleDurationBillableRounding(
        data.duration,
        data.jobPlanningLine ? data.jobPlanningLine.tntModelLine.rounding : 0
      );
      newPrevData.timeInput.durationBillable = data.durationBillable;
      newPrevData.memory.billableDuration = data.durationBillable;
      newPrevData.memory.nonBillableReason = data.nonBillableReason;
    }
  }

  // If tntModell existed and user delete it afterwards, set durationBillable and
  // nonBillableDuration to "initial case"
  // Case: Activity existed, JobTask is deleted and select another JobTask ||
  // ...Activity existed, JobTask is deleted and select the same JobTask again
  if (
    (prevData.memory.tntId.length > 0 &&
      data.jobPlanningLine &&
      data.jobPlanningLine.tntModelLine &&
      data.jobPlanningLine.tntModelLine.id === undefined &&
      ((data.jobTask && data.jobTask.id === null) ||
        (prevData.timeInput.jobTask &&
          prevData.timeInput.jobTask.id !== data.jobTask.id))) ||
    (prevData.memory.tntId.length > 0 &&
      data.jobPlanningLine &&
      data.jobPlanningLine.tntModelLine &&
      data.jobPlanningLine.tntModelLine.id === undefined &&
      prevData.timeInput.jobTask &&
      prevData.timeInput.jobTask.id === data.jobTask.id)
  ) {
    // Calculate dirationBillable from (duration)
    data.durationBillable = data.duration;
    newPrevData.timeInput.durationBillable = data.durationBillable;
    newPrevData.memory.billableDuration = data.durationBillable;
    // Set nonBillableReason to "None"
    data.nonBillableReason = NonBillableReasonOptions.NONE;
    newPrevData.timeInput.nonBillableReason = NonBillableReasonOptions.NONE;
    newPrevData.memory.nonBillableReason = NonBillableReasonOptions.NONE;
    // Set memory correct
    newPrevData.memory.tntId = "";
  }

  // Safety statement befor validate for Template
  if (prevData.timeInput.category) {
    data.category = prevData.timeInput.category;
  }
  // Safety statement befor validate for timerecording status
  if (prevData.timeInput.status) {
    data.status = prevData.timeInput.status;
  }
  // Safety statement befor validate for timerecording calendarEventId
  if (prevData.timeInput.calendarEventId) {
    data.calendarEventId = prevData.timeInput.calendarEventId;
  } else {
    data.calendarEventId = null;
  }
  // Safety statement befor validate for serviceBillingType
  if (
    prevData.timeInput.jobPlanningLine &&
    prevData.timeInput.jobPlanningLine.serviceBillingType
  ) {
    if (data.jobPlanningLine) {
      data.jobPlanningLine.serviceBillingType =
        prevData.timeInput.jobPlanningLine.serviceBillingType;
    }
  }
  if (prevData.timeInput.description && !data.description) {
    data.description = prevData.timeInput.description;
  }

  // Check for Update (Put)
  if (record) {
    if (record.workItem) {
      // Edit foreign timerec
      data.workItem = {
        id: prevData.timeInput.workItem ? prevData.timeInput.workItem.id : null,
        source: prevData.timeInput.workItem
          ? prevData.timeInput.workItem.source
          : null,
        organization: prevData.timeInput.workItem
          ? prevData.timeInput.workItem.organization
          : null,
        project: prevData.timeInput.workItem
          ? prevData.timeInput.workItem.project
          : null,
        title: prevData.timeInput.workItem
          ? prevData.timeInput.workItem.title
          : null,
        status: prevData.timeInput.workItem
          ? prevData.timeInput.workItem.status
          : null,
      };
      if (record.id) {
        data.id = prevData.timeInput.id;
        data.created = prevData.timeInput.created;
      }
    } else {
      // Edit DYCE timerec
      data.id = prevData.timeInput.id;
      data.created = prevData.timeInput.created;
    }
  } else {
    data.created = null;
  }

  newPrevData.timeInput = {
    ...defineTimeInputs(null, data, recordDate),
  };

  return newPrevData;
};
