import { IHostContext, IUserContext } from "@dyce/azure-sdk";
import { IProjectInfo } from "azure-devops-extension-api";
import {
  Customer,
  PopulatedJob,
  PopulatedJobPlanningLine,
  PopulatedJobTask,
  TntModelLine,
} from "@dyce/tnt-api";

export type MappingInfo = {
  customer: Customer | null;
  job: PopulatedJob | null;
  jobTask: PopulatedJobTask | null;
  jobPlanningLine: PopulatedJobPlanningLine | null;
  tntModelLine: TntModelLine | null;
};

export type JiraCompany = {
  /**
   * The company email domain e.g. "singhammer.com"
   */
  emailDomain: string;
  /**
   * The company name e.g. "Singhammer" created out of the emailDomain
   */
  displayName: string;
  /**
   * Custom name created by the user per UI
   */
  customName?: string;
  /**
   * Flag if the company is internal or not
   */
  isInternal?: boolean;
};

export type JiraSavedMapping = {
  /**
   * DYCE companyId which is mapped to the Jira-Project
   */
  [x: string]: JiraSavedMappingValue[];
};

export type JiraSavedMappingValue = {
  jiraCompany?: JiraCompany;
  mapping: MappingInfo;
};

//---===JIRA===---
export type ApiType = "GET" | "POST" | "PUT" | "DELETE";

export type JiraError = {
  message: string;
  "status-code": 400 | 401 | 404 | 429;
  key?: string;
};

export type JiraPayload = {
  key: string;
  value: any;
  self: string;
};

export type JiraBackendPayload<T> = {
  key: string;
  value: T;
  self: string;
};

export type JiraData = {
  organization: Record<string, any> | null;
  project: Record<string, any> | null;
  currentUser: Record<string, any> | null;
};

/**
 * Key names to communicate with Jira BackEnd
 * Values saved behind the keys
 */
export enum JiraBackEndId {
  /**
   * Was used for company-mapping - 'settings' is to generic to define what exactly
   * is used for! For company-mapping use 'DYCE_COMPANY_MAPPING' instead!
   * @deprecated
   */
  SETTINGS = "settings",
  /**
   * Used to check and set DYCE-Jira app as default TimeTracking app in Jira
   */
  INITIAL_SETTINGS = "initial-settings",
  /**
   * Used to get and set the company-mapping between Jira-Project and Jira-SM and DYCE
   */
  DYCE_COMPANY_MAPPING = "dyce-company-mapping",
  /**
   * Used to get and set the project-mapping between Jira-Project and DYCE
   * Map Customer - Job - JobTask - JobPlanningLine - TntModelLine to Jira-Project
   */
  JIRA_PROJECT_MAPPING = "jira-project-mapping",
  /**
   * Used to get and set the project-mapping between Jira-SM and DYCE
   * Map Customer - Job - JobTask - JobPlanningLine - TntModelLine to Jira-SM
   * For every single Customer (e.g.: email provider)
   */
  SERVICE_MANAGEMENT_MAPPING = "service-management-mapping",
  /**
   * Used to save last selected 'Activity' from current Jira-user and created timerecording
   * with this in an Jira-Issue. This is used to preselect the last selected 'Activity'
   * in the Fast Tracking area of the issue.
   * Use like: id: `${DevOpsBackEndId.USERID}${currentUser.id}`
   */
  USERID = `userId-`,
  /**
   * Used to get and set the webhooks for Jira to save
   * the id's from subscriptions to Jira backend
   * e.g.: saveToJiraBackEnd({
          id: JiraBackEndId.WEBHOOKS,
          value: webHookIds,
          type: "PUT",
        })
   */
  WEBHOOK = "webHook",
}

export type UserMappingSelection = {
  issueId: string;
  currentDyceCompany: string;
  selectedMapping: MappingInfo;
};

export interface JiraBackEndSettings {
  mappedInstance?: MappedInstanceValue | undefined | null;
  placeHolder?: Record<string, any> | undefined;
}

export type JiraWebHook = {
  /**
   * Id of created webHook
   */
  id: number;
  /**
   * Date of expiration
   */
  expirationDate: string;
  /**
   * Array of events webHook is triggering on, e.g. ["jira:issue_created", "jira:issue_updated"]
   */
  events: string[];
  /**
   * Filter for webHook, e.g. "project = TT"
   */
  jqlFilter: string;
};

export type JiraOnboarding = {
  /**
   * Foreign project key
   */
  projectKey: string;
  /**
   * DYCE client token
   */
  clientToken: string;
  /**
   * DYCE instance
   */
  instance: string;
  /**
   * DYCE company
   */
  company: string;
};

/**
 * Typed information for Jira-Issues in Jira-Project
 */
export type JiraProjectIssueType = {
  creator: IssueCreator;
  reporter: IssueReporter;
  issueId: string;
  progress: {
    percent?: number;
    progress: number;
    total: number;
  };
  issueType: {
    name: string; // Bug / Task / ...
  };
  status: {
    name: string; // To Do / In progress / Done / ...
  };
  summary: string; // Description
  timeoriginalestimate: number | null;
  timetracking: {
    originalEstimate: string | null;
    originalEstimateSeconds: number | null;
    remainingEstimate: string;
    remainingEstimateSeconds: number;
    timeSpent: string | null;
    timeSpentSeconds: number | null;
  } | null;
};

export type JiraProjectIssueResponseType = {
  expand: string;
  id: string;
  self: string;
  key: string; // ID of issue, e.g. TT-9
  fields: Record<string, any> & {
    creator: IssueCreator;
    reporter: IssueReporter;
    // !!! Empty object in jira-serviceManagement !!!
    timetracking: (
      | {
          originalEstimate?: string; // Can be available in jira-software
          originalEstimateSeconds?: number; // Can be available in jira-software
          remainingEstimate: string; // Always available in jira-software
          remainingEstimateSeconds: number; // Always available in jira-software
          timeSpent?: string; // Can be available in jira-software
          timeSpentSeconds?: number; // Can be available in jira-software
        }
      | undefined
    ) & {
      progress: {
        percent: number;
        progress: number;
        total: number;
      };
      issuetype: {
        name: string; // Bug / Task / ...
      };
      status: {
        name: string; // To Do / In progress / Done / ...
      };
      summary: string; // Description
      timespent?: number | null; // Only in ServiceManagement
      timeoriginalestimate: number | null;
      timeestimate?: number | null; // Only in ServiceManagement
    };
  }; // This has to be mapped to @JiraProjectIssueType
};

export type JiraPagination = {
  isLastPage: boolean;
  limit: number;
  size: number;
  start: number;
};

export type JiraServiceDeskPayload = JiraPagination & {
  /**
   * Array of Jira-ServiceDesk-Projects
   */
  values: JiraServiceDeskValue[];
  _links: JiraLink;
};

export type JiraServiceDeskValue = {
  /**
   * ID of Jira-ServiceDesk e.g. "3"
   */
  id: string;
  /**
   * ID of Jira-ServiceDesk-Project e.g. "10006"
   */
  projectId: string;
  /**
   * Name of Jira-ServiceDesk-Project e.g. "Support-Dyce"
   */
  projectName: string;
  /**
   * Key of Jira-ServiceDesk-Project e.g. "SD"
   */
  projectKey: string;
  /**
   * Link to self
   */
  _links: {
    self: string;
  };
};

export type JiraAvatarUrl = {
  avatarUrls: {
    "48x48": string;
    "24x24": string;
    "16x16": string;
    "32x32": string;
  };
};

export type JiraCustomerValueLink = JiraAvatarUrl & {
  jiraRest: string;
  self: string;
};

export type JiraLink = {
  _links: {
    self: string;
    base: string;
    context: string;
  };
};
export type JiraCustomerValue = {
  accountId: string;
  emailAddress: string;
  displayName: string;
  active: boolean;
  timeZone: string;
  _links: JiraCustomerValueLink;
};

export type IssueCreator = JiraAvatarUrl & {
  accountId: string;
  displayName: string;
  active: boolean;
  accountType: "atlassian" | "customer";
  timeZone: string;
  self: string;
};

export type IssueReporter = IssueCreator;

export type CustomerPayload = JiraPagination &
  JiraLink & {
    values: JiraCustomerValue[];
  };

//---===DevOps===---
export type DevOpsData = {
  organization: IHostContext | null;
  project: IProjectInfo | null;
  currentUser: IUserContext | null;
};

export type DevOpsPayload = {
  id: string;
  value: any;
  __etag: number;
};

export enum DevOpsBackEndId {
  SETTINGS = "settings",
  AREAMAPPING = "area-mapping",
  USERID = `userId-`,
  WEBHOOKS = "webHooks",
}

export interface DevOpsBackEndSettings {
  mappedInstance?: MappedInstanceValue | undefined | null;
  placeHolder?: Record<string, any> | undefined;
}

export type MappedInstanceValue = {
  tenantId: string;
  instance: string;
  companyName: string;
  companyId: string;
};

export interface DevOpsWorkItem {
  fields: WorkItemFields;
  id: number;
  rev: number;
  url: string;
  _links: any;
}

export type WorkItemFields = {
  "Microsoft.VSTS.Common.ActivatedBy": ExtensionInfo;
  "Microsoft.VSTS.Common.ActivatedDate": string;
  "Microsoft.VSTS.Common.Priority": number;
  "Microsoft.VSTS.Common.StateChangeDate": string;
  "Microsoft.VSTS.Scheduling.CompletedWork": number;
  "Microsoft.VSTS.Scheduling.OriginalEstimate": number;
  "Microsoft.VSTS.Scheduling.RemainingWork": number;
  "System.AreaId": number;
  "System.AreaLevel1": string;
  "System.AreaLevel2": string;
  "System.AreaLevel3": string;
  "System.AreaLevel4": string;
  "System.AreaPath": string;
  "System.ChangedBy": ExtensionInfo;
  href: string;
  "System.ChangedDate": string;
  "System.CommentCount": number;
  "System.CreatedBy": ExtensionInfo;
  "System.CreatedDate": string;
  "System.Description": string;
  "System.IterationPath": string;
  "System.NodeName": string;
  "System.Reason": string;
  "System.State": string;
  "System.TeamProject": string;
  "System.Title": string;
  "System.Watermark": number;
  "System.WorkItemType": string;
};

export interface ExtensionInfo {
  descriptor: string;
  displayName: string;
  id: string;
  imageUrl?: string;
  uniqueName: string;
  url?: string;
}

export type UpdateWorkItemType = {
  op: string;
  path: string;
  value: any;
};

export type WorkItemEffortType = {
  originalEstimate: number | undefined;
  remaining: number | undefined;
  completed: number | undefined;
};

export interface SubscriptionType {
  consumerActionId: string;
  consumerId: string;
  consumerInputs: ConsumerInputs;
  description: string;
  eventType: string;
  publisherId: string;
  publisherInputs: PublisherInputs;
  resourceVersion: string;
  status: string;
}

export interface SubscriptionResponse {
  actionDescription: string;
  consumerActionId: string;
  consumerId: string;
  consumerInputs: ConsumerInputs;
  createdBy: ExtensionInfo;
  createdDate: Date;
  eventDescription: string;
  eventType: string;
  id: string;
  lastProbationRetryDate: Date;
  modifiedBy: ExtensionInfo;
  modifiedDate: Date;
  publisherId: string;
  publisherInputs: PublisherInputsResponse;
  resourceVersion: string;
  status: string;
  subscriber: null;
  url: string;
}

export interface ConsumerInputs {
  basicAuthUsername?: string;
  basicAuthPassword?: string;
  detailedMessagesToSend?: string;
  httpHeaders?: string;
  messagesToSend?: string;
  resourceDetailsToSend?: string;
  url: string;
}

export interface PublisherInputs {
  areaPath?: string;
  changedFields?: string;
  projectId: string;
  workItemType?: string;
}

export interface PublisherInputsResponse extends PublisherInputs {
  tfsSubscriptionId: string;
}

export interface JobViewFilter {
  customerIds: string[];
  jobIds: string[];
  jobTaskIds: string[];
  startDate: Date | null;
  endDate: Date | null;
  includeEmptyDate: boolean;
}

export interface ProjectConfiguration {
  id: number;
  identifier: string;
  name: string;
  structureType: "area" | "iteration";
  hasChildren: boolean;
  path: string;
  children: ProjectConfigurationChild[];
  _links: Links;
  url: string;
  attributes?: Attributes;
}

export type ProjectConfigurationResult = {
  count: number;
  value: ProjectConfiguration[];
};

export interface ProjectingTree {
  areaPath: ProjectConfiguration | null;
  areaMappingTree: AreaMappingTree[] | null;
}

export interface ProjectConfigurationChild {
  id: number;
  identifier: string;
  name: string;
  structureType: string;
  hasChildren: boolean;
  path: string;
  children?: ProjectConfigurationChild[];
  url: string;
}

export interface AreaMappingTree {
  areaId: number;
  areaName: string;
  mapping: AreaMappingInfo;
  children?: AreaMappingTree[];
}

export interface Attributes {
  startDate: Date;
  finishDate: Date;
}

export interface AreaMapping {
  areaId: number;
  mapping: AreaMappingInfo;
  inherited: AreaMappingInfo;
}

export type AreaMappingInfo = {
  customer?: Customer | null;
  job?: PopulatedJob | null;
  jobTask?: PopulatedJobTask | null;
  jobPlanningLine?: PopulatedJobPlanningLine | null;
  tntModelLine?: TntModelLine | null;
};

export interface Links {
  self: Self;
}

export interface Self {
  href: string;
}
