import {
  Stopwatch,
  RecordTimeRec,
  ODataArrayResponse,
  RecordTemplate,
  Statistics,
  ValidatedRecord,
  BudgetValues,
} from "../types";
import buildQuery, { QueryOptions } from "odata-query";
import { Axios } from "axios";
import { combineURLs } from "../helpers/helpers";

// Watch Action Types
type watch_get = () => Promise<Stopwatch>;
type watch_start = (startTime: string, description: string) => Promise<void>;
type watch_stop = (id: string) => Promise<void>;

// Summary Action Types
type statistics_get = (from: string, to: string) => Promise<Statistics>;

interface StopwatchAlias {
  /**
   * Gets the stopwatch of the current user
   * @example
   * const client = new TnTClient(args).getRecService()
   * await client.stopwatch.get()
   */
  get: watch_get;
  /**
   * Starts the stopwatch of the current user
   * @param startTime The start time of the stopwatch as a ISO string
   * @param description
   * @example
   * const client = new TnTClient(args).getRecService()
   * await client.stopwatch.start("2021/01/01", "Test Description")
   */
  start: watch_start;
  /**
   * Stops the stopwatch of the current user if the stopwatch is running
   * @example
   * const client = new TnTClient(args).getRecService()
   * await client.stopwatch.stop()
   */
  stop: watch_stop;
}

interface StatisticsAlias {
  /**
   * Gets the time recording summary of the current user
   * @param from start time as ISO string
   * @param to end time as ISO string
   * @param offset timezone offset
   */
  get: statistics_get;
}

export class RecService {
  public batchUrl: string;
  public baseUrl: string;
  private watchURL: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.batchUrl = combineURLs(baseUrl, "/$batch");
    this.baseUrl = combineURLs(baseUrl, "/timerecordings");
    this.watchURL = combineURLs(baseUrl, "/stopwatches");

    this.axiosInstance = instance;
  }

  public get stopwatch(): StopwatchAlias {
    return {
      get: this.watchGetOne,
      start: this.watchStart,
      stop: this.watchStop,
    };
  }

  public get statistics(): StatisticsAlias {
    return {
      get: this.statisticsGet,
    };
  }

  /**
   * Get all timerecordings
   * @param query OData query
   * // Get every single recording
   * const client = new TnTClient(args).getRecService()
   * await client.getAll()
   */
  public async getAll(query?: Partial<QueryOptions<RecordTimeRec>>) {
    const url = `${this.baseUrl}${buildQuery(query)}`;

    const response =
      await this.axiosInstance.get<ODataArrayResponse<RecordTimeRec[]>>(url);

    return response.data;
  }

  /**
   * Gets only one recording by ID
   * @param id The ID of the recording
   * @param query OData Query
   * @example
   * const client = new TnTClient(args).getRecService()
   * await client.getOne("0000-0000-0000-0000")
   */
  public async getOne(
    id: string,
    query?: Partial<QueryOptions<RecordTimeRec>>
  ) {
    const url = `${this.baseUrl}/${id}${buildQuery(query)}`;

    const response = await this.axiosInstance.get<Partial<RecordTimeRec>>(url);

    return response.data;
  }

  /**
   * Creates a new timerecording
   * @param payload
   * @example
   * const client = new TnTClient(args).getRecService()
   * await client.create({ description: "Example", resource: { id: "0000-0000-0000-0000" } })
   */
  public async create(payload: Partial<RecordTimeRec>) {
    const url = `${this.baseUrl}`;

    const response = await this.axiosInstance.post<RecordTimeRec>(url, payload);

    return response.data;
  }

  /**
   * Update a single recording
   * @param id
   * @param payload
   * const client = new TnTClient(args).getRecService()
   * await client.update("0000-0000-0000-0000", { description: "New Example" })
   */
  public async update(id: string, payload: Partial<RecordTimeRec>) {
    const url = `${this.baseUrl}/${id}`;

    const result = await this.axiosInstance.put<Partial<RecordTimeRec>>(
      url,
      payload
    );

    return result.data;
  }

  public async getBudget(
    budgetOf: "jobTaskId" | "jobPlanningLineId",
    id: string
  ) {
    const url = `${this.baseUrl}/getBudget?${budgetOf}=${id}`;

    const response = await this.axiosInstance.get<BudgetValues>(url);

    return response.data;
  }

  /**
   * Deletes a timerecording
   * @param id
   * const client = new TnTClient().getRecService()
   * await client.remove("0000-0000-0000-0000")
   */
  public async remove(id: string) {
    const url = `${this.baseUrl}/${id}`;

    await this.axiosInstance.delete(url);

    return;
  }

  /**
   * validate the provided recording
   */
  public async validate(recording: RecordTemplate): Promise<ValidatedRecord> {
    const url = `${this.baseUrl}/validate`;

    const response = await this.axiosInstance.post(url, recording);

    return response.data;
  }

  // Stopwatch functions
  private watchGetOne: watch_get = async () => {
    const response = await this.axiosInstance.get<Stopwatch>(this.watchURL);

    return response.data;
  };

  private watchStart: watch_start = async (
    startTime: string,
    description: string
  ) => {
    await this.axiosInstance.post<{
      description: string;
      start: string;
    }>(this.watchURL, { start: startTime, description });

    return;
  };

  private watchStop: watch_stop = async (id: string) => {
    const url = `${this.watchURL}/${id}`;
    await this.axiosInstance.delete(url);

    return;
  };

  // Statistics Functions
  private statisticsGet: statistics_get = async (from: string, to: string) => {
    const url = `${this.baseUrl}/GetStatistics(from='${from}',to='${to}')`;

    const response = await this.axiosInstance.get<Statistics>(url);

    return response.data;
  };

  /**
   * Get all timerecordings of foreign app
   * @param param0 Arguments to get items for current task
   * @returns RecordTimeRec[]
   */
  public async getForeignTimeRecs(
    arg: {
      source: string;
      organization: string;
      project: string;
      taskId: string;
    },
    query?: Partial<QueryOptions<RecordTimeRec>>
  ) {
    const { source, organization, project, taskId } = arg;

    const url = `${
      this.baseUrl
    }/ByWorkItem(source='${source}',organization='${organization}',project='${project}',id='${taskId}')${buildQuery(
      query
    )}`;

    const response =
      await this.axiosInstance.get<ODataArrayResponse<RecordTimeRec[]>>(url);

    return response.data;
  }
}
