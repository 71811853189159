import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
import {
  handleBackgroundColor,
  handleBudgetUnits,
  handleSpentBarLength,
} from "./utils";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import {
  Box,
  Collapse,
  Fade,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      display: "flex",
      flex: 1,
      alignItems: "center",
      paddingTop: "2px",
      justifyContent: "end",
    },
    budgetBarFrame: {
      display: "flex",
      borderRadius: theme.shape.borderRadius,
      overflow: "hidden",
    },
    spentBar: {
      position: "absolute",
    },
    overSpentBar: {
      position: "absolute",
    },
    labelText: {
      position: "absolute",
      fontSize: "9px",
      fontWeight: 400,
      lineHeight: "1.4375em",
      letterSpacing: "0.00938em",
      whiteSpace: "nowrap",
      textTransform: "uppercase",
      userSelect: "none",
      transition: "all 0.3s ease",
    },
    amountTextContainer: {
      height: "100%",
    },
  })
);

interface IBudgetBarProps {
  /**
   * Is the bar vertical or horizontal?
   * @default false
   */
  isHorizontal?: boolean;
  /**
   * If true, the container will be shown with transition animations
   * @default true
   */
  showContainer?: boolean;
  /**
   * If true, the container will be shown immediately without transition animations
   * @default {showContainer}
   */
  initialShowContainer?: boolean;
  /**
   * If true, the container will be shown immediately without transition animations
   * @default true
   */
  disableFading?: boolean;
  /**
   * If true, the container will be shown as disabled (greyed out)
   * @default true
   */
  isDisabled?: boolean;
  /**
   * Define size of budget bar
   * @default "medium"
   */
  size?: "small" | "medium";
  /**
   * If true, the red bar will be shown when used > total
   */
  canOverspend: boolean;
  /**
   * If the border is set, background color is set to theme.palette.background.default
   * @default false
   */
  borderActive?: boolean;
  /**
   * The values for the budget and the rest
   */
  budgetValues: {
    /**
     * The budget value
     */
    total: number;
    /**
     * The rest value
     */
    used: number;
  } | null;
}

export const BudgetBar: FunctionComponent<IBudgetBarProps> = ({
  isHorizontal = false,
  showContainer = true,
  initialShowContainer = showContainer,
  disableFading = true,
  isDisabled = true,
  size = "medium",
  borderActive = false,
  canOverspend,
  budgetValues,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme<DyceTheme>();
  const lgViewportLength = useMediaQuery(
    theme.breakpoints.down(
      theme.palette.propsDyce.drawerLeft.isOpen ? 1350 : 1260
    )
  );
  const { total = 0, used = 0 } = budgetValues || {};

  // UseStates
  const [initialOpen, setInitialOpen] = useState<boolean>(initialShowContainer);
  const [dependingTimer, setDependingTimer] = useState<number>(500);
  const [showBar, setShowBar] = useState<boolean>(false);
  const [internalShowContainer, setInternalShowContainer] =
    useState<boolean>(disableFading);
  const [barThickness] = useState<number>(14);
  const [budgetUnit, setBudgetUnit] = useState<string>("0");
  const [spentUnit, setSpentUnit] = useState<string>("0");
  const [budgetBarLength, setBudgetBarLength] = useState<string>(
    isHorizontal
      ? lgViewportLength
        ? "100px"
        : "200px"
      : size === "small"
      ? "108px"
      : "132px"
  );
  const [spentBarLength, setSpentBarLength] = useState<string>("0px");
  const [overSpentBarLength, setOverSpentBarLength] = useState<string>("0px");

  // UseEffects
  useEffect(() => {
    let showBarTimer: NodeJS.Timeout;
    let initialOpenTimer: NodeJS.Timeout;
    if (showContainer) {
      initialOpen && setDependingTimer(0);
      setInternalShowContainer(true);
      showBarTimer = setTimeout(() => {
        setShowBar(true);
      }, dependingTimer);
      initialOpenTimer = setTimeout(() => {
        setDependingTimer(500);
        setInitialOpen(false);
      }, 600);
    } else {
      setShowBar(false);
    }
    return () => {
      clearTimeout(showBarTimer);
      clearTimeout(initialOpenTimer);
    };
  }, [showContainer]);

  useEffect(() => {
    let internalShowContainerTimer: NodeJS.Timeout;
    if (internalShowContainer && !showBar) {
      internalShowContainerTimer = setTimeout(() => {
        setInternalShowContainer(false);
      }, dependingTimer - 100);
    }
    return () => clearTimeout(internalShowContainerTimer);
  }, [showBar]);

  useEffect(() => {
    // Define to show (h / d) | (d / h) or not
    let dayHourSwitch = false;
    if (used >= 8 && total <= 8 && total >= -8) {
      dayHourSwitch = true;
    } else if (
      (total >= 8 && used < 8) ||
      (total >= -8 && total >= 8 && used < 8)
    ) {
      dayHourSwitch = true;
    } else if ((total >= 8 || total <= -8) && used < 8) {
      dayHourSwitch = true;
    }
    setBudgetUnit(
      handleBudgetUnits({
        value: total,
        dayHourSwitch,
        unitSuffix: true,
      })
    );

    setSpentUnit(
      handleBudgetUnits({
        value: used,
        dayHourSwitch,
      })
    );
  }, [total, used]);

  useEffect(() => {
    if (isHorizontal) {
      const newBudgetBarLength = lgViewportLength ? "100px" : "200px";
      setBudgetBarLength(newBudgetBarLength);
      // Calculate the rest bar length
      setSpentBarLength(
        handleSpentBarLength({
          used,
          total,
          budgetBarLength,
          newBudgetBarLength,
        })
      );
    }
  }, [lgViewportLength]);

  useEffect(() => {
    setSpentBarLength(
      handleSpentBarLength({
        used,
        total,
        budgetBarLength,
      })
    );
    if (used > total) {
      setOverSpentBarLength(
        handleSpentBarLength({
          used,
          total,
          budgetBarLength,
          overSpent: true,
        })
      );
    }
  }, [spentUnit]);

  // Handler
  const handleTextColor = () => {
    return isDisabled
      ? theme.palette.mode === "dark"
        ? theme.palette.grey[50]
        : theme.palette.grey[500]
      : theme.palette.mode === "dark"
      ? theme.palette.grey[50]
      : used > total
      ? theme.palette.grey[100]
      : used / total >= 0.75
      ? theme.palette.grey[100]
      : theme.palette.grey[900];
  };

  const handleTextShadow = () => {
    return theme.palette.mode === "dark"
      ? undefined
      : used / total >= 0.75
      ? "0px 0px 0px rgb(255, 255, 255)"
      : "0px 0px 3px rgb(255, 255, 255)";
  };

  return (
    <div
      style={{
        position: "relative",
        marginLeft: disableFading
          ? ""
          : !internalShowContainer && !isHorizontal
          ? "-1rem"
          : "",
      }}
    >
      <Collapse
        in={disableFading ? true : internalShowContainer}
        orientation={isHorizontal ? "vertical" : "horizontal"}
        timeout={disableFading ? 0 : dependingTimer}
        sx={{
          pointerEvents: "none",
        }}
      >
        <div
          style={
            isHorizontal
              ? {}
              : {
                  width: "48px",
                }
          }
        >
          <Fade
            in={initialOpen ? true : showBar}
            timeout={disableFading ? 0 : dependingTimer}
          >
            <div
              className={classes.container}
              style={
                isHorizontal
                  ? {
                      height: "36.5px",
                      paddingRight: "1.25rem",
                    }
                  : {
                      height: size === "small" ? "130px" : "162px",
                      width: "100%",
                      minWidth: "48px",
                      backgroundColor: handleBackgroundColor({
                        theme,
                        active: false,
                        borderActive,
                      }),
                      padding: "16px",
                      borderRadius: theme.shape.borderRadius,
                    }
              }
            >
              <Box
                className={classes.budgetBarFrame}
                sx={
                  isHorizontal
                    ? {
                        transition: "width 0.5s ease",
                        width: budgetBarLength,
                        height: barThickness + 2,
                        justifyContent: "end",
                        border: `1px solid ${
                          isDisabled
                            ? theme.palette.divider
                            : theme.palette.grey[500]
                        }`,
                      }
                    : {
                        width: "1rem",
                        height: budgetBarLength,
                        alignItems: "end",
                        border: `1px solid ${
                          isDisabled
                            ? theme.palette.divider
                            : theme.palette.grey[500]
                        }`,
                      }
                }
              >
                {/* AmountText */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={
                      isHorizontal
                        ? {
                            zIndex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "16px",
                            width: budgetBarLength,
                            color: handleTextColor(),
                            textShadow: handleTextShadow(),
                            whiteSpace: "nowrap",
                            transition: "all 0.3s ease",
                            transform: "translateY(2px)",
                          }
                        : {
                            userSelect: "none",
                            color: handleTextColor(),
                            textShadow: handleTextShadow(),
                            zIndex: 1,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            height: "16px",
                            width: budgetBarLength,
                            transform: "rotate(-90deg) translateY(16.5px)",
                            transformOrigin: "bottom left",
                            whiteSpace: "nowrap",
                            transition: "all 0.3s ease",
                          }
                    }
                  >
                    {`${spentUnit} / ${budgetUnit}`}
                  </Typography>
                </div>
                {/* Spent-Bar (blue) */}
                <Box
                  className={classes.spentBar}
                  sx={
                    isHorizontal
                      ? {
                          width: spentBarLength,
                          transition: `width 0.5s ${
                            used > total ? "ease-in" : "ease-in-out"
                          }`,
                          height: barThickness,
                          background: `rgb(42, 143, 189)`,
                        }
                      : {
                          height: spentBarLength,
                          transition: `height 0.5s ${
                            used > total ? "ease-in" : "ease-in-out"
                          }`,
                          width: barThickness,
                          background: `rgb(42, 143, 189)`,
                        }
                  }
                />
                {/* Overspent-Bar (red) */}
                {used > total && canOverspend && (
                  <Box
                    className={classes.overSpentBar}
                    sx={
                      isHorizontal
                        ? {
                            left: "1px",
                            width: overSpentBarLength,
                            transition: "width 0.5s ease-out",
                            transitionDelay: "0.5s",
                            height: barThickness,
                            background: `rgb(244 67 54)`,
                          }
                        : {
                            top: size === "small" ? "12px" : "16px",
                            height: overSpentBarLength,
                            transition: "height 0.5s ease-out",
                            transitionDelay: "0.5s",
                            width: barThickness,
                            background: `rgb(244 67 54)`,
                          }
                    }
                  />
                )}
                {/* LabelText above/besides the bar */}
                <div
                  className={classes.labelText}
                  style={
                    isHorizontal
                      ? {
                          width: "80px",
                          transformOrigin: "top left",
                          transform: `${
                            lgViewportLength
                              ? "translateX(-14px)"
                              : "translateX(-114px)"
                          }translateY(-13px)`,
                          whiteSpace: "nowrap",
                          color: isDisabled
                            ? theme.palette.grey[600]
                            : theme.palette.primary.main,
                        }
                      : {
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          transform:
                            "rotate(-90deg) translateX(16px) translateY(-26px)",
                          whiteSpace: "nowrap",
                          color: isDisabled
                            ? theme.palette.grey[600]
                            : theme.palette.primary.main,
                        }
                  }
                >
                  {t("lib.ui.timeTrackingTool.budget.caption")}
                </div>
              </Box>
            </div>
          </Fade>
        </div>
      </Collapse>
    </div>
  );
};
