import * as msal from "@azure/msal-browser";
import { InvalidUser } from "@dyce/interfaces";

// Browser check variables
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
const msedge = ua.indexOf("Edge/");
const firefox = ua.indexOf("Firefox");
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

export const msalConfig = {
  auth: {
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: isIE || isEdge || isFirefox, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (
        level: msal.LogLevel,
        message: string,
        containsPii: unknown
      ): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case msal.LogLevel.Error:
            console.error("Error", message);
            return;
          case msal.LogLevel.Info:
            if (
              !process.env["NODE_ENV"] ||
              process.env["NODE_ENV"] === "development"
            ) {
              // console.info(message);
            }
            return;
          case msal.LogLevel.Verbose:
            console.debug("Verbose", message);
            return;
          case msal.LogLevel.Warning:
            console.warn("Warning", message);
            return;
          default:
            console.log("Default", message);
        }
      },
    },
    windowHashTimeout: 60000,
    iframeHashTimeout: 10000,
    loadFrameTimeout: 0,
    asyncPopups: false,
  },
};

class MsalWrapper extends msal.PublicClientApplication {
  private scope: string;
  private invalidGrant:
    | (({ name, email, invalidGrant }: InvalidUser) => void)
    | undefined;

  constructor(
    config: msal.Configuration,
    scope: string,
    invalidGrant?: ({ name, email, invalidGrant }: InvalidUser) => void
  ) {
    super(config);
    this.scope = scope;
    this.invalidGrant = invalidGrant;

    this.registerLoginListener();
  }

  /**
   * refreshes the access token in the background
   */
  refreshToken(): Promise<msal.AuthenticationResult> {
    return new Promise(async (resolve, reject) => {
      const currentAccounts = this.getAllAccounts();

      if (currentAccounts !== null) {
        if (currentAccounts.length > 1) {
          await this.handleRedirectPromise().then(async (result) => {
            const activeAccount = this.getActiveAccount();

            if (!result && !activeAccount) {
              this.loginRedirect({
                scopes: this.getLoginRequest(this.scope).scopes,
                prompt: "select_account",
              });
            } else if (result || activeAccount) {
              const finalResult: msal.AccountInfo | undefined = activeAccount
                ? activeAccount
                : result && result.account
                ? result.account
                : undefined;

              if (finalResult) {
                this.setActiveAccount(finalResult);
                const res = await this.getTokenSilent();
                if (res) {
                  resolve(res);
                } else {
                  this.loginRedirect({
                    scopes: this.getLoginRequest(this.scope).scopes,
                    prompt: "select_account",
                  });
                }
              } else {
                this.loginRedirect({
                  scopes: this.getLoginRequest(this.scope).scopes,
                  prompt: "select_account",
                });
              }
            }
          });
        } else if (currentAccounts.length === 1) {
          const res = await this.getTokenSilent(currentAccounts[0]);
          if (res) {
            resolve(res);
          } else {
            if (window.self === window.top) {
              this.loginRedirect({
                scopes: this.getLoginRequest(this.scope).scopes,
                prompt: "select_account",
              });
            }
          }
        }
      }

      reject("cant fetch new token yet");
    });
  }

  private getTokenSilent = async (
    account?: msal.AccountInfo
  ): Promise<Promise<msal.AuthenticationResult> | null> => {
    let token: msal.AuthenticationResult | null = null;

    await this.acquireTokenSilent({
      scopes: this.getLoginRequest(this.scope).scopes,
      account: account,
    })
      .then((res) => {
        token = res;
      })
      .catch(async (e) => {
        if (window.self !== window.top) {
          if (String(e).includes("invalid_grant")) {
            // Installed callback for invalid grant access in extension
            this.invalidGrant &&
              this.invalidGrant({ name: "", email: "", invalidGrant: true });
          } else {
            this.acquireTokenPopup({
              scopes: this.getLoginRequest(this.scope).scopes,
            });
          }
        } else {
          if (String(e).includes("invalid_grant")) {
            const currentAccounts = this.getAllAccounts();
            /**
             * If currentAccounts.length > 0, then write name and email
             * to locale storage; This is needed for the invalidGrant() callback
             * User is not known in this AAD (scope);
             */
            if (currentAccounts.length === 1) {
              const userName = currentAccounts[0].name
                ? currentAccounts[0].name
                : null;
              const userEmail = currentAccounts[0].username
                ? currentAccounts[0].username
                : null;
              if (userName && userEmail) {
                localStorage.setItem(
                  "invalidGrantInstance",
                  JSON.stringify({
                    name: userName,
                    email: userEmail,
                    invalidGrant: true,
                  })
                );
              }
            }
            // Send user to login page with select_account prompt
            sessionStorage.clear();
            this.loginRedirect({
              scopes: this.getLoginRequest(this.scope).scopes,
              prompt: "select_account",
            });
          } else {
            // Test with this function to redirect to login page if something is wrong with the token
            // https://learn.microsoft.com/en-us/answers/questions/38202/interactionrequiredautherror-aadsts50058-a-silent.html
            // https://stackoverflow.com/a/61549554/12782488
            this.acquireTokenRedirect({
              scopes: this.getLoginRequest(this.scope).scopes,
            });
          }
        }
      });
    return token;
  };

  private registerLoginListener() {
    this.addEventCallback((message) => {
      if (message.eventType === msal.EventType.LOGIN_SUCCESS) {
        this.setActiveAccount(
          (message.payload as msal.AuthenticationResult).account
        );
      }
    });
  }

  /**
   * builds a login request from the provided scope
   * @param scope AD scope of the BAPI
   * @returns MSAL silent login request
   */
  getLoginRequest = (scope: string): msal.SilentRequest => ({
    scopes: ["profile", "openid", scope],
  });
}

export let msalInstance: MsalWrapper;

/**
 * Builds a msal client with the provided data or returns existing one
 * @param clientId AD client id
 * @param authority AD authority
 * @returns MSAL client
 */
export const getMsalInstance = (
  clientId: string,
  authority: string,
  scope: string,
  redirectUri?: string,
  invalidCallback?: ({ name, email }: { name: string; email: string }) => void
): MsalWrapper => {
  if (msalInstance) return msalInstance;

  const auth = redirectUri
    ? {
        ...msalConfig.auth,
        redirectUri: redirectUri,
      }
    : { ...msalConfig.auth };

  msalInstance = new MsalWrapper(
    {
      ...msalConfig,
      auth: { ...auth, authority: authority, clientId: clientId },
    },
    scope,
    invalidCallback
  );

  return msalInstance;
};
