import { FunctionComponent } from "react";
// MUI
import { Box, Typography } from "@mui/material";

interface IMoreOptionsActionProps {
  /**
   * Text shown in Typography component
   */
  title: string;
  /**
   * Define size of action-wrapper
   * @default "medium"
   */
  size?: "small" | "medium";
  /**
   * onClick event callback
   */
  onClick: () => void;
}

export const MoreOptionsAction: FunctionComponent<IMoreOptionsActionProps> = ({
  title,
  size = "medium",
  onClick,
}) => {
  return (
    <Box
      id="more-options-action"
      sx={{
        display: "flex",
        marginTop: size === "small" ? "0px" : "0.75rem",
        alignItems: "center",
        paddingLeft: size === "small" ? "0.5rem" : "1rem",
      }}
    >
      <Typography
        tabIndex={60}
        variant={size === "small" ? "body2" : "body1"}
        color="primary"
        sx={{
          cursor: "pointer",
          textDecoration: "underline",
        }}
        onClick={onClick}
      >
        {title}
      </Typography>
    </Box>
  );
};
