import {
  useState,
  useEffect,
  FunctionComponent,
  useRef,
  MutableRefObject,
} from "react";
// Helper
import { rest } from "lodash";
import { DateTime } from "luxon";
import moment, { Moment } from "moment";
import { parseFromNumber, parseFromString, parseValue } from "./utils";
import { RefCallBack } from "react-hook-form";
// MUI
import { IconButton, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";

interface IDateFieldProps {
  /**
   * Textfield-label
   */
  label: string;
  /**
   * Common styling for textfield
   */
  style?: string;
  /**
   * Default and/or selected value
   */
  value?: string | DateTime;
  /**
   * If true, Textfield is disabled
   * @default false
   */
  isDisabled?: boolean;
  /**
   * Variant of textfield
   */
  variant: "standard" | "filled" | "outlined" | undefined;
  /**
   * Current selected locale with hyphen, e.g. 'en-GB'
   */
  localeHyphen?: string;
  /**
   * Current selected language, e.g. 'en'
   * @default true
   */
  isEnglish: boolean;
  /**
   * Define tabIndex to focus correct field
   */
  tabIndex?: number;
  /**
   * If true, return is empty string instead of "Error"
   * @default false
   */
  allowEmptyField?: boolean;
  /**
   * If true, button to clear dateField appears on valid input
   * @default false
   */
  clearDateButton?: boolean;
  /**
   * If true, the input element will be focused during the first mount
   * @default false
   */
  autoFocus?: boolean;
  /**
   * If true, the input will take up the full width of its container
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Callback fired onChange event
   */
  onChange: (value: any) => void;
  /**
   * Callback fired onBlur event
   */
  onBlur: (value: any, ref: MutableRefObject<HTMLInputElement | null>) => void;
  /**
   * React-Hook-Form reference hook, e.g. to make component focusable
   */
  refHook?: RefCallBack;
  /**
   * Unique ID for testing field
   * @default "test-cypress-id"
   */
  testId?: string;
  /**
   * Define size of textfield
   * @default "medium"
   */
  size?: "small" | "medium";
}

export const DateFieldMoment: FunctionComponent<IDateFieldProps> = ({
  value,
  label,
  style,
  variant,
  isDisabled = false,
  localeHyphen = "de-DE",
  isEnglish = true,
  clearDateButton = false,
  autoFocus = false,
  tabIndex,
  allowEmptyField = false,
  fullWidth = false,
  testId = "test-cypress-id",
  size = "medium",
  onChange,
  onBlur,
  refHook,
}) => {
  // Refs
  const inputRef = useRef<HTMLInputElement | null>(null);

  // States
  const [date, setDate] = useState<Moment | null>(
    parseValue(value, allowEmptyField)
  );
  const [dateString, setDateString] = useState<string>(() => {
    const parse = parseValue(value, allowEmptyField);
    if (parse === null) {
      return "";
    } else {
      return parse.toISOString(true);
    }
  });
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [dateFormat] = useState<string>(
    moment().localeData().longDateFormat("L")
  );
  const [showClearButton, setShowClearButton] = useState<boolean>(
    date !== null
  );

  // UseEffects
  useEffect(() => {
    if (value) {
      const parse = parseValue(value, allowEmptyField);
      if (parse !== null) {
        setDate(parse);
        setDateString(parse.toISOString(true));
        onChange(parse.toISOString(true));
      }
    }
  }, [value]);

  useEffect(() => {
    if (date && !isOpen) {
      onChange(date.toISOString(true));
      setIsOpen(true);
    }
  }, [date, isOpen]);

  useEffect(() => {
    // UseEffect to clear dateField if value was error and cleared with clearDateButton
    if (allowEmptyField && clearDateButton) {
      if (
        typeof value === "string" &&
        value.length === 0 &&
        dateString === "" &&
        date
      ) {
        setDate(null);
        setDateString("");
      }
    }
  }, [value]);

  const handleChange = (val: Moment | null, input: string | undefined) => {
    if (val && input !== undefined) {
      setDateString(input);
      // TODO: Check behavior after merging with main (great bugfix there) - 2023-04-12 MOR
      // Force state change, to update component also with same entry (08:00 => 8 onBlur())
      // setDate(null);
    } else {
      if (val && val.isValid()) {
        setDate(val);
        setDateString(val.toISOString(true));
        onChange(val.toISOString(true));
      } else {
        if (allowEmptyField) {
          if (input === undefined) {
            setDateString("");
          } else if (input !== undefined && input.length === 0) {
            let v = parseFromString(input, localeHyphen, allowEmptyField);
            v = parseFromNumber(v, dateFormat, isEnglish);
            setDateString(v);
          }
        } else {
          setDateString("Error");
        }
      }
    }
  };

  const handleBlur = () => {
    let v = parseFromString(
      dateString ? dateString : "",
      localeHyphen,
      allowEmptyField
    );
    v = parseFromNumber(v, dateFormat, isEnglish);
    if (DateTime.fromISO(v).isValid) {
      setDate(moment(v));
      onChange(v);
    } else {
      if (allowEmptyField && v !== null && v.length === 0) {
        onChange(dateString);
      } else {
        onChange("Error");
      }
    }
    setShowClearButton(true);
    onBlur(date, inputRef);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} locale={localeHyphen}>
      <DesktopDatePicker
        onClose={() => setIsOpen(false)}
        inputRef={(e: HTMLInputElement) => {
          refHook && refHook(e);
          inputRef.current = e;
        }}
        value={date}
        disabled={isDisabled}
        onChange={handleChange}
        label={
          (label?.length === 0 && !allowEmptyField) || label === "Error" ? (
            <ErrorOutlineIcon
              style={{
                color: "#f44336",
                transform: "scale(1.2)",
              }}
            />
          ) : (
            label
          )
        }
        OpenPickerButtonProps={{ tabIndex: -1 }}
        disableMaskedInput={true}
        InputProps={{
          ...rest,
          onBlur: handleBlur,
        }}
        renderInput={(params) => (
          <TextField
            data-testid="test"
            fullWidth={fullWidth}
            id={testId}
            {...params}
            size={size}
            inputProps={{
              ...params.inputProps,
              tabIndex: tabIndex,
            }}
            autoFocus={autoFocus}
            onFocus={(e) => e.target.select()}
            className={style}
            variant={variant}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              endAdornment:
                allowEmptyField && clearDateButton ? (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {showClearButton && dateString && dateString.length > 0 ? (
                      <IconButton
                        sx={{ marginRight: "-12px" }}
                        onClick={() => {
                          handleChange(null, "");
                          setDate(moment()); // Force state change, to trigger useEffect and clear field internally
                          setDateString("");
                          onChange("");
                          setShowClearButton(false);
                        }}
                        tabIndex={-1}
                      >
                        <ClearOutlinedIcon />
                      </IconButton>
                    ) : null}
                    {params.InputProps?.endAdornment}
                  </div>
                ) : (
                  params.InputProps?.endAdornment
                ),
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};
