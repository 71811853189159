import {
  createSlice,
  createAsyncThunk,
  AnyAction,
  PayloadAction,
  AsyncThunk,
} from "@reduxjs/toolkit";
import { pull } from "lodash";
import {
  ActionMeta,
  Activity,
  Customer,
  PopulatedJob,
  PopulatedJobPlanningLine,
  PopulatedJobTask,
} from "@dyce/tnt-api";
import { tntApiHelper } from "../../apiHelper";
import { QueryOptions } from "odata-query";
import { wait } from "./utils";
import { RootState, TntStateSlice } from "../../types/types";

/**
 * Get all Customer
 */
export const getCustomer: AsyncThunk<
  Customer[],
  Partial<QueryOptions<Customer>> | undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  Customer[],
  Partial<QueryOptions<Customer>> | undefined,
  { state: RootState; rejectWithError: Error }
>("job/get", async (query, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getTnTService();

    return await client.getCustomers(query);
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Fetch Jobs from API
 * @param query OData Query to insert
 */
export const getJobs: AsyncThunk<
  PopulatedJob[],
  Partial<QueryOptions<PopulatedJob>> | undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  PopulatedJob[],
  Partial<QueryOptions<PopulatedJob>> | undefined,
  { state: RootState; rejectWithValue: Error }
>("job/getJobs", async (query, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getTnTService();

    return (await client.getJobs(query)) as PopulatedJob[];
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Fetch JobTasks from API
 * @param query OData Query to insert
 */
export const getJobTasks: AsyncThunk<
  PopulatedJobTask[],
  Partial<QueryOptions<PopulatedJobTask>> | undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  PopulatedJobTask[],
  Partial<QueryOptions<PopulatedJobTask>> | undefined,
  { state: RootState; rejectWithValue: Error }
>("job/getJobTasks", async (query, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getTnTService();

    return (await client.getJobTasks(query)) as PopulatedJobTask[];
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Fetch JobPlanningLine from API
 * @param query OData Query to insert
 */
export const getJobPlanningLines: AsyncThunk<
  PopulatedJobPlanningLine[],
  Partial<QueryOptions<PopulatedJobPlanningLine>> | undefined,
  { state: RootState; rejectWithValue: Error }
> = createAsyncThunk<
  PopulatedJobPlanningLine[],
  Partial<QueryOptions<PopulatedJobPlanningLine>> | undefined,
  { state: RootState; rejectWithValue: Error }
>("job/getJobPlanningLines", async (query, { getState, rejectWithValue }) => {
  try {
    const client = await (await tntApiHelper(getState)).getTnTService();

    return (await client.getJobPlanningLines(
      query
    )) as PopulatedJobPlanningLine[];
  } catch (error: any) {
    return rejectWithValue(error);
  }
});

/**
 * Get all Activities for a Job and JobTask
 */
export const getActivities: AsyncThunk<
  Activity[],
  { jobId: string; jobTaskId: string | null; filter: string; orderBy: string },
  { state: RootState; rejectWithError: Error }
> = createAsyncThunk<
  Activity[],
  { jobId: string; jobTaskId: string | null; filter: string; orderBy: string },
  { state: RootState; rejectWithError: Error }
>(
  "tnt/getActivities",
  async (
    { jobId, jobTaskId, filter, orderBy },
    { getState, rejectWithValue }
  ) => {
    try {
      const client = await (await tntApiHelper(getState)).getTnTService();
      await wait(50);

      return await client.getActivities(jobId, jobTaskId, filter, orderBy);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const initialState: TntStateSlice = {
  requests: [],
  customers: {},
  jobPlanningLines: {},
  jobTasks: {},
  jobs: {},
  tntModelLines: {},
};

const tntSlice = createSlice({
  name: "tnt",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCustomer.fulfilled, (state, action) => {
        action.payload.forEach((e) => (state.customers[e.id] = e));
      })
      .addCase(getJobs.fulfilled, (state, action) => {
        action.payload.forEach((job) => (state.jobs[job.id] = job));
      })
      .addCase(getJobTasks.fulfilled, (state, action) => {
        action.payload.forEach(
          (jobtask) => (state.jobTasks[jobtask.id] = jobtask)
        );
      })
      .addCase(getJobPlanningLines.fulfilled, (state, action) => {
        action.payload.forEach((jpl) => (state.jobPlanningLines[jpl.id] = jpl));
      })
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          action.type.startsWith("recs") && action.type.endsWith("pending"),
        (state, action) => {
          state.requests.push(action.meta.requestId);
        }
      )
      .addMatcher(
        (action: AnyAction): action is PayloadAction<any, string, ActionMeta> =>
          (action.type.startsWith("recs") &&
            action.type.endsWith("fulfilled")) ||
          (action.type.startsWith("recs") && action.type.endsWith("rejected")),
        (state, action) => {
          pull(state.requests, action.meta.requestId);
        }
      );
  },
});

export default tntSlice.reducer;
