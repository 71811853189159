// Helper
import { Route } from "react-router-dom";
// Dyce-Lib
import { DrawerLeft, useStaticContent } from "@dyce/ui";
import { DyceTheme } from "@dyce/theme";
import {
  useAppDispatch,
  useAppSelector,
  selectSideDrawerLeft,
  setSideDrawerLeft,
} from "@dyce/slices";
// MUI
import { useTheme } from "@mui/material";
import { Box } from "@mui/material";
// Components
import { UserSettings, TimeRecSettings, SettingsMenu } from "./";

export const Settings: React.FC = () => {
  const theme = useTheme<DyceTheme>();
  const dispatch = useAppDispatch();
  const { routes } = useStaticContent();

  // Selectors
  const open = useAppSelector(selectSideDrawerLeft);

  return (
    <>
      <DrawerLeft
        openClose={(value) => dispatch(setSideDrawerLeft(value))}
        open={open}
      >
        <SettingsMenu />
      </DrawerLeft>
      <Box sx={theme.palette.propsDyce.commonPageProps}>
        <Route exact path={routes.SETTINGS} component={UserSettings} />
        <Route exact path={routes.SETTINGS_TNT} component={TimeRecSettings} />
      </Box>
    </>
  );
};
