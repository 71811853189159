import { Axios } from "axios";
import { JiraOnboarding, JiraWebHook } from "../types";

export class WebHookService {
  public baseUrl: string;

  private axiosInstance: Axios;

  constructor(baseUrl: string, instance: Axios) {
    this.baseUrl = baseUrl;

    this.axiosInstance = instance;
  }

  /**
   * Get all created webHooks from jira
   */
  public async getWebHook() {
    const url = `${this.baseUrl}/webhooks`;

    const response = await this.axiosInstance.get<JiraWebHook[]>(url);

    return response.data;
  }

  /**
   * Creates a webHook for the foreign project
   * @param props Information about the foreign project and app
   * @returns ODataBatchResponse<T>
   */
  public async createWebHook(props: JiraOnboarding) {
    const url = `${this.baseUrl}/onboarding/${props.projectKey}`;

    const response = await this.axiosInstance.post<{
      id: string;
    }>(url, {
      clientKey: props.clientToken,
      instance: props.instance,
      company: props.company,
    });

    return response.data.id;
  }

  /**
   * Creates a webHook for the foreign project
   * @param id Id of the webHook
   */
  public async deleteWebHook(projectKey: string) {
    const url = `${this.baseUrl}/offboarding/${projectKey}`;

    const response = await this.axiosInstance.delete(url);

    return response.data;
  }
}
