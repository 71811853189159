import {
  FunctionComponent,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
// Helper
import { RefCallBack } from "react-hook-form";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Autocomplete, TextField } from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

const useStyles = makeStyles(() =>
  createStyles({
    resetMargin: {
      margin: "0 !important",
      padding: "0 !important",
    },
    resetFont: {
      fontSize: "14px !important",
    },
  })
);

interface IAutoCompleteProps {
  /**
   * Values as selection for autocomplete field
   */
  values: string[];
  /**
   * Default and/or selected value
   */
  value: string;
  /**
   * Common styling for textfield
   */
  style?: string;
  /**
   * Textfield-label
   */
  label: string;
  /**
   * Width for text field, string e.g. '4rem' || '64px'
   */
  width?: number | string;
  /**
   * If true, label is hidden
   * @default false
   */
  hideLabel?: boolean;
  /**
   * If true, Textfield is disabled
   * @default false
   */
  isDisabled?: boolean;
  /**
   * If true, textfield has no margin
   * @default false
   */
  resetMargin?: boolean;
  /**
   * If true, selection will be shown as open
   */
  initialOpen?: boolean;
  /**
   * Define tabIndex to focus correct field
   */
  tabIndex?: number;
  /**
   * If true, require label is shown (*)
   * @default false
   */
  required?: boolean;
  /**
   * If true, the component is used in the time tracking tool and force onChange hook
   * when value changes from outside (validate logic)
   * @default false
   */
  isFromTimeTrackingTool?: boolean;
  /**
   * If true, the input element will be focused during the first mount
   * @default false
   */
  autoFocus?: boolean;
  /**
   * Callback fired from getOptionLabel-prop: Used to determine the string
   * value for a given option. It's used to fill the input (and the list box
   * options if renderOption is not provided).
   */
  getTitle: (key: string) => string;
  /**
   * Callback fired onChange event
   */
  onChange: (value: any) => void;
  /**
   * Callback fired onBlur event
   */
  onBlur: (value: any, ref: MutableRefObject<HTMLInputElement | null>) => void;
  /**
   * Callback fired onClose event
   */
  onClose?: () => void;
  /**
   * React-Hook-Form reference hook, e.g. to make component focusable
   */
  refHook?: RefCallBack;
  /**
   * Unique ID for testing field
   * @default "test-cypress-id"
   */
  testId?: string;
  /**
   * Define size of textfield
   * @default "medium"
   */
  size?: "small" | "medium";
}

export const AutoComplete: FunctionComponent<IAutoCompleteProps> = ({
  values,
  value,
  style,
  label,
  width,
  hideLabel = false,
  isDisabled = false,
  resetMargin = false,
  initialOpen,
  tabIndex,
  required = false,
  isFromTimeTrackingTool = false,
  autoFocus = false,
  testId = "test-cypress-id",
  size = "medium",
  onClose,
  getTitle,
  onChange,
  onBlur,
  refHook,
}) => {
  const classes = useStyles();

  // Refs
  const inputRef = useRef<HTMLInputElement | null>(null);

  // States
  const [selectedValue, setSelectedValue] = useState<string>(value);
  const [textfieldChange, setTextfieldChange] = useState<string>(value);
  const [changedInternal, setChangedInternal] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    setSelectedValue(value);
    if (isFromTimeTrackingTool) {
      onChange(value);
    }
  }, [value]);

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.blur();
    if (changedInternal) {
      inputRef && inputRef.current && inputRef.current.focus();
      setChangedInternal(false);
    }
  }, [selectedValue]);

  // Handler
  const handleChange = (input: string) => {
    setSelectedValue(input);
    setTextfieldChange(input);
    onChange(input);
    setChangedInternal(true);
  };

  const handleBlur = () => {
    const autocompletedValue = handleAutocompleteValue();
    onBlur(autocompletedValue ? autocompletedValue : selectedValue, inputRef);
  };

  // Handler
  const handleAutocompleteValue = (): string | undefined => {
    if (!changedInternal && textfieldChange.length > 0) {
      // Check if value is in values => Autocomplete if found
      const found = values.find((element) =>
        getTitle(element).toLowerCase().includes(textfieldChange.toLowerCase())
      );
      if (found) {
        setSelectedValue(found);
        onChange(found);
        setTextfieldChange(found);
        return found;
      }
    }

    return undefined;
  };

  return (
    <Autocomplete
      open={initialOpen}
      onClose={(_, reason) => {
        if (onClose && reason === "escape") onClose();
      }}
      value={selectedValue}
      options={values}
      disabled={isDisabled}
      getOptionLabel={(option) => getTitle(option)}
      style={{
        width: width ? width : 300,
        fontSize: resetMargin ? "14px" : undefined,
      }}
      autoHighlight
      disableClearable={true}
      classes={
        resetMargin
          ? { inputRoot: classes.resetMargin, input: classes.resetFont }
          : undefined
      }
      onChange={(event, newValue) => {
        newValue && handleChange(newValue);
      }}
      onBlur={() => handleBlur()}
      id={testId}
      renderInput={(params) => (
        <TextField
          {...params}
          id={`${testId}-textField`}
          inputRef={(e: HTMLInputElement) => {
            refHook && refHook(e);
            inputRef.current = e;
          }}
          size={size}
          autoFocus={autoFocus}
          value={selectedValue}
          required={required}
          inputProps={{
            ...params.inputProps,
            tabIndex: tabIndex,
          }}
          classes={resetMargin ? { root: classes.resetMargin } : undefined}
          className={style}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(event) => setTextfieldChange(event.target.value)}
          label={
            hideLabel ? undefined : label.length === 0 ? (
              <ErrorOutlineIcon
                style={{
                  color: "#f44336",
                  transform: "scale(1.2)",
                }}
              />
            ) : (
              label
            )
          }
          variant="outlined"
        />
      )}
    />
  );
};
