import { FunctionComponent, useEffect, useState } from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { Tooltip } from "../tooltip/tooltip";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
// Jira
import { B100, N40 } from "@atlaskit/theme/colors";
import { token } from "@atlaskit/tokens";
import InlineEdit from "@atlaskit/inline-edit";
import UndoIcon from "@atlaskit/icon/glyph/undo";
import Button from "@atlaskit/button";
// Components

const useStyle = makeStyles(() =>
  createStyles({
    readViewStyles: {
      display: "flex",
      maxWidth: "100%",
      padding: `${token("space.075", "6px")} ${token("space.075", "6px")}`,
      fontSize: "18px",
      fontWeight: 500,
      overflow: "hidden",
    },
    editViewStyles: {
      boxSizing: "border-box",
      width: "100%",
      padding: `${token("space.075", "6px")} ${token("space.075", "6px")}`,
      border: `2px solid ${N40}`,
      borderRadius: token("border.radius", "3px"),
      cursor: "inherit",
      fontSize: "18px",
      fontWeight: 500,
      outline: "none",
      ":focus": {
        border: `2px solid ${B100}`,
      },
    },
    iconUndo: {
      position: "absolute",
      top: "5px",
      right: "0px",
      height: "32px",
      width: "32px",
      cursor: "pointer",
    },
  })
);

const CustomTitleComponent = ({
  customValue,
  defaultValue = "Editable title",
  undoButton = false,
  onUndo,
  onConfirm,
  onCancel,
}: {
  customValue?: string;
  defaultValue?: string;
  undoButton?: boolean;
  onUndo?: () => void;
  onCancel?: () => void;
  onConfirm: (value: string) => void;
}) => {
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <InlineEdit
      defaultValue={customValue ? customValue : defaultValue}
      onConfirm={(value) => onConfirm(value)}
      onCancel={onCancel}
      readView={() => (
        <div className={classes.readViewStyles}>
          {customValue ? customValue : defaultValue}
        </div>
      )}
      editView={(props, ref) => (
        <>
          {undoButton && (
            <div className={classes.iconUndo}>
              <Tooltip
                content={t("jira-ui.editableTitle.tooltip.undoButton")}
                position="top"
              >
                {(tooltipProps) => (
                  <Button
                    style={{ height: "28px" }}
                    isDisabled={
                      customValue ? customValue === defaultValue : true
                    }
                    {...tooltipProps}
                    onClick={onUndo}
                    iconAfter={<UndoIcon label="Undo" size="small" />}
                  />
                )}
              </Tooltip>
            </div>
          )}
          <input className={classes.editViewStyles} {...props} ref={ref} />
        </>
      )}
      cancelButtonLabel="Cancel"
      confirmButtonLabel="Confirm"
      keepEditViewOpenOnBlur
    />
  );
};

interface IEditableTitleProps {
  /**
   * Props
   */
  customValue?: string;
  /**
   * Default value
   */
  defaultValue?: string;
  /**
   * Show undo button
   */
  undoButton?: boolean;
  /**
   * Callback of changes from value
   * @param value Input text as string
   * @returns void
   */
  onChange: (value: string) => void;
}

export const EditableTitle: FunctionComponent<IEditableTitleProps> = ({
  onChange,
  ...props
}) => {
  // States
  const [initialCustomValue, setInitialCustomValue] = useState<string>(
    props.customValue ?? ""
  );
  const [internalCustomValue, setInternalCustomValue] = useState<string>(
    props.customValue ?? ""
  );

  return (
    <div
      style={{
        width: "100%",
        height: "32px",
      }}
    >
      <CustomTitleComponent
        {...props}
        customValue={internalCustomValue}
        defaultValue={props.defaultValue}
        onUndo={() => {
          setInitialCustomValue(internalCustomValue);
          setInternalCustomValue(props.defaultValue ?? "");
        }}
        onConfirm={(value) => {
          setInitialCustomValue(value);
          setInternalCustomValue(value);
          onChange(value);
        }}
        onCancel={() => setInternalCustomValue(initialCustomValue)}
      />
    </div>
  );
};
