import { ForeignClient } from "@dyce/foreign-api";

export const foreignApiHelper = async (options?: {
  /**
   * Provide a individual baseUrl to the client in extension app e.g.: Jira
   */
  individualBaseUrl?: string;
  /**
   * Provide jiraAppToken to authenticate with DYCE-Jira backend
   */
  jiraAppToken?: string;
  /**
   * Provide a foreign collection to the client in extension app e.g.: DevOps
   * @default undefined => foreignCollection is set to project.id || "DefaultCollection"
   */
  foreignCollection?: string;
}): Promise<ForeignClient> => {
  const {
    foreignCollection = undefined,
    individualBaseUrl = undefined,
    jiraAppToken = undefined,
  } = options ? options : {};

  const baseUrl = individualBaseUrl;

  // Initial TnT API Client
  const client = new ForeignClient({
    url: baseUrl,
    foreignCollection: foreignCollection ? foreignCollection : undefined,
    jiraAppToken: jiraAppToken,
  });

  return client;
};
