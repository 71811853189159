import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
// Helper
import { loginBG } from "@dyce/ui-assets";
import { IPublicClientApplication } from "@azure/msal-browser";
import { msalInstance } from "@dyce/auth";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { Typography, Button, Paper } from "@mui/material";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    container: {
      position: "relative",
      marginLeft: "3rem",
      marginRight: "3rem",
      marginTop: "1.5rem",
      [theme.breakpoints.down("lg")]: {
        marginLeft: "1.5rem",
        marginRight: "1.5rem",
        marginTop: 0,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "0.75rem",
        marginRight: "0.75rem",
      },
    },
    image: {
      width: "100%",
      borderRadius: 4,
      maxHeight: 650,
      [theme.breakpoints.down("md")]: {
        height: 600,
        objectFit: "cover",
      },
      [theme.breakpoints.down(380)]: {
        height: 500,
      },
      [theme.breakpoints.down(330)]: {
        height: 450,
      },
      "@media screen and (orientation: landscape)": {
        [theme.breakpoints.down(900)]: {
          height: 250,
          paddingTop: -20,
        },
      },
    },
    hero: {
      position: "relative",
      display: "flex",
      alignItems: "left",
      justifyContent: "center",
      flexDirection: "column",
      maxHeight: 650,
      maxWidth: 1300,
      [theme.breakpoints.down("md")]: {
        alignItems: "center",
        textAlign: "center",
      },
    },
    errorMessage: {
      color: "red",
      marginTop: "1rem !important",
    },
    dyce: {
      position: "absolute",
      padding: theme.spacing(6),
      [theme.breakpoints.down("xs")]: {
        paddingTop: theme.spacing(3),
        paddingRight: theme.spacing(1),
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(1),
      },
      "& .MuiTypography-root": {
        letterSpacing: "0.2em",
        fontWeight: 300,
      },
    },
    loginButton: {
      marginTop: theme.spacing(6),
      "& .MuiButton-root": {
        backgroundColor: "#5dffd0",
        color: "#000000",
      },
      "& .MuiButton-root:hover": {
        backgroundColor: "#4fd6b0",
      },
    },
  })
);

/* eslint-disable-next-line */
export interface HeroHeaderProps {
  /**
   * Title text from Hero-Wrapper
   */
  title: string;
  /**
   * Sub-Title text from Hero-Wrapper
   */
  subtitle: string;
  /**
   * Msal instance
   */
  instance: IPublicClientApplication;
}

export const HeroHeader: FunctionComponent<HeroHeaderProps> = ({
  title,
  subtitle,
  instance,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  // States
  const [error, setError] = useState(false);
  const [disableLogin, setDisableLogin] = useState<boolean>(false);

  // UseEffects
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const userNotFound = Boolean(query.get("usernotfound"));

    if (userNotFound) {
      setError(true);
      setDisableLogin(false);
    }
  }, []);

  useEffect(() => {
    const users = instance.getAllAccounts();
    // Login automatically if only one user is found and token is still valid
    if (users.length === 1) {
      instance.setActiveAccount(users[0]);
      msalInstance.refreshToken();
    }
  }, []);

  // Handler
  const handleLogin = () => {
    setDisableLogin(true);
    if (error) {
      setDisableLogin(false);
      history.push("/");
    }
    if (!disableLogin) {
      instance.loginRedirect();
    }
  };

  return (
    <div className={classes.container}>
      <Paper
        elevation={3}
        className={classes.hero}
        sx={{
          backgroundColor: "rgb(22, 11, 118)",
          height: "auto",
        }}
      >
        <img
          src={loginBG}
          alt="dyce-login"
          className={classes.image}
          width="100%"
          height="100%"
        />
        <div className={classes.dyce}>
          <Typography variant="h2" color="white">
            {title}
          </Typography>
          <Typography variant="h5" color="white">
            {subtitle}
          </Typography>
          <div className={classes.loginButton}>
            <Button variant="contained" onClick={() => handleLogin()}>
              {t("login.login")}
            </Button>
            {error && (
              <Typography className={classes.errorMessage}>
                {t("login.error")}
              </Typography>
            )}
          </div>
        </div>
      </Paper>
    </div>
  );
};
