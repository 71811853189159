import { FunctionComponent, useEffect, useState } from "react";
import Particles from "react-tsparticles";
import { createStyles, makeStyles } from "@mui/styles";

const useStyles = makeStyles(() =>
  createStyles({
    particles: {
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      height: "100%",
      minHeight: "400px",
      zIndex: -1,
    },
  })
);

interface IBackgroundProps {
  /**
   * If true, background will be shown in white
   * @default true
   */
  white?: boolean;
}

export const Background: FunctionComponent<IBackgroundProps> = ({
  white = true,
}) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <div>
      {!loaded ? (
        <div
          className={classes.particles}
          style={{ backgroundColor: white ? "#fff" : "#d0dafe" }}
        />
      ) : (
        <Particles
          className={classes.particles}
          id="tsparticles"
          options={{
            background: {
              color: {
                value: white ? "#fff" : "#d0dafe",
              },
            },
            fpsLimit: 60,
            interactivity: {
              events: {
                onClick: {
                  enable: false,
                  mode: "push",
                },
                onHover: {
                  enable: false,
                },
                resize: true,
              },
              modes: {
                bubble: {
                  distance: 400,
                  duration: 2,
                  opacity: 0.8,
                  size: 40,
                },
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 100,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "rgb(65, 65, 65)",
              },
              links: {
                color: "rgb(65, 65, 65)",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: true,
              },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 2,
                straight: true,
              },
              number: {
                density: {
                  enable: true,
                  value_area: 1500,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
              },
              size: {
                random: true,
                value: 2,
              },
            },
            detectRetina: true,
            retina_detect: true,
          }}
        />
      )}
    </div>
  );
};
