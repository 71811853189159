import React from "react";
// Helper
import { useTranslation } from "react-i18next";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { InputBase } from "@mui/material";
import SearchSharpIcon from "@mui/icons-material/SearchSharp";

const useStyles = makeStyles((theme: DyceTheme) =>
  createStyles({
    root: {
      height: 45,
    },
    formStyle: {
      padding: "2px 4px",
      display: "flex",
      alignItems: "center",
      width: 300,
      backgroundColor: theme.palette.background.paper,
      height: "100%",
      borderStyle: "solid",
      border: 1,
      borderRadius: 0,
      borderColor: theme.palette.grey[500],
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 7,
    },
  })
);

interface ISearchboxProps {
  /**
   * Callback fired onBlur event
   */
  onChange: (value: string) => void;
  /**
   * Callback fired onArrowKey event 'up' || 'down'
   */
  onArrowKey?: (key: string) => void;
  /**
   * Common styling for textfield
   * @default ''
   */
  className?: string;
  /**
   * Placeholder text
   * @default '' = 'Search'
   */
  placeholder?: string;
}

export const SearchBox: React.FC<ISearchboxProps> = ({
  onChange,
  onArrowKey,
  placeholder,
  className = "",
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    onChange(e.target.value);
  };

  const handleSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const handleKey = (
    e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    if (["ArrowDown", "ArrowUp"].includes(e.key)) {
      onArrowKey && onArrowKey(e.key);
    }
  };

  return (
    <div className={className}>
      <div className={classes.root}>
        <form className={classes.formStyle} onSubmit={handleSearch}>
          <InputBase
            type="search"
            className={classes.input}
            placeholder={placeholder ? placeholder : t("components.search")}
            onChange={handleChange}
            onKeyDown={handleKey}
          />
          <SearchSharpIcon color="disabled" />
        </form>
      </div>
    </div>
  );
};
