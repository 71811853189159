import { DateTime } from "luxon";
import * as H from "history";
import { t } from "i18next";

/**
 * Function to get today back as hash-string;
 * Also with location.search if exists
 * @param location Location from react-router
 * @returns Date from the Sunday after next as string with hash ("#yyyy-MM-dd")
 */
export const goToToday = (location: Location) => {
  return `${
    location.search ? location.search : ""
  }#${DateTime.now().toISODate()}`;
};

/**
 * Function to get end of last week back as hash-string;
 * Also with location.search if exists
 * @param location Location from react-router
 * @returns Date from the Sunday after next as string with hash ("#yyyy-MM-dd")
 */
export const goToLastWeek = (location: Location) => {
  return `${location.search ? location.search : ""}#${DateTime.fromISO(
    location.hash.split("#")[1]
  )
    .minus({ days: 7 })
    .startOf("week")
    .toISODate()}`;
};

/**
 * Function to get end of next week back as hash-string;
 * Also with location.search if exists
 * @param location Location from react-router
 * @returns Date from the Sunday after next as string with hash ("#yyyy-MM-dd")
 */
export const goToNextWeek = (location: Location) => {
  return `${location.search ? location.search : ""}#${DateTime.fromISO(
    location.hash.split("#")[1]
  )
    .plus({ days: 7 })
    .startOf("week")
    .toISODate()}`;
};

/**
 * Function to parse Infostring from specified date to calendarweek
 * @param hashDate Current date from URL Hash as String ("yyyy-MM-dd")
 * @param dateFormat Format for DateTime ("yyyy-MM-dd")
 * @param locale Locale information from redux store as String
 * @param language Language information from redux store as String
 * @param t Translation TFunction
 * @returns String with Calendarweek information
 */
export const getCalendarWeek = (
  hashDate: string,
  dateFormat: string,
  locale: string,
  language: string
): string => {
  return (
    t("timerec.subHeader.myTime.dailyRecords.subtitle") +
    " " +
    DateTime.fromFormat(hashDate, dateFormat)
      .setLocale(locale)
      .toFormat("W", { locale: language })
  );
};

/**
 * Function to parse Infostring from specified date to calendarweek infostring
 * @param hashDate Current date from URL Hash as String ("yyyy-MM-dd")
 * @param dateFormat Format for DateTime ("yyyy-MM-dd")
 * @param locale Locale information from redux store as String
 * @param language Language information from redux store as String
 * @returns String with Calendarweek information (Startdate - Enddate)
 */
export const getWeekInfoString = (
  hashDate: string,
  dateFormat: string,
  locale: string,
  language: string
): string => {
  return (
    DateTime.fromFormat(hashDate, dateFormat)
      .setLocale(locale)
      .startOf("week")
      .toFormat("D", { locale: language }) +
    " – " +
    DateTime.fromFormat(hashDate, dateFormat)
      .setLocale(locale)
      .endOf("week")
      .toFormat("D", { locale: language })
  );
};

/**
 * Function to parse Infostring from specified date to calendarweek infostring
 * @param hashDate Current date from URL Hash as String ("yyyy-MM-dd")
 * @param dateFormat Format for DateTime ("yyyy-MM-dd")
 * @param language Language information from redux store as String
 * @returns String with current month, could be 2 different month by start and end of week
 */
export const getMonthInfoString = (
  hashDate: string,
  dateFormat: string,
  language: string
): string => {
  let monthInfo = "";

  if (
    DateTime.fromFormat(hashDate, dateFormat).startOf("week").year <
      DateTime.fromFormat(hashDate, dateFormat).endOf("week").year ||
    DateTime.fromFormat(hashDate, dateFormat).startOf("week").month <
      DateTime.fromFormat(hashDate, dateFormat).endOf("week").month
  ) {
    monthInfo = `${DateTime.fromFormat(hashDate, dateFormat)
      .startOf("week")
      .toFormat("LLL", {
        locale: language,
      })}${language === "de" ? "." : ""} – 
          ${DateTime.fromFormat(hashDate, dateFormat)
            .endOf("week")
            .toFormat("LLL", {
              locale: language,
            })}${language === "de" ? "." : ""}`;
  } else {
    monthInfo = DateTime.fromFormat(hashDate, dateFormat).toFormat("LLLL", {
      locale: language,
    });
  }

  return monthInfo;
};

export const handleCreateNewTimerecording = (
  history: H.History,
  editorOpen: boolean
) => {
  const searchQuery = location.search;
  setTimeout(
    () => {
      history.push(
        `${
          searchQuery.length > 0 && !searchQuery.includes("newRecord")
            ? searchQuery + "&"
            : "?"
        }newRecord=true#${DateTime.now().toISODate()}`
      );
    },
    editorOpen ? 500 : 0
  );
};
