import React, { FunctionComponent, MouseEvent, useEffect, useRef } from "react";
// Dyce-Lib
import { Tooltip } from "../tooltip/tooltip";
import { useStaticContent } from "../static-provider/static-provider";
// MUI
import { IconButton, Badge, BadgeProps, styled } from "@mui/material";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: 1,
    top: 20,
    border: `1px solid ${theme.palette.background.paper}`,
  },
}));

interface IStopwatchProps {
  /**
   * If true, stopwatch icon has record badge shown
   */
  isRunning: boolean;
  /**
   * If true, buttons are disabled
   */
  isLoading: boolean;
  /**
   * If true, tooltIp will be shown and IconButton is disabled
   */
  isOpen: boolean;
  /**
   * If provided, toolTip is active with provided label
   */
  tooltipLabel?: string;
  /**
   * Callback fired onClick event
   */
  openModal: (e: MouseEvent<HTMLElement>) => void;
  /**
   * HTML Element
   */
  registerRef: (r: React.MutableRefObject<any>) => void;
}

export const Stopwatch: FunctionComponent<IStopwatchProps> = ({
  isRunning,
  isLoading,
  isOpen,
  tooltipLabel,
  openModal,
  registerRef,
}) => {
  const { docuLinks } = useStaticContent();

  // Refs
  const ref = useRef(null);

  // UseEffects
  useEffect(() => {
    registerRef(ref);
  }, [ref]);

  // Handler
  const handleClick = (e: MouseEvent<HTMLElement>) => {
    openModal(e);
  };

  const kids = isRunning ? (
    <span>
      <IconButton
        id="stopwatchBtn"
        aria-label="stopwatch"
        onClick={handleClick}
        disabled={isLoading || isOpen}
      >
        <StyledBadge badgeContent="" color="error" overlap="circular">
          <TimerOutlinedIcon
            data-testid="icon-started"
            aria-label="stopwatch"
            sx={{
              color: isLoading ? "disabled" : "#fff",
            }}
            // fontSize="large"
          />
        </StyledBadge>
      </IconButton>
    </span>
  ) : (
    <span>
      <IconButton
        aria-label="stopwatch"
        id="stopwatchBtn"
        onClick={handleClick}
        disabled={isLoading || isOpen}
      >
        <TimerOutlinedIcon
          data-testid="icon-stopped"
          aria-label="stopwatch"
          sx={{
            color: isLoading ? "disabled" : "#fff",
          }}
          // fontSize="large"
        />
      </IconButton>
    </span>
  );

  return (
    <div ref={ref}>
      {tooltipLabel && !isOpen ? (
        <Tooltip
          label={tooltipLabel}
          urlPath={docuLinks.navbar.stopwatch.stopwatchButton}
          position="bottom-end"
        >
          <div>{kids}</div>
        </Tooltip>
      ) : (
        { ...kids }
      )}
    </div>
  );
};
