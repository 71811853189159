import { createSelector } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { createGroupHeaderDateFormat } from "./utils";
import { JobViewEntry } from "@dyce/tnt-api";
import { RootState, TaskSettings } from "../../types/types";

export const selectTasksAsArray = (state: RootState) =>
  Object.values(state.task.entries);

export const selectTaskCount = (state: RootState) => state.task.totalCount;

export const selectTaskPagination = (state: RootState) => state.task.pagination;

export const selectTaskInitialResult = (state: RootState) =>
  state.task.initialResult;

// Internal functions used in selectors
const currentLanguage = (state: RootState) => state.settings.general.language;

// CreateSelector functions
export const selectTasksAsTwoDimArray = (groupedBy: TaskSettings) =>
  createSelector(selectTasksAsArray, currentLanguage, (tasks, language) => {
    // Group by Customer or Job => 2D Array
    const groupTasks = Object.entries(
      tasks.reduce(
        (tasks, task) => {
          let name = "Not Defined";
          if (groupedBy === TaskSettings.JOB) {
            if (task.job && task.job.description && task.job.no) {
              name = task.job.no + " - " + task.job.description;
            }
          } else if (groupedBy === TaskSettings.CUSTOMER) {
            if (task.customer && task.customer.name && task.customer.no) {
              name = task.customer.no + " - " + task.customer.name;
            }
          } else if (groupedBy === TaskSettings.STARTDATE) {
            if (task.plannedStartDate) {
              name = createGroupHeaderDateFormat(
                DateTime.fromISO(task.plannedStartDate),
                language
              );
            }
          } else if (groupedBy === TaskSettings.ENDDATE) {
            if (task.plannedEndDate) {
              name = createGroupHeaderDateFormat(
                DateTime.fromISO(task.plannedEndDate),
                language
              );
            }
          }
          tasks[name] = tasks[name] ? [...tasks[name], task] : [task];
          return tasks;
        },
        {} as { [key: string]: JobViewEntry[] }
      )
    );

    return groupTasks;
  });
