import { FunctionComponent, useEffect, useState } from "react";
// Helper
import clsx from "clsx";
// Dyce-Lib
import { DyceTheme } from "@dyce/theme";
// MUI
import { createStyles, makeStyles } from "@mui/styles";
import { List, Paper, Typography, useTheme } from "@mui/material";

const useStyles = makeStyles(() =>
  createStyles({
    component: {
      marginTop: "1.5rem",
    },
  })
);

interface ISettingsGroupProps {
  /**
   * Header title for group
   */
  groupHeader?: string;
  /**
   * CSS class
   */
  className?: any;
  /**
   * Unique id name for component
   */
  anchor: string;
  /**
   * If true, the group will be highlighted
   * @default false
   */
  highlight?: boolean;
  /**
   * If true, marginLeft & marginRight will be set to 0
   * @default false
   */
  fullWidth?: boolean;
  /**
   * Children as React.ReactNode
   */
  children: React.ReactNode;
}

export const SettingsGroup: FunctionComponent<ISettingsGroupProps> = ({
  className,
  groupHeader,
  anchor,
  highlight = false,
  fullWidth = false,
  children,
}) => {
  const classes = useStyles();
  const theme = useTheme<DyceTheme>();

  // UseStates
  const [highlightBackground, setHighlightBackground] =
    useState<boolean>(false);

  useEffect(() => {
    let highlightTimerStart: NodeJS.Timeout;
    let highlightTimer: NodeJS.Timeout;
    if (highlight) {
      highlightTimerStart = setTimeout(() => {
        setHighlightBackground(true);
      }, 800);
      highlightTimer = setTimeout(() => {
        setHighlightBackground(false);
      }, 1400);
    }
    return () => {
      clearTimeout(highlightTimerStart);
      clearTimeout(highlightTimer);
    };
  }, [highlight]);

  return (
    <div id={anchor}>
      <Paper
        variant={theme.palette.propsDyce.paperDesign.variant}
        elevation={theme.palette.propsDyce.paperDesign.elevation}
        className={clsx(classes.component, className)}
        sx={{
          marginRight: fullWidth ? 0 : "1.75rem",
          marginLeft: fullWidth ? 0 : "1.5rem",
          backgroundColor: highlightBackground
            ? theme.palette.primary.main
            : "transparent",
          transition: "background-color 0.4s ease-in-out",
        }}
      >
        {groupHeader && (
          <Typography
            variant="h6"
            sx={{ marginTop: "1rem", marginLeft: "4.5rem" }}
          >
            {groupHeader}
          </Typography>
        )}
        <List>{children}</List>
      </Paper>
    </div>
  );
};
